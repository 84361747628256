import { Drawer, Menu } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { DrawerHeader } from './DrawerHeader';
import deleteSvg from 'shared/assets/images/delete.svg';
import editSvg from 'shared/assets/images/edit.svg';
import { Icon } from '../IconComponent';
import { AssignmentTemplateEditDrawer } from './AssignmentTemplateEditDrawer';

type TAssignmentTemplatesDrawer = {
  visible: boolean;
  onClose: () => void;
  onSelectTemplate: (id: string) => void;
};

type TAssignmentemplate = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  Name: string;
  Content: string;
  AuthorId: number;
  ResponsibleId: number;
  ControllerId: number;
  ExecutorId: number;
  ExecutionTime: string;
};

export const AssignmentsTemplatesDrawer = ({
  visible,
  onClose,
  onSelectTemplate,
}: TAssignmentTemplatesDrawer) => {
  const [templateList, setTemplateList] = useState<TAssignmentemplate[]>([]);
  const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(0);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  const getTemplates = useCallback(() => {
    $api
      .get('AssignmentTemplates')
      .then(res => {
        const newArray = res.data.filter(
          (item: TAssignmentemplate) =>
            item.AuthorId === user?.CurrentContact?.Id,
        );
        setTemplateList(newArray);
      })
      .catch(({ response }) => errorThrow(response));
  }, [user?.CurrentContact?.Id]);

  const onRemoveTemplate = (id: number) => {
    $api
      .delete(`AssignmentTemplates/${id}`)
      .then(() => getTemplates())
      .catch(({ response }) => errorThrow(response));
  };

  useEffect(() => getTemplates(), [getTemplates]);

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      {visibleEditDrawer && (
        <AssignmentTemplateEditDrawer
          visible={visibleEditDrawer}
          onClose={() => setVisibleEditDrawer(false)}
          id={selectedTemplateId}
        />
      )}
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Шаблоны задачи" />
        <Menu>
          {templateList.map(item => (
            <div className="flex-space">
              <Menu.Item
                className="w100per"
                onClick={() => onSelectTemplate(String(item.Id))}
                key={item.Id}
              >
                {item.Name ?? 'Без названия'}
              </Menu.Item>
              <div className="flex-space">
                <Icon
                  icon={editSvg}
                  alt="editSvg"
                  className="mr5"
                  onClick={() => {
                    setSelectedTemplateId(item.Id);
                    setVisibleEditDrawer(true);
                  }}
                  tooltip="Редактировать"
                />
                <Icon
                  icon={deleteSvg}
                  alt="deleteSvg"
                  onClick={() => onRemoveTemplate(item?.Id)}
                  tooltip="Удалить"
                />
              </div>
            </div>
          ))}
        </Menu>
      </div>
    </Drawer>
  );
};
