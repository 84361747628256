import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Auth = {
  isAuth: boolean;
  substituateId: number | null;
};

const initialState: Auth = {
  isAuth: false,
  substituateId: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authUser(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    updateSubstituate(state, action: PayloadAction<number | null>) {
      state.substituateId = action.payload;
    },
  },
});

export const { authUser, updateSubstituate } = authSlice.actions;
export const authReducer = authSlice.reducer;
