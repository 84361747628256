import { Dropdown, Form, Menu, Modal, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import {
  errorThrow,
  pageRefresh,
  successNotification,
  successNotificationToRouteSend,
} from 'shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { $api } from 'shared/utils/api';
import { ASampleDocumentDrawer } from 'shared/components/CustomDrawers';
import { DButton, DTextarea } from 'shared/ui-kit';
import { DocumentEditDrawer } from 'shared/components/CustomDrawers/DocumentEditDrawer';
import { DocumentLinkageDrawer } from 'shared/components/CustomDrawers/DocumentLinkageDrawer';
import { DownOutlined } from '@ant-design/icons';
import { ReduxState } from 'store/store';
import {
  updateDocumentInfo,
  updateInfoByLinkishDocument,
} from 'store/slice/document';
import { useHistory } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import { useEffect } from 'react';
import openEye from 'shared/assets/images/openEye.svg';
import closeEye from 'shared/assets/images/closeEye.svg';
import { Icon } from 'shared/components/IconComponent';
import {
  permissionExist,
  PermissionsAccess,
} from 'shared/utils/isExistPermission';

type TUseDocumentPage = {
  match: {
    url: string;
  };
};

export const useDocumentPage = ({ match }: TUseDocumentPage) => {
  const [visibleEditDrawer, setVisibleEditDrawer] = useState<boolean>(false);
  const [visibleLinkDrawer, setVisibleLinkDrawer] = useState<boolean>(false);
  const [linkType, setLinkType] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [visibleSample, setVisibleSample] = useState<boolean>(false);
  const [visibleRejectModal, setVisibleRejectModal] = useState<boolean>(false);
  const { documentInfo, configureButtons } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useDispatch();
  const documentType = `Documents${documentInfo?.Type}`;
  const documentId = documentInfo?.Id;
  const isHaveDeloPermission =
    user?.Permissions &&
    Boolean(permissionExist(user?.Permissions, PermissionsAccess.DocumentDelo));
  const isShowDeloSend =
    documentInfo?.Type === 'Outcoming' && Boolean(isHaveDeloPermission);

  const deleteDocument = useCallback(() => {
    $api
      .delete(`Documents/${documentInfo?.Id}`)
      .then(() => {
        history.push('/active-documents/page=1');
        setVisible(false);
      })
      .catch(({ response }) => errorThrow(response));
  }, [documentInfo?.Id, history]);

  const onCloseLinkDrawer = (bool: boolean) => {
    setVisibleLinkDrawer(bool);
  };

  const onCloseEditDrawer = (bool: boolean) => {
    setVisibleEditDrawer(bool);
  };

  const onCloseSampleDrawer = (bool: boolean) => {
    setVisibleSample(bool);
    dispatch(updateInfoByLinkishDocument(null));
  };

  const onSendtoRework = () => {
    $api
      .post(`Documents/${documentInfo?.Id}/flow/rework`)
      .then(() => {
        successNotification('Документ успешно отправлен на доработку');
        pageRefresh();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const returnToSendPhase = () => {
    $api
      .post(`Documents/${documentInfo?.Id}/flow/ReturnToSendPhase`)
      .then(() => {
        successNotification('Документ успешно отправлен на доработку');
        pageRefresh();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const deleteModal = (
    <Modal
      title="Удаление документа"
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={[
        <DButton className="mr15" small primary onClick={deleteDocument}>
          Удалить
        </DButton>,
        <DButton small defaultDanger onClick={() => setVisible(false)}>
          Отмена
        </DButton>,
      ]}
    >
      <span>Вы уверены что хотите удалить документ?</span>
    </Modal>
  );

  const additionalOperationsMenu = (
    <Menu>
      <Menu.Item
        key="13_1"
        onClick={() => {
          dispatch(updateInfoByLinkishDocument(Number(documentInfo?.Id)));
          setVisibleSample(true);
          // dispatch(updateDocumentInfo(null));
          // history.push('/create');
        }}
      >
        Создать связанный документ
      </Menu.Item>
      <Menu.Item
        key="14_1"
        onClick={() => {
          setVisibleLinkDrawer(true);
          setLinkType('create');
        }}
      >
        Установить связь с документом
      </Menu.Item>
      <Menu.Item
        key="16_1"
        onClick={() => {
          setVisibleSample(true);
        }}
      >
        Создать документ по образцу
      </Menu.Item>
      <Menu.Item key="17_1" onClick={onSendtoRework}>
        Отозвать на доработку
      </Menu.Item>
      {configureButtons?.Delete && (
        <Menu.Item
          disabled={!!documentInfo?.RegNumber}
          key="18_1"
          onClick={() => setVisible(true)}
        >
          Удалить документ
        </Menu.Item>
      )}
      <Menu.Item
        key="19_1"
        disabled={documentInfo?.State !== 'Closed'}
        onClick={returnToSendPhase}
      >
        Повторная отправка
      </Menu.Item>
    </Menu>
  );

  const getRoutePhases = async () => {
    try {
      const { data } = await $api.get(`flows/ecmflowitems/byflow`, {
        params: {
          EntityId: documentInfo?.Id,
        },
      });
      return data;
    } catch ({ response }) {
      errorThrow(response);
    }
  };

  const onGoingToRoute = () => {
    setButtonLoader(true);
    $api
      .post(`Documents/${documentInfo?.Id}/flow/send`)
      .then(() => {
        getRoutePhases().then(phaseList => {
          successNotificationToRouteSend(phaseList);
          getDocumentInfo({ documentType, documentId, dispatch });
        });
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setButtonLoader(false));
  };

  const onRejectDocument = () => {
    $api
      .patch(`documents/${documentInfo?.Id}/byorganisation/register/reject`, {
        Comment: form.getFieldValue('Comment'),
      })
      .then(() => {
        successNotification('Документ отклонён');
        history.goBack();
        setVisibleRejectModal(false);
      })
      .catch(({ response }) => {
        errorThrow(response);
        setVisibleRejectModal(false);
      });
  };

  const onSendDelo = () => {
    $api
      .post(`documents/${documentInfo?.Id}/delosendingcontext`)
      .then(() => {
        getDocumentInfo({ documentType, documentId, dispatch });
      })
      .catch(({ response }) => {
        errorThrow(response);
      });
  };

  const sendDocumentOnIndividualControl = () => {
    $api
      .post(`Documents/${documentInfo?.Id}/control/put`)
      .then(() => {
        successNotification('Контроль успешно выдан');
        getDocumentInfo({ documentType, documentId, dispatch });
      })
      .catch(({ response }) => errorThrow(response));
  };

  const removeFromIndividualControl = () => {
    $api
      .post(`Documents/${documentInfo?.Id}/control/remove`)
      .then(() => {
        successNotification('Документ успешно снят с контроля');
        getDocumentInfo({ documentType, documentId, dispatch });
      })
      .catch(({ response }) => errorThrow(response));
  };

  useEffect(() => {
    if (!visibleRejectModal) {
      form.resetFields();
    }
  }, [form, visibleRejectModal]);

  const rejectModal = (
    <Modal
      title="Отклонить документ"
      visible={visibleRejectModal}
      onOk={() => setVisibleRejectModal(false)}
      onCancel={() => setVisibleRejectModal(false)}
      footer={null}
      closable={false}
    >
      <Form
        form={form}
        name="basic"
        onFinish={onRejectDocument}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Комментарий"
          name="Comment"
          rules={[{ required: true, message: 'Введите комментарий' }]}
        >
          <DTextarea className="w100per" />
        </Form.Item>
        <Row justify="end">
          <DButton className="mr15" small primary type="submit">
            Подтвердить
          </DButton>
          <DButton
            small
            defaultDanger
            onClick={() => setVisibleRejectModal(false)}
          >
            Отмена
          </DButton>
        </Row>
      </Form>
    </Modal>
  );

  const documentHeader = () => {
    return (
      <div className="mb15 flex-space">
        {deleteModal}
        {rejectModal}
        {visibleLinkDrawer && (
          <DocumentLinkageDrawer
            type={linkType}
            visible={visibleLinkDrawer}
            onClose={onCloseLinkDrawer}
          />
        )}
        {configureButtons?.Reject && (
          <DButton
            small
            primary
            className="mr15"
            onClick={() => setVisibleRejectModal(true)}
          >
            Отклонить документ
          </DButton>
        )}
        {configureButtons?.StartOnRoute && (
          <DButton
            className="mr15"
            primary
            small
            onClick={onGoingToRoute}
            loader={buttonLoader}
          >
            Запустить по процессу
          </DButton>
        )}
        {visibleEditDrawer && (
          <DocumentEditDrawer
            visible={visibleEditDrawer}
            onClose={onCloseEditDrawer}
          />
        )}
        {visibleSample && (
          <ASampleDocumentDrawer
            visible={visibleSample}
            onClose={onCloseSampleDrawer}
          />
        )}
        {configureButtons?.Edit && (
          <DButton
            className="mr15"
            onClick={() => setVisibleEditDrawer(true)}
            small
            primary
          >
            Обработать документ
          </DButton>
        )}
        <Dropdown
          trigger={['click']}
          overlay={additionalOperationsMenu}
          className="mr15"
        >
          <DButton small>
            Дополнительные операции <DownOutlined />
          </DButton>
        </Dropdown>
        {isShowDeloSend && (
          <DButton small primary className="mr15" onClick={onSendDelo}>
            Отправить в ДЕЛО
          </DButton>
        )}
        {documentInfo?.ControlIsCurrentUser ? (
          <Icon
            icon={openEye}
            alt="openEye"
            onClick={removeFromIndividualControl}
            tooltip="Снять с личного контроля"
          />
        ) : (
          <Icon
            icon={closeEye}
            alt="closeEye"
            onClick={sendDocumentOnIndividualControl}
            tooltip="Поставить на личный контроль"
          />
        )}
      </div>
    );
  };

  return {
    documentHeader,
  };
};
