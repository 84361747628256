import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Phases } from 'pages/AssignmentCard/types';

type PhaseSlice = {
  phases: Phases[];
};

const initialState: PhaseSlice = {
  phases: [],
};

const phaseSlice = createSlice({
  name: 'phases',
  initialState,
  reducers: {
    updatePhases(state, action: PayloadAction<Phases[]>) {
      state.phases = action.payload;
    },
  },
});

export const { updatePhases } = phaseSlice.actions;
export const phaseReducer = phaseSlice.reducer;
