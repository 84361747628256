import {
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Select,
  Checkbox,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from 'shared/components/PageHeader';
import { PreLoader } from 'shared/components/PreLoader';
import { DButton } from 'shared/ui-kit';
import { errorThrow, successNotification } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { REPORTS_PAGE } from 'shared/constants/helpCodes';
import { DownOutlined } from '@ant-design/icons';
import { ContactList } from 'pages/CreateDocument/types';
import { useForm } from 'antd/es/form/Form';
import { TReports } from '../Report';
import { useParams } from 'react-router-dom';
import { updateReport } from 'store/slice/reports';
import { ReportItemParams } from './types';

const { Title } = Typography;

export const ReportItem = () => {
  const dispatch = useDispatch();
  const { reportId } = useParams<ReportItemParams>();
  const [loader, setLoader] = useState<boolean>(false);
  const [reportInfo, setReportInfo] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<any>();
  const [controllers, setControllers] = useState<ContactList[]>([]);
  const { RangePicker } = DatePicker;
  const { report } = useSelector((state: ReduxState) => state.reportReducer);
  const isNotices = report.Key === 'DocumentsControlNotices';
  const [form] = useForm();
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  useEffect(() => {
    if (report.Key.length > 0) return;

    $api
      .get('Reports')
      .then(({ data }) => {
        const currentReport = data.find(
          (value: TReports) => value.Key === reportId,
        );

        if (currentReport) {
          dispatch(updateReport(currentReport));
        }
      })
      .catch(({ response }) => errorThrow(response));
  }, [report, reportId, dispatch]);

  const getRequestParams = useCallback(
    values => {
      return {
        ...(report.Key === 'AssignmentsForExecution'
          ? { ExecutionDate: selectedDate.toDate() }
          : {
              DateStart: selectedDate[0].toDate(),
              DateEnd: selectedDate[1].toDate(),
            }),
        ...(isNotices
          ? {
              ControllerId: values.ControllerId,
              ExpiredNotices: values.ExpiredNotices,
            }
          : {}),
      };
    },
    [isNotices, report.Key, selectedDate],
  );

  const onFinish = (values: any) => {
    setLoader(true);

    $api
      .get(`Reports/${report.Key}/html`, {
        params: getRequestParams(values),
      })
      .then(res => setReportInfo(res.data))
      .catch(({ response }) => errorThrow(response))
      .finally(() => setLoader(false));
  };

  const getControllers = useCallback(() => {
    $api
      .get('contacts/withrolecontroller')
      .then(({ data }) => setControllers(data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  useEffect(() => {
    if (isNotices) {
      getControllers();
    }
  }, [getControllers, isNotices]);

  const onDownloadFile = useCallback(
    (type: 'pdf' | 'html' | 'docx' | 'xlsx') => {
      setLoader(true);
      $api
        .get(`Reports/${report.Key}/${type}`, {
          params: getRequestParams(form.getFieldsValue()),
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${report.Value}.${type}`);
          document.body.appendChild(link);
          link.click();
          successNotification('Отчёт скачан');
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setLoader(false));
    },
    [form, getRequestParams, report.Key, report.Value],
  );

  const downloadMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => onDownloadFile('pdf')}>
        PDF файл
      </Menu.Item>
      <Menu.Item key="2" onClick={() => onDownloadFile('html')}>
        HTML файл
      </Menu.Item>
      <Menu.Item key="3" onClick={() => onDownloadFile('docx')}>
        DOCX файл
      </Menu.Item>
      <Menu.Item key="4" onClick={() => onDownloadFile('xlsx')}>
        XLSX файл
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {loader ? <PreLoader /> : null}
      <div className="page3">
        <PageHeader title={`Отчёт`} code={REPORTS_PAGE} />
        <Title level={3}>{report.Value}</Title>
        <Form
          form={form}
          name="basic"
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          className="drawer-form"
          onFinish={onFinish}
        >
          {isNotices && (
            <>
              <Form.Item label="Контролер" name="ControllerId">
                <Select
                  className="ui-select"
                  showSearch
                  optionFilterProp="children"
                >
                  {controllers
                    ?.filter(item => item.Id === user?.CurrentContact?.Id)
                    .map(item => (
                      <Select.Option value={item.Id} key={item.Id}>
                        {item.LastName} {item.FirstName} {item.MiddleName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="ExpiredNotices" valuePropName="checked">
                <Checkbox>Просроченные напоминания</Checkbox>
              </Form.Item>
            </>
          )}

          <Form.Item className="item" label="Выберите дату" name="Date">
            {report.Key === 'AssignmentsForExecution' ? (
              <DatePicker
                onChange={date => setSelectedDate(date)}
                style={{ width: '50%', height: 36, borderRadius: 8 }}
              />
            ) : (
              <RangePicker
                onChange={date => setSelectedDate(date)}
                style={{ width: '50%', height: 36, borderRadius: 8 }}
              />
            )}
            <DButton
              disabled={!selectedDate}
              type="submit"
              primary
              small
              className="mr15 ml15"
            >
              Подтвердить
            </DButton>
            {reportInfo && (
              <Dropdown trigger={['click']} overlay={downloadMenu}>
                <DButton small>
                  Скачать <DownOutlined />
                </DButton>
              </Dropdown>
            )}
          </Form.Item>
        </Form>
      </div>
      {reportInfo && (
        <div className="report-wrapper">
          <div
            className={'report-item'}
            dangerouslySetInnerHTML={{ __html: reportInfo }}
          />
        </div>
      )}
    </>
  );
};
