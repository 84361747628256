import { Tabs, Table, Empty, Button } from 'antd';
import { LinkOutlined, PrinterOutlined } from '@ant-design/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const AssignmentInformation = () => {
  const { TabPane } = Tabs;
  const history = useHistory();

  const columns = [
    {
      title: 'Дата/Время',
      key: 'Date',
      dataIndex: 'Date',
    },
    {
      title: 'Операция',
      key: 'Operation',
      dataIndex: 'Operation',
    },
    {
      title: 'Пользователь',
      key: 'User',
      dataIndex: 'User',
    },
    {
      title: 'Состояние',
      key: 'State',
      dataIndex: 'State',
    },
    {
      title: 'Доп. информация',
      key: 'Information',
      dataIndex: 'Information',
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="История изменений" key="history">
          <Table columns={columns} />
        </TabPane>
      </Tabs>
      <div className="flex-space">
        <Button danger onClick={() => history.goBack()}>
          Закрыть
        </Button>
        <div className="mt20">
          <span className="ml5">
            <PrinterOutlined /> Печать
          </span>
          <span className="ml5">
            <LinkOutlined /> Получить ссылку
          </span>
        </div>
      </div>
    </>
  );
};
