export enum TypesOrganisations {
  'Unknown' = 'Неизвестный',
  'Inner' = 'Внутренний',
  'Outside' = 'Внешний',
  'Medo' = 'МЭДО',
}

export type TOrganisationsList = {
  Id: number;
  Name?: string;
  ParentName?: string;
  Type?: string;
  CreateDate?: string;
  UpdateDate?: string;
  SendTypeId?: number;
  SendTypeName?: string;
  Email?: string;
  key?: string;
  Address?: string;
  PhoneNumber?: string;
  Ogrn?: number;
  Inn?: number;
  Kpp?: number;
  IfnsCode?: number;
  IedoCode?: string;
  SendTypes: any;
};

export type TOrganiastion = {
  Name: string;
  Id: number;
  Inn: number;
  Kpp: number;
};
