import { Drawer, Select, SelectProps, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { $api } from 'shared/utils/api';
import { DButton, DInput } from 'shared/ui-kit';
import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import { errorThrow } from 'shared/utils';
import { CurrentDocument, TDocumentLinks } from 'types/document';
import { debounce } from 'lodash-es';
import { DrawerHeader } from './DrawerHeader';
import { format } from 'date-fns';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { Icon } from 'shared/components/IconComponent';
import searchSvg from 'shared/assets/images/search.svg';
import deleteSvg from 'shared/assets/images/delete.svg';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { TOrganiastion } from 'types/typesOrganisations';

type TDocumentLinkageDrawer = {
  visible: boolean;
  onClose: (bool: boolean) => void;
  type: string;
};

const getUniqDocumentList = (
  documentList: CurrentDocument[],
  currentDocument: CurrentDocument,
) => {
  const documentLinks = currentDocument?.DocumentLinks.map(
    link => link.LinkedDocumentId,
  );

  return documentList.filter(
    linkedDocument =>
      linkedDocument.Id !== currentDocument?.Id &&
      !documentLinks?.includes(linkedDocument.Id),
  );
};

export const DocumentLinkageDrawer = ({
  visible,
  onClose,
  type,
}: TDocumentLinkageDrawer) => {
  const [organisations, setOrganisations] = useState<TOrganiastion[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<number>();
  const [documentList, setDocumentList] = useState<CurrentDocument[]>([]);
  const [documentLinks, setDocumentLinks] = useState<
    TDocumentLinks[] | undefined
  >([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const [filteredCount, setFilteredCount] = useState<number>(0);

  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const documentId = documentInfo?.Id;
  const documentType = 'Documents' + documentInfo?.Type;

  const documentTypes = [
    {
      Type: 'Inner',
      Text: 'Внутренние',
    },
    {
      Type: 'Incoming',
      Text: 'Входящие',
    },
    {
      Type: 'Outcoming',
      Text: 'Исходящие',
    },
  ];

  const getDocumentType = (type?: string) => {
    return documentTypes
      .filter(item => item.Type === type)
      .map(item => item.Text);
  };

  const updateDocuments = useCallback(
    (value?: string, page?: number, orgId?: number) => {
      if (!value) {
        return;
      }

      const organisationId = orgId || user?.CurrentContact?.OrganisationId;
      setIsLoading(true);
      $api
        .get(`documents/byorganisation/${organisationId}`, {
          params: {
            FastSearch: value,
            Page: (page && page - 1) ?? 0,
            Limit: 10,
            OrganisationId: organisationId,
          },
        })
        .then(({ data: { Data, FilteredCount } }) => {
          documentInfo &&
            setDocumentList(getUniqDocumentList(Data, documentInfo));

          setFilteredCount(FilteredCount);
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    },
    [documentInfo, user?.CurrentContact?.OrganisationId],
  );

  const onSearch = debounce(value => {
    setSearchValue(value);
    setPage(1);
    if (!value.length) {
      setDocumentList([]);
      return;
    }
    updateDocuments(value, 1, selectedOrganisation);
  }, DEBOUNCE_TIME);

  useEffect(() => {
    setDocumentLinks(documentInfo?.DocumentLinks);
  }, [documentInfo?.DocumentLinks]);

  useEffect(() => {
    if (searchValue) {
      updateDocuments(searchValue, page, selectedOrganisation);
    }
  }, [documentInfo]);

  const onCreate = (id: number) => {
    $api
      .post('DocumentLinks', {
        DocumentId: documentInfo?.Id,
        LinkedDocumentId: id,
      })
      .then(() => {
        getDocumentInfo({ documentType, documentId, dispatch });
      })
      .catch(({ response }) => errorThrow(response));
  };

  const onDelete = (id: number) => {
    $api
      .delete(`DocumentLinks/${id}`)
      .then(() => {
        getDocumentInfo({ documentType, documentId, dispatch });
      })
      .catch(({ response }) => errorThrow(response));
  };

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Тема',
      dataIndex: 'Subject',
      key: 'Subject',
    },
    {
      title: 'Рег. номер и дата',
      dataIndex: 'RegDate',
      key: 'RegDate',
      render: (date: string, item: any) => (
        <span>
          {item?.RegNumber ? `${item?.RegNumber} от` : ''}{' '}
          {`${date ? format(new Date(date), 'dd.MM.yyyy') : '-'}`}
        </span>
      ),
    },
    {
      title: 'Автор',
      dataIndex: 'AuthorId',
      key: 'AuthorId',
      render: (date: string, item: any) => (
        <span>
          {item.AuthorLastName} {item.AuthorFirstName} {item.AuthorMiddleName}
        </span>
      ),
    },
    {
      title: 'Действия',
      dataIndex: 'Action',
      key: 'Action',
      render: (text: string, item: any) => (
        <DButton small defaultPrimary onClick={() => onCreate(item.Id)}>
          Выбрать
        </DButton>
      ),
    },
  ];

  const convertLinkText = (item: TDocumentLinks) => {
    return (
      <span>
        {getDocumentType(item.DocumentType)} документ {item.RegNumber ?? 'б/н'}{' '}
        от {item.RegDate ? format(new Date(item.RegDate), 'dd.MM.yyyy') : '-'} (
        {item?.DocumentSubject})
      </span>
    );
  };

  const onChangePagination = (currentPage: number) => {
    updateDocuments(searchValue, currentPage, selectedOrganisation);
    setPage(currentPage);
  };

  useEffect(() => {
    $api
      .get('/organisations/byuser')
      .then(res => {
        setOrganisations(res.data);
        setSelectedOrganisation(user?.CurrentContact?.OrganisationId);
      })
      .catch(({ response }) => errorThrow(response));
  }, []);

  const onChangeAllowOrganisations = (value: number) => {
    setSelectedOrganisation(value);
    setPage(1);
    updateDocuments(searchValue, 1, value);
  };

  const organisationOptions: SelectProps['options'] = organisations.map(
    org => ({
      value: org.Id,
      label: org.Name,
    }),
  );

  return (
    <Drawer
      placement="right"
      onClose={() => onClose(false)}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader
          onClose={onClose}
          text={
            type === 'create'
              ? 'Создание связи с документом'
              : 'Удалить связь с документом'
          }
          code="CREATE_DOCUMENT_LINK"
        />
        <form autoComplete="off" className="drawer-form mb15">
          <DFormItemTwo label="Текущий документ">
            <span>
              {getDocumentType(documentInfo?.Type)} {documentInfo?.Subject} №
              {documentInfo?.Id} ОТ КОГО: {documentInfo?.AuthorLastName}{' '}
              {documentInfo?.AuthorFirstName} {documentInfo?.AuthorMiddleName}
            </span>
          </DFormItemTwo>
          <DFormItemTwo
            style={{ alignItems: 'baseLine' }}
            label="Связанные документы"
          >
            <div className="w100per">
              {documentLinks?.map(item => (
                <div className="flex-space">
                  <div className="mb5">
                    <p className="custom-link mb0">{convertLinkText(item)}</p>
                  </div>
                  <Icon
                    icon={deleteSvg}
                    alt="deleteSvg"
                    onClick={() => onDelete(item.Id)}
                    tooltip="Удалить"
                  />
                </div>
              ))}
            </div>
          </DFormItemTwo>
        </form>
        <div className="catalog__select-box mb15">
          <Select
            className="ui-select"
            placeholder="Искать в доступных организациях"
            value={selectedOrganisation}
            onChange={onChangeAllowOrganisations}
            options={organisationOptions}
          />
        </div>
        <div className="document-linkage__search-box">
          <DInput
            style={{ maxWidth: 630, width: '100%' }}
            icon={<Icon icon={searchSvg} alt="searchSvg" />}
            className="mb15"
            type="search"
            placeholder="Поиск"
            onChange={e => checkLengthStringForSearch(e.target.value, onSearch)}
          />
        </div>
        <Table
          loading={isLoading}
          dataSource={documentList}
          columns={columns}
          pagination={{
            onChange(page) {
              onChangePagination(page);
            },
            current: page,
            total: filteredCount,
            showTotal: () => filteredCount,
          }}
        />
      </div>
    </Drawer>
  );
};
