import { prefix } from 'api/prefix';
import axios from 'axios';
import { errorThrow, successNotification } from 'shared/utils';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { updateDisabledButton } from 'store/slice/creatingDocument';
import { AppDispatch } from 'store/store';

export const notificationToSendAssignment = (name?: string) =>
  name && successNotification(`Задача успешно выдана ${name}`);

export const sendFiles = async (
  files: any,
  documentId: number,
  assignmentId: number,
  dispatch: AppDispatch,
) => {
  let formData = new FormData();
  const headerForSendFiles = getAuthToken('auth_token');
  dispatch(updateDisabledButton(true));
  const requests = [];
  for (let i = 0; i < files.length; i++) {
    if (!files[i].hasOwnProperty('file')) {
      formData.append('FormFile', files[i]);
      formData.append('ObjectId', `${assignmentId}`);
      formData.append('ObjectType', 'Assignment');
      requests.push(
        axios.post(`${prefix}/Files`, formData, {
          headers: {
            ...headerForSendFiles,
            'Content-Type': 'multipart/form-data; ',
          },
        }),
      );
      formData.delete('FormFile');
    }
  }
  Promise.all(requests)
    .then(() => {
      successNotification('Файлы успешно отправлены');
    })
    .catch(() => {
      errorThrow({
        status: 500,
        data: {
          detail: '',
          status: 500,
          title: 'При загрузке файла произошла ошибка!',
          traceId: '500',
          type: 'Error',
          Title: 'При загрузке файла произошла ошибка!',
        },
      });
    })
    .finally(() => {
      console.log(1);
      dispatch(updateDisabledButton(false));
    });
};
