import { Pagination } from 'antd';
import {
  createDocumentLinkToArchiveCase,
  fetchArchiveSignatures,
  getArchiveCaseById,
  getDocumentsByArchiveCase,
} from 'api';
import moment from 'moment';
import { ArchiveItem, SignatureTable } from 'pages/Archive/type';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DocumentDrawer } from 'shared/components/CustomDrawers';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { CurrentDocument } from 'types/document';
import { NomenclatureDocument } from '../NomenclatureDocument';
import { DocumentSignDrawer } from './DocumentSignDrawer';
import { SignaturesTable } from './SignaturesTable';
import searchSvg from 'shared/assets/images/search.svg';
import { Icon } from 'shared/components/IconComponent';
import { debounce } from 'lodash-es';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import {
  ArchiveItemStatusList,
  ExpirationDates,
} from 'pages/Archive/constants';
import { ChangeArchiveDrawer } from '../NomenclatureDocument/ChangeArchiveDrawer';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';
import { ArchiveCaseDrawer } from '../ArchiveCaseDrawer';

export const NomenclatureItem = () => {
  const [archiveCaseInfo, setArchiveCaseInfo] = useState<ArchiveItem | null>(
    null,
  );
  const [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(
    null,
  );
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleSignDrawer, setVisibleSignDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<CurrentDocument[]>([]);
  const [documentsFilteredCount, setDocumentsFilteredCount] = useState(0);
  const [signatureTableData, setSignatureTableData] = useState<
    SignatureTable[]
  >([]);
  const [page, setPage] = useState(1);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { archiveCase, tomeId } = useParams<{
    archiveCase: string;
    tomeId: string;
  }>();
  const [changeArchiveOpen, setChangeArchiveOpen] = useState(false);

  const getSignatures = useCallback(() => {
    fetchArchiveSignatures(Number(archiveCase)).then(list =>
      setSignatureTableData(list),
    );
  }, [archiveCase]);

  useEffect(() => {
    getSignatures();
  }, [getSignatures]);

  const history = useHistory();

  const fetchDocuments = useCallback(
    (page = 1, query?: string) => {
      getDocumentsByArchiveCase(Number(archiveCase), page, query)
        .then(({ Data, FilteredCount }) => {
          setDocuments(Data);
          setDocumentsFilteredCount(FilteredCount);
        })
        .finally(() => setIsLoading(false));
    },
    [archiveCase],
  );

  const onOpenDocument = useCallback(id => {
    setSelectedDocumentId(id);
    setVisibleDocumentDrawer(true);
  }, []);

  const updateArchiveCaseById = useCallback(() => {
    if (archiveCase) {
      getArchiveCaseById(Number(archiveCase)).then(caseInfo =>
        setArchiveCaseInfo(caseInfo),
      );
      fetchDocuments();
    }
  }, [archiveCase, fetchDocuments]);

  useEffect(() => {
    updateArchiveCaseById();
  }, [updateArchiveCaseById]);

  const onAddDocument = (item: CurrentDocument) => {
    createDocumentLinkToArchiveCase({
      DocumentId: item.Id,
      ArchiveCaseId: Number(archiveCase),
    }).then(fetchDocuments);
  };

  const renderExpirationDate = () => {
    return (
      ExpirationDates.find(
        item => item.type === archiveCaseInfo?.ExpirationDateType,
      )?.title ?? 'Не задано'
    );
  };

  const onChangePagination = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    fetchDocuments(currentPage, searchValue);
  };

  const onSearch = useCallback(
    debounce(value => {
      fetchDocuments(1, value);
    }, DEBOUNCE_TIME),
    [],
  );

  const onOpenArchiveChangeDrawer = (documentId: number) => {
    setSelectedDocumentId(documentId);
    setChangeArchiveOpen(true);
  };

  const onSubmitChangeArchiveCase = useCallback(
    (NewArchiveCaseId: number) => {
      $api
        .patch(`archivecasedocuments/changearchive`, {
          CurrentArchiveCaseId: archiveCase,
          NewArchiveCaseId: NewArchiveCaseId,
          DocumentIds: [selectedDocumentId],
        })
        .then(() => {
          setChangeArchiveOpen(false);
          fetchDocuments();
        })
        .catch(({ response }) => errorThrow(response));
    },
    [archiveCase, fetchDocuments, selectedDocumentId],
  );

  return (
    <div>
      {visibleModal && (
        <ArchiveCaseDrawer
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
          onUpdateData={updateArchiveCaseById}
          drawerType="single"
          type="edit"
        />
      )}
      {changeArchiveOpen && (
        <ChangeArchiveDrawer
          visible={changeArchiveOpen}
          onClose={() => setChangeArchiveOpen(false)}
          onSubmitChangeArchiveCase={onSubmitChangeArchiveCase}
        />
      )}
      {visibleSignDrawer && (
        <DocumentSignDrawer
          visible={visibleSignDrawer}
          onClose={() => setVisibleSignDrawer(false)}
          item={archiveCaseInfo as ArchiveItem}
          onGetData={getSignatures}
        />
      )}
      {visibleDocumentDrawer && (
        <DocumentDrawer
          visible={visibleDocumentDrawer}
          onClose={() => setVisibleDocumentDrawer(false)}
          documentId={selectedDocumentId}
        />
      )}
      {visibleDrawer && (
        <CatalogDrawer
          visible={visibleDrawer}
          onClose={() => setVisibleDrawer(false)}
          type="document"
          func={(item: CurrentDocument) => onAddDocument(item)}
        />
      )}
      <DButton
        defaultDanger
        small
        onClick={() => history.push(`/archive/${tomeId}`)}
        className="mb40"
      >
        Назад
      </DButton>
      <DButton
        className="ml15"
        small
        primary
        onClick={() => setVisibleModal(true)}
      >
        Редактировать дело
      </DButton>
      <div>
        <DFormItemTwo label="Статус">
          {
            ArchiveItemStatusList.find(
              ({ status }) => status === archiveCaseInfo?.Status,
            )?.title
          }
        </DFormItemTwo>
        <DFormItemTwo label="Индекс дела(код номенклатуры)">
          {archiveCaseInfo?.NomenclatureCode ?? archiveCaseInfo?.Code}
        </DFormItemTwo>
        <DFormItemTwo label="Номер тома">{tomeId}</DFormItemTwo>
        <DFormItemTwo label="Заголовок дела (наименование раздела номенклатуры)">
          {archiveCaseInfo?.NomenclatureName ?? archiveCaseInfo?.Name}
        </DFormItemTwo>
        <DFormItemTwo label="Размер дела">
          {archiveCaseInfo?.Size}МБ
        </DFormItemTwo>
        <DFormItemTwo label="Крайние даты дела (срок хранения)">
          {renderExpirationDate()}
        </DFormItemTwo>
      </div>
      <h2 className="mt40">Документы</h2>
      <div className="archive-search">
        <DButton
          className="mr15"
          small
          primary
          onClick={() => setVisibleDrawer(true)}
        >
          Добавить документ
        </DButton>
        <DButton
          className="mr15"
          small
          defaultDanger
          onClick={() => setVisibleSignDrawer(true)}
        >
          Сформировать опись
        </DButton>
        <DInput
          icon={<Icon icon={searchSvg} alt="searchSvg" />}
          type="search"
          className="mr15 tablemenu__search-input"
          placeholder="Поиск"
          onChange={({ target: { value } }) => {
            setSearchValue(value);
            checkLengthStringForSearch(value, onSearch);
          }}
        />
      </div>
      {!isLoading && !!documents.length && (
        <div>
          {documents?.map(item => (
            <NomenclatureDocument
              onOpenArchiveChangeDrawer={onOpenArchiveChangeDrawer}
              documentInfo={item}
              onUpdateDocuments={fetchDocuments}
              onOpenDocument={onOpenDocument}
            />
          ))}
          <Pagination
            className="mt15 mb15"
            style={{ float: 'right' }}
            defaultCurrent={1}
            defaultPageSize={15}
            total={documentsFilteredCount}
            showTotal={() => documentsFilteredCount}
            current={page}
            onChange={onChangePagination}
          />
        </div>
      )}
      <SignaturesTable signatureTableData={signatureTableData} />
    </div>
  );
};
