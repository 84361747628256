import { Drawer, Skeleton } from 'antd';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { TagItem } from '../TagItem';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { useCreateTagDrawer } from './useCreateTagDrawer';

type TCreateTagDrawer = {
  visible: boolean;
  onClose: (bool: boolean) => void;
};

export const CreateTagDrawer = ({ visible, onClose }: TCreateTagDrawer) => {
  const {
    formik,
    isLoading,
    userTagList,
    updateSelectedTag,
    selectedTag,
    onSearchTag,
    searchTags,
    onCloseDrawer,
    disabledButton,
  } = useCreateTagDrawer(onClose);

  return (
    <Drawer
      placement="right"
      onClose={onCloseDrawer}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Добавить тег" />
        {isLoading ? (
          <Skeleton />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <DFormItemTwo label="Выбрать" style={{ alignItems: 'baseline' }}>
              <div className="document-tag-list">
                {userTagList.map(item => (
                  <TagItem
                    item={item}
                    selectedTag={selectedTag}
                    updateSelectedTag={updateSelectedTag}
                    type="user"
                  />
                ))}
              </div>
            </DFormItemTwo>
            <DFormItemTwo label="Найти" name="Name">
              <DInput
                width="100%"
                type="text"
                value={formik.values.Name}
                name="Name"
                onChange={e => {
                  formik.handleChange(e);
                  onSearchTag(e.target.value);
                }}
              />
            </DFormItemTwo>
            {formik.values.Name?.length && searchTags?.length ? (
              <DFormItemTwo
                label="Найденные теги"
                className="document-tags__menu"
                style={{ alignItems: 'baseline' }}
              >
                <div className="document-tag-list">
                  {searchTags.map(item => (
                    <TagItem
                      item={item}
                      selectedTag={selectedTag}
                      updateSelectedTag={updateSelectedTag}
                      type="system"
                    />
                  ))}
                </div>
              </DFormItemTwo>
            ) : null}
            <DFormItemTwo>
              <DButton disabled={disabledButton} small primary type="submit">
                Добавить
              </DButton>
            </DFormItemTwo>
          </form>
        )}
      </div>
    </Drawer>
  );
};
