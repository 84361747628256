import { Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { USER_GROUPS } from 'shared/constants/helpCodes';
import { useUserGroups } from './useUserGroups';
import { ReduxState } from 'store/store';
import { useSelector } from 'react-redux';
import { UserGroupDrawer } from './components';

export type TUserGroup = {
  Name: string;
  OrganisationId: number;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  SendTypeId?: number;
};

export const UserGroups = () => {
  const {
    columns,
    visibleDrawer,
    selectedId,
    drawerType,
    updateDrawerVisible,
    updateDrawerType,
    onFetchData,
  } = useUserGroups();
  const { userGroups, isLoading } = useSelector(
    (state: ReduxState) => state.userGroupsReducer,
  );

  return (
    <>
      {visibleDrawer && (
        <UserGroupDrawer
          visible={visibleDrawer}
          onClose={() => updateDrawerVisible(false)}
          type={drawerType}
          selectedId={selectedId as number}
          onGetData={onFetchData}
        />
      )}
      <PageHeader title="Получатели" code={USER_GROUPS} />
      <DButton
        onClick={() => {
          updateDrawerVisible(true);
          updateDrawerType('create');
        }}
        className="mb15"
        small
        primary
      >
        + Создать
      </DButton>
      <Table loading={isLoading} dataSource={userGroups} columns={columns} />
    </>
  );
};
