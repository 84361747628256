import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserGroupList = {
  Name: string;
  OrganisationId: number;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  ReceiverGroupItems?: UserReceiverGroup[];
};

export type UserReceiverGroup = {
  Id?: number;
  CreateDate?: string;
  UpdateDate?: string;
  AddresseeId: number | null;
  OrganisationId: number;
  SendTypeId: number;
  OrganisationReceiverGroupId?: number;
  OrganisationName: string;
  AddresseeFirstName: string | null;
  AddresseeMiddleName: string | null;
  AddresseeLastName: string | null;
  SendTypeName: string;
};

export type UserGroup = UserGroupList | null;

type UserGroups = {
  userGroups: UserGroupList[];
  isLoading: boolean;
  selectedItem: UserGroup;
};

const initialState: UserGroups = {
  userGroups: [],
  isLoading: false,
  selectedItem: null,
};

const userGroupsSlice = createSlice({
  name: 'userGroups',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    updateUserGroups(state, action: PayloadAction<UserGroupList[]>) {
      state.userGroups = action.payload;
    },
    updateLoader(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateUserGroup(state, action: PayloadAction<UserGroup>) {
      state.selectedItem = action.payload;
    },
  },
});

export const {
  reset,
  updateUserGroups,
  updateUserGroup,
  updateLoader,
} = userGroupsSlice.actions;
export const userGroupsReducer = userGroupsSlice.reducer;
