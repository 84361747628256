import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TDates = {
  date: string;
  holiday: boolean;
};

type TCalendar = {
  dates: TDates[];
};

const initialState: TCalendar = {
  dates: [],
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    updateCalendar(state, action: PayloadAction<TDates[]>) {
      state.dates = action.payload;
    },
  },
});

export const { updateCalendar } = calendarSlice.actions;
export const calendarReducer = calendarSlice.reducer;
