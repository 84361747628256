import { ArchiveItemStatusList } from 'pages/Archive/constants';
import { ArchiveItem } from 'pages/Archive/type';
import { Link } from 'react-router-dom';
import './ArchiveCase.scss';

export const ArchiveCase = ({ archiveCase }: { archiveCase: ArchiveItem }) => {
  return (
    <Link
      to={`/archive/${archiveCase?.TomeNumber}/archive-case/${archiveCase?.Id}`}
      className="archive-case form"
    >
      <p>Дело</p>
      <b>{archiveCase?.NomenclatureCode ?? archiveCase.Code}</b>
      <p>{archiveCase?.NomenclatureName ?? archiveCase.Name}</p>
      <p>
        {
          ArchiveItemStatusList.find(
            ({ status }) => status === archiveCase?.Status,
          )?.title
        }
      </p>
    </Link>
  );
};
