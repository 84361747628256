import { format } from 'date-fns';
import { formatStateName } from './nameStateDocuments';
import { useState } from 'react';
import { CurrentDocument } from 'types/document';
import { DocumentDrawer } from 'shared/components/CustomDrawers';

type TLinkedDocuments = {
  currentDocument: CurrentDocument | null;
};

const documentTypes = [
  {
    Type: 'Inner',
    Text: 'Внутренние',
  },
  {
    Type: 'Incoming',
    Text: 'Входящие',
  },
  {
    Type: 'Outcoming',
    Text: 'Исходящие',
  },
];

export const getDocumentType = (type: string) => {
  return documentTypes
    .filter(item => item.Type === type)
    .map(item => item.Text);
};

export const LinkedDocuments = ({ currentDocument }: TLinkedDocuments) => {
  const [selectedDocument, setSelectedDocument] = useState<number | null>(null);
  const [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState<boolean>(
    false,
  );
  const onCloseDocumentDrawer = (bool: boolean) => {
    setVisibleDocumentDrawer(bool);
  };

  return (
    <>
      {visibleDocumentDrawer && (
        <DocumentDrawer
          visible={visibleDocumentDrawer}
          onClose={onCloseDocumentDrawer}
          documentId={selectedDocument}
        />
      )}
      <ul style={{ padding: 0, marginBottom: 0 }}>
        {currentDocument?.DocumentLinks?.length
          ? currentDocument?.DocumentLinks?.map(item => (
              <li className="ui-li">
                —{' '}
                <span
                  onClick={() => {
                    setSelectedDocument(item.LinkedDocumentId);
                    setVisibleDocumentDrawer(true);
                  }}
                  className="custom-link"
                >
                  {getDocumentType(item.DocumentType)} документ{' '}
                  {item.RegNumber ?? 'б/н'} от{' '}
                  {item.RegDate && format(new Date(item.RegDate), 'dd.MM.yyyy')}{' '}
                  ({item?.DocumentSubject})
                </span>{' '}
                <br />
                <span className="ml20">{item.OrganisationName}</span>
              </li>
            ))
          : '-'}
      </ul>
    </>
  );
};
