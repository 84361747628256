import { Icon } from 'shared/components/IconComponent';
import { UserReceiverGroup } from 'store/slice/userGroups';
import deleteSvg from 'shared/assets/images/delete.svg';

export const ReceiverGroup = ({
  item,
  onRemove,
}: {
  item?: Partial<UserReceiverGroup>;
  onRemove: (item?: Partial<UserReceiverGroup>) => void;
}) => {
  return (
    <div className="flex-space">
      <div className="mb5">
        <p className="custom-link" style={{ marginBottom: 0 }}>
          {item?.OrganisationName}
        </p>
        <p className="custom-link" style={{ marginBottom: 0 }}>
          {item?.AddresseeId
            ? `${item.AddresseeLastName ?? ''} ${item.AddresseeFirstName ??
                ''} ${item.AddresseeMiddleName ?? ''}`
            : null}
        </p>
        <p style={{ marginBottom: 0 }} className="bottom-comment">
          {item?.SendTypeName}
        </p>
      </div>
      <Icon
        icon={deleteSvg}
        alt="deleteSvg"
        onClick={() => onRemove(item)}
        tooltip="Удалить"
      />
    </div>
  );
};
