import { ReactElement } from 'react';
import { CheckboxProps } from './types';
import { useBem } from '../../hooks';
import cn from 'classnames';
import './Checkbox.scss';

export const Checkbox = ({
  style,
  className,
  children,
  color = 'gray',
  size = 's30',
}: Partial<CheckboxProps>): ReactElement => {
  const bem = useBem('Checkbox');

  return (
    <label
      style={style}
      className={cn(bem(), className, bem(color), bem(size))}
    >
      {children}
      <input type="checkbox" />
      <span className="checkmark" />
    </label>
  );
};
