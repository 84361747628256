import { PaginationTypes } from 'pages/AllAssignmentsPage/types';
import { errorThrow, TError } from 'shared/utils';
import { $api } from 'shared/utils/api';
import {
  TFilterType,
  updateAssignmentList,
  updateFilteredCount,
  updateIsLoading,
} from 'store/slice/assignments';
import { SortActiveParams } from 'store/slice/table';
import { AppDispatch } from 'store/store';
import { AssignmentType } from 'types/document';

const getSortParamsToRequest = (params: SortActiveParams) => {
  const isIncludesParam = (param: string) => params.option === param;

  return {
    ByCreateDate: isIncludesParam('ByCreateDate'),
    ByCreateDateDesc: isIncludesParam('ByCreateDateDesc'),
    ByAuthorName: isIncludesParam('ByAuthorName'),
    ByAuthorNameDesc: isIncludesParam('ByAuthorNameDesc'),
    ByExecutorName: isIncludesParam('ByExecutorName'),
    ByExecutorNameDesc: isIncludesParam('ByExecutorNameDesc'),
    ByExecutorGroupName: isIncludesParam('ByExecutorGroupName'),
    ByExecutorGroupNameDesc: isIncludesParam('ByExecutorGroupNameDesc'),
    ByAssignmentId: isIncludesParam('ByAssignmentId'),
    ByAssignmentIdDesc: isIncludesParam('ByAssignmentIdDesc'),
  };
};

export const fetchAssignmentList = (
  dispatch: AppDispatch,
  queryParams: {
    type?: TFilterType;
    query?: string;
    pagination?: PaginationTypes;
  },
  sortParams?: SortActiveParams | null,
) => {
  dispatch(updateIsLoading(true));

  $api
    .get(`assignments/byuser/${queryParams.type}`, {
      params: {
        Limit: queryParams.pagination?.pageSize,
        Page: queryParams.pagination?.page && queryParams.pagination?.page - 1,
        FastSearch: queryParams.query,
        ...(sortParams
          ? {
              ...getSortParamsToRequest(sortParams),
            }
          : {}),
      },
    })
    .then(({ data: { Data, FilteredCount } }) => {
      dispatch(updateAssignmentList(Data));
      dispatch(updateFilteredCount(FilteredCount));
    })
    .catch(({ response }) => {
      errorThrow(response);
      dispatch(updateAssignmentList([]));
    })
    .finally(() => dispatch(updateIsLoading(false)));
};

export const editAssignmentStatus = async (assignment: AssignmentType) => {
  try {
    await $api.put(`assignments/${assignment.Id}`, {
      ...assignment,
      State: 'OnCompleted',
    });
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};
