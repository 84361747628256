import { useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { Descriptions } from 'antd';
import { Skeleton } from 'antd/es';
import './TableDT.scss';
import { errorThrow } from 'shared/utils';
import { Phases } from 'pages/AssignmentCard/types';

type TRoutTable = {
  documentId?: number;
};

export const RoutTable = ({ documentId }: TRoutTable) => {
  const [allPhases, setAllPhases] = useState<Phases[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (documentId)
      $api
        .get(`flows/ecmflowitems/byflow`, {
          params: {
            EntityId: documentId,
          },
        })
        .then(res => {
          setAllPhases(res.data);
          setLoading(false);
        })
        .catch(({ response }) => errorThrow(response));
  }, [documentId]);

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        allPhases.length > 0 && (
          <>
            <h2>Процесс документа</h2>
            <Descriptions column={1} className="grid-phase" bordered>
              {allPhases.map(item => (
                <Descriptions.Item label={item.Name} key={item.Id}>
                  {!item.IsGroup
                    ? item?.Contacts?.map(item => (
                        <p className="mb0">
                          {item.LastName} {item.FirstName} {item.MiddleName}
                        </p>
                      ))
                    : item?.UserGroups?.map(group => (
                        <p className="mb0">{group.Name}</p>
                      ))}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </>
        )
      )}
    </>
  );
};
