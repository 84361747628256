import { TUserTagList } from 'shared/components/CustomDrawers/FilterDrawer/types';
import './TagItem.scss';
import cn from 'classnames';

type TagItemProps = {
  item: TUserTagList;
  updateSelectedTag: (id: number) => void;
  selectedTag: number | null;
  type: 'user' | 'system';
};

export const TagItem = ({
  item,
  updateSelectedTag,
  selectedTag,
  type,
}: TagItemProps) => {
  const tagClassName =
    item.Id === selectedTag
      ? 'document-tags-item__add-active'
      : 'document-tags-item__add';
  const outsideTagClassName = type === 'system' ? 'outside-tag' : '';

  return (
    <div className="document-tag-list">
      <span
        onClick={() => updateSelectedTag(item.Id)}
        className={cn(tagClassName, outsideTagClassName)}
        key={item.Id}
      >
        {item.Name}
      </span>
    </div>
  );
};
