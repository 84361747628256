import moment from 'moment';
import cn from 'classnames';
import { Table } from 'antd';
import { MedoNotificationsByDocumentTypes } from './types';
import { useBem } from 'shared/hooks';
import { ReactElement, useState } from 'react';
import { MedoNotificationsTypes } from 'types/document';
import { ModalMedoNotification } from './ModalMedoNotification';
import './MedoNotificationsByDocument.scss';

export const MedoNotificationsByDocument = ({
  data,
}: MedoNotificationsByDocumentTypes): ReactElement => {
  const bem = useBem('MedoNotificationsByDocument');
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [currentNotification, setCurrentNotification] = useState<
    MedoNotificationsTypes | undefined
  >();

  const columns = [
    {
      title: 'Получено',
      key: 'CreateDate',
      dataIndex: 'CreateDate',
      render: (item: string) => (
        <span>{moment(item).format('DD.MM.YYYY')}</span>
      ),
    },
    {
      title: 'Вид уведомления',
      key: 'Title',
      dataIndex: 'Title',
      render: (title: string) => <span className="subject">{title}</span>,
    },
  ];

  return (
    <>
      <h2 className="mt40">Уведомления МЭДО</h2>
      <form
        name="basic"
        autoComplete="off"
        className={cn(bem(), 'drawer-form read form mb15')}
      >
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          onRow={record => ({
            onClick: () => {
              setIsVisibleModal(true);
              setCurrentNotification(record);
            },
          })}
        />
      </form>
      <ModalMedoNotification
        visible={isVisibleModal}
        notificationData={currentNotification}
        onClose={setIsVisibleModal}
      />
    </>
  );
};
