import { Checkbox, Drawer, Select, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DButton, DInput, DTextarea } from 'shared/ui-kit';
import { Moment } from 'moment';
import 'pages/AssignmentsPage/AssignmentsPage.scss';
import _ from 'lodash';
import { DatePick } from 'shared/components/DatePicker/DatePick';
import { AssignmentsTemplatesDrawer } from 'shared/components/CustomDrawers/AssignmentTemplatesDrawer';
import { ContactList } from 'pages/CreateDocument/types';
import { DrawerHeader } from '../DrawerHeader';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { CatalogDrawer } from '../CatalogDrawer';
import { AssignmentGeneralInfo } from 'pages/AssignmentCard/components/AssignmentGeneralInfo';
import cn from 'classnames';
import { LinkListTypes, TIssuanceAssignmentDrawer } from './types';
import { PLAIN_OPTIONS, TYPES_ASSIGNMENT } from './constants';
import deleteSvg from 'shared/assets/images/delete.svg';
import { Icon } from 'shared/components/IconComponent';
import { CatalogButton } from 'shared/components/CatalogButton';
import { useIssuanceAssignmentDrawer } from './useIssuanceAssignmentDrawer';
import {
  getDisabledDates,
  getResponsibleName,
  getResponsibleDepartment,
} from './utils';
import './IssuanceAssignmentDrawer.scss';
import { PreLoader } from 'shared/components/PreLoader';
import { TemplateModal } from './components/TemplateModal';
import { format } from 'date-fns';
import { UploadFiles } from 'shared/components/Files';

const { Option } = Select;

export const IssuanceAssignmentDrawer = ({
  visible,
  onClose,
  type,
  childType,
  currentAssignment,
  documentData,
  isFollowing,
}: TIssuanceAssignmentDrawer) => {
  const {
    showError,
    currentFiles,
    setCurrentFiles,
    onSelectTemplate,
    visibleTemplateDrawer,
    visibleCatalog,
    catalogType,
    onCloseDrawer,
    isLoading,
    onChangeTemplateVisible,
    formik,
    IS_SHOW_MORE_BUTTON,
    linkList,
    assignmentTypeClass,
    onSelectAssignmentType,
    getExecutorSelectProps,
    onChangeExecutor,
    onOpenCatalog,
    responsibleList,
    onRemoveExecutor,
    onRemoveLinks,
    onAddedItemFromCatalog,
    IsDisabledResponsibles,
    onChangeSubject,
    DRAWER_TITLE_CODE,
    executors,
    departments,
    onChangeCatalogVisible,
    isShowPreloader,
    responsibleDepsList,
    setVisibleTemplateModal,
    visibleTemplateModal,
    createAssignmentTemplate,
    setIsSaved,
    holidayDays,
  } = useIssuanceAssignmentDrawer({
    type,
    documentData,
    currentAssignment,
    childType,
    onClose,
    isFollowing,
  });
  const { disabledButton } = useSelector(
    (state: ReduxState) => state.creatingDocumentReducer,
  );
  const showResponsibleBySummary = () => {
    if (type === 'assignment-to-execute' && !currentAssignment) {
      return true;
    }
    if (
      (type !== 'assignment-to-execute' && !currentAssignment) ||
      formik.values.AssignmentType !== 'ToExecute'
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      {isShowPreloader && <PreLoader />}
      <section id="create-assignment">
        {visibleTemplateDrawer && (
          <AssignmentsTemplatesDrawer
            visible={visibleTemplateDrawer}
            onClose={() => onChangeTemplateVisible(false)}
            onSelectTemplate={onSelectTemplate}
          />
        )}
        {visibleCatalog && (
          <CatalogDrawer
            visible={visibleCatalog}
            onClose={() => onChangeCatalogVisible(false)}
            type={catalogType}
            func={(item: ContactList) => onAddedItemFromCatalog(item)}
          />
        )}
        {visibleTemplateModal && (
          <TemplateModal
            visible={visibleTemplateModal}
            onClose={() => setVisibleTemplateModal(false)}
            createTemplate={createAssignmentTemplate}
          />
        )}
        <Drawer
          placement="right"
          onClose={onCloseDrawer}
          visible={visible}
          closeIcon={null}
          className="drawer"
        >
          {isLoading ? (
            <Skeleton />
          ) : (
            <div className="page3">
              <DrawerHeader
                onClose={onCloseDrawer}
                text="Выдать задачу"
                code={DRAWER_TITLE_CODE}
              />
              <div className="link-sample">
                <span
                  className="custom-link"
                  onClick={() => onChangeTemplateVisible(true)}
                >
                  Заполнить по шаблону
                </span>
              </div>
              <form
                className={cn('drawer-form', 'drawer-container')}
                onSubmit={formik.handleSubmit}
              >
                <AssignmentGeneralInfo
                  className="container-document-form"
                  documentData={documentData}
                  assignment={currentAssignment}
                />
                <div className="container-form">
                  {currentAssignment && childType !== 'Delegated' ? (
                    <DFormItemTwo
                      required
                      label="Тип задачи"
                      name="AssignmentType"
                    >
                      <Select
                        className={assignmentTypeClass}
                        allowClear
                        onChange={onSelectAssignmentType}
                        value={formik.values.AssignmentType}
                      >
                        {TYPES_ASSIGNMENT.map(assignmentType => (
                          <Option
                            value={assignmentType.Type}
                            key={assignmentType.Type}
                          >
                            {assignmentType.Name}
                          </Option>
                        ))}
                      </Select>
                    </DFormItemTwo>
                  ) : null}
                  {responsibleList.length ? (
                    <DFormItemTwo label="Выбранные" name="Selected">
                      <div className="w100per">
                        {_.uniqBy(responsibleList, 'Id').map(item => (
                          <div className="flex-space">
                            <div className="mb5">
                              <p className="custom-link mb0">
                                {!item.hasOwnProperty('ShortNotation')
                                  ? getResponsibleName(item)
                                  : getResponsibleDepartment(item)}
                              </p>
                            </div>
                            <Icon
                              icon={deleteSvg}
                              alt="deleteSvg"
                              // @ts-ignore
                              onClick={() => onRemoveExecutor(true, item.Id)}
                              tooltip="Удалить"
                            />
                          </div>
                        ))}
                      </div>
                    </DFormItemTwo>
                  ) : null}
                  {/* executors */}
                  <DFormItemTwo
                    required
                    label="Исполнитель контакт"
                    name="executor"
                  >
                    <Select
                      {...getExecutorSelectProps()}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      onChange={value => onChangeExecutor(true, value)}
                      value={formik.values.executor}
                    >
                      {_.uniqBy(executors, 'Id').map(executor => (
                        <Option value={executor.Id} key={executor.Id}>
                          {getResponsibleName(executor)}
                        </Option>
                      ))}
                    </Select>
                    {IS_SHOW_MORE_BUTTON && (
                      <CatalogButton
                        onClick={() => onOpenCatalog('contactsAssignment')}
                      />
                    )}
                  </DFormItemTwo>
                  {responsibleDepsList.length ? (
                    <DFormItemTwo label="Выбранные" name="Selected">
                      <div className="w100per">
                        {_.uniqBy(responsibleDepsList, 'Id').map(item => (
                          <div className="flex-space">
                            <div className="mb5">
                              <p className="custom-link mb0">
                                {!item.hasOwnProperty('ShortNotation')
                                  ? getResponsibleName(item)
                                  : getResponsibleDepartment(item)}
                              </p>
                            </div>
                            <Icon
                              icon={deleteSvg}
                              alt="deleteSvg"
                              // @ts-ignore
                              onClick={() => onRemoveExecutor(false, item.Id)}
                              tooltip="Удалить"
                            />
                          </div>
                        ))}
                      </div>
                    </DFormItemTwo>
                  ) : null}
                  <DFormItemTwo
                    label="Исполнитель департамент"
                    name="executorGroup"
                  >
                    <Select
                      // {...getExecutorSelectProps()}
                      mode="multiple"
                      maxTagCount="responsive"
                      className="ui-select withoutItems"
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      onChange={value => onChangeExecutor(false, value)}
                      value={formik.values.executorGroup}
                    >
                      {_.uniqBy(departments, 'Id').map(department => (
                        <Option value={department.Id} key={department.Id}>
                          {department.Name}
                        </Option>
                      ))}
                    </Select>
                    {IS_SHOW_MORE_BUTTON && (
                      <CatalogButton
                        onClick={() => onOpenCatalog('departments')}
                      />
                    )}
                  </DFormItemTwo>
                  {/* executors */}
                  {showResponsibleBySummary() && (
                    <DFormItemTwo
                      label="Ответственный за свод"
                      name="responsible"
                    >
                      <Select
                        className="ui-select"
                        showSearch
                        allowClear
                        disabled={IsDisabledResponsibles}
                        optionFilterProp="children"
                        value={formik.values.responsible}
                        onChange={value =>
                          formik.setFieldValue('responsible', value)
                        }
                      >
                        {_.uniqBy(responsibleList, 'Id').map(responsible => (
                          // @ts-ignore
                          <Option value={responsible.Id} key={responsible.Id}>
                            {!responsible.hasOwnProperty('ShortNotation')
                              ? getResponsibleName(responsible)
                              : getResponsibleDepartment(responsible)}
                          </Option>
                        ))}
                      </Select>
                    </DFormItemTwo>
                  )}
                  <DFormItemTwo required label="Тема" name="Subject">
                    <DInput
                      width="100%"
                      type="text"
                      required={showError}
                      value={formik.values.Subject}
                      name="Subject"
                      minLength={3}
                      onChange={onChangeSubject}
                    />
                  </DFormItemTwo>
                  <DFormItemTwo
                    style={{ alignItems: 'baseLine' }}
                    label="Текст"
                    name="text"
                  >
                    <DTextarea
                      value={formik.values.text}
                      className="w100per"
                      name="text"
                      onChange={formik.handleChange}
                    />
                  </DFormItemTwo>
                  <DFormItemTwo
                    label="Срок исполнения"
                    required
                    name="deadlineDate"
                  >
                    <DatePick
                      style={{ height: 36, borderRadius: 8 }}
                      showTime={{ minuteStep: 30 }}
                      disabledDate={(currentDate: Moment) =>
                        getDisabledDates(currentDate, holidayDays)
                      }
                      format="DD.MM.YYYY HH:mm"
                      value={formik.values.deadlineDate}
                      placeholder="Выберите дату"
                      allowClear={false}
                      onChange={(date: Moment) => {
                        formik.setFieldValue('deadlineDate', date);
                      }}
                    />
                  </DFormItemTwo>
                  <DFormItemTwo>
                    <Checkbox.Group
                      style={{ display: 'grid' }}
                      onChange={(selectedValues: CheckboxValueType[]) => {
                        formik.setFieldValue('checkedValues', selectedValues);
                      }}
                    >
                      {currentAssignment && childType !== 'Delegated'
                        ? PLAIN_OPTIONS.map(item => (
                            <Checkbox value={item.value} key={item.value}>
                              {item.name}
                            </Checkbox>
                          ))
                        : null}
                      {!currentAssignment ? (
                        <Checkbox
                          value={PLAIN_OPTIONS[0].value}
                          key={PLAIN_OPTIONS[0].value}
                        >
                          {PLAIN_OPTIONS[0].name}
                        </Checkbox>
                      ) : null}
                    </Checkbox.Group>
                  </DFormItemTwo>
                  <DFormItemTwo
                    label="Связанные документы"
                    style={{ alignItems: 'baseLine' }}
                  >
                    <div className="w100per">
                      {linkList.map((item: LinkListTypes) => (
                        <div className="flex-space">
                          <div className="mb5">
                            <p className="custom-link mb0">
                              Документ {item.DocumentRegNumber ?? 'б/н'} от{' '}
                              {format(
                                new Date(item.DocumentRegDate),
                                'dd.MM.yyyy',
                              )}
                            </p>
                          </div>
                          <Icon
                            icon={deleteSvg}
                            alt="deleteSvg"
                            onClick={() => onRemoveLinks(item.LinkedDocumentId)}
                            tooltip="Удалить"
                          />
                        </div>
                      ))}
                      <span
                        className="custom-link"
                        onClick={() => onOpenCatalog('document')}
                      >
                        Создать связь
                      </span>
                    </div>
                  </DFormItemTwo>
                  {type !== 'assignment-for-audit' && (
                    <DFormItemTwo
                      label="Файлы"
                      name="Files"
                      className="form-item__with-list"
                    >
                      <UploadFiles
                        setCurrentFiles={setCurrentFiles}
                        currentFiles={currentFiles}
                      />
                    </DFormItemTwo>
                  )}
                  <DFormItemTwo className="form-buttons">
                    <DButton
                      small
                      className="mr15"
                      primary
                      disabled={isShowPreloader}
                      type="submit"
                    >
                      Выдать задачу
                    </DButton>
                    <DButton
                      onClick={() => setIsSaved(true)}
                      small
                      className="mr15"
                      defaultPrimary
                      disabled={disabledButton}
                      type="submit"
                    >
                      Сохранить
                    </DButton>
                    <DButton
                      small
                      defaultPrimary
                      className="mr15"
                      onClick={() => setVisibleTemplateModal(true)}
                    >
                      Создать шаблон
                    </DButton>
                  </DFormItemTwo>
                </div>
              </form>
            </div>
          )}
        </Drawer>
      </section>
    </>
  );
};
