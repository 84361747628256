import { Drawer, List } from 'antd';
import { DrawerHeader } from './DrawerHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';
import deleteSvg from 'shared/assets/images/delete.svg';
import { Icon } from '../IconComponent';

type TNoticeDrawer = {
  onClose: (bool: boolean) => void;
  visible: boolean;
};

type TNotice = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  ContactId: number;
  EntityId: number;
  Message: string;
  DocumentSubject: string;
  DocumentType: string;
  IsRead: boolean;
  DocumentAuthorLastName: string;
  DocumentAuthorFirstName: string;
  DocumentAuthorMiddleName: string;
  DocumentAuthorId: number;
};

export const getNoticeCount = async () => {
  try {
    const { data } = await $api.get('Notices/byuser/count');
    return data;
  } catch ({ response }) {
    errorThrow(response);
  }
};

export const NoticeDrawer = ({ onClose, visible }: TNoticeDrawer) => {
  const [noticeList, setNoticeList] = useState<TNotice[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [noticeCount, setNoticeCount] = useState<number>(0);

  const getNotices = useCallback(() => {
    setLoader(true);
    $api
      .get('Notices')
      .then(res => setNoticeList(res.data))
      .catch(({ response }) => errorThrow(response))
      .finally(() => setLoader(false));
    getNoticeCount().then(data => setNoticeCount(data.Count));
  }, []);

  useEffect(() => {
    getNotices();
  }, [getNotices]);

  const onRead = (item: TNotice) => {
    $api
      .patch(`Notices/${item.Id}/Read`)
      .then(() => getNotices())
      .catch(({ response }) => errorThrow(response));
  };

  const onReadAll = () => {
    $api
      .post('Notices/ReadAll')
      .then(() => getNotices())
      .catch(({ response }) => errorThrow(response));
  };

  const onDeleteAll = () => {
    $api
      .delete(`Notices`)
      .then(() => getNotices())
      .catch(({ response }) => errorThrow(response));
  };

  const onDeleteNotice = (id: number) => {
    $api
      .delete(`Notices/${id}`)
      .then(() => getNotices())
      .catch(({ response }) => errorThrow(response));
  };

  return (
    <Drawer
      placement="right"
      onClose={() => onClose(false)}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Уведомления" />
        <div className="flex-space mb15">
          <span className="ml5">
            <b>Не прочитано</b> - {noticeCount}
          </span>
          <div>
            <span
              onClick={() => onReadAll()}
              style={{ cursor: 'pointer' }}
              className="mr15"
            >
              прочитать все
            </span>
            <span
              onClick={() => onDeleteAll()}
              style={{ cursor: 'pointer' }}
              className="danger"
            >
              удалить все
            </span>
          </div>
        </div>
        <List
          loading={loader}
          itemLayout="horizontal"
          className="drawer-form"
          dataSource={noticeList}
          pagination={{
            showSizeChanger: false,
            defaultPageSize: 15,
          }}
          renderItem={item => (
            <List.Item
              className={item.IsRead ? 'notice-item' : ''}
              key={item.Id}
              actions={[
                <span
                  className={!item.IsRead ? 'notice-accept' : ''}
                  onClick={() => onRead(item)}
                  style={{ opacity: 0 }}
                >
                  <CheckOutlined />
                </span>,
                <Icon
                  icon={deleteSvg}
                  alt="deleteSvg"
                  onClick={() => onDeleteNotice(item.Id)}
                  tooltip="Удалить"
                />,
              ]}
            >
              <List.Item.Meta
                title={`Документ №${
                  item.EntityId
                } Автор: ${item.DocumentAuthorLastName ??
                  ''} ${item.DocumentAuthorFirstName ??
                  ''} ${item.DocumentAuthorMiddleName ?? ''}`}
                description={item.Message}
              />
              <div>
                <Link
                  onClick={() => onClose(false)}
                  to={`/active-documents/Documents${item.DocumentType}/${item.EntityId}`}
                >
                  {item.DocumentSubject}
                </Link>
                <br />
                {format(new Date(item.CreateDate), 'dd.MM.yyyy H:mm')}
              </div>
            </List.Item>
          )}
        />
      </div>
    </Drawer>
  );
};
