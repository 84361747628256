import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getHost } from 'shared/utils';

export type Routing = {
  data: {
    id?: string;
    documentType?: string;
    documentId?: string;
  };
};

const initialState: Routing = {
  data: {
    id: '',
    documentId: '',
    documentType: '',
  },
};

const routingSlice = createSlice({
  name: 'routing',
  initialState,
  reducers: {
    updateRoutData(state, action: PayloadAction<any>) {
      sessionStorage.setItem('port', getHost());
      sessionStorage.setItem('documentType', action.payload.documentType);
      if (action.payload.id) sessionStorage.setItem('id', action.payload.id);
      if (action.payload.documentId)
        sessionStorage.setItem('documentId', action.payload.documentId);
      state.data = action.payload;
    },
  },
});

export const { updateRoutData } = routingSlice.actions;
export const routingReducer = routingSlice.reducer;
