import { Form, Skeleton, Select, Table, Upload, UploadProps } from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { resetBreadCrumb } from 'store/slice/table';
import { errorThrow, pageRefresh, successNotification } from 'shared/utils';
import { DButton, DInput } from 'shared/ui-kit';
import { PageHeader } from 'shared/components/PageHeader';
import { ChangePasswordDrawer } from 'shared/components/CustomDrawers/ChangePasswordDrawer';
import { CreateSubstituteDrawer } from 'shared/components/CustomDrawers/CreateSubstituteDrawer';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './CardsPage.scss';
import { RcFile } from 'antd/lib/upload/interface';
import { useFormik } from 'formik';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import {
  TDepartmentInfo,
  TOfficerInfo,
  TPositionInfo,
  TSubstitutionsList,
} from './types';
import { DeleteModal } from 'shared/components';

export const OfficerCard = () => {
  const [officerInfo, setOfficerInfo] = useState<TOfficerInfo>();
  const [departmentInfo, setDepartmentInfo] = useState<TDepartmentInfo>();
  const [organisationInfo, setOrganisationInfo] = useState<TDepartmentInfo>();
  const [positionInfo, setPositionInfo] = useState<TPositionInfo>();
  const [substitutionsList, setSubstitutionsList] = useState<
    TSubstitutionsList[]
  >([]);
  const [visibleSubstitute, setVisibleSubstitute] = useState<boolean>(false);
  const [showPasswordDrawer, setShowPasswordDrawer] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [avatarLoading, setAvatarLoading] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [currentItemId, setCurrentItemId] = useState<number | undefined>();
  const [imageUrl, setImageUrl] = useState<any>();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const history = useHistory();
  const dispatch = useDispatch();
  const officerId = history.location.pathname.split('/')[2];
  const initialValues: any = {
    Id: officerId,
    FirstName: officerInfo?.FirstName,
    LastName: officerInfo?.LastName,
    MiddleName: officerInfo?.MiddleName,
    Email: officerInfo?.Email,
    DepartmentId: officerInfo?.DepartmentId,
    PositionId: officerInfo?.PositionId,
    PhoneNumber: officerInfo?.PhoneNumber,
    IsHead: officerInfo?.IsHead,
    Esign: officerInfo?.Esign,
    Timezone: officerInfo?.Timezone,
    InterfaceLanguage: officerInfo?.InterfaceLanguage,
  };

  const onClosePasswordDrawer = (bool: boolean) => setShowPasswordDrawer(bool);

  const onCloseSubstituteDrawer = (bool: boolean) => setVisibleSubstitute(bool);

  const onDeleteSubstitute = (id?: number) => {
    $api
      .delete(`accounts/substitutions/${id}`)
      .then(() => getSubstitutionList())
      .catch(({ response }) => errorThrow(response));
  };

  const substitutionColumns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
      render: (text: string, item: TSubstitutionsList) => (
        <span>
          {item.LastName} {item.FirstName} {item.MiddleName}
        </span>
      ),
    },
    {
      title: 'Должность',
      dataIndex: 'PositionName',
      key: 'PositionName',
    },
    {
      title: 'Срок замещения',
      dataIndex: 'ExpiryDate',
      key: 'ExpiryDate',
      render: (date: string | null) => (date ? date.slice(0, 10) : '-'),
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: TSubstitutionsList) => (
        <DButton
          small
          defaultDanger
          onClick={() => {
            setVisibleModal(true);
            setCurrentItemId(item?.Id);
          }}
        >
          Удалить
        </DButton>
      ),
    },
  ];

  const getSubstitutionList = useCallback(() => {
    $api
      .get(`Contacts/${officerId}/Substitutions`)
      .then(res => setSubstitutionsList(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const avatarUrl = (link: string) =>
    window.URL.createObjectURL(new Blob([link]));

  const getAvatar = useCallback(() => {
    setAvatarLoading(true);
    $api
      .get(`Contacts/${officerId}/avatar/download/MaxSize`, {
        responseType: 'blob',
      })
      .then(res => {
        setImageUrl(avatarUrl(res.data));
        setAvatarLoading(false);
      })
      .catch(({ response }) => errorThrow(response));
  }, [officerId]);

  const generateAvatar = useCallback(() => {
    setAvatarLoading(true);
    $api
      .put(`Contacts/${officerId}/avatar/generate`)
      .then(res => {
        setImageUrl(avatarUrl(res.data));
        setAvatarLoading(false);
        getAvatar();
      })
      .catch(({ response }) => errorThrow(response));
    setAvatarLoading(false);
  }, [getAvatar, officerId]);

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onSendNewInfo(values);
    },
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      resetBreadCrumb([
        {
          path: `/officer-card/${officerId}`,
          title: 'Личный кабинет',
        },
      ]),
    );
    getSubstitutionList();
    $api
      .get(`/Contacts/${officerId}/byorganization`)
      .then(res => {
        setOfficerInfo(res.data);
        getAvatar();
        for (let value in res.data) {
          formik.setFieldValue(value, res.data[value]);
        }
        if (res.data.OrganisationId)
          $api
            .get(`Organisations/${res.data.OrganisationId}`)
            .then(res => setOrganisationInfo(res.data))
            .catch(({ response }) => errorThrow(response))
            .finally(() => setIsLoading(false));
        if (res.data.DepartmentId) {
          $api
            .get(`Departments/${res.data.DepartmentId}/byorganization`)
            .then(department => {
              setDepartmentInfo(department.data);
            });
        } else {
          setIsLoading(false);
        }
        if (res.data.PositionId) {
          $api
            .get(`Positions/${res.data.PositionId}`)
            .then(position => {
              setPositionInfo(position.data);
            })
            .catch(({ response }) => errorThrow(response));
        }
      })
      .catch(({ response }) => errorThrow(response));
  }, [dispatch, form, getAvatar, getSubstitutionList, officerId]);

  const onSendNewInfo = (values: TOfficerInfo) => {
    $api
      .put('accounts/contacts', {
        ...officerInfo,
        Id: officerId,
        FirstName: values.FirstName,
        LastName: values.LastName,
        MiddleName: values.MiddleName,
        Email: values.Email,
        DepartmentId: officerInfo?.DepartmentId,
        PositionId: officerInfo?.PositionId,
        PhoneNumber: values?.PhoneNumber,
        IsHead: officerInfo?.IsHead,
        Esign: values?.Esign,
        InterfaceLanguage: values.InterfaceLanguage,
        Timezone: values.Timezone,
      })
      .then(() => {
        successNotification('Данные успешно изменены');
        pageRefresh();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const uploadButton = (
    <div>
      {avatarLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Изменить</div>
    </div>
  );

  const props: UploadProps = {
    name: 'FormFile',
    multiple: true,
    customRequest: ({ file }) => {
      const formData = new FormData();
      formData.append('FormFile', file);
      formData.append('AvatarSizeType', 'MaxSize');
      $api
        .put(`Contacts/${officerId}/avatar`, formData)
        .then(() => getAvatar())
        .catch(({ response }) => {
          errorThrow(response);
          setAvatarLoading(false);
        });
    },
    beforeUpload(file: RcFile) {
      setAvatarLoading(true);
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      return isJpgOrPng;
    },
    listType: 'picture-card',
    showUploadList: false,
  };

  return (
    <section id="officer-card">
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          {showPasswordDrawer && (
            <ChangePasswordDrawer
              type="own"
              onClose={onClosePasswordDrawer}
              visible={showPasswordDrawer}
            />
          )}
          {visibleSubstitute && (
            <CreateSubstituteDrawer
              visible={visibleSubstitute}
              onClose={onCloseSubstituteDrawer}
              getData={getSubstitutionList}
            />
          )}
          <PageHeader title="Личный кабинет" />
          <form className="form" onSubmit={formik.handleSubmit}>
            {/* закомментировал пока т.к. надо было скрыть этот функционал согласно задаче DKS-2315 */}
            {/*<DFormItemTwo>*/}
            {/*  <Upload {...props}>*/}
            {/*    {imageUrl ? (*/}
            {/*      <img*/}
            {/*        style={{ width: 200, height: 200 }}*/}
            {/*        src={imageUrl}*/}
            {/*        alt="avatar"*/}
            {/*      />*/}
            {/*    ) : (*/}
            {/*      uploadButton*/}
            {/*    )}*/}
            {/*  </Upload>*/}
            {/*  <DButton small primary onClick={generateAvatar}>*/}
            {/*    Сгенерировать аватар*/}
            {/*  </DButton>*/}
            {/*</DFormItemTwo>*/}
            <DFormItemTwo label="Организация" name="Organisation">
              <span>{organisationInfo?.Name}</span> <br />
            </DFormItemTwo>
            <DFormItemTwo label="Подразделение" name="department">
              <span>{departmentInfo?.Name}</span> <br />
            </DFormItemTwo>
            <DFormItemTwo label="Должность" name="Position">
              <span>{positionInfo?.Name}</span> <br />
            </DFormItemTwo>
            <DFormItemTwo label="Логин">
              <span>{user?.CurrentUser?.Login}</span>
              <span
                className="ml20 custom-link"
                onClick={() => setShowPasswordDrawer(true)}
              >
                Сменить пароль
              </span>
            </DFormItemTwo>
            <DFormItemTwo label="Фамилия" name="LastName">
              <DInput
                width="100%"
                type="text"
                value={formik.values.LastName}
                name="LastName"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo label="Имя" name="FirstName">
              <DInput
                width="100%"
                type="text"
                value={formik.values.FirstName}
                name="FirstName"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo label="Отчество" name="MiddleName">
              <DInput
                width="100%"
                type="text"
                value={formik.values.MiddleName}
                name="MiddleName"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo label="Электронная почта" name="Email">
              <DInput
                width="100%"
                type="text"
                value={formik.values.Email}
                name="Email"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo label="Телефон" name="PhoneNumber">
              <DInput
                width="100%"
                type="text"
                value={formik.values.PhoneNumber}
                name="PhoneNumber"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo label="Эл. подпись" name="Esign">
              <DInput
                width="100%"
                type="text"
                value={formik.values.Esign}
                name="Esign"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo className="form-buttons">
              <DButton small primary type="submit">
                Сохранить
              </DButton>
            </DFormItemTwo>
            <div className="flex-space">
              <h2>Заместители</h2>
              {user?.Permissions.find(
                item => item.Entity === 'user.substitution',
              ) ? (
                <DButton
                  small
                  defaultPrimary
                  onClick={() => setVisibleSubstitute(true)}
                >
                  Добавить замещение
                </DButton>
              ) : null}
            </div>
            <Table
              pagination={{ position: [] }}
              dataSource={substitutionsList}
              columns={substitutionColumns}
            />
          </form>
        </>
      )}
      <DeleteModal
        title="Удаление замещения"
        description="Вы уверены что хотите удалить замещение?"
        visible={visibleModal}
        setVisible={setVisibleModal}
        onDelete={() => {
          setVisibleModal(false);
          onDeleteSubstitute(currentItemId);
        }}
      />
    </section>
  );
};
