import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useBreadcrumb } from 'shared/hooks/useBreadcrumb';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { permissionExist } from 'shared/utils/isExistPermission';
import { updateRoutData } from 'store/slice/routing';
import { updateDocumentKindId, User } from 'store/slice/table';
import { ReduxState } from 'store/store';
import { PermissionsAccess } from 'types/permissions';
import { TOrganisationsList } from 'types/typesOrganisations';
import {
  fetchDocumentKindsWithPermission,
  fetchDocumentKindsWithOutPermission,
} from './api';
import { TTreeChildren, TTreeData } from './types';
import { getPageTitle } from './utils';

export const useCreateRedirectPage = () => {
  const [treeList, setTreeList] = useState<TTreeData[]>([]);
  const [organisationList, setOrganisationList] = useState<
    TOrganisationsList[]
  >([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<
    number | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { updateBreadcrumb } = useBreadcrumb();
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { linkishId } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const canShowOrganisations = permissionExist(
    user?.Permissions as User['Permissions'],
    PermissionsAccess.StreamsByOrganisation,
  );

  const fetchData = useCallback(() => {
    if (canShowOrganisations && selectedOrganisation) {
      fetchDocumentKindsWithPermission(selectedOrganisation).then(list => {
        if (list) {
          setTreeList(list);
        }

        setIsLoading(false);
      });
    } else {
      fetchDocumentKindsWithOutPermission().then(list => {
        if (list) {
          setTreeList(list);
        }

        setIsLoading(false);
      });
    }
  }, [canShowOrganisations, selectedOrganisation]);

  const fetchOrganisations = useCallback(() => {
    $api
      .get('counterparties/byorganization')
      .then(({ data }) => setOrganisationList(data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  useEffect(() => {
    if (canShowOrganisations) {
      fetchOrganisations();
    }
  }, [canShowOrganisations, fetchOrganisations]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSelect = useCallback(
    (item: TTreeChildren) => {
      updateBreadcrumb(
        history,
        dispatch,
        `/create/${item.DocumentType}`,
        item.DocumentKindName,
        {
          docType: item.DocumentType,
        },
      );
      const selectedOrganisationName = organisationList.find(
        item => item.Id === selectedOrganisation,
      )?.Name;

      dispatch(
        updateDocumentKindId({
          Name: item.DocumentKindName,
          Id: item.DocumentKindId,
          FlowId: item.FlowId,
          FlowName: item.FlowName,
          DocumentStreamId: item.Id,
          OrganisationId:
            selectedOrganisation ?? user?.CurrentContact?.OrganisationId,
          OrganisationName:
            selectedOrganisationName ?? user?.CurrentContact?.OrganisationName,
        }),
      );
      dispatch(
        updateRoutData({
          documentType: item.DocumentType,
        }),
      );
    },
    [
      updateBreadcrumb,
      history,
      dispatch,
      selectedOrganisation,
      user?.CurrentContact?.OrganisationId,
      user?.CurrentContact?.OrganisationName,
      organisationList,
    ],
  );

  const updateItem = useCallback(
    (item: TTreeData) => {
      const newTreeList = treeList.map(listItem =>
        listItem.key === item.key ? item : listItem,
      );

      setTreeList(newTreeList);
    },
    [treeList],
  );

  return {
    pageTitle: getPageTitle(linkishId),
    isLoading,
    treeList,
    onSelect,
    updateItem,
    setSelectedOrganisation,
    organisationList,
    canShowOrganisations,
    fetchData,
  };
};
