import { TDInput } from './types';
import './index.scss';
import {
  convertClasses,
  convertStyles,
} from 'shared/utils/getClassesAndStyles';
import { InputHTMLAttributes } from 'react';

/**
 * @placeholder string
 * @icon string, ReactElement;
 * @type string;
 * @onChange func;
 * @value string;
 * @disabled boolean;
 * @width any;
 * @style any;
 * @className string, object of string;
 */
export const DInput = ({
  placeholder,
  icon,
  type,
  onChange,
  value,
  disabled,
  width,
  style,
  className,
  required,
  name,
  minLength,
  ...props
}: TDInput) => {
  return (
    <div
      style={convertStyles([width && { width: width }, style])}
      className={convertClasses(
        required && !value?.length ? 'DInput-zero_length' : 'DInput',
        className,
        disabled,
      )}
    >
      <span className="DInput-icon">
        {icon && typeof icon === 'string' ? (
          <img src={icon} />
        ) : (
          icon && typeof icon === 'object' && icon
        )}
      </span>
      <input
        name={name}
        style={icon ? { paddingLeft: 13 } : { paddingLeft: 0 }}
        onChange={e => onChange && onChange(e)}
        disabled={disabled}
        value={value}
        type={type}
        min={0}
        minLength={minLength}
        max={999999999999999}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};
