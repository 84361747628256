import { Folder } from 'types/folder';
import { v4 as uuidv4 } from 'uuid';

export const mockSidebarData: Folder[] = [
  {
    Name: 'Документы',
    Id: uuidv4(),
    link: 'active-documents/page=1',
    childrens: [
      {
        Name: 'Поиск по документам',
        Id: 21,
        link: 'searching/page=1',
        childrens: [],
        parentId: 'active-documents',
      },
    ],
  },
  {
    Name: 'Задачи',
    Id: uuidv4(),
    link: 'assignments',
  },
  {
    Name: 'Архив',
    Id: uuidv4(),
    childrens: [],
    link: 'archive',
    key: 'archive',
  },
  {
    Name: 'Контрольные документы',
    Id: uuidv4(),
    childrens: [],
    link: 'control-documents/page=1',
    key: 'control',
  },
  {
    Name: 'Личный кабинет руководителя',
    Id: uuidv4(),
    link: 'statistics',
    key: 'chief',
  },
  {
    Name: 'Отчёты',
    Id: uuidv4(),
    link: 'reports',
    key: 'report',
  },
  {
    Name: 'Администрирование',
    Id: uuidv4(),
    key: 'admin.organisation',
    childrens: [
      {
        Name: 'Реквизиты организации',
        Id: 23,
        link: 'requisites',
        parentId: 'admin',
      },
      {
        Name: 'Подразделения',
        Id: 24,
        link: 'departments',
        parentId: 'admin',
      },
      {
        Name: 'Группы получателей',
        Id: 25,
        link: 'usergroups',
        parentId: 'admin',
      },
      {
        Name: 'Сотрудники',
        Id: 26,
        link: 'officers',
        parentId: 'admin',
      },
      {
        Name: 'Виды документов',
        Id: 27,
        link: 'document-kinds',
        parentId: 'admin',
      },
      {
        Name: 'Номенклатура',
        Id: 28,
        link: 'nomencaltures',
        parentId: 'admin',
      },
      {
        Name: 'Контрагенты',
        Id: 29,
        link: 'counterparties',
        parentId: 'admin',
      },
      {
        Name: 'Процессы',
        Id: 30,
        link: 'process',
        parentId: 'admin',
      },
      {
        Name: 'Организации',
        Id: 31,
        link: 'organisations',
        parentId: 'admin',
        key: 'organisation.create',
      },
      // закомментировал пока т.к. надо было скрыть этот функционал согласно задаче DKS-2315
      // {
      //     Name: 'Шаблоны документов',
      //     Id: 31,
      //     link: 'document-templates',
      //     parentId: 'admin',
      // },
      // {
      //     Name: 'Лицензия',
      //     Id: 32,
      //     link: 'license',
      //     parentId: 'admin',
      // },
      {
        Name: 'Регистрация документа',
        Id: 32,
        link: 'registration',
        parentId: 'admin',
      },
      {
        Name: 'Документопоток',
        Id: 33,
        link: 'document-flow',
        parentId: 'admin',
      },
    ],
    link: 'admin',
  },
];
