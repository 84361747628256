import { Button, DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { $api } from 'shared/utils/api';
import { useBreadcrumb } from 'shared/hooks/useBreadcrumb';

type TExecutor = {
  Id: number;
  ControlId: number;
  ContactId: number;
};

type TExecutorContact = {
  DepartmentId: number;
  IsHead: boolean;
  Esign: string;
  PositionId: number;
  FirstName: string;
  LastName: string;
  MiddleName: null | string;
  PhoneNumber: null | string;
  Email: null | string;
  Type: string;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
};

type TPropsParams = {
  onFinish: any;
  match: {
    url: string;
  };
};
export const ReminderForm = ({ onFinish, match }: TPropsParams) => {
  const [controlExecutors, setControlExecutors] = useState<TExecutorContact[]>(
    [],
  );
  const { Option } = Select;
  const { closeBreadcrumbs } = useBreadcrumb();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    $api.get('ControlExecutors').then(res => {
      res.data.map((executor: TExecutor) => {
        $api.get(`InnerContacts/${executor.ContactId}`).then(response => {
          if (!controlExecutors.length)
            setControlExecutors(prevState => [...prevState, response.data]);
        });
      });
    });
  }, []);

  return (
    <Form
      name="basic"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      autoComplete="off"
      className="form"
    >
      {match.url !== '/time-extension' && match.url !== '/mark-execution' && (
        <Form.Item label="Исполнители" name="Executor">
          <Select
            defaultActiveFirstOption
            mode="multiple"
            size="large"
            className="w250"
            placeholder="Выберите исполнителя"
            maxTagCount="responsive"
            allowClear
          >
            {controlExecutors.map(executor => (
              <Option
                key={`${executor.Id}_${executor.PositionId}`}
                value={executor.Id}
              >
                {executor.LastName} {executor.FirstName} {executor.MiddleName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label={
          match.url !== '/mark-execution'
            ? 'Контрольный срок'
            : 'Дата исполнения'
        }
        name="deadline"
      >
        <DatePicker
          style={{ height: 36, borderRadius: 8 }}
          className="w250"
          format="DD-MM-YYYY HH:mm"
          placeholder="Выберите дату и время"
          showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
        />
      </Form.Item>
      {(match.url === '/time-extension' || match.url === '/mark-execution') && (
        <Form.Item label="Причина" name="Reason">
          <Select
            placeholder="Выберите причину"
            showSearch
            optionFilterProp="children"
          >
            <Option value={123}>123</Option>
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label={
          match.url !== '/time-extension' && match.url !== '/mark-execution'
            ? 'Содержание'
            : 'Комментарий'
        }
        name="Content"
      >
        <Input.TextArea showCount maxLength={4000} />
      </Form.Item>
      <Form.Item className="form-buttons mt20">
        <Button className="button" type="primary" htmlType="submit">
          Сохранить
        </Button>
        <Button
          danger
          onClick={() => {
            history.goBack();
            closeBreadcrumbs(dispatch, history.location.pathname);
          }}
        >
          Отмена
        </Button>
      </Form.Item>
    </Form>
  );
};
