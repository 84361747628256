import _ from 'lodash';
import moment from 'moment';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import {
  CONTACT_PRESETS,
  DATE_PRESETS,
  formattedFields,
  initialValues,
  KEY,
  VALUE,
} from './constants';
import { TInitialValues, SearchBySettings, InitialValuesKeys } from './types';

export const fetchUrl = async (url: string) => {
  try {
    const { data } = await $api.get(url);
    return data;
  } catch ({ response }) {
    errorThrow(response);
  }
};

export const formatConfigObject = (config: TInitialValues) => {
  let newStringUrl = '';
  for (const [name, value] of Object.entries(config)) {
    for (const [formatSend, formatRecive] of formattedFields) {
      if (name === formatRecive && value) {
        (value as number[])?.forEach(item => {
          newStringUrl += `${formatSend}=${item}&`;
        });
      }
    }
  }

  return newStringUrl;
};

export const checkIsP = (newInitialValues: TInitialValues) => {
  const obj: TInitialValues = {};

  for (const newValue in newInitialValues) {
    if (initialValues.hasOwnProperty(newValue)) {
      // @ts-ignore
      obj[newValue] = newInitialValues[newValue];
    }
  }
  return obj;
};

export const updateDatePreset = (config: TInitialValues) => {
  const updatedDates = { ...DATE_PRESETS };
  // TODO: переделать
  if (config.StartControlDate) {
    updatedDates.ExecutionTime = [
      moment(config.StartControlDate),
      moment(config.EndControlDate),
    ];
  }
  if (config.StartInRegDate) {
    updatedDates.ProceedRegistrationDate = [
      moment(config.StartInRegDate),
      moment(config.EndInRegDate),
    ];
  }
  if (
    (config.StartRegDate || config.EndRegDate) ??
    config.registration_date?.[1]
  ) {
    const startValue = config.StartRegDate
      ? moment(config.StartRegDate)
      : undefined;

    if (config.EndRegDate) {
      updatedDates.registration_date = [
        startValue as moment.Moment,
        moment(config.EndRegDate),
      ];
    } else {
      updatedDates.registration_date = [
        startValue as moment.Moment,
        moment(config.registration_date?.[1]),
      ];
    }
  }
  if (config.StartCreateDate) {
    updatedDates.create_date = [
      moment(config.StartCreateDate),
      moment(config.EndCreateDate),
    ];
  }

  return updatedDates;
};

export const updateContacts = (config: TInitialValues) => {
  const availableContacts = CONTACT_PRESETS.filter((item: string) => {
    return Object.keys(config).includes(item);
  }).map((item: string) => [item, (config as Record<string, number>)[item]]);

  return availableContacts;
};

const prepareConfigValuesForUpdate = (values: TInitialValues) => {
  const startEndKeysCollection: Record<InitialValuesKeys, string[]> = {
    registration_date: ['StartRegDate', 'EndRegDate'],
    ExecutionTime: ['StartControlDate', 'EndControlDate'],
    ProceedRegistrationDate: ['StartInRegDate', 'EndInRegDate'],
    create_date: ['StartCreateDate', 'EndCreateDate'],
  };
  return Object.keys(startEndKeysCollection).reduce(
    (acc: Record<string, Date | undefined>, key) => {
      const currentValue = values[key as InitialValuesKeys];

      if (typeof currentValue === 'object' && currentValue?.length) {
        const [startKey, endKey] = startEndKeysCollection[
          key as InitialValuesKeys
        ];
        acc[startKey] = currentValue[KEY]?.toDate();
        acc[endKey] = currentValue[VALUE]?.toDate();
      }

      return acc;
    },
    {},
  );
};

export const getConfigToUpdate = (values: TInitialValues) => {
  const config = {
    Limit: 15,
    Page: 0,
    ...values,
    // AuthorId: undefined,
    // ControllerId: undefined,
    // AddresseeId: undefined,
    // ExecutorId: undefined,
    // CorrespondentId: undefined,
    // OrganisationReceiverId: undefined,
    // SenderId: undefined,
    ...prepareConfigValuesForUpdate(values),
    WithByName: values.searchBy?.includes(SearchBySettings.BY_NAME),
    WithBySummary: values.searchBy?.includes(SearchBySettings.BY_SUMMARY),
  };

  return config;
};

export const updateSearchBy = (config: TInitialValues) => {
  let value: any = {};

  if (config[SearchBySettings.BY_NAME]) {
    value.searchBy = [SearchBySettings.BY_NAME];
  }

  if (config[SearchBySettings.BY_SUMMARY]) {
    value.searchBy = [...value.searchBy, SearchBySettings.BY_SUMMARY];
  }
  return _.isEmpty(value) ? {} : { ...value.searchBy };
};

export const updateSelectedTags = (config: TInitialValues) => {
  return config?.TagIds;
};
