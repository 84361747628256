import { Drawer, Skeleton } from 'antd';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow, successNotification } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DrawerHeader } from './DrawerHeader';
import { TOrganisationsList } from './FilterDrawer/types';

type TOrganisationAddressDrawer = {
  visible: boolean;
  onClose: () => void;
  item?: TOrganisationsList;
  getData: () => void;
};

type TOrganisaitonInfo = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  OrganisationId: number;
  Index: string;
  Region: string;
  District: string;
  City: string;
  Locality: string;
  Street: string;
  House: string;
  Building: string;
  Office: string;
};

export const OrganisationAddressDrawer = ({
  visible,
  onClose,
  item,
  getData,
}: TOrganisationAddressDrawer) => {
  const [organisationInfo, setOrganisationInfo] = useState<TOrganisaitonInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasAddress, setHasAddress] = useState<boolean>(false);

  const initialValues = {
    Index: organisationInfo?.Index,
    Region: organisationInfo?.Region,
    District: organisationInfo?.District,
    City: organisationInfo?.City,
    Locality: organisationInfo?.Locality,
    Street: organisationInfo?.Street,
    House: organisationInfo?.House,
    Building: organisationInfo?.Building,
    Office: organisationInfo?.Office,
  };

  const checkHasAddress = useCallback(() => {
    if (item?.Id)
      $api
        .get(`organisations/checkhasaddress/${item?.Id}`)
        .then(res => setHasAddress(res.data))
        .catch(({ response }) => console.error(response));
  }, [item?.Id]);

  const getOrganisaitonAddress = useCallback(() => {
    $api
      .get(`organisationaddresses/byorganization`)
      .then(res => {
        setOrganisationInfo(res.data[0]);
        for (let value in res.data[0]) {
          formik.setFieldValue(value, res.data[0][value]);
        }
      })
      .catch(({ response }) => console.error(response))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getOrganisaitonAddress();
    checkHasAddress();
  }, [checkHasAddress, getOrganisaitonAddress]);

  const onFinish = useCallback(
    (values: any) => {
      const requestType = hasAddress ? $api.put : $api.post;
      requestType('OrganisationAddresses/byorganization', {
        ...values,
        OrganisationId: item?.Id,
        Id: organisationInfo?.Id,
      })
        .then(() => {
          getOrganisaitonAddress();
          getData();
          onClose();
          successNotification(
            `Реквизиты организации ${hasAddress ? 'сохранены' : 'созданы'}`,
          );
        })
        .catch(({ response }) => errorThrow(response));
    },
    [
      getData,
      getOrganisaitonAddress,
      hasAddress,
      item?.Id,
      onClose,
      organisationInfo?.Id,
    ],
  );

  const onDelete = (id?: number) => {
    $api
      .delete(`OrganisationAddresses/${id}`)
      .then(() => {
        successNotification('Реквизиты удалены');
        onClose();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      className="drawer"
      closeIcon={null}
    >
      <div className="page3">
        <DrawerHeader
          onClose={onClose}
          text={`${
            hasAddress ? 'Редактирование' : 'Создание'
          } адреса организации`}
        />
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <form className="drawer-form" onSubmit={formik.handleSubmit}>
              <DFormItemTwo label="Индекс" name="Index">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.Index}
                  name="Index"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Регион" name="Region">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.Region}
                  name="Region"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Район" name="District">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.District}
                  name="District"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Город" name="City">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.City}
                  name="City"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Населенный пункт" name="Locality">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.Locality}
                  name="Locality"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Улица" name="Street">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.Street}
                  name="Street"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Дом" name="House">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.House}
                  name="House"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Строение" name="Building">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.Building}
                  name="Building"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Офис" name="Office">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.Office}
                  name="Office"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo className="form-buttons">
                <DButton small primary className="mr15" type="submit">
                  {hasAddress ? 'Сохранить' : 'Создать'}
                </DButton>
                {hasAddress && (
                  <DButton
                    small
                    defaultDanger
                    className="mr15"
                    onClick={() => onDelete(organisationInfo?.Id)}
                  >
                    Удалить
                  </DButton>
                )}
              </DFormItemTwo>
            </form>
          </>
        )}
      </div>
    </Drawer>
  );
};
