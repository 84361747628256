import moment from 'moment';
import {
  TPhaseContacts,
  Phases,
  TPHaseUserGroup,
} from 'pages/AssignmentCard/types';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import '../CreateDocument.scss';
import deleteSvg from 'shared/assets/images/delete.svg';
import { Icon } from 'shared/components/IconComponent';

export type TResponsibles = {
  FirstName?: string | null;
  LastName?: string | null;
  MiddleName?: string | null;
  PhaseId?: number | null;
  PositionName?: string | null;
  ResponsibleId?: number | null;
  Id?: number | null;
  ContactId?: number | null;
};

type TPhaseItem = {
  phase: Phases;
  updatePhase: (item: Phases) => void;
};

export const PhaseItem = ({ phase, updatePhase }: TPhaseItem) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [responsibles, setResponsibles] = useState<TPhaseContacts[]>();
  const [userGroup, setUserGroup] = useState<TPHaseUserGroup[] | null>([]);

  const ESTIMATION = phase?.Estimation;
  const DATE_FORMAT = 'DD.MM.YYYY, HH:mm';
  console.log(userGroup);
  const onClose = () => setVisible(false);

  const checkIsPhaseContacts = (
    node: TPhaseContacts | TPHaseUserGroup,
  ): node is TPhaseContacts => {
    return (node as TPhaseContacts).ContactId !== undefined;
  };

  const onRemoveResponsible = (node: TPHaseUserGroup | TPhaseContacts) => {
    if (!phase.IsGroup && checkIsPhaseContacts(node)) {
      const newContacts = phase?.Contacts?.filter(
        item => item.ContactId !== node.ContactId,
      );
      setResponsibles(newContacts);

      updatePhase({
        ...phase,
        Contacts: !newContacts?.length ? [] : newContacts,
      });
    } else {
      const newDepartments = phase?.UserGroups?.filter(
        // @ts-ignore
        item => item.Id !== node.Id,
      );
      updatePhase({
        ...phase,
        Contacts: [],
        UserGroups: newDepartments ? newDepartments : [],
      });
    }
  };

  useEffect(() => {
    setUserGroup(phase?.UserGroups);
    setResponsibles(
      phase.Contacts?.map(item => ({ ...item, PhaseId: phase.Id })),
    );
  }, [phase.Contacts, phase.Id, phase?.UserGroups]);

  const updateItem = (item: any, type: 'add' | 'remove') => {
    const isExist = responsibles?.find(t => t.ContactId === item.Id);
    if (type === 'add') {
      if (!phase.IsGroup) {
        const newItem = {
          ...item,
          ContactId: item?.Id,
          PhaseId: phase?.PhaseId ?? phase?.Id,
        };
        if (isExist) return;
        else {
          setResponsibles(prev => (prev ? [...prev, newItem] : [newItem]));
        }
        const contacts = phase?.Contacts ? phase.Contacts : [];
        updatePhase({
          ...phase,
          Contacts: [...contacts, newItem],
        });
      } else {
        setUserGroup(prev => {
          return Array.isArray(prev) ? [...prev, item] : [item];
        });
        const departments = phase.UserGroups ? phase.UserGroups : [];
        updatePhase({
          ...phase,
          Contacts: null,
          UserGroups: [...departments, item],
        });
      }
    } else {
      onRemoveResponsible(item);
    }
  };

  const getDefaultList = useCallback(() => {
    if (!phase.IsGroup) {
      return responsibles;
    }

    if (userGroup) {
      return userGroup;
    }

    return [];
  }, [phase.IsGroup, responsibles, userGroup]);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      {visible && (
        <CatalogDrawer
          visible={visible}
          onClose={onClose}
          type="assignment"
          defaultList={getDefaultList()}
          func={updateItem}
          phase={phase}
        />
      )}
      <div className="phase-step__wrapper mb10">
        <div className="phase-step__title mb10">
          <div>
            {phase.Name ?? phase.PhaseName}
            <span
              className={phase?.IsRequired ? 'form-item__label-required' : ''}
            />
          </div>
          <span className="custom-link" onClick={() => setVisible(true)}>
            править
          </span>
        </div>
        {!phase.IsGroup
          ? responsibles?.map(item => (
              <div className="phase-step__inner">
                <div className="phase-step__inner-responsibles mb5">
                  <div className="phase-step__inner-responsibles__name">
                    {item?.LastName} {item?.FirstName} {item?.MiddleName}
                  </div>
                  <div className="phase-step__inner-responsibles__position">
                    {item?.PositionName}
                  </div>
                </div>
                <Icon
                  icon={deleteSvg}
                  alt="deleteSvg"
                  onClick={() => onRemoveResponsible(item)}
                  tooltip="Удалить"
                />
              </div>
            ))
          : userGroup?.map(group => (
              <div className="phase-step__inner">
                <div className="phase-step__inner-responsibles mb5">
                  <div className="phase-step__inner-responsibles__name">
                    {group?.Name}
                  </div>
                </div>
                {userGroup ? (
                  <Icon
                    icon={deleteSvg}
                    alt="deleteSvg"
                    onClick={() => onRemoveResponsible(group)}
                    tooltip="Удалить"
                  />
                ) : null}
              </div>
            ))}
        <div className="phase-step__estimation mt15">
          {ESTIMATION
            ? moment()
                .add(ESTIMATION, 'hours')
                .format(DATE_FORMAT)
            : moment().format(DATE_FORMAT)}
        </div>
      </div>
    </div>
  );
};
