import { getActiveCount } from 'api/activeCount/getActiveCount';
import { getFiltersSidebar } from 'api/filtersSidebar/filtersSidebar';
import { mockSidebarData } from 'mock/sidebarData';
import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBreadcrumb } from 'shared/hooks/useBreadcrumb';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { updateRoutData } from 'store/slice/routing';
import {
  updateCurrentOrganisation,
  updateOrganisationsByUser,
} from 'store/slice/sidebar';
import {
  TSelectedFilter,
  updateButtonType,
  updateDataCount,
  updateFilteredDocuments,
  updateSearchValue,
  updateVisibleSetting,
} from 'store/slice/table';
import { AppDispatch, ReduxState } from 'store/store';
import { Folder } from 'types/folder';
import { MOCK_CHILDS, NUMBER_TO_UNIQ_FILTER } from './constants';
import { updateTableData } from 'pages/MainPage/MainPage';
import { updateInfoByLinkishDocument } from 'store/slice/document';
import {
  updateAssignmentSearchValue,
  updateFilterType,
} from 'store/slice/assignments';
import { updatePhases } from 'store/slice/phases';
import { useHistory } from 'react-router-dom';
import { reset } from 'store/slice/creatingDocument';

export const getActiveNumbers = (dispatch: AppDispatch, data: any) => {
  const requests = data.map((item: any) =>
    $api
      .get(`documents/byorganisation/${item.Id}/register/count`, {
        params: {
          OrganisationId: item.Id,
        },
      })
      .catch(({ response }) => console.error(response)),
  );
  Promise.all(requests).then((res: any) => {
    const array = data.map((item: any, index: any) => {
      return {
        Id: item.Id,
        Count: res[index]?.data?.Count,
      };
    });
    dispatch(updateOrganisationsByUser(array));
  });
};

export const useSidebar = () => {
  const [sidebarItemsAPI, setSidebarItemsAPI] = useState<Folder[]>([]);
  const [selectedCollapse, setSelectedCollapse] = useState<
    string | null | number
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { user, tablePagination, searchValue } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const { reloadBreadcrumb } = useBreadcrumb();
  const { filtersList } = useSelector(
    (state: ReduxState) => state.sidebarReducer,
  );
  const { substituateId } = useSelector(
    (state: ReduxState) => state.authReducer,
  );
  const { filteredDocuments } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const permissionList = user?.Permissions.map(item => item.Entity);

  useEffect(() => {
    getFiltersSidebar({ dispatch });
  }, [user?.CurrentContact?.Id, dispatch]);

  const updateFilterChilds = useCallback(
    (array: Folder[]) => {
      return array.map(item => {
        if (item.link?.includes('active-documents/')) {
          const childrens = item.childrens?.map((child: any) =>
            child.link.includes('searching/')
              ? {
                  ...child,
                  childrens: filtersList.map((item: TSelectedFilter) => ({
                    Name: item?.FilterName,
                    Id: item.Id && item?.Id + NUMBER_TO_UNIQ_FILTER,
                    link: item.Id,
                    parentId: 'searching',
                  })),
                }
              : child,
          );
          return { ...item, childrens };
        } else return item;
      });
    },
    [filtersList],
  );

  const getDataWithChilds = useCallback(
    (data: Folder[]) => {
      const childsByPermission = MOCK_CHILDS.filter(item =>
        permissionList?.some(perm => item.permission === perm),
      );
      const newArray = data.map(item => ({
        ...item,
        childrens: childsByPermission.map(child => ({
          ...child,
          parentId: item.Id,
        })),
      }));
      return newArray;
    },
    [permissionList],
  );

  const getDataByPermission = useCallback(
    (data: Folder[]) => {
      const newArray = data.filter(item =>
        item.hasOwnProperty('key')
          ? permissionList?.find(t => t === item.key)
          : item,
      );

      return newArray.map(item => {
        if (item.key === 'admin.organisation') {
          return {
            ...item,
            childrens: item?.childrens?.filter(
              child =>
                !child?.key ||
                permissionList?.find(permission => permission === child.key),
            ),
          };
        }
        return item;
      });
    },
    [permissionList],
  );

  const concatMockWithData = (data: Folder[], mock: Folder[]) => [
    ...data.slice(0, 2),
    ...mock,
    ...data.slice(2),
  ];

  const getSidebar = useCallback(() => {
    if (permissionList) {
      setIsLoading(true);
      $api
        .get('Organisations/byuser')
        .then(res => {
          if (
            user?.Permissions.find(
              item => item.Entity === 'document.registration',
            )
          ) {
            getActiveNumbers(dispatch, res.data);
          }
          const organisationList = getDataWithChilds(res.data);
          const arrayWithAccess = getDataByPermission(mockSidebarData);
          const concatValue = concatMockWithData(
            arrayWithAccess,
            organisationList,
          );
          setSidebarItemsAPI(updateFilterChilds(concatValue));
          getActiveCount({ dispatch });
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    }
  }, [
    dispatch,
    getDataByPermission,
    getDataWithChilds,
    permissionList,
    updateFilterChilds,
    user?.Permissions,
  ]);

  useEffect(() => {
    getSidebar();
  }, [substituateId, permissionList?.length, filtersList]);

  const updateActiveAtomClass = useCallback(
    (item: Folder) => {
      dispatch(updateAssignmentSearchValue(''));
      dispatch(updateFilterType('executor'));
      dispatch(updateButtonType('inWork'));
      dispatch(updatePhases([]));
      dispatch(reset());
      if (!item.link) {
        sessionStorage.setItem('uniqNumberAtom', String(item.Id));
        sessionStorage.setItem('uniqItem', '0');
        reloadBreadcrumb(dispatch, history, {
          path: `/active-documents/page=1`,
          title: item.Name,
        });
        sessionStorage.setItem('uniqNumberAtom', '1');
      } else {
        reloadBreadcrumb(dispatch, history, {
          path: `/${item.link}`,
          title: item.Name,
        });
        sessionStorage.setItem('uniqNumberAtom', String(item.Id));
        sessionStorage.setItem('uniqItem', '0');
        if (
          sessionStorage.getItem('sidebarData') !== history.location.pathname
        ) {
          sessionStorage.setItem('sidebarData', String(item.link));
          dispatch(
            updateFilteredDocuments({
              ...filteredDocuments,
              config: {},
            }),
          );
          dispatch(updateInfoByLinkishDocument(null));
          dispatch(updateSearchValue(''));
          dispatch(
            updateDataCount({
              totalCount: 0,
              page: 1,
              pageSize: 15,
            }),
          );
        }
      }
    },
    [dispatch, filteredDocuments, history, reloadBreadcrumb],
  );

  const ITEM_LINK = (item: Folder) => item.link;

  const resetFilteredDocuments = (item: Folder) => {
    if (sessionStorage.getItem('sidebarData') !== history.location.pathname) {
      sessionStorage.setItem('sidebarData', String(ITEM_LINK(item)));
      dispatch(
        updateFilteredDocuments({
          data: [],
          filteredCount: 0,
          config: {},
        }),
      );
    }
  };

  const onClickHeaderItem = (item: Folder) => {
    dispatch(updateInfoByLinkishDocument(null));
    if (ITEM_LINK(item)?.includes('searching/')) {
      history.push('/searching/page=1');
      sessionStorage.setItem('uniqItem', '0');
      dispatch(updateButtonType('inWork'));
      resetFilteredDocuments(item);
    }
    if (ITEM_LINK(item)?.includes('active-documents')) {
      dispatch(updateAssignmentSearchValue(''));
      dispatch(updateFilterType('executor'));
      dispatch(updatePhases([]));
      dispatch(reset());
      sessionStorage.setItem('uniqNumberAtom', '1');
      sessionStorage.setItem('uniqItem', '0');
      dispatch(updateSearchValue(''));
      reloadBreadcrumb(dispatch, history, {
        path: `/${ITEM_LINK(item)}`,
        title: item.Name,
      });
      dispatch(
        updateDataCount({
          totalCount: 0,
          page: 1,
          pageSize: 15,
        }),
      );
    }
    if (item.Id === selectedCollapse) {
      setSelectedCollapse(null);
      sessionStorage.removeItem('lastCollapse');
    } else {
      setSelectedCollapse(item.Id);
      sessionStorage.setItem('lastCollapse', String(item.Id));
    }
  };

  const updateSelectedCollapse = (id: number) => {
    setSelectedCollapse(id);
  };

  const updateActiveHeaderClass = (isHead: boolean, item: Folder) => {
    if (ITEM_LINK(item)?.includes('active-documents')) {
      return isHead ? 'arrow-bottom' : 'active';
    }
    if (selectedCollapse === item.Id) {
      return isHead ? 'arrow-bottom' : 'active';
    }
    return isHead ? 'arrow-up' : 'noactive';
  };

  const updateClassOfDocumentLink = (item: Folder) => {
    if (!ITEM_LINK(item)?.includes('active-documents')) {
      return 'sidebar__topItemNoActive';
    }
    if (sessionStorage.getItem('uniqNumberAtom') === '1') {
      return 'sidebar__topItemActive';
    }
    return 'sidebar__topItemNoActive';
  };

  const changeSubAtomChildClass = (child: Folder) => {
    sessionStorage.setItem('uniqItem', child.Id + String(child.parentId));
    sessionStorage.setItem('uniqNumberAtom', '0');
  };

  const pathOfSearchOfChild = (child: Folder) => {
    if (
      child.childrens?.length ||
      (typeof child.link !== 'number' && child?.link?.includes('searching/'))
    ) {
      return `/${child.link}`;
    }

    if (
      typeof child?.link === 'string' &&
      child?.link?.includes('searching-by-tag')
    ) {
      return `/${child.link}`;
    }

    if (typeof child?.link === 'number') {
      return `/${child.parentId}/${child.link}/page=1`;
    }

    return `/${child.parentId}/${child.link}`;
  };

  const onClickSubAtomChild = (child: Folder) => {
    changeSubAtomChildClass(child);
    dispatch(updateInfoByLinkishDocument(null));
    dispatch(updateAssignmentSearchValue(''));
    dispatch(updateFilterType('executor'));
    dispatch(updatePhases([]));
    dispatch(reset());
    if (child.parentId) {
      dispatch(updateCurrentOrganisation(Number(child.parentId)));
    }
    resetFilteredDocuments(child);
    if (child.link === 'searching/page=1') {
      dispatch(updateVisibleSetting(true));
    }
    sessionStorage.setItem('sidebarData', pathOfSearchOfChild(child));
    reloadBreadcrumb(dispatch, history, {
      path: pathOfSearchOfChild(child),
      title: child.Name,
      id: `${child.parentId}`,
      docType: child.link,
    });
    dispatch(updateSearchValue(''));
    dispatch(
      updateDataCount({
        totalCount: 0,
        page: 1,
        pageSize: 15,
      }),
    );
    dispatch(
      updateRoutData({
        id: child.parentId,
        documentType: child.link,
      }),
    );
  };

  const onClickTitle = () => {
    dispatch(updateButtonType('inWork'));
    dispatch(updateInfoByLinkishDocument(null));
    dispatch(reset());
    updateTableData({
      type: 'Documents/byuser/work',
      dispatch,
      tablePagination: tablePagination,
      searchValue: searchValue,
    });
  };

  return {
    sidebarItemsAPI,
    isLoading,
    updateActiveAtomClass,
    onClickHeaderItem,
    updateSelectedCollapse,
    selectedCollapse,
    updateActiveHeaderClass,
    updateClassOfDocumentLink,
    onClickSubAtomChild,
    onClickTitle,
  };
};
