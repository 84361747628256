import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Drawer, Select } from 'antd';
import { FormValues, TypesDelegateAssignmentDrawer } from './types';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { AssignmentGeneralInfo } from 'pages/AssignmentCard/components/AssignmentGeneralInfo';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';
import { ContactList } from 'pages/CreateDocument/types';
import _ from 'lodash';
import { DButton, DInput } from 'shared/ui-kit';
import { useFormik } from 'formik';
import './DelegateAssignmentDrawer.scss';
import { useHistory } from 'react-router';
import { CatalogButton } from 'shared/components/CatalogButton';
import { AssignmentType } from 'types/document';
import { TDepartment } from 'pages/AdminPage/Departments';
import {
  permissionExist,
  PermissionsAccess,
} from 'shared/utils/isExistPermission';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';

export const DelegateAssignmentDrawer = ({
  visible,
  onClose,
  currentAssignment,
  documentData,
}: TypesDelegateAssignmentDrawer) => {
  const history = useHistory();
  const [executors, setExecutors] = useState<ContactList[]>([]);
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [departments, setDepartments] = useState<TDepartment[]>([]);
  const [catalogType, setCatalogType] = useState<
    'contacts' | 'document' | 'departments'
  >('contacts');
  const { Option } = Select;
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const {
    ExecutorLastName,
    ExecutorFirstName,
    ExecutorMiddleName,
    ExecutionGroupId,
    ExecutionGroupName,
    ExecutorId,
  } = currentAssignment as AssignmentType;

  const getExecutorName = (executor: ContactList) => {
    return `${executor.LastName ?? ''} ${executor.FirstName ??
      ''} ${executor.MiddleName ?? ''} (${executor?.PositionName ??
      ''} ${executor?.DepartmentName ?? ''})`;
  };

  const defaultExecutor = () => {
    if (ExecutionGroupId) {
      return ExecutionGroupName;
    }

    if (ExecutorId) {
      return `${ExecutorLastName ?? ''} ${ExecutorFirstName ??
        ''} ${ExecutorMiddleName ?? ''}`;
    }
  };

  const getDepartments = useCallback(() => {
    $api
      .get(`departments/byorganization`)
      .then(res => setDepartments(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const getExecutors = useCallback(() => {
    $api
      .get('Contacts/byorganization/full')
      .then(res => setExecutors(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const initialValues: FormValues = {
    Id: currentAssignment?.Id,
    NewExecutorId: undefined,
    ExecutionType: ExecutorId ? 'contacts' : 'departments',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });
  const isContactType = formik.values.ExecutionType === 'contacts';

  useEffect(() => {
    if (isContactType) {
      getExecutors();
    } else {
      getDepartments();
    }
  }, [getDepartments, getExecutors, isContactType]);

  const nodeIsContactType = (
    node: ContactList | TDepartment,
  ): node is ContactList => {
    return (node as ContactList).FirstName !== undefined;
  };

  const updateExecutor = (item: ContactList | TDepartment) => {
    if (isContactType && nodeIsContactType(item)) {
      setExecutors(prev => [...prev, item]);
    } else {
      setDepartments(prev => [...prev, item as TDepartment]);
    }
    formik.setFieldValue('NewExecutorId', item.Id);
  };

  const onFinish = (values: FormValues) => {
    const requestParams =
      values.ExecutionType === 'contacts'
        ? { NewExecutorId: values.NewExecutorId }
        : { NewExecutionGroupId: values.NewExecutorId };

    $api
      .patch(`assignments/${values?.Id}/delegate`, null, {
        params: requestParams,
      })
      .then(() => history.go(0))
      .catch(({ response }) => errorThrow(response));
  };

  return (
    <section id="create-assignment">
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={() => setVisibleCatalog(false)}
          type={catalogType}
          func={updateExecutor}
        />
      )}
      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        closeIcon={null}
        className="drawer"
      >
        <div className="page3">
          <DrawerHeader
            code="GIVE_ASSIGNMENT_DELEGATED"
            onClose={onClose}
            text="Делегировать задачу"
          />
          <form
            className={cn('drawer-form', 'drawer-container')}
            onSubmit={formik.handleSubmit}
          >
            <AssignmentGeneralInfo
              className="container-document-form"
              documentData={documentData}
            />
            <div className="container-form">
              <DFormItemTwo label="Исполнитель" name="Id">
                <DInput type="text" value={defaultExecutor()} disabled />
              </DFormItemTwo>
              <DFormItemTwo label="Тип исполнителя" name="ExecutionType">
                <Select
                  showSearch
                  className="ui-select"
                  optionFilterProp="children"
                  value={formik.values.ExecutionType}
                  onSelect={(value: string) => {
                    formik.setFieldValue('ExecutionType', value);
                    formik.setFieldValue('NewExecutorId', undefined);
                  }}
                >
                  <Option value="contacts">Пользователь</Option>
                  <Option value="departments">Департамент</Option>
                </Select>
              </DFormItemTwo>
              <DFormItemTwo
                required
                label="Новый исполнитель"
                name="NewExecutorId"
              >
                <Select
                  className="assignment-select, ui-select"
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  value={formik.values.NewExecutorId}
                  onChange={value => {
                    formik.setFieldValue('NewExecutorId', value);
                  }}
                >
                  {isContactType
                    ? _.uniqBy(executors, 'Id').map(executor => (
                        <Option key={executor.Id} value={executor.Id}>
                          {getExecutorName(executor)}
                        </Option>
                      ))
                    : _.uniqBy(departments, 'Id').map(department => (
                        <Option key={department.Id} value={department.Id}>
                          {department.Name}
                        </Option>
                      ))}
                </Select>
                {user?.Permissions &&
                  permissionExist(
                    user?.Permissions,
                    PermissionsAccess.AssignmentExtContacts,
                  ) && (
                    <CatalogButton
                      onClick={() => {
                        setVisibleCatalog(true);
                        setCatalogType(
                          isContactType ? 'contacts' : 'departments',
                        );
                      }}
                    />
                  )}
              </DFormItemTwo>
              <DFormItemTwo>
                <DButton small className="mr15" primary type="submit">
                  Делегировать задачу
                </DButton>
              </DFormItemTwo>
            </div>
          </form>
        </div>
      </Drawer>
    </section>
  );
};
