import { Form, Select, Space, Spin, Checkbox } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { PlusCircleOutlined } from '@ant-design/icons';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'react-flow-renderer';
import { useForm } from 'antd/lib/form/Form';
import { ContactList } from 'pages/CreateDocument/types';
import { DButton, DInput } from 'shared/ui-kit';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { TDepartment } from 'pages/AdminPage/Departments';
import { errorThrow } from 'shared/utils';
import { CatalogDrawer } from '../CatalogDrawer';
import { debounce } from 'lodash-es';
import { TPhaseLinks, TPhaseSteps } from './types';
import { executionType, phaselinksColor, phaseTypes } from './constants';
import './PhaseSteps.scss';
import { useBem } from 'shared/hooks';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';

const onInit = (reactFlowInstance: any) =>
  console.log('flow loaded:', reactFlowInstance);

export const PhaseSteps = ({ route }: TPhaseSteps) => {
  const [phaseLinks, setPhaseLinks] = useState<TPhaseLinks[]>([]);
  const [phases, setPhases] = useState<any>([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isExist, setIsExist] = useState<boolean>(false);
  const [selectedEdge, setSelectedEdge] = useState<any>();
  const [selectedNode, setSelectedNode] = useState<any>();
  const [menuType, setMenuType] = useState<'phase' | 'phaseLink'>('phase');
  const [selectedType, setSelectedType] = useState<string>('');
  const [executors, setExecutors] = useState<ContactList[]>([]);
  const [departments, setDepartments] = useState<TDepartment[]>([]);
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [catalogType, setCatalogType] = useState<'contacts' | 'departments'>(
    'contacts',
  );
  const [flowLoading, setFlowLoading] = useState<boolean>(false);
  const [currentType, setCurrentType] = useState<string>('');
  const [form] = useForm();
  const { Option } = Select;
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const bem = useBem('PhaseSteps');
  const disableSelectContact =
    selectedType === 'Author' ||
    selectedType === 'AuthorLeader' ||
    selectedType === 'Addresseee' ||
    selectedType === 'Correspondent';

  const getDepartments = useCallback(() => {
    $api
      .get('Departments/byorganization')
      .then(res => setDepartments(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const onCloseCatalog = () => setVisibleCatalog(false);

  const onConnect = (params: any) => {
    setEdges(eds => addEdge(params, eds));
    $api
      .post('flowitemlinks', {
        FlowId: route?.Id,
        Name: null,
        Description: null,
        OrganisationId: user?.CurrentContact?.OrganisationId,
        Source: Number(params.source),
        Color: 'Unknown',
        Target: Number(params.target),
      })
      .then(() => getPhaseLinks())
      .catch(({ response }) => errorThrow(response));
  };

  const getPhases = useCallback(() => {
    $api
      .get(`flows/${route?.Id}/flowitems`)
      .then(res => {
        setPhases(res.data);
        const nodes = res.data?.map((item: any) => {
          return {
            id: `${item?.Id}`,
            data: {
              label: <>{item.Name}</>,
            },
            position: {
              x: item.PositionX,
              y: item.PositionY,
            },
          };
        });
        setNodes(nodes);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => {
        setFlowLoading(false);
      });
  }, [route?.Id, setNodes]);

  const getPhaseLinks = useCallback(() => {
    setFlowLoading(true);
    setPhaseLinks([]);
    $api
      .get(`flows/${route?.Id}/flowitemlinks`)
      .then(res => {
        setPhaseLinks(res.data);
        const edges = res.data?.map((item: any) => {
          return {
            id: `el${item?.Source}-${item?.Target}`,
            source: `${item?.Source}`,
            target: `${item?.Target}`,
            label: item.Name,
            labelBgPadding: [8, 4],
            labelBgBorderRadius: 4,
            labelBgStyle: { fill: item.Color, color: '#fff', fillOpacity: 0.7 },
          };
        });
        setEdges(edges);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setFlowLoading(false));
  }, [route?.Id]);

  const getExecutors = useCallback((value?: string) => {
    $api
      .get(`contacts/active/byorganization/page`, {
        params: {
          FastSearch: value,
        },
      })
      .then(res => setExecutors(res.data.Data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  useEffect(() => {
    getPhases();
    getPhaseLinks();
    getExecutors();
    getDepartments();
  }, [getDepartments, getExecutors, getPhaseLinks, getPhases]);

  const onDelete = useCallback(() => {
    if (menuType === 'phase')
      $api
        .delete(`flowitems/${selectedNode?.Id}/byorganization`)
        .then(() => {
          getPhases();
          form.resetFields();
        })
        .catch(({ response }) => errorThrow(response));
    else
      $api
        .delete(`flowitemlinks/${selectedEdge?.Id}/byorganization`)
        .then(() => {
          getPhaseLinks();
          form.resetFields();
        })
        .catch(({ response }) => errorThrow(response));
  }, [menuType, selectedNode?.Id, selectedEdge?.Id]);

  const onSelectNode = (node: any) => {
    form.resetFields();
    const currentNode = phases.find((item: any) => item.Id == node.id);
    setSelectedNode({
      ...currentNode,
      PositionX: Math.ceil(node.position.x),
      PositionY: Math.ceil(node.position.y),
    });
    setSelectedType(currentNode?.Data?.ExecutionType ?? '');
    setMenuType('phase');
    if (currentNode) setIsExist(true);
    else setIsExist(false);
    setCurrentType(currentNode.Type);
    form.setFieldsValue({
      Name: currentNode.Name,
      ExecutionType: currentNode?.Data?.ExecutionType,
      Executions:
        currentNode.Data?.ExecutionType === 'User' &&
        currentNode?.Data?.Executions?.length
          ? currentNode?.Data?.Executions
          : currentNode.Data?.ExecutionType === 'Department'
          ? currentNode.Data.ExecutionGroups
          : undefined,
      Estimation: currentNode?.Data?.Estimation,
      IsRequired: currentNode?.Data?.IsRequired,
      SigningRequired: currentNode?.Data?.SigningRequired,
      Type: currentNode.Type,
    });
  };

  useEffect(() => {
    setIsExist(true);
  }, [phases?.length]);

  const findCurrentNode = (item: any) => {
    const currentNode = phases.find((t: any) => t.Id == item.id);
    setSelectedNode(currentNode);
    setCurrentType(currentNode.Type);
    form.setFieldsValue({
      Name: currentNode.Name,
      ExecutionType: currentNode?.Data?.ExecutionType,
      Executions:
        currentNode.Data?.ExecutionType === 'User' &&
        currentNode?.Data?.Executions?.length
          ? currentNode?.Data?.Executions
          : currentNode.Data?.ExecutionType === 'Department'
          ? currentNode.Data.ExecutionGroups
          : undefined,
      Estimation: currentNode?.Data?.Estimation,
      IsRequired: currentNode?.Data?.IsRequired,
      SigningRequired: currentNode?.Data?.SigningRequired,
      Type: currentNode.Type,
    });
  };

  const onSelectEdge = (edge: any) => {
    form.resetFields();
    const currentEdge = phaseLinks.find(
      item => item?.Source == edge.source && item?.Target == edge.target,
    );
    setIsExist(true);
    setSelectedEdge(currentEdge);
    setMenuType('phaseLink');
    form.setFieldsValue({
      Name: currentEdge?.Name,
      Description: currentEdge?.Description,
      Color: currentEdge?.Color,
    });
  };

  const updateData = useCallback(
    values => {
      setFlowLoading(true);
      $api
        .put(`flows/bpmflowitem/${values.Id}/data`, {
          Id: values.Id,
          Data: {
            Executions: selectedType === 'User' ? values.Executions : [],
            ExecutionType: values?.ExecutionType,
            Estimation: values.Estimation,
            IsRequired: values?.IsRequired,
            SigningRequired: values?.SigningRequired,
            ExecutionGroups:
              selectedType === 'Department' ? values.Executions : null,
          },
        })
        .then(() => getPhases())
        .catch(({ response }) => {
          errorThrow(response);
          setFlowLoading(true);
        });
    },
    [getPhases, selectedType],
  );

  const updateNodePosition = (node: any) => {
    const currentNode = phases.find((item: any) => item.Id == node.id);
    setFlowLoading(true);
    const newNodeValues = {
      ...currentNode,
      PositionX: Math.ceil(node.position.x),
      PositionY: Math.ceil(node.position.y),
    };
    setSelectedNode(newNodeValues);
    $api
      .put('flowitems/byorganization', {
        ...newNodeValues,
      })
      .then(() => getPhases())
      .catch(({ response }) => {
        errorThrow(response);
        setFlowLoading(true);
      });
  };

  const onFinish = (values: any) => {
    if (menuType === 'phaseLink') {
      if (selectedEdge?.FlowId)
        $api
          .put('flowitemlinks/byorganization', {
            ...selectedEdge,
            FlowId: route?.Id,
            Name: values.Name,
            Description: values.Description,
            Color: values.Color,
          })
          .then(() => getPhaseLinks())
          .catch(({ response }) => errorThrow(response));
    } else {
      if (selectedNode)
        $api
          .put('flowitems/byorganization', {
            ...selectedNode,
            Name: values.Name,
            Type: values.Type,
          })
          .then(res => {
            updateData({ ...values, Id: res.data.Id });
          })
          .catch(({ response }) => errorThrow(response));
      else
        $api
          .post('flowitems/byorganization', {
            FlowId: route?.Id,
            Name: values.Name,
            Type: 'Unknown',
            PositionX: phases.length
              ? Math.ceil(phases[phases.length - 1].PositionX + 50)
              : 0,
            PositionY: phases.length
              ? Math.ceil(phases[phases.length - 1].PositionY + 50)
              : 50,
          })
          .then(res => {
            updateData({ ...values, Id: res.data.Id });
          })
          .catch(({ response }) => errorThrow(response));
    }
  };

  const onCreatePhase = () => {
    form.resetFields();
    setFlowLoading(true);
    $api
      .post('flowitems/byorganization', {
        FlowId: route?.Id,
        Name: '',
        Type: 'Unknown',
        PositionX: phases.length ? phases[phases.length - 1].PositionX + 50 : 0,
        PositionY: phases.length
          ? phases[phases.length - 1].PositionY - 50
          : -50,
      })
      .then(res => {
        getPhases();
        setSelectedNode(res.data);
        setCurrentType(res.data.Type);

        form.setFieldsValue({
          Name: res.data.Name,
          ExecutionType: res.data?.Data?.ExecutionType,
          Executions: res.data?.Data?.Executions?.length
            ? res.data?.Data?.Executions
            : undefined,
          Type: res.data.Type,
          IsRequired: res.data?.IsRequired,
          Estimation: res.data?.Data?.Estimation,
          SigningRequired: res.data?.SigningRequired,
        });
      })
      .catch(({ response }) => {
        errorThrow(response);
        setFlowLoading(true);
      });
  };

  const onAddExecutor = (id: number) => {
    // TODO: selectedType в enum в глобальную область
    if (selectedType === 'User') {
      $api
        .get(`contacts/${id}`)
        .then(res => setExecutors(prev => [...prev, res.data]))
        .catch(({ response }) => errorThrow(response));
    } else {
      $api
        .get(`departments/${id}`)
        .then(res => setDepartments(prev => [...prev, res.data]))
        .catch(({ response }) => errorThrow(response));
    }
    onCloseCatalog();
  };

  const onSearch = debounce(value => {
    getExecutors(value);
  }, DEBOUNCE_TIME);

  const showRequiredStage =
    currentType !== 'EventStart' && currentType !== 'EventEnd';

  const showEsignedState =
    currentType === 'TaskReview' ||
    currentType === 'TaskApproval' ||
    currentType === 'TaskSigning';

  return (
    <>
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={onCloseCatalog}
          func={onAddExecutor}
          type={catalogType}
        />
      )}
      <Spin spinning={flowLoading}>
        <div className={bem()}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={onInit}
            fitView
            attributionPosition="top-right"
            style={{ height: 700 }}
            onNodeClick={(e, item) => onSelectNode(item)}
            onEdgeClick={(e, item) => onSelectEdge(item)}
            onNodeDragStop={(e, item) => updateNodePosition(item)}
            onNodeDragStart={(e, item) => findCurrentNode(item)}
            onPaneClick={() => setIsExist(false)}
          >
            <PlusCircleOutlined
              style={{
                fontSize: 26,
                position: 'absolute',
                top: 10,
                left: 10,
                zIndex: 4,
              }}
              onClick={onCreatePhase}
            />
            <MiniMap
              nodeStrokeColor={(n: any) => {
                if (n.style?.background) return n.style.background;
                if (n.type === 'input') return '#0041d0';
                if (n.type === 'output') return '#ff0072';
                if (n.type === 'default') return '#1a192b';

                return '#eee';
              }}
              nodeColor={(n: any) => {
                if (n.style?.background) return n.style.background;

                return '#fff';
              }}
              nodeBorderRadius={2}
            />
            <Controls />
            <Background color="#aaa" gap={16} />
          </ReactFlow>
          <div className="route-form">
            <Form
              className="route-wrapper"
              name="basic"
              style={{ width: 600 }}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              autoComplete="off"
              form={form}
              layout="vertical"
              onFinish={onFinish}
            >
              <h2>Редактирование</h2>
              <Form.Item label="Название" name="Name">
                <DInput disabled={!isExist} type="text" />
              </Form.Item>
              {menuType === 'phase' ? (
                <>
                  <Form.Item label="Тип" name="Type">
                    <Select
                      disabled={!isExist}
                      className="ui-select"
                      style={{ width: 350 }}
                      showSearch
                      optionFilterProp="children"
                      onChange={value => setCurrentType(value)}
                    >
                      {phaseTypes.map(type => (
                        <Option key={type.Type} value={type.Type}>
                          {type.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Тип исполнителя" name="ExecutionType">
                    <Select
                      disabled={!isExist}
                      className="ui-select"
                      style={{ width: 350 }}
                      showSearch
                      optionFilterProp="children"
                      onSelect={(value: string) => setSelectedType(value)}
                    >
                      {executionType.map(type => (
                        <Option key={type.Id} value={type.Type}>
                          {type.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Исполнители">
                    <Space align="baseline">
                      <Form.Item name="Executions">
                        <Select
                          disabled={!isExist || disableSelectContact}
                          mode="multiple"
                          allowClear
                          className="ui-select"
                          style={{ width: 350 }}
                          showSearch
                          optionFilterProp="children"
                          maxTagCount="responsive"
                          onSearch={value =>
                            selectedType === 'User' && onSearch(value)
                          }
                        >
                          {selectedType === 'User'
                            ? executors.map(item => (
                                <Option key={item.Id} value={item.Id}>
                                  {item.LastName} {item.FirstName}{' '}
                                  {item.MiddleName}
                                </Option>
                              ))
                            : departments.map(item => (
                                <Option key={item.Id} value={item.Id}>
                                  {item.Name}
                                </Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <Form.Item label="Время исполнения" name="Estimation">
                    <DInput disabled={!isExist} type="number" />
                  </Form.Item>
                  {showRequiredStage && (
                    <Form.Item valuePropName="checked" name="IsRequired">
                      <Checkbox>Обязательный этап</Checkbox>
                    </Form.Item>
                  )}
                  {showEsignedState && (
                    <Form.Item valuePropName="checked" name="SigningRequired">
                      <Checkbox>Заверять ЭП</Checkbox>
                    </Form.Item>
                  )}
                </>
              ) : (
                <>
                  <Form.Item label="Описание" name="Description">
                    <DInput disabled={!isExist} type="text" />
                  </Form.Item>
                  <Form.Item label="Цвет" name="Color">
                    <Select
                      disabled={!isExist}
                      className="ui-select"
                      style={{ width: 350 }}
                      showSearch
                      optionFilterProp="children"
                    >
                      {phaselinksColor.map(item => (
                        <Option key={item.Color} value={item.Color}>
                          {item.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              )}
              <Form.Item>
                <DButton
                  disabled={!isExist}
                  className="mr15"
                  small
                  primary
                  type="submit"
                >
                  Сохранить
                </DButton>
                {isExist && (
                  <DButton small defaultDanger onClick={onDelete}>
                    Удалить
                  </DButton>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    </>
  );
};
