import { DatePicker, Input, notification, Select } from 'antd';
import { useFormik } from 'formik';
import _ from 'lodash';
import { debounce } from 'lodash-es';
import moment from 'moment';
import { NomenclatureParams } from 'pages/AssignmentCard/types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CatalogButton } from 'shared/components/CatalogButton';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import { UnuseRegNumbers } from 'shared/components/CustomDrawers/UnuseRegnumbers';
import { PreLoader } from 'shared/components/PreLoader';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow, successNotification } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { ReduxState } from 'store/store';
import { TabKeys } from '../constants';

export const getUnUniqDocuments = (ids: number[]) => {
  notification['error']({
    message: 'Текущий рег.номер уже принадлежит документам',
    description: (
      <>
        {ids.map(id => (
          <>
            <a
              target="_blank"
              href={`/active-documents/DocumentsOutcoming/${id}`}
              rel="noreferrer"
            >
              Документ №{id}
            </a>
            <br />
          </>
        ))}
      </>
    ),
    icon: null,
    className: 'custom-notification__error-after',
  });
};

export const RegistrateDocument = ({
  formValues,
  updateSelectedTab,
  setShowRouteComponent,
  setFormValues,
}: any) => {
  const [nomenclaturesList, setNomenclaturesList] = useState<
    NomenclatureParams[]
  >([]);
  const [visible, setVisible] = useState<boolean>(false);
  const { Option } = Select;
  const [selectedNomenclature, setSelectedNomenclature] = useState<number>(0);
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { documentInfo, sampleSettings } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  const onClose = () => setVisible(false);

  const initialValues = {
    NomenclatureId: formValues?.NomenclatureId ?? documentInfo?.NomenclatureId,
    RegNUmberRequisite: '',
    RegDate: documentInfo?.RegDate
      ? moment(documentInfo?.RegDate)
      : moment(
          moment()
            .toDate()
            .setMinutes(0),
        ),
  };

  const onFinish = (values: any) => {
    $api
      .patch(`documents/${formValues?.Id ?? documentInfo?.Id}/registrate`, {
        NomenclatureId: values.NomenclatureId,
        RegNumber: values.RegNUmberRequisite,
        RegDate: values.RegDate ?? moment(),
      })
      .then(() => {
        updateSelectedTab(TabKeys.SEND_TO_ROUTE);
        setShowRouteComponent(true);
        setFormValues((prev: any) => ({
          ...prev,
          RegNumber: values.RegNUmberRequisite,
          RegDate: values.RegDate ?? moment(),
        }));
      })
      .catch(({ response }) => errorThrow(response));
  };

  const getRegNumber = ({
    requestUrl,
    value,
  }: {
    requestUrl: string;
    value?: number;
  }) => {
    $api
      .get(requestUrl, {
        params: {
          DocumentId: formValues?.Id ?? documentInfo?.Id,
          NomenculatureId:
            value ??
            formik?.values?.NomenclatureId ??
            documentInfo?.NomenclatureId,
          DocumentType: formValues?.Type ?? documentInfo?.Type,
          DocumentKind:
            formValues?.DocumentKindId ?? documentInfo?.DocumentKindId,
        },
      })
      .then(res => {
        formik.setFieldValue('RegNUmberRequisite', res.data);
      })
      .catch(({ response }) => errorThrow(response));
  };

  const getNomenclatures = useCallback(
    (query?: string) => {
      $api
        .get(`nomenclatures/active/page`, {
          params: {
            Limit: 15,
            FastSearch: query,
            OrganisationId: user?.CurrentContact?.OrganisationId,
            // documentInfo?.OrganisationId ?? formValues?.OrganisationId,
          },
        })
        .then(({ data }) => {
          setNomenclaturesList(data.Data);
        })
        .catch(({ response }) => errorThrow(response));
    },
    [documentInfo?.OrganisationId, formValues?.OrganisationId],
  );

  useEffect(() => {
    getNomenclatures();
  }, [getNomenclatures]);

  useEffect(() => {
    if (documentInfo && !sampleSettings)
      formik.setFieldValue('RegNUmberRequisite', documentInfo?.RegNumber ?? '');
    else formik.setFieldValue('RegNUmberRequisite', '');
  }, []);

  const getNomenclature = useCallback(() => {
    if (formValues?.NomenclatureId || documentInfo?.NomenclatureId)
      $api
        .get(
          `Nomenclatures/${formValues?.NomenclatureId ??
            documentInfo?.NomenclatureId}`,
        )
        .then(res => {
          setNomenclaturesList(prev => [...prev, res.data]);
          formik.setFieldValue(
            'NomenclatureId',
            formValues?.NomenclatureId ?? documentInfo?.NomenclatureId,
          );
        })
        .catch(({ response }) => {
          errorThrow(response);
        });
  }, [documentInfo?.NomenclatureId, formValues?.NomenclatureId]);

  useEffect(() => {
    getNomenclature();
  }, [getNomenclature]);

  const onSearch = useCallback(
    debounce(query => getNomenclatures(query), DEBOUNCE_TIME),
    [],
  );

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  const onSelectNomenclature = (item: NomenclatureParams) => {
    setNomenclaturesList(prev => [...prev, item]);
    formik.setFieldValue('NomenclatureId', item.Id);
    setSelectedNomenclature(item.Id);
    getRegNumber({
      requestUrl: 'documents/getregnumberbynomenclarue/byonenomenclature',
      value: item.Id,
    });
  };

  const onCheckRegNumber = useCallback(() => {
    setIsLoading(true);
    $api
      .get('documents/checkregnumber', {
        params: {
          RegNumber: formik.values.RegNUmberRequisite,
        },
      })
      .then(({ data: { Ids } }) => {
        !Ids.length
          ? successNotification('Текущий рег. номер является уникальным')
          : getUnUniqDocuments(Ids);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, [formik.values.RegNUmberRequisite]);

  return (
    <>
      {isLoading && <PreLoader />}
      {visible && (
        <UnuseRegNumbers
          visible={visible}
          onClose={onClose}
          nomenclatureId={selectedNomenclature}
          formik={formik}
        />
      )}
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={() => setVisibleCatalog(false)}
          type="nomenclatures"
          func={onSelectNomenclature}
          orgId={user?.CurrentContact?.OrganisationId}
        />
      )}
      <h2>Регистрация</h2>
      <form className="form" onSubmit={formik.handleSubmit}>
        <DFormItemTwo label="Дело по номенклатуре" name="NomenclatureId">
          <Select
            className="ui-select"
            allowClear
            showSearch
            onClear={() => setSelectedNomenclature(0)}
            onSelect={(value: any) => {
              setSelectedNomenclature(value);
              getRegNumber({
                requestUrl:
                  'documents/getregnumberbynomenclarue/byonenomenclature',
                value,
              });
            }}
            onSearch={value => checkLengthStringForSearch(value, onSearch)}
            optionFilterProp="children"
            value={formik.values.NomenclatureId}
            onChange={value => formik.setFieldValue('NomenclatureId', value)}
          >
            {_.uniqBy(nomenclaturesList, 'Id')?.map(nomenclature => (
              <Option value={nomenclature.Id}>
                {nomenclature.Code} {nomenclature.Name}
              </Option>
            ))}
          </Select>
          <CatalogButton
            onClick={() => {
              setVisibleCatalog(true);
            }}
          />
        </DFormItemTwo>
        <DFormItemTwo label="Рег. номер" name="RegNUmberRequisite">
          <DInput
            type="text"
            className="mr10"
            name="RegNUmberRequisite"
            value={formik.values.RegNUmberRequisite}
            onChange={formik.handleChange}
          />
          <DButton
            onClick={() =>
              getRegNumber({ requestUrl: 'Documents/GetRegistrationNumber' })
            }
            className="mr10"
            small
            primary
          >
            Получить рег. №
          </DButton>
          <DatePicker
            style={{
              maxWidth: 140,
              height: 36,
              borderRadius: 8,
            }}
            onChange={(date: any) => {
              formik.setFieldValue('RegDate', date);
            }}
            allowClear={false}
            format={'DD.MM.YYYY'}
            value={moment(formik.values.RegDate)}
          />
        </DFormItemTwo>
        <DFormItemTwo className="registrate-buttons">
          <DButton
            disabled={!formik.values?.RegNUmberRequisite}
            small
            defaultPrimary
            onClick={onCheckRegNumber}
          >
            Проверить
          </DButton>
          <span onClick={() => setVisible(true)} className="custom-link">
            Неиспользованные рег. номера
          </span>
        </DFormItemTwo>

        <DFormItemTwo className="form-buttons">
          <DButton
            small
            defaultDanger
            className="mr15"
            onClick={() => {
              updateSelectedTab(TabKeys.CREATE);
              setShowRouteComponent(false);
            }}
          >
            Назад
          </DButton>
          <DButton
            small
            primary
            disabled={!formik.values?.RegNUmberRequisite}
            type="submit"
          >
            Сохранить
          </DButton>
        </DFormItemTwo>
      </form>
    </>
  );
};
