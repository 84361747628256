import { useState } from 'react';
import { AssignmentDrawer } from 'shared/components';
import { DTag } from 'shared/ui-kit/DTag';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { selectActiveAssignment } from 'store/slice/assignments';
import { AssignmentType, TDocumentAssignment } from 'types/document';
import { assignmentTypes } from 'shared/utils';

export const AssignmentIndicator = () => {
  const [visibleAssignmentDrawer, setVisibleAssignmentDrawer] = useState<
    boolean
  >(false);
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const dispatch = useDispatch();

  const assignmentForUser = (assignment: AssignmentType) => {
    return (
      assignment?.ExecutorId === user?.CurrentContact?.Id ||
      (user?.CurrentContact?.DepartmentId &&
        assignment.ExecutionGroupId === user?.CurrentContact?.DepartmentId)
    );
  };

  const conditionToDisplay = (assignment: AssignmentType) =>
    assignment?.State !== 'Completed' &&
    assignment?.State !== 'Returned' &&
    assignment?.State !== 'Rejected' &&
    assignmentForUser(assignment);

  const onCloseAssignmentDrawer = (bool: boolean) => {
    setVisibleAssignmentDrawer(bool);
  };

  const selectAssignmentType = (Type: string, Id: number) => {
    return assignmentTypes
      .filter(item => item.Type === Type)
      .map(item => {
        return (
          <DTag
            key={Id}
            onClick={() => {
              setVisibleAssignmentDrawer(true);
              dispatch(selectActiveAssignment(Id));
            }}
          >
            {item.Text}
          </DTag>
        );
      });
  };

  return (
    <>
      {visibleAssignmentDrawer && (
        <AssignmentDrawer
          visibleDrawer={visibleAssignmentDrawer}
          onCloseDrawer={onCloseAssignmentDrawer}
        />
      )}
      <div className="assignment">
        {documentInfo?.Assignments.length
          ? documentInfo?.Assignments.map(
              assignment =>
                conditionToDisplay(assignment) &&
                selectAssignmentType(assignment?.Type, assignment?.Id),
            )
          : null}
      </div>
    </>
  );
};
