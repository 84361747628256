import { DatePicker } from 'antd';
import 'moment/locale/ru';
import { useDatePicker } from './useDatePicker';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { CSSProperties, memo } from 'react';
import './DatePick.scss';
moment.locale('ru');

type RangePickProps = {
  className: string;
  style: CSSProperties;
  showCustomDates?: boolean;
  showTwoMonths?: boolean;
} & any;

export const RangePick = memo(
  ({
    className,
    style,
    showCustomDates = true,
    showTwoMonths = false,
    ...props
  }: RangePickProps) => {
    const { RangePicker } = DatePicker;
    const { renderCustomDate, onYearChange } = useDatePicker();

    const customProps = {
      ...(showCustomDates
        ? {
            dateRender: (currentDate: moment.Moment) =>
              renderCustomDate(currentDate),
          }
        : {}),
      ...(showTwoMonths
        ? {
            defaultPickerValue: [moment().add(-1, 'M'), moment()],
          }
        : {}),
    };

    return (
      <RangePicker
        className={`${className ?? ''} dpicker`}
        onPanelChange={value => {
          const START_DATE = value && value[0];
          const END_DATE = value && value[1];
          const date = END_DATE ?? START_DATE;

          onYearChange(Number(date?.format('YYYY')));
        }}
        {...customProps}
        locale={locale}
        style={style}
        {...props}
      />
    );
  },
);
