import { Drawer, Table } from 'antd';
import React, { useState } from 'react';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { format } from 'date-fns';
import { selectAssignmentType } from 'shared/utils';
import { AssignmentType } from 'types/document';
import { dateCompare } from 'shared/utils/dateCompare';
import { TableResult } from 'types/tableData';
import { AssignmentDrawer } from '..';
import { DocumentDrawer } from './DocumentDrawer';
import { selectActiveAssignment } from 'store/slice/assignments';
import { useDispatch } from 'react-redux';

type TStatisticsDrawer = {
  onCloseDrawer: (bool: boolean) => void;
  visibleDrawer: boolean;
  count: any;
  point: string;
};

export const StatisticsDrawer = ({
  visibleDrawer,
  count,
  point,
  onCloseDrawer,
}: TStatisticsDrawer) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<number>();
  const dispatch = useDispatch();
  let link: null | string = null;

  const onClose = () => setVisible(false);

  switch (point) {
    case 'Documents': {
      link = `/active-documents`;
      break;
    }
    case 'Assignments': {
      link = '/assignments';
      break;
    }
    default:
      link = null;
      break;
  }

  const onRedirect = (item: any) => {
    if (point === 'Documents') {
      setDocumentId(item.Id);
      setVisible(true);
    } else if (point === 'Assignments') {
      setDocumentId(undefined);
      setVisible(true);
      dispatch(selectActiveAssignment(item.Id));
    }
  };

  const documentColumns = [
    {
      title: 'Системный номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Тема документа',
      dataIndex: 'Subject',
      key: 'Subject',
      render: (subject: string, item: TableResult) => (
        <span className="custom-link" onClick={() => link && onRedirect(item)}>
          {subject}
        </span>
      ),
    },
    {
      title: 'Рег. номер и дата',
      dataIndex: 'RegDate',
      key: 'RegDate',
      render: (date: string, item: TableResult) => (
        <span>
          {item?.RegNumber ? `${item?.RegNumber}` : 'б/н'} от
          {` ${date ? format(new Date(date), 'dd.MM.yyyy') : '-'}`}
        </span>
      ),
    },
    {
      title: 'Автор',
      dataIndex: 'Author',
      key: 'Author',
    },
  ];

  const assignmentColumns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Тема задачи',
      dataIndex: 'Subject',
      key: 'Subject',
      render: (subject: string, item: AssignmentType) => (
        <>
          <span
            className="custom-link"
            onClick={() => link && onRedirect(item)}
          >
            {subject}
          </span>
          <span>{item.Content && `(${item.Content})`}</span>
        </>
      ),
    },
    {
      title: 'Исполнитель',
      dataIndex: 'Executor',
      key: 'Executor',
    },
    {
      title: 'Срок исполнения',
      dataIndex: 'ExecutionTime',
      key: 'ExecutionTime',
      render: (date: string) => (
        <span className={dateCompare(new Date(date))}>
          {format(new Date(date), 'dd.MM.yyyy H:mm')}
        </span>
      ),
    },
    {
      title: 'Фактическая дата исполнения',
      dataIndex: 'ExecutionFactTime',
      key: 'ExecutionFactTime',
      render: (date: string) =>
        date ? format(new Date(date), 'dd.MM.yyyy H:mm') : '-',
    },
    {
      title: 'Тип',
      dataIndex: 'Type',
      key: 'Type',
      render: (type: string) => selectAssignmentType(type),
    },
  ];

  return (
    <Drawer
      placement="right"
      onClose={() => onCloseDrawer(false)}
      visible={visibleDrawer}
      className="drawer"
      closeIcon={null}
    >
      {visible && !documentId ? (
        <AssignmentDrawer visibleDrawer={visible} onCloseDrawer={onClose} />
      ) : (
        <DocumentDrawer
          visible={visible}
          onClose={onClose}
          documentId={documentId}
        />
      )}
      <div className="page3">
        <DrawerHeader
          onClose={onCloseDrawer}
          text={`Карточка ${point === 'Assignments' ? 'задач' : 'документов'}`}
        />
        {point === 'Documents' ? (
          <Table
            pagination={{
              position: ['bottomRight'],
              defaultPageSize: 15,
              showTotal: (total: number) => total,
            }}
            dataSource={count}
            columns={documentColumns}
          />
        ) : (
          <Table
            pagination={{
              position: ['bottomRight'],
              defaultPageSize: 15,
              showTotal: (total: number) => total,
            }}
            dataSource={count}
            columns={assignmentColumns}
          />
        )}
      </div>
    </Drawer>
  );
};
