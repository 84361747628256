import { Upload, UploadProps } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { onDownloadFile } from 'api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorThrow, getHost } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { updateDisabledButton } from 'store/slice/creatingDocument';
import { ReduxState } from 'store/store';
import { FileListParams } from './types';
import { UploadFilesProps } from './UploadFiles';
import { checkIsItemUnderMaxSize, getErrorNotification } from './utils';

export const useUploadFiles = ({
  setCurrentFiles,
  currentFiles,
}: UploadFilesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [fileList, setFileList] = useState<UploadFile<RcFile>[]>();
  const [visibleBarCode, setVisibleBarCode] = useState(false);
  const dispatch = useDispatch();

  const { sampleSettings } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { disabledButton } = useSelector(
    (state: ReduxState) => state.creatingDocumentReducer,
  );

  const onUploadProgress = (event: ProgressEvent) => {
    const percent = Math.floor((event.loaded / event.total) * 100);
    setProgress(percent);
    if (percent === 100) {
      setTimeout(() => {
        setProgress(0);
      }, 1000);
    }
  };

  const cacheFile = useCallback(
    (file: string | RcFile | Blob) => {
      dispatch(updateDisabledButton(true));
      const formData = new FormData();
      formData.append('FormFile', file);
      $api
        .post('files/cache', formData, {
          headers: {
            'Content-Type': 'multipart/form-data;',
          },
          onUploadProgress,
        })
        .then(({ data }) => {
          setCurrentFiles(
            prev =>
              [
                ...prev,
                {
                  file: file as RcFile,
                  id: data,
                },
              ] as FileListParams[],
          );
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => {
          formData.delete('FormFile');
          dispatch(updateDisabledButton(false));
        });
    },
    [dispatch, setCurrentFiles],
  );

  const convertFileData = useCallback(
    list => {
      const filesArray = list?.map((item: FileListParams) => {
        const uid = {
          uid: item?.file?.Id ? String(item?.file?.Id) : item?.file?.uid,
        };
        const name = { name: item?.file?.name ?? item.file?.Name };
        const status = { status: 'done' };
        const url = { url: `${getHost()}/${item?.file?.name}` };
        return Object.assign(url, uid, name, status, item);
      });
      setFileList(filesArray);
      // if (sampleSettings) {
      //   filesArray.forEach((file: any) => {
      //     console.log(file);
      //     const blob = new Blob([file.file]);
      //     console.log(blob);
      //     const newFile = new File([blob], file.name, {
      //       // @ts-ignore
      //       lastModifiedDate: new Date(0),
      //       type: 'image/png',
      //     });
      //     cacheFile(newFile);
      //   });
      // }
    },
    [sampleSettings],
  );

  useEffect(() => {
    // if (!sampleSettings) {
    convertFileData(currentFiles);
    // }
  }, [convertFileData, currentFiles]);

  // useEffect(() => {
  //   if (sampleSettings?.Files) {
  //     convertFileData(formatFilesToUpdateState(sampleSettings?.Files));
  //   }
  // }, [convertFileData, sampleSettings?.Files]);

  useEffect(() => {
    fileList && setIsLoading(false);
  }, [fileList]);

  const fileIsExist = (file: UploadFile<RcFile>) => {
    const isExist: FileListParams | undefined = currentFiles.find(
      item => item.file.name === file.name,
    );

    return isExist;
  };

  const updateCurrentFiles = (file: UploadFile<RcFile>) => {
    const newFileList = currentFiles.filter(
      item => item.file.name !== file.name,
    );
    setCurrentFiles(newFileList);
  };

  const uploadProps: UploadProps = {
    name: 'FormFile',
    multiple: true,
    onRemove(file: UploadFile<RcFile>) {
      const IsHadId = currentFiles.find(
        item => item.file.name === file.name && item.file.hasOwnProperty('Id'),
      );
      updateCurrentFiles(file);

      if (!IsHadId || (sampleSettings && !sampleSettings?.isCreated)) return;
      if (IsHadId || sampleSettings?.isCreated) {
        $api
          .delete(`files/${fileIsExist(file)?.file.Id}`)
          .catch(({ response }) => errorThrow(response));
      }
    },
    customRequest: ({ file }) => {
      cacheFile(file);
    },
    beforeUpload(file: RcFile) {
      const under50MB = checkIsItemUnderMaxSize(file.size, 50);

      if (!under50MB) {
        getErrorNotification('Размер загруженного файла больше 50МБ');
      }

      if (fileIsExist(file)) {
        getErrorNotification('Этот файл уже был загружен ранее');
      }

      return (!fileIsExist(file) && under50MB) || Upload.LIST_IGNORE;
    },
    onPreview(file: UploadFile<RcFile>) {
      onDownloadFile(fileIsExist(file)?.file);
    },
    defaultFileList: fileList,
    fileList,
    iconRender() {
      return '—';
    },
    disabled: disabledButton,
  };

  return {
    uploadProps,
    isLoading,
    progress,
    disabledButton,
    setVisibleBarCode,
    visibleBarCode,
  };
};
