import React, { useState } from 'react';
import { StatisticsDrawer } from 'shared/components/CustomDrawers';
import { motion } from 'framer-motion';
import './statistics.scss';

const AtomOrganization = ({ type, count, point }: any) => {
  const [visible, setVisible] = useState<boolean>(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const createStatisticProgress = () => {
    return (count.length ?? 0) + 50;
  };

  return (
    <>
      <div className="information">
        <p style={{ minWidth: 300, paddingTop: 10 }}>{type}</p>
        <motion.div
          animate={{ width: [0, createStatisticProgress()] }}
          role="button"
          className="atomStatistic"
          onClick={() => showDrawer()}
          style={{
            width: `${createStatisticProgress()}px`,
            marginBottom: '1em',
            padding: 10,
            backgroundColor: '#67b7dc',
            borderRadius: 5,
            textAlign: 'center',
          }}
        >
          {count.length}
        </motion.div>
      </div>
      <StatisticsDrawer
        onCloseDrawer={onClose}
        visibleDrawer={visible}
        count={count}
        point={point}
      />
    </>
  );
};

export { AtomOrganization };
