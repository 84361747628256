import { Table } from 'antd';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { DocumentReminderDrawer } from 'shared/components/CustomDrawers/DocumentReminderDrawer';
import { DButton } from 'shared/ui-kit';
import {
  permissionExist,
  PermissionsAccess,
} from 'shared/utils/isExistPermission';
import { ReduxState } from 'store/store';
import { DocumentReminderProps } from './types';
import { useDocumentReminder } from './useDocumentReminder';
import './style.scss';

export const DocumentReminder: FC<DocumentReminderProps> = ({ canEdit }) => {
  const {
    visible,
    isDisabledButton,
    remindList,
    selectedItem,
    columns,
    onClose,
    drawerType,
    getData,
    onClickButton,
  } = useDocumentReminder(canEdit);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  return (
    <>
      {visible && (
        <DocumentReminderDrawer
          item={selectedItem}
          visible={visible}
          onClose={onClose}
          type={drawerType}
          getData={getData}
        />
      )}
      <h2 className="mt35">Напоминания</h2>
      {canEdit &&
        user?.Permissions &&
        permissionExist(user?.Permissions, PermissionsAccess.Control) && (
          <DButton
            primary
            disabled={isDisabledButton}
            className="mb15"
            small
            onClick={onClickButton}
          >
            Создать
          </DButton>
        )}
      <Table className="mb35" columns={columns} dataSource={remindList} />
    </>
  );
};
