export const formatStateName = (state: string | undefined) => {
  return nameStateDocuments
    .filter(item => item.State === state)
    .map(item => item.Name);
};

type TnameStateDocuments = {
  Name: string;
  State: string;
};

const nameStateDocuments: TnameStateDocuments[] = [
  {
    Name: 'Неизвестен',
    State: 'Unknown',
  },
  {
    Name: 'Сформирован',
    State: 'Create',
  },
  {
    Name: 'Согласован',
    State: 'Approved',
  },
  {
    Name: 'Ожидает согласования',
    State: 'Approving',
  },
  {
    Name: 'Архивный',
    State: 'Archive',
  },
  {
    Name: 'Ожидает архивирования',
    State: 'Archiving',
  },
  {
    Name: 'Завершен',
    State: 'Closed',
  },
  {
    Name: 'Ожидает уточнений',
    State: 'Precising',
  },
  {
    Name: 'Зарегистрирован',
    State: 'Register',
  },
  {
    Name: 'Отклонен',
    State: 'Rejected',
  },
  {
    Name: 'Рассмотрен',
    State: 'Reviewed',
  },
  {
    Name: 'Раcсмотрение',
    State: 'Reviewing',
  },
  {
    Name: 'На доработке',
    State: 'Rework',
  },
  {
    Name: 'Отправлен получателям',
    State: 'Send',
  },
  {
    Name: 'Ожидает подписания',
    State: 'Signature',
  },
  {
    Name: 'Подписан',
    State: 'Signed',
  },
  {
    Name: 'Согласование',
    State: 'Agreement',
  },
  {
    Name: 'Проверка',
    State: 'Verify',
  },
  {
    Name: 'Подписание',
    State: 'Signing',
  },
  {
    Name: 'Регистрация',
    State: 'Registration',
  },
  {
    Name: 'Отправка',
    State: 'Sending',
  },
  {
    Name: 'Наложение штампа',
    State: 'ApplyingStamp',
  },
  {
    Name: 'Ознакомление',
    State: 'Review',
  },
];
