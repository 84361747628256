import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import {
  loadingStatus,
  updateFilteredDocuments,
  updateVisibleSetting,
} from 'store/slice/table';
import { ReduxState } from 'store/store';

export const useDocumentTags = () => {
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<number | null>(null);
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const documentType = 'Documents' + documentInfo?.Type;
  const documentId = documentInfo?.Id;

  const detachTag = useCallback(() => {
    $api
      .delete(`Documents/${documentInfo?.Id}/tags/${selectedTag}/detach`)
      .then(() => {
        getDocumentInfo({ documentType, documentId, dispatch });
        setVisibleModal(false);
      })
      .catch(({ response }) => errorThrow(response));
  }, [dispatch, documentId, documentInfo?.Id, documentType, selectedTag]);

  const onSelectTag = useCallback(
    tagId => {
      dispatch(
        updateFilteredDocuments({
          data: [],
          config: { TagIds: [tagId] },
          filteredCount: 0,
        }),
      );
      dispatch(loadingStatus(true));
      dispatch(updateVisibleSetting(false));
      history.push('/searching/page=1');
      sessionStorage.setItem('uniqItem', '231active-documents');
      sessionStorage.setItem('uniqNumberAtom', '0');
      sessionStorage.setItem('sidebarData', 'searching');
    },
    [dispatch, history],
  );

  return {
    onChangeModalVisible: (visible: boolean) => setVisibleModal(visible),
    onChangeDrawerVisible: (visible: boolean) => setVisibleDrawer(visible),
    detachTag,
    onSelectTag,
    visibleDrawer,
    visibleModal,
    updateSelectedTag: (id: number) => setSelectedTag(id),
  };
};
