import { Menu, Skeleton } from 'antd';
import { createAttachedSignature, getUserCertificates } from 'crypto-pro';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PreLoader } from 'shared/components/PreLoader';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { SignedInfo, updateSignedInfo } from 'store/slice/assignments';
import { ReduxState } from 'store/store';
import { AssignmentType } from 'types/document';

const updateUserCertificates = async () => {
  try {
    const data = await getUserCertificates();
    return data;
  } catch (err) {
    console.log('Error: ', err);
  }
};

type SignedFormProps = {
  assignment?: AssignmentType;
  archive?: any;
};

const getDocumentSignature = async (signedInfo: SignedInfo) => {
  const convertDataToString = JSON.stringify(signedInfo.DocumentSignatureData);
  try {
    const data = await await createAttachedSignature(
      signedInfo.CertificateThumbprint,
      convertDataToString,
    );
    return signedInfo.CertificateThumbprint !== 'Unknown' ? data : null;
  } catch (err) {
    console.log(err);
  }
};

export const SignedForm = ({ assignment, archive }: SignedFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userCertificate, setUserCertificate] = useState<any>([]);
  const [isReady, setIsReady] = useState<boolean>(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  const { signedInfo } = useSelector(
    (state: ReduxState) => state.assignmentReducer,
  );
  const dispatch = useDispatch();
  const SAVED_USER = JSON.parse(
    localStorage.getItem('previousUserInfo') as string,
  );

  const DEFAULT_ESIGN = SAVED_USER
    ? SAVED_USER?.CurrentContact?.Esign
    : user?.CurrentContact?.Esign;

  const getDocumentSignatureData = useCallback(async () => {
    if (signedInfo?.CertificateThumbprint !== 'Unknown') {
      setIsLoading(true);
      const result = await getDocumentSignature(signedInfo);
      $api
        .get(`/Documents/${assignment?.DocumentId}/signature/data`)
        .then(({ data }) => {
          dispatch(
            updateSignedInfo({
              ...signedInfo,
              DocumentSignatureData: JSON.stringify(data),
              // @ts-ignore
              DocumentSignature: result,
            }),
          );
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    }
  }, [assignment?.DocumentId, dispatch, signedInfo]);

  const getArchiveSignatureDate = useCallback(async () => {
    if (signedInfo?.CertificateThumbprint !== 'Unknown') {
      setIsLoading(true);
      const result = await getDocumentSignature(signedInfo);
      $api
        .get(`/archivecases/${archive?.Id}/signature/data`)
        .then(({ data }) => {
          dispatch(
            updateSignedInfo({
              ...signedInfo,
              DocumentSignatureData: JSON.stringify(data),
              // @ts-ignore
              DocumentSignature: result,
            }),
          );
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    }
  }, [archive?.Id, dispatch, signedInfo]);

  useEffect(() => {
    setUserCertificate([
      {
        thumbprint: 'Unknown',
        name: 'Не задано',
      },
      // {
      //   thumbprint: 'BD800A7843667D463AB44B34E74212E2BB2B9672',
      //   name: 'test',
      // },
    ]);
    updateUserCertificates()
      .then(data => {
        data && setUserCertificate((prev: any) => [...prev, ...data]);
      })
      .finally(() => setIsReady(true));
  }, [assignment?.DocumentId, DEFAULT_ESIGN]);

  useEffect(() => {
    if (assignment) {
      getDocumentSignatureData();
    } else {
      getArchiveSignatureDate();
    }
  }, [signedInfo?.CertificateThumbprint]);

  useEffect(() => {
    return () => {
      console.log('unmounted');
      dispatch(
        updateSignedInfo({
          CertificateThumbprint: 'Unknown',
          DocumentSignatureData: '',
          DocumentSignature: '',
        }),
      );
    };
  }, [dispatch]);

  const getDefaultValue = useCallback(() => {
    const currentEsign = userCertificate.find(
      (item: any) =>
        item.thumbprint.toLowerCase() === DEFAULT_ESIGN?.toLowerCase(),
    );
    dispatch(
      updateSignedInfo({
        ...signedInfo,
        CertificateThumbprint: currentEsign
          ? currentEsign.thumbprint
          : 'Unknown',
      }),
    );
  }, [DEFAULT_ESIGN, dispatch, signedInfo, userCertificate]);

  useEffect(() => {
    if (isReady === true) getDefaultValue();
  }, [dispatch, isReady]);

  const findUserESign = useCallback(() => {
    const currentEsign = userCertificate.find(
      (item: any) =>
        item.thumbprint.toLowerCase() === DEFAULT_ESIGN?.toLowerCase(),
    );
    return currentEsign ? currentEsign.thumbprint : 'Unknown';
  }, [DEFAULT_ESIGN, userCertificate]);

  const onSelectThumbPrint = useCallback(
    async thumbprint => {
      setIsLoading(true);
      const result = await getDocumentSignature(signedInfo);
      dispatch(
        updateSignedInfo({
          ...signedInfo,
          CertificateThumbprint: thumbprint,
          // @ts-ignore
          DocumentSignature: result,
        }),
      );
      setIsLoading(false);
    },
    [dispatch, signedInfo],
  );

  return (
    <>
      {isLoading && <PreLoader />}
      <DFormItemTwo
        label="Выбор сертификата для подписания"
        name="choiceSignature"
        style={{ alignItems: 'baseline', flexDirection: 'column' }}
      >
        {isReady ? (
          <Menu
            style={{ width: '100%' }}
            defaultSelectedKeys={[findUserESign()]}
          >
            {userCertificate.map((certificate: any, index: number) => {
              return (
                <Menu.Item
                  style={{ height: index === 0 ? undefined : 'auto' }}
                  onClick={() => onSelectThumbPrint(certificate.thumbprint)}
                  key={certificate.thumbprint}
                >
                  {certificate.name} <br /> {certificate.thumbprint} <br />
                </Menu.Item>
              );
            })}
          </Menu>
        ) : (
          <Skeleton />
        )}
      </DFormItemTwo>
    </>
  );
};
