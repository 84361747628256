import { Drawer, Select } from 'antd';
import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import { useFormik } from 'formik';
import { debounce } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { DButton } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { DrawerHeader } from './DrawerHeader';
import { TOrganisationsList } from './FilterDrawer/types';

type TRedirectByMedo = {
  visible: boolean;
  onClose: () => void;
};

export const RedirectByMedo = ({ visible, onClose }: TRedirectByMedo) => {
  const [organisationList, setOrganisationList] = useState<
    TOrganisationsList[]
  >([]);
  const { Option } = Select;
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const dispatch = useDispatch();

  const onFinish = (values: { OrganisationId?: number }) => {
    $api
      .post('integration/documents/redirect/medo', {
        DocumentId: documentInfo?.Id,
        OrganizationId: values.OrganisationId,
      })
      .then(() => {
        onClose();
        getDocumentInfo({
          documentType: 'Documents' + documentInfo?.Type,
          documentId: documentInfo?.Id,
          dispatch,
        });
      })
      .catch(({ response }) => errorThrow(response));
  };

  const getOrganisations = useCallback((query?: string) => {
    $api
      .get('Organisations/active/page', {
        params: {
          FastSearch: query,
        },
      })
      .then(res => setOrganisationList(res.data.Data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const onSearch = debounce(value => {
    getOrganisations(value);
  }, DEBOUNCE_TIME);

  useEffect(() => {
    getOrganisations();
  }, [getOrganisations]);

  const formik = useFormik({
    initialValues: {
      OrganisationId: undefined,
    },
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      className="drawer"
      closeIcon={null}
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Перенаправить документ" />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Организация" name="OrganisationId">
            <Select
              value={formik.values.OrganisationId}
              className="ui-select"
              allowClear
              showSearch
              onSearch={onSearch}
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('OrganisationId', value)}
            >
              {organisationList?.map(item => (
                <Option value={item.Id}>{item.Name}</Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton
              disabled={!formik.values.OrganisationId}
              small
              primary
              className="mr15"
              type="submit"
            >
              Перенаправить
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
