export const assignmentTypes = [
  {
    Type: 'ToExecute',
    Text: 'Исполнение',
  },
  {
    Type: 'ToView',
    Text: 'Рассмотрение',
  },
  {
    Type: 'ToReview',
    Text: 'Ознакомление',
  },
  {
    Type: 'Unknown',
    Text: 'Неизвестно',
  },
  {
    Type: 'ToCheck',
    Text: 'Проверка',
  },
  {
    Type: 'ToSigning',
    Text: 'Подписание',
  },
  {
    Type: 'ToRegistration',
    Text: 'Регистрация',
  },
  {
    Type: 'ToDispatch',
    Text: 'Отправка',
  },
  {
    Type: 'ToApproval',
    Text: 'Согласование',
  },
  {
    Type: 'ToApplyingStamp',
    Text: 'Наложение штампа',
  },
  {
    Type: 'Delegated',
    Text: 'Делегировано',
  },
];

export const assignmentState = [
  {
    State: 'Unknown',
    Text: 'Не задано',
  },
  {
    State: 'OnCompleted',
    Text: 'На исполнении',
  },
  {
    State: 'OnClarification',
    Text: 'На уточнении',
  },
  {
    State: 'Completed',
    Text: 'Исполнено',
  },
  {
    State: 'Rejected',
    Text: 'Отклонено',
  },
];

export const selectAssignmentState = (state: string) => {
  return assignmentState
    .filter(item => item.State === state)
    .map(item => item.Text);
};
export const selectAssignmentType = (type: string | undefined) => {
  return assignmentTypes
    .filter(item => item.Type === type)
    .map(item => item.Text);
};
