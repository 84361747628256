import { Checkbox, Drawer } from 'antd';
import { DButton } from 'shared/ui-kit';
import { useColumnSetup } from '../ColumnSetup/useColumnSetup';
import { DrawerHeader } from '../CustomDrawers/DrawerHeader';

type TColumnSetupDrawer = {
  visible: boolean;
  pageType: 'document' | 'assignment';
  onClose: () => void;
};

export const ColumnSetupDrawer = ({
  visible,
  onClose,
  pageType,
}: TColumnSetupDrawer) => {
  const {
    optionsColumns,
    defaultColumns,
    updateChecked,
    onSubmit,
    onReset,
  } = useColumnSetup({ pageType });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        {onClose && (
          <DrawerHeader
            onClose={onClose}
            text="Настройка столбцов"
            code="COLUMNS_SETUP"
          />
        )}
        <div className="checkbox-wrapper drawer-form">
          <Checkbox.Group
            className="mb15"
            onChange={items => updateChecked(items)}
            value={defaultColumns}
          >
            {optionsColumns.map(item => {
              if (item.type === pageType)
                return (
                  <Checkbox value={item.key} key={item.key}>
                    {item.title}
                  </Checkbox>
                );
              else if (!item.hasOwnProperty('type'))
                return (
                  <Checkbox value={item.key} key={item.key}>
                    {item.title}
                  </Checkbox>
                );
            })}
          </Checkbox.Group>
          <div style={{ display: 'flex' }}>
            <DButton
              primary
              small
              disabled={!defaultColumns.length}
              className="mr15"
              onClick={onSubmit}
            >
              Сохранить
            </DButton>
            <DButton small onClick={onReset}>
              Сбросить к начальным настройкам
            </DButton>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
