import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { AssignmentType } from 'types/document';
import { selectAssignmentType } from 'shared/utils';
import { HelpBlock } from 'shared/components';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateDocumentInfo } from 'store/slice/document';

export const AssignmentHeaderShow = ({
  onCloseDrawer,
  assignment,
}: {
  onCloseDrawer?: (bool: boolean) => void;
  assignment?: AssignmentType;
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <>
      {onCloseDrawer && (
        <div className="content_head">
          <CloseOutlined
            className="mr10"
            style={{
              cursor: 'pointer',
              fontSize: 36,
              color: '#8a9499',
            }}
            onClick={() => {
              onCloseDrawer(false);
              if (location.pathname.split('/').length < 3)
                dispatch(updateDocumentInfo(null));
            }}
          />
          <h1 style={{ marginBottom: 0 }}>Задача № {assignment?.Id}</h1>
          <h1 className="ml5 mr15" style={{ marginBottom: 0 }}>
            — {selectAssignmentType(assignment?.Type)}
          </h1>
          <HelpBlock
            code={`ASSIGNMENT_${assignment?.Type.toUpperCase()}`}
            isDrawer
          />
        </div>
      )}
    </>
  );
};
