import { Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { CounterPartiesDrawer } from 'shared/components/CustomDrawers/CounterPartiesDrawer';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { COUNTERPARTIES } from 'shared/constants/helpCodes';
import searchSvg from 'shared/assets/images/search.svg';
import { Icon } from 'shared/components/IconComponent';

export type TParties = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  Name: string;
  Email: string;
  Address: string;
  Type: string;
  SendTypeId: number;
  SendTypeName: string;
  PhoneNumber: string;
  PhoneNumberAlter: string;
  Ogrn: number;
  Inn: number;
  Kpp: number;
  IfnsCode: number;
  IedoCode: string;
  MedoUid: string;
  IsAgreement: boolean;
  ParentId: number;
  CuratorId: number;
  OfficialCode: string;
};

export const partiesTypes = [
  {
    type: 'Unknown',
    name: 'Неизвестно',
  },
  {
    type: 'Inner',
    name: 'Внутренняя',
  },
  {
    type: 'Outside',
    name: 'Внешняя',
  },
  {
    type: 'Medo',
    name: 'МЭДО',
  },
];

export const CounterParties = () => {
  const [partiesList, setPartiesList] = useState<TParties[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultList, setDefaultList] = useState<TParties[]>([]);

  const onClose = () => setVisible(false);

  const getData = useCallback(() => {
    setIsLoading(true);
    $api
      .get('counterparties/byorganization')
      .then(res => {
        setPartiesList(res.data);
        setDefaultList([...res.data]);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => getData(), [getData]);

  const onDelete = (Id: number) => {
    $api
      .delete(`counterparties/byorganization/${Id}`)
      .then(() => {
        getData();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Доставка',
      dataIndex: 'SendTypeName',
      key: 'SendTypeName',
    },
    {
      title: 'ЭДО код',
      dataIndex: 'IedoCode',
      key: 'IedoCode',
    },
    {
      title: 'Guid в МЭДО',
      dataIndex: 'MedoUid',
      key: 'MedoUid',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: TParties) => (
        <DButton small defaultDanger onClick={() => onDelete(item.Id)}>
          Удалить
        </DButton>
      ),
    },
  ];

  const onSearch = (value: string) => {
    if (value) {
      const newList = defaultList?.filter(item =>
        item?.Name?.toLowerCase()?.includes(value.toLowerCase()),
      );
      setPartiesList(newList);
    } else {
      setPartiesList(defaultList);
    }
  };

  return (
    <>
      {visible && (
        <CounterPartiesDrawer
          visible={visible}
          onClose={onClose}
          getData={getData}
        />
      )}
      <div className="flex-space">
        <PageHeader title="Контрагенты" code={COUNTERPARTIES} />
      </div>
      <div className="flex-space mb15">
        <DButton
          className="mr15"
          onClick={() => {
            setVisible(true);
          }}
          small
          primary
        >
          + Создать
        </DButton>
        <DInput
          type="text"
          style={{ maxWidth: 630, width: '100%' }}
          placeholder="Поиск по наименованию"
          icon={<Icon icon={searchSvg} alt="searchSvg" />}
          onChange={({ target: { value } }) => onSearch(value)}
        />
      </div>
      <Table loading={isLoading} dataSource={partiesList} columns={columns} />
    </>
  );
};
