import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TReport = {
  Key: string;
  Value: string;
};

type Reports = {
  report: TReport;
};

const initialState: Reports = {
  report: { Key: '', Value: '' },
};

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    updateReport(state, action: PayloadAction<TReport>) {
      state.report = action.payload;
    },
  },
});

export const { updateReport } = reportSlice.actions;
export const reportReducer = reportSlice.reducer;
