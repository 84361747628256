import { TTreeChildren, TTreeData } from './types';

export const getItemChildrens = (
  documentKindItem: TTreeData,
  responseList: TTreeChildren[],
) => {
  const newChildrens = responseList.filter(
    responseItem =>
      responseItem.DocumentType === documentKindItem.type && responseItem,
  );

  return {
    ...documentKindItem,
    children: newChildrens as TTreeChildren[],
  };
};

export const getPageTitle = (linkishId: number | null) => {
  if (linkishId) {
    return `Создание связанного с документом № ${linkishId}`;
  }

  return 'Создание документа';
};
