import { Drawer, notification } from 'antd';
import { useState } from 'react';
import { DocumentControlForm } from 'shared/components/DocumentControl/DocumentControlForm';
import { DrawerHeader } from '../DrawerHeader';
import login_error from 'shared/assets/images/login-error.png';
import { $api } from 'shared/utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { errorThrow, successNotification } from 'shared/utils';
import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import { controlType, pointByType } from './constants';
import { TControlDrawer } from './types';

const getTitle = (type: string) => {
  return controlType
    .filter(item => item.Type === type)
    .map(item => item.Text)[0];
};

export const ControlDrawer = ({ visible, onClose, type }: TControlDrawer) => {
  const [showError, setShowError] = useState<boolean>(false);
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const dispatch = useDispatch();
  const DOCUMENT_CONTROL = documentInfo?.Controls[0];

  const updateShowError = () => setShowError(false);

  const getPoint = () =>
    pointByType.filter(item => item.type === type).map(item => item.point)[0];

  const getMessage = () =>
    pointByType.filter(item => item.type === type).map(item => item.success)[0];

  const notificationOpen = (description: string) => {
    setShowError(true);
    notification['error']({
      message: 'Произошла ошибка',
      description: description,
      className: 'custom-notification__warning',
      icon: <img src={login_error} alt="error" />,
    });
  };

  const onFinish = (values: any) => {
    const request = type === 'take-control' ? $api.post : $api.put;
    const typeByError =
      type === 'take-control' || type === 'reschedule-control';
    if (
      !values.deadlineDate &&
      !values.Controller &&
      !values.ControlKind &&
      typeByError
    ) {
      notificationOpen(
        'Необходимо заполнить срок получения ответа, вид контроля и контролера',
      );
    } else if (!values.deadlineDate && typeByError) {
      notificationOpen('Необходимо заполнить срок получения ответа');
    } else if (!values.ControlKind && typeByError) {
      notificationOpen('Необходимо заполнить вид контроля');
    } else if (!values.Controller && typeByError) {
      notificationOpen('Необходимо заполнить контролера');
      setShowError(true);
    } else {
      const apiUrl = getPoint().replace('id', String(DOCUMENT_CONTROL?.Id));
      request(apiUrl, {
        Id: DOCUMENT_CONTROL?.Id ?? 0,
        ControllerId: values.Controller ?? DOCUMENT_CONTROL?.ControllerId,
        DocumentId: documentInfo?.Id,
        Comment: values.Comment,
        ControlDate: values.deadlineDate?._d ?? DOCUMENT_CONTROL?.ControlDate,
        ControlKind: values.ControlKind ?? DOCUMENT_CONTROL?.ControlKind,
        ReceiveNotification: true, //уточнить
        ExecutorId: values.Controller ?? DOCUMENT_CONTROL?.ControllerId,
        ExecutionDate: values.ExecuteDate ? values.ExecuteDate._d : null,
        IsExecuted: type === 'mark-execution-document',
        RemovedFromControlDate: values.DeregisterDate
          ? values.DeregisterDate._d
          : null,
      })
        .then(() => {
          const documentType = `Documents${documentInfo?.Type}`;
          const documentId = documentInfo?.Id;
          successNotification(getMessage());
          getDocumentInfo({ documentType, documentId, dispatch });
          onClose();
        })
        .catch(({ response }) => errorThrow(response));
    }
  };

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader
          onClose={onClose}
          text={getTitle(type)}
          code="DOCUMENT_CONTROL"
        />
        <DocumentControlForm
          type={type}
          onFinish={onFinish}
          showError={showError}
          updateShowError={updateShowError}
        />
      </div>
    </Drawer>
  );
};
