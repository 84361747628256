import cn from 'classnames';
import { HelpBlock } from '../HelpBlock';

type TPageHeader = {
  title: any;
  code?: string | null;
  children?: any;
  className?: string;
};

export const PageHeader = ({
  children,
  title,
  code,
  className,
}: TPageHeader) => {
  return (
    <div
      className={cn('content_head', className)}
      style={{ alignItems: 'center' }}
    >
      <h1 className="mr15" style={{ marginBottom: 0, lineHeight: '38px' }}>
        {title}
      </h1>
      {children}
      {code && <HelpBlock code={code} />}
    </div>
  );
};
