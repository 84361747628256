import { $api } from 'shared/utils/api';
import { updateFiltersSidebar } from 'store/slice/sidebar';
import { AppDispatch } from 'store/store';
import { errorThrow } from 'shared/utils';

export const getFiltersSidebar = async ({
  dispatch,
}: {
  dispatch: AppDispatch;
}) => {
  const { data } = await $api.get('documentfilters/byuser', {
    params: {
      IsShowSideBar: true,
    },
  });

  try {
    await dispatch(updateFiltersSidebar(data));
  } catch ({ response }) {
    errorThrow(response);
  }
};
