import axios from 'axios';
import { prefix } from 'api/prefix';
import { getAuthToken } from './getAuthToken';

export const $api = axios.create({
  withCredentials: true,
  baseURL: prefix,
});

$api.interceptors.request.use(config => {
  config.headers = getAuthToken('auth_token');
  return config;
});
$api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);
