export const documentTypes = [
  {
    id: 0,
    type: 'Incoming',
    name: 'Входящие',
  },
  {
    id: 1,
    type: 'Outcoming',
    name: 'Исходящие',
  },
  {
    id: 2,
    type: 'Inner',
    name: 'Внутренние',
  },
];
