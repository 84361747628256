import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import moment from 'moment';
import { CreateGeneralInfo } from 'pages/CreateDocument/components/CreateGeneralInfo';
import { RegistrateDocument } from 'pages/CreateDocument/components/RegistrateDocument';
import { RouteComponent } from 'pages/CreateDocument/components/RouteComponent';
import { TabKeys } from 'pages/CreateDocument/constants';
import { TDocumentLink } from 'pages/CreateDocument/types';
import { useSelector } from 'react-redux';
import { OfficerDrawer } from 'shared/components';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton, DInput, DTextarea } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { ReduxState } from 'store/store';
import { DocumentVisibleEntities } from './types';
import { useCreateDocument } from './useCreateDocument';
import { DocumentLink } from './components/DocumentLink/DocumentLink';
import { CollapseItem } from './components/CollapseItem';
import './CreateDocument.scss';
import { PreLoader } from 'shared/components/PreLoader';
import { UploadFiles } from 'shared/components/Files';

const { TabPane } = Tabs;

export const CreateDocument = ({
  onClose,
}: {
  onClose?: (bool: boolean) => void;
}) => {
  const { documentInfo, sampleSettings } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const {
    visibleCatalog,
    onAddLink,
    visibleOfficerDrawer,
    selectedUser,
    getPageHeader,
    selectedTab,
    getPageHeaderCode,
    updateSelectedTab,
    formik,
    displayedDocumentType,
    updateSelectedUser,
    showError,
    showRouteComponent,
    documentLinks,
    formValues,
    getDocumentKindItem,
    FIRST_TAB_NAME,
    changeEntitiesVisible,
    DOCUMENT_TYPE,
    setCurrentFiles,
    currentFiles,
    onRemoveLinks,
    onChangeSubjectValue,
    onClickCloseButton,
    onClickNextButton,
    nextButtonText,
    isProcessTabDisabled,
    isRegistrationTabDisabled,
    onCloseDrawer,
    isLoading,
    disabledButton,
    organisationName,
    isCreatingDocument,
    incomingDisableButton,
    setIsCheckedRegNumber,
    setFormValues,
  } = useCreateDocument({ onClose });

  return (
    <>
      {(isLoading || disabledButton) && <PreLoader />}
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={() =>
            changeEntitiesVisible(false, DocumentVisibleEntities.Catalog)
          }
          type="document"
          func={(item: TDocumentLink) => onAddLink(item.Id)}
        />
      )}
      {visibleOfficerDrawer && (
        <OfficerDrawer
          visibleDrawer={visibleOfficerDrawer}
          onCloseDrawer={(visible: boolean) =>
            changeEntitiesVisible(false, DocumentVisibleEntities.Officer)
          }
          officerId={selectedUser}
        />
      )}

      {!documentInfo?.Id || sampleSettings ? (
        <div>
          {sampleSettings && (
            <CloseOutlined
              className="mr10 close-drawer-icon"
              onClick={onCloseDrawer}
            />
          )}
          <PageHeader title={getPageHeader()} code={getPageHeaderCode()} />
        </div>
      ) : null}
      <section id="create_document">
        <Tabs
          defaultActiveKey={TabKeys.CREATE}
          activeKey={selectedTab}
          onTabClick={updateSelectedTab}
        >
          <TabPane tab={FIRST_TAB_NAME} key={TabKeys.CREATE}>
            <form className="form" onSubmit={formik.handleSubmit}>
              <DFormItemTwo label="Тип документа" name="DocumentType">
                <b>{displayedDocumentType}</b>
              </DFormItemTwo>
              {getDocumentKindItem()}
              <DFormItemTwo label="Дата создания" name="CreateDateRequisite">
                <span>{moment().format('DD.MM.YYYY')}</span>
              </DFormItemTwo>
              <DFormItemTwo label="Организация" name="RegOrganisation">
                <span>{organisationName}</span>
              </DFormItemTwo>
              <DFormItemTwo label="Автор РКК" name="AUTHOR">
                <span
                  className="custom-link"
                  onClick={() => {
                    changeEntitiesVisible(
                      true,
                      DocumentVisibleEntities.Officer,
                    );
                    updateSelectedUser();
                  }}
                >
                  {!documentInfo || sampleSettings ? (
                    <span>
                      {user?.CurrentContact?.LastName}{' '}
                      {user?.CurrentContact?.FirstName}{' '}
                      {user?.CurrentContact?.MiddleName}
                    </span>
                  ) : (
                    <span>
                      {documentInfo?.AuthorLastName}{' '}
                      {documentInfo?.AuthorFirstName}{' '}
                      {documentInfo?.AuthorMiddleName}
                    </span>
                  )}
                </span>
              </DFormItemTwo>
              <DFormItemTwo label="Тема" required name="Subject">
                <DInput
                  minLength={3}
                  width="100%"
                  required={showError}
                  type="text"
                  value={formik.values.Subject}
                  name="Subject"
                  onChange={onChangeSubjectValue}
                />
              </DFormItemTwo>
              <DFormItemTwo
                style={{ alignItems: 'baseLine' }}
                label="Краткое содержание"
                name="Summary"
              >
                <DTextarea
                  maxLength={4000}
                  showCount
                  name="Summary"
                  value={formik.values.Summary}
                  className="w100per"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <CollapseItem
                formik={formik}
                type={DOCUMENT_TYPE}
                setIsCheckedRegNumber={setIsCheckedRegNumber}
              />
              <DFormItemTwo
                label="Связанные документы"
                style={{ alignItems: 'baseLine' }}
              >
                <div className="w100per">
                  <div className="w100per">
                    {documentLinks.map(item => (
                      <DocumentLink item={item} onRemoveLinks={onRemoveLinks} />
                    ))}
                  </div>
                  <span
                    className="custom-link"
                    onClick={() =>
                      changeEntitiesVisible(
                        true,
                        DocumentVisibleEntities.Catalog,
                      )
                    }
                  >
                    Создать связь
                  </span>
                </div>
              </DFormItemTwo>
              <DFormItemTwo
                label="Загрузка файлов"
                name="Files"
                className="form-item__with-list"
              >
                <UploadFiles
                  setCurrentFiles={setCurrentFiles}
                  currentFiles={currentFiles}
                  isShowBarcode={true}
                />
              </DFormItemTwo>
              <DFormItemTwo className="form-buttons">
                <DButton
                  small
                  defaultDanger
                  className="mr15"
                  onClick={onClickCloseButton}
                >
                  {documentInfo && !sampleSettings ? 'Закрыть' : 'Назад'}
                </DButton>
                {isCreatingDocument && (
                  <DButton
                    small
                    className="mr15"
                    defaultPrimary
                    type="submit"
                    onClick={() =>
                      changeEntitiesVisible(
                        false,
                        DocumentVisibleEntities.ContinueButton,
                      )
                    }
                  >
                    Сохранить
                  </DButton>
                )}
                <DButton
                  small
                  disabled={
                    showRouteComponent ||
                    disabledButton ||
                    incomingDisableButton
                  }
                  primary
                  type="submit"
                  onClick={onClickNextButton}
                >
                  <div className="button-span">
                    <span>{nextButtonText}</span>
                    {documentInfo && !sampleSettings ? null : (
                      <ArrowRightOutlined className="ml5" />
                    )}
                  </div>
                </DButton>
                {documentInfo && !sampleSettings && (
                  <DButton
                    small
                    className="ml15"
                    defaultPrimary
                    disabled={disabledButton}
                    type="submit"
                    onClick={() =>
                      changeEntitiesVisible(
                        true,
                        DocumentVisibleEntities.ContinueButton,
                      )
                    }
                  >
                    Далее
                  </DButton>
                )}
              </DFormItemTwo>
            </form>
          </TabPane>
          {DOCUMENT_TYPE === 'Incoming' && (
            <TabPane
              tab="Регистрация"
              key={TabKeys.REGISTRATION}
              disabled={isRegistrationTabDisabled}
            >
              <CreateGeneralInfo
                formValues={formValues}
                currentFiles={currentFiles}
                docType={DOCUMENT_TYPE}
              />
              <RegistrateDocument
                formValues={formValues}
                setFormValues={setFormValues}
                updateSelectedTab={updateSelectedTab}
                setShowRouteComponent={(visible: boolean) =>
                  changeEntitiesVisible(
                    visible,
                    DocumentVisibleEntities.RouteComponent,
                  )
                }
              />
            </TabPane>
          )}
          <TabPane
            tab="Процесс"
            disabled={isProcessTabDisabled}
            key={TabKeys.SEND_TO_ROUTE}
          >
            <RouteComponent
              updateSelectedTab={updateSelectedTab}
              docType={DOCUMENT_TYPE}
              formValues={formValues}
              currentFiles={currentFiles}
              onClose={onClose}
              setShowRouteComponent={() =>
                changeEntitiesVisible(
                  false,
                  DocumentVisibleEntities.RouteComponent,
                )
              }
            />
          </TabPane>
        </Tabs>
      </section>
    </>
  );
};
