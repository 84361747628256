import { Drawer, Select } from 'antd';
import { useFormik } from 'formik';
import { TDocumentType } from 'pages/AdminPage/DocumentKinds';
import { useCallback, useEffect, useState } from 'react';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DrawerHeader } from './DrawerHeader';

type TDocumentKindDrawer = {
  visible: boolean;
  onClose: () => void;
  item?: TDocumentType | null;
  type: string;
  getData: () => void;
};

export const DocumentKindDrawer = ({
  visible,
  onClose,
  item,
  type,
  getData,
}: TDocumentKindDrawer) => {
  const [documentKindList, setDocumentKindList] = useState<TDocumentType[]>([]);
  const { Option } = Select;
  let CURRENT_ACTION = null;

  const initialValues = {
    ParentId: item?.ParentId,
    Name: item?.Name,
    DocumentType: item?.DocumentType,
    DocumentKind: undefined,
  };

  switch (type) {
    case 'create':
      CURRENT_ACTION = 'Создать';
      break;
    case 'edit':
      CURRENT_ACTION = 'Редактировать';
      break;
    case 'delete':
      CURRENT_ACTION = 'Удалить';
      break;
    default:
      CURRENT_ACTION = '';
      break;
  }

  const geDocumentKinds = useCallback(() => {
    $api
      .get('documentkinds/byorganization')
      .then(res => {
        if (type === 'delete') {
          const newArr = res.data.filter(
            (t: TDocumentType) => t.Id !== item?.Id,
          );
          setDocumentKindList(newArr);
        } else setDocumentKindList(res.data);
      })
      .catch(({ response }) => errorThrow(response));
  }, [item?.Id, type]);

  useEffect(() => {
    geDocumentKinds();
  }, [geDocumentKinds]);

  const onFinish = (values: any) => {
    if (type !== 'delete') {
      const request = type === 'create' ? $api.post : $api.put;
      const isAddedId = type === 'edit' ? item?.Id : '';

      request(`documentkinds/${isAddedId}/byorganization`, {
        Id: item?.Id,
        ParentId: values.ParentId,
        Name: values.Name,
      })
        .then(() => {
          onClose();
          getData();
        })
        .catch(({ response }) => errorThrow(response));
    } else {
      $api
        .patch(`documentkinds/changedocumentkind`, null, {
          params: {
            OldDocumentKindId: item?.Id,
            NewDocumentKindId: values.DocumentKind,
          },
        })
        .then(() => {
          onClose();
          getData();
        })
        .catch(({ response }) => errorThrow(response));
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader
          onClose={onClose}
          text={`${CURRENT_ACTION} вид документа`}
        />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          {type === 'delete' ? (
            <>
              <DFormItemTwo label="Вид документа">
                <span>{item?.Name}</span>
              </DFormItemTwo>
              <DFormItemTwo label="Заменить на" name="DocumentKind">
                <Select
                  value={formik.values.DocumentKind}
                  className="ui-select"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={value =>
                    formik.setFieldValue('DocumentKind', value)
                  }
                >
                  {documentKindList?.map(item => (
                    <Option value={item.Id}>{item.Name}</Option>
                  ))}
                </Select>
              </DFormItemTwo>
            </>
          ) : (
            <>
              <DFormItemTwo label="Имя" name="Name">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.Name}
                  name="Name"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Родитель" name="ParentId">
                <Select
                  value={formik.values.ParentId}
                  className="ui-select"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  onChange={value => formik.setFieldValue('ParentId', value)}
                >
                  {documentKindList?.map(item => (
                    <Option value={item.Id}>{item.Name}</Option>
                  ))}
                </Select>
              </DFormItemTwo>
            </>
          )}
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              {CURRENT_ACTION}
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
