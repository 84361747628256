import { Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { PageHeader } from 'shared/components/PageHeader';
import { DocumentFlowDrawer } from './components';
import { DButton } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DeleteModal } from 'shared/components';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { DOCUMENT_FLOW } from 'shared/constants/helpCodes';
import { TDocument } from './types';
import { documentTypes } from './constants';
import cn from 'classnames';

const getDocumentTypeName = (type: string) =>
  documentTypes.filter(item => item.type === type).map(item => item.name)[0];

export const DocumentFlow = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentList, setDocumentList] = useState<TDocument[]>([]);
  const [selectedItem, setSelectedItem] = useState<TDocument>();
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [currentDocument, setCurrentDocument] = useState<TDocument>();
  const [activeButton, setActiveButtons] = useState<string>();
  const [type, setType] = useState<string>('');
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  const onClose = () => {
    setVisibleDrawer(false);
    setSelectedItem(undefined);
  };

  const getData = useCallback(
    (type?: string) => {
      setIsLoading(true);
      $api
        .get(`documentStreams/byorganisation/filter`, {
          params: {
            Limit: 1000,
            DocumentType: type ?? activeButton,
            OrganisationId: user?.CurrentContact?.OrganisationId,
            ForSelectedOrg: false,
          },
        })
        .then(res => setDocumentList(res.data.Data))
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    },
    [activeButton, user?.CurrentContact?.OrganisationId],
  );

  const onDelete = (Id: number) => {
    $api
      .delete(`documentstreams/${Id}/byorganisation`)
      .then(() => {
        getData(activeButton);
      })
      .catch(({ response }) => errorThrow(response));
  };

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Наименование',
      dataIndex: 'DocumentName',
      key: 'DocumentName',
      render: (_t: undefined, item: TDocument) =>
        `${getDocumentTypeName(item.DocumentType) ?? ''}  ${
          item.DocumentKindName
        }`,
    },
    {
      title: 'Действие',
      dataIndex: 'Flow',
      key: 'Flow',
      render: (text: string, item: TDocument) => (
        <>
          <DButton
            small
            defaultPrimary
            className="mr15"
            onClick={() => {
              setSelectedItem(item);
              setVisibleDrawer(true);
              setType('edit');
            }}
          >
            Редактировать
          </DButton>
          <DButton
            small
            defaultDanger
            onClick={() => {
              setVisibleModal(true);
              setCurrentDocument(item);
            }}
          >
            Удалить
          </DButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {visibleDrawer && (
        <DocumentFlowDrawer
          visible={visibleDrawer}
          type={type}
          item={selectedItem}
          getData={getData}
          onClose={onClose}
        />
      )}
      <PageHeader title="Документопоток" code={DOCUMENT_FLOW} />
      <div className="flex-space mb15">
        <div>
          <DButton
            onClick={() => {
              setVisibleDrawer(true);
              setType('create');
            }}
            small
            primary
          >
            + Создать
          </DButton>
          <DButton
            small
            className={cn('ml15', { 'selected-button': !activeButton })}
            onClick={() => {
              setActiveButtons(undefined);
              getData();
            }}
          >
            Все
          </DButton>
          {documentTypes.map(item => (
            <DButton
              className={cn('ml15', {
                'selected-button': activeButton === item.type,
              })}
              small
              onClick={() => {
                setActiveButtons(item.type);
                getData(item.type);
              }}
            >
              {item.name}
            </DButton>
          ))}
        </div>
      </div>
      <Table loading={isLoading} dataSource={documentList} columns={columns} />
      <DeleteModal
        title="Удаление документа"
        description="Вы уверены что хотите удалить документ?"
        visible={visibleModal}
        setVisible={setVisibleModal}
        onDelete={() => {
          setVisibleModal(false);
          currentDocument && onDelete(currentDocument?.Id);
        }}
      />
    </>
  );
};
