import React, { FC, ReactElement, useState } from 'react';
import logotype from 'shared/assets/images/updoc-logo.svg';
import { AtomSidebar } from './components/AtomSidebar';
import { Link } from 'react-router-dom';
import { HeaderSidebar } from './components/HeaderSidebar';
import { useSidebar } from './useSidebar';
import './Sidebar.scss';
import { Spin } from 'antd';

export const Sidebar: FC = (): ReactElement => {
  const [visible, setVisible] = useState<boolean>(true);
  const {
    sidebarItemsAPI,
    isLoading,
    updateActiveAtomClass,
    onClickHeaderItem,
    updateSelectedCollapse,
    selectedCollapse,
    updateActiveHeaderClass,
    updateClassOfDocumentLink,
    onClickSubAtomChild,
    onClickTitle,
  } = useSidebar();

  return (
    <Spin spinning={isLoading}>
      <div
        style={visible ? { left: '1%' } : { left: '35%' }}
        className="burger"
        onClick={() => setVisible(!visible)}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={`reponsive ${visible ? 'sidebar-wrapper' : ''}`}>
        <header className="page1 content_head">
          <Link
            to="/active-documents/page=1"
            onClick={() => {
              sessionStorage.setItem('uniqNumberAtom', '1');
              sessionStorage.setItem('uniqItem', '0');
            }}
          >
            <div className="sidebar__logo" onClick={onClickTitle}>
              <img src={logotype} alt="logo" />
              <h1>UpDoc</h1>
            </div>
          </Link>
        </header>
        <nav className="sidebar">
          <div className="sidebar__menu">
            {sidebarItemsAPI?.map(item => {
              if (item?.childrens?.length) {
                return (
                  <HeaderSidebar
                    item={item}
                    onClickHeaderItem={onClickHeaderItem}
                    updateSelectedCollapse={updateSelectedCollapse}
                    selectedCollapse={selectedCollapse}
                    updateActiveHeaderClass={updateActiveHeaderClass}
                    updateClassOfDocumentLink={updateClassOfDocumentLink}
                    onClickSubAtomChild={onClickSubAtomChild}
                  />
                );
              } else {
                return (
                  <AtomSidebar
                    item={item}
                    onClickItem={updateActiveAtomClass}
                  />
                );
              }
            })}
          </div>
        </nav>
        <footer className="page1 mb40 sidebar-info">
          <span className="company">bgTeam © 2021 UpDoc</span>
        </footer>
      </div>
    </Spin>
  );
};
