export type TSettingsName = {
  setting: string;
  Name: string;
  value?: null | number | string;
  formatter: string | null;
  text?: string | null;
  isReceived: boolean;
};

export const settingsName: TSettingsName[] = [
  {
    setting: 'SenderId',
    Name: 'Организация отправителя',
    value: null,
    formatter: 'Organisations',
    isReceived: true,
  },
  {
    setting: 'OrganisationReceiverId',
    Name: 'Организация получателя',
    value: null,
    formatter: 'Organisations',
    isReceived: true,
  },
  {
    setting: 'SendTypeId',
    Name: 'Способ доставки',
    value: null,
    formatter: 'sendtypes',
    isReceived: true,
  },
  {
    setting: 'OrgNomenclatureId',
    Name: 'Дело по номенклатуре',
    value: null,
    formatter: 'Nomenclatures',
    isReceived: true,
  },
  {
    setting: 'TagIds',
    value: null,
    Name: 'Тег',
    formatter: 'TagIds',
    isReceived: true,
  },
  {
    setting: 'WithByName',
    Name: 'По наименованию',
    value: null,
    formatter: 'bool',
    isReceived: false,
  },
  {
    setting: 'WithBySummary',
    Name: 'По краткому содержанию',
    value: null,
    formatter: 'bool',
    isReceived: false,
  },
  {
    setting: 'CorrespondentId',
    Name: 'Корреспондент',
    value: null,
    formatter: 'Contacts',
    isReceived: true,
  },
  {
    setting: 'AddresseeId',
    Name: 'Адресат',
    value: null,
    formatter: 'Contacts',
    isReceived: true,
  },
  {
    setting: 'AuthorId',
    Name: 'Автор',
    value: null,
    formatter: 'Contacts',
    isReceived: true,
  },
  {
    setting: 'ControllerId',
    Name: 'Контролер',
    value: null,
    formatter: 'Contacts',
    isReceived: true,
  },
  {
    setting: 'ExecutorId',
    Name: 'Исполнитель',
    value: null,
    formatter: 'Contacts',
    isReceived: true,
  },
  {
    setting: 'DocumentKindId',
    Name: 'Вид документа',
    value: null,
    formatter: 'DocumentKinds',
    isReceived: true,
  },
  {
    setting: 'DocumentType',
    Name: 'Тип документа',
    value: null,
    formatter: 'documentType',
    isReceived: false,
  },
  {
    setting: 'RegNumber',
    Name: 'Рег. номер',
    value: null,
    formatter: null,
    isReceived: false,
  },
  {
    setting: 'InRegNumber',
    Name: 'Рег. номер отправителя',
    value: null,
    formatter: null,
    isReceived: false,
  },
  {
    setting: 'DocumentId',
    Name: 'Системный номер',
    value: null,
    formatter: null,
    isReceived: false,
  },
  {
    setting: 'State',
    Name: 'Состояние',
    value: null,
    formatter: 'state',
    isReceived: false,
  },
  {
    setting: 'ControlStatus',
    Name: 'Статус',
    value: null,
    formatter: 'status',
    isReceived: false,
  },
  {
    Name: 'Дата создания с',
    setting: 'StartCreateDate',
    value: null,
    formatter: 'date',
    isReceived: false,
  },
  {
    Name: 'Дата создания по',
    setting: 'EndCreateDate',
    value: null,
    formatter: 'date',
    isReceived: false,
  },
  {
    Name: 'Дата регистрации с',
    setting: 'StartRegDate',
    value: null,
    formatter: 'date',
    text: null,
    isReceived: false,
  },
  {
    Name: 'Дата регистрации по',
    setting: 'EndRegDate',
    value: null,
    formatter: 'date',
    text: null,
    isReceived: false,
  },
  {
    Name: 'Контрольная дата с',
    setting: 'StartControlDate',
    value: null,
    formatter: 'date',
    text: null,
    isReceived: false,
  },
  {
    Name: 'Контрольная дата по',
    setting: 'EndControlDate',
    value: null,
    formatter: 'date',
    text: null,
    isReceived: false,
  },
  {
    Name: 'Исходящая дата регистрации с',
    setting: 'StartInRegDate',
    value: null,
    formatter: 'date',
    text: null,
    isReceived: false,
  },
  {
    Name: 'Исходящая дата регистрации по',
    setting: 'EndInRegDate',
    value: null,
    formatter: 'date',
    text: null,
    isReceived: false,
  },
];

export const ControlStatus = [
  {
    status: 'InControl',
    title: 'На контроле',
  },
  {
    status: 'NotRequired',
    title: 'Не обязателен',
  },
  {
    status: 'OutControl',
    title: 'Без контроля',
  },
];
