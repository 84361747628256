import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import { useCallback, useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { useHistory } from 'react-router-dom';
import { PhaseItem } from '../PhaseItem';
import { resetBreadCrumb, updateDocumentKindId } from 'store/slice/table';
import { useDispatch, useSelector } from 'react-redux';
import { DButton } from 'shared/ui-kit';
import { ReduxState } from 'store/store';
import {
  errorThrow,
  successNotification,
  successNotificationToRouteSend,
} from 'shared/utils';
import {
  updateASampleDocument,
  updateDocumentInfo,
} from 'store/slice/document';
import { CreateGeneralInfo } from '../CreateGeneralInfo';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { updateEstimations } from 'store/slice/assignments';
import { FlowDrawer } from 'shared/components/CustomDrawers/FlowDrawer';
import { TDocumentKind } from 'shared/components/CustomDrawers/ASampleDocumentDrawer';
import { TRouteComponent } from './types';
import '../../CreateDocument.scss';
import { TabKeys } from 'pages/CreateDocument/constants';
import _, { debounce } from 'lodash';
import { updatePhases } from 'store/slice/phases';
import { Phases } from 'pages/AssignmentCard/types';
import { reset } from 'store/slice/creatingDocument';

export const RouteComponent = ({
  formValues,
  currentFiles,
  docType,
  onClose,
  updateSelectedTab,
  setShowRouteComponent,
}: TRouteComponent) => {
  const [defaultPhases, setDefaultPhases] = useState<Phases[]>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [selectedRoute, setSelectedRoute] = useState<number | null>(null);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [currentDocumentStream, setCurrentDocumentStream] = useState<
    TDocumentKind
  >();
  const { DocumentKind, user } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const { phases } = useSelector((state: ReduxState) => state.phaseReducer);
  const { documentInfo, sampleSettings } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const documentType = `Documents${documentInfo?.Type}`;
  const documentId = documentInfo?.Id;

  const onCloseDrawer = () => setVisibleDrawer(false);

  const updatePhaseData = useCallback(
    data => {
      const phaseEstimations = data.map((item: Phases) => ({
        PhaseId: item.Id,
        Estimation: item.Estimation,
      }));
      setDefaultPhases(data);
      dispatch(updatePhases(data));
      dispatch(updateEstimations(phaseEstimations));
    },
    [dispatch],
  );

  useEffect(() => {
    if (documentInfo?.DocumentPhasesId && !sampleSettings)
      $api
        .get(`flows/ecmflowitems/byflow`, {
          params: {
            EntityId: documentInfo?.Id,
          },
        })
        .then(res => updatePhaseData(res.data))
        .catch(({ response }) => errorThrow(response));
  }, [dispatch, documentInfo, sampleSettings]);

  const checkIsStartRoute = useCallback(() => {
    $api
      .get(`Documents/${formValues?.Id ?? documentInfo?.Id}/flow/checkstart`)
      .then(() => setDisabledButton(false))
      .catch(({ response }) => errorThrow(response));
  }, [documentInfo?.Id, formValues?.Id]);

  const onSelectRoute = useCallback(
    id => {
      if (id) {
        $api
          .post(`Documents/${formValues?.Id ?? documentInfo?.Id}/flow/setup`, {
            RouteId: id,
          })
          .then(res => {
            setSelectedRoute(id);
            updatePhaseData(res.data);
            checkIsStartRoute();
          })
          .catch(({ response }) => errorThrow(response));
      }
    },
    [checkIsStartRoute, documentInfo?.Id, formValues?.Id, updatePhaseData],
  );

  const getDocumentStream = useCallback(() => {
    $api
      .get(`DocumentStreams/filter`, {
        params: {
          DocumentType: documentInfo?.Type,
          DocumentKindId: !!documentInfo?.DocumentKindId
            ? documentInfo?.DocumentKindId
            : DocumentKind?.Id,
          ForSelectedOrg:
            user?.CurrentContact?.OrganisationId ===
            documentInfo?.OrganisationId
              ? false
              : true,
          OrganisationId:
            user?.CurrentContact?.OrganisationId ===
            documentInfo?.OrganisationId
              ? documentInfo?.OrganisationId
              : undefined,
          SelectedOrganisationId:
            user?.CurrentContact?.OrganisationId !==
            documentInfo?.OrganisationId
              ? documentInfo?.OrganisationId
              : undefined,
        },
      })
      .then(({ data: { Data } }) => {
        if (!documentInfo?.DocumentPhasesId && Data[0]) {
          onSelectRoute(Data[0]?.FlowId);
        }
        setCurrentDocumentStream(Data[0]);
      })
      .catch(({ response }) => errorThrow(response));
  }, [
    DocumentKind?.Id,
    documentInfo?.DocumentKindId,
    documentInfo?.DocumentPhasesId,
    documentInfo?.OrganisationId,
    documentInfo?.Type,
    onSelectRoute,
    user?.CurrentContact?.OrganisationId,
  ]);

  useEffect(() => {
    if (documentInfo && !sampleSettings) {
      getDocumentStream();
    }
  }, [documentInfo, getDocumentStream, sampleSettings]);

  useEffect(() => {
    if (DocumentKind?.FlowId && formValues?.Id) {
      onSelectRoute(DocumentKind?.FlowId);
    }
    if (DocumentKind?.FlowId === null) {
      dispatch(updatePhases([]));
    }
  }, [DocumentKind?.FlowId, dispatch, onSelectRoute, formValues?.Id]);

  const updatePhase = (node: Phases) => {
    const newArray = phases.map(item => (item.Id === node?.Id ? node : item));
    dispatch(updatePhases(newArray));
  };

  const updateRequests = () => {
    const uniq = phases.reduce((acc: Phases[], item: Phases, index: number) => {
      if (!_.isEqual(item, defaultPhases[index])) {
        acc.push(item);
      }
      return acc;
    }, []);

    const requests = uniq.map((item: Phases) => {
      return $api.put(`flows/ecmflowitem/${item.Id}/data`, {
        DocumentId: formValues ? formValues?.Id : documentInfo?.Id,
        Id: item.PhaseId,
        Data: {
          IsRequired: item.IsRequired,
          SigningRequired: item.SigningRequired,
          Executions: !item.IsGroup
            ? item.Contacts
              ? item.Contacts.map(item => item.ContactId)
              : null
            : [],
          Estimation: item.Estimation,
          ExecutionGroups:
            item.IsGroup && item?.UserGroups?.length
              ? item.UserGroups.map(item => item.Id)
              : null,
        },
      });
    });
    return requests;
  };
  const onReset = () => {
    onClose?.(true);
    dispatch(reset());
    dispatch(updateDocumentKindId(null));
    sessionStorage.setItem('uniqNumberAtom', '1');
    sessionStorage.setItem('uniqItem', '0');
  };

  const findUniqValues = () => {
    Promise.all(updateRequests()).then(() => {
      $api
        .post(`Documents/${formValues?.Id ?? documentInfo?.Id}/flow/send`)
        .then(() => {
          successNotification('Документ успешно отправлен по процессу');
          if (!documentInfo || sampleSettings) {
            dispatch(updateASampleDocument());
            history.push('/active-documents/page=1');
            dispatch(updateDocumentInfo(null));
            dispatch(
              resetBreadCrumb([
                {
                  path: '/active-documents/page=1',
                  title: 'Мои документы',
                },
              ]),
            );
          } else if (documentInfo) {
            if (!sampleSettings)
              getDocumentInfo({ documentType, documentId, dispatch });
          }
          onReset();
          successNotificationToRouteSend(phases);
          dispatch(updatePhases([]));
        })
        .catch(({ response }) => errorThrow(response));
    });
  };

  const patchResponsible = () => {
    Promise.all(updateRequests()).then(() => {
      successNotification('Документ успешно сохранен');
      if (!documentInfo || sampleSettings) {
        history.push('/active-documents/page=1');
        dispatch(updateASampleDocument());
        dispatch(updateDocumentInfo(null));
      } else if (onClose) {
        !sampleSettings &&
          getDocumentInfo({ documentType, documentId, dispatch });
      }
      onReset();
      dispatch(updatePhases([]));
    });
  };

  const onResetRoute = async () => {
    await $api.post(
      `documents/${formValues?.Id ?? documentInfo?.Id}/flow/reset`,
    );

    try {
      setSelectedRoute(null);
      dispatch(updatePhases([]));
    } catch ({ response }) {
      errorThrow(response);
    }
  };

  return (
    <>
      <CreateGeneralInfo
        formValues={formValues}
        currentFiles={currentFiles}
        docType={docType}
      />
      {visibleDrawer && (
        <FlowDrawer
          visible={visibleDrawer}
          onClose={onCloseDrawer}
          documentStreamId={
            sampleSettings
              ? sampleSettings?.DocumentStreamId
              : currentDocumentStream
              ? currentDocumentStream?.Id
              : DocumentKind?.DocumentStreamId
          }
          onSelect={onSelectRoute}
        />
      )}
      <>
        <h2 className="mt15">Процесс</h2>
        <div className="mb15 form">
          <DFormItemTwo label="Выбор процесса">
            <DButton
              className="mr15"
              small
              primary
              onClick={() => setVisibleDrawer(true)}
            >
              Выбрать
            </DButton>
            <DButton
              small
              defaultPrimary
              className="mr15"
              disabled={!selectedRoute}
              onClick={() => {
                onSelectRoute(selectedRoute);
              }}
            >
              По умолчанию
            </DButton>
            <DButton
              small
              defaultDanger
              className="mr15"
              type="reset"
              onClick={() => onResetRoute()}
            >
              Cбросить
            </DButton>
          </DFormItemTwo>
        </div>
        {phases.length ? (
          <>
            <h2>Этапы</h2>
            <div className="form">
              <div className="phase-wrapper">
                {phases.map(phase => (
                  <PhaseItem updatePhase={updatePhase} phase={phase} />
                ))}
              </div>
            </div>
          </>
        ) : null}
      </>
      <div className="route-buttons">
        <DButton
          small
          defaultDanger
          className="mr15"
          onClick={() => {
            setShowRouteComponent();
            if (docType !== 'Incoming') updateSelectedTab(TabKeys.CREATE);
            else updateSelectedTab(TabKeys.REGISTRATION);
          }}
        >
          Назад
        </DButton>
        <DButton
          onClick={findUniqValues}
          disabled={disabledButton && !documentInfo}
          className="mr15"
          primary
          small
        >
          Отправить по процессу
        </DButton>
        <DButton defaultPrimary small onClick={() => patchResponsible()}>
          Сохранить
        </DButton>
      </div>
    </>
  );
};
