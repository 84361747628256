import { UploadOutlined } from '@ant-design/icons';
import { Progress, Upload } from 'antd';
import { DButton } from 'shared/ui-kit';
import { useUploadFiles } from './useUploadFiles';
import './Files.scss';
import { Dispatch } from 'react';
import { FileListParams } from './types';
import { UploadByBarCode } from './UploadByBarCode';

export type UploadFilesProps = {
  setCurrentFiles: Dispatch<React.SetStateAction<FileListParams[]>>;
  currentFiles: FileListParams[];
  isShowBarcode?: boolean;
};

export const UploadFiles = ({
  setCurrentFiles,
  currentFiles,
  isShowBarcode = false,
}: UploadFilesProps) => {
  const {
    uploadProps,
    isLoading,
    progress,
    disabledButton,
    setVisibleBarCode,
    visibleBarCode,
  } = useUploadFiles({
    setCurrentFiles,
    currentFiles,
    isShowBarcode,
  });

  return (
    <article id="file-upload">
      {isLoading ? (
        'Загрузка...'
      ) : (
        <>
          {visibleBarCode && (
            <UploadByBarCode
              visible={visibleBarCode}
              onClose={() => setVisibleBarCode(false)}
              setCurrentFiles={setCurrentFiles}
            />
          )}
          <div className="upload-wrapper">
            <Upload {...uploadProps}>
              <DButton
                disabled={disabledButton}
                small
                defaultPrimary
                className="mr15"
              >
                <UploadOutlined /> Загрузить
              </DButton>
            </Upload>
            {isShowBarcode && (
              <DButton
                disabled={disabledButton}
                small
                defaultPrimary
                onClick={() => setVisibleBarCode(true)}
              >
                <UploadOutlined /> Загрузить по коду
              </DButton>
            )}
          </div>
          {progress > 0 ? <Progress size="small" percent={progress} /> : null}
        </>
      )}
    </article>
  );
};
