import moment from 'moment';
import { DocumentTypes } from 'pages/DocumentPage/types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { OfficerDrawer } from 'shared/components';
import { OrganisationDrawer } from 'shared/components';
import { RedirectByMedo } from 'shared/components';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { ReduxState } from 'store/store';
import { CurrentDocument, TSendingContext } from 'types/document';
import { sendingStates } from './DocumentSendContext';
import './ContextItem.scss';
import { Icon } from 'shared/components/IconComponent';
import warningSvg from 'shared/assets/images/warning.svg';
import { ErrorModal } from './ErrorModal';
import { getFullName } from 'shared/utils';

export const ContextItem = ({
  sendItem,
  currentDocument,
  canEdit,
}: {
  sendItem: TSendingContext;
  currentDocument: CurrentDocument;
  canEdit: boolean;
}) => {
  const [visibleRedirect, setVisibleRedirect] = useState<boolean>(false);
  const [visibleOfficerDrawer, setVisibleOfficerDrawer] = useState<boolean>(
    false,
  );
  const [visibleOrganisationDrawer, setVisibleOrganisaionDrawer] = useState<
    boolean
  >(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(false);

  const correspondentName = getFullName({
    lastName: sendItem.CorrespondentLastName,
    firstName: sendItem.CorrespondentFirstName,
    middleName: sendItem.CorrespondentMiddleName,
  });

  const addresseeName = getFullName({
    lastName: sendItem.AddresseeLastName,
    firstName: sendItem.AddresseeFirstName,
    middleName: sendItem.AddresseeMiddleName,
  });

  const regDate =
    sendItem?.RegDate && `от ${moment(sendItem?.RegDate).format('DD.MM.YYYY')}`;
  const sendStatus = sendingStates.find(item => item.State === sendItem.State)
    ?.Name;

  const { configureButtons } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );

  const onCloseOfficerDrawer = (bool: boolean) => {
    setVisibleOfficerDrawer(bool);
  };

  const onCloseOrganisationDrawer = (bool: boolean) => {
    setVisibleOrganisaionDrawer(bool);
  };

  const onSelectContact = (id: number) => {
    setVisibleOfficerDrawer(true);
    setSelectedId(id);
  };

  const onSelectOrganisation = (id: number) => {
    setVisibleOrganisaionDrawer(true);
    setSelectedId(id);
  };

  const onCloseRedirect = () => setVisibleRedirect(false);

  return (
    <>
      {showError && (
        <ErrorModal
          visible={showError}
          onClose={() => setShowError(false)}
          text={sendItem.Errors}
        />
      )}
      {visibleRedirect && (
        <RedirectByMedo visible={visibleRedirect} onClose={onCloseRedirect} />
      )}
      {visibleOrganisationDrawer && (
        <OrganisationDrawer
          visibleDrawer={visibleOrganisationDrawer}
          onCloseDrawer={onCloseOrganisationDrawer}
          organisationId={selectedId}
        />
      )}
      {visibleOfficerDrawer && (
        <OfficerDrawer
          visibleDrawer={visibleOfficerDrawer}
          onCloseDrawer={onCloseOfficerDrawer}
          officerId={selectedId}
        />
      )}
      <DFormItemTwo label="Доставка">
        {sendItem?.SendTypeName ?? '-'}{' '}
        {configureButtons?.Redirect && canEdit ? (
          <span
            className="ml15 custom-link"
            onClick={() => setVisibleRedirect(true)}
          >
            Перенаправить
          </span>
        ) : null}
      </DFormItemTwo>
      {currentDocument?.Type === DocumentTypes.Outcoming && (
        <>
          <DFormItemTwo label="Статус отправки">
            <p className="mb0 mr15">{sendStatus}</p>
            {sendItem.Errors ? (
              <Icon
                icon={warningSvg}
                alt="warning icon"
                tooltip="Информация по ошибке"
                onClick={() => setShowError(true)}
              />
            ) : null}
          </DFormItemTwo>
          <DFormItemTwo label="Корреспондент">
            <span
              className="subject"
              onClick={() => onSelectContact(sendItem?.CorrespondentId)}
            >
              {correspondentName}
            </span>
          </DFormItemTwo>
          <DFormItemTwo
            className="form-item__with-list"
            label="Получатели (организации)"
          >
            <span
              onClick={() => onSelectOrganisation(sendItem.OrganisationId)}
              className="custom-link"
            >
              {sendItem?.OrganisationName}
            </span>
          </DFormItemTwo>
        </>
      )}
      {currentDocument?.Type === DocumentTypes.Incoming && (
        <>
          <DFormItemTwo label="Рег. номер и дата">
            {sendItem?.RegNumber} {regDate}
          </DFormItemTwo>
          <DFormItemTwo
            label="Отправители (организации)"
            className="form-item__with-list"
          >
            {sendItem?.SenderId ? (
              <div className="document-sender__info">
                <span
                  className="subject"
                  onClick={() => onSelectOrganisation(sendItem?.SenderId)}
                >
                  {sendItem?.SenderName}
                </span>
                <span
                  className="subject"
                  onClick={() => onSelectContact(sendItem?.CorrespondentId)}
                >
                  {correspondentName}
                </span>
              </div>
            ) : (
              '-'
            )}
          </DFormItemTwo>
        </>
      )}
      <DFormItemTwo label="Адресат">
        <span
          className="subject"
          onClick={() => onSelectContact(sendItem?.AddresseeId)}
        >
          {addresseeName}
        </span>
      </DFormItemTwo>
    </>
  );
};
