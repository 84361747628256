import { AtomOrganization } from './AtomOrganization';
import './statistics.scss';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Skeleton } from 'antd';

const OrganizationStatistics = ({ departList }: any) => {
  const [maxNumber, setMaxNumber] = useState<number>();

  useEffect(() => {
    const tmp = [];

    for (let prop in departList) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      departList[prop]?.length >= 0 ? tmp.push(departList[prop]?.length) : null;
    }
    setMaxNumber(Math.ceil(Math.max(...tmp) / 100) * 100);
  }, [departList]);

  return (
    <div className="infoBlock">
      <h2 className="organizationName">{departList.Name}</h2>
      <div className="information">
        <p style={{ minWidth: 300, paddingTop: 10 }}>Руководитель </p>
        <p>
          {departList.HeadFirstName} {departList.HeadMiddleName}{' '}
          {departList.HeadLastName}
        </p>
      </div>
      <div className="information">
        <p style={{ minWidth: 300, paddingTop: 10 }}>Сотрудников </p>
        <p>{departList.Employees?.length}</p>
      </div>
      <AtomOrganization
        type="Исходящих документов"
        count={departList.OutcomingDocuments}
        point="Documents"
      />
      <AtomOrganization
        type="Входящих документов"
        count={departList.IncomingDocuments}
        point="Documents"
      />
      <AtomOrganization
        type="Исполнено документов"
        count={departList.CompletedDocuments}
        point="Documents"
      />
      <AtomOrganization
        type="Получено документов"
        count={departList.ReceivedDocuments}
        point="Documents"
      />
      <AtomOrganization
        type="Задач выполнено"
        count={departList.CompleteAssignments}
        point="Assignments"
      />
      <AtomOrganization
        type="Задач просрочено"
        count={departList.ExpiredAssignments}
        point="Assignments"
      />
      <AtomOrganization
        type="Контрольных документов"
        count={departList.DocumentsOnControl}
        point="Documents"
      />
      <AtomOrganization
        type="Исходящие МЭДО"
        count={departList.OutcomingMedoDocuments}
        point="Documents"
      />
      <AtomOrganization
        type="Входящие МЭДО"
        count={departList.IncomingMedoDocuments}
        point="Documents"
      />
      {maxNumber !== undefined ? (
        <motion.div
          animate={{ width: [0, maxNumber] }}
          className="mainStatistic"
          style={{
            width: maxNumber,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          ></div>
        </motion.div>
      ) : (
        <Skeleton />
      )}
      <div
        style={{
          width: maxNumber,
          marginLeft: 350,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p>0</p>
        <p>{maxNumber}</p>
      </div>
    </div>
  );
};

export { OrganizationStatistics };
