import { Table } from 'antd';
import { findParent } from 'api/assignments/fetchAssignmentsData';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DepartmentDrawer } from 'shared/components/CustomDrawers/DepartmentDrawer';
import { UploadBookDrawer } from 'shared/components/CustomDrawers/UploadBookDrawer/UploadBookDrawer';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { DEPARTMENTS } from 'shared/constants/helpCodes';
import { Icon } from 'shared/components/IconComponent';
import searchSvg from 'shared/assets/images/search.svg';
import { debounce } from 'lodash-es';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';

export type TDepartment = {
  Id: number;
  ParentId: number;
  OrganisationId: number;
  Name: string;
  Kpp: number;
  ShortNotation: string;
};

export const Departments = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [departmentList, setDepartmentList] = useState<TDepartment[]>([]);
  const [selectedItem, setSelectedItem] = useState<TDepartment>();
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const [visibleUpload, setVisibleUpload] = useState<boolean>(false);

  const onCloseUpload = () => setVisibleUpload(false);

  const onClose = () => {
    setVisibleDrawer(false);
    setSelectedItem(undefined);
  };

  const getData = useCallback(
    (query?: string) => {
      if (user?.CurrentContact?.OrganisationId) {
        setIsLoading(true);
        $api
          .get(`departments/byorganisation/page`, {
            params: {
              Limit: 1000,
              FastSearch: query,
              OrganisationId: user?.CurrentContact?.OrganisationId,
            },
          })
          .then(({ data: { Data } }) => {
            setDepartmentList(findParent(Data));
          })
          .catch(({ response }) => errorThrow(response))
          .finally(() => setIsLoading(false));
      }
    },
    [user?.CurrentContact?.OrganisationId],
  );

  const onDelete = (Id: number) => {
    $api
      .delete(`Departments/${Id}/byorganization`)
      .then(() => {
        onClose();
        getData();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'КПП',
      dataIndex: 'Kpp',
      key: 'Kpp',
    },
    {
      title: 'Краткое описание',
      dataIndex: 'ShortNotation',
      key: 'ShortNotation',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: TDepartment) => (
        <>
          <DButton
            small
            defaultPrimary
            className="mr15"
            onClick={() => {
              setSelectedItem(item);
              setVisibleDrawer(true);
              setType('edit');
            }}
          >
            Редактировать
          </DButton>
          <DButton small defaultDanger onClick={() => onDelete(item.Id)}>
            Удалить
          </DButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  const onSearch = useCallback(
    debounce((value: string) => {
      getData(value);
    }, DEBOUNCE_TIME),
    [],
  );

  return (
    <>
      {visibleUpload && (
        <UploadBookDrawer
          visible={visibleUpload}
          onClose={onCloseUpload}
          type="department"
          title="департаментов"
          getData={getData}
        />
      )}
      {visibleDrawer && (
        <DepartmentDrawer
          type={type}
          visible={visibleDrawer}
          onClose={onClose}
          item={selectedItem}
          getData={getData}
          searchValue={searchValue}
        />
      )}
      <PageHeader title="Подразделения" code={DEPARTMENTS} />
      <div className="flex-space mb15">
        <DButton
          className="mr15"
          onClick={() => {
            setVisibleDrawer(true);
            setType('create');
          }}
          small
          primary
        >
          + Создать
        </DButton>
        <DInput
          type="text"
          style={{ maxWidth: 630, width: '100%' }}
          placeholder="Поиск по наименованию"
          icon={<Icon icon={searchSvg} alt="searchSvg" />}
          onChange={({ target: { value } }) => {
            setSearchValue(value);
            onSearch(value);
          }}
          value={searchValue}
        />
        <DButton
          className="ml15"
          small
          defaultPrimary
          onClick={() => setVisibleUpload(true)}
        >
          Загрузить
        </DButton>
      </div>
      <Table
        loading={isLoading}
        dataSource={departmentList}
        columns={columns}
      />
    </>
  );
};
