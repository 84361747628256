import { FC, ReactElement, useEffect, useState } from 'react';
import { InputProps, Status } from './types';
import cn from 'classnames';
import './styles/Input.scss';
import { useBem } from '../../hooks';
import { ValidationIcon } from './ValidationIcon';
import { upperFirstLetter } from '../../utils/upperFirstLetter';

export const Input: FC<InputProps> = ({
  style,
  className,
  prefix,
  label,
  size = 's30',
  status,
  message,
  disabled,
  required,
  highlight,
  ...props
}): ReactElement => {
  const showValidationMessage = !!required && !!status;
  const showIcon = !!required && !!message && !!status;
  const bem = useBem('Input');

  const wrapperClassName = cn(
    bem({
      [status as string]: showIcon && !!highlight && !!status,
    }),
    className,
    { [bem(['disabled'])]: disabled },
  );
  const messageClassName = cn(bem('message'), {
    [bem(['success'])]: status === Status.Success,
    [bem(['error'])]: status === Status.Error,
  });

  return (
    <div style={style} className={wrapperClassName}>
      {label && (
        <label className={bem('label')}>{upperFirstLetter(label)}</label>
      )}
      <div className={cn(bem('container'), bem([size]))}>
        {prefix && <span className="prefix">{prefix}</span>}
        <input disabled={disabled} {...props} />
        {showIcon && <ValidationIcon status={status} />}
      </div>

      {showValidationMessage && (
        <span className={messageClassName}>{message}</span>
      )}
    </div>
  );
};
