import { AssignmentType } from 'types/document';
import { useCallback, useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { AssignmentMenu } from './components';
import { errorThrow, getAssignmentExecutorName } from 'shared/utils';
import {
  updateASampleDocument,
  updateDocumentInfo,
} from 'store/slice/document';
import { useDispatch, useSelector } from 'react-redux';
import { DButton } from 'shared/ui-kit';
import { ReduxState } from 'store/store';
import { AssignmentFilterDrawer } from 'shared/components/CustomDrawers/AssignmentFilterDrawer';
import { debounce } from 'lodash-es';
import { Table } from 'antd';
import {
  selectActiveAssignment,
  TFilterType,
  updateAssignmentFilterConfig,
  updateAssignmentList,
  updateFilteredCount,
  updateFilterType,
  updateIsLoading,
  updatePagination,
} from 'store/slice/assignments';
import { format } from 'date-fns';
import { AssignmentDrawer } from 'shared/components';
import { DTag } from 'shared/ui-kit/DTag';
import cn from 'classnames';
import './AllAssignmentsPage.scss';
import { DEFAULT_PAGINATION } from './constants';
import { AssignmentTag } from './types';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { useHistory } from 'react-router-dom';
import { fetchAssignmentList } from 'api/assignments/fetchAssignmentList';
import { dateCompare } from 'shared/utils/dateCompare';
import { getRegNumberAndDate } from './helpers';

export const AllAssignmentsPage = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [assignmentDrawer, setAssignmentDrawer] = useState<boolean>(false);
  const {
    filterType,
    pagination,
    filteredCount,
    searchValue,
    config,
    assignmentList,
    isLoading,
    activeAssignmentSortParams,
  } = useSelector((state: ReduxState) => state.assignmentReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const onClose = () => setVisible(false);
  const onCloseAssignmentDrawer = () => setAssignmentDrawer(false);
  sessionStorage.removeItem('documentType');

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Тема',
      dataIndex: 'Subject',
      key: 'Subject',
      render: (subject: string, item: AssignmentType) => (
        <div className="subject-container">
          <span
            className={cn('custom-link', 'subject-text')}
            onClick={() => {
              setAssignmentDrawer(true);
              dispatch(selectActiveAssignment(item.Id));
            }}
          >
            {subject}
          </span>
          <div>
            {item?.IsDelegated && (
              <DTag className="tag-subject" type="small">
                {AssignmentTag.IsDelegated}
              </DTag>
            )}
            {item?.IsConsolidated && (
              <DTag
                className={cn('tag-subject', {
                  'tag-without-child': !item.OpenChildExists,
                })}
                type="small"
              >
                {AssignmentTag.IsConsolidated}
              </DTag>
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'Документ',
      dataIndex: 'DocumentId',
      key: 'DocumentId',
      render: (docId: number, item: AssignmentType) => (
        <span
          className="subject"
          onClick={() =>
            history.push(
              `/active-documents/DocumentsUnknown/${item.DocumentId}`,
            )
          }
        >
          Документ №{docId} - {item.DocumentSubject}
        </span>
      ),
    },
    {
      title: 'Рег. номер и дата',
      dataIndex: 'DocumentRegNumber',
      key: 'DocumentRegNumber',
      render: (_d: undefined, item: AssignmentType) =>
        getRegNumberAndDate(item),
    },
    {
      title: 'Исполнитель',
      dataIndex: 'ExecutorId',
      key: 'ExecutorId',
      render: (_d: undefined, item: AssignmentType) =>
        getAssignmentExecutorName(item),
    },
    {
      title: 'Автор',
      dataIndex: 'AuthorId',
      key: 'AuthorId',
      render: (_d: undefined, item: AssignmentType) => (
        <span>
          {item.AuthorLastName} {item.AuthorFirstName} {item.AuthorMiddleName}
        </span>
      ),
    },
    {
      title: 'Время исполнения',
      dataIndex: 'ExecutionTime',
      key: 'ExecutionTime',
      render: (
        date: string,
        { ExecutionTime, ExecutionFactTime }: AssignmentType,
      ) => (
        <span className={dateCompare(ExecutionTime, ExecutionFactTime)}>
          {format(new Date(date), 'dd.MM.yyyy')}
        </span>
      ),
    },
  ];

  const getAllAssignments = useCallback(
    ({ query, array }: { query?: string; array?: AssignmentType[] }) => {
      dispatch(updateIsLoading(true));
      if (Array.isArray(array)) {
        dispatch(updateAssignmentList(array));
        dispatch(updateIsLoading(false));
      } else {
        fetchAssignmentList(
          dispatch,
          { type: filterType, query, pagination },
          activeAssignmentSortParams,
        );
      }
    },
    [dispatch, filterType, pagination, activeAssignmentSortParams],
  );

  const onSearchWithFilter = useCallback(
    debounce(value => {
      $api
        .get('Assignments/filter', {
          params: { ...config, FastSearch: value },
        })
        .then(({ data: { Data, FilteredCount } }) => {
          dispatch(updateAssignmentList(Data));
          dispatch(updateFilteredCount(FilteredCount));
        })
        .catch(({ response }) => errorThrow(response));
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    dispatch(updateDocumentInfo(null));
    sessionStorage.removeItem('documentId');
    dispatch(updateASampleDocument(undefined));
  }, [dispatch, filterType]);

  useEffect(() => {
    if (!config) {
      getAllAssignments({ query: searchValue });
    }
  }, [config, filterType, pagination.page, pagination.pageSize]);

  useEffect(() => {
    return () => {
      dispatch(updateAssignmentFilterConfig(null));
    };
  }, [dispatch]);

  const onChangePagination = (page: number, pageSize: number) => {
    dispatch(updatePagination({ page, pageSize }));
  };

  const onClickFilter = (type: TFilterType) => {
    dispatch(updateFilterType(type));
    onChangePagination(DEFAULT_PAGINATION.page, DEFAULT_PAGINATION.pageSize);
  };

  return (
    <section id="assignments-table">
      {visible && (
        <AssignmentFilterDrawer
          visible={visible}
          onClose={onClose}
          updateTable={getAllAssignments}
        />
      )}
      {assignmentDrawer && (
        <AssignmentDrawer
          visibleDrawer={assignmentDrawer}
          onCloseDrawer={onCloseAssignmentDrawer}
        />
      )}
      <AssignmentMenu
        onUpdateTable={getAllAssignments}
        onSearchWithFilter={onSearchWithFilter}
      />
      <div
        style={config ? { justifyContent: 'flex-end' } : {}}
        className="mb15 flex-space"
      >
        {!config && (
          <div>
            <DButton
              onClick={() => onClickFilter('executor')}
              className={`mr15 ${filterType === 'executor' &&
                'selected-button'}`}
              small
            >
              Выданные мне
            </DButton>
            <DButton
              className={`mr15 ${filterType === 'author' && 'selected-button'}`}
              onClick={() => onClickFilter('author')}
              small
            >
              Выданные мной
            </DButton>
            <DButton
              className={`mr15 ${filterType === 'overdue' &&
                'selected-button'}`}
              onClick={() => onClickFilter('overdue')}
              small
            >
              Просроченные
            </DButton>
            <DButton
              className={`mr15 ${filterType === 'executed' &&
                'selected-button'}`}
              onClick={() => onClickFilter('executed')}
              small
            >
              Завершенные
            </DButton>
            <DButton
              onClick={() => onClickFilter('all')}
              className={`mr15 ${
                filterType === 'all' ? 'selected-button' : ''
              }`}
              small
            >
              Все
            </DButton>
          </div>
        )}
        <span className="custom-link" onClick={() => setVisible(true)}>
          Расширенный поиск
        </span>
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={assignmentList}
        pagination={{
          position: ['bottomRight'],
          defaultCurrent: 1,
          defaultPageSize: 15,
          hideOnSinglePage: false,
          showSizeChanger: false,
          onChange: onChangePagination,
          current: pagination?.page,
          total: filteredCount,
          showTotal: () => filteredCount,
        }}
      />
    </section>
  );
};
