import { updateDataTable } from 'store/slice/table';
import { TableResult } from 'types/tableData';
import { AppDispatch } from 'store/store';

type DocumentListParams = {
  dispatch: AppDispatch;
  data: any;
  // {
  //   Data: TableResult[];
  //   FilteredCount: number;
  // };
};

export const getDocumentsList = ({ data, dispatch }: DocumentListParams) => {
  if (data.Data === null) {
    dispatch(updateDataTable({ documents: [], filteredCount: 0 }));
    return;
  }
  let newArr: any;
  if (data.Data)
    newArr = data.Data.map((item: TableResult) => {
      let key = { key: item.Id };
      return Object.assign(key, item);
    });
  else
    newArr = data?.map((item: TableResult) => {
      let key = { key: item.Id };
      return Object.assign(key, item);
    });
  dispatch(
    updateDataTable({ documents: newArr, filteredCount: data.FilteredCount }),
  );
};
