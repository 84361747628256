import { SortFilters } from './types';

export const getDefaultActiveParams = (array: SortFilters[]) => {
  return array
    .map(filter =>
      filter.options
        .map(option =>
          option.enabled
            ? {
                title: filter.title,
                option: option.type,
              }
            : undefined,
        )
        .filter(item => item),
    )
    .flat()[0];
};
