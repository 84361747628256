import { Checkbox, DatePicker, Form, Input, Select, Table } from 'antd';

type Flag = {
  flag: boolean;
};

export const Forms = ({ flag }: Flag) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: 'Контакт',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Доп. сведения',
      dataIndex: 'age',
      key: 'age',
    },
  ];

  const forms = () => {
    if (flag) {
      return (
        <>
          <Form.Item label="Организация" name="Organisations">
            <Select>
              <Option value="lucy">Сотрудники</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Должность" name="Position">
            <Select>
              <Option value="lucy">Lucy</Option>
            </Select>
          </Form.Item>
          <Form.Item name="OnlyInSelfOrganisation" className="ml207">
            <Checkbox>Не показывать запрещенные</Checkbox>
          </Form.Item>
        </>
      );
    } else {
      return (
        <>
          <Form.Item label="Адрес" name="Position">
            <Select>
              <Option value="lucy">Lucy</Option>
            </Select>
          </Form.Item>
          <Form.Item name="OnlyInSelfOrganisation" className="ml207">
            <Checkbox>Только в своей организации</Checkbox>
          </Form.Item>
          <Form.Item label="Организация" name="Organisations">
            <Select>
              <Option value="lucy">Сотрудники</Option>
            </Select>
          </Form.Item>
          <Form.Item name="OnlyInSelfOrganisation" className="ml207">
            <Checkbox>Не показывать запрещенные</Checkbox>
          </Form.Item>
        </>
      );
    }
  };

  const data = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
    },
    {
      key: '2',
      name: 'Jim Green',
      age: 42,
    },
    {
      key: '3',
      name: 'Joe Black',
      age: 32,
    },
  ];

  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <Form
      className="form"
      name="basic"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="Папка" name="Folder">
        <Select>
          <Option value="lucy">Lucy</Option>
        </Select>
      </Form.Item>
      <Form.Item name="checkDoc" className="ml207">
        <Checkbox>Мои документы</Checkbox>
      </Form.Item>
      <Form.Item label="Дата регистрации" name="RegistrationDate">
        <RangePicker className="ml100" />
      </Form.Item>
      <Form.Item label="Дата создания" name="CreateDate">
        <RangePicker className="ml100" />
      </Form.Item>
      <Form.Item label="Регистрационный номер" name="RegNumber">
        <Input />
      </Form.Item>
      <Form.Item label="Системный номер" name="SysNumber">
        <Input />
      </Form.Item>
      <Form.Item label="Документ содержит" name="DocumentHas">
        <Input />
      </Form.Item>
      <Form.Item label="Наименование" name="Name">
        <Input />
      </Form.Item>
      <Form.Item label="Искать только" name="SearchFor">
        <Select>
          <Option value="lucy">Сотрудники</Option>
        </Select>
      </Form.Item>
      <Form.Item name="OnlyInSelfOrganisation" className="ml207">
        <Checkbox>Только в своей организации</Checkbox>
      </Form.Item>
      {forms()}
      <Form.Item label="Из группы" name="FromGroup">
        <Select>
          <Option value="lucy">ОИВ ВО - ГБУ ВО</Option>
        </Select>
      </Form.Item>
      <Table
        pagination={{
          position: ['bottomRight'],
          defaultCurrent: 1,
          defaultPageSize: 15,
          hideOnSinglePage: false,
          showSizeChanger: false,
          pageSizeOptions: ['15', '20', '25'],
          showTotal: total => total,
        }}
        columns={columns}
        dataSource={data}
      />
    </Form>
  );
};
