import { Drawer, Skeleton } from 'antd';
import { useAssignments } from 'pages/AssignmentCard';
import { AssignmentHeaderShow } from 'pages/AssignmentCard/components/AssignmentHeaderShow';
import React, { useCallback, useEffect, useState } from 'react';
import { AssignmentType } from 'types/document';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { useLocation } from 'react-router-dom';
import { updateDocumentInfo } from 'store/slice/document';
import { updateSignedInfo } from 'store/slice/assignments';

type TAssignmentDrawer = {
  visibleDrawer: boolean;
  onCloseDrawer: (bool: boolean) => void;
};

export const AssignmentDrawer = ({
  visibleDrawer,
  onCloseDrawer,
}: TAssignmentDrawer) => {
  const [assignment, setAssignment] = useState<AssignmentType>();
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const { activeAssignment } = useSelector(
    (state: ReduxState) => state.assignmentReducer,
  );

  const getAssignment = useCallback(() => {
    if (activeAssignment) {
      setLoader(true);
      $api
        .get(`Assignments/${activeAssignment}/full`)
        .then(res => {
          setAssignment(res.data);
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setLoader(false));
    }
  }, [activeAssignment]);

  const { assignmentInfoForm } = useAssignments({
    assignment,
    onCloseDrawer,
    getAssignment,
  });

  useEffect(() => {
    getAssignment();
  }, [getAssignment]);

  return (
    <Drawer
      placement="right"
      onClose={() => {
        onCloseDrawer(false);
        if (location.pathname.split('/').length < 3)
          dispatch(updateDocumentInfo(null));
      }}
      visible={visibleDrawer}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <AssignmentHeaderShow
          onCloseDrawer={onCloseDrawer}
          assignment={assignment}
        />
        {loader ? (
          <Skeleton />
        ) : assignment?.Type === 'ToApplyingStamp' ? null : (
          assignmentInfoForm()
        )}
      </div>
      {assignment?.Type === 'ToApplyingStamp' ? assignmentInfoForm() : null}
    </Drawer>
  );
};
