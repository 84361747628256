import { AxiosResponse } from 'axios';
import {
  loadingStatus,
  SortActiveParams,
  updateDataTable,
  updateDocumentKindId,
  updateSelectedDocuments,
} from 'store/slice/table';
import { getDocumentsList } from 'shared/utils/getDocumentsList';
import { $api } from 'shared/utils/api';
import { AppDispatch } from 'store/store';
import React from 'react';
import { errorThrow } from 'shared/utils';

const getSortParamsToRequest = (params: SortActiveParams) => {
  const isIncludesParam = (param: string) => params.option === param;
  const byCreateDate = (param: string) => {
    if (param === 'ByCreateDateAsc') {
      return false;
    }
    if (param === 'ByCreateDateDesc') {
      return true;
    }

    return undefined;
  };

  return {
    ByDocumentId: isIncludesParam('ByDocumentId'),
    ByDocumentIdDesc: isIncludesParam('ByDocumentIdDesc'),
    ByAuthorName: isIncludesParam('ByAuthorName'),
    ByAuthorNameDesc: isIncludesParam('ByAuthorNameDesc'),
    ByRegDate: isIncludesParam('ByRegDate'),
    ByRegDateDesc: isIncludesParam('ByRegDateDesc'),
    ByCreateDateDesc: byCreateDate(
      params.option.includes('CreateDate') ? params.option : '',
    ),
  };
};

export const updateTable = (
  dispatch: AppDispatch,
  documentType: string,
  value?: string | React.ChangeEvent<HTMLInputElement>,
  orgId?: string | number,
  page?: number,
  pageSize?: number,
  sendTypeId?: number | string,
  DocumentType?: string,
  sortParams?: SortActiveParams | null,
) => {
  dispatch(loadingStatus(true));
  dispatch(updateSelectedDocuments(0));
  dispatch(updateDocumentKindId(null));
  $api
    .get(`/${documentType}`, {
      params: {
        Limit: pageSize ?? 15,
        Page: page && page - 1,
        FastSearch: value ?? '',
        OrgId: orgId ?? '',
        SendTypeId: sendTypeId,
        OrganisationId: orgId,
        DocumentType: documentType.split('/')[3],
        ...(documentType === 'Documents/byuser/create'
          ? { OrganisationId: orgId }
          : {}),
        ...(sortParams
          ? {
              ...getSortParamsToRequest(sortParams),
            }
          : {}),
      },
    })
    .then(({ data }: AxiosResponse) => {
      getDocumentsList({ data, dispatch });
    })
    .catch(({ response }) => {
      dispatch(updateDataTable({ documents: [], filteredCount: 0 }));
      errorThrow(response);
    })
    .finally(() => {
      dispatch(loadingStatus(false));
    });
};
