import { Drawer, Form, Skeleton, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import './CustomDrawer.scss';
import { errorThrow } from 'shared/utils';
import { DrawerHeader } from './DrawerHeader';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';

type TOfficerDrawer = {
  visibleDrawer: boolean;
  onCloseDrawer: (bool: boolean) => void;
  officerId?: number | null;
};

type TOfficerInfo = {
  DepartmentId: number;
  IsHead: boolean;
  Esign: string;
  PositionId: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  PhoneNumber: string;
  Email: string;
  Type: string;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
};

export type TDepartmentInfo = {
  ParentId: number | null;
  OrganisationId: number;
  Name: string;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
};

type TPositionInfo = {
  id: number;
  Name: string;
};

type TSubstitutionsList = {
  Id: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  PositionId: number;
  PositionName: string;
};

export const OfficerDrawer = ({
  visibleDrawer,
  onCloseDrawer,
  officerId,
}: TOfficerDrawer) => {
  const [officerInfo, setOfficerInfo] = useState<TOfficerInfo>();
  const [departmentInfo, setDepartmentInfo] = useState<TDepartmentInfo>();
  const [organisationInfo, setOrganisationInfo] = useState<TDepartmentInfo>();
  const [positionInfo, setPositionInfo] = useState<TPositionInfo>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [substitutionList, setSubstitutionList] = useState<
    TSubstitutionsList[]
  >([]);
  const [form] = Form.useForm();

  const substitutionColumns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
      render: (text: string, item: TSubstitutionsList) => (
        <span>
          {item.LastName} {item.FirstName} {item.MiddleName}
        </span>
      ),
    },
    {
      title: 'Должность',
      dataIndex: 'PositionName',
      key: 'PositionName',
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    if (officerId) {
      $api
        .get(`Contacts/${officerId}/Substitutions`)
        .then(res => setSubstitutionList(res.data))
        .catch(({ response }) => errorThrow(response));
      $api
        .get(`/Contacts/${officerId}`)
        .then(res => {
          setOfficerInfo(res.data);
          Object.keys(res.data).forEach(key =>
            form.setFieldsValue({ [key]: res.data[key] }),
          );
          if (res.data.OrganisationId)
            $api
              .get(`Organisations/${res.data.OrganisationId}`)
              .then(res => setOrganisationInfo(res.data))
              .catch(({ response }) => errorThrow(response))
              .finally(() => setIsLoading(false));
          if (res.data.DepartmentId) {
            $api
              .get(`Departments/${res.data.DepartmentId}`)
              .then(department => {
                setDepartmentInfo(department.data);
              })
              .catch(({ response }) => errorThrow(response));
          } else {
            setIsLoading(false);
          }
          if (res.data.PositionId) {
            $api
              .get(`Positions/${res.data.PositionId}`)
              .then(position => {
                setPositionInfo(position.data);
              })
              .catch(({ response }) => errorThrow(response));
          }
        })
        .catch(({ response }) => errorThrow(response));
    }
  }, [form, officerId]);

  return (
    <Drawer
      placement="right"
      onClose={() => onCloseDrawer(false)}
      visible={visibleDrawer}
      className="drawer"
      closeIcon={null}
    >
      <div className="page3">
        <DrawerHeader onClose={onCloseDrawer} text="Карточка сотрудника" />
        <>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <form>
                <DFormItemTwo label="Организация" name="Organisation">
                  <span>{organisationInfo?.Name}</span>
                </DFormItemTwo>
                <DFormItemTwo label="Подразделение">
                  <span>{departmentInfo?.Name}</span>
                </DFormItemTwo>
                <DFormItemTwo label="Должность" name="Position">
                  <span>{positionInfo?.Name}</span>
                </DFormItemTwo>
                <DFormItemTwo label="Фамилия" name="LastName">
                  <span>{officerInfo?.LastName}</span>
                </DFormItemTwo>
                <DFormItemTwo label="Имя" name="FirstName">
                  <span>{officerInfo?.FirstName}</span>
                </DFormItemTwo>
                <DFormItemTwo label="Отчество" name="MiddleName">
                  <span>{officerInfo?.MiddleName}</span>
                </DFormItemTwo>
                <DFormItemTwo label="Электронная почта" name="Email">
                  <span>{officerInfo?.Email}</span>
                </DFormItemTwo>
              </form>

              <h2>Заместители</h2>
              <Table
                pagination={{ position: [] }}
                dataSource={substitutionList}
                columns={substitutionColumns}
              />
            </>
          )}
        </>
      </div>
    </Drawer>
  );
};
