import { Drawer } from 'antd';
import { prefixSSO } from 'api/prefix';
import axios from 'axios';
import { useFormik } from 'formik';
import { ContactList } from 'pages/CreateDocument/types';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow, successNotification } from 'shared/utils';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { DrawerHeader } from './DrawerHeader';

type TChangeLoginDrawer = {
  visible: boolean;
  onClose: () => void;
  item?: ContactList;
  getData: () => void;
};

export const ChangeLoginDrawer = ({
  visible,
  onClose,
  item,
  getData,
}: TChangeLoginDrawer) => {
  const initialValues = {
    Login: item?.Login,
  };

  const onFinish = (values: { Login?: string }) => {
    axios
      .patch(
        `${prefixSSO}/Users/Login`,
        {
          UserId: item?.SsoUserId,
          NewLogin: values.Login,
        },
        { headers: getAuthToken('auth_token') },
      )
      .then(() => {
        successNotification('Логин успешно изменён');
        onClose();
        getData();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Смена логина" />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Логин" name="Login">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Login}
              name="Login"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              Сохранить
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
