import { FC } from 'react';
import { DButton } from 'shared/ui-kit';
import { PopoverContentProps } from './types';
import './PopoverContent.scss';
import { useBem } from 'shared/hooks';

export const PopoverContent: FC<PopoverContentProps> = ({
  onDelete,
  closePopover,
}) => {
  const bem = useBem('PopoverContent');

  return (
    <div className={bem()}>
      <p>Вы уверены, что хотите удалить задачу?</p>
      <div className={bem('button-group')}>
        <DButton defaultPrimary small onClick={closePopover}>
          Отмена
        </DButton>
        <DButton defaultDanger small onClick={onDelete}>
          Подтвердить
        </DButton>
      </div>
    </div>
  );
};
