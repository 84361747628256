import { MatchParams } from 'types/tableData';
import { OperationsForm } from './OperationsForm';

export const LinkToADocuments = ({ match }: MatchParams) => {
  const onFinish = (values: any) => {
    console.log(values);
  };

  return <OperationsForm onFinish={onFinish} match={match} />;
};
