import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { updateTable } from 'api/table/table';
import { ReduxState } from 'store/store';
import { useLocation } from 'react-router';
import { DButton, DInput } from 'shared/ui-kit';
import { FilterDrawer } from 'shared/components/CustomDrawers';
import {
  updateButtonType,
  updateButtonTypeRegistration,
  updateDataCount,
  updateRegButtons,
  updateSearchValue,
} from 'store/slice/table';
import './TableMenu.scss';
import _ from 'lodash';
import { updateTableData } from 'pages/MainPage/MainPage';
import { ColumnSetupDrawer } from '../ColumnSetup';
import { Drawer, Dropdown, Menu } from 'antd';
import { buttonTypes, codeNames } from './constants';
import { PageHeader } from '../PageHeader';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { updateDocumentInfo } from 'store/slice/document';
import filterSvg from 'shared/assets/images/filter.svg';
import tableSvg from 'shared/assets/images/table-line.svg';
import { Icon } from '../IconComponent';
import searchSvg from 'shared/assets/images/search.svg';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';
import { TableSort } from '../TableSort/TableSort';
import { updateTableOnSelectType } from './utils';
import { TableButton } from './types';

const getPageInfo = (currentPath: string, key: 'name' | 'code') => {
  const currentObject = codeNames.find(item =>
    currentPath.includes(item.location),
  );

  if (currentObject) return currentObject[key];

  return null;
};

export function TableMenu() {
  const [visibleColumns, setVisibleColumns] = useState<boolean>(false);
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const {
    documentTableData,
    tablePagination,
    tableType,
    buttonType,
    buttonTypeRegistration,
    registrationButtons,
    searchValue,
    activeSortParams,
  } = useSelector((state: ReduxState) => state.tableDataReducer);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const orgId = location.pathname.split('/')[1];

  const onCloseFilter = (bool: boolean) => {
    setVisibleFilter(bool);
  };

  const getSendTypes = useCallback(() => {
    $api
      .get(`documentsregistration/sendtypes`, {
        params: {
          OrganisationId: orgId,
        },
      })
      .then(({ data }) => {
        dispatch(updateRegButtons(data));
      })
      .catch(({ response }) => errorThrow(response));
  }, [dispatch, orgId]);

  useEffect(() => {
    if (location.pathname.includes('on-registration')) getSendTypes();
  }, [orgId, getSendTypes]);

  const onCloseDrawer = () => {
    setVisibleColumns(false);
  };

  const onSelect = useCallback(
    (type?: TableButton) => {
      let pagination = {
        ...tablePagination,
      };
      if (type !== buttonType) {
        pagination = { ...pagination, page: 1 };
        dispatch(updateDataCount({ ...pagination, page: 1 }));
        history.push('/active-documents/page=1');
      }
      updateTableOnSelectType({
        type,
        dispatch,
        pagination,
        searchValue,
        organisationId: user?.CurrentContact?.OrganisationId,
        sortParams: activeSortParams,
      });
    },
    [
      searchValue,
      buttonType,
      dispatch,
      history,
      tablePagination,
      user?.CurrentContact?.OrganisationId,
      activeSortParams,
    ],
  );
  const onRegSelect = useCallback(
    (sendTypeId?: number | string) => {
      let pagination = {
        ...tablePagination,
      };
      if (sendTypeId !== buttonTypeRegistration) {
        const getPathWithOutPage = location.pathname.split('/').slice(0, 3);
        history.push(getPathWithOutPage.join('/') + '/page=1');
        pagination = { ...pagination, page: 1 };
        dispatch(updateDataCount({ ...pagination, page: 1 }));
      }
      updateTableData({
        type: `documents/byorganisation/${orgId}/register`,
        dispatch,
        tablePagination: pagination,
        orgId: orgId,
        sendTypeId: typeof sendTypeId !== 'string' ? sendTypeId : undefined,
      });
    },
    [
      buttonTypeRegistration,
      dispatch,
      history,
      location.pathname,
      orgId,
      tablePagination,
    ],
  );

  useEffect(() => {
    if (buttonType && location.pathname.includes('active-documents'))
      onSelect(buttonType);
    else if (
      buttonTypeRegistration &&
      location.pathname.includes('on-registration')
    ) {
      onRegSelect(buttonTypeRegistration);
    }
  }, [location.pathname, dispatch, tablePagination?.pageSize]);

  const onSearch = useCallback(
    _.debounce((value?: string | React.ChangeEvent<HTMLInputElement>) => {
      const arrayOfPath = location.pathname.split('/');
      const getPathWithOutPage =
        arrayOfPath.includes('active-documents') ||
        arrayOfPath.includes('control-documents')
          ? arrayOfPath.slice(0, 2)
          : arrayOfPath.slice(0, 3);
      history.push(getPathWithOutPage.join('/') + '/page=1');
      dispatch(updateButtonType(buttonType));
      if (orgId !== 'active-documents' && orgId !== 'control-documents') {
        updateTableData({
          type: tableType,
          dispatch,
          tablePagination: {
            page: 0,
            pageSize: tablePagination?.pageSize,
          },
          orgId,
          searchValue: value as string,
          sortParams: activeSortParams,
        });
      } else {
        updateTableData({
          type: tableType,
          dispatch,
          tablePagination: {
            page: 0,
            pageSize: tablePagination?.pageSize,
          },
          searchValue: value as string,
          sortParams: activeSortParams,
        });
      }
    }, DEBOUNCE_TIME),
    [
      buttonType,
      dispatch,
      history,
      location.pathname,
      orgId,
      tableType,
      activeSortParams,
      tablePagination?.pageSize,
    ],
  );

  const getAccessToShowButtons = () => {
    const getCurrentLocation = (path: string) => {
      return location.pathname.includes(path);
    };
    if (getCurrentLocation('active-documents')) {
      return menuToUserDocuments;
    }
    if (getCurrentLocation('on-registration')) {
      return menuToRegDocuments;
    }

    return null;
  };

  const menuToUserDocuments = (
    <Menu activeKey={String(buttonType)}>
      <Menu.Item
        key="inWork"
        onClick={() => {
          dispatch(updateButtonType('inWork'));
          onSelect('inWork');
        }}
      >
        В работе
      </Menu.Item>
      <Menu.Item
        key="my"
        onClick={() => {
          dispatch(updateButtonType('my'));
          onSelect('my');
        }}
      >
        Мои документы
      </Menu.Item>
      <Menu.Item
        key="control"
        onClick={() => {
          dispatch(updateButtonType('control'));
          onSelect('control');
        }}
      >
        На контроле
      </Menu.Item>
      <Menu.Item
        key="draft"
        onClick={() => {
          dispatch(updateButtonType('draft'));
          onSelect('draft');
        }}
      >
        Черновики
      </Menu.Item>
      <Menu.Item
        key="completed"
        onClick={() => {
          dispatch(updateButtonType('completed'));
          onSelect('completed');
        }}
      >
        Завершенные
      </Menu.Item>
      <Menu.Item
        key="all"
        onClick={() => {
          dispatch(updateButtonType('all'));
          onSelect('all');
        }}
      >
        Все
      </Menu.Item>
    </Menu>
  );

  const menuToRegDocuments = (
    <Menu activeKey={String(buttonTypeRegistration)}>
      <Menu.Item
        key="all"
        onClick={() => {
          dispatch(updateButtonTypeRegistration('all'));
          onRegSelect('all');
        }}
      >
        Все
      </Menu.Item>
      {registrationButtons?.map(item => (
        <Menu.Item
          key={item.Id}
          onClick={() => {
            onRegSelect(item.Id);
            dispatch(updateButtonTypeRegistration(item.Id));
          }}
        >
          {item.Name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const dropdownButtonRender = () => {
    const currentButton = buttonTypes.find(({ Type }) =>
      location.pathname.includes('on-registration')
        ? Type === buttonTypeRegistration
        : Type === buttonType,
    );

    if (currentButton) {
      return currentButton?.Name;
    }

    return registrationButtons?.find(
      item => item?.Id === buttonTypeRegistration,
    )?.Name;
  };

  return (
    <>
      <div className="tablemenu__wrapper">
        <div className="tablemenu__title">
          <PageHeader
            className="tablemenu__title-text"
            title={getPageInfo(location.pathname, 'name')}
            code={getPageInfo(location.pathname, 'code')}
          />
          {user?.Permissions?.find(
            item => item.Entity === 'document.create',
          ) ? (
            <DButton
              primary
              small
              onClick={() => {
                history.push('/create');
                dispatch(updateDocumentInfo(null));
              }}
            >
              + Создать документ
            </DButton>
          ) : null}
        </div>
        <div className="flex-space w100per">
          <div className="tablemenu__search-wrapper">
            <DInput
              value={searchValue}
              icon={<Icon icon={searchSvg} alt="searchSvg" />}
              type="search"
              className="mr15 tablemenu__search-input"
              placeholder="Поиск по теме, ФИО, сист. номеру, рег. номеру"
              onChange={({ target: { value } }) => {
                checkLengthStringForSearch(value, onSearch);
                dispatch(updateSearchValue(value));
              }}
            />
            {getAccessToShowButtons() ? (
              <Dropdown
                trigger={['click']}
                overlay={getAccessToShowButtons() ?? <></>}
              >
                <DButton small>
                  {dropdownButtonRender()}
                  <DownOutlined className="ml5" />
                </DButton>
              </Dropdown>
            ) : null}
          </div>
          <div className="tablemenu__icons">
            <TableSort type="document" />
            <Icon
              alt="Настройка столбцов"
              className="mr15"
              icon={tableSvg}
              onClick={() => setVisibleColumns(true)}
              tooltip="Настройка столбцов"
            />
            {location.pathname.includes('active-documents') && (
              <Icon
                alt="Фильтр"
                icon={filterSvg}
                onClick={() => setVisibleFilter(true)}
                tooltip="Фильтр по документам"
                tooltipPlacement="topRight"
              />
            )}
          </div>
        </div>
      </div>
      {visibleColumns && (
        <ColumnSetupDrawer
          visible={visibleColumns}
          onClose={onCloseDrawer}
          pageType="document"
        />
      )}
      {visibleFilter && (
        <Drawer
          placement="right"
          onClose={() => onCloseFilter(!visibleFilter)}
          visible={visibleFilter}
          className="drawer"
          closeIcon={null}
        >
          <FilterDrawer
            type={'drawer'}
            visible={visibleFilter}
            onClose={onCloseFilter}
          />
        </Drawer>
      )}
    </>
  );
}
