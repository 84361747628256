import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import {
  MOCK_EXAMPLE,
  InitialValuesTypes,
  useDocumentRegistrationDrawerTypes,
} from 'pages/AdminPage/DocumentRegistration/types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';

const DEFAULT_FULL_DATE = ['2001', '06', '22', '16', '44', '12'];

export const useDocumentRegistrationDrawer = ({
  item,
  type,
  onClose,
}: useDocumentRegistrationDrawerTypes) => {
  const [selectionTags, setSelectionTags] = useState<string>('');
  const [regCounter, setRegCounter] = useState<string>('');
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const initialValues: Partial<InitialValuesTypes> = {
    Name: item?.Name,
    StartCount: item?.StartCount,
    MaskRegNumber: undefined,
    DocumentType: undefined,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => onSend(values),
  });

  const getRegCounter = useCallback(() => {
    if (item?.Id) {
      $api
        .get(`documentregisters/${item?.Id}/full`)
        .then(({ data }) => setRegCounter(data.CurrentCount))
        .catch(({ response }) => errorThrow(response));
    }
  }, [item?.Id]);

  useEffect(() => getRegCounter(), [getRegCounter]);

  const getRequestToSend = useCallback(
    values => {
      if (type === 'create') {
        return $api.post('documentregisters', {
          Name: values?.Name,
          StartCount: Number(values?.StartCount),
          MaskRegNumber: selectionTags,
          OrganisationId: user?.CurrentContact?.OrganisationId,
        });
      } else {
        return $api.put(`documentregisters/${item?.Id}`, {
          Name: values?.Name,
          Id: item?.Id,
          StartCount: Number(values?.StartCount),
          MaskRegNumber: selectionTags,
          OrganisationId: user?.CurrentContact?.OrganisationId,
        });
      }
    },
    [item?.Id, selectionTags, type, user?.CurrentContact?.OrganisationId],
  );

  const onSend = useCallback(
    values => {
      getRequestToSend(values)
        .then(() => onClose(true))
        .catch(({ response }) => errorThrow(response));
    },
    [getRequestToSend, onClose],
  );

  const editDocumentType = () => {
    $api
      .patch('documentstreams/bydocumenttype', {
        DocumentRegisterId: item?.Id,
        DocumentType: formik.values?.DocumentType,
      })
      .then(() => onClose(true))
      .catch(({ response }) => errorThrow(response));
  };

  const getInitialValueMaskRegNumber = useCallback((mask?: string) => {
    if (mask) {
      setSelectionTags(prev => (mask.includes('{Count}') ? mask : prev + mask));
    }
  }, []);

  const onChangeTags = ([key]: string[]) => {
    if (!selectionTags.includes(key)) {
      selectionTags
        ? setSelectionTags((prev: string) => `${prev}.${key}`)
        : setSelectionTags(`${key}`);
    } else {
      setSelectionTags((prev: string) =>
        prev.includes(`.${key}`)
          ? prev.replace(`.${key}`, '').trim()
          : prev.replace(key, '').trim(),
      );
    }
  };

  const getExample = useCallback(() => {
    let example = selectionTags;

    Object.entries(MOCK_EXAMPLE).forEach(([key, value]) => {
      if (key.includes('Count')) {
        const currentValueCounter = formik.values.StartCount ?? '1';
        const lengthStringCounter = selectionTags.match(/\d+/g);
        const countAddingZero =
          Number(lengthStringCounter?.[0]) - currentValueCounter?.length;

        if (
          selectionTags.match(/.Count: d\d./) &&
          lengthStringCounter &&
          countAddingZero > 0
        ) {
          const deleteMaskCounter = example
            .split(/[./,]/)
            .filter(item => item.includes('Count'))[0];
          const addCounterForExample =
            '0'.repeat(countAddingZero) + currentValueCounter;
          example = example.replace(deleteMaskCounter, addCounterForExample);
        } else {
          const findCurrentMask = selectionTags
            .split(/[./,]/)
            .filter(item => item.includes('Count'))[0];
          example = example.replace(findCurrentMask, currentValueCounter);
        }
      }

      if (selectionTags.includes('FullDate:')) {
        const formatDate = selectionTags
          .split('FullDate:')[1]
          .split('}')[0]
          .replace(/[^a-zA-Z\d./\- ]/g, '');
        const findMaskDate = selectionTags
          .split(/[./,]{/)
          .filter(item => item.includes('FullDate'))[0];
        const fullDateMask = findMaskDate.includes('{')
          ? findMaskDate
          : `{${findMaskDate}`;
        const addDate = moment(DEFAULT_FULL_DATE)
          .format(formatDate.toUpperCase())
          .trim();
        example = example.replace(fullDateMask, addDate);
      }

      if (selectionTags.includes(key)) {
        example = example.replace(key, value);
      }
    });

    return example;
  }, [formik.values.StartCount, selectionTags]);

  return {
    formik,
    getInitialValueMaskRegNumber,
    onChangeTags,
    selectionTags,
    setSelectionTags,
    getExample,
    editDocumentType,
    regCounter,
  };
};
