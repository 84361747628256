import { FC, memo, ReactElement } from 'react';
import { useBem } from '../../hooks';
import { ButtonProps } from './types';
import cn from 'classnames';
import './styles/Button.scss';
import '../../styles/general.scss';

export const Button: FC<ButtonProps> = memo(
  ({
    children,
    style,
    className,
    onClick,
    size = 's30',
    type = 'primary',
    htmlType = 'button',
    color = 'gray',
    ...props
  }): ReactElement => {
    const bem = useBem('Button');
    const buttonClassName = cn(bem(type, [size, color]), className);

    return (
      <button
        style={style}
        className={buttonClassName}
        onClick={onClick}
        type={htmlType}
        {...props}
      >
        <span className="children">{children}</span>
      </button>
    );
  },
);
