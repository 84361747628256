export const REPORTS_PAGE = 'REPORTS_PAGE';
export const COUNTERPARTIES = 'COUNTERPARTIES';
export const DEPARTMENTS = 'DEPARTMENTS';
export const DOCUMENT_FLOW = 'DOCUMENT_FLOW';
export const DOCUMENT_KINDS = 'DOCUMENT_KINDS';
export const DOCUMENT_REGISTERS = 'DOCUMENT_REGISTERS';
export const NOMENCLATURES = 'NOMENCLATURES';
export const CONTACTS = 'CONTACTS';
export const PROCESSES = 'PROCESSES';
export const USER_GROUPS = 'USER_GROUPS';
export const REQUISITES = 'REQUISITES';
export const STATISTICS_PAGE = 'STATISTICS_PAGE';
export const CREATE_DOCUMENT_PAGE = 'CREATE_DOCUMENT_PAGE';
export const SEND_TO_ROUTE_PAGE = 'SEND_TO_ROUTE_PAGE';
