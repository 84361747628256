import { Button, Input } from '@duktus/ui-kit';
import { ReactComponent as ArrowDown } from 'shared/assets/images/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'shared/assets/images/arrow-up.svg';
import { Spin } from 'antd';
import { DocumentTypeItemProps } from '../types';
import { useDocumentTypeItem } from './useDocumentTypeItem';
import { DocumentKindItem } from './DocumentKindItem';

export const DocumentTypeItem = ({
  item,
  onSelect,
  updateItem,
  fetchData,
}: DocumentTypeItemProps) => {
  const { title, Id, type } = item;
  const {
    onChangeInput,
    isLoading,
    isShowButton,
    onShowMore,
    isCollapsed,
    DOC_TYPE_CHILDS,
    countForDisplay,
  } = useDocumentTypeItem({ item, updateItem, onSelect, fetchData });

  return (
    <div key={`${type}__${Id}`} className="redirect-item">
      <p className="redirect-item__title">{title}</p>
      <div className="redirect-item__search">
        <Input
          label="Поиск шаблона"
          onChange={({ target: { value } }) => onChangeInput(value)}
          placeholder="Поиск"
          size="s20"
        />
      </div>
      <Spin spinning={isLoading}>
        <div className="redirect-item__inner">
          {DOC_TYPE_CHILDS?.map(
            (child, index) =>
              index < countForDisplay && (
                <DocumentKindItem
                  child={child}
                  index={index}
                  onSelect={onSelect}
                />
              ),
          )}
          {isShowButton && (
            <Button
              className="redirect-more"
              type="link"
              size="s20"
              color="blue"
              onClick={onShowMore}
            >
              <ArrowDown />
              Еще
            </Button>
          )}
          {isCollapsed && (
            <Button
              className="redirect-more"
              type="link"
              size="s20"
              color="blue"
              onClick={onShowMore}
            >
              <ArrowUp />
              Скрыть
            </Button>
          )}
        </div>
      </Spin>
    </div>
  );
};
