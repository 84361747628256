import { Modal } from 'antd';
import { DButton } from 'shared/ui-kit';

type DeleteModalTypes = {
  title: string;
  description: string;
  visible: boolean;
  setVisible: (item: boolean) => void;
  onDelete: (item: number) => void;
};

export const DeleteModal = ({
  title,
  description,
  visible,
  setVisible,
  onDelete,
}: DeleteModalTypes) => {
  return (
    <Modal
      title={title}
      centered
      visible={visible}
      closable={false}
      footer={[
        <DButton className="mr15" small primary onClick={onDelete}>
          Удалить
        </DButton>,
        <DButton small defaultDanger onClick={() => setVisible(false)}>
          Отмена
        </DButton>,
      ]}
    >
      <span>{description}</span>
    </Modal>
  );
};
