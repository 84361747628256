import { Menu, notification, Select, Skeleton } from 'antd';
import { DButton, DTextarea } from 'shared/ui-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { AssignmentType, CurrentDocument } from 'types/document';
import { createAttachedSignature, getUserCertificates } from 'crypto-pro';
import { ReduxState } from 'store/store';
import { errorThrow } from 'shared/utils';
import { useFormik } from 'formik';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { AssignmentGeneralInfo } from './AssignmentGeneralInfo';
import { Phases } from '../types';
import { useSelector } from 'react-redux';
import { SignedForm } from './SignedForm';
import { ChildAssignmentDrawer } from 'shared/components/CustomDrawers/ChildAssignmentDrawer';

type TAssignmentDocumentSignForm = {
  onReject: (
    resolution?: string,
    documentId?: number,
    assignmentId?: number,
  ) => void;
  assignment?: AssignmentType;
  phases: Phases[];
  documentData?: CurrentDocument | null;
  onSuccessResponse: (text: string) => void;
};

export const AssignmentDocumentSignForm = ({
  onReject,
  phases,
  assignment,
  documentData,
  onSuccessResponse,
}: TAssignmentDocumentSignForm) => {
  const [selectedPhase, setSelectedPhase] = useState<number | null>();
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [visibleChild, setVisibleChild] = useState<boolean>(false);
  const [childType, setChildType] = useState<string>('');
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { signedInfo } = useSelector(
    (state: ReduxState) => state.assignmentReducer,
  );
  const documentId = documentInfo?.Id;
  const executorIsCurrentUser =
    assignment?.ExecutorId === user?.CurrentContact?.Id ||
    assignment?.ExecutionGroupId === user?.CurrentContact?.DepartmentId;

  const onCloseChildDrawer = (bool: boolean) => {
    if (isFollowing && bool) {
      onFinish({ Resolution: formik.values.Resolution });
    }
    setVisibleChild(bool);
  };

  const onFinish = async (values: any) => {
    $api
      .patch(`Assignments/${assignment?.Id}/Execute/Signing`, {
        PhaseId: selectedPhase ?? null,
        Resolution: values.Resolution,
        CertificateThumbprint:
          signedInfo?.CertificateThumbprint !== 'Unknown'
            ? signedInfo?.CertificateThumbprint
            : null,
        DocumentSignatureData:
          signedInfo?.CertificateThumbprint !== 'Unknown'
            ? signedInfo?.DocumentSignatureData
            : null,
        DocumentSignature:
          signedInfo?.CertificateThumbprint !== 'Unknown'
            ? signedInfo?.DocumentSignature
            : null,
        ExecutionFactTime: new Date(),
      })
      .then(() => onSuccessResponse('Задача успешно подписана'))
      .catch(({ response }) => {
        errorThrow(response);
      });
  };

  const initialValues = {
    choiceSignature: undefined,
    Resolution: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <div style={{ display: 'flex', gap: 20 }}>
      {visibleChild && (
        <ChildAssignmentDrawer
          visible={visibleChild}
          onClose={onCloseChildDrawer}
          assignment={assignment}
          childType={childType}
          documentData={documentData}
          isFollowing={isFollowing}
        />
      )}
      <AssignmentGeneralInfo
        assignment={assignment}
        documentData={documentData}
      />
      <form className="drawer-form" onSubmit={formik.handleSubmit}>
        <SignedForm assignment={assignment as AssignmentType} />
        <DFormItemTwo
          style={{ alignItems: 'baseline', flexDirection: 'column' }}
          label="Комментарий к резолюции"
          name="Resolution"
        >
          <DTextarea
            className="w100per"
            value={formik.values.Resolution}
            onChange={formik.handleChange}
            name="Resolution"
          />
        </DFormItemTwo>
        <DFormItemTwo className="form-buttons">
          {phases.length ? (
            phases?.map(item => (
              <DButton
                onClick={() => setSelectedPhase(item.Id)}
                className="mr15"
                primary
                small
                type="submit"
              >
                {item?.LinkName ?? 'Выполнить'}
              </DButton>
            ))
          ) : (
            <DButton
              onClick={() => setSelectedPhase(null)}
              className="mr15"
              primary
              small
              type="submit"
            >
              Выполнить
            </DButton>
          )}
          {(!phases.length || phases.length === 1) && executorIsCurrentUser ? (
            <DButton
              onClick={() => {
                setIsFollowing(true);
                setVisibleChild(true);
                setChildType('ToExecute');
                setSelectedPhase(phases?.[0]?.Id ?? null);
              }}
              className="mr15"
              defaultPrimary
              small
            >
              По итогам
            </DButton>
          ) : null}
          <DButton
            small
            defaultDanger
            onClick={() =>
              onReject(formik.values.Resolution, documentId, assignment?.Id)
            }
          >
            Отклонить
          </DButton>
        </DFormItemTwo>
      </form>
    </div>
  );
};
