import { Skeleton } from 'antd';
import { useFormik } from 'formik';
import { TProcess } from 'pages/AdminPage/Processes';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow, successNotification } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { PageHeader } from '../PageHeader';
import { ProcessDiagram } from '../ProcessDiagram';
import { PhaseSteps } from './PhaseSteps';

export const OrganisationProcessItem = () => {
  const [route, setRoute] = useState<TProcess>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();
  const routeId = location.pathname.split('/')[4];
  const initialValues = {
    Name: '',
  };

  const getRoute = useCallback(() => {
    setIsLoading(true);
    $api
      .get(`flows/${routeId}`)
      .then(res => {
        setRoute(res.data);
        formik.setFieldValue('Name', res.data?.Name);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, [routeId]);

  useEffect(() => {
    getRoute();
  }, [getRoute]);

  const onFinish = (values: { Name: string }) => {
    $api
      .put('flows/byorganization', {
        Id: route?.Id,
        Name: values.Name,
      })
      .then(() => {
        getRoute();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  const onCheckRoute = useCallback(() => {
    $api
      .get(`flows/${route?.Id}/check`)
      .then(() => successNotification('Процесс прошел проверку'))
      .catch(({ response }) => errorThrow(response));
  }, [route?.Id]);

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div className="page3">
          <PageHeader title={`Процесс № ${route?.Id} - ${route?.Name}`} />
          <form className="drawer-form" onSubmit={formik.handleSubmit}>
            <DFormItemTwo label="Имя" name="Name">
              <DInput
                width="100%"
                type="text"
                value={formik.values.Name}
                name="Name"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo className="form-buttons">
              <DButton small primary className="mr15" type="submit">
                Сохранить
              </DButton>
              <DButton
                small
                defaultDanger
                onClick={onCheckRoute}
                className="mr15"
              >
                Проверить
              </DButton>
            </DFormItemTwo>
          </form>
          {/* TODO: после вернуть и добавить новые поля */}
          {/* {route && <ProcessDiagram route={route} />} */}
          <PhaseSteps route={route} />
        </div>
      )}
    </>
  );
};
