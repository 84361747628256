import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentDocument } from 'types/document';
import { TSampleDocument } from 'shared/components/CustomDrawers/ASampleDocumentDrawer';

type TConfigureButton = {
  Edit: boolean;
  StartOnRoute: boolean;
  Reject: boolean;
  Redirect: boolean;
  Delete: boolean;
};

export type Assignments = {
  documentInfo: CurrentDocument | null;
  loader: boolean;
  linkishId: number | null;
  sampleSettings?: TSampleDocument;
  configureButtons: TConfigureButton | null;
};

const initialState: Assignments = {
  documentInfo: null,
  loader: true,
  linkishId: null,
  sampleSettings: undefined,
  configureButtons: null,
};

const documentInfoSlice = createSlice({
  name: 'documentInfo',
  initialState,
  reducers: {
    updateDocumentInfo(state, action: PayloadAction<CurrentDocument | null>) {
      state.documentInfo = action.payload;
    },
    updateLoader(state, action: PayloadAction<boolean>) {
      state.loader = action.payload;
    },
    updateInfoByLinkishDocument(state, action: PayloadAction<number | null>) {
      state.linkishId = action.payload;
    },
    updateConfigureButtons(
      state,
      action: PayloadAction<TConfigureButton | null>,
    ) {
      state.configureButtons = action.payload;
    },
    updateASampleDocument(
      state,
      action: PayloadAction<TSampleDocument | undefined>,
    ) {
      state.sampleSettings = action.payload;
    },
  },
});

export const {
  updateDocumentInfo,
  updateLoader,
  updateInfoByLinkishDocument,
  updateASampleDocument,
  updateConfigureButtons,
} = documentInfoSlice.actions;
export const documentInfoReducer = documentInfoSlice.reducer;
