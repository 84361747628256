import { TUserTagList } from '../types';

type TFilterTags = {
  userTags?: TUserTagList[];
  selectedTags: number[];
  onAddTag: (tagId: number) => void;
  onRemoveTag: (tagId: number) => void;
};

export const FilterTags = ({
  userTags,
  selectedTags,
  onRemoveTag,
  onAddTag,
}: TFilterTags) => {
  const checkIsExist = (item: TUserTagList) =>
    selectedTags.find(tagId => tagId === item.Id);

  const onClickItem = (item: TUserTagList) => {
    if (checkIsExist(item)) {
      onRemoveTag(item.Id);
    } else {
      onAddTag(item.Id);
    }
  };

  return (
    <div className="filter-drawer__tags">
      {userTags?.length
        ? userTags?.map(item => (
            <div className="document-tag-list">
              <span
                onClick={() => onClickItem(item)}
                className={
                  checkIsExist(item)
                    ? 'document-tags-item__add-active'
                    : 'document-tags-item__add'
                }
                key={item.Id}
              >
                {item.Name}
              </span>
            </div>
          ))
        : 'нет тегов'}
    </div>
  );
};
