import { AssignmentType } from 'types/document';

export type TAssignmentTable = {
  assignments?: AssignmentType[];
  isPrint?: boolean;
};

export enum AssignmentStatus {
  'Completed' = 'completed',
  'Rejected' = 'rejected',
  'OnCompleted' = 'on_completed',
  'Returned' = 'rejected',
  'Draft' = '',
}
