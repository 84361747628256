import { Form, Select, Input, Button } from 'antd';

export const DelegationOfExecution = () => {
  const { Option } = Select;

  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      autoComplete="off"
      className="form"
    >
      <div className="form-inner">
        <Form.Item label="Операция" name="Operation">
          <strong>Поручение. Делегировать исполнение</strong>
        </Form.Item>
        <Form.Item label="Причина" name="Reason">
          <Select showSearch optionFilterProp="children" className="250">
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Комментарий" name="Comment">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Соисполнители" name="CoExecutors">
          <Select showSearch optionFilterProp="children" className="250">
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
          </Select>
        </Form.Item>
      </div>
      <div className="form-buttons">
        <Button className="button mr15" htmlType="submit">
          Сохранить
        </Button>
        <Button danger>Закрыть</Button>
      </div>
    </Form>
  );
};
