import { Select, Space, Dropdown, Checkbox } from 'antd';
import { Moment } from 'moment';
import { DButton, DInput } from 'shared/ui-kit';
import { DownOutlined } from '@ant-design/icons';
import { RangePick } from '../../DatePicker/RangePick';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { CatalogDrawer } from '../CatalogDrawer';
import { ControlStatus, nameStateDocuments } from './constants';
import { documentTypes } from 'pages/AdminPage/DocumentKinds';
import { PreLoader } from 'shared/components/PreLoader';
import _ from 'lodash';
import { DrawerHeader } from '../DrawerHeader';
import { useFilterDrawer } from './useFilterDrawer';
import cn from 'classnames';
import { FilterPresetMenu, FilterSaveModal, FilterTags } from './components';
import { CatalogButton } from '../../CatalogButton';
import React from 'react';
import { FilterDrawerTypes, SearchBySettings } from './types';
import { SelectedFields } from './components/SelectedFields';

export const FilterDrawer = ({ visible, onClose, type }: FilterDrawerTypes) => {
  const { Option } = Select;

  const {
    isLoading,
    currentFilter,
    userTags,
    selectedTags,
    visibleCatalog,
    onCloseCatalog,
    onOpenCatalog,
    formik,
    onAddFilterIntoSidebar,
    showError,
    setShowError,
    orgnomencalutreList,
    contacts,
    documentKindList,
    checkDisabledButtonSearch,
    onLoadFilterPreset,
    onClearForm,
    changeModalVisible,
    updateFieldValue,
    updateContactLists,
    onAddTag,
    onRemoveTag,
    visibleModal,
    getFilterPresets,
    filterPresetList,
    organisationList,
    catalogType,
    sendTypeList,
    onUpdateSelectedFields,
    selectedFields,
    onRemoveSelectedFields,
    senderOrganisations,
    onRemoveFilterPreset,
    fieldValue,
  } = useFilterDrawer();

  return (
    <>
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={onCloseCatalog}
          type={catalogType}
          func={updateContactLists}
          defaultList={selectedFields[fieldValue]}
          withRemoveTable
        />
      )}
      <FilterSaveModal
        visible={visibleModal}
        onClose={changeModalVisible}
        selectedTags={selectedTags}
        formValues={formik.values}
        getFilterPresets={getFilterPresets}
      />
      {isLoading && <PreLoader />}
      <div className="page3">
        {type === 'drawer' && (
          <DrawerHeader
            onClose={onClose}
            text="Фильтрация документов"
            code="DOCUMENTS_FILTER"
          />
        )}

        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <div
            className={cn('mb20', {
              'filter-header': currentFilter,
              'filter-header__without-filter': !currentFilter,
            })}
          >
            {currentFilter && (
              <div>
                <span>{currentFilter?.FilterName}</span>
                <Checkbox
                  checked={currentFilter?.IsSideBarShow}
                  onChange={onAddFilterIntoSidebar}
                  className="ml15"
                >
                  Добавить в меню
                </Checkbox>
              </div>
            )}
            <Dropdown
              trigger={['click']}
              overlay={
                <FilterPresetMenu
                  presetList={filterPresetList}
                  onLoadPreset={onLoadFilterPreset}
                  onRemove={onRemoveFilterPreset}
                />
              }
            >
              <DButton small>
                Загрузить фильтр <DownOutlined />
              </DButton>
            </Dropdown>
          </div>
          <DFormItemTwo label="Дата регистрации" name="registration_date">
            <RangePick
              onChange={(date: Moment) => {
                formik.setFieldValue('registration_date', date);
              }}
              showCustomDates={false}
              showTwoMonths
              value={formik.values.registration_date}
              style={{ width: '100%', height: 36, borderRadius: 8 }}
              format={'DD.MM.YYYY'}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Дата создания" name="create_date">
            <RangePick
              onChange={(date: Moment) => {
                formik.setFieldValue('create_date', date);
              }}
              showCustomDates={false}
              showTwoMonths
              value={formik.values.create_date}
              style={{ width: '100%', height: 36, borderRadius: 8 }}
              format={'DD.MM.YYYY'}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Контрольный срок" name="ExecutionTime">
            <RangePick
              showCustomDates={false}
              showTwoMonths
              onChange={(date: Moment) => {
                formik.setFieldValue('ExecutionTime', date);
              }}
              value={formik.values.ExecutionTime}
              style={{ width: '100%', height: 36, borderRadius: 8 }}
              format={'DD.MM.YYYY'}
            />
          </DFormItemTwo>
          <DFormItemTwo
            label="Исходящая дата регистрации"
            name="ProceedRegistrationDate"
          >
            <RangePick
              onChange={(date: Moment) => {
                formik.setFieldValue('ProceedRegistrationDate', date);
              }}
              showCustomDates={false}
              showTwoMonths
              value={formik.values.ProceedRegistrationDate}
              style={{ width: '100%', height: 36, borderRadius: 8 }}
              format={'DD.MM.YYYY'}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Рег. номер отправителя" name="InRegNumber">
            <DInput
              width="100%"
              type="text"
              value={formik.values.InRegNumber}
              name="InRegNumber"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Системный номер" name="DocumentId">
            <DInput
              width="100%"
              type="text"
              value={formik.values.DocumentId}
              name="DocumentId"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Рег. номер" name="RegNumber">
            <DInput
              width="100%"
              type="text"
              value={formik.values.RegNumber}
              name="RegNumber"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo
            label="Документ содержит"
            required={!!formik.values.searchBy?.length}
            name="DocumentContain"
          >
            <DInput
              width="100%"
              required={showError}
              type="text"
              value={formik.values.DocumentContain}
              name="DocumentContain"
              onChange={e => {
                formik.handleChange(e);
                e.target.value.length > 0 && setShowError(false);
              }}
            />
          </DFormItemTwo>
          <DFormItemTwo>
            <span>искать по</span> <br />
          </DFormItemTwo>
          <DFormItemTwo name="searchBy">
            <Checkbox.Group
              onChange={value => {
                formik.setFieldValue('searchBy', value);
              }}
              value={formik.values.searchBy}
            >
              <Space direction="vertical">
                <Checkbox value={SearchBySettings.BY_NAME}>теме</Checkbox>
                <Checkbox value={SearchBySettings.BY_SUMMARY}>
                  краткому содержанию
                </Checkbox>
              </Space>
            </Checkbox.Group>
          </DFormItemTwo>
          <DFormItemTwo label="Адресат" name="AddresseeId">
            <Select
              optionFilterProp="children"
              className="ui-select"
              placeholder="Выберите адресата"
              allowClear
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              value={formik.values.AddresseeId}
              onChange={value => {
                formik.setFieldValue('AddresseeId', value);
                const arr = contacts.AddresseeId.filter(item =>
                  // @ts-ignore
                  value.includes(item.Id),
                );
                onUpdateSelectedFields('AddresseeId', arr);
              }}
            >
              {_.uniqBy(contacts.AddresseeId, 'Id')?.map(item => (
                <Option key={item.Id} value={item.Id}>
                  {item.LastName} {item.FirstName} {item.MiddleName}
                </Option>
              ))}
            </Select>
            <CatalogButton
              onClick={() => {
                onOpenCatalog();
                updateFieldValue('AddresseeId');
              }}
            />
          </DFormItemTwo>
          {!!selectedFields['AddresseeId'].length && (
            <SelectedFields
              fieldValue="AddresseeId"
              onRemove={onRemoveSelectedFields}
              array={selectedFields['AddresseeId']}
            />
          )}
          <DFormItemTwo label="Корреспондент" name="CorrespondentId">
            <Select
              optionFilterProp="children"
              className="ui-select"
              placeholder="Выберите корреспондента"
              allowClear
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              value={formik.values.CorrespondentId}
              onChange={value => {
                formik.setFieldValue('CorrespondentId', value);
                const arr = contacts.CorrespondentId.filter(item =>
                  // @ts-ignore
                  value.includes(item.Id),
                );
                onUpdateSelectedFields('CorrespondentId', arr);
              }}
            >
              {_.uniqBy(contacts.CorrespondentId, 'Id')?.map(item => (
                <Option key={item.Id} value={item.Id}>
                  {item.LastName} {item.FirstName} {item.MiddleName}
                </Option>
              ))}
            </Select>
            <CatalogButton
              onClick={() => {
                onOpenCatalog();
                updateFieldValue('CorrespondentId');
              }}
            />
          </DFormItemTwo>
          {!!selectedFields['CorrespondentId'].length && (
            <SelectedFields
              fieldValue="CorrespondentId"
              onRemove={onRemoveSelectedFields}
              array={selectedFields['CorrespondentId']}
            />
          )}
          <DFormItemTwo label="Автор РКК" name="AuthorId">
            <Select
              optionFilterProp="children"
              className="ui-select"
              placeholder="Выберите автора РКК"
              allowClear
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              value={formik.values.AuthorId}
              onChange={value => {
                formik.setFieldValue('AuthorId', value);
                const arr = contacts.AuthorId.filter(item =>
                  // @ts-ignore
                  value.includes(item.Id),
                );
                onUpdateSelectedFields('AuthorId', arr);
              }}
            >
              {_.uniqBy(contacts.AuthorId, 'Id')?.map(item => (
                <Option key={item.Id} value={item.Id}>
                  {item.LastName} {item.FirstName} {item.MiddleName}
                </Option>
              ))}
            </Select>
            <CatalogButton
              onClick={() => {
                onOpenCatalog();
                updateFieldValue('AuthorId');
              }}
            />
          </DFormItemTwo>
          {!!selectedFields['AuthorId'].length && (
            <SelectedFields
              fieldValue="AuthorId"
              onRemove={onRemoveSelectedFields}
              array={selectedFields['AuthorId']}
            />
          )}
          <DFormItemTwo label="Контролер" name="ControllerId">
            <Select
              optionFilterProp="children"
              className="ui-select"
              placeholder="Выберите контролера"
              allowClear
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              value={formik.values.ControllerId}
              onChange={value => {
                formik.setFieldValue('ControllerId', value);
                const arr = contacts.ControllerId.filter(item =>
                  // @ts-ignore
                  value.includes(item.Id),
                );
                onUpdateSelectedFields('ControllerId', arr);
              }}
            >
              {_.uniqBy(contacts.ControllerId, 'Id')?.map(item => (
                <Option key={item.Id} value={item.Id}>
                  {item.LastName} {item.FirstName} {item.MiddleName}
                </Option>
              ))}
            </Select>
            <CatalogButton
              onClick={() => {
                onOpenCatalog();
                updateFieldValue('ControllerId');
              }}
            />
          </DFormItemTwo>
          {!!selectedFields['ControllerId'].length && (
            <SelectedFields
              fieldValue="ControllerId"
              onRemove={onRemoveSelectedFields}
              array={selectedFields['ControllerId']}
            />
          )}
          <DFormItemTwo label="Исполнитель" name="ExecutorId">
            <Select
              optionFilterProp="children"
              className="ui-select"
              showSearch
              placeholder="Выберите исполнителя"
              allowClear
              mode="multiple"
              maxTagCount="responsive"
              value={formik.values.ExecutorId}
              onChange={value => {
                formik.setFieldValue('ExecutorId', value);
                const arr = contacts.ExecutorId.filter(item =>
                  // @ts-ignore
                  value.includes(item.Id),
                );
                onUpdateSelectedFields('ExecutorId', arr);
              }}
            >
              {_.uniqBy(contacts.ExecutorId, 'Id')?.map(item => (
                <Option key={item.Id} value={item.Id}>
                  {item.LastName} {item.FirstName} {item.MiddleName}
                </Option>
              ))}
            </Select>
            <CatalogButton
              onClick={() => {
                onOpenCatalog();
                updateFieldValue('ExecutorId');
              }}
            />
          </DFormItemTwo>
          {!!selectedFields['ExecutorId'].length && (
            <SelectedFields
              fieldValue="ExecutorId"
              onRemove={onRemoveSelectedFields}
              array={selectedFields['ExecutorId']}
            />
          )}
          <DFormItemTwo label="Организация отправителя" name="SenderId">
            <Select
              optionFilterProp="children"
              className="ui-select"
              showSearch
              placeholder="Выберите организацию отправителя"
              allowClear
              mode="multiple"
              maxTagCount="responsive"
              value={formik.values.SenderId}
              onChange={value => {
                formik.setFieldValue('SenderId', value);
                const arr = senderOrganisations.filter(item =>
                  // @ts-ignore
                  value.includes(item.Id),
                );
                onUpdateSelectedFields('SenderId', arr);
              }}
            >
              {_.uniqBy(senderOrganisations, 'Id')?.map(item => (
                <Option key={item.Id} value={item.Id}>
                  {item.Name}
                </Option>
              ))}
            </Select>
            <CatalogButton
              onClick={() => {
                onOpenCatalog('organisations');
                updateFieldValue('SenderId');
              }}
            />
          </DFormItemTwo>
          {!!selectedFields['SenderId'].length && (
            <SelectedFields
              fieldValue="SenderId"
              onRemove={onRemoveSelectedFields}
              array={selectedFields['SenderId']}
            />
          )}
          <DFormItemTwo
            label="Организация получателя"
            name="OrganisationReceiverId"
          >
            <Select
              optionFilterProp="children"
              className="ui-select"
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              placeholder="Выберите организацию получателя"
              allowClear
              value={formik.values.OrganisationReceiverId}
              onChange={value => {
                formik.setFieldValue('OrganisationReceiverId', value);

                const arr = organisationList.filter(item =>
                  // @ts-ignore
                  value.includes(item.Id),
                );
                onUpdateSelectedFields('OrganisationReceiverId', arr);
              }}
            >
              {_.uniqBy(organisationList, 'Id')?.map(item => (
                <Option key={item.Id} value={item.Id}>
                  {item.Name}
                </Option>
              ))}
            </Select>
            <CatalogButton
              onClick={() => {
                onOpenCatalog('organisations');
                updateFieldValue('OrganisationReceiverId');
              }}
            />
          </DFormItemTwo>
          {!!selectedFields['OrganisationReceiverId'].length && (
            <SelectedFields
              fieldValue="OrganisationReceiverId"
              onRemove={onRemoveSelectedFields}
              array={selectedFields['OrganisationReceiverId']}
            />
          )}
          <DFormItemTwo label="Состояние документа" name="State">
            <Select
              optionFilterProp="children"
              showSearch
              className="ui-select"
              placeholder="Выберите состояние"
              allowClear
              value={formik.values.State}
              onChange={value => formik.setFieldValue('State', value)}
            >
              {nameStateDocuments?.map(item => (
                <Option key={item.State} value={item.State}>
                  {item.Name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Дело по номенклатуре" name="OrgNomenclatureId">
            <Select
              optionFilterProp="children"
              className="ui-select"
              showSearch
              placeholder="Выберите дело по номенклатуре"
              allowClear
              value={formik.values.OrgNomenclatureId}
              onChange={value =>
                formik.setFieldValue('OrgNomenclatureId', value)
              }
            >
              {orgnomencalutreList?.map(item => (
                <Option key={item?.Id} value={item.Id}>
                  {item?.Name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>{' '}
          <DFormItemTwo label="Статус контроля" name="ControlStatus">
            <Select
              optionFilterProp="children"
              className="ui-select"
              showSearch
              placeholder="Выберите статус контроля"
              allowClear
              value={formik.values.ControlStatus}
              onChange={value => formik.setFieldValue('ControlStatus', value)}
            >
              {ControlStatus?.map(item => (
                <Option key={item?.status} value={item.status}>
                  {item?.title}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Вид документа" name="DocumentKindId">
            <Select
              optionFilterProp="children"
              className="ui-select"
              showSearch
              placeholder="Выберите вид документа"
              allowClear
              value={formik.values.DocumentKindId}
              onChange={value => formik.setFieldValue('DocumentKindId', value)}
            >
              {documentKindList?.map(item => (
                <Option key={item?.Id} value={item.Id}>
                  {item?.Name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Тип документа" name="DocumentType">
            <Select
              className="ui-select"
              allowClear
              placeholder="Выберите тип документа"
              value={formik.values.DocumentType}
              onChange={value => formik.setFieldValue('DocumentType', value)}
            >
              {documentTypes.map(document => (
                <Option key={document.type} value={document.type}>
                  {document.name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Способ доставки" name="SendTypeId">
            <Select
              className="ui-select"
              allowClear
              placeholder="Выберите тип документа"
              value={formik.values.SendTypeId}
              onChange={value => formik.setFieldValue('SendTypeId', value)}
            >
              {sendTypeList.map(sendtype => (
                <Option key={sendtype.Id} value={sendtype.Id}>
                  {sendtype.Name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo
            label="Выбрать"
            name="Tags"
            style={{ alignItems: 'baseline' }}
          >
            <FilterTags
              userTags={userTags}
              selectedTags={selectedTags}
              onRemoveTag={onRemoveTag}
              onAddTag={onAddTag}
            />
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton
              className="mr15"
              type="submit"
              primary
              small
              disabled={checkDisabledButtonSearch() && !selectedTags?.length}
            >
              Найти
            </DButton>
            <DButton
              className="mr15"
              defaultDanger
              small
              onClick={() => onClose(!visible)}
            >
              Отмена
            </DButton>
            <DButton
              className="mr15"
              defaultPrimary
              small
              onClick={onClearForm}
            >
              Очистить
            </DButton>
            <DButton small onClick={() => changeModalVisible(true)}>
              Сохранить фильтр
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </>
  );
};
