import { format } from 'date-fns';
import { AssignmentType } from 'types/document';

export const getRegNumberAndDate = (item: AssignmentType) => {
  let value = '';

  if (item.DocumentRegNumber) {
    value = `${item.DocumentRegNumber} `;
  }

  if (item.DocumentRegistrationDate) {
    const formatDate = format(
      new Date(item.DocumentRegistrationDate),
      'dd.MM.yyyy',
    );
    value += `от ${formatDate}`;
  }

  return value;
};
