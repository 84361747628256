import { ArchiveCaseDrawerForm } from 'pages/Archive/type';
import { errorThrow, TError } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { downloadByResponse } from 'shared/utils/downloadByResponse';

export const fetchArchiveItemsByYear = async ({
  tomeNumber,
  nomenclatureId,
  orgId,
}: {
  tomeNumber: string;
  orgId: number;
  nomenclatureId?: number;
}) => {
  try {
    const { data } = await $api.get(`archivecases/byorganisation/filter`, {
      params: {
        TomeNumber: tomeNumber,
        OrganisationId: orgId,
        NomenclatureId: nomenclatureId,
        Limit: 1000,
      },
    });

    return data.Data;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const getArchiveCaseById = async (id: number) => {
  try {
    const { data } = await $api.get(`archivecases/${id}`);

    return data;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const editArchiveCaseById = async (
  archiveCaseId: number,
  values: Partial<ArchiveCaseDrawerForm>,
) => {
  try {
    await $api.put(`archivecases/${archiveCaseId}`, values);
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const downloadArchiveCaseInventory = async (tomeNumber: number) => {
  try {
    const { data } = await $api.get(
      `archivecases/${tomeNumber}/inventory/docx`,
      {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
        responseType: 'blob',
      },
    );
    downloadByResponse(data, `Опись дела-${tomeNumber}.docx`);
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const getDocumentsByArchiveCase = async (
  archiveCaseId: number,
  page: number,
  query?: string,
) => {
  try {
    const { data } = await $api.get(
      `documents/byarchivecase/${archiveCaseId}`,
      {
        params: {
          Limit: 15,
          Page: page - 1,
          FastSearch: query,
        },
      },
    );

    return data;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const createDocumentLinkToArchiveCase = async (settings: {
  DocumentId: number;
  ArchiveCaseId: number;
}) => {
  try {
    await $api.post('archivecasedocuments', settings);
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const removeDocumentLinkToArchiveCase = async (params: {
  documentId: number;
  archiveCaseId: number;
}) => {
  try {
    await $api.delete(
      `archivecasedocuments/bydocument/${params.documentId}?ArchiveCaseId=${params.archiveCaseId}`,
    );
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const createArchiveCase = async (
  bodyParams: Partial<ArchiveCaseDrawerForm>,
) => {
  try {
    await $api.post(`archivecases`, bodyParams);
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const createArchiveCasesByTomeNumber = async (
  params: Partial<ArchiveCaseDrawerForm>,
) => {
  try {
    await $api.post(`archivecases/bytomenumber`, {
      TomeNumber: params.TomeNumber,
      ExpirationDateType: params.ExpirationDateType,
    });
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const fetchTomeList = async () => {
  try {
    const { data } = await $api.get(`archivecases/byorganisation/tomenumbers`);

    return data;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const fetchArchiveSignatures = async (caseId: number) => {
  try {
    const { data } = await $api.get(`archivecases/${caseId}/signatures`);

    return data;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};
