import { Drawer, Form } from 'antd';
import { TDepartment } from 'pages/AdminPage/Departments';
import { useCallback, useEffect, useState } from 'react';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DrawerHeader } from './DrawerHeader';

export const DepartmentInfoDrawer = ({
  visible,
  onClose,
  departmentId,
}: {
  visible: boolean;
  onClose: () => void;
  departmentId: number;
}) => {
  const [departmentInfo, setDepartmentInfo] = useState<TDepartment | null>(
    null,
  );
  const getDepartmentInfo = useCallback(() => {
    $api
      .get(`departments/${departmentId}`)
      .then(({ data }) => setDepartmentInfo(data))
      .catch(({ response }) => errorThrow(response));
  }, [departmentId]);

  useEffect(() => {
    getDepartmentInfo();
  }, [getDepartmentInfo]);

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      className="drawer"
      closeIcon={null}
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Карточка департамента" />
        <Form
          name="basic"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          className="drawer-form read mb35"
        >
          <Form.Item className="officer-item" label="Название">
            <span>{departmentInfo?.Name}</span>
          </Form.Item>
          <Form.Item className="officer-item" label="Краткое описание">
            <span>
              {departmentInfo?.ShortNotation?.length
                ? departmentInfo?.ShortNotation
                : '-'}
            </span>
          </Form.Item>
          <Form.Item className="officer-item" label="КПП">
            <span>{departmentInfo?.Kpp ?? '-'}</span>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};
