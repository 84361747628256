import { Modal } from 'antd';
import { DButton } from 'shared/ui-kit';

type DetachTagProps = {
  visible: boolean;
  detachTag: () => void;
  onChangeVisible: (visible: boolean) => void;
};

export const DetachTagModal = ({
  visible,
  detachTag,
  onChangeVisible,
}: DetachTagProps) => {
  return (
    <Modal
      title="Открепление тега"
      centered
      visible={visible}
      onOk={() => onChangeVisible(false)}
      onCancel={() => onChangeVisible(false)}
      footer={[
        <DButton className="mr15" small primary onClick={detachTag}>
          Открепить
        </DButton>,
        <DButton small defaultDanger onClick={() => onChangeVisible(false)}>
          Отмена
        </DButton>,
      ]}
    >
      <span>Вы уверены что хотите открепить тег?</span>
    </Modal>
  );
};
