import { Menu } from 'antd';
import { TSelectedFilter } from 'store/slice/table';
import deleteSvg from 'shared/assets/images/delete.svg';
import { Icon } from 'shared/components/IconComponent';
import '../FilterDrawer.scss';

type TFilterPresetList = {
  presetList: TSelectedFilter[];
  onLoadPreset: (item: TSelectedFilter) => void;
  onRemove: (item: TSelectedFilter) => void;
};

export const FilterPresetMenu = ({
  presetList,
  onLoadPreset,
  onRemove,
}: TFilterPresetList) => {
  return (
    <Menu className="filter-menu">
      {presetList.map(item => (
        <div className="flex-space">
          {' '}
          <Menu.Item
            className="filter-preset-item"
            onClick={() => onLoadPreset(item)}
            key={item.Id}
          >
            Фильтр №{item.Id}: {item.FilterName}
          </Menu.Item>
          <Icon
            icon={deleteSvg}
            alt="deleteSvg"
            onClick={() => onRemove(item)}
            tooltip="Удалить"
          />
        </div>
      ))}
    </Menu>
  );
};
