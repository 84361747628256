import { DrawerHeader } from './DrawerHeader';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer } from 'antd';
import { HelpInfo } from '../HelpBlock/types';
import { createReactEditorJS } from 'react-editor-js';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';
import { EDITOR_TOOLS } from '../HelpBlock/tools';

type TForgotPasswordDrawer = {
  visible: boolean;
  onClose: () => void;
};

const ReactEditorJS = createReactEditorJS();

export const ForgotPasswordDrawer = ({
  visible,
  onClose,
}: TForgotPasswordDrawer) => {
  const [helpInfo, setHelpInfo] = useState<HelpInfo | null>(null);
  const editorCore = useRef(null);

  const handleInitialize = useCallback(instance => {
    editorCore.current = instance;
  }, []);

  useEffect(() => {
    $api
      .get(`Helps/FORGOT_PASSWORD`)
      .then(res => setHelpInfo(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Восстановление пароля" />
        {helpInfo !== null ? (
          <ReactEditorJS
            style={{ width: 500 }}
            defaultValue={JSON.parse(helpInfo?.Text)}
            onInitialize={handleInitialize}
            tools={EDITOR_TOOLS}
            readOnly
          />
        ) : (
          <p />
        )}
      </div>
    </Drawer>
  );
};
