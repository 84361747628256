import { useCallback } from 'react';

/*
  hook for working with BEM

  Examples:

  const bem = useBem('ComponentBlockName')

  className={bem()}  -> className={'ComponentBlockName'}

  bem('elementName')  -> 'ComponentBlockName__elementName'

  bem('elementName', {redModifier: true }) -> ' ComponentBlockName__elementName  ComponentBlockName__elementName_redModifier'

  bem('elementName', ['greenModifier']) -> ' ComponentBlockName__elementName  ComponentBlockName__elementName_greenModifier'

  bem(['blueModifier']) -> ' ComponentBlockName_blueModifier'
*/

type BemElement = string;
type BemModifiers = Record<string, boolean> | string[];

const getModifierClasses = (
  elementName: string,
  modifiers?: BemModifiers,
): string | undefined => {
  if (!modifiers) {
    return undefined;
  }

  return (Array.isArray(modifiers)
    ? modifiers
    : Object.keys(modifiers).filter(modifier => modifiers[modifier])
  )
    .map(modifier => `${elementName}_${modifier}`)
    .join(' ');
};

export const useBem = (
  block: string,
): ((
  elementOrModifier?: BemElement | BemModifiers,
  modifiers?: BemModifiers,
) => string) => {
  return useCallback(
    (
      firstParam?: BemElement | BemModifiers,
      secondParam?: BemModifiers,
    ): string => {
      if (firstParam === undefined) {
        return block;
      }

      if (typeof firstParam === 'string') {
        const elementName = `${block}__${firstParam}`;
        const modifierClasses = getModifierClasses(elementName, secondParam);

        return [elementName, modifierClasses].join(' ');
      }

      const modifierClasses = getModifierClasses(block, firstParam);

      return [block, modifierClasses].join(' ');
    },
    [block],
  );
};
