import { TableResult } from 'types/tableData';
import { ControlStatus } from './constants';

export const convertData = (data: TableResult[]) => {
  return data.map(item => ({ key: item.Id, ...item }));
};

export const formatStatusName = (status?: string) => {
  return ControlStatus?.find(item => item.status === status)?.title;
};

export const IsJsonString = (str?: string) => {
  try {
    str && JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const getQueryByTags = (tagIdsList: number[]) => {
  const query = new URLSearchParams();
  tagIdsList?.forEach(tagId => query.append('TagIds', String(tagId)));

  return query.toString();
};
