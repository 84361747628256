import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TActiveCount = {
  CountActiveAssignments: number;
  CountActiveDocuments: number;
};

type TFilter = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  ContactId: number;
  FilterData: string;
  FilterName: string;
  IsSideBarShow: boolean;
};

type TApiSidebarData = {
  id: number;
  activeCount: TActiveCount | null;
  userOrganisations:
    | {
        Id: number;
        Count: number;
      }[]
    | [];
  filtersList: TFilter[];
};

const initialState: TApiSidebarData = {
  id: 0,
  activeCount: null,
  userOrganisations: [],
  filtersList: [],
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    updateCurrentOrganisation(state, action: PayloadAction<number>) {
      state.id = action.payload;
    },
    updateActiveCount(state, action: PayloadAction<TActiveCount | null>) {
      state.activeCount = action.payload;
    },
    updateOrganisationsByUser(state, action: PayloadAction<any>) {
      state.userOrganisations = action.payload;
    },
    updateFiltersSidebar(state, action: PayloadAction<TFilter[]>) {
      state.filtersList = action.payload;
    },
  },
});

export const {
  updateCurrentOrganisation,
  updateActiveCount,
  updateOrganisationsByUser,
  updateFiltersSidebar,
} = sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
