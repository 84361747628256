import { DatePicker } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'moment/locale/ru';
import { useDatePicker } from './useDatePicker';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { memo, useMemo } from 'react';
moment.locale('ru');

export const DatePick = memo(({ className, style, ...props }: any) => {
  const { renderCustomDate, onYearChange } = useDatePicker();
  const { dates } = useSelector((state: ReduxState) => state.calendarReducer);

  const disableDates = useMemo(() => {
    return dates.filter(item => item.holiday);
  }, [dates]);

  const disabledDate = (current: moment.Moment) => {
    return disableDates.find(
      date => date.date === moment(current).format('DD.MM.YYYY'),
    );
  };

  return (
    <DatePicker
      className={`${className ?? ''} dpicker`}
      onPanelChange={value => onYearChange(Number(value?.format('YYYY')))}
      dateRender={currentDate => renderCustomDate(currentDate)}
      locale={locale}
      disabledDate={disabledDate}
      style={style}
      showNow={false}
      {...props}
    />
  );
});
