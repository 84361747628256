import { Button, Table } from 'antd';
import { useHistory } from 'react-router';
import { useBreadcrumb } from 'shared/hooks/useBreadcrumb';
import { useDispatch } from 'react-redux';
import './CheckElectronicSignature.scss';

export const CheckElectronicSignature = () => {
  const { closeBreadcrumbs } = useBreadcrumb();
  const history = useHistory();
  const dispatch = useDispatch();
  //TODO: any до получения данных с бэка
  const columns = [
    {
      key: 'Type',
      dataIndex: 'Type',
      title: '',
      render: (text: string, item: any) => (
        <span className="document-text">
          {item.Type} {item.title}
        </span>
      ),
      width: 200,
    },
    {
      key: 'Status',
      dataIndex: 'Status',
      title: 'Статус ЭП',
      width: 107,
    },
    {
      key: 'Officer',
      dataIndex: 'Officer',
      title: 'Сотрудник',
      width: 160,
    },
    {
      key: 'Time',
      dataIndex: 'Time',
      title: 'Время подписи',
      width: 150,
    },
    {
      key: 'Сertificate',
      dataIndex: 'Сertificate',
      title: 'Сертификат',
    },
  ];

  const data = [
    {
      Type: 'Документ:',
      title: 'Исх. №34-12-01-11',
      key: 1,
      children: [
        {
          key: 1,
          Status: 'Верна',
          Officer: 'Заботина Л.Ю.',
          Time: 'Утверждение',
          Сertificate:
            '28.10.2021 08.49 Облкомсоцзащиты 7FDGHF5674DFGD23GFHD4F3DFGH',
        },
        {
          Type: 'Файл:',
          title: 'Сопровод.doc',
          key: 2,
          children: [
            {
              key: 1,
              Status: 'Верна',
              Officer: 'Заботина Л.Ю.',
              Time: 'Утверждение',
              Сertificate:
                '28.10.2021 08.49 Облкомсоцзащиты 7FDGHF5674DFGD23GFHD4F3DFGH',
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        pagination={{ position: [] }}
        columns={columns}
      />
      <div className="buttons">
        <Button className="mr15">Распечатать</Button>
        <Button
          danger
          onClick={() => {
            history.goBack();
            closeBreadcrumbs(dispatch, history.location.pathname);
          }}
        >
          Закрыть
        </Button>
      </div>
    </>
  );
};
