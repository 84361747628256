import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { PreLoader } from 'shared/components/PreLoader';
import { useBreadcrumb } from 'shared/hooks/useBreadcrumb';
import { copyToClipboard, getHost } from 'shared/utils';
import { DButton } from 'shared/ui-kit';
import { useReactToPrint } from 'react-to-print';
import { ReduxState } from 'store/store';
import { DocumentInfo } from './DocumentInfo';
import { GeneralInfo } from './GeneralInfo';
import { DocumentControl } from './DocumentControl';
import { RoutTable } from './TableDT/RoutTable';
import { CommentsTable } from './CommentsTable';
import printSvg from 'shared/assets/images/print.svg';
import linkSvg from 'shared/assets/images/Link.svg';
import { Icon } from 'shared/components/IconComponent';
import { AssignmentTable } from './AssignmentTable';
import { format } from 'date-fns';

export const DocumentFooter = () => {
  const [showComponent, setShowComponent] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { closeBreadcrumbs } = useBreadcrumb();
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const port = getHost();
  const componentRef = useRef(null);
  const pageStyle = 'width: 90%';

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'копия электронного документа АСЭД',
    pageStyle,
  });

  useEffect(() => {
    if (showComponent) {
      setLoader(true);
      setTimeout(() => {
        handlePrint();
        setShowComponent(false);
        setLoader(false);
      }, 2500);
    }
  }, [handlePrint, showComponent]);
  const PAGE_TITLE = `Документ № ${documentInfo?.RegNumber ?? 'б/н'} от ${
    documentInfo?.RegDate
      ? format(new Date(documentInfo?.RegDate), 'dd.MM.yyyy')
      : '-'
  }`;
  return (
    <>
      {loader ? <PreLoader /> : null}
      <div
        className="button-margin"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {showComponent && (
          <div
            className="forPrint mt40"
            style={{ padding: 24 }}
            ref={componentRef}
          >
            <div>
              <h1 className="mb40">{PAGE_TITLE}</h1>
              <DocumentInfo currentDocument={documentInfo} />
              <h2 className="mt35">Общая информация</h2>
              <GeneralInfo currentDocument={documentInfo} canEdit={false} />
              <h2 className="mt35">Контроль</h2>
              <DocumentControl currentDocument={documentInfo} canEdit={false} />
              <RoutTable documentId={documentInfo?.Id} />
              <h2 className="mt35">Задачи</h2>
              <AssignmentTable
                isPrint
                assignments={documentInfo?.Assignments}
              />
              <CommentsTable documentId={documentInfo?.Id} type="read" />
            </div>
          </div>
        )}

        <DButton
          className="mr15"
          small
          defaultDanger
          onClick={() => {
            history.goBack();
            closeBreadcrumbs(dispatch, history.location.pathname);
          }}
        >
          Закрыть
        </DButton>
        <div className="footer-icons">
          <Icon
            icon={printSvg}
            alt="printSvg"
            onClick={() => setShowComponent(true)}
            className="footer-navigation mr15"
            children="Печать"
          />
          <Icon
            icon={linkSvg}
            alt="linkSvg"
            onClick={() => copyToClipboard(`${port}${location.pathname}`)}
            className="footer-navigation"
            children="Получить ссылку"
          />
        </div>
      </div>
    </>
  );
};
