import { errorThrow, TError } from 'shared/utils';
import { $api } from 'shared/utils/api';

export const fetchUserGroups = async () => {
  try {
    const { data } = await $api.get(
      'organisationreceivergroups/byorganization',
    );
    return data;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const removeUserGroup = async (id: number) => {
  try {
    await $api.delete(`organisationreceivergroups/${id}/byorganization`);
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const getUserGroup = async (id: number) => {
  try {
    const { data: userGroup } = await $api.get(
      `organisationreceivergroups/${id}/byorganization`,
    );
    const { data: receiverGroups } = await $api.get(
      `organisationreceivergroups/${id}/byorganisation/receivergroupitems`,
    );

    return {
      ...userGroup,
      ReceiverGroupItems: receiverGroups,
    };
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};
