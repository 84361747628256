import { Table } from 'antd';
import { NomenclatureDrawer } from 'shared/components/CustomDrawers/NomenclatureDrawer';
import { UploadBookDrawer } from 'shared/components/CustomDrawers/UploadBookDrawer/UploadBookDrawer';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { NOMENCLATURES } from 'shared/constants/helpCodes';
import cn from 'classnames';
import { useNomenclatures } from './useNomenclatures';
import { Icon } from 'shared/components/IconComponent';
import searchSvg from 'shared/assets/images/search.svg';

export const Nomenclatures = () => {
  const {
    onChangeUploadVisible,
    onChangeVisibleDrawer,
    visibleUpload,
    getData,
    isLoading,
    onSearch,
    columns,
    visible,
    currentNomenclature,
    type,
    updateTableType,
    archiveList,
    nomenclatureList,
    isArchive,
    onGenerateStructure,
  } = useNomenclatures();

  return (
    <>
      {visibleUpload && (
        <UploadBookDrawer
          visible={visibleUpload}
          onClose={() => onChangeUploadVisible(false)}
          type="nomenclature"
          title="номенклатуры"
          getData={getData}
        />
      )}
      {visible && (
        <NomenclatureDrawer
          visible={visible}
          onClose={() => onChangeVisibleDrawer(false)}
          item={currentNomenclature}
          type={type}
          getData={getData}
        />
      )}
      <PageHeader title="Номенклатура" code={NOMENCLATURES} />
      <div className="flex-space mb15">
        <div>
          <DButton
            className="mr15"
            onClick={() => onChangeVisibleDrawer(true)}
            small
            primary
          >
            + Создать
          </DButton>
          <DButton
            className={cn('mr15', { 'selected-button': isArchive })}
            small
            onClick={updateTableType}
          >
            Архив
          </DButton>
          <DButton className="mr15" small onClick={onGenerateStructure}>
            Сформировать структуру
          </DButton>
        </div>
        <DInput
          type="text"
          style={{ maxWidth: 630, width: '100%' }}
          placeholder="Поиск по наименованию"
          icon={<Icon icon={searchSvg} alt="searchSvg" />}
          onChange={({ target: { value } }) => onSearch(value)}
        />
        <DButton
          className="ml15"
          small
          defaultPrimary
          onClick={() => onChangeUploadVisible(true)}
        >
          Загрузить
        </DButton>
      </div>
      <Table
        loading={isLoading}
        dataSource={!isArchive ? nomenclatureList : archiveList}
        columns={columns}
        scroll={{ x: '100%' }}
        pagination={{
          showSizeChanger: false,
        }}
      />
    </>
  );
};
