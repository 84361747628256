import _ from 'lodash';
import { ControlPosition } from 'react-draggable';
import { TDocumentFile } from 'types/document';
import { stampsLength } from './constants';
import {
  PageWithStamp,
  StampCoordinates,
  StampInfo,
  StampTypes,
  TStampTypes,
} from './types';

export const getOnlyPdfs = (fileList: TDocumentFile[]) => {
  return fileList.filter(item => item.Name.match(/.pdf/));
};

export const generateStampCoordinate = (
  page: number,
  stampType?: TStampTypes,
) => {
  const getDefaultMockStamp = (type?: TStampTypes) => {
    return {
      page,
      stamp: {
        type: type,
        x: 0,
        y: 0,
      },
      isCreated: true,
    };
  };

  if (stampType !== StampTypes.Full) {
    return getDefaultMockStamp(stampType);
  }

  return [
    getDefaultMockStamp('OnlyRegNumber'),
    getDefaultMockStamp('OnlyStamp'),
  ];
};

export const getStampBounds = (coordinate: ControlPosition) => ({
  top: 0,
  left: -50,
  // right: coordinate?.x && coordinate?.x / 1.3,
  right: coordinate?.x && coordinate?.x - 175,
  bottom: coordinate?.y - 115,
});

export const getRegNumberBounds = (coordinate: ControlPosition) => ({
  top: 0,
  left: 0,
  right: coordinate?.x - 45,
  bottom: coordinate?.y && coordinate?.y - 25,
});

export const checkIsStampOnThePage = (
  array: PageWithStamp[],
  pageNumber: number,
) => !!array.find(item => item.page === pageNumber && item.isCreated);

const convertToIntegerNumber = (value: number) => Math.ceil(value);

const checkStampTypeToGetCoordinate = (
  item: PageWithStamp,
  type: TStampTypes,
  key: keyof StampCoordinates,
) => {
  return item.stamp.type === type
    ? convertToIntegerNumber(item.stamp[key])
    : undefined;
};

export const convertStampsInfo = (
  pageWithStampList: PageWithStamp[],
  fileId?: string,
  pageSize?: StampCoordinates,
) => {
  const arr = [];
  const stampsOfPage = _.groupBy(pageWithStampList, 'page');

  for (const item in stampsOfPage) {
    const dataOfItem = stampsOfPage[item];
    const firstStamp = dataOfItem[0];
    const secondStamp = dataOfItem[1];

    const getStampStatus = () => {
      if (dataOfItem.length === 2) {
        return 'Unknown';
      } else {
        if (firstStamp.stamp.type === StampTypes.Stamp) return 'Signature';
        else return 'Registration';
      }
    };

    let stampInfo: StampInfo = {
      ...stampsLength,
      FileId: fileId,
      PageNumber: Number(item),
      PageWidth: pageSize?.x,
      PageHeight: pageSize?.y,
      StampType: getStampStatus(),
    };

    if (dataOfItem.length === 2) {
      stampInfo = {
        ...stampInfo,
        X1: convertToIntegerNumber(secondStamp.stamp.x),
        Y1: convertToIntegerNumber(secondStamp.stamp.y),
        X2: convertToIntegerNumber(firstStamp.stamp.x),
        Y2: convertToIntegerNumber(firstStamp.stamp.y),
      };
    } else {
      stampInfo = {
        ...stampInfo,
        X1: checkStampTypeToGetCoordinate(firstStamp, StampTypes.Stamp, 'x'),
        Y1: checkStampTypeToGetCoordinate(firstStamp, StampTypes.Stamp, 'y'),
        X2: checkStampTypeToGetCoordinate(
          firstStamp,
          StampTypes.RegNumber,
          'x',
        ),
        Y2: checkStampTypeToGetCoordinate(
          firstStamp,
          StampTypes.RegNumber,
          'y',
        ),
      };
    }

    arr.push(stampInfo);
  }

  return arr;
};
