import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import { onLogout } from 'api/login/login';
import { deleteCookie } from 'shared/utils/getAuthToken';
import { updateUser } from 'store/slice/table';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { authUser, updateSubstituate } from 'store/slice/auth';
import { useHistory } from 'react-router';
import { errorThrow, successNotification } from 'shared/utils';
import { NoticeDrawer } from '../CustomDrawers';
import { SubstitutionDrawer } from '../CustomDrawers/SubstitutionDrawer';
import { getNoticeCount } from '../CustomDrawers/NoticeDrawer';
import { fetchCalendarData } from 'api/calendar/fetchCalendarData';
import './Header.scss';
import moment from 'moment';
import signOutSvg from 'shared/assets/images/signOut.svg';
import userSvg from 'shared/assets/images/user.svg';
import notifEmptySvg from 'shared/assets/images/notifEmpty.svg';
import notifActiveSvg from 'shared/assets/images/notifActive.svg';
import substitutionSvg from 'shared/assets/images/substitutionList.svg';
import { Icon } from '../IconComponent';
import { getActualYear } from './GetActualYear';

export type TUser = {
  Id: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  PositionId: number;
  PositionName: string;
  ContactSubstitutionId: number;
  DepartmentName: string;
  ExpiryDate: string;
  ContactId: number;
};

export const Header: FC = (): ReactElement => {
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const [userList, setUserList] = useState<TUser[]>([]);
  const [displayedUser, setDisplayedUser] = useState<TUser>();
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [visbileSubstituateDrawer, setVisibleSubstituateDrawer] = useState<
    boolean
  >(false);
  const [noticeCount, setNoticeCount] = useState<number>(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const prevUser =
    localStorage.getItem('previousUserInfo') &&
    JSON.parse(localStorage.getItem('previousUserInfo') ?? '');

  const getUserName = () => {
    const currentName = `${user?.CurrentContact?.LastName ?? ''} ${user
      ?.CurrentContact?.FirstName ?? ''} ${user?.CurrentContact?.MiddleName ??
      ''}`;
    const substituteName = `${displayedUser?.LastName ??
      ''} ${displayedUser?.FirstName ?? ''} ${displayedUser?.MiddleName ?? ''}`;
    if (!displayedUser) return currentName;

    return `${currentName} (${substituteName})`;
  };

  const onCloseSubstituateDrawer = (bool: boolean) => {
    setVisibleSubstituateDrawer(bool);
  };

  const getSubstitutions = useCallback(() => {
    if (!userList.length)
      $api
        .get(`accounts/substitutions`)
        .then(res => setUserList(res.data))
        .catch(({ response }) => errorThrow(response));
  }, [userList.length]);

  const getData = useCallback(() => {
    $api
      .get('/Accounts/IsAuthorize')
      .then(res => {
        if (res.data.CurrentUser.SubstitutionId) {
          setDisplayedUser(prevUser?.CurrentContact);
        } else {
          localStorage.setItem('previousUserInfo', JSON.stringify(res.data));
        }
        dispatch(updateUser(res.data));
      })
      .catch(({ response }) => {
        errorThrow(response);
        deleteCookie('auth_token');
        dispatch(authUser(false));
      });
  }, [dispatch, prevUser]);

  useEffect(() => {
    getData();
    fetchCalendarData({ dispatch, year: getActualYear() });
    getNoticeCount().then(data => setNoticeCount(data.Count));
  }, []);

  const updateSubstitution = useCallback(
    async (item?: TUser) => {
      if (!item) {
        localStorage.removeItem('previousUserInfo');
      }

      const { data } = await $api.post(`Accounts/1`, {
        Id: item?.ContactId ?? null,
      });

      try {
        dispatch(updateSubstituate(item?.ContactId ?? null));
        document.cookie = `auth_token=${data}; path=/; expires=${moment().add(
          18,
          'hour',
        )}`;
        getData();
        setDisplayedUser(item ?? undefined);
        setVisibleSubstituateDrawer(false);
        successNotification(
          item ? 'Пользователь успешно замещен' : 'Замещение снято',
        );
        history.push('/');
      } catch ({ response }) {
        errorThrow(response);
      }
    },
    [dispatch, getData, history],
  );

  const onClose = (bool: boolean) => {
    setVisibleDrawer(bool);
  };

  return (
    <>
      {visbileSubstituateDrawer && (
        <SubstitutionDrawer
          userList={userList}
          visible={visbileSubstituateDrawer}
          onClose={onCloseSubstituateDrawer}
          displayedUser={displayedUser}
          updateSubstitution={updateSubstitution}
        />
      )}
      {visibleDrawer && (
        <NoticeDrawer visible={visibleDrawer} onClose={onClose} />
      )}
      <section id="user-info">
        <div className="user-info__wrapper">
          {displayedUser ? (
            <span
              className="header-item"
              onClick={() => {
                updateSubstitution();
                history.push('/');
              }}
            >
              Cнять замещение
            </span>
          ) : (
            <Icon
              icon={substitutionSvg}
              alt="substitutionSvg"
              className="header-item"
              onClick={() => {
                setVisibleSubstituateDrawer(true);
                getSubstitutions();
              }}
              tooltip="Список замещений"
            />
          )}
          {noticeCount ? (
            <Icon
              icon={notifActiveSvg}
              alt="notifActiveSvg"
              className="header-item"
              onClick={() => setVisibleDrawer(true)}
              tooltip="Уведомления"
            />
          ) : (
            <Icon
              icon={notifEmptySvg}
              alt="notifEmptySvg"
              className="header-item"
              onClick={() => setVisibleDrawer(true)}
              tooltip="Уведомления"
            />
          )}
          {!user?.CurrentContact?.Id ? (
            <Skeleton.Input className="header-item w300" active />
          ) : (
            <Icon
              icon={userSvg}
              alt="userSvg"
              className="header-item"
              children={getUserName()}
              onClick={() => {
                history.push(`/officer-card/${user?.CurrentContact?.Id}`);
                sessionStorage.removeItem('uniqNumberAtom');
                sessionStorage.removeItem('uniqItem');
              }}
            />
          )}
          <Icon
            icon={signOutSvg}
            alt="signOutSvg"
            className="header-item"
            children="Выход"
            onClick={() => onLogout(dispatch)}
          />
        </div>
      </section>
    </>
  );
};
