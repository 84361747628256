import { ArchiveItemStatus } from './type';
import { ExpirationTypes } from 'pages/AssignmentCard/types';

export const ExpirationDates: {
  title: string;
  value: number;
  type: ExpirationTypes;
}[] = [
  {
    title: 'Не задано',
    value: 0,
    type: 'Unknown',
  },
  {
    title: '1 год',
    value: 1,
    type: 'OneYear',
  },
  {
    title: '3 года',
    value: 3,
    type: 'ThreeYears',
  },
  {
    title: '5 лет',
    value: 5,
    type: 'FiveYears',
  },
  {
    title: 'Постоянно',
    value: 6,
    type: 'Constantly',
  },
];

export const ArchiveItemStatusList: {
  title: string;
  status: ArchiveItemStatus;
}[] = [
  {
    title: 'Не задано',
    status: 'Unknown',
  },
  {
    title: 'Черновик',
    status: 'Draft',
  },
  {
    title: 'Хранение',
    status: 'Storage',
  },
  {
    title: 'Готовый к уничтожению',
    status: 'ReadyToDestroy',
  },
  {
    title: 'Уничтоженный',
    status: 'Destroyed',
  },
];
