import {
  Form,
  Modal,
  notification,
  Popover,
  Skeleton,
  Table,
  Tabs,
} from 'antd';
import { AssignmentToCheck } from 'pages/AssignmentCard/components/AssignmentToCheck';
import { AssignmentTable } from 'pages/DocumentPage/components/AssignmentTable';
import { $api } from 'shared/utils/api';
import {
  AssignmentType,
  CurrentDocument,
  TAssignmentLink,
} from 'types/document';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import {
  THistoryColumns,
  useDrawer,
} from 'shared/components/CustomDrawers/useDrawer';
import { OfficerDrawer } from 'shared/components';
import { useHistory } from 'react-router';
import { AssignmentRegistrationsForm } from './components/AssignmentRegistrationsForm';
import { AssignmentDocumentDispatchForm } from './components/AssignmentDocumentDispatchForm';
import { AssignmentDocumentSignForm } from './components/AssignmentDocumentSignForm';
import { AssignmentApprovalForm } from './components/AssignmentApprovalForm';
import axios, { AxiosResponse } from 'axios';
import { prefix } from 'api/prefix';
import { getAuthToken } from 'shared/utils/getAuthToken';
import {
  fetchAssignmentsData,
  getDocumentInfo,
} from 'api/assignments/fetchAssignmentsData';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { AssignmentStamp } from './components/AssignmentStamp';
import {
  errorThrow,
  getFullName,
  getIsComma,
  selectAssignmentType,
  successNotification,
} from 'shared/utils';
import { DButton } from 'shared/ui-kit';
import {
  AssignmentEditDrawer,
  DocumentDrawer,
} from 'shared/components/CustomDrawers';
import { Phases, UseAssignments } from './types';
import { PopoverContent } from './components/PopoverContent';
import { notificationToSendAssignment } from './utils';
import {
  editAssignmentStatus,
  fetchAssignmentList,
} from 'api/assignments/fetchAssignmentList';
import { updateTableData } from 'pages/MainPage/MainPage';
import { DepartmentInfoDrawer } from 'shared/components/CustomDrawers/DepartmentInfoDrawer';
import { selectActiveAssignment } from 'store/slice/assignments';
import { Icon } from 'shared/components/IconComponent';
import printSvg from 'shared/assets/images/print.svg';
import { useReactToPrint } from 'react-to-print';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { AssignmentGeneralInfo } from './components/AssignmentGeneralInfo';

enum TabKeys {
  CHILDREN = '1',
  HISTORY = '2',
}

export const useAssignments = ({
  assignment,
  onCloseDrawer,
  getAssignment,
}: UseAssignments) => {
  const [phases, setPhases] = useState<Phases[]>([]);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [visibleOfficerDrawer, setVisibleOfficerDrawer] = useState<boolean>(
    false,
  );
  const [clickableOfficer, setClickableOfficer] = useState<number | undefined>(
    0,
  );
  const [loader, setLoader] = useState<boolean>(true);
  const [assignmentHistory, setAssignmentHistory] = useState<THistoryColumns[]>(
    [],
  );
  const [visibleDepartmentDrawer, setVisibleDepartmentDrawer] = useState<
    boolean
  >(false);
  const [selectedDepartment, setSelectedDepartment] = useState<number>(0);
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const [IsCanEdit, setIsCanEdit] = useState<boolean>(false);
  const { activeAssignment } = useSelector(
    (state: ReduxState) => state.assignmentReducer,
  );
  const [childrenList, setChildrenList] = useState<AssignmentType[]>();
  const [documentData, setDocumentData] = useState<CurrentDocument | null>();
  const [linkList, setLinkList] = useState<TAssignmentLink[]>();
  const [selectedDocument, setSelectedDocument] = useState<number | null>(null);
  const [nextExecutor, setNextExecutor] = useState<string>();
  const [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState<boolean>(
    false,
  );
  const [selectedTab, setSelectedTab] = useState<string>('1');
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const {
    filterType,
    pagination,
    searchValue,
    activeAssignmentSortParams,
  } = useSelector((state: ReduxState) => state.assignmentReducer);
  const {
    tablePagination,
    searchValue: documentSearchValue,
    tableType,
    activeSortParams,
  } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { historyColumns, assignmentState, docType } = useDrawer();
  const { TabPane } = Tabs;
  const history = useHistory();
  const dispatch = useDispatch();
  const headerForSendFiles = getAuthToken('auth_token');
  const documentType = `Documents${documentInfo?.Type ?? documentData?.Type}`;
  const docId = documentInfo?.Id ?? documentData?.Id;

  const openPopover = () => {
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
  };

  const onCloseVisibleOfficerDrawer = (bool: boolean) => {
    setVisibleOfficerDrawer(bool);
  };

  const onCloseDocumentDrawer = (bool: boolean) => {
    setVisibleDocumentDrawer(bool);
  };

  const onCloseEdit = (bool: boolean) => {
    setVisibleEdit(bool);
  };

  const selectType = (
    arr: { Type: string; title: string }[],
    type: string | undefined,
  ) => {
    return arr
      .filter(assignment => assignment.Type === type)
      .map(item => item.title);
  };

  const getNextExecutorName = useCallback(data => {
    if (data.ExecutionGroups) {
      const departmentRequests: Promise<AxiosResponse>[] =
        data.ExecutionGroups.map((departmentId: number) =>
          $api.get(`Departments/${departmentId}`),
        ) ?? [];
      Promise.all(departmentRequests).then(res => {
        let name = '';
        departmentRequests.forEach(
          (_d: Promise<AxiosResponse>, index: number) => {
            const { Name } = res[index].data;
            name += `${Name}${getIsComma(departmentRequests, index)}`;
          },
        );
        setNextExecutor(name);
      });
    } else {
      const contactRequests: Promise<AxiosResponse>[] =
        data?.Executions?.map((contactId: number) =>
          $api.get(`contacts/${contactId}/full`),
        ) ?? [];
      Promise.all(contactRequests).then(res => {
        let name = '';
        contactRequests.forEach((_d: Promise<AxiosResponse>, index: number) => {
          const { LastName, FirstName, MiddleName } = res[index].data;

          name += `${LastName} ${FirstName} ${MiddleName}${getIsComma(
            contactRequests,
            index,
          )}`;
        });
        setNextExecutor(name);
      });
    }
  }, []);

  const updateRoute = useCallback(() => {
    if (assignment)
      $api
        .get(`documents/${assignment?.DocumentId}/flow/nextphases`, {
          params: {
            AssignmentId:
              activeAssignment > 0 ? activeAssignment : assignment?.Id,
          },
        })
        .then(({ data }) => {
          setPhases(data);
          if (data.length) {
            getNextExecutorName(data[0].Data);
          }
        })
        .catch(({ response }) => errorThrow(response));
  }, [activeAssignment, assignment, getNextExecutorName]);

  const getLinks = useCallback(() => {
    setLinkList(assignment?.DocumentLinks);
  }, [assignment?.DocumentLinks]);

  useEffect(() => {
    getLinks();
  }, [getLinks]);

  const sendFiles = useCallback(
    (files, documentId, assignmentId) => {
      let formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append('FormFile', files[i]);
        formData.append('ObjectId', `${assignmentId}`);
        formData.append('ObjectType', 'Assignment');
        axios
          .post(`${prefix}/Files`, formData, {
            headers: {
              ...headerForSendFiles,
              'Content-Type': 'multipart/form-data; ',
            },
          })
          .then(() => {
            successNotification('Файлы успешно отправлены');
          })
          .catch(() =>
            notification['error']({
              message: 'При загрузке файла произошла ошибка!',
            }),
          );
        formData.delete('FormFile');
      }
    },
    [headerForSendFiles],
  );

  const updateMainTables = useCallback(() => {
    if (history.location.pathname.split('/').includes('assignments')) {
      fetchAssignmentList(
        dispatch,
        {
          type: filterType,
          pagination,
          query: searchValue,
        },
        activeAssignmentSortParams,
      );
    } else {
      updateTableData({
        type: tableType,
        dispatch,
        tablePagination,
        searchValue: documentSearchValue,
        sortParams: activeSortParams,
      });
    }
  }, [
    dispatch,
    documentSearchValue,
    filterType,
    history.location.pathname,
    pagination,
    searchValue,
    tablePagination,
    tableType,
    activeSortParams,
    activeAssignmentSortParams,
  ]);

  const onReject = useCallback(
    (resolution, documentId, assignmentId) => {
      $api
        .patch(`Assignments/${assignmentId}/Return`, {
          Resolution: resolution,
        })
        .then(() => {
          onCloseDrawer?.(false);
          if (documentInfo) {
            getDocumentInfo({ documentType, documentId, dispatch });
          } else {
            updateMainTables();
          }
          successNotification('Задача отклонена');
        })
        .catch(({ response }) => errorThrow(response));
    },
    [dispatch, documentInfo, documentType, onCloseDrawer, updateMainTables],
  );

  const getAssignmentHistory = useCallback(() => {
    $api
      .get(`Assignments/${assignment?.Id}/history`)
      .then(res => setAssignmentHistory(res.data))
      .catch(err => console.log(err.message));
  }, [assignment?.Id]);

  const onSuccessResponse = (text: string) => {
    if (activeAssignment) {
      onCloseDrawer?.(false);
      if (documentInfo && assignment?.DocumentId) {
        getDocumentInfo({
          documentType,
          documentId: assignment?.DocumentId,
          dispatch,
        });
      } else {
        updateMainTables();
      }
    } else {
      history.goBack();
    }
    successNotification(text);
    notificationToSendAssignment(nextExecutor);
  };

  useEffect(() => {
    if (selectedTab === TabKeys.HISTORY && !assignmentHistory?.length) {
      getAssignmentHistory();
    }
  }, [assignmentHistory?.length, getAssignmentHistory, selectedTab]);

  const getChildrenAssignments = useCallback(() => {
    setChildrenList(assignment?.ChildAssignments);
  }, [assignment?.ChildAssignments]);

  const getPermissionForEdit = useCallback(() => {
    if (assignment?.Id)
      $api
        .get(`Assignments/${assignment?.Id}/Check/IsAuthor`)
        .then(res => setIsCanEdit(res.data))
        .catch(({ response }) => errorThrow(response));
  }, [assignment?.Id]);

  const getDocumentData = useCallback(() => {
    if (assignment?.DocumentId)
      $api
        .get(`Documents/${assignment?.DocumentId}/full`)
        .then(res => setDocumentData(res.data))
        .catch(({ response }) => errorThrow(response))
        .finally(() => setLoader(false));
  }, [assignment?.DocumentId]);

  useEffect(() => {
    setChildrenList([]);
    updateRoute();
    getPermissionForEdit();
    if (assignment) {
      getChildrenAssignments();
    }
  }, [assignment]);

  useEffect(() => {
    getDocumentData();
  }, [getDocumentData, assignment?.DocumentId]);

  const selectPathToDocument = () =>
    `/active-documents/Documents${documentData?.Type}/${documentData?.Id}`;

  const renderForm = () => {
    if (assignment?.Type === 'ToRegistration')
      return (
        <AssignmentRegistrationsForm
          phases={phases}
          assignment={assignment}
          documentData={documentData}
          onSuccessResponse={onSuccessResponse}
        />
      );
    else if (assignment?.Type === 'ToDispatch')
      return (
        <AssignmentDocumentDispatchForm
          phases={phases}
          assignment={assignment}
          documentData={documentData}
          onSuccessResponse={onSuccessResponse}
        />
      );
    else if (assignment?.Type === 'ToSigning')
      return (
        <AssignmentDocumentSignForm
          onReject={onReject}
          phases={phases}
          assignment={assignment}
          documentData={documentData}
          onSuccessResponse={onSuccessResponse}
        />
      );
    else if (assignment?.Type === 'ToApplyingStamp')
      return (
        <AssignmentStamp
          phases={phases}
          assignment={assignment}
          documentData={documentData}
          onSuccessResponse={onSuccessResponse}
        />
      );
    else if (assignment?.Type === 'ToCheck')
      return (
        <AssignmentToCheck
          phases={phases}
          assignment={assignment}
          documentData={documentData}
          onSuccessResponse={onSuccessResponse}
        />
      );
    else
      return (
        <AssignmentApprovalForm
          phases={phases}
          onReject={onReject}
          assignment={assignment}
          documentData={documentData}
          onSuccessResponse={onSuccessResponse}
        />
      );
  };

  const showLinkDocs =
    assignment?.Type === 'ToRegistration' ||
    assignment?.Type === 'ToDispatch' ||
    assignment?.Type === 'ToSigning' ||
    assignment?.Type === 'ToApplyingStamp' ||
    assignment?.Type === 'ToCheck';

  const onDownloadFile = (item: any) => {
    axios
      .get(`${prefix}/Files/Download/${item.Id}`, {
        headers: getAuthToken('auth_token'),
        responseType: 'blob',
      })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${item.Name}`);
        document.body.appendChild(link);
        link.click();
        successNotification('Файл скачан');
      })
      .catch(({ response }) => errorThrow(response));
  };

  const onDeleteAssignment = useCallback(
    documentId => {
      $api
        .delete(`Assignments/${assignment?.Id}/ByAuthor`)
        .then(() => {
          if (documentData) {
            getDocumentInfo({ documentType, documentId, dispatch });
            if (onCloseDrawer) {
              onCloseDrawer(false);
            }
          } else history.goBack();
        })
        .catch(({ response }) => errorThrow(response));
    },
    [
      assignment?.Id,
      dispatch,
      documentData,
      documentType,
      history,
      onCloseDrawer,
    ],
  );

  const executorName = assignment?.ExecutionGroupId
    ? assignment?.ExecutionGroupName
    : getFullName({
        lastName: assignment?.ExecutorLastName,
        middleName: assignment?.ExecutorMiddleName,
        firstName: assignment?.ExecutorFirstName,
      });

  const disableExecuteTab =
    assignment?.State === 'Completed' ||
    assignment?.State === 'Rejected' ||
    assignment?.State === 'Draft' ||
    assignment?.State === 'Returned';

  const onSendAssignment = () => {
    assignment &&
      editAssignmentStatus(assignment).then(() => {
        dispatch(selectActiveAssignment(assignment?.Id));
        getDocumentInfo({
          documentType,
          documentId: documentData?.Id,
          dispatch,
        });
        fetchAssignmentsData({
          array: documentData?.Assignments,
          dispatch,
          type: 'all',
        });
        getAssignment?.();
      });
  };

  const componentRef = useRef(null);
  const pageStyle = 'width: 90%';

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'копия электронной задачи АСЭД',
    pageStyle,
  });

  const assignmentInfoForm = () =>
    loader ? (
      <Skeleton />
    ) : (
      <section id="assignment-form">
        {visibleDepartmentDrawer && (
          <DepartmentInfoDrawer
            visible={visibleDepartmentDrawer}
            onClose={() => setVisibleDepartmentDrawer(false)}
            departmentId={selectedDepartment}
          />
        )}
        {visibleDocumentDrawer && (
          <DocumentDrawer
            visible={visibleDocumentDrawer}
            onClose={onCloseDocumentDrawer}
            documentId={selectedDocument}
          />
        )}
        {visibleEdit && (
          <AssignmentEditDrawer
            visible={visibleEdit}
            onClose={onCloseEdit}
            assignment={assignment}
            sendFiles={sendFiles}
            getAssignment={getAssignment}
            documentData={documentData}
          />
        )}
        <div
          className="forPrint mt40"
          style={{ padding: 24 }}
          ref={componentRef}
        >
          <div className="mb40">
            <h2>
              Задача № {assignment?.Id} —{' '}
              {selectAssignmentType(assignment?.Type)}
            </h2>
          </div>
          <h3>Информация о документе:</h3>
          <AssignmentGeneralInfo documentData={documentData} />
          <h3 className="mt35">Информация о задаче:</h3>
          <DFormItemTwo className="item" label="Состояние" name="State">
            <span>{selectType(assignmentState, assignment?.State)}</span>
          </DFormItemTwo>
          <DFormItemTwo className="item" label="Тема задачи" name="Type">
            <span>{assignment?.Subject}</span>
          </DFormItemTwo>
          <DFormItemTwo className="item" label="Автор" name="Responsible">
            {assignment ? (
              <span
                className="subject"
                onClick={() => {
                  setVisibleOfficerDrawer(true);
                  setClickableOfficer(assignment?.AuthorId);
                }}
              >
                {assignment?.AuthorLastName} {assignment?.AuthorFirstName}{' '}
                {assignment?.AuthorMiddleName}
              </span>
            ) : (
              '-'
            )}
          </DFormItemTwo>
          <DFormItemTwo className="item" label="Исполнитель" name="Executor">
            <span
              className="subject"
              onClick={() => {
                if (!assignment?.ExecutionGroupId) {
                  setVisibleOfficerDrawer(true);
                  setClickableOfficer(assignment?.ExecutorId);
                } else {
                  setSelectedDepartment(assignment?.ExecutionGroupId);
                  setVisibleDepartmentDrawer(true);
                }
              }}
            >
              {executorName}
            </span>
            {assignment?.ExecutorSubstitutionId ? (
              <span
                className="subject"
                onClick={() => {
                  setVisibleOfficerDrawer(true);
                  setClickableOfficer(assignment?.ExecutorSubstitutionId);
                }}
              >
                (
                {getFullName({
                  lastName: assignment?.ExecutorSubstitutionLastName,
                  firstName: assignment?.ExecutorSubstitutionFirstName,
                  middleName: assignment?.ExecutorSubstitutionMiddleName,
                })}
                )
              </span>
            ) : null}
          </DFormItemTwo>
          <DFormItemTwo className="item" label="Документ" name="Document">
            {selectType(docType, documentData?.Type)}{' '}
            {documentData?.Id ? (
              <>
                <Link to={selectPathToDocument()}>{documentData?.Subject}</Link>{' '}
                {documentData?.CorrespondentId ? (
                  <>
                    ОТ КОГО{' '}
                    <span
                      className="subject"
                      onClick={() => {
                        setVisibleOfficerDrawer(true);
                        setClickableOfficer(documentData?.CorrespondentId);
                      }}
                    >
                      {documentData?.CorrespondentLastName}{' '}
                      {documentData?.CorrespondentFirstName}{' '}
                      {documentData?.CorrespondentMiddleName}
                    </span>
                  </>
                ) : null}
              </>
            ) : (
              '-'
            )}
          </DFormItemTwo>
          <DFormItemTwo className="item" label="Текст задачи" name="Subject">
            <span>{assignment?.Content ?? '-'}</span>
          </DFormItemTwo>
          <DFormItemTwo
            className="item"
            label="Срок исполнения"
            name="ExecutorTime"
          >
            <span>
              {assignment?.ExecutionTime
                ? format(new Date(assignment?.ExecutionTime), 'dd.MM.yyyy H:mm')
                : '-'}
            </span>
          </DFormItemTwo>
          <DFormItemTwo
            className="item"
            label="Исполнено"
            name="ExecutionFactTime"
          >
            <span>
              {assignment?.ExecutionFactTime
                ? format(new Date(assignment?.ExecutionFactTime), 'dd.MM.yyyy')
                : '-'}
            </span>
          </DFormItemTwo>
          <DFormItemTwo className="item" label="Резолюция" name="Controller">
            <span>{assignment?.Resolution ?? '-'}</span>
          </DFormItemTwo>
          {!showLinkDocs && (
            <DFormItemTwo className="item" label="Связанные документы">
              {linkList?.map(item => (
                <li
                  onClick={() => {
                    setSelectedDocument(item.LinkedDocumentId);
                    setVisibleDocumentDrawer(true);
                  }}
                  className="ui-li"
                >
                  —{' '}
                  <span>
                    Документ № {item.LinkedDocumentId} от{' '}
                    {format(new Date(item.CreateDate), 'dd.MM.yyyy')}
                  </span>
                  <span></span>
                </li>
              ))}
            </DFormItemTwo>
          )}
          <DFormItemTwo label="Файлы" name="Files">
            {assignment?.Files.length
              ? assignment?.Files.map(item => (
                  <li onClick={() => onDownloadFile(item)} className="ui-li">
                    — <span>{item.Name}</span>
                    <span></span>
                  </li>
                ))
              : 'нет файлов'}
          </DFormItemTwo>
          <h3 className="mt35">Информация о связанных задачах:</h3>
          <AssignmentTable assignments={childrenList} />
        </div>

        <Tabs
          className={
            assignment?.Type === 'ToApplyingStamp' ? 'tabPaneAssignment' : ''
          }
          defaultActiveKey={disableExecuteTab ? '2' : '1'}
        >
          <TabPane disabled={disableExecuteTab} tab="Исполнение" key="1">
            {renderForm()}
          </TabPane>
          <TabPane tab="Информация по задаче" key="2">
            <Form
              name="basic"
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 16 }}
              autoComplete="off"
              className={`drawer-form read ${
                assignment?.Type === 'ToApplyingStamp' ? 'page3' : ''
              }`}
            >
              <DFormItemTwo className="item" label="Состояние" name="State">
                <span>{selectType(assignmentState, assignment?.State)}</span>
              </DFormItemTwo>
              <DFormItemTwo className="item" label="Тема задачи" name="Type">
                <span>{assignment?.Subject}</span>
              </DFormItemTwo>
              <DFormItemTwo className="item" label="Автор" name="Responsible">
                {assignment ? (
                  <span
                    className="subject"
                    onClick={() => {
                      setVisibleOfficerDrawer(true);
                      setClickableOfficer(assignment?.AuthorId);
                    }}
                  >
                    {assignment?.AuthorLastName} {assignment?.AuthorFirstName}{' '}
                    {assignment?.AuthorMiddleName}
                  </span>
                ) : (
                  '-'
                )}
              </DFormItemTwo>
              <DFormItemTwo
                className="item"
                label="Исполнитель"
                name="Executor"
              >
                <span
                  className="subject"
                  onClick={() => {
                    if (!assignment?.ExecutionGroupId) {
                      setVisibleOfficerDrawer(true);
                      setClickableOfficer(assignment?.ExecutorId);
                    } else {
                      setSelectedDepartment(assignment?.ExecutionGroupId);
                      setVisibleDepartmentDrawer(true);
                    }
                  }}
                >
                  {executorName}
                </span>
                {assignment?.ExecutorSubstitutionId ? (
                  <span
                    className="subject"
                    onClick={() => {
                      setVisibleOfficerDrawer(true);
                      setClickableOfficer(assignment?.ExecutorSubstitutionId);
                    }}
                  >
                    (
                    {getFullName({
                      lastName: assignment?.ExecutorSubstitutionLastName,
                      firstName: assignment?.ExecutorSubstitutionFirstName,
                      middleName: assignment?.ExecutorSubstitutionMiddleName,
                    })}
                    )
                  </span>
                ) : null}
              </DFormItemTwo>
              <DFormItemTwo className="item" label="Документ" name="Document">
                {selectType(docType, documentData?.Type)}{' '}
                {documentData?.Id ? (
                  <>
                    <Link to={selectPathToDocument()}>
                      {documentData?.Subject}
                    </Link>{' '}
                    {documentData?.CorrespondentId ? (
                      <>
                        ОТ КОГО{' '}
                        <span
                          className="subject"
                          onClick={() => {
                            setVisibleOfficerDrawer(true);
                            setClickableOfficer(documentData?.CorrespondentId);
                          }}
                        >
                          {documentData?.CorrespondentLastName}{' '}
                          {documentData?.CorrespondentFirstName}{' '}
                          {documentData?.CorrespondentMiddleName}
                        </span>
                      </>
                    ) : null}
                  </>
                ) : (
                  '-'
                )}
              </DFormItemTwo>
              <DFormItemTwo
                className="item"
                label="Текст задачи"
                name="Subject"
              >
                <span>{assignment?.Content ?? '-'}</span>
              </DFormItemTwo>
              <DFormItemTwo
                className="item"
                label="Срок исполнения"
                name="ExecutorTime"
              >
                <span>
                  {assignment?.ExecutionTime
                    ? format(
                        new Date(assignment?.ExecutionTime),
                        'dd.MM.yyyy H:mm',
                      )
                    : '-'}
                </span>
              </DFormItemTwo>
              <DFormItemTwo
                className="item"
                label="Исполнено"
                name="ExecutionFactTime"
              >
                <span>
                  {assignment?.ExecutionFactTime
                    ? format(
                        new Date(assignment?.ExecutionFactTime),
                        'dd.MM.yyyy',
                      )
                    : '-'}
                </span>
              </DFormItemTwo>
              <DFormItemTwo
                className="item"
                label="Резолюция"
                name="Controller"
              >
                <span>{assignment?.Resolution ?? '-'}</span>
              </DFormItemTwo>
              {!showLinkDocs && (
                <DFormItemTwo className="item" label="Связанные документы">
                  {linkList?.map(item => (
                    <li
                      onClick={() => {
                        setSelectedDocument(item.LinkedDocumentId);
                        setVisibleDocumentDrawer(true);
                      }}
                      className="ui-li"
                    >
                      —{' '}
                      <span>
                        Документ № {item.LinkedDocumentId} от{' '}
                        {format(new Date(item.CreateDate), 'dd.MM.yyyy')}
                      </span>
                      <span></span>
                    </li>
                  ))}
                </DFormItemTwo>
              )}
              <DFormItemTwo label="Файлы" name="Files">
                {assignment?.Files.length
                  ? assignment?.Files.map(item => (
                      <li
                        onClick={() => onDownloadFile(item)}
                        className="ui-li"
                      >
                        — <span>{item.Name}</span>
                        <span></span>
                      </li>
                    ))
                  : 'нет файлов'}
              </DFormItemTwo>
              {assignment?.State !== 'Completed' &&
              assignment?.State !== 'Rejected' &&
              // !phases.length &&
              IsCanEdit ? (
                <DFormItemTwo>
                  <div style={{ display: 'flex' }}>
                    <DButton
                      defaultPrimary
                      small
                      className="mr15"
                      onClick={() => setVisibleEdit(true)}
                    >
                      Редактировать
                    </DButton>
                    <Popover
                      visible={showPopover}
                      content={
                        <PopoverContent
                          onDelete={() => onDeleteAssignment(docId)}
                          closePopover={closePopover}
                        />
                      }
                    >
                      <DButton
                        className="mr15"
                        defaultDanger
                        small
                        onClick={openPopover}
                      >
                        Удалить
                      </DButton>
                    </Popover>
                    {assignment?.State === 'Draft' && (
                      <DButton
                        defaultPrimary
                        small
                        className="mr15"
                        onClick={onSendAssignment}
                      >
                        Выдать задачу
                      </DButton>
                    )}
                  </div>
                </DFormItemTwo>
              ) : null}
              <Tabs
                defaultActiveKey={TabKeys.CHILDREN}
                activeKey={selectedTab}
                onTabClick={key => setSelectedTab(key)}
              >
                <TabPane tab="Дополнительные задачи" key={TabKeys.CHILDREN}>
                  <AssignmentTable assignments={childrenList} />
                </TabPane>
                <TabPane tab="История изменения" key={TabKeys.HISTORY}>
                  <Table
                    dataSource={assignmentHistory}
                    columns={historyColumns}
                  />
                </TabPane>
              </Tabs>
            </Form>

            <Icon
              icon={printSvg}
              alt="printSvg"
              onClick={handlePrint}
              className="footer-navigation mr15 mt15"
              children="Печать"
            />
          </TabPane>
        </Tabs>
        <OfficerDrawer
          visibleDrawer={visibleOfficerDrawer}
          onCloseDrawer={onCloseVisibleOfficerDrawer}
          officerId={clickableOfficer}
        />
      </section>
    );

  return {
    assignmentInfoForm,
  };
};
