import { notification, Select } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import { TabKeys, TabNames } from 'pages/CreateDocument/constants';
import { TDocumentLink } from 'pages/CreateDocument/types';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  errorThrow,
  formatFiles,
  successNotification,
  formatFilesToUpdateState,
  TError,
  getFilesWithBarcode,
} from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { CurrentDocument, DocumentTypes } from 'types/document';
import {
  DocumentEntities,
  DocumentInitialValues,
  DocumentVisibleEntities,
  DocumentKind,
} from './types';
import { useCallback } from 'react';
import { updateDocumentKindId } from 'store/slice/table';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { SEND_TO_ROUTE_PAGE } from 'shared/constants/helpCodes';
import { getNameByDocumentType } from 'pages/CreateDocument/utils';
import { AxiosResponse } from 'axios';
import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import login_error from 'shared/assets/images/login-error.png';
import {
  updateASampleDocument,
  updateDocumentInfo,
  updateInfoByLinkishDocument,
} from 'store/slice/document';
import {
  reset,
  updateDisabledButton,
  updateOfficerRelations,
} from 'store/slice/creatingDocument';
import { FileListParams } from 'shared/components/Files/types';
import { TSampleDocument } from 'shared/components/CustomDrawers/ASampleDocumentDrawer';

const { Option } = Select;

export const useCreateDocument = ({
  onClose,
}: {
  onClose?: (bool: boolean) => void;
}) => {
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [documentLinks, setDocumentLinks] = useState<TDocumentLink[]>([]);
  const [visibleOfficerDrawer, setVisibleOfficerDrawer] = useState<boolean>(
    false,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [documentKinds, setDocumentKinds] = useState<DocumentKind[]>([]);
  const [formValues, setFormValues] = useState<CurrentDocument>();
  const [selectedUser, setSelectedUser] = useState<number>();
  const [showRouteComponent, setShowRouteComponent] = useState<boolean>(false);
  const [isContinue, setIsContinue] = useState<boolean>(false);
  const [currentFiles, setCurrentFiles] = useState<FileListParams[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>(TabKeys.CREATE);
  const [isCheckedRegNumber, setIsCheckedRegNumber] = useState(false);
  const { documentInfo, linkishId, sampleSettings } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { user, DocumentKind } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const { officerRelations, disabledButton } = useSelector(
    (state: ReduxState) => state.creatingDocumentReducer,
  );
  const location = useLocation<{ docType: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const isCreatingDocument =
    (DocumentKind?.Id && !documentInfo) || sampleSettings;
  const incomingSendingContext = documentInfo?.SendingContexts[0];
  const DOCUMENT_TYPE = sampleSettings
    ? sampleSettings.DocumentType
    : documentInfo?.Type ?? location.state?.docType;
  const nextButtonText =
    documentInfo && !sampleSettings ? 'Сохранить' : 'Продолжить';

  const FIRST_TAB_NAME =
    documentInfo && !sampleSettings ? TabNames.EDIT : TabNames.CREATE;

  const displayedDocumentType = getNameByDocumentType(
    DOCUMENT_TYPE as DocumentTypes,
  );

  const getInitialRegDateValue = () => {
    if (documentInfo?.SendingContexts[0]?.RegDate) {
      return moment(documentInfo?.SendingContexts[0]?.RegDate);
    }

    if (DOCUMENT_TYPE === 'Incoming' && !documentInfo) {
      return moment();
    }

    return null;
  };

  const getDocumentKindToForm = () =>
    !sampleSettings ? documentInfo?.DocumentKindId : DocumentKind?.Id;

  const initialValueToDocumentInfo = {
    DocumentKind: getDocumentKindToForm() || undefined,
    Subject: documentInfo?.Subject || sampleSettings?.Subject || '',
    Summary: documentInfo?.Summary ?? sampleSettings?.Summary,
    delivery: incomingSendingContext?.SendTypeId ?? sampleSettings?.SendType,
    Correspondent:
      incomingSendingContext?.CorrespondentId ?? sampleSettings?.Correspondent,
    Addressee: incomingSendingContext?.AddresseeId ?? sampleSettings?.Addressee,
    CreateDate: getInitialRegDateValue(),
    Sender: incomingSendingContext?.SenderId,
    InRegNumber: incomingSendingContext?.RegNumber,
    DocumentKindId: getDocumentKindToForm() || undefined,
    DocumentStreamId: documentInfo?.DocumentStreamId,
  };

  const initialValueToSampleDocument = {
    Subject: sampleSettings?.Subject || '',
    Summary: sampleSettings?.Summary,
    Correspondent: sampleSettings?.Correspondent,
    Addressee: sampleSettings?.Addressee,
    delivery: sampleSettings?.SendType,
  };

  const initialValues: DocumentInitialValues =
    documentInfo !== null && !sampleSettings
      ? initialValueToDocumentInfo
      : initialValueToSampleDocument;

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      if (sampleSettings && !sampleSettings?.isCreated) {
        onCreateSampleDocument(values);
      } else {
        if (!documentInfo && !formValues) {
          onFinish(values);
        } else {
          onEdit(values);
        }
      }
    },
  });

  const getPageHeaderCode = () => {
    return selectedTab === TabKeys.CREATE
      ? `CREATE_${DOCUMENT_TYPE?.toUpperCase()}_DOCUMENT_PAGE`
      : SEND_TO_ROUTE_PAGE;
  };

  const getPageHeader = () => {
    if (selectedTab === TabKeys.CREATE) {
      return FIRST_TAB_NAME;
    }
    if (selectedTab === TabKeys.REGISTRATION) {
      return TabNames.REGISTRATION;
    }
    if (selectedTab === TabKeys.SEND_TO_ROUTE) {
      return TabNames.SEND_TO_ROUTE;
    }
  };

  const changeEntitiesVisible = (
    visible: boolean,
    entities: DocumentEntities,
  ) => {
    switch (entities) {
      case DocumentVisibleEntities.Catalog: {
        setVisibleCatalog(visible);
        break;
      }
      case DocumentVisibleEntities.Officer: {
        setVisibleOfficerDrawer(visible);
        break;
      }
      case DocumentVisibleEntities.RouteComponent: {
        setShowRouteComponent(visible);
        break;
      }
      case DocumentVisibleEntities.Error: {
        setShowError(visible);
        break;
      }
      case DocumentVisibleEntities.ContinueButton: {
        setIsContinue(visible);
        break;
      }
    }
  };

  const getNotification = (description: string) => {
    setShowError(true);
    notification['error']({
      message: 'Произошла ошибка',
      description: description,
      className: 'custom-notification__warning',
      icon: <img src={login_error} alt="error" />,
    });
  };

  const onClickCloseButton = () => {
    if (sampleSettings) {
      dispatch(updateOfficerRelations([]));
    }
    if (!documentInfo) {
      history.goBack();
    } else {
      onClose?.(true);
    }
  };

  const onClickNextButton = () => {
    if (documentInfo && !sampleSettings) {
      changeEntitiesVisible(false, DocumentVisibleEntities.ContinueButton);
    } else {
      changeEntitiesVisible(true, DocumentVisibleEntities.ContinueButton);
    }
  };

  const isRegistrationTabDisabled =
    !formValues?.DocumentKindId && !documentInfo?.DocumentKindId;

  const isProcessTabDisabled =
    documentInfo?.DocumentKindId && documentInfo?.AuthorId && !sampleSettings
      ? showRouteComponent
      : !showRouteComponent;

  const incomingDisableButton =
    !!(DOCUMENT_TYPE === 'Incoming') &&
    !!formik.values.InRegNumber &&
    !isCheckedRegNumber;

  const updateFilesAndLinks = (res: AxiosResponse, isEdit?: boolean) => {
    const documentType = documentInfo
      ? `Documents${documentInfo?.Type}`
      : `Documents${formValues?.Type}`;
    const documentId = documentInfo?.Id ?? formValues?.Id;
    dispatch(updateDisabledButton(true));

    const concatAllRequests = () => {
      const linkRequests = documentLinks.length
        ? documentLinks
            .filter(item => !item.LinkedDocumentId)
            .map(item =>
              $api.post('DocumentLinks', {
                DocumentId: res.data.Id,
                LinkedDocumentId: item.Id,
              }),
            )
        : [];
      return linkRequests.concat(
        getFilesWithBarcode(
          currentFiles,
          (res.data.Id ?? documentInfo?.Id) as number,
          'Document',
        ),
      );
    };

    Promise.all(concatAllRequests())
      .then(() => {
        $api
          .get(`Documents/${res.data.Id}/full`)
          .then(rec => {
            setCurrentFiles(formatFilesToUpdateState(rec.data.Files));
            setDocumentLinks(rec.data.DocumentLinks);
          })
          .catch(err => console.log(err));
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => {
        dispatch(updateDisabledButton(false));

        if (!formValues && isEdit) {
          getDocumentInfo({ documentType, documentId, dispatch });
        }
        if (isContinue) return;

        onClose?.(false);

        if (isCreatingDocument) {
          history.push('/active-documents/page=1');
          dispatch(updateASampleDocument());
          dispatch(updateDocumentInfo(null));
          dispatch(reset());
        }
      });
  };

  const formatDocumentReceivers = (correspondentId?: number) => {
    const receiverList = officerRelations.map(item => {
      return {
        OrganisationId: item?.OrganisationId,
        AddresseeId: item?.AddresseeId,
        SendTypeId: item?.SendTypeId ?? 0,
        SenderId: user?.CurrentContact?.OrganisationId,
        CorrespondentId: correspondentId,
      };
    });

    return receiverList;
  };

  const onCreateSampleDocument = (values: DocumentInitialValues) => {
    const outcomingReceivers = formatDocumentReceivers(values.Correspondent);
    const incomingReceivers = formatIncomingReceivers(values);
    setIsLoading(true);

    $api
      .post(`documents/${documentInfo?.Id ?? linkishId}/copy`, {
        DocumentKindId: sampleSettings?.DocumentKindId,
        Type: DOCUMENT_TYPE,
        Subject: values.Subject,
        Summary: values.Summary,
        FileIds: currentFiles
          ?.map(item => item.file.FileId)
          .filter(item => item),
        FileGuidIds: formatFiles(currentFiles),
        // WithLink: !!linkishId,
        OutcomingDocuments: outcomingReceivers.length
          ? outcomingReceivers
          : null,
        IncomingDocuments: incomingReceivers,
      })
      .then(res => {
        successNotification('Документ успешно создан');
        updateFilesAndLinks(res);
        dispatch(updateInfoByLinkishDocument(null));

        if (!isContinue) {
          dispatch(updateASampleDocument());
          dispatch(updateDocumentInfo(null));
          dispatch(reset());
          history.push('/active-documents/page=1');
          return;
        }

        if (DOCUMENT_TYPE === 'Incoming') setSelectedTab(TabKeys.REGISTRATION);
        else {
          setSelectedTab(TabKeys.SEND_TO_ROUTE);
          setShowRouteComponent(true);
        }
        setFormValues(res.data);
        dispatch(
          updateASampleDocument({
            ...sampleSettings,
            isCreated: true,
          } as TSampleDocument),
        );
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  };

  const formatIncomingReceivers = (values: DocumentInitialValues) => {
    const {
      delivery,
      Addressee,
      Correspondent,
      Sender,
      InRegNumber,
      CreateDate,
    } = values;

    if (Sender && delivery)
      return [
        {
          SenderId: Sender,
          AddresseeId: Addressee,
          SendTypeId: delivery,
          CorrespondentId: Correspondent,
          ReceiverId: Addressee,
          OrganisationId: user?.CurrentContact?.OrganisationId,
          RegDate: CreateDate,
          RegNumber: InRegNumber,
        },
      ];
  };

  const onEdit = (values: DocumentInitialValues) => {
    const currentDate = moment();
    const outcomingReceivers = formatDocumentReceivers(values.Correspondent);
    const incomingReceivers = formatIncomingReceivers(values);

    if (!values.Subject) {
      getNotification('Необходимо заполнить тему');
    } else if (
      documentInfo &&
      !values.DocumentKindId &&
      !formValues?.DocumentKindId
    ) {
      getNotification('Вид документа не выбран');
    } else {
      setIsLoading(true);

      $api
        .patch(`Documents/${formValues?.Id ?? documentInfo?.Id}/UpdateAuthor`, {
          AuthorId: user?.CurrentContact?.Id,
        })
        .then(() => {
          $api
            .put(`/Documents/${formValues?.Id ?? documentInfo?.Id}`, {
              Subject: values.Subject,
              RegDate: !sampleSettings
                ? documentInfo?.RegDate
                : formValues?.RegDate,
              ExecutionTime: currentDate,
              RegNumber: !sampleSettings
                ? documentInfo?.RegNumber
                : formValues?.RegNumber,
              Type: DOCUMENT_TYPE,
              CorrespondentId:
                values?.Correspondent ?? documentInfo?.CorrespondentId,
              SenderId: values.Sender,
              AddresseeId: values.Addressee ?? documentInfo?.AddresseeId,
              SendType: values.delivery,
              Summary: values.Summary,
              OrganisationId: DocumentKind?.OrganisationId
                ? DocumentKind?.OrganisationId
                : documentInfo?.OrganisationId,
              DocumentStreamId: sampleSettings
                ? sampleSettings?.DocumentStreamId
                : DocumentKind
                ? DocumentKind?.DocumentStreamId
                : values?.DocumentStreamId
                ? values?.DocumentStreamId
                : documentInfo?.DocumentStreamId,
              DocumentKindId:
                values.DocumentKindId ??
                formValues?.DocumentKindId ??
                documentInfo?.DocumentKindId,
              ControlSign: 'InControl',
              FilesId: formatFiles(currentFiles),
              FileIds: formatFiles(currentFiles),
              OrganisationToRegister: sampleSettings
                ? DocumentKind?.OrganisationId
                : documentInfo?.OrganisationToRegister,
              IncomingDocuments: incomingReceivers,
              OutcomingDocuments: outcomingReceivers.length
                ? outcomingReceivers
                : null,
              InRegNumber: values.InRegNumber,
              InRegDate: documentInfo?.InRegDate ?? values.CreateDate,
            })
            .then(res => {
              successNotification('Документ успешно изменён');
              updateFilesAndLinks(res, true);
              dispatch(updateInfoByLinkishDocument(null));
              if (!documentInfo) setFormValues(res.data);
              if (isContinue) {
                if (res.data.Type === 'Incoming')
                  setSelectedTab(TabKeys.REGISTRATION);
                else {
                  setSelectedTab(TabKeys.SEND_TO_ROUTE);
                  setShowRouteComponent(true);
                }
              }
            })
            .catch(({ response }) => errorThrow(response))
            .finally(() => setIsLoading(false));
        })
        .catch(err => {
          console.error(err.message);
          setIsLoading(false);
        });
    }
  };

  const onAddLink = useCallback(
    (itemId: number) => {
      const isExist = documentLinks
        .map(item => item.Id)
        .filter(Id => Id === itemId);
      if (!isExist.length)
        $api
          .get(`documents/${itemId}/full`)
          .then(res => {
            setDocumentLinks(prev => [...prev, res.data]);
          })
          .catch(({ response }) => errorThrow(response));
    },
    [documentLinks],
  );

  const onFinish = (values: DocumentInitialValues) => {
    const currentDate = moment();
    const outcomingReceivers = formatDocumentReceivers(values.Correspondent);
    const incomingReceivers = formatIncomingReceivers(values);
    const requestUrl =
      DocumentKind?.OrganisationId !== user?.CurrentContact?.OrganisationId
        ? '/documents/byorganisation'
        : '/Documents';
    if (!values.Subject) {
      getNotification('Необходимо заполнить тему');
    } else {
      setIsLoading(true);
      $api
        .post(requestUrl, {
          OrganisationId: DocumentKind?.OrganisationId,
          Type: DOCUMENT_TYPE,
          AuthorId: user?.CurrentContact?.Id,
          Subject: values.Subject,
          ExecutionTime: currentDate,
          CorrespondentId: values.Correspondent ?? null,
          SenderId: values.Sender,
          AddresseeId: values.Addressee ?? null,
          SendType: values.delivery,
          Summary: values.Summary,
          DocumentKindId: sampleSettings
            ? sampleSettings?.DocumentKindId
            : DocumentKind?.Id,
          DocumentStreamId: !sampleSettings
            ? DocumentKind?.DocumentStreamId
            : sampleSettings?.DocumentStreamId,
          ControlSign: 'InControl',
          FilesId: formatFiles(currentFiles),
          FileIds: formatFiles(currentFiles),
          OrganisationToRegister: user?.CurrentContact?.OrganisationId,
          OutcomingDocuments: outcomingReceivers.length
            ? outcomingReceivers
            : null,
          IncomingDocuments: incomingReceivers,
          InRegNumber: values.InRegNumber,
          InRegDate: values.CreateDate ?? moment(),
        })
        .then(res => {
          successNotification('Документ успешно создан');
          updateFilesAndLinks(res);
          dispatch(updateInfoByLinkishDocument(null));

          if (!isContinue) {
            dispatch(updateASampleDocument());
            dispatch(updateDocumentInfo(null));
            dispatch(reset());
            history.push('/active-documents/page=1');
            return;
          }

          if (DOCUMENT_TYPE === 'Incoming')
            setSelectedTab(TabKeys.REGISTRATION);
          else {
            setSelectedTab(TabKeys.SEND_TO_ROUTE);
            setShowRouteComponent(true);
          }
          setFormValues(res.data);
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    }
  };

  const onRemoveLinks = (t: TDocumentLink) => {
    setDocumentLinks(prev => prev.filter(item => item.Id !== t.Id));

    if (documentInfo) {
      const isExist = documentInfo?.DocumentLinks.find(
        item => item.LinkedDocumentId === t.LinkedDocumentId,
      );

      if (isExist) {
        $api
          .delete(`DocumentLinks/${t.Id}`)
          .catch(({ response }) => errorThrow(response));
      }
    }
  };

  const getDocumentKind = useCallback(
    updateDocumentKind => {
      if (documentInfo?.DocumentKindId && !sampleSettings) {
        $api
          .get(`DocumentStreams/filter`, {
            params: {
              DocumentType: documentInfo?.Type,
              DocumentKindId: !sampleSettings
                ? documentInfo?.DocumentKindId
                : DocumentKind?.Id,
            },
          })
          .then(({ data: { Data } }) => Data[0] && updateDocumentKind(Data[0]))
          .catch(({ response }) => errorThrow(response));
      }
    },
    [
      DocumentKind?.Id,
      documentInfo?.DocumentKindId,
      documentInfo?.Type,
      sampleSettings,
    ],
  );

  const onCloseDrawer = () => {
    if (sampleSettings) {
      dispatch(updateOfficerRelations([]));
    }
    dispatch(updateDocumentKindId(null));
    onClose?.(true);
    dispatch(updateASampleDocument());
    dispatch(updateInfoByLinkishDocument(null));
  };

  const fetchDocumentKinds = useCallback(() => {
    const updateDocumentKindSelector = (data: DocumentKind) => {
      dispatch(
        updateDocumentKindId({
          Name: data.Name,
          Id: data.Id,
          FlowId: data.FlowId,
          FlowName: data.FlowName,
          DocumentStreamId: data.Id,
        }),
      );
    };
    if (
      user?.CurrentContact?.OrganisationId ===
      (documentInfo?.OrganisationId ?? DocumentKind?.OrganisationId)
    ) {
      $api
        .get(`documentStreams/byorganisation/filter`, {
          params: {
            OrganisationId: user?.CurrentContact?.OrganisationId,
            DocumentType: !sampleSettings
              ? documentInfo?.Type
              : sampleSettings.DocumentType,
            Limit: 1000,
            ForSelectedOrg: false,
          },
        })
        .then(res => {
          setDocumentKinds(res.data.Data);
          getDocumentKind(updateDocumentKindSelector);
        })
        .catch(({ response }) => errorThrow(response));
    } else {
      $api
        .get(`documentstreams/filter`, {
          params: {
            ForSelectedOrg: true,
            OrganisationId:
              documentInfo?.OrganisationId ?? DocumentKind?.OrganisationId,
            DocumentType: !sampleSettings
              ? documentInfo?.Type
              : sampleSettings.DocumentType,
            Limit: 1000,
          },
        })
        .then(res => {
          setDocumentKinds(res.data.Data);
          getDocumentKind(updateDocumentKindSelector);
        })
        .catch(({ response }) => errorThrow(response));
    }
  }, [
    DocumentKind?.OrganisationId,
    dispatch,
    documentInfo?.OrganisationId,
    documentInfo?.Type,
    getDocumentKind,
    sampleSettings,
    user?.CurrentContact?.OrganisationId,
  ]);

  const getDocumentReceivers = useCallback(() => {
    const newLinks = documentInfo?.SendingContexts?.map(item => ({
      AddresseeFirstName: item.AddresseeFirstName,
      AddresseeId: item.AddresseeId,
      AddresseeLastName: item.AddresseeLastName,
      AddresseeMiddleName: item.AddresseeMiddleName,
      OrganisationId: item.OrganisationId,
      OrganisationName: item.OrganisationName,
      SendTypeName: item.SendTypeName,
      SendTypeId: item.SendTypeId,
    }));

    if (newLinks?.length) {
      dispatch(updateOfficerRelations(newLinks));
    }
  }, [dispatch, documentInfo?.SendingContexts]);

  useEffect(() => {
    if (linkishId) {
      onAddLink(linkishId);
    }
  }, [linkishId, onAddLink]);

  useEffect(() => {
    if (location.pathname.split('/').includes('create')) {
      dispatch(updateDocumentInfo(null));
    }
  }, []);

  const onChangeSubjectValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === ' ') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    formik.handleChange(e);
    if (showRouteComponent) {
      changeEntitiesVisible(false, DocumentVisibleEntities.RouteComponent);
    }
    if (e.target.value.length > 2) {
      changeEntitiesVisible(false, DocumentVisibleEntities.Error);
    }
  };

  useEffect(() => {
    if (
      (documentInfo !== null && !sampleSettings) ||
      sampleSettings?.hasOwnProperty('Addressee')
    )
      getDocumentReceivers();
  }, [getDocumentReceivers]);

  const updateFiles = useCallback(() => {
    documentInfo?.Files &&
      setCurrentFiles(formatFilesToUpdateState(documentInfo?.Files));
  }, [documentInfo?.Files]);

  const updateFilesByDocument = sampleSettings
    ? !!sampleSettings?.Files
    : !!documentInfo;

  useEffect(() => {
    if (updateFilesByDocument) {
      updateFiles();
    }
    if ((documentInfo || sampleSettings) && selectedTab === TabKeys.CREATE) {
      fetchDocumentKinds();
    }
    if (documentInfo && !sampleSettings) {
      setDocumentLinks(documentInfo?.DocumentLinks);
    }
  }, [documentInfo, sampleSettings, updateFilesByDocument]);

  useEffect(() => {
    if (!documentInfo && !sampleSettings && !DocumentKind?.Id) history.goBack();
  }, [DocumentKind?.Id, documentInfo, history, sampleSettings]);

  const getDocumentKindItem = () => {
    if (isCreatingDocument) {
      return (
        <DFormItemTwo label="Вид документа">
          <b>{DocumentKind?.Name}</b>
        </DFormItemTwo>
      );
    } else {
      return (
        <DFormItemTwo required label="Вид документа" name="DocumentKindId">
          <Select
            className="ui-select"
            allowClear
            showSearch
            optionFilterProp="children"
            onChange={value => {
              formik.setFieldValue('DocumentKindId', value);
              formik.setFieldValue(
                'DocumentStreamId',
                documentKinds.find((item: any) => item.DocumentKindId === value)
                  ?.Id,
              );
              setShowError(false);
            }}
            value={formik.values.DocumentKindId}
          >
            {documentKinds?.map(item => (
              <Option key={item?.DocumentKindId} value={item?.DocumentKindId}>
                {item?.DocumentKindName}
              </Option>
            ))}
          </Select>
        </DFormItemTwo>
      );
    }
  };

  const [organisationName, setOrganisationName] = useState<string>();

  const getOrganisationNameByRequest = useCallback(async (id: number) => {
    try {
      const { data } = await $api.get(`organisations/${id}`);
      return data;
    } catch ({ response }) {
      errorThrow(response as TError);
    }
  }, []);

  const getOrganisationName = useCallback(() => {
    if (DocumentKind?.OrganisationId) {
      setOrganisationName(DocumentKind?.OrganisationName);
      return;
    } else if (documentInfo) {
      getOrganisationNameByRequest(
        documentInfo?.OrganisationId,
      ).then(organisation => setOrganisationName(organisation.Name));
      return;
    }

    setOrganisationName(user?.CurrentContact?.OrganisationName);
    return;
  }, [
    DocumentKind?.OrganisationId,
    DocumentKind?.OrganisationName,
    documentInfo,
    getOrganisationNameByRequest,
    user?.CurrentContact?.OrganisationName,
  ]);

  useEffect(() => {
    getOrganisationName();
  }, [getOrganisationName]);

  return {
    visibleCatalog,
    onAddLink,
    visibleOfficerDrawer,
    DOCUMENT_TYPE,
    selectedUser,
    getPageHeader,
    selectedTab,
    getPageHeaderCode,
    updateSelectedTab: (key: string) => setSelectedTab(key),
    formik,
    displayedDocumentType,
    updateSelectedUser: () => setSelectedUser(user?.CurrentContact?.Id),
    showError,
    showRouteComponent,
    documentLinks,
    changeEntitiesVisible,
    formValues,
    getDocumentKindItem,
    FIRST_TAB_NAME,
    currentFiles,
    setCurrentFiles,
    onRemoveLinks,
    onChangeSubjectValue,
    onClickCloseButton,
    onClickNextButton,
    nextButtonText,
    isProcessTabDisabled,
    isRegistrationTabDisabled,
    onCloseDrawer,
    isLoading,
    disabledButton,
    organisationName,
    isCreatingDocument,
    incomingDisableButton,
    setIsCheckedRegNumber,
    linkishId,
    setFormValues,
  };
};
