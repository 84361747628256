export const getAuthToken = (name: string) => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)',
    ),
  );
  return matches
    ? { 'X-auth-token': decodeURIComponent(matches[1]) }
    : undefined;
};

export const deleteCookie = (name: string) => {
  document.cookie = name + '=; Max-Age=-99999999;';
};
