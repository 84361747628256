import {
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  UploadOutlined,
} from '@ant-design/icons';
import { Checkbox, Drawer, Table, Upload, UploadProps } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { DButton } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DrawerHeader } from '../DrawerHeader';
import { ColumnsFile, Entity, TFileResult, TUploadBookDrawer } from './types';
import { TagsEntitiesDrawer } from './TagsEntitiesDrawer';
import _ from 'lodash-es';
import './UploadBookDrawer.scss';

export const UploadBookDrawer = ({
  visible,
  onClose,
  type,
  getData,
  title,
}: TUploadBookDrawer) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileResult, setFileResult] = useState<TFileResult>();
  const [fileTable, setFileTable] = useState<
    Record<string, string | number>[]
  >();
  const [entities, setEntities] = useState<string[]>([]);
  const [selectionEntities, setSelectionEntities] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [visibleEntitiesDrawer, setVisibleEntitiesDrawer] = useState(false);
  const onCloseEntitiesDrawer = () => setVisibleEntitiesDrawer(false);
  const [currentIndexColumn, setCurrentIndexColumn] = useState<number>(0);

  const props: UploadProps = {
    name: 'FormFile',
    multiple: true,
    customRequest: ({ file }) => {
      const formData = new FormData();
      formData.append('FormFile', file);
      $api
        .post(`system/${type}/file`, formData)
        .then(res => {
          setFileResult(res.data);
          setFileTable(res.data.Data);
          setEntities(Object.keys(res.data.Entity));
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    },
  };

  const getIndex = useCallback(() => {
    let currentIndex = 0;
    let currentLength = 0;
    fileTable?.forEach(
      (item: Record<string, string | number>, index: number) => {
        if (Object.keys(item).length > currentLength) {
          currentLength = Object.keys(item).length;
          currentIndex = index;
        }
      },
    );

    return currentIndex;
  }, [fileTable]);

  const columns: Record<string, ColumnsFile>[] = useMemo(
    () =>
      fileTable
        ? Object.keys(fileTable[getIndex()])?.map((item, index) => ({
            key: item,
            dataIndex: item,
            title: (
              <div className="container-title-column">
                <MinusCircleTwoTone
                  onClick={() => {
                    const currentTitle = selectionEntities[index];
                    const newSelection = [...selectionEntities];
                    newSelection[index] = '';
                    setSelectionEntities(newSelection);
                    setEntities([...entities, currentTitle]);
                  }}
                />
                <span className="title-column">{selectionEntities[index]}</span>
                <PlusCircleTwoTone
                  onClick={() => {
                    setVisibleEntitiesDrawer(true);
                    setCurrentIndexColumn(index);
                  }}
                />
              </div>
            ),
          }))
        : [],
    [entities, fileTable, getIndex, selectionEntities],
  );

  const onSend = () => {
    let result = {};
    selectionEntities.forEach((item, index) => {
      result = {
        ...result,
        [`Column_${index}`]: item,
      };
    });

    $api
      .post(`system/${type}/import`, {
        FileId: fileResult?.FileId,
        Mapping: !_.isEmpty(result) ? result : null,
        HasHeader: isChecked,
      })
      .then(() => {
        onClose();
        getData();
      })
      .catch(({ response }) => errorThrow(response));
  };

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      className="drawer"
      closeIcon={null}
    >
      <TagsEntitiesDrawer
        visible={visibleEntitiesDrawer}
        onClose={onCloseEntitiesDrawer}
        entities={entities}
        setEntities={setEntities}
        selectionEntities={selectionEntities}
        setSelectionEntities={setSelectionEntities}
        currentIndexColumn={currentIndexColumn}
      />
      <div className="page3 mb15">
        <DrawerHeader onClose={onClose} text={`Загрузка шаблона ${title}`} />
        <div className="upload-container">
          <Upload className="mb15" {...props}>
            <DButton small defaultPrimary>
              <UploadOutlined /> Выбрать файл
            </DButton>
          </Upload>
          <Checkbox
            disabled={!fileResult}
            className="mb15"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          >
            Заголовок
          </Checkbox>
          <DButton
            disabled={!fileResult}
            className="mb15"
            onClick={onSend}
            small
            primary
          >
            Загрузить
          </DButton>
        </div>
        <Table
          className="file-table"
          loading={isLoading}
          dataSource={fileTable}
          pagination={false}
          columns={columns}
        />
      </div>
    </Drawer>
  );
};
