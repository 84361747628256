import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { HelpBlock } from '../HelpBlock';

type TDrawerHeader = {
  onClose: (visible: boolean) => void;
  text: string;
  code?: string;
};

export const DrawerHeader = ({ onClose, text, code }: TDrawerHeader) => {
  return (
    <div className="content_head">
      <CloseOutlined
        className="mr10"
        style={{
          cursor: 'pointer',
          fontSize: 36,
          color: '#8a9499',
        }}
        onClick={() => onClose(false)}
      />
      <h1 style={{ marginBottom: 0 }} className="mr15">
        {text}
      </h1>
      {code && <HelpBlock code={code} isDrawer />}
    </div>
  );
};
