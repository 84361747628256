import './index.scss';
import { TDTextarea } from './types';
import {
  convertClasses,
  convertStyles,
} from 'shared/utils/getClassesAndStyles';

/**
 * @placeholder string
 * @onChange func;
 * @value string;
 * @disabled boolean;
 * @width string;
 * @style any;
 * @className string, object of string;
 */
export const DTextarea = ({
  placeholder,
  onChange,
  value,
  disabled,
  width,
  style,
  className,
  required,
  name,
  minLength,
}: TDTextarea) => {
  return (
    <>
      <textarea
        name={name}
        required={required}
        style={convertStyles([width && { width: width }, style])}
        className={convertClasses(
          required && !value ? 'DTextarea-danger' : 'DTextarea',
          className,
          disabled,
        )}
        minLength={minLength}
        disabled={disabled}
        value={value}
        onChange={e => onChange && onChange(e)}
        placeholder={placeholder}
      />
    </>
  );
};
