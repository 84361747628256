import { Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateProcessDrawer } from 'shared/components/CustomDrawers/CreateProcessDrawer';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { Icon } from 'shared/components/IconComponent';
import searchSvg from 'shared/assets/images/search.svg';
import { PROCESSES } from 'shared/constants/helpCodes';

export type TProcess = {
  OrganisationId: number;
  DocumentKindId: number;
  Name: string;
  IsDefault: boolean;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
};

export const Processes = () => {
  const [processList, setProcessList] = useState<TProcess[]>([]);
  const [defaultList, setDefaultList] = useState<TProcess[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const history = useHistory();

  const onClose = () => setVisible(false);

  const onDelete = (id: number) => {
    $api
      .delete(`flows/${id}/byorganization`)
      .then(() => getProcesses())
      .catch(err => console.error(err));
  };

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Вид документа',
      dataIndex: 'DocumentKindId',
      key: 'DocumentKindId',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: TProcess) => (
        <>
          <DButton
            small
            defaultPrimary
            className="mr15"
            onClick={() => {
              history.push(`/admin/process/route/${item.Id}`);
            }}
          >
            Редактировать
          </DButton>
          <DButton small defaultDanger onClick={() => onDelete(item?.Id)}>
            Удалить
          </DButton>
        </>
      ),
    },
  ];

  const onSearch = (value: string) => {
    if (value) {
      const newList = defaultList?.filter(item =>
        item?.Name?.toLowerCase()?.includes(value.toLowerCase()),
      );
      setProcessList(newList);
    } else {
      setProcessList(defaultList);
    }
  };

  const getProcesses = useCallback(() => {
    setIsLoading(true);
    $api
      .get(`flows/byorganization`)
      .then(res => {
        setProcessList(res.data);
        setDefaultList([...res.data]);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => getProcesses(), [getProcesses]);

  return (
    <>
      {visible && (
        <CreateProcessDrawer
          visible={visible}
          onClose={onClose}
          getData={getProcesses}
        />
      )}
      <PageHeader title="Процессы" code={PROCESSES} />
      <div className="flex-space mb15">
        <DButton
          className="mr15"
          onClick={() => {
            setVisible(true);
          }}
          small
          primary
        >
          + Создать
        </DButton>
        <DInput
          type="text"
          style={{ maxWidth: 630, width: '100%' }}
          placeholder="Поиск по наименованию"
          icon={<Icon icon={searchSvg} alt="searchSvg" />}
          onChange={e => onSearch(e.target.value)}
        />
      </div>
      <Table loading={isLoading} dataSource={processList} columns={columns} />
    </>
  );
};
