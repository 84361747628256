import { notification } from 'antd';
import { getFiltersSidebar } from 'api/filtersSidebar/filtersSidebar';
import { useFormik } from 'formik';
import { ContactList, DeliveryParams } from 'pages/CreateDocument/types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import {
  loadingStatus,
  TSelectedFilter,
  updateDataCount,
  updateFilteredDocuments,
  updateVisibleSetting,
} from 'store/slice/table';
import { ReduxState } from 'store/store';
import {
  TContactType,
  TDocumentKindList,
  TInitialValues,
  TOrganisationsList,
  TOrgNomenclature,
  TUserTagList,
} from './types';
import {
  checkIsP,
  fetchUrl,
  formatConfigObject,
  getConfigToUpdate,
  updateContacts,
  updateDatePreset,
  updateSearchBy,
  updateSelectedTags,
} from './utils';
import login_error from 'shared/assets/images/login-error.png';
import {
  GET_CONTACTS,
  GET_CONTROLLES,
  GET_DOCUMENT_KIND,
  GET_NOMENCLATURES,
  GET_ORGANISATIONS,
  GET_SENDTYPES,
  GET_TAGS,
  initialValues,
  initialSelectedFields,
  GET_SENDER_ORGANISATIONS,
} from './constants';
import { convertData } from 'pages/FilteredDocumentsPage/utils';
import _ from 'lodash';
import moment from 'moment';

export const useFilterDrawer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<TSelectedFilter | null>(
    null,
  );
  const [userTags, setUserTags] = useState<TUserTagList[]>();
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [documentKindList, setDocumentKindList] = useState<TDocumentKindList[]>(
    [],
  );
  const [catalogType, setCatalogType] = useState<'contacts' | 'organisations'>(
    'contacts',
  );
  const [sendTypeList, setSendTypeList] = useState<DeliveryParams[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [orgnomencalutreList, setOrgnomencalutreList] = useState<
    TOrgNomenclature[]
  >([]);
  const [contacts, setContacts] = useState<{
    AuthorId: ContactList[];
    ExecutorId: ContactList[];
    ControllerId: ContactList[];
    CorrespondentId: ContactList[];
    AddresseeId: ContactList[];
  }>({
    AuthorId: [],
    ExecutorId: [],
    ControllerId: [],
    CorrespondentId: [],
    AddresseeId: [],
  });
  const [selectedFields, setSelectedFields] = useState<{
    AuthorId: ContactList[];
    ExecutorId: ContactList[];
    ControllerId: ContactList[];
    CorrespondentId: ContactList[];
    AddresseeId: ContactList[];
    OrganisationReceiverId: TOrganisationsList[];
    SenderId: TOrganisationsList[];
  }>(initialSelectedFields);
  const [organisationList, setOrganisationList] = useState<
    TOrganisationsList[]
  >([]);
  const [senderOrganisations, setSenderOrganisations] = useState<
    TOrganisationsList[]
  >([]);
  const [fieldValue, setFieldValue] = useState<TContactType>('AuthorId');
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [filterPresetList, setFilterPresetList] = useState<TSelectedFilter[]>(
    [],
  );
  const { filteredDocuments, selectedFilter } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: values => onFinish(values),
  });

  const changeModalVisible = (visible: boolean) => setVisibleModal(visible);

  const onAddTag = useCallback((id: number) => {
    setSelectedTags(prev => [...prev, id]);
  }, []);

  const onRemoveTag = useCallback((id: number) => {
    setSelectedTags(prev => prev.filter(tagId => tagId !== id));
  }, []);

  const onUpdateSelectedFields = (key: string, arrayOfValues: any) => {
    setSelectedFields((prev: any) => ({ ...prev, [key]: arrayOfValues }));
  };

  const onRemoveSelectedFields = (key: string, arrayOfValues: any) => {
    setSelectedFields((prev: any) => ({ ...prev, [key]: arrayOfValues }));
    formik.setFieldValue(
      key,
      arrayOfValues.map((item: any) => item.Id),
    );
  };

  const getFields = useCallback(
    config => {
      const readyDates = updateDatePreset(
        !_.isEmpty(config)
          ? { ...config }
          : { ...initialValues, registration_date: [undefined, moment()] },
      );

      updateContacts({ ...config }).forEach(([key, value]) => {
        if (value) {
          // @ts-ignore
          value.forEach(itemId => {
            $api.get(`Contacts/${itemId}`).then(({ data }) => {
              if (key === 'ControllerId') {
                setContacts(prev => {
                  onUpdateSelectedFields(
                    key,
                    _.uniqBy(
                      [...prev.ControllerId, data].filter(item =>
                        // @ts-ignore
                        value.includes(item.Id),
                      ),
                      'Id',
                    ),
                  );

                  return {
                    ...prev,
                    ControllerId: [...prev.ControllerId, data],
                  };
                });
              } else {
                setContacts(prev => {
                  onUpdateSelectedFields(
                    key as string,
                    _.uniqBy(
                      // @ts-ignore
                      [...prev[key], data].filter(item =>
                        // @ts-ignore
                        value.includes(item.Id),
                      ),
                      'Id',
                    ),
                  );
                  return {
                    ...prev,
                    [String(key)]: [
                      ...(prev as Record<string, ContactList[]>)[key],
                      data,
                    ],
                  };
                });
              }
            });
          });
        }
      });
      if (config.SenderId) {
        // @ts-ignore
        config.SenderId.forEach(itemId =>
          $api
            .get(`Organisations/${itemId}`)
            .then(({ data }) => {
              setSenderOrganisations(prev => {
                onUpdateSelectedFields(
                  'SenderId',
                  _.uniqBy(
                    [...prev, data].filter(item =>
                      // @ts-ignore
                      config.SenderId.includes(item.Id),
                    ),
                    'Id',
                  ),
                );
                return [...prev, data];
              });
            })
            .catch(({ response }) => errorThrow(response)),
        );
      }
      if (config.OrganisationReceiverId) {
        // @ts-ignore
        config.OrganisationReceiverId.forEach(itemId =>
          $api
            .get(`Organisations/${itemId}`)
            .then(({ data }) =>
              setOrganisationList(prev => {
                onUpdateSelectedFields(
                  'OrganisationReceiverId',
                  _.uniqBy(
                    [...prev, data].filter(item =>
                      // @ts-ignore
                      config.OrganisationReceiverId.includes(item.Id),
                    ),
                    'Id',
                  ),
                );
                return [...prev, data];
              }),
            )
            .catch(({ response }) => errorThrow(response)),
        );
      }
      setSelectedTags(updateSelectedTags(config) ?? []);
      const newInitialValue = Object.assign(
        { ...initialValues, registration_date: [undefined, moment()] },
        config,
        readyDates,
        updateSearchBy(config),
      );
      formik.setValues(checkIsP(newInitialValue));
      setIsLoading(false);
    },
    [formik],
  );

  const getContacts = async (controllerResponse: ContactList[]) => {
    try {
      const { data } = await $api.get(GET_CONTACTS);
      // TODO: ренейм полей под листы
      setContacts({
        AuthorId: data,
        ExecutorId: data,
        ControllerId: controllerResponse,
        CorrespondentId: data,
        AddresseeId: data,
      });
    } catch ({ response }) {
      errorThrow(response);
    }
  };

  const getLists = useCallback(() => {
    setIsLoading(true);

    const promiseArray = [
      GET_DOCUMENT_KIND,
      GET_NOMENCLATURES,
      GET_CONTROLLES,
      GET_TAGS,
      GET_ORGANISATIONS,
      GET_SENDTYPES,
      GET_SENDER_ORGANISATIONS,
    ].map(fetchUrl);

    return Promise.all(promiseArray)
      .then(res => {
        const [
          documentKindResponse,
          nomenclautureResponse,
          controllerResponse,
          tagsResponse,
          organisationResponse,
          sendTypeResponse,
          senderOrganisationResponse,
        ] = res;
        getContacts(controllerResponse);
        setDocumentKindList(documentKindResponse);
        setOrgnomencalutreList(nomenclautureResponse);
        setUserTags(tagsResponse);
        setOrganisationList(organisationResponse.Data);
        setSenderOrganisations(senderOrganisationResponse);
        setSendTypeList(sendTypeResponse);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => {
        getFields(filteredDocuments.config);
      });
  }, [filteredDocuments.config]);

  const onFinish = (values: TInitialValues) => {
    if (!values.DocumentContain && values.searchBy?.length) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо заполнить поле',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    } else if (values.DocumentContain && !values.searchBy) {
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо выбрать параметр поиска (искать по)',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    } else {
      setIsLoading(true);
      dispatch(loadingStatus(true));
      const queryString = selectedTags.length
        ? selectedTags
            ?.map(item => {
              return `TagIds=${item}`;
            })
            .join('&')
        : '';

      $api
        .get(`Documents/Filter?${queryString}&${formatConfigObject(values)}`, {
          params: getConfigToUpdate({
            ...values,
            TagIds: selectedTags.length ? selectedTags : null,
          }),
        })
        .then(res => {
          dispatch(
            updateDataCount({
              totalCount: res.data?.FilteredCount,
              page: 1,
              pageSize: 15,
            }),
          );
          dispatch(
            updateFilteredDocuments({
              data: convertData(res.data.Data),
              config: getConfigToUpdate({
                ...values,
                TagIds: selectedTags.length ? selectedTags : null,
              }),
              filteredCount: res.data.FilteredCount,
            }),
          );
          if (history.location.pathname === '/active-documents/page=1') {
            history.push('/searching/page=1');
            sessionStorage.setItem('uniqItem', '147active-documents');
            sessionStorage.setItem('uniqNumberAtom', '21');
          }
          dispatch(updateVisibleSetting(false));
          setIsLoading(false);
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => {
          setIsLoading(false);
          dispatch(loadingStatus(false));
        });
    }
  };

  const onAddFilterIntoSidebar = () => {
    const selectedFilter = {
      ...currentFilter,
      IsSideBarShow: !currentFilter?.IsSideBarShow,
    };
    $api
      .put(`documentfilters/${currentFilter?.Id}`, selectedFilter)
      .then(() => {
        getFiltersSidebar({ dispatch });
        setCurrentFilter(selectedFilter);
      })
      .catch(({ response }) => errorThrow(response));
  };

  const onLoadFilterPreset = useCallback(
    item => {
      setCurrentFilter(item);
      formik.resetForm();
      const config = JSON.parse(item.FilterData);
      getFields(config);
    },
    [formik, getFields],
  );

  const getFilterPresets = useCallback(() => {
    $api
      .get('DocumentFilters/ByUser')
      .then(res => setFilterPresetList(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const onRemoveFilterPreset = (item: TSelectedFilter) => {
    $api
      .delete(`DocumentFilters/${item.Id}`)
      .then(() => {
        if (currentFilter?.Id === item?.Id) {
          setCurrentFilter(null);
        }
        getFilterPresets();
        getFiltersSidebar({ dispatch });
      })
      .catch(({ response }) => errorThrow(response));
  };

  const updateContactLists = (item: ContactList, type: 'add' | 'remove') => {
    const oldArray = formik.values[fieldValue] ? formik.values[fieldValue] : [];

    if (type === 'remove') {
      const newArrayOfIds = (oldArray as number[]).filter(id => item.Id !== id);
      formik.setFieldValue(fieldValue, newArrayOfIds);
      onUpdateSelectedFields(
        fieldValue,
        (selectedFields[fieldValue] as ContactList[]).filter(
          selectedItem => selectedItem.Id !== item.Id,
        ),
      );

      return;
    }

    const newArrayOfIds = _.uniq([...(oldArray as number[]), item.Id]);
    onUpdateSelectedFields(fieldValue, [...selectedFields[fieldValue], item]);
    formik.setFieldValue(fieldValue, newArrayOfIds);
  };

  const onClearForm = () => {
    formik.resetForm();
    dispatch(
      updateFilteredDocuments({
        data: [],
        config: {},
        filteredCount: 0,
      }),
    );
    setSelectedFields(initialSelectedFields);
    setCurrentFilter(null);
    setShowError(false);
  };

  const checkDisabledButtonSearch = () =>
    !Object.values(formik.values).find(item =>
      Array.isArray(item) ? item?.length : !!item,
    );

  useEffect(() => {
    getLists();
    getFilterPresets();
  }, [getLists]);

  useEffect(() => {
    setCurrentFilter(selectedFilter);
  }, [selectedFilter]);

  return {
    isLoading,
    currentFilter,
    userTags,
    selectedTags,
    visibleCatalog,
    onCloseCatalog: () => setVisibleCatalog(false),
    onOpenCatalog: (type: 'contacts' | 'organisations' = 'contacts') => {
      setCatalogType(type);
      setVisibleCatalog(true);
    },
    formik,
    onAddFilterIntoSidebar,
    showError,
    setShowError,
    orgnomencalutreList,
    contacts,
    documentKindList,
    checkDisabledButtonSearch,
    onLoadFilterPreset,
    changeModalVisible,
    onClearForm,
    onAddTag,
    onRemoveTag,
    updateFieldValue: (type: TContactType) => setFieldValue(type),
    updateContactLists,
    visibleModal,
    getFilterPresets,
    filterPresetList,
    organisationList,
    catalogType,
    sendTypeList,
    onUpdateSelectedFields,
    selectedFields,
    onRemoveSelectedFields,
    senderOrganisations,
    onRemoveFilterPreset,
    fieldValue,
  };
};
