export const MOCK_MASK_REG_NUMBER = {
  '{OrgSenderOfficialCode}': 'Код организации отправителя',
  '{OrgOfficialCode}': 'Код организации',
  '{Day}': 'День',
  '{Month}': 'Месяц',
  '{Year}': 'Год',
  '{NomCode}': 'Номенклатура',
  '{Count}': 'Счетчик',
  '{FullDate}': 'Полная дата',
};

export const documentTypes = [
  {
    id: 0,
    type: 'Incoming',
    name: 'Входящие',
  },
  {
    id: 1,
    type: 'Outcoming',
    name: 'Исходящие',
  },
  {
    id: 2,
    type: 'Inner',
    name: 'Внутренние',
  },
];
