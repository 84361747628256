import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { Drawer, Tag } from 'antd';
import { TagsEntitiesDrawerTypes } from './types';
import './TagsEntitiesDrawer.scss';

export const TagsEntitiesDrawer = ({
  visible,
  onClose,
  entities,
  setEntities,
  currentIndexColumn,
  selectionEntities,
  setSelectionEntities,
}: TagsEntitiesDrawerTypes) => {
  const onClickTag = (item: string) => {
    onClose();
    const newSelection = [...selectionEntities];
    setEntities((prev: string[]) => prev.filter(value => value !== item));
    newSelection[currentIndexColumn] = item;
    setSelectionEntities(newSelection);
  };

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      className="drawer"
      closeIcon={null}
    >
      <div className="page3 mb15">
        <DrawerHeader onClose={onClose} text="Загрузка cущностей" />
        <div className="entities-container">
          <div className="tags-container">
            {entities.map(item => (
              <Tag className="tag" onClick={() => onClickTag(item)}>
                {item}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
