import { TStampDraggableProps } from './types';

export const stampTypes = [
  {
    Type: 'Full',
    Name: 'Штамп и рег.номер',
  },
  {
    Type: 'OnlyStamp',
    Name: 'Штамп',
  },
  {
    Type: 'OnlyRegNumber',
    Name: 'Рег. номер',
  },
];

export const initialValues = {
  File: undefined,
  Resolution: '',
  StampType: undefined,
};

export const stampsLength = {
  X1Length: 222,
  X2Length: 142,
  Y1Length: 114,
  Y2Length: 22,
};

export const stampDraggableProps: TStampDraggableProps = {
  scale: 1,
  grid: [1, 1],
};

export const defaultStampPosition = { x: -20, y: 0 };
export const defaultRegNumberPosition = { x: 0, y: 250 };
