import { useCallback, useMemo } from 'react';
import { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { fetchCalendarData } from 'api/calendar/fetchCalendarData';
import { TDates } from 'store/slice/calendar';

export type TDays = {
  Number: number;
  Type: string;
  DayOfWeek: string;
};

export type TMonth = {
  Type: string;
  Volume: number;
  Days: TDays[];
};

export const useDatePicker = () => {
  const dispatch = useDispatch();
  const { dates } = useSelector((state: ReduxState) => state.calendarReducer);

  const datesMap = useMemo(() => {
    const map = new Map<string, TDates>();

    dates.forEach(date => {
      map.set(date.date, date);
    });

    return map;
  }, [dates]);

  const renderCustomDate = useCallback(
    (currentDate: Moment) => {
      const dateWithHoliday = datesMap.get(currentDate.format('DD.MM.yyyy'));
      return (
        <div
          title=""
          className={
            dateWithHoliday?.holiday
              ? 'danger ant-picker-cell-inner'
              : 'ant-picker-cell-inner'
          }
        >
          {currentDate.date()}
        </div>
      );
    },
    [datesMap],
  );

  const onYearChange = (year?: number) => {
    fetchCalendarData({ dispatch, year });
  };

  return {
    renderCustomDate,
    onYearChange,
  };
};
