import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableMenu } from 'shared/components';
import { MatchParams } from 'types/tableData';
import { MainTable } from 'shared/components/MainTable';
import { ReduxState } from 'store/store';
import { useLocation } from 'react-router';
import { updateTableData } from 'pages/MainPage/MainPage';
import { debounce } from 'lodash-es';

export const InnerPage = ({ match }: MatchParams) => {
  const {
    tablePagination,
    documentTableData,
    searchValue,
    activeSortParams,
  } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { substituateId } = useSelector(
    (state: ReduxState) => state.authReducer,
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const orgId = location.pathname.split('/')[1];
  const pageNumber = location.pathname
    .split('/')
    [location.pathname.split('/').length - 1].split('page=')[1];

  const updateTable = useCallback(
    debounce(() => {
      updateTableData({
        type: `Documents/byorganisation/${orgId}/inner`,
        dispatch,
        tablePagination: {
          page: Number(pageNumber) ?? tablePagination?.page,
          pageSize: tablePagination?.pageSize,
        },
        orgId,
        searchValue: searchValue,
        DocumentType: 'inner',
        sortParams: activeSortParams,
      });
    }, 500),
    [
      substituateId,
      dispatch,
      activeSortParams,
      pageNumber,
      tablePagination?.pageSize,
    ],
  );

  useEffect(() => {
    updateTable();
  }, [substituateId, updateTable, activeSortParams]);

  return (
    <section id="inner_table">
      <TableMenu />
      <MainTable
        data={{
          tableData: documentTableData.documents,
          filteredCount: documentTableData.filteredCount,
        }}
        match={match}
      />
    </section>
  );
};
