import { changeBarCodeFile } from 'api/downloadFile/changeBarCodeFile';
import { FileListParams } from 'shared/components/Files/types';
import { TDocumentFile } from 'types/document';

export const formatFiles = (files: FileListParams[]) => {
  return files
    .map(item => item.id)
    .filter(item => item)
    .filter(item => !item.match(/^\d+$/));
};

export const formatFilesToUpdateState = (files: TDocumentFile[]) => {
  return files.map((item: TDocumentFile) => ({
    file: {
      ...item,
      name: item.Name,
    },
  })) as FileListParams[];
};

export const getFilesWithBarcode = (
  fileList: FileListParams[],
  itemId: number,
  type: 'Document' | 'Assignment',
) => {
  const filesWithBarCode = fileList.filter(item => item.file?.ObjectId === -1);
  const filesRequests: Promise<any>[] = [];
  filesWithBarCode.forEach(item => {
    filesRequests.push(changeBarCodeFile(item.file, itemId, type));
  });

  return filesRequests;
};
