import { notification } from 'antd';
import { copyToClipboard } from './copyToClipboard';
import { deleteCookie } from './getAuthToken';

export type TError = {
  status: number;
  data: {
    detail: string;
    status: number;
    title: string;
    traceId: string;
    type: string;
    Title: string;
  };
};
export const errorThrow = (response: TError) => {
  const title = response?.data?.title ?? response?.data?.Title;
  if (response?.status === 401) {
    deleteCookie('auth_token');
    window.location.href = '/login';
  } else
    notification['error']({
      message: 'Произошла ошибка',
      description: `${response ? title : 'Нет заголовка'}`,
      icon: null,
      className: 'custom-notification__error-after',
      onClick: () => copyToClipboard(response?.data?.detail),
    });
};
