import { Drawer } from 'antd';
import { useFormik } from 'formik';
import { DocumentSignDrawerType } from 'pages/Archive/type';
import { SignedForm } from 'pages/AssignmentCard/components/SignedForm';
import { useSelector } from 'react-redux';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { DButton } from 'shared/ui-kit';
import { errorThrow, TError } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';

export const DocumentSignDrawer = ({
  visible,
  onClose,
  item,
  onGetData,
}: DocumentSignDrawerType) => {
  const { signedInfo } = useSelector(
    (state: ReduxState) => state.assignmentReducer,
  );

  const formik = useFormik({
    initialValues: {},
    onSubmit: values => {
      $api
        .patch(`archivecases/${item?.Id}/sign`, {
          CertificateThumbprint: signedInfo.CertificateThumbprint,
          SignatureData: signedInfo.DocumentSignatureData,
          Signature: signedInfo.DocumentSignature,
        })
        .then(onClose)
        .catch(({ response }) => errorThrow(response as TError))
        .finally(onGetData);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Формирование описи" />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <SignedForm archive={item} />
          <DButton small primary type="submit">
            Сформировать
          </DButton>
        </form>
      </div>
    </Drawer>
  );
};
