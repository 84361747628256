import _ from 'lodash';
import { DecadeArray } from './type';

export const getYearsByCurrent = (maxYear: number) => {
  const yearArray = [];
  for (let i = 2000; i <= maxYear; i++) {
    yearArray.push(i);
  }

  return yearArray;
};

export const getDecades = (array: number[]) => {
  const sortedArray = array.sort((a, b) => (a > b ? -1 : 1));
  const decades: {
    year: number;
    decade: number;
  }[] = [];
  sortedArray.forEach(year => {
    const decade = year - (year % 10);
    decades.push({ decade: decade, year: year });
  });

  const objectOfDecades = _.groupBy(decades, (item: any) => item.decade);
  const completeArray: (string | number[])[][] = [];
  for (const item in objectOfDecades) {
    const newItem = [item, objectOfDecades[item].map(mapItem => mapItem.year)];
    completeArray.push(newItem);
  }
  return completeArray.reverse();
};

export const getDecadeTitle = (array: DecadeArray[]) => {
  if (array[1].length > 1) {
    if (array[1].includes(Number(array[0]))) {
      return array[1][0] + '—' + array[0];
    } else {
      return array[1][0] + '—' + array[1][array[1].length - 1];
    }
  } else {
    return array[1][0];
  }
};
