import { DrawerHeader } from './DrawerHeader';
import { useCallback, useEffect, useState } from 'react';
import { Drawer, Select } from 'antd';
import { $api } from 'shared/utils/api';
import { errorThrow, successNotification } from 'shared/utils';
import { DButton } from 'shared/ui-kit';
import { DatePick } from '../DatePicker/DatePick';
import { useFormik } from 'formik';
import moment from 'moment';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { debounce } from 'lodash-es';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';

type TCreateSubstituteDrawer = {
  visible: boolean;
  onClose: (bool: boolean) => void;
  getData: () => void;
};

type TContact = {
  Id: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
};

export const CreateSubstituteDrawer = ({
  visible,
  onClose,
  getData,
}: TCreateSubstituteDrawer) => {
  const [contactList, setContactList] = useState<TContact[]>([]);
  const { Option } = Select;

  const initialValues = {
    SubstitutionId: undefined,
    ExpiryDate: moment(),
  };

  const getContacts = useCallback((query?: string) => {
    $api
      .get(`contacts/active/byorganization/page`, {
        params: {
          FastSearch: query,
        },
      })
      .then(({ data }) => setContactList(data.Data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const onSearchContact = debounce(value => getContacts(value), DEBOUNCE_TIME);

  const onFinish = (values: { SubstitutionId?: number; ExpiryDate: any }) => {
    $api
      .post(`accounts/substitutions`, {
        SubstitutionId: values.SubstitutionId,
        ExpiryDate: values.ExpiryDate,
      })
      .then(() => {
        successNotification('Замещение создано');
        getData();
        onClose(false);
      })
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose(false)}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Создать замещение" />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Замещающий" name="SubstitutionId">
            <Select
              value={formik.values.SubstitutionId}
              className="ui-select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('SubstitutionId', value)}
              onSearch={onSearchContact}
            >
              {contactList?.map(item => (
                <Option key={item.Id} value={item.Id}>
                  {item.LastName} {item.FirstName} {item.MiddleName}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Срок замещения" name="ExpiryDate">
            <DatePick
              style={{
                maxWidth: 140,
                height: 36,
                borderRadius: 8,
              }}
              onChange={(date: moment.Moment) => {
                formik.setFieldValue('ExpiryDate', date);
              }}
              allowClear
              format="DD.MM.YYYY"
              value={formik.values.ExpiryDate ?? null}
            />
          </DFormItemTwo>
          <DFormItemTwo>
            <DButton small primary type="submit" className="mr15">
              Создать
            </DButton>
            <DButton small defaultDanger onClick={() => onClose(false)}>
              Закрыть
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
