import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { TableResult } from 'types/tableData';
import { TColumns } from 'types/columns';
import { TableButton } from 'shared/components/TableMenu/types';

export type SortActiveParams = { title: string; option: string };

export type User = {
  CurrentContact: {
    FirstName: string;
    LastName: string;
    MiddleName: string;
    Id: number;
    DepartmentId?: number;
    Email?: string;
    PositionId?: number;
    Type?: string;
    Esign?: string;
    IsHead: boolean;
    AvatarMaxSizeId: number;
    PhoneNumber: string;
    OrganisationId?: number;
    OrganisationName: string;
  } | null;
  Permissions: {
    Entity: string;
    Action: string;
  }[];
  CurrentUser: {
    UserId: number;
    Login: string;
    Name: string;
    SubstitutionId: number;
    IsPasswordRequiresChange: boolean;
  };
};

type BreadCrumbsParams = {
  path: string;
  title: string;
};

export type DocumentKind = {
  Name: string;
  Id: number | null;
  DocumentType?: string;
  NomenclatureId?: number;
  FlowId?: number;
  MaskRegNumber?: string;
  OrganisationId?: number;
  NomenclatureName?: string;
  FlowName?: string;
  DocumentStreamId?: number | null;
  OrganisationName?: string;
};

type TCols = {
  documentColumns?: TColumns[];
  assignmentColumns?: TColumns[];
};

export type TSelectedFilter = {
  Id?: number;
  CreateDate?: string;
  UpdateDate?: string;
  ContactId?: number;
  FilterData?: string;
  FilterName?: string;
  IsSideBarShow?: boolean;
};

export type TDocumentTableData = {
  documents: TableResult[];
  filteredCount: number;
};

export type TTablePagination = {
  totalCount?: number;
  page?: number;
  pageSize?: number;
};

type TRegButton = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  Name: string;
  SendModuleId: number;
};

export type TableData = {
  documentTableData: TDocumentTableData;
  tablePagination: TTablePagination | null;
  filteredDocuments: {
    data: TableResult[];
    config: any;
    filteredCount: number;
  };
  selectedFilter: TSelectedFilter | null;
  isLoading: boolean;
  selectedDocuments: number;
  currentItemId: number | null | string;
  user: User | null;
  breadCrumb: BreadCrumbsParams[];
  tableType: string;
  DocumentKind: DocumentKind | null;
  columns: TCols;
  buttonType: TableButton;
  buttonTypeRegistration: string | number;
  searchValue: string;
  registrationButtons: TRegButton[];
  visibleSettings: boolean;
  activeSortParams: SortActiveParams | null;
};

const initialState: TableData = {
  documentTableData: {
    documents: [],
    filteredCount: 0,
  },
  tablePagination: {
    totalCount: 0,
    page: 1,
    pageSize: 15,
  },
  buttonType: 'inWork',
  buttonTypeRegistration: 'all',
  selectedFilter: null,
  isLoading: false,
  selectedDocuments: 0,
  currentItemId: null,
  user: null,
  breadCrumb: [
    {
      path: '/active-documents',
      title: 'Мои документы',
    },
  ],
  tableType: 'Documents/byuser/work',
  DocumentKind: null,
  columns: {
    documentColumns: [],
    assignmentColumns: [],
  },
  filteredDocuments: {
    data: [],
    config: [],
    filteredCount: 0,
  },
  searchValue: '',
  registrationButtons: [],
  visibleSettings: true,
  activeSortParams: {
    title: 'ДАТА СОЗДАНИЯ',
    option: 'ByCreateDateDesc',
  },
};

const tableDataSlice = createSlice({
  name: 'mainTable',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    updateVisibleSetting(state, action: PayloadAction<boolean>) {
      state.visibleSettings = action.payload;
    },
    updateColumns(state, action: PayloadAction<TCols>) {
      state.columns = action.payload;
    },
    updateActiveSortParams(
      state,
      action: PayloadAction<SortActiveParams | null>,
    ) {
      state.activeSortParams = action.payload;
    },
    updateSearchValue(state, action: PayloadAction<any>) {
      state.searchValue = action.payload;
    },
    updateRegButtons(state, action: PayloadAction<TRegButton[]>) {
      state.registrationButtons = action.payload;
    },
    updateSelectedFilter(state, action: PayloadAction<TSelectedFilter | null>) {
      state.selectedFilter = action.payload;
    },
    updateDataTable(state, action: PayloadAction<TDocumentTableData>) {
      state.documentTableData = action.payload;
    },
    loadingStatus(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateSelectedDocuments(state, action: PayloadAction<number>) {
      state.selectedDocuments = action.payload;
    },
    updateCurrentItemId(state, action: PayloadAction<string | null | number>) {
      state.currentItemId = action.payload;
    },
    updateUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    updateDataCount(state, action: PayloadAction<TTablePagination | null>) {
      state.tablePagination = action.payload;
    },
    updateBreadcrumbs(state, action: PayloadAction<BreadCrumbsParams>) {
      let equal = current(state.breadCrumb).filter(
        item => item.path === action.payload.path,
      );
      if (equal.length) return;
      state.breadCrumb.push(action.payload);
    },
    closeBreadcrumb(state, action: PayloadAction<string>) {
      state.breadCrumb = current(state.breadCrumb).filter(
        item => item.path !== action.payload,
      );
    },
    deleteBreadcrumbs(state, action: PayloadAction<string>) {
      let paths = current(state.breadCrumb).map(item => item.path);
      if (action.payload === state.currentItemId) {
        return;
      }
      if (state.breadCrumb.length === 1) return;
      state.breadCrumb = current(state.breadCrumb).filter(
        (item, index) => index < paths.indexOf(action.payload) + 1,
      );
    },
    reloadBreadcrumbs(state, action: PayloadAction<BreadCrumbsParams>) {
      state.breadCrumb = [action.payload];
    },
    updateTableType(state, action: PayloadAction<string>) {
      state.tableType = action.payload;
    },
    updateDocumentKindId(state, action: PayloadAction<DocumentKind | null>) {
      state.DocumentKind = action.payload;
    },
    resetBreadCrumb(state, action: PayloadAction<BreadCrumbsParams[]>) {
      state.breadCrumb = action.payload;
    },
    updateFilteredDocuments(state, action: PayloadAction<any>) {
      state.filteredDocuments = action.payload;
    },
    updateButtonType(state, action: PayloadAction<TableButton>) {
      state.buttonType = action.payload;
    },
    updateButtonTypeRegistration(
      state,
      action: PayloadAction<string | number>,
    ) {
      state.buttonTypeRegistration = action.payload;
    },
  },
});

export const {
  updateRegButtons,
  updateButtonType,
  updateDataCount,
  updateDataTable,
  loadingStatus,
  updateSelectedDocuments,
  updateCurrentItemId,
  updateUser,
  updateBreadcrumbs,
  deleteBreadcrumbs,
  closeBreadcrumb,
  reloadBreadcrumbs,
  updateTableType,
  updateDocumentKindId,
  resetBreadCrumb,
  reset,
  updateColumns,
  updateSelectedFilter,
  updateFilteredDocuments,
  updateSearchValue,
  updateButtonTypeRegistration,
  updateVisibleSetting,
  updateActiveSortParams,
} = tableDataSlice.actions;
export const tableDataReducer = tableDataSlice.reducer;
