import {
  fetchAssignmentsData,
  TFilterAssignment,
} from 'api/assignments/fetchAssignmentsData';
import React, { ReactElement, useEffect, useState } from 'react';
import { Dropdown, Menu, Select, Skeleton, Tabs } from 'antd';
import { CommentsTable } from '../CommentsTable';
import { RevisionHistory } from '../RevisionHistory';
import { RoutTable } from './RoutTable';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { SignaturesTable } from 'pages/DocumentPage/components';
import { DocumentControl } from '../DocumentControl';
import { GeneralInfo } from '../GeneralInfo';
import { AssignmentTable } from '../AssignmentTable';
import { DButton } from 'shared/ui-kit';
import { DownOutlined } from '@ant-design/icons';
import { IssuanceAssignmentDrawer } from 'shared/components/CustomDrawers/IssuanceAssignmentDrawer';
import { MedoNotificationsByDocument } from './MedoNotificationsByDocument';
import './TableDT.scss';
import { AssignmentIncomingType } from 'shared/components/CustomDrawers/IssuanceAssignmentDrawer/types';

type TAssignmentOption = {
  option: TFilterAssignment;
  name: string;
};

const assignmentOptions: TAssignmentOption[] = [
  {
    option: 'all',
    name: 'Все задачи',
  },
  {
    option: 'completed',
    name: 'Исполненные',
  },
  {
    option: 'not',
    name: 'Неисполненные',
  },
];

const issueInstructions = [
  {
    id: 1,
    title: 'На исполнение',
    link: 'assignment-to-execute',
  },
  {
    id: 2,
    title: 'На рассмотрение',
    link: 'assignment-for-audit',
  },
  {
    id: 3,
    title: 'На ознакомление',
    link: 'assignment-for-review',
  },
];

export const TableDT = (): ReactElement => {
  const [visible, setVisible] = useState<boolean>(false);
  const [assignmentType, setAssignmentType] = useState<AssignmentIncomingType>(
    'assignment-to-execute',
  );
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { assignments } = useSelector(
    (state: ReduxState) => state.assignmentReducer,
  );
  const { documentInfo, loader } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const dispatch = useDispatch();

  const onClose = () => setVisible(false);

  const onSelect = (value: TFilterAssignment) => {
    fetchAssignmentsData({
      array: documentInfo?.Assignments,
      dispatch,
      type: value,
    });
  };

  const issueInstructionsMenu = (
    <Menu>
      {issueInstructions.map(instruction => (
        <Menu.Item
          key={instruction.id}
          onClick={() => {
            setVisible(true);
            setAssignmentType(instruction.link as AssignmentIncomingType);
            sessionStorage.setItem('assignmentType', instruction.link);
          }}
        >
          {instruction.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    sessionStorage.removeItem('assignmentType');
  }, []);

  return (
    <>
      {visible && (
        <IssuanceAssignmentDrawer
          type={assignmentType}
          onClose={onClose}
          visible={visible}
          documentData={documentInfo}
        />
      )}
      {loader ? (
        <Skeleton />
      ) : (
        <>
          <Tabs
            className="mb40"
            defaultActiveKey={
              sessionStorage.getItem('activeDocumentTab') ?? '1'
            }
            onChange={data => sessionStorage.setItem('activeDocumentTab', data)}
          >
            <TabPane tab="Общая информация" key="1">
              <GeneralInfo currentDocument={documentInfo} canEdit={true} />
              {documentInfo?.MedoNotifications?.length ? (
                <MedoNotificationsByDocument
                  data={documentInfo?.MedoNotifications}
                />
              ) : null}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                <div
                  className="mt35"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <h2 style={{ marginBottom: 0 }}>Задачи</h2>
                  <Dropdown
                    className="ml15"
                    trigger={['click']}
                    overlay={issueInstructionsMenu}
                  >
                    <DButton small>
                      Выдать задачи <DownOutlined />
                    </DButton>
                  </Dropdown>
                </div>
                <div style={{ float: 'right' }}>
                  <Select
                    defaultValue="all"
                    className="w300 mb10 ui-select"
                    onChange={(value: TFilterAssignment) => onSelect(value)}
                  >
                    {assignmentOptions.map(item => (
                      <Option key={item.option} value={item.option}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <AssignmentTable assignments={assignments} />
              <CommentsTable documentId={documentInfo?.Id} />
            </TabPane>

            <TabPane tab="Контроль исполнения" key="2">
              <DocumentControl currentDocument={documentInfo} canEdit={true} />
            </TabPane>

            <TabPane tab="Подписи документа" key="3">
              <SignaturesTable documentId={documentInfo?.Id} />
            </TabPane>
            <TabPane tab="История обработки" key="4">
              <RoutTable documentId={documentInfo?.Id} />
              <RevisionHistory documentId={documentInfo?.Id} />
            </TabPane>
          </Tabs>
        </>
      )}
    </>
  );
};
