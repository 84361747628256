import { Button, Table } from 'antd';
import { useHistory } from 'react-router';

import { MatchParams } from 'types/tableData';

const columns = [
  {
    title: 'Документ',
    dataIndex: 'Document',
    key: 'Document',
    onFilter: (value: any, record: any) => record.Document.indexOf(value) === 0,
    sorter: (a: any, b: any) => a.Document.length - b.Document.length,
    sortDirections: ['descend'],
  },
  {
    title: 'Тип/вид документа',
    dataIndex: 'DocumentKind',
    key: 'DocumentKind',
  },
];

const data: any = [
  {
    key: 1,
    Document:
      'Вх. б/н Тест  ОТ КОГО: Комитет по образованию и науке АВО за подписью Корреспондентов: 2;',
    DocumentKind: 'Входящие. Эл. Письмо',
  },
  {
    key: 2,
    Document:
      'Исх. б/н Тест  ОТ КОГО: Комитет по образованию и науке АВО за подписью Корреспондентов: 2;',
    DocumentKind: 'Исходящие. Эл. Письмо',
  },
];

export const LinkTOADocumentTable = ({ match }: MatchParams) => {
  const history = useHistory();
  return (
    <>
      {/*@ts-ignore*/}
      <Table columns={columns} dataSource={data} />
      <Button danger onClick={() => history.goBack()}>
        Верунться
      </Button>
    </>
  );
};
