import { InputHTMLAttributes, ReactNode } from 'react';
import { Size } from '../../types/size';
import { SimpleSpread } from '../../types/utils';

type InputStatus = 'error' | 'success';

export enum Status {
  Success = 'success',
  Error = 'error',
}

type InputPropsExtra = {
  prefix?: ReactNode;
  size?: Size;
  message?: string;
  status?: InputStatus;
  highlight?: boolean;
  label?: string;
};

export interface InputProps
  extends SimpleSpread<
    InputHTMLAttributes<HTMLInputElement>,
    InputPropsExtra
  > {}

export type ValidationIconProps = {
  status: InputStatus | undefined;
};
