export type Departments = {
  Id: number;
  Name: string;
  OfficialCode: string;
  OrgNomenclatureId: null | number;
  ParentId: null | number;
  RegNumberRule: string | null;
};

export type ContactList = {
  DepartmentId: null | number;
  Email: null | string;
  Esign: string;
  FirstName: string;
  Id: number;
  IsHead: boolean;
  IsBanned: boolean;
  LastName: string;
  MiddleName: string;
  PhoneNumber: null | string;
  PositionId: number;
  Type: string;
  PositionName: string;
  Login: string;
  DepartmentName: string;
  Password: string;
  PasswordChangeDate: string;
  UserName: string;
  SsoUserId: number;
  OrganisationId: number;
  receiverGroup?: boolean;
};

export type DeliveryParams = {
  Id: number;
  Name: string;
};

export type OrgParams = {
  MedoUid: string;
  Address: string;
  ParticipantName: string;
  IsAgreement: boolean;
  Name: string;
  Id: number;
};

export type TCreateDocument = {
  onClose?: (bool: boolean) => void;
  getDocumentInfo?: () => void;
};

export type TDocumentLink = {
  OrganisationId: number;
  OrganisationName: string;
  DocumentState: string;
  DocumentType: string;
  DocumentCreateDate: string;
  RegNumber: string;
  Id: number;
  RegDate: string;
  DocumentId: number;
  LinkedDocumentId: number;
  LinkType: string;
  Type?: string;
  CreateDate?: string;
  Subject?: string;
  DocumentSubject?: string;
};

export type DocumentInfo = {
  Id: number;
  Type: string;
  AuthorId: number;
  SubstitutionId: number;
  DocumentKindId: number;
  NomenclatureId: number;
  RegNumber: string;
  State: string;
  Subject: string;
  Comment: null | string;
  OrgNomenclatureId: null | number;
  RegDate: string;
  ExecutionTime: string;
  DocumentType: string;
  ControlSign: string;
  Summary: string;
  InRegDate: string;
};

export type LinkOfficersToOrgnanisation = {
  AddresseeFirstName: string;
  AddresseeId: number;
  AddresseeLastName: string;
  AddresseeMiddleName: string;
  OrganisationId: number;
  OrganisationName: string;
  SendTypeName: string;
  SendTypeId: number;
};

export type DocumentInitialValues = {
  DocumentKind?: number | null;
  Subject?: string;
  Summary?: string;
  delivery?: number;
  Correspondent?: number;
  Addressee?: number;
  CreateDate?: moment.Moment | null;
  Sender?: number;
  DocumentKindId?: number | null;
  InRegNumber?: string;
  DocumentStreamId?: number;
};

export type DocumentEntities =
  | 'OfficerDrawer'
  | 'RouteComponent'
  | 'Error'
  | 'ContinueButton'
  | 'Catalog';

export enum DocumentVisibleEntities {
  Officer = 'OfficerDrawer',
  RouteComponent = 'RouteComponent',
  Error = 'Error',
  ContinueButton = 'ContinueButton',
  Catalog = 'Catalog',
}

export type DocumentKind = {
  Name: string;
  Id: number | null;
  DocumentType?: string;
  NomenclatureId?: number;
  FlowId?: number;
  MaskRegNumber?: string;
  OrganisationId?: number;
  NomenclatureName?: string;
  FlowName?: string;
  DocumentStreamId?: number | null;
  DocumentKindId: number;
  DocumentKindName: string;
};
