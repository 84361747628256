export const TYPES_ASSIGNMENT = [
  {
    Type: 'ToExecute',
    Name: 'На исполнение',
  },
  {
    Type: 'ToView',
    Name: 'На рассмотрение',
  },
  {
    Type: 'ToReview',
    Name: 'На ознакомление',
  },
];

export const PLAIN_OPTIONS = [
  {
    name: 'Заверять задачу ЭП',
    value: 'IsESigned',
  },
  {
    name: 'Закрывать родительскую задачу автоматически',
    value: 'AutoExecute',
  },
];
