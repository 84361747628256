import _ from 'lodash';
import { ContactList } from 'pages/CreateDocument/types';
import { Icon } from 'shared/components/IconComponent';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { getFullName } from 'shared/utils';
import { TOrganisationsList } from '../types';
import deleteSvg from 'shared/assets/images/delete.svg';

type SelectedFieldsProps = {
  array: ContactList[] | TOrganisationsList[];
  onRemove: (
    key: string,
    newArray: ContactList[] | TOrganisationsList[],
  ) => void;
  fieldValue: string;
};

export const SelectedFields = ({
  fieldValue,
  array,
  onRemove,
}: SelectedFieldsProps) => {
  return (
    <DFormItemTwo label="Выбранные" name="Selected">
      <div className="w100per">
        {/* @ts-ignore */}
        {_.uniqBy(array, 'Id').map(item => (
          <div className="flex-space">
            <div className="mb5">
              <p className="custom-link mb0">
                {!item.hasOwnProperty('Name')
                  ? getFullName({
                      // @ts-ignore
                      lastName: item.LastName,
                      // @ts-ignore
                      firstName: item.FirstName,
                      // @ts-ignore
                      middleName: item.MiddleName,
                    })
                  : // @ts-ignore
                    item.Name}
              </p>
            </div>
            <Icon
              icon={deleteSvg}
              alt="deleteSvg"
              onClick={() =>
                onRemove(
                  fieldValue,
                  // @ts-ignore
                  array.filter(
                    (arrayItem: ContactList | TOrganisationsList) =>
                      arrayItem.Id !== item.Id,
                  ),
                )
              }
              tooltip="Удалить"
            />
          </div>
        ))}
      </div>
    </DFormItemTwo>
  );
};
