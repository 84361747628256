import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { Folder } from 'types/folder';

type TSubAtomSidebar = {
  child: Folder;
  onClickSubAtomChild: (child: Folder) => void;
};

const SubAtomSidebar = ({ child, onClickSubAtomChild }: TSubAtomSidebar) => {
  const { userOrganisations } = useSelector(
    (state: ReduxState) => state.sidebarReducer,
  );

  const CHILDREN_LENGTH = child.childrens?.length;

  const updateActiveChild = () => {
    const UNIQ_ITEM = child.Id + String(child.parentId);

    if (sessionStorage.getItem('uniqItem') == UNIQ_ITEM) {
      return 'sidebar__subItemActive';
    }

    return 'sidebar__subItem';
  };

  return (
    <>
      <div
        onClick={e => {
          e.stopPropagation();
          onClickSubAtomChild(child);
        }}
        key={child.Id}
        className={updateActiveChild()}
      >
        <span className="sidebar__tire">—</span>
        {child.Name}
        {typeof child.link !== 'number' &&
        child?.link?.includes('on-registration') ? (
          <span className="ml5">
            (
            {
              userOrganisations?.find((item: any) => item.Id === child.parentId)
                ?.Count
            }
            )
          </span>
        ) : null}
      </div>
      {CHILDREN_LENGTH ? (
        <>
          <div className="divider-sidebar" />
          {child.childrens?.map(item => (
            <SubAtomSidebar
              child={item}
              onClickSubAtomChild={onClickSubAtomChild}
            />
          ))}
        </>
      ) : null}
    </>
  );
};

export { SubAtomSidebar };
