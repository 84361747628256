import { Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { format } from 'date-fns';
import { errorThrow } from 'shared/utils';
import { DTag } from 'shared/ui-kit/DTag';

type TDocumentHistory = {
  ActionType: string;
  CreateDate: string;
  Description: string;
  Id: number;
  ObjectId: number;
  ObjectType: string;
  ObjectValue: string;
  SubstitutionId: number;
  UpdateDate: string;
  UserFirstName: string;
  UserId: string;
  UserLastName: string;
  UserMiddleName: string;
  SubstitutionLastName: string;
  SubstitutionMiddleName: string;
  SubstitutionFirstName: string;
  IsGrouped: boolean;
};

type TRevisionHistory = {
  documentId?: number;
};

export const RevisionHistory = ({ documentId }: TRevisionHistory) => {
  const [documentHistory, setDocumentHistory] = useState<TDocumentHistory[]>(
    [],
  );

  const historyColumns = [
    {
      title: 'Дата/Время',
      dataIndex: 'UpdateDate',
      key: 'UpdateDate',
      render: (text: string, item: TDocumentHistory) => (
        <span>{format(new Date(item.UpdateDate), 'dd.MM.yyyy HH:mm')}</span>
      ),
    },
    {
      title: 'Операция',
      dataIndex: 'Description',
      key: 'Description',
      render: (description: string, item: TDocumentHistory) => (
        <>
          <p className="mb0">{description}</p>
          {item.IsGrouped && (
            <DTag className="tag-subject" type="small">
              Группа
            </DTag>
          )}
        </>
      ),
    },
    {
      title: 'Пользователь',
      dataIndex: 'UserId',
      key: 'UserId',
      render: (text: string, item: TDocumentHistory) => (
        <>
          <span>
            {item.UserLastName} {item.UserFirstName} {item.UserMiddleName}
          </span>
          {item?.SubstitutionId && (
            <p>
              {item?.SubstitutionLastName} {item?.SubstitutionFirstName}{' '}
              {item?.SubstitutionMiddleName}
            </p>
          )}
        </>
      ),
    },
  ];

  const getData = useCallback(() => {
    if (documentId)
      $api
        .get(`Documents/${documentId}/audit`)
        .then(res => {
          let newArr = res.data.map((item: TDocumentHistory) => {
            let key = { key: item.Id };
            return Object.assign(key, item);
          });
          setDocumentHistory(newArr);
        })
        .catch(({ response }) => errorThrow(response));
  }, [documentId]);

  useEffect(() => {
    getData();
  }, [documentId]);

  return (
    <>
      <h2 className="mt35">История изменений</h2>
      <Table
        pagination={{
          position: ['bottomRight'],
          defaultCurrent: 1,
          defaultPageSize: 1000,
          hideOnSinglePage: true,
        }}
        dataSource={documentHistory}
        columns={historyColumns}
      />
    </>
  );
};
