import { Button, Checkbox, Form, Input, Select, Table } from 'antd';
import { FC, ReactElement } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { formRules } from 'shared/utils/rules';
import { ReduxState } from 'store/store';
import { useBreadcrumb } from 'shared/hooks/useBreadcrumb';
import { successNotification } from 'shared/utils';

export const FileSend: FC = (): ReactElement => {
  const { Option } = Select;
  const { Column } = Table;
  const history = useHistory();
  const dispatch = useDispatch();
  const { closeBreadcrumbs } = useBreadcrumb();
  const { selectedDocuments } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );

  const columns = [
    {
      title: 'Контакт',
      key: 'contact',
      dataIndex: 'contact',
    },
    {
      title: 'Дополнительные сведения',
      key: 'info',
      dataIndex: 'info',
    },
  ];

  const data = [{ contact: 'Список пуст', info: '' }];

  const onFinish = () => {
    successNotification('Документ успешно отправлен');
    history.push('/active-documents/page=1');
    closeBreadcrumbs(dispatch, history.location.pathname);
  };

  return (
    <>
      <span className="danger">
        Для обработки выбрано документов: {selectedDocuments}
      </span>
      <Form
        name="basic"
        labelCol={{ span: 7 }}
        className="form mt5"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="form-inner">
          <Form.Item
            label="Наименование"
            name="name"
            rules={formRules('наименование')}
            className="form-inner__field"
          >
            <Input />
          </Form.Item>

          <Form.Item label="Искать только" name="search">
            <Select defaultValue="Сотрудники" className="w620">
              <Option value="boss">Начальник</Option>
              <Option value="staff">Сотрудники</Option>
              <Option value="disabled" disabled>
                Неизвестно
              </Option>
            </Select>
            <Checkbox className="mt5" disabled>
              Только в своей организации
            </Checkbox>
          </Form.Item>

          <Form.Item
            label="Организация"
            name="organization"
            rules={formRules('организация')}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Должность"
            name="position"
            rules={formRules('должность')}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Из группы" name="group">
            <Select defaultValue="center" className="w620">
              <Option value="center">
                ОИВ ВО - ГБУ ВО "Центр информационных технологий Волгоградской
                области"
              </Option>
              <Option value="oivo">ОИВ ВО</Option>
              <Option value="disabled" disabled>
                Неизвестно
              </Option>
            </Select>
            <Checkbox className="mt5">Не показывать запрещенные</Checkbox>
          </Form.Item>

          <Table columns={columns} dataSource={data}>
            {columns.map(column => {
              return (
                <Column
                  title={column.title}
                  dataIndex={column.dataIndex}
                  key={column.key}
                />
              );
            })}
          </Table>
        </div>

        <Form.Item className="form-buttons">
          <Button className="button" type="primary" htmlType="submit">
            Отправить
          </Button>
          <Button
            danger
            onClick={() => {
              history.goBack();
              closeBreadcrumbs(dispatch, history.location.pathname);
            }}
          >
            Закрыть
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
