export const options = [
  {
    title: 'Системный номер',
    dataIndex: 'Id',
    key: 'Id',
    formatter: 'sysNumber',
  },
  {
    title: 'Рег. номер и рег. дата',
    dataIndex: 'RegDate',
    key: 'RegDate',
    formatter: 'RegInfo',
    checked: false,
    type: 'document',
  },
  {
    title: 'Дата создания',
    dataIndex: 'CreateDate',
    key: 'CreateDate',
    formatter: 'date',
    checked: false,
  },
  {
    title: 'Автор РКК',
    dataIndex: 'author',
    key: 'author',
    formatter: 'authorName',
    checked: false,
    type: 'document',
  },
  {
    title: 'Тип',
    dataIndex: 'Type',
    key: 'Type',
    checked: false,
    formatter: 'type',
  },
  {
    title: 'Наличие ЭП',
    dataIndex: 'HasESign',
    key: 'HasESign',
    checked: false,
    formatter: 'HasESign',
    type: 'document',
  },
  {
    title: 'Состояние',
    dataIndex: 'State',
    key: 'State',
    checked: false,
    formatter: 'state',
  },
  {
    title: 'Краткое содержание',
    dataIndex: 'Summary',
    key: 'Summary',
    checked: false,
    type: 'document',
  },
  {
    title: 'Организация автора РКК',
    dataIndex: 'AuthorOrganisationName',
    key: 'AuthorOrganisationName',
    checked: false,
    type: 'document',
    formatter: 'AuthorOrganisation',
  },
  {
    title: 'Корреспондент',
    dataIndex: 'Correspondent',
    key: 'Correspondent',
    formatter: 'correspondentName',
    checked: false,
    type: 'document',
  },
  {
    title: 'Адресат',
    dataIndex: 'Addressee',
    key: 'Addressee',
    formatter: 'addresseeName',
    checked: false,
    type: 'document',
  },
  {
    title: 'Отправитель',
    dataIndex: 'SenderName',
    key: 'SenderName',
    checked: false,
    type: 'document',
    formatter: 'SenderName',
  },
  {
    title: 'Получатель',
    dataIndex: 'ReceiverName',
    key: 'ReceiverName',
    checked: false,
    type: 'document',
    formatter: 'ReceiverName',
  },
  {
    title: 'Исх. номер отправителя',
    dataIndex: 'InRegNumber',
    key: 'InRegNumber',
    checked: false,
    type: 'document',
    formatter: 'InRegNumber',
  },
  {
    title: 'Вид документа',
    dataIndex: 'DocumentKindName',
    key: 'DocumentKindName',
    checked: false,
    type: 'document',
    formatter: 'DocumentKind',
  },
  {
    title: 'Дело по номенклатуре',
    dataIndex: 'NomenclatureName',
    key: 'NomenclatureName',
    checked: false,
    type: 'document',
  },
  {
    title: 'Отправка по МЭДО',
    dataIndex: 'IsMedo',
    key: 'IsMedo',
    checked: false,
    type: 'document',
    formatter: 'Medo',
  },
  {
    title: 'Контрольная дата',
    dataIndex: 'ControlDate',
    key: 'ControlDate',
    checked: false,
    type: 'document',
    formatter: 'ControlDate',
  },
  {
    title: 'Автор',
    dataIndex: 'AuthorId',
    key: 'AuthorId',
    checked: false,
    type: 'assignment',
    formatter: 'AuthorName',
  },
  {
    title: 'Ответственный',
    dataIndex: 'ResponsibleId',
    key: 'ResponsibleId',
    checked: false,
    type: 'assignment',
    formatter: 'ResponsibleName',
  },
  {
    title: 'Исполнитель',
    dataIndex: 'ExecutorId',
    key: 'ExecutorId',
    checked: false,
    type: 'assignment',
    formatter: 'ExecutorName',
  },
  {
    title: 'Дата обновления',
    dataIndex: 'UpdateDate',
    key: 'UpdateDate',
    checked: false,
    type: 'assignment',
    formatter: 'date',
  },
  {
    title: 'Номер родительского задачи',
    dataIndex: 'ParentId',
    key: 'ParentId',
    checked: false,
    type: 'assignment',
  },
  {
    title: 'Дата исполнения',
    dataIndex: 'ExecutionTime',
    key: 'ExecutionTime',
    checked: false,
    type: 'assignment',
    formatter: 'date',
  },
];
