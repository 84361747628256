import { prefix } from 'api/prefix';
import axios, { AxiosRequestConfig } from 'axios';
import { errorThrow, successNotification } from 'shared/utils';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { CurrentDocument, TDocumentFile } from 'types/document';

export const DocumentFiles = ({
  currentDocument,
}: {
  currentDocument?: CurrentDocument | null;
}) => {
  const FILES_LENGTH = currentDocument?.Files?.length;
  const onDownloadFile = (type: 'all' | 'single', item?: TDocumentFile) => {
    const requestBlobHeader: AxiosRequestConfig = {
      headers: getAuthToken('auth_token') ?? '',
      responseType: 'blob',
    };

    const getLinkToDownload = (responseData: string, fileTitle?: string) => {
      const url = window.URL.createObjectURL(new Blob([responseData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}`);
      document.body.appendChild(link);
      link.click();
      successNotification('Файл скачан');
    };

    if (type === 'single') {
      axios
        .get(`${prefix}/Files/Download/${item?.Id}`, requestBlobHeader)
        .then(({ data }) => getLinkToDownload(data, item?.Name))
        .catch(({ response }) => errorThrow(response));
    } else {
      axios
        .get(
          `${prefix}/documents/${currentDocument?.Id}/Download/allfiles`,
          requestBlobHeader,
        )
        .then(({ data }) =>
          getLinkToDownload(
            data,
            `Файлы по документу №${currentDocument?.Id}.zip`,
          ),
        )
        .catch(({ response }) => errorThrow(response));
    }
  };

  return (
    <>
      <ul style={{ padding: 0, marginBottom: 0 }}>
        {currentDocument?.Files.length
          ? currentDocument?.Files.map(item => (
              <span
                key={item.Id}
                onClick={() => onDownloadFile('single', item)}
                className="ui-li"
              >
                — <span className="custom-link">{item.Name}</span> <br />
                <span></span>
              </span>
            ))
          : 'нет файлов'}
      </ul>
      {!!(FILES_LENGTH && FILES_LENGTH > 1) && (
        <span
          className="custom-link ml15"
          onClick={() => onDownloadFile('all')}
        >
          Скачать все
        </span>
      )}
    </>
  );
};
