import Draggable from 'react-draggable';
import {
  defaultRegNumberPosition,
  defaultStampPosition,
  stampDraggableProps,
} from '../constants';
import { StampTypes, TStampComponent } from '../types';
import { getRegNumberBounds, getStampBounds } from '../utils';

export const StampComponent = ({
  stampType,
  dragWrapperSize,
  stampXY,
  draggableEntity,
  onDragging,
  setStampXY,
  regXY,
  setRegXY,
}: TStampComponent) => {
  return (
    <>
      {stampType === StampTypes.Full || stampType === StampTypes.Stamp ? (
        <Draggable
          defaultPosition={defaultStampPosition}
          bounds={getStampBounds(dragWrapperSize)}
          position={stampXY}
          {...stampDraggableProps}
          ref={draggableEntity}
          onDrag={(_d, data) => onDragging(data, setStampXY, StampTypes.Stamp)}
        >
          <div id="drag-stamp">Штамп</div>
        </Draggable>
      ) : null}
      {stampType === StampTypes.Full || stampType === StampTypes.RegNumber ? (
        <Draggable
          defaultPosition={defaultRegNumberPosition}
          bounds={getRegNumberBounds(dragWrapperSize)}
          position={regXY}
          {...stampDraggableProps}
          ref={draggableEntity}
          onDrag={(_d, data) =>
            onDragging(data, setRegXY, StampTypes.RegNumber)
          }
        >
          <div id="drag-reg">Рег. номер и дата</div>
        </Draggable>
      ) : null}
    </>
  );
};
