import { ReportItem } from 'pages/MainPage/components/Report/components';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { ReduxState } from 'store/store';
import {
  MainPage,
  LoginPage,
  LoginPlug,
  OutcomingPage,
  InnerPage,
  IncomingPage,
  DocumentPage,
  SortPage,
  Contact,
  ExecutionAssignmentsPage,
  OfficerCard,
  CreateDocument,
  CheckElectronicSignature,
  AllAssignmentsPage,
  Archive,
} from 'pages';
import { FileSend, Report } from 'pages/MainPage/components';
import {
  Header,
  Sidebar,
  CreateReminder,
  ChangeReminder,
  TimeExtension,
  MarkExecution,
  ChangeHistory,
  CreateDocumentASample,
  CreateLinkedDocument,
  LinkToADocuments,
  LinkTOADocumentTable,
} from 'shared/components';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { authUser } from 'store/slice/auth';
import { updateCurrentItemId } from 'store/slice/table';
import { CreateRedirectPage } from 'shared/components/CreateRedirectPage';
import { Organization } from 'pages/SearchPages/Organization';
import {
  AssignmentInformation,
  DelegationOfExecution,
  RefundAssignment,
} from 'pages/AssignmentsPage';
import { OnRegistration } from './pages/OnRegistration';
import { ControlDocuments } from './pages/ControlDocuments';
import { StatisticsPage } from './pages/StatisticsPage';
import { FilteredDocumentsPage } from './pages/FilteredDocumentsPage';
import { Requisites } from 'pages/AdminPage/Requisites';
import { Departments } from 'pages/AdminPage/Departments';
import { Officers } from 'pages/AdminPage/Officers';
import { DocumentKinds } from 'pages/AdminPage/DocumentKinds';
import { DocumentFlow } from 'pages/AdminPage/DocumentFlow';
import { CounterParties } from 'pages/AdminPage/CounterParties';
import { Organisations, UserGroups } from 'pages/AdminPage';
import { Processes } from 'pages/AdminPage/Processes';
import { OrganisationProcessItem } from 'shared/components/CustomDrawers/OrganisationProcessItem';
import { DocumentRegistration } from 'pages/AdminPage/DocumentRegistration';
import { Nomenclatures } from 'pages/AdminPage/Nomenclatures';
import { ArchiveCaseByTome } from 'pages/Archive/components/ArchiveCaseByTome';
import { NomenclatureItem } from 'pages/Archive/components/NomenclatureItem';

export const Routes = () => {
  const { isAuth } = useSelector((state: ReduxState) => state.authReducer);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getAuthToken('auth_token')) {
      dispatch(authUser(true));
    }
  }, [dispatch, isAuth]);
  dispatch(updateCurrentItemId(location.pathname));

  if (!getAuthToken('auth_token')) {
    return (
      <Switch>
        <Route exact path="*">
          <Redirect push to="/login" />
          <Route path="/login" component={LoginPlug} />
        </Route>
      </Switch>
    );
  }
  const searchPagePath =
    location.pathname.split('/').length < 4
      ? '/searching/page=:requestId'
      : '/searching/:id?/page=:requestId';

  return (
    <div className="layout-inner">
      <Sidebar />
      <div className="layout-inner__content">
        <section className="page2">
          <div className="main-container">
            <Header />
            <Switch>
              <Redirect exact from="/" to="/active-documents/page=1" />
              <Route
                path="/active-documents/page=:requestId"
                exact
                component={MainPage}
              />
              {!location.pathname.includes('page') && (
                <Route
                  path={`/:id/:docType/:documentId`}
                  exact
                  component={DocumentPage}
                />
              )}
              <Route
                path={`/:id/on-registration/:docType/:documentId`}
                exact
                component={DocumentPage}
              />
              <Route
                path={`/:id/MyDocuments/:docType/:documentId`}
                exact
                component={DocumentPage}
              />
              <Route path={`/create`} exact component={CreateRedirectPage} />
              <Route
                path={`/create/:docType`}
                exact
                component={CreateDocument}
              />
              <Route
                path={`/:id/DocumentsOutcoming/page=:requestId`}
                exact
                component={OutcomingPage}
              />
              <Route path={`/admin/requisites`} exact component={Requisites} />
              <Route path={`/admin/officers`} exact component={Officers} />
              <Route
                path={`/admin/organisations`}
                exact
                component={Organisations}
              />
              <Route
                path={`/admin/departments`}
                exact
                component={Departments}
              />
              <Route
                path={`/admin/nomencaltures`}
                exact
                component={Nomenclatures}
              />
              <Route
                path={`/admin/counterparties`}
                exact
                component={CounterParties}
              />{' '}
              <Route path={`/admin/process`} exact component={Processes} />
              <Route
                path={`/admin/process/route/:routeId`}
                exact
                component={OrganisationProcessItem}
              />
              <Route
                path={`/admin/document-flow`}
                exact
                component={DocumentFlow}
              />
              <Route path={`/admin/usergroups`} exact component={UserGroups} />
              <Route
                path={`/admin/registration`}
                exact
                component={DocumentRegistration}
              />
              <Route
                path={`/admin/document-kinds`}
                exact
                component={DocumentKinds}
              />
              <Route
                path={`/:id/DocumentsIncoming/page=:requestId`}
                exact
                component={IncomingPage}
              />
              <Route
                path={`/:id/DocumentsInner/page=:requestId`}
                exact
                component={InnerPage}
              />
              <Route
                path={`/:id/on-registration/page=:requestId`}
                exact
                component={OnRegistration}
              />
              <Route
                path="/control-documents/page=:requestId"
                exact
                component={ControlDocuments}
              />
              <Route
                path="/execution-assignment"
                exact
                component={ExecutionAssignmentsPage}
              />
              <Route
                path="/execution-assignment/delegate"
                exact
                component={DelegationOfExecution}
              />
              <Route
                path="/execution-assignment/refund"
                exact
                component={RefundAssignment}
              />
              <Route
                path="/execution-assignment/information"
                exact
                component={AssignmentInformation}
              />
              <Route
                path="/officer-card/:officerId"
                exact
                component={OfficerCard}
              />
              <Route path="/send-file" exact component={FileSend} />
              <Route path="/reports" exact component={Report} />
              {/* <Route path="/reports/:reportId" exact component={ReportItem} /> */}
              <Route path="/assignments" exact component={AllAssignmentsPage} />
              <Route path="/sort-table" exact component={SortPage} />
              <Route path="/search-contacts" exact component={Contact} />
              <Route
                path={searchPagePath}
                exact
                component={FilteredDocumentsPage}
              />
              <Route
                path="/search-organization"
                exact
                component={Organization}
              />
              <Route path="/create-reminder" exact component={CreateReminder} />
              <Route path="/change-reminder" exact component={ChangeReminder} />
              <Route path="/time-extension" exact component={TimeExtension} />
              <Route path="/mark-execution" exact component={MarkExecution} />
              <Route path="/history-change" exact component={ChangeHistory} />
              {/* узанть о наобности */}
              <Route
                path="/create-linked-document"
                exact
                component={CreateLinkedDocument}
              />
              <Route
                path="/link-to-a-document"
                exact
                component={LinkToADocuments}
              />
              <Route
                path="/link-to-a-document-table"
                exact
                component={LinkTOADocumentTable}
              />
              <Route
                path="/create-a-sample-document"
                exact
                component={CreateDocumentASample}
              />
              <Route
                path="/check-electronic-signature"
                exact
                component={CheckElectronicSignature}
              />
              <Route path="/statistics" exact component={StatisticsPage} />
              <Route path="/archive" exact component={Archive} />
              <Route
                path="/archive/:tomeId"
                exact
                component={ArchiveCaseByTome}
              />{' '}
              <Route
                path="/archive/:tomeId/archive-case/:archiveCase"
                exact
                component={NomenclatureItem}
              />
            </Switch>
          </div>
        </section>
        <Switch>
          <Route path="/reports/:reportId" exact component={ReportItem} />
        </Switch>
      </div>
    </div>
    // </div>
  );
};
