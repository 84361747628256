import { Select, Skeleton } from 'antd';
import { PageHeader } from 'shared/components/PageHeader';
import { DocumentTypeItem } from './components';
import { CREATE_DOCUMENT_PAGE } from 'shared/constants/helpCodes';
import { useCreateRedirectPage } from './useCreateRedirectPage';
import './CreateRedirectPage.scss';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';

export const CreateRedirectPage = () => {
  const {
    isLoading,
    treeList,
    onSelect,
    updateItem,
    pageTitle,
    setSelectedOrganisation,
    organisationList,
    canShowOrganisations,
    fetchData,
  } = useCreateRedirectPage();

  return (
    <section id="document_types">
      <PageHeader title={pageTitle} code={CREATE_DOCUMENT_PAGE} />
      {isLoading ? (
        <Skeleton
          className="skeleton"
          title={false}
          paragraph={{ rows: 15 }}
          active
        />
      ) : (
        <div>
          {canShowOrganisations && (
            <DFormItemTwo label="Организация" className="redirect-select">
              <Select
                className="ui-select"
                showSearch
                style={{ width: 400 }}
                allowClear
                optionFilterProp="children"
                onChange={value => setSelectedOrganisation(value)}
              >
                {organisationList?.map(item => (
                  <Select.Option value={item.Id} key={item.Id}>
                    {item.Name}
                  </Select.Option>
                ))}
              </Select>
            </DFormItemTwo>
          )}

          <div className="redirect flex-space">
            {treeList.map(item => (
              <DocumentTypeItem
                item={item}
                onSelect={onSelect}
                updateItem={updateItem}
                fetchData={fetchData}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};
