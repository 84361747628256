import { findParent } from 'api/assignments/fetchAssignmentsData';
import { NomenclatureParams } from 'pages/AssignmentCard/types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DButton } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { getDataAfterToday, getDataBeforeToday } from './utils';

export const useNomenclatures = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nomenclatureList, setNomenclatureList] = useState<
    NomenclatureParams[]
  >([]);
  const [defaultList, setDefaultList] = useState<NomenclatureParams[]>([]);
  const [archiveList, setArchiveList] = useState<NomenclatureParams[]>([]);
  const [isArchive, setIsArchive] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [
    currentNomenclature,
    setCurrentNomenclature,
  ] = useState<NomenclatureParams | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleUpload, setVisibleUpload] = useState<boolean>(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  const getData = useCallback(() => {
    setIsLoading(true);
    if (user?.CurrentContact?.OrganisationId) {
      $api
        .get(
          `nomenclatures/byorganization/${user?.CurrentContact?.OrganisationId}`,
          {
            params: {
              Limit: 100000,
            },
          },
        )
        .then(({ data }) => {
          const archiveNomenclatures = data.filter(getDataBeforeToday);
          const relevantNomenclatures = findParent(
            data.filter(getDataAfterToday),
          );
          setArchiveList(archiveNomenclatures);
          setNomenclatureList(relevantNomenclatures);
          setDefaultList(relevantNomenclatures);
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    }
  }, [user?.CurrentContact?.OrganisationId]);

  const onDelete = (id: number) => {
    $api
      .delete(`nomenclatures/${id}/byorganization`)
      .then(() => getData())
      .catch(({ response }) => errorThrow(response));
  };

  useEffect(() => getData(), [getData]);

  const onGenerateStructure = () => {
    $api
      .patch(`nomenclatures/orderbycode`, {
        OrganisationId: user?.CurrentContact?.OrganisationId,
      })
      .then(getData)
      .catch(({ response }) => errorThrow(response));
  };

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
      width: 300,
    },
    {
      title: 'Код',
      dataIndex: 'Code',
      key: 'Code',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (_d: undefined, item: NomenclatureParams) => (
        <>
          <DButton
            small
            defaultPrimary
            className="mr15"
            onClick={() => {
              setCurrentNomenclature(item);
              setVisible(true);
              setType('edit');
            }}
          >
            Редактировать
          </DButton>
          <DButton small defaultDanger onClick={() => onDelete(item.Id)}>
            Удалить
          </DButton>
        </>
      ),
    },
  ];

  const onSearch = (value: string) => {
    if (value) {
      const newList = defaultList?.filter(item =>
        item?.Name?.toLowerCase()?.includes(value.toLowerCase()),
      );
      setNomenclatureList(newList);
    } else {
      setNomenclatureList(defaultList);
    }
  };

  const onChangeVisibleDrawer = (visible: boolean) => {
    setVisible(visible);
    setType('create');
    setCurrentNomenclature(null);
  };

  return {
    onChangeUploadVisible: (visible: boolean) => setVisibleUpload(visible),
    visibleUpload,
    getData,
    isLoading,
    onSearch,
    columns,
    visible,
    currentNomenclature,
    type,
    updateTableType: () => setIsArchive(!isArchive),
    archiveList,
    nomenclatureList,
    isArchive,
    onChangeVisibleDrawer,
    onGenerateStructure,
  };
};
