import { Drawer, Select, Switch } from 'antd';
import {
  createArchiveCase,
  createArchiveCasesByTomeNumber,
  editArchiveCaseById,
  getArchiveCaseById,
} from 'api';
import { useFormik } from 'formik';
import { ExpirationDates } from 'pages/Archive/constants';
import {
  ArchiveCaseDrawerForm,
  ArchiveCaseDrawerType,
  ArchiveItem,
} from 'pages/Archive/type';
import { getYearsByCurrent } from 'pages/Archive/utils';
import { NomenclatureParams } from 'pages/AssignmentCard/types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { PreLoader } from 'shared/components/PreLoader';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import './ArchiveCaseDrawer.scss';

export const ArchiveCaseDrawer = ({
  visible,
  onClose,
  onUpdateData,
  drawerType,
  type,
}: ArchiveCaseDrawerType) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nomenclatureList, setNomenclatureList] = useState<
    NomenclatureParams[]
  >([]);
  const [
    currentArchiveCase,
    setCurrentArchiveCase,
  ] = useState<ArchiveItem | null>(null);
  const [withoutNomenclature, setWithoutNomenclature] = useState(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { archiveCase: archiveCaseId } = useParams<{
    archiveCase: string;
    tomeId: string;
  }>();
  const history = useHistory();
  const drawerTitle = type === 'create' ? 'Создание' : 'Редактирование';

  const fetchNomenclatureList = useCallback(() => {
    $api
      .get(
        `nomenclatures/byorganization/${user?.CurrentContact?.OrganisationId}`,
        {
          params: {
            Limit: 100000,
          },
        },
      )
      .then(({ data }) => setNomenclatureList(data))
      .catch(({ response }) => errorThrow(response));
  }, [user?.CurrentContact?.OrganisationId]);

  useEffect(() => {
    if (archiveCaseId && type === 'edit') {
      getArchiveCaseById(Number(archiveCaseId)).then(archiveCase => {
        setCurrentArchiveCase(archiveCase);
        formik.setFieldValue('NomenclatureId', archiveCase.NomenclatureId);
        formik.setFieldValue(
          'ExpirationDateType',
          archiveCase.ExpirationDateType ?? 'Unknown',
        );
        formik.setFieldValue('TomeNumber', archiveCase.TomeNumber);
        formik.setFieldValue('ListCount', archiveCase.ListCount);
        formik.setFieldValue('Name', archiveCase.Name);
      });
    }
  }, [archiveCaseId, type]);

  useEffect(() => {
    fetchNomenclatureList();
  }, [fetchNomenclatureList]);

  const onSubmit = (values: Partial<ArchiveCaseDrawerForm>) => {
    setIsLoading(true);

    if (type === 'create') {
      const archiveCasePromise =
        drawerType === 'single'
          ? createArchiveCase({
              ...values,
              OrganisationId: user?.CurrentContact?.OrganisationId,
            })
          : createArchiveCasesByTomeNumber({
              ...values,
            });

      archiveCasePromise
        .then(() => {
          onUpdateData?.();
          onClose();
        })
        .finally(() => setIsLoading(false));
    } else {
      editArchiveCaseById(Number(archiveCaseId), {
        ...values,
        OrganisationId: user?.CurrentContact?.OrganisationId,
      })
        .then(() => {
          history.push(
            `/archive/${values.TomeNumber}/archive-case/${archiveCaseId}`,
          );
          onClose();
          onUpdateData?.();
        })
        .finally(() => setIsLoading(false));
    }
  };

  const formik = useFormik({
    initialValues: {
      NomenclatureId: currentArchiveCase?.NomenclatureId,
      ExpirationDateType: currentArchiveCase?.ExpirationDateType ?? 'Unknown',
      TomeNumber: currentArchiveCase?.TomeNumber,
      ListCount: currentArchiveCase?.ListCount,
      Name: currentArchiveCase?.Name,
      Code: currentArchiveCase?.Code,
    },
    onSubmit,
  });

  return (
    <>
      {isLoading && <PreLoader />}
      <Drawer
        placement="right"
        onClose={() => onClose()}
        visible={visible}
        width="70%"
        className="drawer"
      >
        <div className="page3">
          <DrawerHeader onClose={onClose} text={`${drawerTitle} дела`} />
          <form onSubmit={formik.handleSubmit}>
            {drawerType === 'single' && (
              <div className="mb15 drawer-switcher">
                <span className="mr5">С номенклатурой</span>
                <Switch
                  onChange={() => setWithoutNomenclature(!withoutNomenclature)}
                  checked={withoutNomenclature}
                />
                <span className="ml5">Без номенклатуры</span>
              </div>
            )}
            {drawerType === 'single' &&
              (!withoutNomenclature ? (
                <DFormItemTwo label="Номенклатура" name="NomenclatureId">
                  <Select
                    value={formik.values.NomenclatureId}
                    className="ui-select"
                    showSearch
                    optionFilterProp="children"
                    onChange={value => {
                      const currentNomenclature = nomenclatureList.find(
                        nomenclature => nomenclature.Id === value,
                      );

                      formik.setFieldValue('NomenclatureId', value);
                      formik.setFieldValue(
                        'ExpirationDateType',
                        currentNomenclature?.ArchiveExpirationDateType,
                      );
                    }}
                  >
                    {nomenclatureList?.map(item => (
                      <Select.Option value={item.Id} key={item.Id}>
                        {item.Code} {item.Name}
                      </Select.Option>
                    ))}
                  </Select>
                </DFormItemTwo>
              ) : (
                <>
                  <DFormItemTwo label="Номенклатура" name="Name">
                    <DInput
                      width="100%"
                      type="text"
                      value={formik.values.Name}
                      name="Name"
                      onChange={formik.handleChange}
                    />
                  </DFormItemTwo>
                  <DFormItemTwo label="Код" name="Code">
                    <DInput
                      width="100%"
                      type="text"
                      value={formik.values.Code}
                      name="Code"
                      onChange={formik.handleChange}
                    />
                  </DFormItemTwo>
                </>
              ))}
            <DFormItemTwo label="Номер тома" name="TomeNumber">
              <Select
                value={formik.values.TomeNumber}
                className="ui-select"
                showSearch
                optionFilterProp="children"
                onChange={value => formik.setFieldValue('TomeNumber', value)}
              >
                {getYearsByCurrent(2100)?.map(item => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </DFormItemTwo>
            <DFormItemTwo label="Срок хранения" name="ExpirationDateType">
              <Select
                value={formik.values.ExpirationDateType}
                className="ui-select"
                showSearch
                optionFilterProp="children"
                onChange={value =>
                  formik.setFieldValue('ExpirationDateType', value)
                }
              >
                {ExpirationDates?.map(item => (
                  <Select.Option value={item.type} key={item.type}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </DFormItemTwo>
            <DFormItemTwo className="form-buttons">
              <DButton small primary className="mr15" type="submit">
                {type === 'create' ? 'Создать' : 'Редактировать'}
              </DButton>
            </DFormItemTwo>
          </form>
        </div>
      </Drawer>
    </>
  );
};
