import { Table, Tooltip } from 'antd';
import { AssignmentType } from 'types/document';
import certificateSvg from 'shared/assets/images/certificate.svg';
import { selectActiveAssignment } from 'store/slice/assignments';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AssignmentDrawer, OfficerDrawer } from 'shared/components';
import {
  getAssignmentExecutorName,
  selectAssignmentType,
  getFullName,
} from 'shared/utils';
import { dateCompare } from 'shared/utils/dateCompare';
import { getSlicedText } from 'shared/utils';
import './AssignmentTable.scss';
import { useBem } from 'shared/hooks';
import { AssignmentStatus, TAssignmentTable } from './types';
import moment from 'moment';
import { Icon } from 'shared/components/IconComponent';
import fileSvg from 'shared/assets/images/file.svg';
import { DepartmentInfoDrawer } from 'shared/components/CustomDrawers/DepartmentInfoDrawer';
import { DTag } from 'shared/ui-kit/DTag';

export const AssignmentTable = ({ assignments, isPrint }: TAssignmentTable) => {
  const bem = useBem('AssignmentTable');
  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [selectedDepartment, setSelectedDepartment] = useState<number>(0);
  const [visibleAssignmentDrawer, setVisibleAssignmentDrawer] = useState<
    boolean
  >(false);
  const [visibleOfficerDrawer, setVisibleOfficerDrawer] = useState<boolean>(
    false,
  );
  const [visibleDepartmentDrawer, setVisibleDepartmentDrawer] = useState<
    boolean
  >(false);
  const dispatch = useDispatch();

  const onCloseOfficerDrawer = (bool: boolean) => setVisibleOfficerDrawer(bool);

  const onCloseAssignmentDrawer = (bool: boolean) => {
    dispatch(selectActiveAssignment(0));
    setVisibleAssignmentDrawer(bool);
  };

  const assignmentsColumns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
      render: (text: string, item: AssignmentType) => (
        <span className="assignmentId">{item.Id}</span>
      ),
    },
    {
      title: (
        <Icon icon={fileSvg} alt="fileSvg" tooltip="Прикрепленные файлы" />
      ),
      dataIndex: 'DocumentSignatureId',
      key: 'DocumentSignatureId',
      render: (text: string, item: AssignmentType) =>
        item?.IsExistFiles ? <Icon icon={fileSvg} alt="fileSvg" /> : null,
    },
    {
      title: (
        <Icon
          icon={certificateSvg}
          alt="certificateSvg"
          tooltip="Электронная подпись"
        />
      ),
      dataIndex: 'DocumentSignatureId',
      key: 'DocumentSignatureId',
      render: (text: string, item: AssignmentType) =>
        item?.IsExistSignatures && (
          <Icon icon={certificateSvg} alt="certificateSvg" />
        ),
    },
    {
      title: 'Задача',
      dataIndex: 'Subject',
      key: 'Subject',
      render: (text: string, assignment: AssignmentType) => (
        <>
          <span
            className="custom-link"
            onClick={() => {
              setVisibleAssignmentDrawer(true);
              dispatch(selectActiveAssignment(assignment.Id));
            }}
          >
            {assignment.Subject}
          </span>{' '}
          {assignment.Content && (
            <Tooltip title={assignment.Content}>
              <span>({getSlicedText(assignment.Content)})</span>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Автор',
      dataIndex: 'AuthorId',
      key: 'AuthorId',
      render: (text: string, assignment: AssignmentType) => (
        <span
          className="subject"
          onClick={() => {
            setVisibleOfficerDrawer(true);
            setSelectedUser(assignment.AuthorId);
          }}
        >
          {getFullName({
            lastName: assignment.AuthorLastName,
            firstName: assignment.AuthorFirstName,
            middleName: assignment.AuthorMiddleName,
          })}
        </span>
      ),
    },
    {
      title: 'Исполнитель',
      dataIndex: 'Executor',
      key: 'Executor',
      render: (text: string, assignment: AssignmentType) => (
        <>
          <span
            className="subject"
            onClick={() => {
              if (assignment?.ExecutorId) {
                setVisibleOfficerDrawer(true);
                setSelectedUser(assignment.ExecutorId);
              } else if (assignment.ExecutionGroupId) {
                setSelectedDepartment(assignment?.ExecutionGroupId);
                setVisibleDepartmentDrawer(true);
              }
            }}
          >
            {getAssignmentExecutorName(assignment)}
          </span>
          {assignment?.ExecutorSubstitutionId ? (
            <span
              className="subject"
              onClick={() => {
                setVisibleOfficerDrawer(true);
                setSelectedUser(assignment.ExecutorSubstitutionId);
              }}
            >
              (
              {getFullName({
                lastName: assignment.ExecutorSubstitutionLastName,
                firstName: assignment.ExecutorSubstitutionFirstName,
                middleName: assignment.ExecutorSubstitutionMiddleName,
              })}
              )
            </span>
          ) : null}
        </>
      ),
    },
    {
      title: 'Срок исполнения',
      dataIndex: 'ExecutionTime',
      key: 'ExecutionTime',
      render: (
        text: string,
        { ExecutionTime, ExecutionFactTime }: AssignmentType,
      ) => (
        <span className={dateCompare(ExecutionTime, ExecutionFactTime)}>
          {moment(ExecutionTime).format('DD.MM.YYYY HH:mm')}
        </span>
      ),
    },
    {
      title: 'Фактическая дата исполнения',
      dataIndex: 'ExecutionFactTime',
      key: 'ExecutionFactTime',
      render: (text: string, { ExecutionFactTime }: AssignmentType) =>
        ExecutionFactTime
          ? moment(ExecutionFactTime).format('DD.MM.YYYY HH:mm')
          : '-',
    },
    {
      title: 'Резолюция/Комментарий',
      dataIndex: 'Resolution',
      key: 'Resolution',
      render: (text: string) => (
        <Tooltip title={text}>
          <span className={bem('resolution-text')}>
            {!isPrint ? text && getSlicedText(text) : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Тип',
      dataIndex: 'Type',
      key: 'Type',
      render: (text: string, item: AssignmentType) => (
        <div>
          <span>{selectAssignmentType(text)}</span>
          {item.State === 'Draft' && (
            <DTag className="tag-subject" type="small">
              Черновик
            </DTag>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={bem()}>
      {visibleDepartmentDrawer && (
        <DepartmentInfoDrawer
          visible={visibleDepartmentDrawer}
          onClose={() => setVisibleDepartmentDrawer(false)}
          departmentId={selectedDepartment}
        />
      )}
      {visibleAssignmentDrawer && (
        <AssignmentDrawer
          visibleDrawer={visibleAssignmentDrawer}
          onCloseDrawer={onCloseAssignmentDrawer}
        />
      )}
      {visibleOfficerDrawer && (
        <OfficerDrawer
          visibleDrawer={visibleOfficerDrawer}
          onCloseDrawer={onCloseOfficerDrawer}
          officerId={selectedUser}
        />
      )}
      <Table
        className={isPrint ? bem('container-at-print') : ''}
        rowClassName={record => AssignmentStatus[record.State]}
        pagination={{
          position: ['bottomRight'],
          defaultCurrent: 1,
          defaultPageSize: 100,
          hideOnSinglePage: true,
        }}
        expandable={{ defaultExpandAllRows: true }}
        dataSource={assignments}
        columns={assignmentsColumns}
      />
    </div>
  );
};
