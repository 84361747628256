export type TDocumentKindList = {
  Name: string;
  ParentId: null | number;
  DocumentType: string;
  IsMedo: boolean;
  Id: number;
  title?: string;
};

export type TnameStateDocuments = {
  Name: string;
  State: string;
};

export type TDatePresets = {
  registration_date?: moment.Moment[];
  create_date: moment.Moment[];
  ExecutionTime: moment.Moment[];
  ProceedRegistrationDate: moment.Moment[];
};

export type TContactType =
  | 'AuthorId'
  | 'ExecutorId'
  | 'CorrespondentId'
  | 'AddresseeId'
  | 'ControllerId'
  | 'OrganisationReceiverId'
  | 'SenderId';

export type InitialValuesKeys =
  | 'registration_date'
  | 'ExecutionTime'
  | 'ProceedRegistrationDate'
  | 'create_date';

export type SearchByTypes = 'WithByName' | 'WithBySummary';

export type TInitialValues = {
  AuthorId?: number;
  AddresseeId?: number;
  CorrespondentId?: number;
  ControllerId?: number;
  ExecutorId?: number;
  OrgNomenclatureId?: number;
  ControlStatus?: string;
  DocumentKindId?: number;
  State?: string;
  ExecutionTime?: moment.Moment[];
  ProceedRegistrationDate?: moment.Moment[];
  registration_date?: [moment.Moment | undefined, moment.Moment];
  create_date?: moment.Moment[];
  RegNumber?: string;
  InRegNumber?: string;
  DocumentContain?: string;
  DocumentId?: string;
  DocumentType?: 'Incoming' | 'Outcoming' | 'Inner';
  searchBy?: SearchByTypes[];
  TagIds?: number[] | null;
  StartControlDate?: string;
  EndControlDate?: string;
  EndInRegDate?: string;
  StartInRegDate?: string;
  StartRegDate?: string;
  EndRegDate?: string;
  StartCreateDate?: string;
  EndCreateDate?: string;
  WithByName?: boolean;
  WithBySummary?: boolean;
  SenderId?: number;
  SendTypeId?: number;
  OrganisationReceiverId?: number;
};

export type SendTypes = {
  SendTypeName: string;
  OrganisationId: number;
  SendTypeId: number;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
};

export type TOrganisationsList = {
  Id: number;
  Name?: string;
  ParentName?: string;
  Type?: string;
  CreateDate?: string;
  UpdateDate?: string;
  SendTypeId?: number;
  SendTypeName?: string;
  Email?: string;
  key?: string;
  Address?: string;
  PhoneNumber?: string;
  Ogrn?: number;
  Inn?: number;
  Kpp?: number;
  IfnsCode?: number;
  IedoCode?: string;
  SendTypes?: SendTypes[];
  receiverGroup?: boolean;
};

export type TOrgNomenclature = {
  Id: number;
  Name: string;
  Code: string;
  Type: string;
};

export type TUserTagList = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  Name: string;
};

export type FilterDrawerTypes = {
  visible: boolean;
  onClose: (bool: boolean) => void;
  type: 'drawer' | 'window';
};

export enum SearchBySettings {
  BY_NAME = 'WithByName',
  BY_SUMMARY = 'WithBySummary',
}
