import { AssignmentType } from 'types/document';
import { getFullName } from './getFullName';

export const getAssignmentExecutorName = (assignment: AssignmentType) => {
  if (assignment?.ExecutorId !== null) {
    return getFullName({
      lastName: assignment.ExecutorLastName,
      firstName: assignment.ExecutorFirstName,
      middleName: assignment.ExecutorMiddleName,
    });
  }

  return assignment.ExecutionGroupName;
};
