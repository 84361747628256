import loaderImg from 'shared/assets/loader-img.png';
import './PreLoader.scss';

export const PreLoader = () => {
  const beforeImg = (
    <img className="preloader-image" src={loaderImg} alt="loader" />
  );

  return <div className="preloader">{beforeImg}</div>;
};
