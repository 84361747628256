import { Drawer } from 'antd';
import { FormikValues } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { DrawerHeader } from './DrawerHeader';

type TUnuseRegNumbers = {
  visible: boolean;
  onClose: () => void;
  nomenclatureId: number | null;
  formik: FormikValues;
};

export const UnuseRegNumbers = ({
  visible,
  onClose,
  nomenclatureId,
  formik,
}: TUnuseRegNumbers) => {
  const [regNumberList, setRegNumberList] = useState<string[]>([]);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  const getRegNumbers = useCallback(() => {
    $api
      .get('documents/availableregnumbers', {
        params: {
          OrganisationId: user?.CurrentContact?.OrganisationId,
        },
      })
      .then(res => setRegNumberList(res.data))
      .catch(({ response }) => errorThrow(response));
  }, [user?.CurrentContact?.OrganisationId]);

  const onSelect = (item: string) => {
    formik.setFieldValue('RegNUmberRequisite', item);
    onClose();
  };

  useEffect(() => getRegNumbers(), [getRegNumbers]);

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      className="drawer"
      closeIcon={null}
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Неиспользованные рег. номера" />
      </div>
      <ul>
        {regNumberList.map(item => (
          <span key={item} onClick={() => onSelect(item)} className="ui-li">
            — <span className="custom-link">{item}</span> <br />
            <span></span>
          </span>
        ))}
      </ul>
    </Drawer>
  );
};
