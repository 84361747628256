import { Drawer } from 'antd';
import React from 'react';
import { CreateDocument } from 'pages';
import { DrawerHeader } from './DrawerHeader';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { useHistory, useParams } from 'react-router-dom';

type TDocumentEditDrawer = {
  visible: boolean;
  onClose: (bool: boolean) => void;
};

export const DocumentEditDrawer = ({
  visible,
  onClose,
}: TDocumentEditDrawer) => {
  const { sampleSettings, documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const history = useHistory();
  const {
    documentId,
  }: {
    documentId?: string;
  } = useParams();

  const onCloseDrawer = (isSubmiting?: boolean) => {
    if (isSubmiting && !documentId) {
      history.push(
        `/active-documents/Documents${documentInfo?.Type}/${documentInfo?.Id}`,
      );
    }

    onClose(false);
  };

  return (
    <Drawer
      placement="right"
      onClose={() => onCloseDrawer()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        {!sampleSettings && (
          <DrawerHeader
            onClose={onClose}
            text="Редактирование документа"
            code="EDIT_DOCUMENT"
          />
        )}
        <CreateDocument onClose={onCloseDrawer} />
      </div>
    </Drawer>
  );
};
