import moment from 'moment';
import { useMainPage } from 'pages/MainPage/useMainPage';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DTag } from 'shared/ui-kit/DTag';
import {
  errorThrow,
  selectAssignmentType,
  successNotification,
} from 'shared/utils';
import { selectActiveAssignment } from 'store/slice/assignments';
import { PropsParams } from 'types/tableData';
import certificateSvg from 'shared/assets/images/certificate.svg';
import { ReduxState } from 'store/store';
import { formatStateName } from 'pages/DocumentPage/components/nameStateDocuments';
import { TFile } from './types';
import axios from 'axios';
import { prefix } from 'api/prefix';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { useHistory, useLocation } from 'react-router-dom';
import {
  updateASampleDocument,
  updateDocumentInfo,
} from 'store/slice/document';
import { updateDataCount } from 'store/slice/table';
import { TColumns } from 'types/columns';
import { AssignmentType, CurrentDocument } from 'types/document';
import { Icon } from '../IconComponent';
import fileSvg from 'shared/assets/images/file.svg';
import { sendingStates } from 'pages/DocumentPage/components/DocumentSendContext/DocumentSendContext';
import _ from 'lodash';

export const useMainTable = ({ data, match }: PropsParams) => {
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [clickableUser, setClickableUser] = useState<number | null>(0);
  const [visibleAssignment, setVisibleAssignment] = useState<boolean>(false);
  const [visibleDocumentEditDrawer, setVisibleDocumentEditDarwer] = useState<
    boolean
  >(false);
  const { openDocument } = useMainPage({ match });
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { tablePagination } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentLocation = location.pathname.split('/page=');

  const onCloseDrawer = (bool: boolean) => {
    setVisibleDrawer(bool);
  };

  const onCloseAssignmentDrawer = (bool: boolean) => {
    setVisibleAssignment(bool);
  };

  const formatterDate = (key: string, date: string) => {
    if (!date) return '-';

    if (key === 'CreateDate') {
      return moment(date).format('DD.MM.YYYY H:mm');
    }

    return moment(date).format('DD.MM.YYYY');
  };

  const formatterSender = (item: CurrentDocument) => {
    if (item.SendingContexts.length) {
      return <span>{item.SendingContexts[0].SenderName}</span>;
    }
  };

  const formatterReceiver = (item: CurrentDocument) => {
    if (item.SendingContexts.length > 1) {
      return <span>{item.SendingContexts.length}</span>;
    } else if (item.SendingContexts.length === 1) {
      return <span>{item.SendingContexts?.[0]?.OrganisationName}</span>;
    }
  };

  const formatterMedo = (item: CurrentDocument) => {
    if (item.SendingContexts.length) {
      return item.SendingContexts.map(context =>
        context.SendTypeId === 18 ? (
          <p>
            {sendingStates.find(item => item.State === context.State)?.Name}
          </p>
        ) : (
          <p>-</p>
        ),
      );
    } else {
      return <p>-</p>;
    }
  };

  const formatterName = (type: string, item: CurrentDocument) => {
    if (type === 'authorName') {
      return (
        <span
          className="subject"
          onClick={() => {
            setVisibleDrawer(true);
            setClickableUser(item.AuthorId);
          }}
        >
          {item.AuthorLastName} {item.AuthorFirstName} {item.AuthorMiddleName}
        </span>
      );
    }
    if (type === 'correspondentName') {
      return _.uniqBy(item?.SendingContexts, 'CorrespondentId')?.map(
        context => (
          <p
            className="subject"
            onClick={() => {
              setVisibleDrawer(true);
              setClickableUser(context.CorrespondentId);
            }}
          >
            {context.CorrespondentLastName} {context.CorrespondentFirstName}{' '}
            {context.CorrespondentMiddleName}
          </p>
        ),
      );
    }
    if (type === 'addresseeName') {
      return item?.SendingContexts?.map(context => (
        <p
          className="subject"
          onClick={() => {
            setVisibleDrawer(true);
            setClickableUser(context.AddresseeId);
          }}
        >
          {context.AddresseeLastName} {context.AddresseeFirstName}{' '}
          {context.AddresseeMiddleName}
        </p>
      ));
    }
  };

  const formatterControl = (item: CurrentDocument) => {
    const control = item?.Controls;

    if (control?.length) {
      if (control[0].Status === 'OutControl') return <b>К</b>;
      if (control[0].Status === 'InControl')
        return <b style={{ color: 'red' }}>К</b>;
    }
  };

  const formatterRegInfo = (item: CurrentDocument) => {
    if (item.RegDate) {
      return (
        <span>
          {item.RegNumber && `${item.RegNumber} от`}{' '}
          {moment(item.RegDate).format('DD.MM.YYYY')}
        </span>
      );
    }
    if (item.InRegNumber) {
      return <span>{item.InRegNumber}</span>;
    }
  };

  const onAssginmentClick = (
    item: CurrentDocument,
    assignment: AssignmentType,
  ) => {
    setVisibleAssignment(true);
    dispatch(selectActiveAssignment(assignment.Id));
    sessionStorage.setItem('documentId', String(item.Id));
    sessionStorage.setItem('documentType', `Documents${item.Type}`);
  };

  const formatterSubject = (item: CurrentDocument) => {
    return (
      <>
        <p className="custom-link" onClick={() => openDocument(item)}>
          {item.Subject ? item.Subject : 'Тема отсутствует'}
        </p>
        {item.Assignments?.map(assignment => (
          <DTag
            className="tag-title"
            type="small"
            onClick={() => onAssginmentClick(item, assignment)}
          >
            {selectAssignmentType(assignment.Type)}
          </DTag>
        ))}
      </>
    );
  };

  const formatterType = (item: CurrentDocument) => {
    if (item.Type === 'Inner') {
      return 'Внутренний';
    }
    if (item.Type === 'Outcoming') {
      return 'Исходящий';
    }
    if (item.Type === 'Incoming') {
      return 'Входящий';
    }
  };

  const formatterIcon = (type: string, row: CurrentDocument) => {
    if (type === 'files') {
      return row.Files?.length ? <Icon icon={fileSvg} alt="fileSvg" /> : null;
    }
  };

  const formatterHasEsign = (item: CurrentDocument) => {
    return (
      item.CorrectSignaturesIsExists && (
        <Icon icon={certificateSvg} alt="certificateSvg" />
      )
    );
  };

  const formatterDocumentKind = (item: CurrentDocument) => {
    return item.KindName ? <span>{item.KindName}</span> : '-';
  };

  const formatterControlDate = (item: CurrentDocument) => {
    const control = item?.Controls;
    return control.length ? (
      <span>{moment(control[0]?.ControlDate).format('DD.MM.YYYY')}</span>
    ) : (
      '-'
    );
  };

  const formatterInRegNumber = (item: CurrentDocument) => {
    const regNumber = item?.SendingContexts?.[0]?.RegNumber;
    return regNumber ? <span>{regNumber}</span> : '-';
  };

  const formatterAuthorOrganisation = (item: CurrentDocument) => {
    return <span>{item.AuthorOrganisationName}</span>;
  };

  const formatterState = (item: CurrentDocument) => {
    if (item.State === 'Rework' && item.AuthorId === user?.CurrentContact?.Id)
      return (
        <div
          className="rework-tag"
          onClick={() => {
            dispatch(updateDocumentInfo(item));
            setVisibleDocumentEditDarwer(true);
          }}
        >
          <span>{formatStateName(item.State)}</span>
        </div>
      );

    return formatStateName(item.State);
  };

  const onDownloadFile = (item: TFile) => {
    axios
      .get(`${prefix}/Files/Download/${item.Id}`, {
        headers: getAuthToken('auth_token'),
        responseType: 'blob',
      })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${item.Name}`);
        document.body.appendChild(link);
        link.click();
        successNotification('Файл скачан');
      })
      .catch(({ response }) => errorThrow(response));
  };

  const onChangePagination = useCallback(
    (currentPage: number, pageSize: number) => {
      dispatch(
        updateDataCount({
          totalCount: tablePagination?.totalCount,
          page: currentPage,
          pageSize: pageSize,
        }),
      );
      history.push(`${currentLocation[0]}/page=${currentPage}`);
    },
    [currentLocation, dispatch, history, tablePagination?.totalCount],
  );

  useEffect(() => {
    dispatch(updateDocumentInfo(null));
    dispatch(updateASampleDocument(undefined));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      updateDataCount({
        totalCount: data?.filteredCount,
        page: Number(currentLocation[1]) ?? 1,
        pageSize: tablePagination?.pageSize,
      }),
    );
  }, [location.pathname, dispatch, tablePagination?.pageSize]);

  const renderColumn = (
    column: TColumns,
    text: string,
    record: CurrentDocument,
  ) => {
    let matchingFormatter;
    switch (column.formatter) {
      case 'date': {
        matchingFormatter = formatterDate(column.key, text);
        break;
      }
      case 'authorName': {
        matchingFormatter = formatterName('authorName', record);
        break;
      }
      case 'correspondentName': {
        matchingFormatter = formatterName('correspondentName', record);
        break;
      }
      case 'addresseeName': {
        matchingFormatter = formatterName('addresseeName', record);
        break;
      }
      case 'subject': {
        matchingFormatter = formatterSubject(record);
        break;
      }
      case 'files': {
        matchingFormatter = formatterIcon('files', record);
        break;
      }
      case 'state': {
        matchingFormatter = formatterState(record);
        break;
      }
      case 'RegInfo': {
        matchingFormatter = formatterRegInfo(record);
        break;
      }
      case 'type': {
        matchingFormatter = formatterType(record);
        break;
      }
      case 'HasESign': {
        matchingFormatter = formatterHasEsign(record);
        break;
      }
      case 'SysControl': {
        matchingFormatter = formatterControl(record);
        break;
      }
      case 'SenderName': {
        matchingFormatter = formatterSender(record);
        break;
      }
      case 'ReceiverName': {
        matchingFormatter = formatterReceiver(record);
        break;
      }
      case 'Medo': {
        matchingFormatter = formatterMedo(record);
        break;
      }
      case 'DocumentKind': {
        matchingFormatter = formatterDocumentKind(record);
        break;
      }
      case 'InRegNumber': {
        matchingFormatter = formatterInRegNumber(record);
        break;
      }
      case 'AuthorOrganisation': {
        matchingFormatter = formatterAuthorOrganisation(record);
        break;
      }
      case 'ControlDate': {
        matchingFormatter = formatterControlDate(record);
        break;
      }
      default: {
        matchingFormatter = text ?? '-';
        break;
      }
    }
    return matchingFormatter;
  };

  return {
    visibleDrawer,
    clickableUser,
    visibleAssignment,
    onCloseDrawer,
    onCloseAssignmentDrawer,
    onDownloadFile,
    onChangePagination,
    renderColumn,
    visibleDocumentEditDrawer,
    onCloseDocumentEditDrawer: (visible: boolean) =>
      setVisibleDocumentEditDarwer(visible),
  };
};
