import { AxiosResponse } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { formatStateName } from 'pages/DocumentPage/components/nameStateDocuments';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { typeNames } from 'shared/components/CustomDrawers/FilterDrawer/constants';
import { formatConfigObject } from 'shared/components/CustomDrawers/FilterDrawer/utils';
import { errorThrow, getIsComma } from 'shared/utils';
import { $api } from 'shared/utils/api';
import {
  loadingStatus,
  TSelectedFilter,
  updateFilteredDocuments,
  updateSelectedFilter,
  updateTableType,
  updateVisibleSetting,
} from 'store/slice/table';
import { ReduxState } from 'store/store';
import { settingsName, TSettingsName } from './constants';
import {
  convertData,
  formatStatusName,
  getQueryByTags,
  IsJsonString,
} from './utils';

export const useFilteredDocumentPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { filteredDocuments, tablePagination, selectedFilter } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const [settings, setSettings] = useState<TSettingsName[]>();
  const dispatch = useDispatch();
  const location = useLocation();

  const filterId = Number(location.pathname.split('/')[2]) ?? null;
  const SETTING_KEYS = filteredDocuments.config
    ? Object.keys(filteredDocuments.config)
    : null;

  const onClose = (bool: boolean) => {
    dispatch(updateVisibleSetting(bool));
  };

  const getDocumentsByFilter = useCallback(
    (pagination: any, userFilter: any) => {
      const getFilterTags = () => {
        if (filterId && IsJsonString(userFilter?.FilterData)) {
          return JSON.parse(userFilter?.FilterData)?.TagIds;
        }

        return filteredDocuments?.config.TagIds;
      };

      const getFilterRequest = (config: any, tagParams: string) => {
        return $api.get(
          `Documents/filter?${tagParams}${formatConfigObject(config)}`,
          {
            params: {
              ...config,
              Page: pagination.page && pagination.page - 1,
              Limit: pagination.pageSize,
            },
          },
        );
      };
      const filterTags: number[] = getFilterTags();
      const tagsQuery = getQueryByTags(filterTags);
      if (userFilter) {
        dispatch(updateSelectedFilter(userFilter));

        getFilterRequest(JSON.parse(userFilter?.FilterData), tagsQuery)
          .then(res => {
            dispatch(
              updateFilteredDocuments({
                data: convertData(res.data.Data),
                config: JSON.parse(userFilter?.FilterData),
                filteredCount: res.data.FilteredCount,
              }),
            );
          })
          .catch(({ response }) => errorThrow(response))
          .finally(() => dispatch(loadingStatus(false)));
      } else {
        getFilterRequest(filteredDocuments?.config, tagsQuery)
          .then(res => {
            dispatch(
              updateFilteredDocuments({
                data: convertData(res.data.Data),
                config: filteredDocuments?.config,
                filteredCount: res.data.FilteredCount,
              }),
            );
          })
          .catch(({ response }) => errorThrow(response))
          .finally(() => dispatch(loadingStatus(false)));
      }
    },
    [dispatch, filterId, filteredDocuments?.config],
  );

  const getUserFilters = useCallback(
    pagination => {
      dispatch(loadingStatus(true));
      $api
        .get('documentfilters/byuser', {
          params: {
            IsShowSideBar: true,
          },
        })
        .then(res => {
          const currentFilter = res.data.find(
            (item: TSelectedFilter) => item.Id === Number(filterId),
          );
          getDocumentsByFilter(pagination, currentFilter);
        });
    },
    [dispatch, filterId, getDocumentsByFilter],
  );

  const getSettingRequests = useCallback(() => {
    setIsLoading(true);
    const requests: Promise<void>[] = [];
    settingsName.filter(item =>
      SETTING_KEYS?.some(key => {
        if (
          item.setting === key &&
          item.isReceived &&
          filteredDocuments.config[`${item.setting}`]
        ) {
          if (item.formatter === 'DocumentKinds') {
            requests.push(
              $api
                .get(
                  `${item.formatter}/${
                    filteredDocuments.config[`${item.setting}`]
                  }/byorganization`,
                )
                .then(({ data }) => (item.value = data.Name)),
            );
          }
          if (item.formatter === 'TagIds') {
            item.value = '';
            const arrayOfTags = filteredDocuments.config[`${item.setting}`];
            const tagRequests: Promise<
              AxiosResponse
            >[] = arrayOfTags.map((tagId: number) =>
              $api.get(`Documents/${tagId}/Tags/${tagId}`),
            );
            requests.push(
              Promise.all(tagRequests).then(res => {
                tagRequests.forEach(
                  (_d: Promise<AxiosResponse>, index: number) => {
                    const { Name } = res[index].data;

                    item.value += `${Name}${getIsComma(tagRequests, index)} `;
                  },
                );
              }),
            );
          } else {
            const arrayOfIds = filteredDocuments.config[`${item.setting}`];
            if (Array.isArray(arrayOfIds)) {
              item.value = '';
              const arrayRequests = arrayOfIds.map((itemId: number) => {
                if (!Array.isArray(itemId))
                  return $api.get(`${item.formatter}/${itemId}`);
              });
              requests.push(
                Promise.all(arrayRequests)
                  .then(res => {
                    arrayRequests.forEach((_d: any, index: number) => {
                      if (item.formatter !== 'Contacts') {
                        // @ts-ignore
                        item.value += `${res[index].data.Name}${getIsComma(
                          arrayRequests,
                          index,
                        )} `;
                      } else {
                        // @ts-ignore
                        item.value += `${res[index].data.LastName ?? ''} ${res[
                          index
                        ]?.data.FirstName ?? ''} ${res[index]?.data
                          .MiddleName ?? ''}${getIsComma(
                          arrayRequests,
                          index,
                        )} `;
                      }
                    });
                  })
                  .catch(err => console.log('err', err)),
              );
            } else {
              requests.push(
                $api
                  .get(
                    `${item.formatter}/${
                      filteredDocuments.config[`${item.setting}`]
                    }`,
                  )
                  .then(({ data }) => {
                    if (item.formatter !== 'Contacts') {
                      item.value = data.Name;
                    } else {
                      item.value = `${data.LastName ?? ''} ${data.FirstName ??
                        ''} ${data.MiddleName ?? ''}`;
                    }
                  }),
              );
            }
          }
          return true;
        }
        return false;
      }),
    );
    return requests;
  }, [SETTING_KEYS, filteredDocuments.config]);

  const getTagData = () => {
    Promise.all(getSettingRequests())
      .catch(({ response }) => errorThrow(response))
      .finally(() => {
        const arr = settingsName.filter(item =>
          SETTING_KEYS?.some(key => {
            if (
              item.setting === key &&
              filteredDocuments.config[`${item.setting}`]
            ) {
              switch (item.formatter) {
                case 'date': {
                  item.value = moment(
                    new Date(filteredDocuments.config[`${item.setting}`]),
                  ).format('DD.MM.YYYY');
                  setIsLoading(false);
                  break;
                }
                case 'state': {
                  item.value = formatStateName(
                    filteredDocuments.config[`${item.setting}`],
                  )[0];
                  break;
                }
                case 'status': {
                  item.value = formatStatusName(
                    filteredDocuments.config[`${item.setting}`],
                  );
                  break;
                }
                case null: {
                  item.value = filteredDocuments.config[`${item.setting}`];
                  break;
                }
                case 'documentType': {
                  item.value =
                    typeNames[filteredDocuments.config[`${item.setting}`]];
                  break;
                }
                case 'bool': {
                  item.value = filteredDocuments.config['DocumentContain'];
                }
              }
              return true;
            }
            return false;
          }),
        );
        setSettings(arr.filter(item => item.value));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      !filterId &&
      location.pathname === '/searching/page=1' &&
      selectedFilter
    ) {
      dispatch(updateSelectedFilter(null));
      dispatch(updateVisibleSetting(true));
      dispatch(
        updateFilteredDocuments({
          data: [],
          config: {},
          filteredCount: 0,
        }),
      );
      dispatch(loadingStatus(false));
    } else if (filterId || !_.isEmpty(filteredDocuments.config)) {
      getUserFilters(tablePagination);
      dispatch(updateVisibleSetting(false));
    }
  }, [dispatch, filterId, tablePagination?.page, tablePagination?.pageSize]);

  useEffect(() => {
    getTagData();
  }, [filteredDocuments.config]);

  useEffect(() => {
    dispatch(updateTableType('FilteredDocuments'));
  }, [dispatch]);

  return {
    isLoading,
    onClose,
    settings,
  };
};
