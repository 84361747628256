import { notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Dispatch, useCallback, useState } from 'react';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { TDocumentFile } from 'types/document';
import { FileListParams } from './types';

export const useUploadByBarCode = ({
  setCurrentFiles,
  onClose,
}: {
  setCurrentFiles: Dispatch<React.SetStateAction<FileListParams[]>>;
  onClose: () => void;
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [barCodeList, setBarCodeList] = useState<RcFile[] & TDocumentFile[]>(
    [],
  );

  const findFileByBarCode = useCallback(() => {
    $api
      .get(`files/barcode/${searchValue}`)
      .then(({ data }) => setBarCodeList([data]))
      .catch(({ response }) => {
        const errorText = response.data.title;
        const documentId = errorText?.split(": '")[1]?.split("'")[0];
        const textToDocument = errorText.replace(`'${documentId}'`, '');

        if (documentId) {
          notification['error']({
            message: 'Произошла ошибка',
            description: (
              <>
                <span>{textToDocument}</span>
                <a
                  target="_blank"
                  href={`/active-documents/DocumentsOutcoming/${documentId}`}
                  rel="noreferrer"
                >
                  Документ №{documentId}
                </a>
              </>
            ),
            icon: null,
            className: 'custom-notification__error-after',
          });
        } else {
          errorThrow(response);
        }
      });
  }, [searchValue]);

  const onAddFileByBarCode = (item: TDocumentFile) => {
    setCurrentFiles(prev => [
      ...prev,
      {
        file: {
          ...item,
          name: item.Name,
        },
        id: String(item.Id),
      } as FileListParams,
    ]);
    onClose();
  };

  return {
    setSearchValue,
    searchValue,
    findFileByBarCode,
    barCodeList,
    onAddFileByBarCode,
  };
};
