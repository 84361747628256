import { Select } from 'antd';
import { ContactList, DeliveryParams } from '../types';
import { $api } from 'shared/utils/api';
import React, { useCallback, useEffect, useState } from 'react';
import { errorThrow, TError } from 'shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, ReduxState } from 'store/store';
import { PreLoader } from 'shared/components/PreLoader';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import _, { debounce } from 'lodash';
import deleteSvg from 'shared/assets/images/delete.svg';
import { Icon } from 'shared/components/IconComponent';
import { TOrganisationsList } from 'shared/components/CustomDrawers/FilterDrawer/types';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { CatalogButton } from 'shared/components/CatalogButton';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import {
  updateDocumentReceivers,
  updateOfficerRelations,
} from 'store/slice/creatingDocument';
import { FormikValues } from 'formik';
import { DButton } from 'shared/ui-kit';
import { UserReceiverGroup } from 'store/slice/userGroups';

type TReceiver = {
  Name?: string;
  Id?: number;
  SendTypeName?: string;
  SendTypeId?: number;
};

const getSelectedDelivery = async (id: number) => {
  if (id)
    try {
      const { data } = await $api.get(`sendtypes/${id}`);
      return data;
    } catch ({ response }) {
      errorThrow(response);
    }
};

const fetchDocumentReceivers = async (dispatch: AppDispatch) => {
  try {
    const { data } = await $api.get('counterparties/byorganization');
    dispatch(updateDocumentReceivers(data));
  } catch ({ response }) {
    errorThrow(response);
  }
};

export const ReceiverComponent = ({ formik }: { formik: FormikValues }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [officers, setOfficers] = useState<ContactList[] | undefined>([]);
  const [currentOrganisation, setCurrentOrganisation] = useState<TReceiver>();
  const [currentOfficer, setCurrentOfficer] = useState<any>();
  const [deliveryList, setDeliveryList] = useState<DeliveryParams[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [catalogType, setCatalogType] = useState<'organisations' | 'contacts'>(
    'contacts',
  );
  const { Option } = Select;
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { documentReceivers, officerRelations } = useSelector(
    (state: ReduxState) => state.creatingDocumentReducer,
  );
  const dispatch = useDispatch();
  const onClose = () => setVisible(false);

  const updateOfficers = useCallback(
    (query?: string, organisationId?: number) => {
      setLoader(true);

      const headers = {
        params: {
          counterpartyIds: user?.CurrentContact?.OrganisationId,
          fastSearch: query,
          limit: 25,
        },
      };
      const url = !organisationId
        ? 'contacts/active/bycounterparty/page'
        : `contacts/active/byorganization/${organisationId}/page`;

      $api
        .get(url, headers)
        .then(({ data: { Data } }) => setOfficers(Data))
        .catch(({ response }) => errorThrow(response))
        .finally(() => setLoader(false));
    },
    [user?.CurrentContact?.OrganisationId],
  );

  useEffect(() => {
    fetchDocumentReceivers(dispatch);
    updateOfficers();
  }, [fetchDocumentReceivers, updateOfficers]);

  const updateLinkageInfo = useCallback(
    (receiverData: {
      sendType?: {
        Name: string;
        Id: number;
      };
      officerInfo: ContactList;
      organisationInfo: TOrganisationsList;
    }) => {
      if (!formik.values.delivery) {
        formik.setFieldValue(
          'delivery',
          receiverData.organisationInfo.SendTypeId,
        );
      }
      dispatch(
        updateOfficerRelations([
          ...officerRelations,
          {
            OrganisationId: receiverData.organisationInfo.Id,
            OrganisationName: receiverData.organisationInfo.Name,
            AddresseeId: receiverData.officerInfo.Id,
            AddresseeLastName: receiverData.officerInfo.LastName,
            AddresseeFirstName: receiverData.officerInfo.FirstName,
            AddresseeMiddleName: receiverData.officerInfo.MiddleName,
            SendTypeName: receiverData.sendType?.Name,
            SendTypeId:
              receiverData.sendType?.Id ?? currentOrganisation?.SendTypeId,
          },
        ]),
      );
      setCurrentOrganisation(undefined);
      updateOfficers();
    },
    [
      currentOrganisation?.SendTypeId,
      dispatch,
      formik,
      officerRelations,
      updateOfficers,
    ],
  );

  const removeOfficerRelations = (index: number) => {
    const newArray = officerRelations.filter((_d, i: number) => i !== index);
    dispatch(updateOfficerRelations(newArray));
  };

  const getOrganisationByOfficer = useCallback(
    (officerInfo: ContactList) => {
      $api
        .get(`Organisations/${officerInfo.OrganisationId}`)
        .then(({ data: organisationInfo }) => {
          if (formik.values.delivery || organisationInfo.SendTypeId) {
            getSelectedDelivery(
              formik.values.delivery ?? organisationInfo.SendTypeId,
            ).then(({ Id, Name }: { Id: number; Name: string }) => {
              updateLinkageInfo({
                sendType: {
                  Id,
                  Name,
                },
                officerInfo,
                organisationInfo,
              });
            });
          } else {
            updateLinkageInfo({
              officerInfo,
              organisationInfo,
            });
          }
        });
    },
    [formik.values.delivery, updateLinkageInfo],
  );

  const getDelivery = useCallback(() => {
    $api
      .get('SendTypes')
      .then(({ data }) => setDeliveryList(data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  useEffect(() => {
    getDelivery();
  }, [getDelivery]);

  const onSaveOnlyOrganisation = () => {
    dispatch(
      updateOfficerRelations([
        ...officerRelations,
        {
          OrganisationId: currentOrganisation?.Id,
          OrganisationName: currentOrganisation?.Name,
          AddresseeId: null,
          AddresseeLastName: null,
          AddresseeFirstName: null,
          AddresseeMiddleName: null,
          SendTypeName: currentOrganisation?.SendTypeName,
          SendTypeId: currentOrganisation?.SendTypeId ?? formik.values.delivery,
        },
      ]),
    );
    setCurrentOrganisation(undefined);
    updateOfficers();
  };

  const onUpdateOrganisation = (item: TOrganisationsList) => {
    onSelectOrganisation(item);
  };

  const onUpdateContact = (item: ContactList) => {
    setOfficers(prev => prev && [...prev, item]);
    onSelectAddressee(item.Id);
  };

  const onSelectAddressee = (value: number) => {
    $api
      .get(`contacts/${value}`)
      .then(({ data }) => {
        if (
          currentOrganisation &&
          currentOrganisation.Id === data.OrganisationId
        ) {
          dispatch(
            updateOfficerRelations([
              ...officerRelations,
              {
                OrganisationId: currentOrganisation.Id,
                OrganisationName: currentOrganisation.Name,
                AddresseeId: value,
                AddresseeLastName: data.LastName,
                AddresseeFirstName: data.FirstName,
                AddresseeMiddleName: data.MiddleName,
                SendTypeName: currentOrganisation.SendTypeName,
                SendTypeId: currentOrganisation.SendTypeId,
              },
            ]),
          );

          setCurrentOfficer(undefined);
          setCurrentOrganisation(undefined);
          setCurrentOfficer((prev: any) => prev !== value);
          setOfficers(undefined);
          updateOfficers();
        } else {
          getOrganisationByOfficer(data);
          setCurrentOfficer(undefined);
          setCurrentOrganisation(undefined);
          setCurrentOfficer((prev: any) => prev !== value);
          setOfficers(undefined);
        }
      })
      .catch(({ response }) => errorThrow(response));
  };

  const onSelectOrganisation = (item: TOrganisationsList) => {
    setCurrentOfficer(undefined);
    const selectedOrganisation =
      documentReceivers.find(org => org.Id === item.Id) ?? item;
    getSelectedDelivery(
      formik.values.delivery ?? selectedOrganisation.SendTypeId,
    ).then(data => {
      setCurrentOrganisation({
        ...selectedOrganisation,
        SendTypeName: data?.Name,
        SendTypeId: data?.Id,
      });
      updateOfficers('', selectedOrganisation?.Id);
    });
    if (!formik.values.delivery)
      formik.setFieldValue('delivery', selectedOrganisation.SendTypeId);
  };

  const onSearchContact = debounce(value => {
    updateOfficers(value, currentOrganisation?.Id);
  }, DEBOUNCE_TIME);

  const onUpdateReceivers = async (item: TOrganisationsList) => {
    try {
      const { data } = await $api.get(
        `organisationreceivergroups/${item.Id}/byorganisation/receivergroupitems`,
      );
      const receiverRelations = data.map((item: UserReceiverGroup) => ({
        OrganisationId: item.OrganisationId,
        OrganisationName: item.OrganisationName,
        AddresseeId: item.AddresseeId,
        AddresseeLastName: item.AddresseeLastName,
        AddresseeFirstName: item.AddresseeFirstName,
        AddresseeMiddleName: item.AddresseeMiddleName,
        SendTypeName: item.SendTypeName,
        SendTypeId: item.SendTypeId,
      }));

      dispatch(
        updateOfficerRelations([...officerRelations, ...receiverRelations]),
      );
    } catch ({ response }) {
      errorThrow(response as TError);
    }
  };

  return (
    <>
      {visible && (
        <CatalogDrawer
          visible={visible}
          onClose={onClose}
          type={catalogType}
          func={(item: TOrganisationsList | ContactList) => {
            if (catalogType === 'organisations') {
              if (!item?.receiverGroup) {
                onUpdateOrganisation(item as TOrganisationsList);
              } else {
                onUpdateReceivers(item as TOrganisationsList);
              }
            } else {
              onUpdateContact(item as ContactList);
            }
          }}
          isReceiver={catalogType === 'organisations'}
        />
      )}
      {loader ? <PreLoader /> : null}
      <h2>Получатели(организации)</h2>
      <div className="document-group__block">
        <DFormItemTwo label="Доставка" name="delivery">
          <Select
            onSelect={(value: number) => {
              if (currentOrganisation)
                getSelectedDelivery(value).then(data => {
                  setCurrentOrganisation(prev => ({
                    ...prev,
                    SendTypeName: data.Name,
                    SendTypeId: data.Id,
                  }));
                });
            }}
            onClear={() => fetchDocumentReceivers(dispatch)}
            onChange={value => formik.setFieldValue('delivery', value)}
            value={formik?.values?.delivery}
            className="ui-select"
            allowClear
            showSearch
            optionFilterProp="children"
          >
            {deliveryList?.map(item => (
              <Option value={item.Id}>{item.Name}</Option>
            ))}
          </Select>
        </DFormItemTwo>
        <>
          <DFormItemTwo label="Получатель" name="Receiver">
            <Select
              className="ui-select"
              showSearch
              value={currentOrganisation?.Name}
              optionFilterProp="children"
              allowClear
              onClear={() => {
                formik.setFieldValue('Receiver', undefined);
                setCurrentOrganisation(undefined);
                setCurrentOfficer(undefined);
                updateOfficers();
              }}
              onSelect={(value: string) =>
                onSelectOrganisation({ Id: Number(value) })
              }
            >
              {_.uniqBy(
                documentReceivers.filter(item =>
                  officerRelations.every(t => item.Id !== t.OrganisationId),
                ),
                'Id',
              ).map(item => (
                <Option key={item.Id} value={item.Id}>
                  {item.Name} <br />
                  <span className="bottom-comment">{item.SendTypeName}</span>
                </Option>
              ))}
            </Select>
            {currentOrganisation && (
              <DButton
                defaultPrimary
                onClick={() => onSaveOnlyOrganisation()}
                className="ml15"
                small
              >
                Добавить
              </DButton>
            )}
            <CatalogButton
              onClick={() => {
                setVisible(true);
                setCatalogType('organisations');
              }}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Адресат (кому)" name="Addressee">
            <Select
              className="ui-select"
              showSearch
              allowClear
              onClear={updateOfficers}
              value={currentOfficer}
              optionFilterProp="children"
              onSelect={(value: number) => onSelectAddressee(value)}
              onSearch={value =>
                checkLengthStringForSearch(value, onSearchContact)
              }
            >
              {_.uniqBy(officers, 'Id').map(item => {
                return (
                  <Option key={item.Id} value={item.Id}>
                    {item?.LastName} {item?.FirstName} {item?.MiddleName}
                  </Option>
                );
              })}
            </Select>
            <CatalogButton
              onClick={() => {
                setVisible(true);
                setCatalogType('contacts');
              }}
            />
          </DFormItemTwo>
        </>
        {!!officerRelations.length && (
          <DFormItemTwo
            style={{ alignItems: 'baseLine' }}
            label="Выбранные"
            name="Selected"
          >
            <div className="w100per">
              {officerRelations.map((item, index: number) => (
                <div className="flex-space">
                  <div className="mb5">
                    <p className="custom-link" style={{ marginBottom: 0 }}>
                      {item.OrganisationName}
                    </p>
                    <p className="custom-link" style={{ marginBottom: 0 }}>
                      {item.AddresseeId
                        ? `${item.AddresseeLastName ??
                            ''} ${item.AddresseeFirstName ??
                            ''} ${item.AddresseeMiddleName ?? ''}`
                        : null}
                    </p>
                    <p style={{ marginBottom: 0 }} className="bottom-comment">
                      {item.SendTypeName}
                    </p>
                  </div>
                  <Icon
                    icon={deleteSvg}
                    alt="deleteSvg"
                    onClick={() => removeOfficerRelations(index)}
                    tooltip="Удалить"
                  />
                </div>
              ))}
            </div>
          </DFormItemTwo>
        )}
      </div>
    </>
  );
};
