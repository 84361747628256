import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Form, Menu } from 'antd';
import { format } from 'date-fns';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ControlDrawer } from 'shared/components/CustomDrawers/ControlDrawer';
import { DButton } from 'shared/ui-kit';
import {
  permissionExist,
  PermissionsAccess,
} from 'shared/utils/isExistPermission';
import { ReduxState } from 'store/store';
import { CurrentDocument } from 'types/document';
import { DocumentReminder } from './DocumentReminder/DocumentReminder';

type TDocumentControl = {
  currentDocument: CurrentDocument | null;
  canEdit?: boolean;
};

export const DocumentControl = ({
  currentDocument,
  canEdit,
}: TDocumentControl) => {
  const [controlType, setControlType] = useState<string>('');
  const [visibleControl, setVisibleControl] = useState<boolean>(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const DOCUMENT_CONTROL = currentDocument?.Controls[0];
  let controlSign = '';

  const onCloseControlDrawer = () => setVisibleControl(false);

  switch (currentDocument && DOCUMENT_CONTROL?.Status) {
    case 'Unknown':
      controlSign = 'Не задано';
      break;
    case 'InControl':
      controlSign = 'На контроле';
      break;
    case 'OutControl':
      controlSign = 'Снят с контроля';
      break;
    case 'NotRequired':
      controlSign = 'Не требует контроля';
      break;
    default:
      controlSign = '-';
      break;
  }

  const controlKinds = [
    {
      Kind: 'Unknown',
      title: 'Не задано',
    },
    {
      Kind: 'One',
      title: 'Однократный',
    },
    {
      Kind: 'Week',
      title: 'Постоянный с еженедельной информацией',
    },
    {
      Kind: 'Month',
      title: 'Постоянный с ежемесячной информацией',
    },
    {
      Kind: 'Quarter',
      title: 'Постоянный с ежеквартальной информацией',
    },
    {
      Kind: 'HalfYear',
      title: 'Постоянный с полугодовой информацией',
    },
    {
      Kind: 'Year',
      title: 'Постоянный с информацией по годам',
    },
  ];

  const selectControlKind = (kind: string | undefined) => {
    return controlKinds
      .filter(item => item.Kind === kind)
      .map(item => item.title);
  };

  const ControlOperationsMenu = (
    <Menu>
      <Menu.Item
        key="8"
        disabled={DOCUMENT_CONTROL?.Status === 'InControl'}
        onClick={() => {
          setControlType('take-control');
          setVisibleControl(true);
        }}
      >
        Поставить на контроль
      </Menu.Item>
      <Menu.Item
        key="9"
        disabled={DOCUMENT_CONTROL?.Status !== 'InControl'}
        onClick={() => {
          setControlType('reschedule-control');
          setVisibleControl(true);
        }}
      >
        Перенести срок исполнения
      </Menu.Item>
      <Menu.Item
        key="10"
        disabled={DOCUMENT_CONTROL?.Status !== 'InControl'}
        onClick={() => {
          setControlType('deregister-document');
          setVisibleControl(true);
        }}
      >
        Снять с контроля
      </Menu.Item>
      <Menu.Item
        key="11"
        disabled={DOCUMENT_CONTROL?.Status !== 'InControl'}
        onClick={() => {
          setControlType('relinquish-control');
          setVisibleControl(true);
        }}
      >
        Отказаться от контроля
      </Menu.Item>
      <Menu.Item
        key="12"
        disabled={DOCUMENT_CONTROL?.Status !== 'InControl'}
        onClick={() => {
          setControlType('mark-execution-document');
          setVisibleControl(true);
        }}
      >
        Отметить исполнение документа
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {visibleControl && (
        <ControlDrawer
          visible={visibleControl}
          onClose={onCloseControlDrawer}
          type={controlType}
        />
      )}
      <Form
        name="basic"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off"
        className="drawer-form read mb35 form"
      >
        {canEdit &&
          user?.Permissions &&
          permissionExist(user?.Permissions, PermissionsAccess.Control) && (
            <Dropdown
              trigger={['click']}
              className="mr15 mb15"
              overlay={ControlOperationsMenu}
            >
              <DButton small defaultPrimary>
                Операции по контролю <DownOutlined />
              </DButton>
            </Dropdown>
          )}
        <Form.Item
          className="item"
          label={
            DOCUMENT_CONTROL?.ControlDateFact === null &&
            DOCUMENT_CONTROL?.Status === 'NotRequired'
              ? 'Срок получения ответа:'
              : 'Срок исполнения:'
          }
        >
          <span>
            {DOCUMENT_CONTROL?.Id
              ? format(
                  new Date(DOCUMENT_CONTROL?.ControlDate),
                  'dd.MM.yyyy H:00',
                )
              : '-'}
          </span>
        </Form.Item>
        {DOCUMENT_CONTROL?.ControlDateFact !== null ? (
          <Form.Item className="item" label="Дата исполнения">
            {DOCUMENT_CONTROL?.Id &&
              format(
                new Date(DOCUMENT_CONTROL?.ExecutionDate),
                'dd.MM.yyyy H:00',
              )}
          </Form.Item>
        ) : null}
        {DOCUMENT_CONTROL?.Status === 'InControl' ? (
          <Form.Item className="item" label="Контролер">
            <span>
              {!!DOCUMENT_CONTROL?.ControllerId ? (
                <span>
                  {DOCUMENT_CONTROL?.ControllerLastName}{' '}
                  {DOCUMENT_CONTROL?.ControllerFirstName}{' '}
                  {DOCUMENT_CONTROL?.ControllerMiddleName}
                </span>
              ) : (
                '-'
              )}
            </span>
          </Form.Item>
        ) : null}
        <Form.Item className="item" label="Статус контроля">
          {controlSign}
        </Form.Item>
        {DOCUMENT_CONTROL?.ControlDateFact === null &&
        DOCUMENT_CONTROL?.Status === 'NotRequired' ? null : (
          <Form.Item className="item" label="Вид контроля">
            {DOCUMENT_CONTROL?.ControlKind
              ? selectControlKind(DOCUMENT_CONTROL?.ControlKind)
              : '-'}
          </Form.Item>
        )}
        <Form.Item className="item" label="Комментарий">
          {DOCUMENT_CONTROL?.Comment ?? '-'}
        </Form.Item>
      </Form>
      <DocumentReminder canEdit={canEdit} />
    </>
  );
};
