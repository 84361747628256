import { SubAtomSidebar } from './SubAtomSidebar';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Folder } from 'types/folder';

type THeaderSidebar = {
  item: Folder;
  updateSelectedCollapse: (id: number) => void;
  onClickHeaderItem: (item: Folder) => void;
  selectedCollapse: number | null | string;
  updateActiveHeaderClass: (isHead: boolean, item: Folder) => string;
  updateClassOfDocumentLink: (item: Folder) => string;
  onClickSubAtomChild: (child: Folder) => void;
};

const HeaderSidebar = ({
  item,
  updateSelectedCollapse,
  onClickHeaderItem,
  selectedCollapse,
  updateActiveHeaderClass,
  updateClassOfDocumentLink,
  onClickSubAtomChild,
}: THeaderSidebar) => {
  const location = useLocation();

  const ITEM_LINK = item.link;
  const isFilterPage =
    location.pathname.split('/')[1] === 'searching' &&
    ITEM_LINK?.includes('searching/') &&
    location.pathname.split('/').length < 3;

  useEffect(() => {
    if (selectedCollapse === null) {
      const result = sessionStorage.getItem('lastCollapse');
      updateSelectedCollapse(Number(result));
    }
  }, [selectedCollapse, updateSelectedCollapse]);

  return (
    <article>
      <div
        onClick={() => onClickHeaderItem(item)}
        className={updateClassOfDocumentLink(item)}
        style={
          isFilterPage ? { backgroundColor: '#1c57ef1a', color: '#1F2A37' } : {}
        }
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {item.Name}
        </span>
        <div className={updateActiveHeaderClass(true, item)} />
      </div>
      <div className={updateActiveHeaderClass(false, item)}>
        {item.childrens?.map((child: any) => (
          <SubAtomSidebar
            child={child}
            onClickSubAtomChild={onClickSubAtomChild}
          />
        ))}
      </div>
    </article>
  );
};

export { HeaderSidebar };
