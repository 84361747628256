import { useCallback, useEffect, useRef, useState } from 'react';
import Draggable, { ControlPosition } from 'react-draggable';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { AssignmentType, CurrentDocument } from 'types/document';
import {
  InitialValues,
  PageWithStamp,
  StampTypes,
  StateUpdate,
  TStampTypes,
} from './types';
import {
  checkIsStampOnThePage,
  convertStampsInfo,
  generateStampCoordinate,
  getOnlyPdfs,
} from './utils';
import { useFormik } from 'formik';
import { initialValues } from './constants';
import { Document } from 'react-pdf';

export const useAssignmentStamp = (
  assignment: AssignmentType,
  onSuccessResponse: (text: string) => void,
  documentData?: CurrentDocument | null,
) => {
  const [file, setFile] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [files, setFiles] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [stampXY, setStampXY] = useState<ControlPosition>();
  const [regXY, setRegXY] = useState<ControlPosition>();
  const [isStamp, setIsStamp] = useState<boolean>(false);
  const [selectedPhase, setSelectedPhase] = useState<number | null>();
  const [pagesWithStamp, setPagesWithStamp] = useState<PageWithStamp[]>([]);
  const [dragWrapperSize, setDragWrapperSize] = useState<ControlPosition>({
    x: 0,
    y: 0,
  });
  const [pageSize, setPageSize] = useState<ControlPosition>({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const draggableEntity = useRef<Draggable>(null);
  const pages = useRef<number>(0);

  const formik = useFormik({
    initialValues,
    onSubmit: values => onSend(values),
  });

  const resetStampCoordinate = (
    arrayLength: number,
    stampType: TStampTypes,
    setState: StateUpdate,
  ) => {
    if (arrayLength < 2) {
      setState(undefined);
      formik.setFieldValue('StampType', stampType);
    } else {
      formik.setFieldValue('StampType', StampTypes.Full);
    }
  };

  const updateStampCoordinates = (
    coordinates: ControlPosition,
    setState: StateUpdate,
  ) => {
    setState(coordinates);
  };

  const getPageCountByAllPdfs = useCallback(
    usedFileId => {
      setFiles([]);
      const onlyPdfs =
        documentData?.Files &&
        getOnlyPdfs(documentData?.Files.filter(item => item.Id !== usedFileId));
      const filesAsString: string[] = [];
      const fileRequests = onlyPdfs?.length
        ? onlyPdfs?.map(file => $api.get(`/Files/Download/${file?.Id}/base64`))
        : [];

      Promise.all(fileRequests)
        .then(responseArray => {
          responseArray.forEach(response => {
            filesAsString.push('data:application/pdf;base64,' + response.data);
          });
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => {
          setFiles(filesAsString);
          if (!filesAsString.length) {
            setIsLoading(false);
          }
        });
    },
    [documentData?.Files],
  );

  const renderDocuments = () => {
    return files.map(fileSrc => (
      <Document
        className="hidden"
        options={{ workerSrc: '/pdf.worker.js' }}
        onLoadProgress={() => setIsLoading(true)}
        onLoadSuccess={({ numPages }) => {
          setIsLoading(false);
          pages.current += numPages;
        }}
        file={fileSrc}
      />
    ));
  };

  const updateStampRenderByPageNumber = () => {
    pagesWithStamp
      .filter(({ page }) => page === pageNumber)
      .forEach(({ stamp }, _d, array) => {
        const coordinates = { x: stamp.x, y: stamp.y };
        const ARRAY_LENGTH = array.length;

        if (stamp.type === 'OnlyRegNumber') {
          updateStampCoordinates(coordinates, setRegXY);
          resetStampCoordinate(ARRAY_LENGTH, stamp.type, setStampXY);
        }
        if (stamp.type === 'OnlyStamp') {
          updateStampCoordinates(coordinates, setStampXY);
          resetStampCoordinate(ARRAY_LENGTH, stamp.type, setRegXY);
        }
      });
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setTimeout(() => {
      const wrapper = document.getElementsByClassName('react-pdf__Page');
      const width = wrapper.item(0)?.children.item(0)?.clientWidth;
      const height = wrapper.item(0)?.children.item(0)?.clientHeight;
      if (width && height) {
        setPageSize({ x: width, y: height });
        setDragWrapperSize({
          x: width - 100,
          y: height,
        });
      }
    }, 200);
  };

  const getFileInfo = useCallback(
    (id: number) => {
      pages.current = 0;
      const file = documentData?.Files.find(item => item.Id === id);
      $api
        .get(`/Files/Download/${file?.Id}/base64`)
        .then(res => {
          setFile('data:application/pdf;base64,' + res.data);
          getPageCountByAllPdfs(file?.Id);
        })
        .catch(({ response }) => {
          errorThrow(response);
        });
    },
    [documentData?.Files, getPageCountByAllPdfs],
  );

  const updateStampInfoOnDrag = (
    coordinates: { x: number; y: number },
    type: TStampTypes,
  ) => {
    const newArray = pagesWithStamp.map(item => {
      if (item.stamp.type === type && item.page === pageNumber) {
        return {
          ...item,
          stamp: {
            ...item.stamp,
            x: coordinates.x,
            y: coordinates.y,
          },
        };
      }
      return item;
    });
    setPagesWithStamp(newArray);
  };

  const onDragging = (
    data: { x: number; y: number },
    setState: StateUpdate,
    type: TStampTypes,
  ) => {
    const coordinates = { x: data.x, y: data.y };
    updateStampInfoOnDrag(coordinates, type);
    updateStampCoordinates(coordinates, setState);
  };

  const onCreateStampInfo = () => {
    setIsStamp(true);

    const newStampInfo = generateStampCoordinate(
      pageNumber,
      formik.values.StampType,
    );

    if (Array.isArray(newStampInfo)) {
      setPagesWithStamp(prev => [...prev, ...newStampInfo]);
    } else {
      setPagesWithStamp(prev => [...prev, newStampInfo]);
    }
  };

  useEffect(() => {
    if (checkIsStampOnThePage(pagesWithStamp, pageNumber)) {
      setIsStamp(true);
      updateStampRenderByPageNumber();
    } else {
      setIsStamp(false);
      setRegXY(undefined);
      setStampXY(undefined);
    }
  }, [pageNumber]);

  const onSend = (values: InitialValues) => {
    $api
      .patch(`Assignments/${assignment?.Id}/Execute/ApplyingStamp`, {
        PhaseId: selectedPhase ?? null,
        Resolution: values.Resolution ?? null,
        ExecutionFactTime: new Date().toISOString(),
        DocumentFileStamps: convertStampsInfo(
          pagesWithStamp,
          values.File,
          pageSize,
        ),
        DocumentComposition: `${numPages}+${pages.current}`,
      })
      .then(() => onSuccessResponse('Штамп поставлен'))
      .catch(({ response }) => errorThrow(response));
  };

  const onNextPage = () => {
    if (!(pageNumber + 1 === (numPages && numPages + 1))) {
      setPageNumber(pageNumber + 1);
    }
  };

  const onPreviousPage = () => {
    if (!(pageNumber - 1 === 0)) {
      setPageNumber(pageNumber - 1);
    }
  };

  const onRejectStamp = () => {
    const stampsOnAnotherPages = pagesWithStamp.filter(
      item => item.page !== pageNumber,
    );
    setPagesWithStamp(stampsOnAnotherPages);
    setIsStamp(false);
  };

  return {
    formik,
    onPreviousPage,
    pageNumber,
    onNextPage,
    isStamp,
    file,
    onCreateStampInfo,
    pagesWithStamp,
    onRejectStamp,
    onDocumentLoadSuccess,
    updateSelectedPhase: (id: number | null) => setSelectedPhase(id),
    getFileInfo,
    dragWrapperSize,
    stampXY,
    draggableEntity,
    onDragging,
    setStampXY,
    setRegXY,
    regXY,
    checkStampOnThePage: () =>
      checkIsStampOnThePage(pagesWithStamp, pageNumber),
    renderDocuments,
    isLoading,
  };
};
