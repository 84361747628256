import { Drawer, Select } from 'antd';
import { fetchArchiveItemsByYear } from 'api';
import { useFormik } from 'formik';
import { ArchiveItem } from 'pages/Archive/type';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { DButton } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { ReduxState } from 'store/store';

export const ChangeArchiveDrawer = ({
  visible,
  onClose,
  onSubmitChangeArchiveCase,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmitChangeArchiveCase: (documentId: number) => void;
}) => {
  const [archiveItems, setArchiveItems] = useState<ArchiveItem[]>([]);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { tomeId } = useParams<{
    tomeId: string;
  }>();

  const getArchiveItems = useCallback(
    (nomenclatureId?: number) => {
      fetchArchiveItemsByYear({
        tomeNumber: tomeId,
        nomenclatureId,
        orgId: user?.CurrentContact?.OrganisationId as number,
      }).then(list => setArchiveItems(list));
    },
    [tomeId, user?.CurrentContact?.OrganisationId],
  );

  useEffect(() => {
    if (tomeId) {
      getArchiveItems();
    }
  }, [getArchiveItems, tomeId]);

  const formik = useFormik({
    initialValues: {
      NewArchiveCaseId: undefined,
    },
    onSubmit: values =>
      onSubmitChangeArchiveCase(Number(values.NewArchiveCaseId)),
  });

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Формирование описи" />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Дело" name="NewArchiveCaseId">
            <Select
              value={formik.values.NewArchiveCaseId}
              className="ui-select"
              showSearch
              optionFilterProp="children"
              onChange={value =>
                formik.setFieldValue('NewArchiveCaseId', value)
              }
            >
              {archiveItems?.map(item => (
                <Select.Option value={item.Id} key={item.Id}>
                  {item.NomenclatureCode} {item.NomenclatureName}
                </Select.Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo>
            <DButton small primary type="submit">
              Перенести
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
