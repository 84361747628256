import { DatePicker, Select } from 'antd';
import { PreLoader } from 'shared/components/PreLoader';
import { DeliveryParams, ContactList } from '../types';
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { DButton, DInput } from 'shared/ui-kit';
import { $api } from 'shared/utils/api';
import { ReceiverComponent } from './ReceiverComponent';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { errorThrow, successNotification } from 'shared/utils';
import { DatePick } from 'shared/components/DatePicker/DatePick';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import moment, { Moment } from 'moment';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import _ from 'lodash';
import { TOrganisationsList } from 'shared/components/CustomDrawers/FilterDrawer/types';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { CatalogButton } from 'shared/components/CatalogButton';
import { updateDocumentReceivers } from 'store/slice/creatingDocument';
import { FormikValues } from 'formik';
import { getUnUniqDocuments } from './RegistrateDocument';

type CollapseParams = {
  type: string;
  formik: FormikValues;
  setIsCheckedRegNumber: (bool: boolean) => void;
};

export const CollapseItem = ({
  type,
  formik,
  setIsCheckedRegNumber,
}: CollapseParams) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [delivery, setDelivery] = useState<DeliveryParams[]>([]);
  const [correspondentList, setCorrespondentList] = useState<ContactList[]>([]);
  const [addresseeList, setAddresseeList] = useState<ContactList[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [catalogType, setCatalogType] = useState<'organisations' | 'contacts'>(
    'contacts',
  );
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { Option } = Select;
  const { documentInfo, sampleSettings } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { documentReceivers } = useSelector(
    (state: ReduxState) => state.creatingDocumentReducer,
  );
  const dispatch = useDispatch();
  const isIncomingType = type === 'Incoming';
  const incomingSendingContext = documentInfo?.SendingContexts?.[0];
  const organisationId = user?.CurrentContact?.OrganisationId;

  const onClose = () => setVisible(false);

  const getOrganisation = useCallback(
    (senderId, organisationList) => {
      $api
        .get(`Organisations/${senderId}`)
        .then(({ data }) =>
          dispatch(updateDocumentReceivers([...organisationList, data])),
        )
        .catch(({ response }) => errorThrow(response));
    },
    [dispatch],
  );

  const getCorrespondent = useCallback(() => {
    $api
      .get(`Contacts/${incomingSendingContext?.CorrespondentId}`)
      .then(({ data }) => setCorrespondentList(prev => [...prev, data]))
      .catch(({ response }) => errorThrow(response));
  }, [incomingSendingContext?.CorrespondentId]);

  const fetchDocumentReceivers = useCallback(
    (receiverList?: TOrganisationsList[]) => {
      if (receiverList) {
        dispatch(updateDocumentReceivers(receiverList));
      } else {
        $api
          .get('counterparties/byorganization')
          .then(({ data }) => {
            const senderId = incomingSendingContext?.SenderId;
            dispatch(updateDocumentReceivers(data));

            if (senderId && sampleSettings?.Correspondent) {
              getOrganisation(senderId, data);
              formik.setFieldValue('Sender', senderId);
            }

            if (senderId && !sampleSettings) {
              getOrganisation(senderId, data);
              formik.setFieldValue('Sender', senderId);
            }
          })
          .catch(({ response }) => errorThrow(response));
      }
    },
    [
      dispatch,
      getOrganisation,
      incomingSendingContext?.SenderId,
      sampleSettings?.Correspondent,
    ],
  );

  const fetchCorrespondents = useCallback(
    orgId => {
      const requestUrl = !incomingSendingContext?.SenderId
        ? 'contacts/active/bycounterparty/page'
        : `contacts/active/byorganization/${orgId}/page`;
      $api
        .get(requestUrl, {
          params: {
            CounterpartyIds: orgId,
          },
        })
        .then(({ data: { Data } }) => {
          setCorrespondentList(Data);
          if (incomingSendingContext?.CorrespondentId) {
            getCorrespondent();
          }
        })
        .catch(({ response }) => errorThrow(response));
    },
    [
      getCorrespondent,
      incomingSendingContext?.CorrespondentId,
      incomingSendingContext?.SenderId,
    ],
  );

  const getDocumentAddressee = useCallback(id => {
    $api
      .get(`contacts/${id}`)
      .then(({ data }) => setAddresseeList(prev => [...prev, data]))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const fetchAddressees = useCallback(
    (query?: string) => {
      if (organisationId) {
        $api
          .get(`contacts/active/byorganization/${organisationId}/page`, {
            params: {
              FastSearch: query,
            },
          })
          .then(({ data: { Data } }) => {
            setAddresseeList(Data);
            if (incomingSendingContext?.AddresseeId) {
              getDocumentAddressee(incomingSendingContext?.AddresseeId);
            }
            if (
              incomingSendingContext?.CorrespondentId &&
              (documentInfo?.Type === 'Outcoming' ||
                sampleSettings?.DocumentType === 'Outcoming')
            ) {
              getDocumentAddressee(incomingSendingContext?.CorrespondentId);
            }
          })
          .catch(({ response }) => errorThrow(response));
      }
    },
    [
      organisationId,
      incomingSendingContext?.AddresseeId,
      incomingSendingContext?.CorrespondentId,
      documentInfo?.Type,
      getDocumentAddressee,
      sampleSettings?.DocumentType,
    ],
  );

  const onSearchAddressee = useCallback(
    debounce(query => fetchAddressees(query), DEBOUNCE_TIME),
    [],
  );

  const getDelivery = useCallback(() => {
    $api
      .get('SendTypes')
      .then(res => setDelivery(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  useEffect(() => {
    fetchAddressees();
  }, [fetchAddressees]);

  useEffect(() => {
    if (type === 'Incoming') {
      fetchDocumentReceivers();
      fetchCorrespondents(incomingSendingContext?.SenderId ?? organisationId);
      getDelivery();
    }
  }, [
    fetchCorrespondents,
    fetchDocumentReceivers,
    getDelivery,
    incomingSendingContext?.SenderId,
    organisationId,
    type,
  ]);

  const onSelectSender = useCallback((organisationId: number) => {
    setLoader(true);
    $api
      .get(`contacts/active/byorganization/${organisationId}/page`, {
        params: { limit: 1000 },
      })
      .then(({ data: { Data } }) => setCorrespondentList(Data))
      .catch(({ response }) => errorThrow(response))
      .finally(() => setLoader(false));
  }, []);

  useEffect(() => {
    if (documentInfo?.SenderId) {
      onSelectSender(documentInfo?.SenderId);
    }
  }, [documentInfo?.SenderId, onSelectSender]);

  const onCheckRegNumber = useCallback(() => {
    $api
      .get(`documents/checkregnumberincoming`, {
        params: {
          RegNumber: formik.values.InRegNumber,
          Id: documentInfo?.Id ?? null,
          SenderId: formik.values.Sender ?? null,
        },
      })
      .then(({ data: { Ids } }) => {
        !Ids.length
          ? successNotification('Текущий рег. номер является уникальным')
          : getUnUniqDocuments(Ids);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsCheckedRegNumber(true));
  }, [
    documentInfo?.Id,
    formik.values.InRegNumber,
    formik.values.Sender,
    setIsCheckedRegNumber,
  ]);

  const onSelectCorrespondent = useCallback(
    async (id: number) => {
      setLoader(true);

      try {
        const { data: contactInfo } = await $api.get(`contacts/${id}`);
        const { data: organisationInfo } = await $api.get(
          `Organisations/${contactInfo?.OrganisationId}`,
        );

        onSelectSender(contactInfo?.OrganisationId);
        dispatch(
          updateDocumentReceivers([...documentReceivers, organisationInfo]),
        );
        formik.setFieldValue('Sender', organisationInfo.Id);
      } catch ({ response }) {
        errorThrow(response);
      } finally {
        setLoader(false);
      }
    },
    [dispatch, documentReceivers, formik, onSelectSender],
  );

  const isShowOnAuto =
    documentInfo?.IsAutoGenerated && documentInfo?.SendType === 57;

  const updateSender = (item: TOrganisationsList) => {
    if (catalogType === 'organisations') {
      dispatch(updateDocumentReceivers([...documentReceivers, item]));
      formik.setFieldValue('Sender', item.Id);
      onSelectSender(item.Id);
    }

    if (!formik.values.Sender && catalogType === 'contacts') {
      formik.setFieldValue('Correspondent', item.Id);
      onSelectCorrespondent(item.Id);
    }
  };

  return (
    <>
      {visible && (
        <CatalogDrawer
          visible={visible}
          onClose={onClose}
          type={catalogType}
          func={updateSender}
        />
      )}
      {loader && <PreLoader />}
      {type === 'Incoming' && (
        <>
          <h2>Отправители</h2>
          <div className="document-group__block">
            <DFormItemTwo label="Доставка" name="delivery">
              <Select
                value={formik.values.delivery}
                className="ui-select"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={value => formik.setFieldValue('delivery', value)}
              >
                {delivery?.map(item => (
                  <Option value={item.Id}>{item.Name}</Option>
                ))}
              </Select>
            </DFormItemTwo>
            <DFormItemTwo label="Отправитель" name="Sender">
              <Select
                disabled={isShowOnAuto}
                className="ui-select"
                showSearch
                value={formik.values.Sender}
                onClear={() => {
                  fetchCorrespondents(organisationId);
                  fetchAddressees();
                  formik.setFieldValue('Correspondent', undefined);
                  formik.setFieldValue('Sender', undefined);
                }}
                onSelect={(value: number) => {
                  onSelectSender(value);
                  formik.setFieldValue('Correspondent', undefined);
                  formik.setFieldValue('Sender', value);
                }}
                optionFilterProp="children"
                allowClear
              >
                {_.uniqBy(documentReceivers, 'Id').map(item => (
                  <Option key={item.Id} value={item.Id}>
                    {item.Name}
                  </Option>
                ))}
              </Select>
              <CatalogButton
                onClick={() => {
                  setVisible(true);
                  setCatalogType('organisations');
                }}
              />
            </DFormItemTwo>
            <div style={{ display: 'flex', width: '100%' }}>
              <DFormItemTwo
                className="regdata"
                label="Исх. рег. номер"
                name="InRegNumber"
              >
                <DInput
                  value={formik.values.InRegNumber}
                  name="InRegNumber"
                  onChange={({ target: { value } }) => {
                    setIsCheckedRegNumber(false);
                    formik.setFieldValue('InRegNumber', value);
                  }}
                  disabled={isShowOnAuto}
                  type="text"
                  width="100%"
                />
              </DFormItemTwo>
              <DFormItemTwo
                className="regdata"
                label="Исх. дата регистрации"
                name="CreateDate"
              >
                <DatePicker
                  allowClear
                  onChange={date => formik.setFieldValue('CreateDate', date)}
                  value={
                    formik.values.CreateDate
                      ? moment(formik.values.CreateDate)
                      : null
                  }
                  disabled={isShowOnAuto}
                  style={{ height: 36, borderRadius: 8, width: '100%' }}
                  format={'DD.MM.YYYY'}
                />
              </DFormItemTwo>
            </div>
            <DFormItemTwo>
              <DButton
                small
                defaultPrimary
                disabled={!formik.values.InRegNumber || !formik.values.Sender}
                onClick={onCheckRegNumber}
              >
                Проверить
              </DButton>
            </DFormItemTwo>
            <DFormItemTwo label="Корреспондент" name="Correspondent">
              <Select
                disabled={isShowOnAuto || !correspondentList.length}
                className="ui-select"
                showSearch
                onChange={value => formik.setFieldValue('Correspondent', value)}
                value={formik.values.Correspondent}
                onClear={() => {
                  fetchAddressees();
                  formik.setFieldValue('Correspondent', undefined);
                  fetchCorrespondents(formik.values.Sender ?? organisationId);
                }}
                optionFilterProp="children"
                onSelect={(value: number) => {
                  if (!formik.values.Correspondent) {
                    onSelectCorrespondent(value);
                  }
                }}
                allowClear
              >
                {_.uniqBy(correspondentList, 'Id').map(item => (
                  <Option key={item.Id} value={item.Id}>
                    {item.LastName} {item.FirstName} {item.MiddleName}
                  </Option>
                ))}
              </Select>
              <CatalogButton
                onClick={() => {
                  setVisible(true);
                  setCatalogType('contacts');
                }}
              />
            </DFormItemTwo>
          </div>
        </>
      )}
      {type === 'Outcoming' && <ReceiverComponent formik={formik} />}
      {type !== 'Inner' && (
        <DFormItemTwo
          label={isIncomingType ? 'Адресат(кому)' : 'Корреспондент(от кого)'}
          name={isIncomingType ? 'Addressee' : 'Correspondent'}
        >
          <Select
            className="ui-select"
            allowClear
            onChange={value => {
              const key = isIncomingType ? 'Addressee' : 'Correspondent';
              formik.setFieldValue(key, value);
            }}
            value={
              isIncomingType
                ? formik.values.Addressee
                : formik.values.Correspondent
            }
            showSearch
            optionFilterProp="children"
            onSearch={onSearchAddressee}
          >
            {_.uniqBy(addresseeList, 'Id').map((addressee: ContactList) => (
              <Option key={addressee.Id} value={addressee.Id}>
                {addressee.LastName} {addressee.FirstName}{' '}
                {addressee.MiddleName}
              </Option>
            ))}
          </Select>
        </DFormItemTwo>
      )}
    </>
  );
};
