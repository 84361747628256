export enum TabNames {
  CREATE = 'Создание документа',
  EDIT = 'Редактирование документа',
  REGISTRATION = 'Регистрация документа',
  SEND_TO_ROUTE = 'Запуск по процессу',
}

export enum TabKeys {
  CREATE = 'create',
  REGISTRATION = 'registration',
  SEND_TO_ROUTE = 'route',
}
