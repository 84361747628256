import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { DButton } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DrawerType, TRegNumber } from './types';

export const useDocumentRegistration = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [regNumberList, setRegNumberList] = useState<TRegNumber[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [drawerType, setDrawerType] = useState<DrawerType>('create');
  const [selectedItem, setSelectedItem] = useState<TRegNumber>();

  const onDelete = (id?: number) => {
    $api
      .delete(`documentregisters/${id}`)
      .then(() => getRegNumber())
      .catch(({ response }) => errorThrow(response));
  };

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Наименование',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Маска',
      dataIndex: 'MaskRegNumber',
      key: 'MaskRegNumber',
    },
    {
      title: 'Счетчик',
      dataIndex: 'StartCount',
      key: 'StartCount',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (_d: undefined, item: TRegNumber) => (
        <>
          <DButton
            small
            defaultPrimary
            className="mr15"
            onClick={() => {
              setSelectedItem(item);
              setVisible(true);
              setDrawerType('edit');
            }}
          >
            Редактировать
          </DButton>
          <DButton small defaultDanger onClick={() => onDelete(item?.Id)}>
            Удалить
          </DButton>
        </>
      ),
    },
  ];

  const onClose = (isSending?: boolean) => {
    if (isSending) {
      getRegNumber();
    }
    setSelectedItem(undefined);
    setVisible(false);
  };

  const getRegNumber = useCallback(() => {
    setIsLoading(true);
    $api
      .get(`documentregisters`)
      .then(res => setRegNumberList(res.data))
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getRegNumber();
  }, [getRegNumber]);

  const onCreate = () => {
    setVisible(true);
    setDrawerType('create');
  };

  return {
    isLoading,
    columns,
    regNumberList,
    onCreate,
    visible,
    selectedItem,
    onClose,
    drawerType,
  };
};
