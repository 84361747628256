import { IssuanceAssignmentDrawer } from './IssuanceAssignmentDrawer';
import { DelegateAssignmentDrawer } from './DelegateAssignmentDrawer';
import { AssignmentIncomingType } from './IssuanceAssignmentDrawer/types';

const types = [
  {
    Name: 'ToExecute',
    Type: 'assignment-to-execute',
  },
  {
    Name: 'ToView',
    Type: 'assignment-for-audit',
  },
  {
    Name: 'ToReview',
    Type: 'assignment-for-review',
  },
  {
    Name: 'ToApproval',
    Type: 'ToApproval',
  },
];

const getType = (type: string) =>
  types.find(item => item.Name === type)?.Type as AssignmentIncomingType;

export const ChildAssignmentDrawer = ({
  onClose,
  visible,
  assignment,
  childType,
  documentData,
  isFollowing,
}: any) => {
  return (
    <div className="page3">
      {childType === 'Delegated' ? (
        <DelegateAssignmentDrawer
          currentAssignment={assignment}
          onClose={onClose}
          visible={visible}
          documentData={documentData}
        />
      ) : (
        <IssuanceAssignmentDrawer
          type={getType(assignment?.Type) ?? 'ToApproval'}
          currentAssignment={assignment}
          onClose={onClose}
          childType={childType}
          visible={visible}
          documentData={documentData}
          isFollowing={isFollowing}
        />
      )}
    </div>
  );
};
