import { Button, Checkbox, Form, Input } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SwapOutlined } from '@ant-design/icons';
import { useBreadcrumb } from 'shared/hooks/useBreadcrumb';
import './DocumentAdditionalOperations.scss';

export const OperationsForm = ({ onFinish, match }: any) => {
  const { closeBreadcrumbs } = useBreadcrumb();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Form
      name="basic"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      autoComplete="off"
      className="form"
    >
      {match.url !== '/link-to-a-document' && (
        <Form.Item label="Тип/вид документа" name="DocumentKind">
          <Input defaultValue="Входящие/Письмо" disabled />
        </Form.Item>
      )}
      {match.url === '/link-to-a-document' && (
        <Form.Item label="Текущий документ" name="CurrentDocument">
          <Input defaultValue="Вх. б/н Тест  ОТ КОГО: Комитет по образованию и науке АВО за подписью Корреспондентов: 2;" />
        </Form.Item>
      )}
      {match.url === '/link-to-a-document' && (
        <Form.Item label="Установить связь с документом" name="LinkDocument">
          <Input />
        </Form.Item>
      )}
      {(match.url === '/create-linked-document' ||
        match.url === '/link-to-a-document') && (
        <Form.Item label="Тип связи документов" name="DocumentLinkageType">
          <div className="linkage mt5">
            <div className="linkage__title">
              <p className="w250">Новый документ</p>
              <p>
                Вх. б/н "Тест" <br />
                <span>
                  ОТ КОГО: Комитет по образованию и науке АВО за подписью
                  Корреспондентов: 2;
                </span>
                <br />
                <span>
                  КОМУ: Комитет информационных технологий Волгоградской области
                </span>
              </p>
            </div>
            <div className="linkage__type">
              <p>Входящий</p>
              <SwapOutlined className="linkage__type-icon" />
              <p>Ответ на входящий запрос</p>
            </div>
          </div>
          <Link to="/link-to-a-document-table">Изменить</Link>
        </Form.Item>
      )}
      {match.url !== '/link-to-a-document' && (
        <>
          <Form.Item label="Копировать из докуемнта" name="CopyingFormDoc">
            <p>
              Вх. б/н "Тест" <br />
              <span>
                ОТ КОГО: Комитет по образованию и науке АВО за подписью
                Корреспондентов: 2;
              </span>
              <span>
                КОМУ: Комитет информационных технологий Волгоградской области
              </span>
            </p>
          </Form.Item>
          <Form.Item className="operations-checkbox" name="Checkbox">
            <Checkbox>Тему</Checkbox>
            <Checkbox>Содержание</Checkbox>
            {match.url !== '/create-linked-document' && (
              <>
                <Checkbox>Корреспондента</Checkbox>
                <Checkbox>Адресата</Checkbox>
              </>
            )}
          </Form.Item>
        </>
      )}
      <Form.Item className="form-buttons">
        {match.url !== '/link-to-a-document' ? (
          <Button htmlType="submit" className="button" type="primary">
            Сохранить
          </Button>
        ) : (
          <Button htmlType="submit" className="button" type="primary">
            Выполнить
          </Button>
        )}
        <Button
          danger
          onClick={() => {
            history.goBack();
            closeBreadcrumbs(dispatch, history.location.pathname);
          }}
        >
          Отмена
        </Button>
      </Form.Item>
    </Form>
  );
};
