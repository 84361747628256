import { Modal } from 'antd';
import { DButton } from 'shared/ui-kit';

type ErrorModalProps = {
  visible: boolean;
  onClose: () => void;
  text: string;
};

export const ErrorModal = ({ visible, onClose, text }: ErrorModalProps) => {
  return (
    <Modal
      title="Ошибка отправки"
      visible={visible}
      onCancel={onClose}
      footer={[
        <DButton small defaultDanger onClick={onClose}>
          Закрыть
        </DButton>,
      ]}
    >
      <p className="modal-text">{text}</p>
    </Modal>
  );
};
