import { Phases } from 'pages/AssignmentCard/types';
import { Dispatch, RefObject, SetStateAction } from 'react';
import Draggable, { ControlPosition } from 'react-draggable';
import { AssignmentType, CurrentDocument } from 'types/document';

export type TStampTypes = 'OnlyRegNumber' | 'OnlyStamp' | 'Full';

export type StampCoordinates = {
  x: number;
  y: number;
};

export type StateUpdate = Dispatch<SetStateAction<ControlPosition | undefined>>;

export enum StampTypes {
  RegNumber = 'OnlyRegNumber',
  Stamp = 'OnlyStamp',
  Full = 'Full',
}

export type StampInfo = {
  X1?: number;
  Y1?: number;
  X2?: number;
  Y2?: number;
  FileId?: string;
  X1Length: number;
  X2Length: number;
  Y1Length: number;
  Y2Length: number;
  PageNumber: number;
  PageWidth?: number;
  PageHeight?: number;
  StampType: 'Unknown' | 'Signature' | 'Registration';
};

export type TStampDraggableProps = {
  scale: number;
  grid: [number, number];
};

export type InitialValues = {
  File?: string;
  Resolution: string;
  StampType?: TStampTypes;
};

export type PageWithStamp = {
  page: number;
  stamp: {
    type?: TStampTypes;
  } & StampCoordinates;
  isCreated: boolean;
};

export type TAssignmentStamp = {
  phases: Phases[];
  assignment: AssignmentType;
  onCloseDrawer?: (bool: boolean) => void;
  documentData?: CurrentDocument | null;
  nextExecutorName?: string;
  onSuccessResponse: (text: string) => void;
};

export type TStampComponent = {
  dragWrapperSize: ControlPosition;
  stampType?: TStampTypes;
  stampXY: ControlPosition | undefined;
  regXY: ControlPosition | undefined;
  draggableEntity: RefObject<Draggable>;
  onDragging: (
    data: { x: number; y: number },
    setState: StateUpdate,
    type: TStampTypes,
  ) => void;
  setStampXY: StateUpdate;
  setRegXY: StateUpdate;
};
