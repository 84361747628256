import { AssignmentType, CurrentDocument } from 'types/document';

export type AssignmentDocumentDispatchFormProps = {
  phases: Phases[];
  assignment: AssignmentType;
  onCloseDrawer?: (bool: boolean) => void;
  documentData?: CurrentDocument | null;
  nextExecutorName?: string;
  onSuccessResponse: (text: string) => void;
};

export type Receiver = {
  OrganisationId: number;
  ContactId: number | null;
  SendTypeId: number;
};

export type SubmittedData = {
  PhaseId: number | null;
  Resolution: string;
  DocumentComposition: any;
  ExecutionFactTime: string;
  DocumentReceivers: Receiver[];
  FileIds: string[];
  CorrespondentId: number;
};

export type TPhaseContacts = {
  ContactId: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  PositionName: string;
  Id?: number;
};

export type TPHaseUserGroup = {
  Id: number;
  Kpp: string | null;
  Name: string;
  OrganisationId: number;
  ParentId: number;
  ShortNotation: string;
  Type: number;
};

export type Phases = {
  PhaseId: number;
  PhaseName: string;
  CreateDate: string;
  Estimation: number;
  SigningRequired: boolean;
  UserGroups: TPHaseUserGroup[] | null;
  Data: {
    Executions: number[];
    ExecutionType: string;
    Estimation: number;
  };
  Contacts?: TPhaseContacts[] | null;
  IsGroup: boolean;
  DataJson: string;
  EntityId: number;
  EntityType: string;
  Id: number;
  IsSkip: boolean;
  LinkId: number;
  LinkName: string;
  Name: string;
  OrganisationId: number;
  Point: number;
  Type: string;
  UpdateDate: string;
  IsRequired: boolean;
};

export type UseAssignments = {
  assignment?: AssignmentType;
  onCloseDrawer?: (bool: boolean) => void;
  getAssignment?: () => void;
};

export type ExpirationTypes =
  | 'Unknown'
  | 'OneYear'
  | 'ThreeYears'
  | 'FiveYears'
  | 'Constantly';

export type NomenclatureParams = {
  Name: string;
  Id: number;
  Code: string;
  Type: string;
  MaskRegNumber: string;
  OrganisationId: number;
  ParentId: number;
  EndDate: string;
  ArchiveExpirationDateType: ExpirationTypes;
};

export const RequestTypes: Record<AssignmentTypes, string> = {
  ToReview: 'execute/review',
  ToApproval: 'execute/approval',
  ToExecute: 'execute',
  ToView: 'execute',
};

export type AssignmentTypes =
  | 'ToReview'
  | 'ToApproval'
  | 'ToExecute'
  | 'ToView';
