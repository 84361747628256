export const convertStyles = (args?: any) => {
  const newObject = Object.assign(
    // @ts-ignore
    ...args.map((item: any) => item !== undefined && item),
  );
  return newObject;
};

export const convertClasses = (
  defClass: string,
  classes?: string,
  disabled?: boolean,
) => {
  const hasClassName = classes ?? '';
  if (disabled) return defClass + ' disabled' + ' ' + hasClassName;
  else return defClass + ' ' + hasClassName;
};
