import { Drawer, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentInfo } from 'pages/DocumentPage/components/DocumentInfo/DocumentInfo';
import { CurrentDocument } from 'types/document';
import { $api } from 'shared/utils/api';
import { DocumentControl } from 'pages/DocumentPage/components/DocumentControl';
import { RoutTable } from 'pages/DocumentPage/components/TableDT/RoutTable';
import { AssignmentTable, CommentsTable } from 'pages/DocumentPage/components';
import { GeneralInfo } from 'pages/DocumentPage/components/GeneralInfo';
import { DrawerHeader } from './DrawerHeader';
import { SignaturesTable } from 'pages/DocumentPage/components';
import { format } from 'date-fns';
import { copyToClipboard, getHost } from 'shared/utils';
import { Icon } from '../IconComponent';
import linkSvg from 'shared/assets/images/Link.svg';

type TDocumentDrawer = {
  onClose: (bool: boolean) => void;
  visible: boolean;
  documentId?: number | null;
};

export const DocumentDrawer = React.forwardRef(
  ({ onClose, visible, documentId }: TDocumentDrawer) => {
    const [
      currentDocument,
      setCurrentDocument,
    ] = useState<CurrentDocument | null>(null);
    const [loader, setLoader] = useState<boolean>(true);

    const getDocumentInfo = useCallback(() => {
      setLoader(true);
      $api
        .get(`Documents/${documentId}/full`)
        .then(({ data }) => {
          if (data?.SendType)
            $api
              .get(`/SendTypes/${data?.SendType}`)
              .then(rec => {
                const sendTypeName = { sendTypeName: rec.data.Name };
                setCurrentDocument(Object.assign(sendTypeName, data));
              })
              .catch(err => console.log(err.message))
              .finally(() => setLoader(false));
          else {
            setCurrentDocument(data);
            setLoader(false);
          }
        })
        .catch(err => console.log(err));
    }, [documentId]);

    useEffect(() => {
      if (documentId) getDocumentInfo();
    }, [documentId, getDocumentInfo]);
    const PAGE_TITLE = `Документ № ${currentDocument?.RegNumber ?? 'б/н'} от ${
      currentDocument?.RegDate
        ? format(new Date(currentDocument?.RegDate), 'dd.MM.yyyy')
        : '-'
    }`;

    return (
      <Drawer
        placement="right"
        onClose={() => onClose(false)}
        visible={visible}
        closeIcon={null}
        className="drawer"
      >
        <div className="page3">
          {loader ? (
            <Skeleton />
          ) : (
            <div>
              <DrawerHeader onClose={onClose} text={PAGE_TITLE} />
              <DocumentInfo currentDocument={currentDocument} />
              <h2 className="mt35">Общая информация</h2>
              <GeneralInfo currentDocument={currentDocument} canEdit={false} />
              <h2 className="mt35">Задачи</h2>
              <AssignmentTable assignments={currentDocument?.Assignments} />
              <h2 className="mt35">Подписи документа</h2>
              <SignaturesTable documentId={currentDocument?.Id} />
              <h2 className="mt35">Контроль</h2>
              <DocumentControl
                currentDocument={currentDocument}
                canEdit={false}
              />
              <RoutTable documentId={currentDocument?.Id} />
              <CommentsTable documentId={currentDocument?.Id} type="read" />
              <Icon
                icon={linkSvg}
                alt="linkSvg"
                onClick={() =>
                  copyToClipboard(
                    `${getHost()}/active-documents/Document${
                      currentDocument?.Type
                    }/${currentDocument?.Id}`,
                  )
                }
                className="footer-navigation mt20 mb20"
                children="Получить ссылку"
              />
            </div>
          )}
        </div>
      </Drawer>
    );
  },
);
