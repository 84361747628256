import axios from 'axios';
import { History } from 'history';
import { prefixSSO } from '../prefix';
import { deleteCookie, getAuthToken } from 'shared/utils/getAuthToken';
import { authUser } from 'store/slice/auth';
import { reset } from 'store/slice/table';
import { AppDispatch } from 'store/store';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { updateActiveCount } from 'store/slice/sidebar';
import { User } from 'store/slice/table';
import { notification } from 'antd';
import login_error from 'shared/assets/images/login-error.png';

type AccountParams = {
  dispatch: AppDispatch;
  history: History;
  values?: Record<string, string>;
  setShowError?: (b: boolean) => void;
  setErrorText: (text: string) => void;
};

type IsAuthParams = {
  dispatch: AppDispatch;
  history: History;
};

export const authCheck = (
  { dispatch, history }: IsAuthParams,
  value?: string,
) => {
  $api
    .get(`Accounts/IsAuthorize`, {
      headers: getAuthToken('auth_token'),
    })
    .then(({ data }) => {
      dispatch(authUser(true));
      history.push('/active-documents/page=1');
      value && checkDefaultPassword(value, history, data);
      value && checkOldPassword(data, history);
    })
    .catch(({ response }) => {
      errorThrow(response);
      deleteCookie('auth_token');
    });
};

export const checkOldPassword = (data: User, history: History) => {
  axios
    .get(
      `${prefixSSO}/Accounts/${data?.CurrentUser?.UserId}/IsChangedOldPassword`,
      { headers: getAuthToken('auth_token') },
    )
    .then(res => {
      if (res?.data) {
        notification['error']({
          message: (
            <span style={{ fontSize: 18 }}>
              Срок действия пароля истек. Необходимо сменить пароль
            </span>
          ),
          className: 'custom-notification__warning',
          icon: <img src={login_error} alt="error" />,
          placement: 'top',
        });
        history.push(`/officer-card/${data?.CurrentContact?.Id}`);
      }
    })
    .catch(({ response }) => {
      errorThrow(response);
    });
};

export const checkDefaultPassword = (
  value: string,
  history: History,
  data: User,
) => {
  axios
    .post(
      `${prefixSSO}/Accounts/IsDefaultPassword`,
      { Password: value },
      { headers: getAuthToken('auth_token') },
    )
    .then(res => {
      if (res?.data) {
        notification['error']({
          message: 'Пожалуйста, смените пароль',
          className: 'custom-notification__warning',
          icon: <img src={login_error} alt="error" />,
        });
        history.push(`/officer-card/${data?.CurrentContact?.Id}`);
      } else history.push(`/`);
    })
    .catch(({ response }) => {
      errorThrow(response);
    });
};

export const onLogin = ({
  dispatch,
  history,
  values,
  setShowError,
  setErrorText,
}: AccountParams) => {
  axios
    .post(`${prefixSSO}/Accounts/Login`, {
      Login: values?.username,
      Password: values?.password,
    })
    .then(() => {
      authCheck({ dispatch, history }, values?.password);
    })
    .catch(({ response }) => {
      setShowError ? setShowError(true) : errorThrow(response);
      setErrorText(response?.data?.title);
    });
};

export const onLogout = (dispatch: AppDispatch): void => {
  axios
    .post(
      `${prefixSSO}/Accounts/Logout`,
      {},
      {
        headers: getAuthToken('auth_token'),
      },
    )
    .then(() => {
      sessionStorage.removeItem('previousUserInfo');
      dispatch(authUser(false));
      dispatch(reset());
      dispatch(updateActiveCount(null));
    })
    .catch(({ response }) => {
      errorThrow(response);
    })
    .finally(() => {
      deleteCookie('auth_token');
      window.location.href = '/login';
    });
};
