import { DButton, DTextarea } from 'shared/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { UploadFiles } from 'shared/components/Files';
import { ChildAssignmentDrawer } from 'shared/components/CustomDrawers/ChildAssignmentDrawer';
import { $api } from 'shared/utils/api';
import { AssignmentType, CurrentDocument } from 'types/document';
import { errorThrow, formatFiles } from 'shared/utils';
import { useFormik } from 'formik';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { format } from 'date-fns';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import moment from 'moment';
import { AssignmentGeneralInfo } from './AssignmentGeneralInfo';
import { AssignmentTypes, Phases, RequestTypes } from '../types';
import deleteSvg from 'shared/assets/images/delete.svg';
import { Icon } from 'shared/components/IconComponent';
import { FileListParams } from 'shared/components/Files/types';
import { formatFilesToUpdateState } from 'shared/utils/fromatFilesToSend';
import { PreLoader } from 'shared/components/PreLoader';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { SignedForm } from './SignedForm';

type TAssignmentApprovalForm = {
  phases: Phases[];
  onReject: (
    resolution?: string,
    documentId?: number,
    assignmentId?: number,
  ) => void;
  assignment?: AssignmentType;
  documentData?: CurrentDocument | null;
  onSuccessResponse: (text: string) => void;
};

export type TLinkList = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  AssignmentId: number;
  LinkedDocumentId: number;
  DocumentRegDate: string;
  DocumentRegNumber: string;
};

export const AssignmentApprovalForm = ({
  phases,
  onReject,
  assignment,
  documentData,
  onSuccessResponse,
}: TAssignmentApprovalForm) => {
  const [visibleChild, setVisibleChild] = useState<boolean>(false);
  const [currentFiles, setCurrentFiles] = useState<FileListParams[]>([]);
  const [childType, setChildType] = useState<string>('');
  const [linkList, setLinkList] = useState<TLinkList[]>([]);
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [selectedPhase, setSelectedPhase] = useState<Phases | null>();
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { disabledButton } = useSelector(
    (state: ReduxState) => state.creatingDocumentReducer,
  );
  const { signedInfo } = useSelector(
    (state: ReduxState) => state.assignmentReducer,
  );
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const documentId = documentData?.Id;
  const onCloseCatalog = () => setVisibleCatalog(false);

  const onCloseChildDrawer = async (bool: boolean) => {
    if (isFollowing && bool) {
      await executeAssignment({ Resolution: formik.values.Resolution });
    }
    setVisibleChild(bool);
  };

  const getLinks = useCallback(() => {
    if (assignment?.Id)
      $api
        .get(`assignments/${assignment?.Id}/links`)
        .then(res => setLinkList(res.data))
        .catch(({ response }) => errorThrow(response));
  }, [assignment?.Id]);

  useEffect(() => {
    getLinks();
    assignment?.Files &&
      setCurrentFiles(formatFilesToUpdateState(assignment?.Files));
  }, [getLinks]);

  const initialValues = {
    Resolution: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      executeAssignment(values);
    },
  });

  const updateResolution = useCallback(
    (resolution, item: Phases) => {
      if (assignment?.Type === 'ToApproval') {
        const appendPart = item?.LinkName ? `(${item?.LinkName})` : '';

        return resolution + appendPart;
      } else {
        return resolution;
      }
    },
    [assignment?.Type],
  );

  const executeAssignment = async (values: { Resolution: string }) => {
    const request = RequestTypes[assignment?.Type as AssignmentTypes];
    setIsLoading(true);

    await $api
      .patch(`/Assignments/${assignment?.Id}/${request}`, {
        CertificateThumbprint:
          signedInfo?.CertificateThumbprint !== 'Unknown'
            ? signedInfo?.CertificateThumbprint
            : null,
        DocumentSignatureData:
          signedInfo?.CertificateThumbprint !== 'Unknown'
            ? signedInfo?.DocumentSignatureData
            : null,
        DocumentSignature:
          signedInfo?.CertificateThumbprint !== 'Unknown'
            ? signedInfo?.DocumentSignature
            : null,
        PhaseId:
          assignment?.ParentId && !assignment?.AutoExecute
            ? null
            : selectedPhase?.Id,
        Resolution: selectedPhase
          ? updateResolution(values.Resolution, selectedPhase)
          : values.Resolution,
        ExecutionFactTime: moment(),
        FileIds: formatFiles(currentFiles),
      })
      .then(() => onSuccessResponse('Задача успешно исполнена'))
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  };

  const onAddLink = (item: TLinkList) => {
    const isExist = linkList
      .map(item => item.LinkedDocumentId)
      .filter(Id => Id === item.Id);
    if (!isExist.length)
      $api
        .post(`assignments/${assignment?.Id}/links`, {
          CreateDate: moment(),
          UpdateDate: moment(),
          AssignmentId: assignment?.Id,
          LinkedDocumentId: item.Id,
        })
        .then(getLinks)
        .catch(({ response }) => errorThrow(response));
  };

  const onRemoveLinks = (id: any) => {
    $api
      .delete(`assignments/${assignment?.Id}/links/${id}`)
      .then(() => {
        const array = linkList.filter(item => item.Id !== id);
        setLinkList(array);
      })
      .catch(({ response }) => errorThrow(response));
  };

  const executorIsCurrentUser =
    assignment?.ExecutorId === user?.CurrentContact?.Id ||
    assignment?.ExecutionGroupId === user?.CurrentContact?.DepartmentId;

  return (
    <>
      {(isLoading || disabledButton) && <PreLoader />}
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={onCloseCatalog}
          type="document"
          func={onAddLink}
        />
      )}
      {visibleChild && (
        <ChildAssignmentDrawer
          visible={visibleChild}
          onClose={onCloseChildDrawer}
          assignment={assignment}
          childType={childType}
          documentData={documentData}
          isFollowing={isFollowing}
        />
      )}
      <AssignmentGeneralInfo
        assignment={assignment}
        documentData={documentData}
      />
      <form className="drawer-form" onSubmit={formik.handleSubmit}>
        {assignment?.Type !== 'Delegated' && executorIsCurrentUser ? (
          <div style={{ float: 'right', display: 'flex' }} className="mb15">
            <DButton
              className="mr10"
              onClick={() => {
                setVisibleChild(true);
                setChildType('ToExecute');
              }}
              small
            >
              Выдать дочернее
            </DButton>
            <DButton
              onClick={() => {
                setVisibleChild(true);
                setChildType('Delegated');
              }}
              small
            >
              Делегировать
            </DButton>
          </div>
        ) : null}
        {assignment?.SigningRequired ? (
          <SignedForm assignment={assignment as AssignmentType} />
        ) : null}
        <DFormItemTwo label="Комментарий к резолюции" name="Resolution">
          <DTextarea
            className="w100per"
            value={formik.values.Resolution}
            name="Resolution"
            onChange={formik.handleChange}
          />
        </DFormItemTwo>
        <DFormItemTwo
          label="Связанные документы"
          style={{ alignItems: 'baseLine' }}
        >
          <div className="w100per">
            {linkList?.length ? (
              <div className="w100per">
                {linkList?.map(item => (
                  <div className="flex-space">
                    <div className="mb5">
                      <p className="custom-link" style={{ marginBottom: 0 }}>
                        Документ {item.DocumentRegNumber ?? 'б/н'} от{' '}
                        {item.DocumentRegDate
                          ? format(new Date(item.DocumentRegDate), 'dd.MM.yyyy')
                          : '-'}
                      </p>
                    </div>
                    <Icon
                      icon={deleteSvg}
                      alt="deleteSvg"
                      onClick={() => onRemoveLinks(item.Id)}
                      tooltip="Удалить"
                    />
                  </div>
                ))}
              </div>
            ) : null}
            <span
              className="custom-link"
              onClick={() => setVisibleCatalog(true)}
            >
              Создать связь
            </span>
          </div>
        </DFormItemTwo>
        <DFormItemTwo
          label="Мои версии документа"
          name="Documents"
          className="form-item__with-list"
        >
          <UploadFiles
            setCurrentFiles={setCurrentFiles}
            currentFiles={currentFiles}
          />
        </DFormItemTwo>
        <DFormItemTwo className="form-buttons">
          {phases.length ? (
            phases.map(item => (
              <DButton
                onClick={() => {
                  setSelectedPhase(item);
                }}
                disabled={disabledButton}
                className="mr15"
                primary
                small
                type="submit"
              >
                {item?.LinkName ?? 'Выполнить'}
              </DButton>
            ))
          ) : (
            <DButton
              onClick={() => setSelectedPhase(null)}
              className="mr15"
              disabled={disabledButton}
              primary
              small
              type="submit"
            >
              Выполнить
            </DButton>
          )}
          {(!phases.length || phases.length === 1) && executorIsCurrentUser ? (
            <DButton
              onClick={() => {
                setIsFollowing(true);
                setVisibleChild(true);
                setChildType('ToExecute');
                setSelectedPhase(phases?.[0] ?? null);
              }}
              className="mr15"
              disabled={disabledButton}
              defaultPrimary
              small
            >
              По итогам
            </DButton>
          ) : null}

          <DButton
            small
            defaultDanger
            disabled={disabledButton}
            onClick={() =>
              onReject(formik.values.Resolution, documentId, assignment?.Id)
            }
          >
            Отклонить
          </DButton>
        </DFormItemTwo>
      </form>
    </>
  );
};
