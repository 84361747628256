import { phaseReducer } from './slice/phases/phases';
import { combineReducers } from '@reduxjs/toolkit';
import { reportReducer } from 'store/slice/reports';
import { authReducer } from './slice/auth';
import { tableDataReducer } from './slice/table';
import { routingReducer } from './slice/routing';
import { sidebarReducer } from './slice/sidebar';
import { assignmentReducer } from './slice/assignments';
import { documentInfoReducer } from './slice/document';
import { calendarReducer } from './slice/calendar';
import { creatingDocumentReducer } from './slice/creatingDocument';
import { userGroupsReducer } from './slice/userGroups';

export const rootReducer = combineReducers({
  authReducer,
  tableDataReducer,
  routingReducer,
  sidebarReducer,
  assignmentReducer,
  documentInfoReducer,
  reportReducer,
  calendarReducer,
  creatingDocumentReducer,
  phaseReducer,
  userGroupsReducer,
});
