import { DrawerHeader } from './DrawerHeader';
import { Drawer, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { ContactList } from 'pages/CreateDocument/types';
import { DButton, DInput, DTextarea } from 'shared/ui-kit';
import moment, { Moment } from 'moment';
import { AssignmentType, CurrentDocument } from 'types/document';
import { $api } from 'shared/utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { errorThrow, successNotification } from 'shared/utils';
import { fetchAssignmentsData } from 'api/assignments/fetchAssignmentsData';
import _ from 'lodash';
import { selectActiveAssignment } from 'store/slice/assignments';
import { DatePick } from 'shared/components/DatePicker/DatePick';
import { useFormik } from 'formik';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { CatalogDrawer } from './CatalogDrawer';
import { TDepartment } from 'pages/AdminPage/Departments';
import { format } from 'date-fns';
import deleteSvg from 'shared/assets/images/delete.svg';
import { Icon } from '../IconComponent';
import { CatalogButton } from '../CatalogButton';
import { FileListParams } from '../Files/types';
import { UploadFiles } from '../Files';
import { LinkListTypes } from './IssuanceAssignmentDrawer/types';
import {
  formatFiles,
  formatFilesToUpdateState,
} from 'shared/utils/fromatFilesToSend';
import { PreLoader } from '../PreLoader';

type TAssignmentEditDrawer = {
  visible: boolean;
  onClose: (bool: boolean) => void;
  assignment?: AssignmentType;
  sendFiles: any;
  getAssignment?: () => void;
  documentData?: CurrentDocument | null;
};

export const AssignmentEditDrawer = ({
  visible,
  onClose,
  assignment,
  getAssignment,
  documentData,
}: TAssignmentEditDrawer) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [currentFiles, setCurrentFiles] = useState<FileListParams[]>([]);
  const [departments, setDepartments] = useState<TDepartment[]>([]);
  const [executors, setExecutors] = useState<ContactList[]>([]);
  const [catalogType, setCatalogType] = useState<
    'contacts' | 'document' | 'departments'
  >('contacts');
  const [linkList, setLinkList] = useState<LinkListTypes[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const { Option } = Select;
  const { dates } = useSelector((state: ReduxState) => state.calendarReducer);
  const { disabledButton } = useSelector(
    (state: ReduxState) => state.creatingDocumentReducer,
  );
  const dispatch = useDispatch();

  const onCloseCatalog = () => setVisibleCatalog(false);

  const getContacts = async (query?: string) => {
    try {
      const { data } = await $api.get(`contacts/byorganization/active`, {
        params: {
          FastSearch: query,
        },
      });
      return data;
    } catch ({ response }) {
      errorThrow(response);
    }
  };

  const getLinks = useCallback(() => {
    $api
      .get(`assignments/${assignment?.Id}/links`)
      .then(res => setLinkList(res.data))
      .catch(({ response }) => errorThrow(response));
  }, [assignment?.Id]);

  const onAddLink = (item: any) => {
    const isExist = linkList
      .map((item: any) => item.LinkedDocumentId)
      .filter((Id: number) => Id === item.Id);
    if (!isExist.length)
      setLinkList((prev: any) => [
        ...prev,
        {
          DocumentRegDate: item.RegDate,
          DocumentRegNumber: item.RegNumber,
          CreateDate: new Date().toISOString(),
          UpdateDate: new Date().toISOString(),
          LinkedDocumentId: item.Id,
          AssignmentId: null,
        },
      ]);
  };

  const onRemoveLinks = (id: any) => {
    const newArray = linkList.filter(
      (item: any) => item.LinkedDocumentId !== id,
    );
    setLinkList(newArray);
  };

  const getData = useCallback(() => {
    if (!assignment?.ExecutionGroupId)
      getContacts().then(list => {
        setExecutors(list);
        $api
          .get(`Contacts/${assignment?.ExecutorId}`)
          .then(res => setExecutors(prev => [...prev, res.data]))
          .catch(({ response }) => errorThrow(response));
        if (assignment?.AuditorId)
          $api
            .get(`Contacts/${assignment?.AuditorId}`)
            .then(res => setExecutors(prev => [...prev, res.data]))
            .catch(({ response }) => errorThrow(response));
      });
    else
      $api
        .get(`departments/byorganization`)
        .then(res => setDepartments(res.data))
        .catch(({ response }) => errorThrow(response));
    getLinks();
  }, [assignment, getLinks]);

  useEffect(() => {
    getData();
    assignment?.Files &&
      setCurrentFiles(formatFilesToUpdateState(assignment?.Files));
  }, [getData]);

  const getIdsFromLinkList = () => linkList.map(item => item.LinkedDocumentId);

  const onFinish = (values: any) => {
    setIsLoading(true);
    $api
      .put(`Assignments/${assignment?.Id}`, {
        ...assignment,
        ExecutorId: !assignment?.ExecutionGroupId ? values.ExecutorId : null,
        ExecutionGroupId: assignment?.ExecutionGroupId
          ? values.ExecutorId
          : null,
        ResponsibleId: values.ExecutorId,
        Subject: values.Subject,
        Content: values.text,
        AuditorId: values.Controler,
        ExecutionTime: values.deadlineDate._d,
        LinkedDocumentIds: getIdsFromLinkList(),
        FileIds: formatFiles(currentFiles),
      })
      .then(res => {
        const type = 'all';
        successNotification('Задача изменена');
        assignment?.Id && dispatch(selectActiveAssignment(assignment?.Id));
        fetchAssignmentsData({
          array: documentData?.Assignments,
          dispatch,
          type,
        });
        onClose(false);
        if (getAssignment) {
          getAssignment();
        }
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  };

  const initialValues: any = {
    ExecutorId: assignment?.ExecutionGroupId ?? assignment?.ExecutorId,
    Subject: assignment?.Subject,
    text: assignment?.Content,
    deadlineDate: moment(
      // @ts-ignore
      moment(new Date(assignment?.ExecutionTime), 'DD-MM-YYYY HH:mm'),
    ),
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  const updateExecutor = (item: ContactList) => {
    if (catalogType === 'contacts') {
      setExecutors(prev => [...prev, item]);
      formik.setFieldValue('ExecutorId', item.Id);
    } else {
      onAddLink(item);
    }
  };

  const disableDates = dates.filter(item => item.holiday);
  const disabledDate = (current: moment.Moment) => {
    const lessRealCurrentDate = current.isBefore(moment().startOf('day'));

    return (
      lessRealCurrentDate ||
      !!disableDates.find(
        date => date.date === moment(current).format('DD.MM.YYYY'),
      )
    );
  };

  return (
    <>
      {(isLoading || disabledButton) && <PreLoader />}
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={onCloseCatalog}
          type={catalogType}
          func={updateExecutor}
        />
      )}
      <Drawer
        placement="right"
        onClose={() => onClose(false)}
        visible={visible}
        className="drawer"
        closeIcon={null}
      >
        <div className="page3">
          <DrawerHeader onClose={onClose} text="Редактирование задачи" />
          <form className="drawer-form" onSubmit={formik.handleSubmit}>
            <DFormItemTwo label="Исполнитель" name="ExecutorId">
              <Select
                className={
                  !showError || formik.values.ExecutorId !== undefined
                    ? 'ui-select'
                    : 'ui-select-danger'
                }
                value={formik.values.ExecutorId}
                showSearch
                allowClear
                optionFilterProp="children"
                onChange={(t: number) => {
                  setShowError(false);
                  formik.setFieldValue('ExecutorId', t);
                }}
              >
                {!assignment?.ExecutionGroupId
                  ? _.uniqBy(executors, 'Id').map(executor => (
                      <Option key={executor.Id} value={executor.Id}>
                        {executor.LastName} {executor.FirstName}{' '}
                        {executor.MiddleName}
                      </Option>
                    ))
                  : _.uniqBy(departments, 'Id').map(department => (
                      <Option key={department.Id} value={department.Id}>
                        {department.Name}
                      </Option>
                    ))}
              </Select>
              <CatalogButton
                disabled={!!assignment?.ExecutionGroupId}
                onClick={() => {
                  setVisibleCatalog(true);
                  setCatalogType('contacts');
                }}
              />
            </DFormItemTwo>
            <DFormItemTwo required label="Тема" name="Subject">
              <DInput
                type="text"
                width="100%"
                name="Subject"
                style={{ height: 36 }}
                onChange={e => {
                  e.target.value.length > 0 && setShowError(false);
                  formik.handleChange(e);
                }}
                value={formik.values.Subject}
                required={showError}
              />
            </DFormItemTwo>
            <DFormItemTwo
              style={{ alignItems: 'baseLine' }}
              label="Текст"
              name="text"
            >
              <DTextarea
                name="text"
                className="w100per"
                onChange={formik.handleChange}
                value={formik.values.text}
              />
            </DFormItemTwo>
            <DFormItemTwo required label="Срок исполнения" name="deadlineDate">
              <DatePick
                style={{ height: 36, borderRadius: 8 }}
                showTime={{ minuteStep: 30 }}
                format="DD.MM.YYYY HH:mm"
                placeholder="Выберите дату"
                disabledDate={disabledDate}
                allowClear={false}
                onChange={(date: Moment) => {
                  formik.setFieldValue('deadlineDate', date);
                }}
                value={formik.values.deadlineDate}
              />
            </DFormItemTwo>
            <DFormItemTwo
              label="Связанные документы"
              style={{ alignItems: 'baseLine' }}
            >
              <div className="w100per">
                {linkList.length ? (
                  <div className="w100per">
                    {linkList.map((item: any) => (
                      <div className="flex-space">
                        <div className="mb5">
                          <p
                            className="custom-link"
                            style={{ marginBottom: 0 }}
                          >
                            Документ {item.DocumentRegNumber ?? 'б/н'} от{' '}
                            {format(
                              new Date(item.DocumentRegDate),
                              'dd.MM.yyyy',
                            )}
                          </p>
                        </div>
                        <Icon
                          icon={deleteSvg}
                          alt="deleteSvg"
                          onClick={() => onRemoveLinks(item.LinkedDocumentId)}
                          tooltip="Удалить"
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
                <span
                  className="custom-link"
                  onClick={() => {
                    setVisibleCatalog(true);
                    setCatalogType('document');
                  }}
                >
                  Создать связь
                </span>
              </div>
            </DFormItemTwo>
            <DFormItemTwo
              label="Файлы"
              name="Files"
              className="form-item__with-list"
            >
              <UploadFiles
                setCurrentFiles={setCurrentFiles}
                currentFiles={currentFiles}
              />
            </DFormItemTwo>
            <DFormItemTwo className="form-buttons">
              <DButton
                defaultPrimary
                small
                type="submit"
                disabled={disabledButton}
                className="mr15"
              >
                Сохранить
              </DButton>
              <DButton defaultDanger small onClick={() => onClose(false)}>
                Закрыть
              </DButton>
            </DFormItemTwo>
          </form>
        </div>
      </Drawer>
    </>
  );
};
