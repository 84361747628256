import { TableMenu } from 'shared/components';
import { MainTable } from 'shared/components/MainTable';
import { MatchParams } from 'types/tableData';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { useLocation } from 'react-router';
import { updateTableData } from 'pages/MainPage/MainPage';
import { getActiveNumbers } from 'shared/components/Sidebar/useSidebar';
import {
  updateButtonType,
  updateButtonTypeRegistration,
} from 'store/slice/table';
import { debounce } from 'lodash-es';

export const OnRegistration = ({ match }: MatchParams) => {
  const {
    tablePagination,
    buttonType,
    documentTableData,
    searchValue,
    activeSortParams,
  } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { substituateId } = useSelector(
    (state: ReduxState) => state.authReducer,
  );
  const { userOrganisations } = useSelector(
    (state: ReduxState) => state.sidebarReducer,
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const orgId = location.pathname.split('/')[1];
  const pageNumber = location.pathname
    .split('/')
    [location.pathname.split('/').length - 1].split('page=')[1];

  const updateTable = useCallback(
    debounce(() => {
      if (buttonType === 'inWork')
        updateTableData({
          type: `documents/byorganisation/${orgId}/register`,
          dispatch,
          tablePagination: {
            page: Number(pageNumber) ?? tablePagination?.page,
            pageSize: tablePagination?.pageSize,
          },
          orgId,
          searchValue: searchValue,
          sortParams: activeSortParams,
        });
    }, 500),
    [
      dispatch,
      buttonType,
      pageNumber,
      activeSortParams,
      tablePagination?.pageSize,
    ],
  );

  useEffect(() => {
    getActiveNumbers(dispatch, userOrganisations);
    updateTable();
  }, [dispatch, substituateId, updateTable, activeSortParams]);

  useEffect(() => {
    dispatch(updateButtonTypeRegistration('all'));
  }, [dispatch]);

  return (
    <section id="registration_table">
      <TableMenu />
      <MainTable
        data={{
          tableData: documentTableData.documents,
          filteredCount: documentTableData.filteredCount,
        }}
        match={match}
      />
    </section>
  );
};
