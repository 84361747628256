import moment from 'moment';

export const dateCompare = (
  ExecutionTime: string | Date,
  ExecutionFactTime?: string,
) => {
  const assignmentFactTime = ExecutionFactTime
    ? moment(ExecutionFactTime).startOf('day')
    : moment().startOf('day');
  const assignmentDate = moment(ExecutionTime).startOf('day');

  if (assignmentDate.isSame(assignmentFactTime)) {
    return 'today';
  }

  if (assignmentDate.isBefore(assignmentFactTime)) {
    return 'expired';
  }

  return '';
};
