import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import { useFormik } from 'formik';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TUserTagList } from 'shared/components/CustomDrawers/FilterDrawer/types';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { errorThrow, successNotification } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';

export const useCreateTagDrawer = (onClose: (visible: boolean) => void) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userTagList, setUserTagList] = useState<TUserTagList[]>([]);
  const [selectedTag, setSelectedTag] = useState<number | null>(null);
  const [searchTags, setSearchTags] = useState<TUserTagList[]>([]);
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const dispatch = useDispatch();
  const documentType = 'Documents' + documentInfo?.Type;
  const documentId = documentInfo?.Id;
  const formik = useFormik({
    initialValues: {
      Name: '',
    },
    onSubmit: values => {
      onFinish(values);
    },
  });
  const disabledButton = !selectedTag && !formik.values.Name;

  const getUserTags = useCallback(() => {
    setIsLoading(true);
    $api
      .get('tags/byuser')
      .then(({ data }) => {
        setUserTagList(data);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, []);

  const getAllTags = useCallback((name?: string) => {
    $api
      .get(`tags/Search?Name=${name ?? ''}`)
      .then(({ data }) => setSearchTags(name?.length ? data : []))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const onSearchTag = debounce(value => {
    setSelectedTag(null);
    getAllTags(value);
  }, DEBOUNCE_TIME);

  useEffect(() => {
    getUserTags();
  }, [getUserTags]);

  const onCloseDrawer = () => {
    formik.resetForm();
    onClose(false);
  };

  const onSuccessResponse = () => {
    successNotification('Тег добавлен');
    getDocumentInfo({ documentType, documentId, dispatch });
    onCloseDrawer();
  };

  const onFinish = ({ Name }: { Name: string }) => {
    if (selectedTag) {
      $api
        .put(`Documents/${documentId}/tags/${selectedTag}/attach`)
        .then(onSuccessResponse)
        .catch(({ response }) => errorThrow(response));
    } else {
      $api
        .post(`Tags`, {
          Name,
        })
        .then(res => {
          return $api.put(`Documents/${documentId}/tags/${res.data.Id}/attach`);
        })
        .then(onSuccessResponse)
        .catch(({ response }) => errorThrow(response));
    }
  };

  const updateSelectedTag = useCallback(
    (id: number) => setSelectedTag(selectedTag !== id ? id : null),
    [selectedTag],
  );

  return {
    formik,
    isLoading,
    userTagList,
    updateSelectedTag,
    selectedTag,
    onSearchTag,
    searchTags,
    onCloseDrawer,
    disabledButton,
  };
};
