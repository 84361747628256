import { Descriptions, Drawer, Form, Skeleton, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { OfficerDrawer } from './OfficerDrawer';
import './CustomDrawer.scss';
import { errorThrow } from 'shared/utils';
import { DrawerHeader } from './DrawerHeader';

type TOrganisationDrawer = {
  visibleDrawer: boolean;
  onCloseDrawer: (bool: boolean) => void;
  organisationId?: number | null;
};

export const OrganisationDrawer = ({
  visibleDrawer,
  onCloseDrawer,
  organisationId,
}: TOrganisationDrawer) => {
  const [organisationInfo, setOrganisationInfo] = useState<any>();
  const [contactsInfo, setContactsInfo] = useState<any>();
  const [loader, setLoader] = useState<boolean>(true);
  const [visibleOfficerDrawer, setVisibleOfficerDrawer] = useState<boolean>(
    false,
  );
  const [clickableUser, setClickableUser] = useState<number>(0);

  const onCloseOfficerDrawer = (bool: boolean) => {
    setVisibleOfficerDrawer(bool);
  };

  const officerColumns = [
    {
      title: 'Id',
      key: 'Id',
      dataIndex: 'Id',
    },
    {
      title: 'Имя',
      key: 'Name',
      dataIndex: 'Name',
      render: (text: string, item: any) => (
        <span
          className="subject"
          onClick={() => {
            setClickableUser(item?.Id);
            setVisibleOfficerDrawer(true);
          }}
        >
          {item?.LastName} {item?.FirstName} {item?.MiddleName}
        </span>
      ),
    },
    {
      title: 'Должность',
      key: 'PositionName',
      dataIndex: 'PositionName',
    },
    {
      title: 'Номер телефона',
      key: 'PhoneNumber',
      dataIndex: 'PhoneNumber',
    },
    {
      title: 'Почта',
      key: 'Email',
      dataIndex: 'Email',
    },
  ];

  useEffect(() => {
    $api
      .get(`Organisations/${organisationId}`)
      .then(res => setOrganisationInfo(res.data))
      .catch(({ response }) => {
        errorThrow(response);
      })
      .finally(() => setLoader(false));

    $api
      .get(`contacts/active/byorganization/${organisationId}/page`, {
        params: {
          Limit: 1000,
        },
      })
      .then(res => setContactsInfo(res.data.Data))
      .catch(({ response }) => {
        errorThrow(response);
      })
      .finally(() => setLoader(false));
  }, [organisationId]);

  return (
    <Drawer
      placement="right"
      onClose={() => onCloseDrawer(false)}
      visible={visibleDrawer}
      className="drawer"
      closeIcon={null}
    >
      <div className="page3">
        <DrawerHeader onClose={onCloseDrawer} text="Карточка организации" />
        {loader ? (
          <Skeleton />
        ) : (
          <div className="form">
            <OfficerDrawer
              visibleDrawer={visibleOfficerDrawer}
              onCloseDrawer={onCloseOfficerDrawer}
              officerId={clickableUser}
            />
            <Form
              name="basic"
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 16 }}
              autoComplete="off"
              className="drawer-form"
            >
              <Form.Item className="item" label="Наименование">
                <span>{organisationInfo?.Name}</span>
              </Form.Item>

              <Descriptions
                className="mt20"
                bordered
                title="Контактная информация"
                layout="horizontal"
              >
                <Descriptions.Item label="Электронная почта:">
                  {organisationInfo?.Email ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Место расположения:">
                  {organisationInfo?.Address ?? '-'}
                </Descriptions.Item>
                {organisationInfo?.Type === 'Medo' && (
                  <Descriptions.Item label="МЭДО:">
                    {organisationInfo?.ParticipantName}
                  </Descriptions.Item>
                )}
              </Descriptions>
              <h2 className="mt20">Сотрудники</h2>
              <Table
                bordered
                columns={officerColumns}
                dataSource={contactsInfo}
              />
            </Form>
          </div>
        )}
      </div>
    </Drawer>
  );
};
