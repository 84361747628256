import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { Skeleton } from 'antd';
import { OrganizationStatistics } from './components/OrganizationStatistics';
import { PageHeader } from 'shared/components/PageHeader';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { errorThrow } from 'shared/utils';
import { DButton } from 'shared/ui-kit';
import { RangePick } from 'shared/components/DatePicker/RangePick';
import { STATISTICS_PAGE } from 'shared/constants/helpCodes';

type TMonth = {
  Text: string;
  StartDate: string;
  EndingDate: string;
};

const months: TMonth[] = [
  {
    Text: 'За прошлый месяц',
    StartDate: moment()
      .subtract(1, 'months')
      .startOf('month')
      .utcOffset(0, true)
      .format('YYYY-MM-DDThh:mm:ssZ'),
    EndingDate: moment()
      .subtract(1, 'months')
      .endOf('month')
      .utcOffset(0, true)
      .format('YYYY-MM-DDThh:mm:ssZ'),
  },
  {
    Text: 'За текущий месяц',
    StartDate: moment()
      .startOf('month')
      .utcOffset(0, true)
      .format('YYYY-MM-DDThh:mm:ssZ'),
    EndingDate: moment()
      .utcOffset(0, true)
      .format('YYYY-MM-DDThh:mm:ssZ'),
  },
  {
    Text: 'Первый квартал',
    StartDate: moment()
      .startOf('month')
      .utcOffset(0, true)
      .format(
        `${moment()
          .utcOffset(0, true)
          .format('YYYY')}-01-01Thh:mm:ssZ`,
      ),
    EndingDate: moment()
      .utcOffset(0, true)
      .format(
        `${moment()
          .utcOffset(0, true)
          .format('YYYY')}-03-01Thh:mm:ssZ`,
      ),
  },
  {
    Text: 'Второй квартал',
    StartDate: moment()
      .startOf('month')
      .utcOffset(0, true)
      .format(
        `${moment()
          .utcOffset(0, true)
          .format('YYYY')}-04-01Thh:mm:ssZ`,
      ),
    EndingDate: moment()
      .utcOffset(0, true)
      .format(
        `${moment()
          .utcOffset(0, true)
          .format('YYYY')}-06-01Thh:mm:ssZ`,
      ),
  },
  {
    Text: 'Третий квартал',
    StartDate: moment()
      .startOf('month')
      .utcOffset(0, true)
      .format(
        `${moment()
          .utcOffset(0, true)
          .format('YYYY')}-07-01Thh:mm:ssZ`,
      ),
    EndingDate: moment()
      .utcOffset(0, true)
      .format(
        `${moment()
          .utcOffset(0, true)
          .format('YYYY')}-09-01Thh:mm:ssZ`,
      ),
  },
  {
    Text: 'Четвертый квартал',
    StartDate: moment()
      .startOf('month')
      .utcOffset(0, true)
      .format(
        `${moment()
          .utcOffset(0, true)
          .format('YYYY')}-10-01Thh:mm:ssZ`,
      ),
    EndingDate: moment()
      .utcOffset(0, true)
      .format(
        `${moment()
          .utcOffset(0, true)
          .format('YYYY')}-12-01Thh:mm:ssZ`,
      ),
  },
];

const StatisticsPage = () => {
  const [statisticList, setStatisticList] = useState<any>();
  const [loader, setLoader] = useState<boolean>(true);
  const [currentOrganisation, setCurrentOrganisation] = useState<any>();
  const [selectedButton, setSelectedButton] = useState<string>('');
  const [pickerDate, setPickerDate] = useState<moment.Moment[]>();
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  const getOrganisation = useCallback(() => {
    if (user?.CurrentContact?.OrganisationId)
      $api
        .get(`Organisations/${user?.CurrentContact?.OrganisationId}`)
        .then(res => {
          setCurrentOrganisation(res.data);
          setLoader(false);
        })
        .catch(({ response }) => errorThrow(response));
  }, [user?.CurrentContact?.OrganisationId]);

  useEffect(() => {
    setLoader(true);
    getOrganisation();
  }, [user?.CurrentContact?.OrganisationId]);

  const getData = useCallback((startDate, endDate) => {
    $api
      .get('Chief/Departments', { params: { Start: startDate, End: endDate } })
      .then(res => {
        setStatisticList(res.data);
        setLoader(false);
      })
      .catch(({ response }) => errorThrow(response));
  }, []);

  const onSelectDate = (item: { StartDate: string; EndingDate: string }) =>
    getData(item.StartDate, item.EndingDate);

  return (
    <section id="statistics">
      <PageHeader title="Личный кабинет руководителя" code={STATISTICS_PAGE} />
      {loader ? (
        <Skeleton.Input className="w300 mb10" active />
      ) : (
        <>
          <h2 className="statistics-title">{currentOrganisation?.Name}</h2>
          <div>
            {months.map(item => (
              <DButton
                className={`mr10 ${
                  selectedButton === item.Text ? 'selected-button' : ''
                }`}
                small
                onClick={() => {
                  onSelectDate({
                    StartDate: item.StartDate,
                    EndingDate: item.EndingDate,
                  });
                  setSelectedButton(item.Text);
                  setPickerDate(undefined);
                }}
              >
                {item.Text}
              </DButton>
            ))}
          </div>
          <RangePick
            onOpenChange={() => setSelectedButton('')}
            value={pickerDate}
            onChange={(date: moment.Moment[]) => {
              date &&
                onSelectDate({
                  StartDate: date[0]
                    .utcOffset(0, true)
                    .format('YYYY-MM-DDThh:mm:ssZ'),
                  EndingDate: date[1]
                    .utcOffset(0, true)
                    .format('YYYY-MM-DDThh:mm:ssZ'),
                });
              setPickerDate(date);
            }}
            className="mt10 w100per"
            style={{ height: 36, borderRadius: 8 }}
          />
        </>
      )}
      <div className="mt35">
        {loader ? (
          <Skeleton />
        ) : (
          <>
            <div key={statisticList?.OrganisationId}>
              {statisticList?.Departments?.map((depart: any) => (
                <OrganizationStatistics departList={depart} />
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export { StatisticsPage };
