import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UploadFiles } from 'shared/components/Files';
import { FileListParams } from 'shared/components/Files/types';
import { PreLoader } from 'shared/components/PreLoader';
import { DButton, DInput, DTextarea } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow, formatFiles } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { AssignmentType, CurrentDocument, TDocumentFile } from 'types/document';
import { Phases } from '../types';
import { AssignmentGeneralInfo } from './AssignmentGeneralInfo';

type TAssignmentToCheck = {
  phases: Phases[];
  assignment: AssignmentType;
  documentData?: CurrentDocument | null;
  onSuccessResponse: (text: string) => void;
};

type InitialValues = {
  Subject?: string;
  summary?: string;
  Resolution: string;
  Correspondent?: number;
};

export const AssignmentToCheck = ({
  phases,
  assignment,
  documentData,
  onSuccessResponse,
}: TAssignmentToCheck) => {
  const [currentFiles, setCurrentFiles] = useState<FileListParams[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [selectedPhase, setSelectedPhase] = useState<number | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { disabledButton } = useSelector(
    (state: ReduxState) => state.creatingDocumentReducer,
  );
  const initialValues: InitialValues = {
    Subject: documentData?.Subject,
    summary: documentData?.Summary,
    Resolution: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue('Subject', documentData?.Subject);
    formik.setFieldValue('summary', documentData?.Summary);
    setCurrentFiles(
      documentData?.Files.map((item: TDocumentFile) => ({
        file: {
          ...item,
          name: item.Name,
        },
      })) as FileListParams[],
    );
  }, [documentData]);

  const onFinish = (values: InitialValues) => {
    setIsLoading(true);
    $api
      .patch(`Assignments/${assignment?.Id}/Execute/Editing`, {
        PhaseId: selectedPhase ?? null,
        Resolution: values.Resolution,
        CorrespondentId: values.Correspondent,
        Subject: values.Subject,
        Summary: values.summary,
        FileIds: formatFiles(currentFiles),
        ExecutionFactTime: new Date().toISOString(),
      })
      .then(() => onSuccessResponse('Задача успешно исполнена'))
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {(isLoading || disabledButton) && <PreLoader />}
      <AssignmentGeneralInfo
        assignment={assignment}
        documentData={documentData}
      />
      <form className="drawer-form" onSubmit={formik.handleSubmit}>
        <DFormItemTwo label="Тема" name="Subject">
          <DInput
            required={showError}
            width="100%"
            type="text"
            value={formik.values.Subject}
            name="Subject"
            onChange={e => {
              formik.handleChange(e);
              e.target.value.length > 0 && setShowError(false);
            }}
          />
        </DFormItemTwo>
        <DFormItemTwo
          style={{ alignItems: 'baseLine' }}
          label="Краткое содержание"
          name="summary"
        >
          <DTextarea
            className="w100per"
            value={formik.values.summary}
            name="summary"
            onChange={e => {
              formik.handleChange(e);
            }}
          />
        </DFormItemTwo>
        <DFormItemTwo label="Загрузка файлов" className="form-item__with-list">
          <UploadFiles
            setCurrentFiles={setCurrentFiles}
            currentFiles={currentFiles}
          />
        </DFormItemTwo>
        <DFormItemTwo
          style={{ alignItems: 'baseLine' }}
          label="Комментарий исполнителя"
          name="Resolution"
        >
          <DTextarea
            className="w100per"
            value={formik.values.Resolution}
            name="Resolution"
            onChange={e => {
              formik.handleChange(e);
            }}
          />
        </DFormItemTwo>
        <DFormItemTwo className="form-buttons">
          {phases.length ? (
            phases.map(item => (
              <DButton
                onClick={() => setSelectedPhase(item.Id)}
                disabled={disabledButton}
                className="mr15"
                primary
                small
                type="submit"
              >
                {item.LinkName ?? 'Выполнить'}
              </DButton>
            ))
          ) : (
            <DButton
              onClick={() => setSelectedPhase(null)}
              className="mr15"
              disabled={disabledButton}
              primary
              small
              type="submit"
            >
              Выполнить
            </DButton>
          )}
        </DFormItemTwo>
      </form>
    </>
  );
};
