import { Link } from 'react-router-dom';
import { DButton } from 'shared/ui-kit';

export const YearItem = ({ year }: { year: number }) => {
  return (
    <Link to={`/archive/${year}`}>
      <DButton defaultPrimary>{year}</DButton>
    </Link>
  );
};
