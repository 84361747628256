import { Drawer, Table } from 'antd';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DButton } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { DrawerHeader } from './DrawerHeader';

type TFlowDrawer = {
  visible: boolean;
  onClose: () => void;
  documentStreamId?: number | null;
  onSelect: (id: number) => void;
};

type TProcesses = {
  DocumentStreamId: number;
  FlowId: number;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  Name?: string;
};

export const FlowDrawer = ({
  visible,
  onClose,
  documentStreamId,
  onSelect,
}: TFlowDrawer) => {
  const [processes, setProcesses] = useState<TProcesses[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { DocumentKind } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const organisationId = DocumentKind?.OrganisationId
    ? DocumentKind?.OrganisationId
    : documentInfo?.OrganisationId;

  const getRoutes = useCallback(async () => {
    setIsLoading(true);
    const res = await $api.get(`flows/byorganization/${organisationId}`);

    if (documentStreamId) {
      try {
        const { data } = await $api.get(
          `documentstreams/flows/${documentStreamId}`,
        );

        try {
          const result = data?.map((itemProcess: TProcesses) => {
            const flowListFilter = res?.data.filter(
              (value: Record<string, string | number>) =>
                value?.Id === itemProcess?.FlowId,
            );
            return { ...itemProcess, Name: flowListFilter[0]?.Name };
          });
          setProcesses(result);
        } catch ({ response }) {
          errorThrow(response);
        }

        setIsLoading(false);
      } catch ({ response }) {
        errorThrow(response);
      }
    } else {
      setIsLoading(false);
    }
  }, [documentStreamId, organisationId]);

  useEffect(() => {
    getRoutes();
  }, [getRoutes]);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Действие',
      dataIndex: 'Action',
      key: 'Action',
      render: (_d: undefined, item: TProcesses) => (
        <DButton
          onClick={() => {
            onSelect(item.FlowId);
            onClose();
          }}
          small
          defaultPrimary
        >
          Добавить
        </DButton>
      ),
    },
  ];

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Выбор процесса" />
        <Table loading={isLoading} columns={columns} dataSource={processes} />
      </div>
    </Drawer>
  );
};
