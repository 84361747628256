import moment from 'moment';
import { NomenclatureParams } from 'pages/AssignmentCard/types';
import { TODAY_DAY } from './constants';

export const getItemDay = (date: string) => moment(date).startOf('day');

export const getDataBeforeToday = (item: NomenclatureParams) =>
  item.EndDate !== null && getItemDay(item.EndDate).isBefore(TODAY_DAY);

export const getDataAfterToday = (item: NomenclatureParams) =>
  item.EndDate === null ||
  getItemDay(item.EndDate).isAfter(TODAY_DAY) ||
  getItemDay(item.EndDate).isSame(TODAY_DAY);
