export const checkLengthStringForSearch = (
  value: string,
  onSearch: (item: string) => void,
) => {
  if (value.length > 2) {
    onSearch(value);
  }

  if (!value.length) {
    onSearch('');
  }
};
