import { Menu } from 'antd';
import moment from 'moment';

export type THistoryColumns = {
  ActionType: string;
  Description: string;
  Id: number;
  ObjectId: number;
  ObjectType: string;
  ObjectValue: string;
  SubstitutionId: number;
  UserFirstName: string;
  UserId: number;
  UserLastName: string;
  UserMiddleName: string;
  IsGrouped: boolean;
};

export const useDrawer = () => {
  const historyColumns = [
    {
      title: 'Дата',
      dataIndex: 'CreateDate',
      key: 'CreateDate',
      render: (date: string) => moment(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Операция',
      dataIndex: 'Description',
      key: 'Description',
    },
    {
      title: 'Пользователь',
      dataIndex: 'UserId',
      key: 'UserId',
      render: (text: string, item: THistoryColumns) => (
        <span>
          {item.UserLastName} {item.UserFirstName} {item.UserMiddleName}
        </span>
      ),
    },
  ];

  const assignmentState = [
    {
      Type: 'Unknown',
      title: 'Неизвестен',
    },
    {
      Type: 'OnCompleted',
      title: 'Выдано',
    },
    {
      Type: 'OnClarification',
      title: 'На уточнении',
    },
    {
      Type: 'Completed',
      title: 'Исполнено',
    },
    {
      Type: 'Rejected',
      title: 'Отклонено',
    },
    {
      Type: 'Delegate',
      title: 'Делегировано',
    },
  ];

  const docType = [
    {
      Type: 'Inner',
      title: 'Внутренний',
    },
    {
      Type: 'Incoming',
      title: 'Входящий',
    },
    {
      Type: 'Outcoming',
      title: 'Исходящий',
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1">Выдать повторно</Menu.Item>
      <Menu.Item key="2">Проверить ЭП</Menu.Item>
    </Menu>
  );

  return {
    historyColumns,
    assignmentState,
    docType,
    menu,
  };
};
