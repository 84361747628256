import { DocumentFiles } from 'pages/DocumentPage/components/DocumentFiles';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { AssignmentType, CurrentDocument } from 'types/document';
import cn from 'classnames';
import { format } from 'date-fns';

type TAssignmentGeneralInfo = {
  assignment?: AssignmentType;
  documentData?: CurrentDocument | null;
  className?: string;
};

export const AssignmentGeneralInfo = ({
  documentData,
  className,
  assignment,
}: TAssignmentGeneralInfo) => {
  return (
    <form className={cn(className, 'mb15')}>
      <DFormItemTwo label="Документ">
        {`${documentData?.RegNumber ?? 'б/н'} от ${
          documentData?.RegDate
            ? format(new Date(documentData?.RegDate), 'dd.MM.yyyy')
            : '-'
        }`}
      </DFormItemTwo>
      <DFormItemTwo label="Системный номер документа">
        №{documentData?.Id}
      </DFormItemTwo>
      <DFormItemTwo label="Автор РКК">
        {documentData?.AuthorLastName} {documentData?.AuthorFirstName}{' '}
        {documentData?.AuthorMiddleName}
      </DFormItemTwo>
      <DFormItemTwo label="Тема">{documentData?.Subject}</DFormItemTwo>
      <DFormItemTwo style={{ alignItems: 'baseLine' }} label="Файлы">
        <DocumentFiles currentDocument={documentData} />
      </DFormItemTwo>
      {assignment && (
        <DFormItemTwo style={{ alignItems: 'baseLine' }} label="Текст задачи">
          {assignment.Content || '-'}
        </DFormItemTwo>
      )}
      {assignment && (
        <DFormItemTwo
          style={{ alignItems: 'baseLine' }}
          label="Срок исполнения"
        >
          {format(new Date(assignment.ExecutionTime), 'dd.MM.yyyy H:mm')}
        </DFormItemTwo>
      )}
    </form>
  );
};
