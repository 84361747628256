import { Checkbox, Drawer, notification, Select } from 'antd';
import { TDepartment } from 'pages/AdminPage/Departments';
import { ContactList } from 'pages/CreateDocument/types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DButton, DInput } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { DrawerHeader } from './DrawerHeader';
import { useFormik } from 'formik';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import login_error from 'shared/assets/images/login-error.png';
import { ChangePasswordDrawer } from './ChangePasswordDrawer';
import { format } from 'date-fns';
import { ChangeLoginDrawer } from './ChangeLoginDrawer';
import { Pagination } from 'types/pagination';
import { DEFAULT_PAGINATION } from 'shared/constants/pagination';
import axios from 'axios';
import { prefixSSO } from 'api/prefix';
import { getAuthToken } from 'shared/utils/getAuthToken';
import _ from 'lodash';

type TOrganisationOfficerDrawer = {
  visible: boolean;
  type: string;
  onClose: () => void;
  item?: ContactList;
  getData: (pagination: Pagination, query?: string) => void;
  pagination: Pagination;
};

type TPosition = {
  Id: number;
  Name: string;
};

type TRoleUser = {
  ApiId: number;
  Id: number;
  Name: string;
  UserRoleId: number;
};

type Roles = {
  RoleId: number;
  RoleName: string;
  Permissions: {
    Entity: string;
    Action: string;
  }[];
};

export const OrganisationOfficerDrawer = ({
  visible,
  onClose,
  type,
  item,
  getData,
  pagination,
}: TOrganisationOfficerDrawer) => {
  const [userInfo, setUserInfo] = useState<ContactList>();
  const [positionList, setPositionList] = useState<TPosition[]>([]);
  const [departmentList, setDepartmentList] = useState<TDepartment[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
  const [visibleLogin, setVisibleLogin] = useState<boolean>(false);
  const [roleUser, setRoleUser] = useState<TRoleUser[]>([]);
  const [allRoles, setAllRoles] = useState<Roles[]>([]);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { Option } = Select;

  const initialValues = {
    PositionId: item?.PositionId,
    FirstName: item?.FirstName,
    LastName: item?.LastName,
    MiddleName: item?.MiddleName,
    PhoneNumber: item?.PhoneNumber ?? '',
    Email: item?.Email,
    DepartmentId: item?.DepartmentId,
    IsHead: item?.IsHead,
    IsBanned: item?.IsBanned,
    Esign: item?.Esign,
    Login: item?.Login,
    Password: item?.Password,
    UserName: item?.UserName,
    role: roleUser.map(item => item.Id),
  };

  const onCloseLogin = () => setVisibleLogin(false);

  const onClosePassword = () => setVisiblePassword(false);

  const getUserInfo = useCallback(() => {
    if (item?.Id)
      $api
        .get(`contacts/${item?.Id}/full`)
        .then(res => {
          setUserInfo(res.data);
          for (const value in res.data) {
            formik.setFieldValue(value, res.data[value] ?? '');
          }
          axios
            .get(`${prefixSSO}/Users/${res.data.SsoUserId}/Roles`, {
              headers: getAuthToken('auth_token'),
            })
            .then(res => {
              setRoleUser(res.data);
            })
            .catch(({ response }) => errorThrow(response));
        })
        .catch(({ response }) => errorThrow(response));
  }, [item?.Id]);

  const updateInitialRoles = useCallback(() => {
    const allRoleIds = allRoles.map(item => item.RoleId);
    const newRoles = roleUser
      .filter(role => allRoleIds.includes(role.Id))
      .map(role => role.Id);
    formik.setFieldValue('role', newRoles);
  }, [allRoles, roleUser]);

  useEffect(() => {
    updateInitialRoles();
  }, [updateInitialRoles]);

  const checkIsIncludesEntity = (item: Roles, entity: string) => {
    const entityList = item.Permissions.map(permission => permission.Entity);
    return entityList.includes(entity);
  };

  const getRoles = useCallback(() => {
    axios
      .get(`${prefixSSO}/roles/allpermissions`, {
        headers: getAuthToken('auth_token'),
      })
      .then(({ data }) => {
        const newArr = data.filter(
          (item: Roles) =>
            !checkIsIncludesEntity(item, 'admin') &&
            !checkIsIncludesEntity(item, 'admin.sso') &&
            !checkIsIncludesEntity(item, 'admin.organisation'),
        );
        setAllRoles(newArr);
      })
      .catch(({ response }) => errorThrow(response));
  }, []);

  const getInfo = useCallback(() => {
    $api
      .get('Positions')
      .then(res => setPositionList(res.data))
      .catch(({ response }) => errorThrow(response));
    if (user?.CurrentContact?.OrganisationId)
      $api
        .get(`Departments/byorganization`)
        .then(res => setDepartmentList(res.data))
        .catch(({ response }) => errorThrow(response));
  }, [user?.CurrentContact?.OrganisationId]);

  useEffect(() => {
    type === 'edit' && getRoles();
    getInfo();
    getUserInfo();
  }, [getInfo, getUserInfo, getRoles]);

  const onFinish = (values: any) => {
    const requestType = type === 'edit' ? $api.put : $api.post;
    const differenceRoles =
      type === 'edit'
        ? _.difference(
            values.role,
            roleUser.map(item => item.Id),
          )
        : [];
    if (type === 'create' && !values.Login) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо заполнить логин',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    }
    if (type === 'create' && !values.Password) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо заполнить пароль',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    }
    if (!values.LastName) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо заполнить фамилию',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    }
    if (!values.FirstName) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо заполнить имя',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    } else {
      const requests = differenceRoles.map(id => {
        return axios.post(
          `${prefixSSO}/UserRoles`,
          {
            UserId: userInfo?.SsoUserId,
            RoleId: id,
          },
          {
            headers: getAuthToken('auth_token'),
          },
        );
      });
      Promise.all(requests)
        .then(() => {
          requestType('contacts/byorganization', {
            ...values,
            OrganisationId: user?.CurrentContact?.OrganisationId,
            Id: item?.Id,
          })
            .then(() => {
              getData(pagination);
              onClose();
            })
            .catch(({ response }) => errorThrow(response));
        })
        .catch(({ response }) => errorThrow(response));
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  const onDeleteRole = useCallback(
    id => {
      const isIncludes = roleUser.map(item => item.Id).includes(id);
      if (isIncludes) {
        const currentItem = roleUser.filter(item => item.Id === id)[0];
        axios
          .delete(`${prefixSSO}/UserRoles/${currentItem.UserRoleId}`, {
            headers: getAuthToken('auth_token'),
          })
          .then(() => {
            getUserInfo();
          })
          .catch(err => console.error(err));
      }
    },
    [getUserInfo, roleUser],
  );

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      {visiblePassword && (
        <ChangePasswordDrawer
          visible={visiblePassword}
          onClose={onClosePassword}
          type="any"
          item={userInfo}
          getData={getUserInfo}
        />
      )}
      {visibleLogin && (
        <ChangeLoginDrawer
          visible={visibleLogin}
          onClose={onCloseLogin}
          item={userInfo}
          getData={getUserInfo}
        />
      )}
      <div className="page3">
        <DrawerHeader
          onClose={onClose}
          text={`${type === 'edit' ? 'Редактирование' : 'Создание'} сотрудника`}
        />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Подразделение" name="DepartmentId">
            <Select
              value={formik.values.DepartmentId}
              className="ui-select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('DepartmentId', value)}
            >
              {departmentList?.map(item => (
                <Option value={item.Id}>{item.Name}</Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Должность" name="PositionId">
            <Select
              value={formik.values.PositionId}
              className="ui-select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('PositionId', value)}
            >
              {positionList?.map(item => (
                <Option value={item.Id}>{item.Name}</Option>
              ))}
            </Select>
          </DFormItemTwo>
          {type === 'edit' && (
            <DFormItemTwo label="Роли" name="role">
              <Select
                value={formik.values.role}
                mode="multiple"
                className="ui-select"
                allowClear
                showSearch
                maxTagCount="responsive"
                optionFilterProp="children"
                onChange={value => formik.setFieldValue('role', value)}
                onDeselect={(id: number) => onDeleteRole(id)}
              >
                {allRoles.map(item => (
                  <Option key={item.RoleId} value={item.RoleId}>
                    {item.RoleName}
                  </Option>
                ))}
              </Select>
            </DFormItemTwo>
          )}
          <DFormItemTwo label="Логин" name="Login">
            {type === 'create' ? (
              <DInput
                width="100%"
                type="text"
                value={formik.values.Login}
                name="Login"
                required={showError}
                onChange={formik.handleChange}
              />
            ) : (
              <>
                <span className="mr15">{userInfo?.Login}</span>
                <span
                  className="custom-link"
                  onClick={() => setVisibleLogin(true)}
                >
                  сменить логин
                </span>
              </>
            )}
          </DFormItemTwo>
          <DFormItemTwo
            label={type === 'create' ? 'Пароль' : 'Дата смены пароля'}
            name="Password"
          >
            {type === 'create' ? (
              <DInput
                width="100%"
                type="text"
                value={formik.values.Password}
                name="Password"
                required={showError}
                onChange={formik.handleChange}
              />
            ) : (
              <>
                <span className="mr15">
                  {userInfo?.PasswordChangeDate &&
                    format(
                      new Date(userInfo?.PasswordChangeDate),
                      'dd.MM.yyyy',
                    )}
                </span>
                <span
                  className="custom-link"
                  onClick={() => setVisiblePassword(true)}
                >
                  сменить пароль
                </span>
              </>
            )}
          </DFormItemTwo>
          <DFormItemTwo label="Имя пользователя" name="UserName">
            <DInput
              width="100%"
              type="text"
              value={formik.values.UserName}
              name="UserName"
              required={showError}
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Фамилия" name="LastName">
            <DInput
              width="100%"
              type="text"
              value={formik.values.LastName}
              name="LastName"
              required={showError}
              onChange={e => {
                formik.handleChange(e);
                e.target.value.length > 0 && setShowError(false);
              }}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Имя" name="FirstName">
            <DInput
              width="100%"
              type="text"
              value={formik.values.FirstName}
              name="FirstName"
              required={showError}
              onChange={e => {
                formik.handleChange(e);
                e.target.value.length > 0 && setShowError(false);
              }}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Отчество" name="MiddleName">
            <DInput
              width="100%"
              type="text"
              value={formik.values.MiddleName ?? undefined}
              name="MiddleName"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Электронная почта" name="Email">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Email ?? undefined}
              name="Email"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Телефон" name="PhoneNumber">
            <DInput
              width="100%"
              type="text"
              value={String(formik.values.PhoneNumber)}
              name="PhoneNumber"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Эл. подпись" name="Esign">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Esign}
              name="Esign"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '50%',
              marginLeft: '200px',
            }}
          >
            <DFormItemTwo name="IsHead">
              <Checkbox
                name="IsHead"
                checked={formik.values.IsHead}
                value={formik.values.IsHead}
                onChange={value =>
                  formik.setFieldValue('IsHead', value.target.checked)
                }
              >
                Начальник
              </Checkbox>
            </DFormItemTwo>
            <DFormItemTwo name="IsBanned">
              <Checkbox
                name="Ban"
                checked={formik.values.IsBanned}
                value={formik.values.IsBanned}
                onChange={value =>
                  formik.setFieldValue('IsBanned', value.target.checked)
                }
              >
                Забанить
              </Checkbox>
            </DFormItemTwo>
          </div>
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              {type === 'create' ? 'Создать' : 'Редактировать'}
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
