import { Table } from 'antd';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';

type TSignaturesTable = {
  Id: number;
  SignDate: string;
  IsCorrect: boolean;
  Thumbprint: string;
  SignerId: number;
  SignerFirstName: string;
  SignerLastName: string;
  SignerMiddleName: string;
};

export const SignaturesTable = ({ documentId }: { documentId?: number }) => {
  const [loader, setLoader] = useState<boolean>(true);
  const [signaturesList, setSignaturesList] = useState<TSignaturesTable[]>([]);

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Дата подписания',
      dataIndex: 'SignDate',
      key: 'SignDate',
      render: (date: string) => format(new Date(date), 'dd.MM.yyyy'),
    },
    {
      title: 'Подписант',
      dataIndex: 'SignerId',
      key: 'SignerId',
      render: (text: string, item: TSignaturesTable) => (
        <span>
          {item.SignerLastName} {item.SignerFirstName} {item.SignerMiddleName}
        </span>
      ),
    },
    {
      title: 'Отпечаток подписи',
      dataIndex: 'Thumbprint',
      key: 'Thumbprint',
    },
    {
      title: 'Признак',
      dataIndex: 'IsCorrect',
      key: 'IsCorrect',
      render: (IsCorrect: boolean) => (IsCorrect ? 'Верна' : 'Не верна'),
    },
  ];

  useEffect(() => {
    if (documentId) {
      $api
        .get(`Documents/${documentId}/Signatures`)
        .then(res => setSignaturesList(res.data))
        .catch(({ response }) => errorThrow(response))
        .finally(() => setLoader(false));
    }
  }, [documentId]);

  return (
    <Table loading={loader} dataSource={signaturesList} columns={columns} />
  );
};
