import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useEffect } from 'react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageRefresh } from 'shared/utils';
import { updateColumns, updateDataCount } from 'store/slice/table';
import { ReduxState } from 'store/store';
import { TableResult } from 'types/tableData';
import { options } from './constants';
import { TColumns } from './types';
import fileSvg from 'shared/assets/images/file.svg';
import { Icon } from '../IconComponent';
import { useHistory } from 'react-router-dom';

const defaultActiveColumns = [
  {
    title: <Icon icon={fileSvg} alt="fileSvg" tooltip="Прикрепленные файлы" />,
    dataIndex: 'Files',
    key: 'Files',
    width: 22,
    render: (_d: undefined, row: TableResult) =>
      row.Files &&
      row.Files.length > 0 && <Icon icon={fileSvg} alt="fileSvg" />,
    formatter: 'files',
  },
];

export const useColumnSetup = ({ pageType }: { pageType: string }) => {
  const [optionsColumns] = useState<TColumns[]>(options);
  const [defaultColumns, setDefaultColumns] = useState<CheckboxValueType[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { columns } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );

  const getArrayOfColumns = useCallback(
    (array: string[]) => {
      return optionsColumns
        .filter(item => array?.some(key => key === item.key))
        .map(item => item.key);
    },
    [optionsColumns],
  );

  const getDefaultColumns = useCallback(
    (pageType: string) => {
      if (pageType === 'document') {
        const documentColumns =
          columns?.documentColumns?.map(item => item.key) || [];
        setDefaultColumns(getArrayOfColumns(documentColumns));
      }
    },
    [columns?.documentColumns, getArrayOfColumns],
  );

  const getItemsOfString = (arrayOfStrings: CheckboxValueType[]) => {
    return optionsColumns.filter(item =>
      arrayOfStrings?.some(key => key === item.key),
    );
  };

  const updateChecked = (items: CheckboxValueType[]) => {
    setDefaultColumns(items);
  };

  const updateUrl = () => {
    const currentPath = history.location.pathname.split('/');
    // history.push()
    const currentPageValue = currentPath[currentPath.length - 1];
    currentPath.pop();
    console.log(`${currentPath.join('/')}/page=1`);
  };

  useEffect(() => {
    getDefaultColumns(pageType);
    updateUrl();
  }, [getDefaultColumns, pageType]);

  const onSubmit = () => {
    if (pageType === 'document') {
      localStorage.setItem(
        'columns',
        JSON.stringify(getItemsOfString(defaultColumns)),
      );
      const selectedColumns = localStorage.getItem('columns');
      if (selectedColumns != null) {
        dispatch(
          updateColumns({
            documentColumns: [
              ...defaultActiveColumns,
              ...JSON.parse(selectedColumns),
            ],
          }),
        );
      }
    }
    if (pageType === 'assignment') {
      localStorage.setItem(
        'assignmentColumns',
        JSON.stringify(getItemsOfString(defaultColumns)),
      );
      const selectedColumns = localStorage.getItem('assignmentColumns');
      if (selectedColumns != null) {
        dispatch(
          updateColumns({
            assignmentColumns: [
              ...defaultActiveColumns,
              ...JSON.parse(selectedColumns),
            ],
          }),
        );
      }
    }
    pageRefresh();
  };

  const onReset = () => {
    if (pageType === 'document') {
      window.localStorage.removeItem('columns');
      dispatch(updateColumns({ documentColumns: [] }));
    }
    if (pageType === 'assignment') {
      window.localStorage.removeItem('assignmentColumns');
      dispatch(updateColumns({ assignmentColumns: [] }));
    }
    pageRefresh();
  };

  return {
    optionsColumns,
    defaultColumns,
    updateChecked,
    onSubmit,
    onReset,
  };
};
