import { Drawer, Select } from 'antd';
import { useFormik } from 'formik';
import { debounce } from 'lodash-es';
import { DeliveryParams } from 'pages/CreateDocument/types';
import { useCallback, useEffect, useState } from 'react';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { DButton } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { DrawerHeader } from './DrawerHeader';
import { TOrganisationsList } from './FilterDrawer/types';

type TCounterPartiesDrawer = {
  visible: boolean;
  onClose: () => void;
  getData: () => void;
};

type TInitialValue = {
  Id?: number;
  CounterpartyId?: number;
  SendTypeId?: number;
};

export const CounterPartiesDrawer = ({
  visible,
  onClose,
  getData,
}: TCounterPartiesDrawer) => {
  const [organizationList, setOrganizationList] = useState<
    TOrganisationsList[]
  >([]);
  const [sendTypeList, setSendTypeList] = useState<DeliveryParams[]>([]);
  const { Option } = Select;
  const initialValues: TInitialValue = {
    Id: undefined,
    CounterpartyId: undefined,
    SendTypeId: undefined,
  };

  const onFinish = (values: TInitialValue) => {
    $api
      .post('counterparties/byorganization', {
        ...values,
      })
      .then(() => {
        getData();
        onClose();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const getOrganizations = useCallback((query?: string) => {
    $api
      .get('organisations/active/page', {
        params: {
          Limit: 100,
          FastSearch: query,
        },
      })
      .then(res => setOrganizationList(res.data.Data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const getInfo = useCallback(() => {
    $api
      .get('sendtypes')
      .then(res => setSendTypeList(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  useEffect(() => {
    getInfo();
    getOrganizations();
  }, []);

  const onSearchOrganisation = useCallback(
    debounce(value => {
      getOrganizations(value);
    }, DEBOUNCE_TIME),
    [],
  );

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Создание контрагента" />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Контрагент" name="CounterpartyId">
            <Select
              value={formik.values.CounterpartyId}
              className="ui-select"
              allowClear
              showSearch
              optionFilterProp="children"
              onSearch={value =>
                checkLengthStringForSearch(value, onSearchOrganisation)
              }
              onChange={value => formik.setFieldValue('CounterpartyId', value)}
            >
              {organizationList?.map(item => (
                <Option value={item.Id}>{item.Name}</Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Доставка" name="SendTypeId">
            <Select
              value={formik.values.SendTypeId}
              className="ui-select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('SendTypeId', value)}
            >
              {sendTypeList?.map(item => (
                <Option value={item.Id}>{item.Name}</Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              Создать
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
