import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Button } from 'antd';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { PropsParams } from 'types/tableData';
import { updateColumns, updateFilteredDocuments } from 'store/slice/table';
import { useBreadcrumb } from 'shared/hooks/useBreadcrumb';
import { updateRoutData } from 'store/slice/routing';
import { ReduxState } from 'store/store';
import { TColumns } from 'types/columns';
import { CurrentDocument } from 'types/document';
import { Icon } from 'shared/components/IconComponent';
import fileSvg from 'shared/assets/images/file.svg';

export const useMainPage = ({ match }: PropsParams) => {
  const defaultColumns: TColumns[] = [
    {
      title: '',
      dataIndex: 'SysControl',
      key: 'SysControl',
      width: 22,
      render: (text: string, row: CurrentDocument) =>
        row?.Controls?.length ? <b>К</b> : null,
      formatter: 'SysControl',
    },
    {
      title: (
        <Icon icon={fileSvg} alt="fileSvg" tooltip="Прикрепленные файлы" />
      ),
      dataIndex: 'Files',
      key: 'Files',
      width: 22,
      render: (text: string, row: CurrentDocument) =>
        row?.Files?.length > 0 ? <Icon icon={fileSvg} alt="fileSvg" /> : null,
      formatter: 'files',
    },
    {
      title: 'Системный номер',
      dataIndex: 'Id',
      key: 'Id',
      render: (text: string, item: CurrentDocument) => <span>{item.Id}</span>,
      formatter: 'sysNumber',
    },
    {
      title: 'Рег. номер и рег. дата',
      dataIndex: 'RegDate',
      key: 'RegDate',
      render: (text: string, item: CurrentDocument) =>
        item.RegDate ? (
          <>
            {item.RegNumber && `${item.RegNumber} от`}{' '}
            {format(new Date(item.RegDate), 'dd.MM.yyyy')}
          </>
        ) : null,
      formatter: 'RegInfo',
    },
    {
      title: 'Состояние',
      dataIndex: 'State',
      key: 'State',
      formatter: 'state',
    },
    {
      title: 'Тема документа',
      dataIndex: 'Subject',
      key: 'Subject',
      render: (text: string, item: CurrentDocument) => (
        <>
          <p className="subject" onClick={() => openDocument(item)}>
            {item.Subject ? item.Subject : 'Тема отсутствует'}
          </p>
          <Button className="button">На исполнение (делегировано)</Button>
        </>
      ),
      formatter: 'subject',
    },
    {
      title: 'Дата создания',
      dataIndex: 'CreateDate',
      key: 'CreateDate',
      render: (text: string, item: CurrentDocument) => (
        <span>{format(new Date(item.CreateDate), 'dd.MM.yyyy')}</span>
      ),
      formatter: 'date',
    },
    {
      title: 'Автор РКК',
      dataIndex: 'author',
      key: 'author',
      render: (text: string, item: CurrentDocument) => (
        <span>{`${item.AuthorLastName} ${item.AuthorFirstName} ${item.AuthorMiddleName}`}</span>
      ),
      formatter: 'authorName',
    },
  ];
  const [tableColumns, setTableColumns] = useState<TColumns[]>([]);
  const { updateBreadcrumb } = useBreadcrumb();
  const { data } = useSelector((state: ReduxState) => state.routingReducer);
  const { columns } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const conditions = ['Incoming', 'Outcoming', 'Inner'];

  useEffect(() => {
    sessionStorage.removeItem('activeDocumentTab');
    const localColumns = localStorage.getItem('columns');
    if (
      columns?.documentColumns &&
      !columns?.documentColumns.length &&
      localColumns == null
    ) {
      dispatch(updateColumns({ documentColumns: defaultColumns }));
      setTableColumns(defaultColumns);
    } else {
      setTableColumns(defaultColumns);
      if (localColumns != null) {
        setTableColumns([
          ...defaultColumns.slice(0, 2),
          ...defaultColumns.slice(5, 6),
          ...JSON.parse(localColumns),
        ]);
        dispatch(
          updateColumns({
            documentColumns: [
              ...defaultColumns.slice(0, 2),
              ...defaultColumns.slice(5, 6),
              ...JSON.parse(localColumns),
            ],
          }),
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!location.pathname.includes('searching')) {
      dispatch(
        updateFilteredDocuments({
          data: [],
          config: {},
          filteredCount: 0,
        }),
      );
    }
  }, [dispatch]);

  const openDocument = (documentList: CurrentDocument) => {
    const docType = documentList?.Type ?? documentList?.DocumentType;
    const startLocation = !location.pathname.includes('searching')
      ? match.url.split('/page=')[0]
      : '/active-documents';
    updateBreadcrumb(
      history,
      dispatch,
      `${startLocation}${
        !conditions.some(str => location.pathname.includes(str))
          ? `/${'Documents' + docType}`
          : ''
      }/${documentList.Id}`,
      'Документ',
      {
        docType: 'Documents' + docType,
        id: data.id,
        documentId: documentList.Id,
      },
    );
    dispatch(
      updateRoutData({
        id: data.id,
        documentId: documentList.Id,
        documentType: 'Documents' + docType,
      }),
    );
  };

  return {
    columns,
    openDocument,
    tableColumns,
  };
};
