import { useBem } from 'shared/hooks';
import { IconProps } from './types';
import { Tooltip } from 'antd';
import cn from 'classnames';
import './Icon.scss';

export const Icon = ({
  icon,
  onClick,
  style,
  className,
  alt,
  tooltip,
  tooltipPlacement = 'top',
  children,
}: IconProps) => {
  const bem = useBem('Icon');
  const onClickClass = bem('withEvent');
  const isChildrenExist = children ? 'mr5' : '';
  const classString = cn(!onClick ? bem() : onClickClass, className);

  return (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <div className={classString} style={style} onClick={onClick}>
        <img className={isChildrenExist} src={icon} alt={alt} />
        {children}
      </div>
    </Tooltip>
  );
};
