import moment from 'moment';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DButton } from 'shared/ui-kit';
import { errorThrow, getSlicedText } from 'shared/utils';
import { $api } from 'shared/utils/api';
import {
  permissionExist,
  PermissionsAccess,
} from 'shared/utils/isExistPermission';
import { ReduxState } from 'store/store';
import { ControlStatuses, RemindList } from './types';
import { Tooltip } from 'antd';

export const useDocumentReminder = (canEdit: boolean | undefined) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [drawerType, setDrawerType] = useState<string>('');
  const [remindList, setRemindList] = useState<RemindList[]>([]);
  const [selectedItem, setSelectedItem] = useState<RemindList | null>(null);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  const onClose = () => {
    setVisible(false);
    setSelectedItem(null);
  };
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );

  const getData = useCallback(() => {
    if (documentInfo?.Id)
      $api
        .get(`documentcontrolreminders/bydocument/${documentInfo?.Id}`)
        .then(res => setRemindList(res.data))
        .catch(({ response }) => errorThrow(response));
  }, [documentInfo?.Id]);

  const onDelete = useCallback(
    id => {
      $api
        .delete(`documentcontrolreminders/${id}`)
        .then(() => getData())
        .catch(({ response }) => errorThrow(response));
    },
    [getData],
  );

  const onClickButton = () => {
    setVisible(true);
    setDrawerType('create');
  };

  const isDisabledButton = !documentInfo?.Controls.filter(
    control => control?.Status === ControlStatuses.InControl,
  ).length;

  useEffect(() => {
    getData();
  }, [getData]);

  const columns = [
    {
      title: 'Контроль',
      key: 'ControlId',
      dataIndex: 'ControlId',
      width: '10%',
    },
    {
      title: 'Контрольный срок',
      key: 'ControlDate',
      dataIndex: 'ControlDate',
      width: '10%',
      render: (date: string) =>
        date ? moment(date).format('DD.MM.YYYY H:mm') : '-',
    },
    {
      title: 'Исполнитель',
      key: 'ExecutorIds',
      dataIndex: 'ExecutorIds',
      width: '20%',
      render: (text: string, item: RemindList) =>
        item.Executors?.map(item => (
          <p className="mb0">
            {item.LastName} {item.FirstName} {item.MiddleName}
          </p>
        )),
    },
    {
      title: 'Исполнено',
      key: 'IsExecuted',
      dataIndex: 'IsExecuted',
      width: '10%',
      render: (IsExecuted: boolean) => (IsExecuted ? 'Да' : 'Нет'),
    },
    {
      title: 'Срок исполнения',
      key: 'ExecutionDate',
      dataIndex: 'ExecutionDate',
      width: '10%',
      render: (date: string) =>
        date ? moment(date).format('DD.MM.YYYY H:mm') : '-',
    },
    {
      title: 'Комментарий',
      key: 'Summary',
      dataIndex: 'Summary',
      width: '18%',
      render: (_: string, item: RemindList) => {
        const summaryText = item?.Summary || '';

        return (
          <Tooltip title={summaryText}>
            <span className="document-reminder__summary">
              {getSlicedText(summaryText, 100)}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  if (
    canEdit &&
    user?.Permissions &&
    permissionExist(user?.Permissions, PermissionsAccess.Control)
  ) {
    columns.push({
      title: 'Действия',
      key: 'Actions',
      dataIndex: 'Actions',
      width: '30%',
      render: (text: string, item: RemindList) => (
        <>
          <DButton
            small
            primary
            onClick={() => {
              setVisible(true);
              setDrawerType('execute');
              setSelectedItem(item);
            }}
            className="mr15 mb10"
            disabled={item.IsExecuted}
          >
            Исполнить
          </DButton>
          <DButton
            small
            defaultPrimary
            onClick={() => {
              setVisible(true);
              setDrawerType('edit');
              setSelectedItem(item);
            }}
            className="mr15 mb10"
            disabled={item.IsExecuted}
          >
            Редактировать
          </DButton>
          <DButton
            className="mb10"
            small
            defaultDanger
            onClick={() => onDelete(item.Id)}
          >
            Удалить
          </DButton>
        </>
      ),
    });
  }

  return {
    visible,
    isDisabledButton,
    remindList,
    selectedItem,
    columns,
    onClose,
    drawerType,
    getData,
    onClickButton,
  };
};
