import { Drawer, Select } from 'antd';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { TDepartment } from 'pages/AdminPage/Departments';
import { ContactList } from 'pages/CreateDocument/types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DButton, DTextarea } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { CatalogButton } from '../CatalogButton';
import { DatePick } from '../DatePicker/DatePick';
import { CatalogDrawer } from './CatalogDrawer';
import { DrawerHeader } from './DrawerHeader';
import {
  fetchDepartmentData,
  fetchExecutors,
} from './IssuanceAssignmentDrawer/api';
import {
  getDisabledDates,
  getResponsibleName,
} from './IssuanceAssignmentDrawer/utils';

type AssignmentTemplateEditType = {
  visible: boolean;
  onClose: () => void;
  id: number;
};

type AssignmentTemplate = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  Name: string;
  Content: string;
  AuthorId: number;
  ResponsibleId: number;
  ControllerId: number;
  ExecutionTime: string;
  ExecutorsId: number[];
  ExecutionsGroupId: number[];
};

export const AssignmentTemplateEditDrawer = ({
  visible,
  onClose,
  id,
}: AssignmentTemplateEditType) => {
  const [
    currentTemplate,
    setCurrentTemplate,
  ] = useState<AssignmentTemplate | null>(null);
  const [departments, setDepartments] = useState<TDepartment[]>([]);
  const [executors, setExecutors] = useState<ContactList[]>([]);
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [catalogType, setCatalogType] = useState<
    'contactsAssignment' | 'departments'
  >('contactsAssignment');
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { dates } = useSelector((state: ReduxState) => state.calendarReducer);
  const holidayDays = dates.filter(item => item.holiday);
  const IS_SHOW_MORE_BUTTON = user?.Permissions.find(
    item => item.Entity === 'assignment.ext.contacts',
  );

  const onOpenCatalog = (type: 'contactsAssignment' | 'departments') => {
    setVisibleCatalog(true);
    setCatalogType(type);
  };

  const fetchAssignmentTemplate = useCallback(() => {
    $api
      .get(`AssignmentTemplates/${id}/executors`)
      .then(({ data }) => setCurrentTemplate(data))
      .catch(({ response }) => errorThrow(response));
  }, [id]);

  const onChangeExecutor = (isContact: boolean, value: number[]) => {
    if (isContact) {
      formik.setFieldValue('ExecutorsId', value);
    } else {
      formik.setFieldValue('ExecutionsGroupId', value);
    }
  };

  const getExecutorName = useCallback((arrayOfIds: number[]) => {
    const requests = arrayOfIds.map(item => $api.get(`Contacts/${item}`));
    Promise.all(requests)
      .then(res => {
        const allData: ContactList[] = res.map(item => item.data);
        setExecutors(prev => [...prev, ...allData]);
      })
      .catch(({ response }) => errorThrow(response));
  }, []);

  const getExecutorGroupName = useCallback((arrayOfIds: number[]) => {
    const requests = arrayOfIds.map(id => $api.get(`Departments/${id}`));
    Promise.all(requests)
      .then(res => {
        const allData: TDepartment[] = res.map(item => item.data);
        setDepartments(prev => [...prev, ...allData]);
      })
      .catch(({ response }) => errorThrow(response));
  }, []);

  useEffect(() => {
    if (id) {
      fetchAssignmentTemplate();
    }
  }, [id, fetchAssignmentTemplate]);

  useEffect(() => {
    if (currentTemplate) {
      fetchDepartmentData()
        .then(list => setDepartments(list ?? []))
        .finally(() => {
          getExecutorGroupName(
            (currentTemplate?.ExecutionsGroupId as number[]) ?? [],
          );
        });
    }
  }, [currentTemplate]);

  useEffect(() => {
    if (currentTemplate) {
      fetchExecutors()
        .then(list => setExecutors(list ?? []))
        .finally(() => {
          getExecutorName((currentTemplate?.ExecutorsId as number[]) ?? []);
        });
    }
  }, [currentTemplate]);

  const onSubmit = (
    values: Partial<{
      ExecutorsId: number[];
      ExecutionsGroupId: number[];
      Content: string;
      ExecutionTime: moment.Moment;
    }>,
  ) => {
    $api
      .put(`assignmenttemplates/${id}/withmanyexecutors`, {
        ...currentTemplate,
        ...values,
      })
      .then(() => onClose())
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues: {
      ExecutorsId: currentTemplate?.ExecutorsId,
      ExecutionsGroupId: currentTemplate?.ExecutionsGroupId,
      Content: currentTemplate?.Content,
      ExecutionTime: moment(currentTemplate?.ExecutionTime),
    },
    enableReinitialize: true,
    onSubmit,
  });

  const itemIsDepartment = (
    node: TDepartment | ContactList,
  ): node is TDepartment => {
    return !!(node as TDepartment).Name;
  };

  const onAddedItemFromCatalog = (item: ContactList | TDepartment) => {
    if (catalogType === 'contactsAssignment' && !itemIsDepartment(item)) {
      setExecutors(prev => [...prev, item]);
      const executors = Array.isArray(formik.values.ExecutorsId)
        ? formik.values.ExecutorsId
        : [];
      formik.setFieldValue('ExecutorsId', [...executors, item.Id]);
    }
    if (catalogType === 'departments' && itemIsDepartment(item)) {
      const executorGroups = Array.isArray(formik.values.ExecutionsGroupId)
        ? formik.values.ExecutionsGroupId
        : [];
      setDepartments(prev => [...prev, item]);
      formik.setFieldValue('ExecutionsGroupId', [...executorGroups, item.Id]);
    }
  };

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={() => setVisibleCatalog(false)}
          type={catalogType}
          func={(item: ContactList) => onAddedItemFromCatalog(item)}
        />
      )}
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Шаблоны задачи" />
        <form onSubmit={formik.handleSubmit}>
          <DFormItemTwo
            label="Исполнитель департамент"
            name="ExecutionsGroupId"
          >
            <Select
              mode="multiple"
              maxTagCount="responsive"
              className="ui-select"
              showSearch
              allowClear
              optionFilterProp="children"
              onChange={value => onChangeExecutor(false, value)}
              value={formik.values.ExecutionsGroupId}
            >
              {_.uniqBy(departments, 'Id').map(department => (
                <Select.Option value={department.Id} key={department.Id}>
                  {department.Name}
                </Select.Option>
              ))}
            </Select>
            {IS_SHOW_MORE_BUTTON && (
              <CatalogButton onClick={() => onOpenCatalog('departments')} />
            )}
          </DFormItemTwo>
          <DFormItemTwo label="Исполнитель контакт" name="ExecutorsId">
            <Select
              mode="multiple"
              maxTagCount="responsive"
              className="ui-select"
              showSearch
              allowClear
              optionFilterProp="children"
              onChange={value => onChangeExecutor(true, value)}
              value={formik.values.ExecutorsId}
            >
              {_.uniqBy(executors, 'Id').map(executor => (
                <Select.Option value={executor.Id} key={executor.Id}>
                  {getResponsibleName(executor)}
                </Select.Option>
              ))}
            </Select>
            {IS_SHOW_MORE_BUTTON && (
              <CatalogButton
                onClick={() => onOpenCatalog('contactsAssignment')}
              />
            )}
          </DFormItemTwo>
          <DFormItemTwo
            style={{ alignItems: 'baseLine' }}
            label="Текст"
            name="Content"
          >
            <DTextarea
              value={formik.values.Content}
              className="w100per"
              name="Content"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Срок исполнения" name="ExecutionTime">
            <DatePick
              style={{ height: 36, borderRadius: 8 }}
              showTime={{ minuteStep: 30 }}
              disabledDate={(currentDate: moment.Moment) =>
                getDisabledDates(currentDate, holidayDays)
              }
              format="DD.MM.YYYY HH:mm"
              value={formik.values.ExecutionTime}
              placeholder="Выберите дату"
              allowClear={false}
              onChange={(date: moment.Moment) => {
                formik.setFieldValue('ExecutionTime', date);
              }}
            />
          </DFormItemTwo>
          <DFormItemTwo>
            <DButton primary small type="submit">
              Редактировать
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
