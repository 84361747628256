import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { useMainPage } from 'pages/MainPage/useMainPage';
import { PropsParams, TableResult } from 'types/tableData';
import { AssignmentDrawer, OfficerDrawer } from '../CustomDrawers';
import { useMainTable } from './useMainTable';
import { CurrentDocument } from 'types/document';
import { DocumentEditDrawer } from '../CustomDrawers/DocumentEditDrawer';
import './MainTable.scss';

export const MainTable = ({ data, match }: PropsParams) => {
  const { tableColumns } = useMainPage({ match });
  const {
    visibleDrawer,
    clickableUser,
    visibleAssignment,
    onCloseDrawer,
    onCloseAssignmentDrawer,
    onDownloadFile,
    onChangePagination,
    renderColumn,
    visibleDocumentEditDrawer,
    onCloseDocumentEditDrawer,
  } = useMainTable({ data, match });
  const { isLoading, tablePagination } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );
  const { Column } = Table;

  return (
    <>
      {visibleAssignment && (
        <AssignmentDrawer
          visibleDrawer={visibleAssignment}
          onCloseDrawer={onCloseAssignmentDrawer}
        />
      )}
      {visibleDocumentEditDrawer && (
        <DocumentEditDrawer
          visible={visibleDocumentEditDrawer}
          onClose={onCloseDocumentEditDrawer}
        />
      )}
      {visibleDrawer && (
        <OfficerDrawer
          visibleDrawer={visibleDrawer}
          onCloseDrawer={onCloseDrawer}
          officerId={clickableUser}
        />
      )}
      <Table
        loading={isLoading}
        pagination={{
          position: ['bottomRight'],
          defaultCurrent: 1,
          defaultPageSize: 15,
          // hideOnSinglePage: false,
          // showSizeChanger: false,
          pageSizeOptions: ['15', '20', '25'],
          showTotal: () => data?.filteredCount,
          onChange: onChangePagination,
          total: data?.filteredCount,
          current: tablePagination?.page,
        }}
        expandable={{
          expandedRowRender: (record: TableResult) => (
            <>
              {record?.Files?.length &&
                record?.Files?.map(item => (
                  <p
                    onClick={() => onDownloadFile(item)}
                    className="custom-link ml55"
                  >
                    {item?.Name ?? 'Имя файла'}
                  </p>
                ))}
            </>
          ),
          rowExpandable: record => record?.Files?.length > 0,
        }}
        dataSource={data?.tableData}
        scroll={{ x: 'maxContent' }}
      >
        {tableColumns?.map(column => {
          return (
            <Column
              title={column.title}
              dataIndex={column.dataIndex}
              key={column.key}
              width={column.width}
              render={(text: string, record: CurrentDocument) =>
                renderColumn(column, text, record)
              }
            />
          );
        })}
      </Table>
    </>
  );
};
