import { Modal } from 'antd';
import { useFormik } from 'formik';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow, successNotification } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { TInitialValues } from '../types';
import { getConfigToUpdate } from '../utils';

type TFilterSaveModal = {
  visible: boolean;
  onClose: (visible: boolean) => void;
  selectedTags: number[];
  formValues: TInitialValues;
  getFilterPresets: () => void;
};

export const FilterSaveModal = ({
  visible,
  onClose,
  selectedTags,
  formValues,
  getFilterPresets,
}: TFilterSaveModal) => {
  const formik = useFormik({
    initialValues: {
      FilterName: '',
    },
    onSubmit: values => onSaveSetting(values),
  });

  const onSaveSetting = (values: { FilterName: string }) => {
    $api
      .post('DocumentFilters/savefilter', {
        FilterData: JSON.stringify(
          getConfigToUpdate({ ...formValues, TagIds: selectedTags ?? null }),
        ),
        FilterName: formik.values.FilterName,
      })
      .then(() => {
        successNotification('Фильтр сохранен');
        getFilterPresets();
        formik.setFieldValue('FilterName', '');
        onClose(false);
      })
      .catch(({ response }) => errorThrow(response));
  };

  return (
    <Modal
      className="filter-modal"
      title="Сохранение фильтра"
      visible={visible}
      onCancel={() => onClose(false)}
      footer={[
        <DButton
          small
          primary
          type="submit"
          onClick={onSaveSetting}
          className="mr15"
        >
          Сохранить
        </DButton>,
        <DButton small defaultDanger onClick={() => onClose(false)}>
          Закрыть
        </DButton>,
      ]}
    >
      <form name="modalForm" onSubmit={formik.handleSubmit}>
        <DFormItemTwo label="Название" name="FilterName">
          <DInput
            value={formik.values.FilterName}
            type="text"
            name="FilterName"
            width="100%"
            onChange={formik.handleChange}
          />
        </DFormItemTwo>
      </form>
    </Modal>
  );
};
