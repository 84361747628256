import cn from 'classnames';
import { Drawer, Select, Tag } from 'antd';
import { useEffect } from 'react';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import {
  documentTypes,
  MOCK_MASK_REG_NUMBER,
} from 'pages/AdminPage/DocumentRegistration/constants';
import {
  CheckableTagTypes,
  RegistrationDrawer,
} from 'pages/AdminPage/DocumentRegistration/types';
import { useDocumentRegistrationDrawer } from './useDocumentRegistartionDrawer';
import { DrawerTypes } from 'types/sectionTypes';
import { useBem } from 'shared/hooks';
import './DocumentRegistrationDrawer.scss';

export const DocumentRegistrationDrawer = ({
  item,
  onClose,
  visible,
  type,
}: RegistrationDrawer) => {
  const {
    formik,
    getInitialValueMaskRegNumber,
    onChangeTags,
    selectionTags,
    setSelectionTags,
    getExample,
    editDocumentType,
    regCounter,
  } = useDocumentRegistrationDrawer({ item, type, onClose });
  const { Option } = Select;
  const { CheckableTag }: CheckableTagTypes = Tag;
  const bem = useBem('DocumentRegistrationDrawer');

  useEffect(() => {
    getInitialValueMaskRegNumber(item?.MaskRegNumber);
  }, [getInitialValueMaskRegNumber, item?.MaskRegNumber]);

  return (
    <Drawer
      placement="right"
      onClose={() => onClose(false)}
      visible={visible}
      closeIcon={null}
      className={cn(bem(), 'flow-drawer')}
    >
      <div className="page3">
        <DrawerHeader
          onClose={onClose}
          text={
            type === DrawerTypes.Edit
              ? 'Редактирование рег. номера'
              : 'Создание рег. номера'
          }
        />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          {type === DrawerTypes.Edit && (
            <DFormItemTwo label="Текущий счетчик">
              <span>{regCounter}</span>
            </DFormItemTwo>
          )}
          <DFormItemTwo label="Наименование регномера" name="Name">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Name}
              onChange={e => formik.setFieldValue('Name', e.target.value)}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Начальный счетчик" name="StartCount">
            <DInput
              width="100%"
              type="number"
              value={formik.values.StartCount}
              name="DocumentContent"
              onChange={e => formik.setFieldValue('StartCount', e.target.value)}
            />
          </DFormItemTwo>
          <DFormItemTwo
            className="flow-drawer__item-mask"
            childrenClassName="flow-drawer__mask"
            label="Маска рег. номера"
            name="MaskRegNumber"
            required
          >
            <DInput
              value={selectionTags}
              type="text"
              name="MaskRegNumber"
              width="100%"
              className="mb10"
              onChange={e => setSelectionTags(e.target.value)}
            />
            <div className="flow-drawer__tags">
              {Object.entries(MOCK_MASK_REG_NUMBER)
                .reverse()
                .map(([key, value], index) => (
                  <CheckableTag
                    key={index}
                    className="mb10"
                    checked={selectionTags.includes(key.slice(0, -1))}
                    onChange={() => onChangeTags([key, value])}
                  >
                    {value}
                  </CheckableTag>
                ))}
            </div>
          </DFormItemTwo>
          <DFormItemTwo label="Пример">
            <span>{getExample()}</span>
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton
              disabled={!selectionTags.length}
              small
              primary
              className="mr15"
              type="submit"
            >
              {type === DrawerTypes.Create ? 'Создать' : 'Редактировать'}
            </DButton>
          </DFormItemTwo>
          <DFormItemTwo>
            <span>Установить для всех документопотоков с типом</span>
          </DFormItemTwo>
          <DFormItemTwo label="Тип документа" name="DocumentType">
            <Select
              value={formik.values.DocumentType}
              className="ui-select"
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('DocumentType', value)}
            >
              {documentTypes?.map(item => (
                <Option value={item.type} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton
              small
              defaultPrimary
              className="mr15"
              onClick={editDocumentType}
            >
              Применить
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
