import { DocumentTypes } from 'types/document';

export const getNameByDocumentType = (documentType: DocumentTypes) => {
  let typeToDisplay;

  switch (documentType) {
    case 'Outcoming':
      typeToDisplay = 'Исходящие';
      break;
    case 'Inner':
      typeToDisplay = 'Внутренние';
      break;
    case 'Incoming':
      typeToDisplay = 'Входящие';
      break;
  }

  return typeToDisplay;
};

const documentTypes = [
  {
    Type: 'Inner',
    Text: 'Внутренние',
  },
  {
    Type: 'Incoming',
    Text: 'Входящие',
  },
  {
    Type: 'Outcoming',
    Text: 'Исходящие',
  },
];

export const getDocumentType = (type: string) => {
  return documentTypes.find(item => item.Type === type)?.Text;
};
