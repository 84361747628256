import login_error from 'shared/assets/images/login-error.png';
import { DrawerHeader } from './DrawerHeader';
import React, { useState } from 'react';
import { Drawer, Form, notification } from 'antd';
import { DButton, DInput } from 'shared/ui-kit';
import axios from 'axios';
import { prefixSSO } from 'api/prefix';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { errorThrow, successNotification } from 'shared/utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ContactList } from 'pages/CreateDocument/types';

type TChangePasswordDrawer = {
  onClose: (bool: boolean) => void;
  visible: boolean;
  type: 'own' | 'any';
  item?: ContactList;
  getData?: () => void;
};

type TFormPassword = {
  OldPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
};

export const ChangePasswordDrawer = ({
  onClose,
  visible,
  type,
  item,
  getData,
}: TChangePasswordDrawer) => {
  const [showError, setShowError] = useState<boolean>(false);

  const onChangePassword = (values: TFormPassword) => {
    if (
      !values.OldPassword &&
      type === 'own' &&
      !values.NewPassword &&
      !values.ConfirmPassword
    ) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо заполнить все поля',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    } else if (!values.OldPassword && type === 'own') {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо заполнить старый пароль',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    } else if (!values.NewPassword) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо заполнить новый пароль',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    } else if (!values.ConfirmPassword) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо подтвердить пароль',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    } else if (values.ConfirmPassword !== values.NewPassword) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Пароли не совпадают',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
    } else {
      if (type === 'own')
        axios
          .patch(
            `${prefixSSO}/Accounts/Password`,
            {
              OldPassword: values.OldPassword,
              NewPassword: values.NewPassword,
            },
            { headers: getAuthToken('auth_token') },
          )
          .then(() => {
            successNotification('Пароль успешно изменён');
            onClose(false);
          })
          .catch(({ response }) => errorThrow(response));
      else
        axios
          .patch(
            `${prefixSSO}/Users/Password`,
            {
              UserId: item?.SsoUserId,
              NewPassword: values.NewPassword,
            },
            { headers: getAuthToken('auth_token') },
          )
          .then(() => {
            successNotification('Пароль успешно изменён');
            onClose(false);
            if (getData) getData();
          })
          .catch(({ response }) => errorThrow(response));
    }
  };

  return (
    <Drawer
      placement="right"
      onClose={() => onClose(false)}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Смена пароля" />
        <div className="alert-box">
          <ExclamationCircleOutlined
            style={{ fontSize: 22 }}
            className="mr15"
          />
          <span>
            Пароль должен включать буквы, цифры и содержать минимум 8 символов.
          </span>
        </div>
        <Form
          name="password"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          className="drawer-form password-form"
          onFinish={onChangePassword}
        >
          {type === 'own' && (
            <Form.Item
              className="item"
              label="Старый пароль"
              name="OldPassword"
              required
            >
              <DInput
                required={showError}
                onChange={e => e.target.value.length > 0 && setShowError(false)}
                width="100%"
                type="password"
              />
            </Form.Item>
          )}
          <Form.Item
            className="item"
            label="Новый пароль"
            name="NewPassword"
            required
          >
            <DInput
              required={showError}
              onChange={e => e.target.value.length > 0 && setShowError(false)}
              width="100%"
              type="password"
            />
          </Form.Item>
          <Form.Item
            className="item"
            label="Подтверждение пароля"
            name="ConfirmPassword"
            required
          >
            <DInput
              required={showError}
              onChange={e => e.target.value.length > 0 && setShowError(false)}
              width="100%"
              type="password"
            />
          </Form.Item>
          <Form.Item className="mt40" wrapperCol={{ offset: 7, span: 16 }}>
            <DButton small primary type="submit">
              Сохранить
            </DButton>
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
};
