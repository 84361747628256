import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import { MatchParams } from 'types/tableData';
import '../Reminders.scss';

type TMock = {
  id: number;
  key: number;
  Date: string;
  Operation: string;
  User: string;
  Info: string;
};

export const ChangeHistory = ({ match }: MatchParams) => {
  const history = useHistory();

  const columns = [
    {
      title: 'Дата/Время',
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: 'Операции',
      dataIndex: 'Operation',
      key: 'Operation',
    },
    {
      title: 'Пользователь',
      dataIndex: 'User',
      key: 'User',
    },
    {
      title: 'Доп. информация',
      dataIndex: 'Info',
      key: 'Info',
    },
  ];

  const mock: TMock[] = [
    {
      id: 1,
      key: 1,
      Date: '26.10.2021 17:40',
      Operation: 'Добавить',
      User: 'Земцов Д.Н.',
      Info: '26.10.2021 17:40; Волков В.А.; Земцов Д.Н.',
    },
    {
      id: 2,
      key: 2,
      Date: '26.10.2021 3:12',
      Operation: 'Перенести сроки',
      User: 'Земцов Д.Н.',
      Info: '26.10.2021 17;',
    },
  ];
  //TODO: доделать переход на карточку сотрудника когда подключится апи
  return (
    <div className="history-table">
      <p>
        Контролёр: <Link to="/officer-card">Земцов Д.Н.</Link>
      </p>
      <p>
        Исполнители: <Link to="/officer-card">Земцов Д.Н.</Link>
      </p>
      <p>
        Контрльный срок: <span>26.10.2021 23:30</span>
      </p>
      <p>
        Содержание: <span>Тест 2</span>
      </p>
      <Table
        title={() => 'История изменений'}
        dataSource={mock}
        columns={columns}
        expandable={{
          expandedRowRender: () => <p className="ml55">Some information</p>,
          columnWidth: 22,
        }}
      />
      <Button danger onClick={() => history.goBack()}>
        Закрыть
      </Button>
    </div>
  );
};
