import moment from 'moment';
import {
  SearchBySettings,
  TDatePresets,
  TInitialValues,
  TnameStateDocuments,
} from './types';

export const KEY = 0;
export const VALUE = 1;

export const formattedFields = [
  //TODO: передалть на [] из [[],[]]
  ['AddresseeIds', 'AddresseeId'],
  ['AuthorIds', 'AuthorId'],
  ['ControllerIds', 'ControllerId'],
  ['ExecutorIds', 'ExecutorId'],
  ['CorrespondentIds', 'CorrespondentId'],
  ['OrganisationReceiverIds', 'OrganisationReceiverId'],
  ['SenderIds', 'SenderId'],
];

export const initialSelectedFields = {
  AuthorId: [],
  ExecutorId: [],
  ControllerId: [],
  CorrespondentId: [],
  AddresseeId: [],
  OrganisationReceiverId: [],
  SenderId: [],
};

export const nameStateDocuments: TnameStateDocuments[] = [
  {
    Name: 'Неизвестен.',
    State: 'Unknown',
  },
  {
    Name: 'Сформирован.',
    State: 'Create',
  },
  {
    Name: 'Согласован.',
    State: 'Approved',
  },
  {
    Name: 'Ожидает согласования.',
    State: 'Approving',
  },
  {
    Name: 'Архивный.',
    State: 'Archive',
  },
  {
    Name: 'Ожидает архивирования.',
    State: 'Archiving',
  },
  {
    Name: 'Завершен.',
    State: 'Closed',
  },
  {
    Name: 'Ожидает уточнений.',
    State: 'Precising',
  },
  {
    Name: 'Зарегистрирован.',
    State: 'Register',
  },
  {
    Name: 'Отклонен.',
    State: 'Rejected',
  },
  {
    Name: 'Рассмотрен.',
    State: 'Reviewed',
  },
  {
    Name: 'Раcсмотрение.',
    State: 'Reviewing',
  },
  {
    Name: 'На доработке.',
    State: 'Rework',
  },
  {
    Name: 'Отправлен получателям.',
    State: 'Send',
  },
  {
    Name: 'Ожидает подписания.',
    State: 'Signature',
  },
  {
    Name: 'Подписан.',
    State: 'Signed',
  },
  {
    Name: 'Согласование.',
    State: 'Agreement',
  },
  {
    Name: 'Проверка.',
    State: 'Verify',
  },
  {
    Name: 'Подписание.',
    State: 'Signing',
  },
  {
    Name: 'Регистрация.',
    State: 'Registration',
  },
  {
    Name: 'Отправка.',
    State: 'Sending',
  },
];

export const ControlStatus = [
  {
    status: 'InControl',
    title: 'На контроле',
  },
  {
    status: 'NotRequired',
    title: 'Не обязателен',
  },
  {
    status: 'OutControl',
    title: 'Без контроля',
  },
];

export const typeNames: Record<string, string> = {
  Incoming: 'Входящие',
  Outcoming: 'Исходящие',
  Inner: 'Внутренние',
};

export const DATE_PRESETS: TDatePresets = {
  registration_date: [],
  create_date: [],
  ExecutionTime: [],
  ProceedRegistrationDate: [],
};

export const CONTACT_PRESETS: string[] = [
  'AuthorId',
  'ExecutorId',
  'ControllerId',
  'CorrespondentId',
  'AddresseeId',
];

export const initialValues: TInitialValues = {
  AuthorId: undefined,
  AddresseeId: undefined,
  CorrespondentId: undefined,
  ControllerId: undefined,
  ExecutorId: undefined,
  OrgNomenclatureId: undefined,
  ControlStatus: undefined,
  DocumentKindId: undefined,
  State: undefined,
  ExecutionTime: [],
  ProceedRegistrationDate: [],
  registration_date: [undefined, moment()],
  create_date: [],
  RegNumber: '',
  InRegNumber: '',
  DocumentContain: '',
  DocumentId: '',
  DocumentType: undefined,
  searchBy: undefined,
  SenderId: undefined,
  SendTypeId: undefined,
  OrganisationReceiverId: undefined,
};

export const GET_CONTACTS = 'contacts/byorganization/active';
export const GET_CONTROLLES = 'Contacts/withrolecontroller';
export const GET_DOCUMENT_KIND = 'DocumentKinds/byorganization';
export const GET_NOMENCLATURES = 'Nomenclatures/byorganization';
export const GET_TAGS = 'tags/byuser';
export const GET_ORGANISATIONS = 'organisations/active/page';
export const GET_SENDER_ORGANISATIONS = 'counterparties/byorganization';
export const GET_SENDTYPES = 'Sendtypes';
