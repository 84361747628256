import { format } from 'date-fns';
import { useBem } from 'shared/hooks';
import cn from 'classnames';
import { FC } from 'react';
import { DocumentInfoProps } from './types';
import './DocumentInfo.scss';

const documentTypes = [
  {
    Type: 'Inner',
    Text: 'Внутренние',
  },
  {
    Type: 'Incoming',
    Text: 'Входящие',
  },
  {
    Type: 'Outcoming',
    Text: 'Исходящие',
  },
];

export const DocumentInfo: FC<DocumentInfoProps> = ({ currentDocument }) => {
  const bem = useBem('DocumentInfo');

  const getDocumentType = () => {
    const document = documentTypes.find(
      ({ Type }) => Type === currentDocument?.Type,
    );
    return document?.Text;
  };

  return (
    <article className="mb35 document-form" style={{ fontSize: 16 }}>
      <div className="form">
        <div className="mb15">
          <b>{currentDocument?.Id}</b> <br />
        </div>
        <div>
          <span>{getDocumentType()} </span> <br />
          <span>{currentDocument?.KindName}</span>
        </div>
      </div>
      <div className="form">
        <div className="mb15">
          <span style={{ color: '#999999' }}>Тема: </span>
          <span>{currentDocument?.Subject ?? 'отсутствует'}</span>
        </div>
        <div>
          <span style={{ color: '#999999' }}>Краткое содержание: </span>
          <span>{currentDocument?.Summary ?? 'отсутствует'}</span>
        </div>
      </div>
    </article>
  );
};
