import { DrawerHeader } from './DrawerHeader';
import React, { useState } from 'react';
import { Drawer, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { DButton } from 'shared/ui-kit';
import { TUser } from '../Header/Header';
import { format } from 'date-fns';

type TSubstitutionDrawer = {
  visible: boolean;
  onClose: (bool: boolean) => void;
  displayedUser: any;
  userList: any;
  updateSubstitution: (item: any) => void;
};
export const SubstitutionDrawer = ({
  visible,
  onClose,
  displayedUser,
  userList,
  updateSubstitution,
}: TSubstitutionDrawer) => {
  const [selectedItem, setSelectedItem] = useState<TUser>();

  return (
    <Drawer
      placement="right"
      onClose={() => onClose(false)}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Заместить" />
        <Menu
          defaultSelectedKeys={[`${displayedUser?.Id}`]}
          selectedKeys={[`${selectedItem?.Id}`]}
          className="drawer-form"
        >
          {userList.map((item: TUser) => (
            <Menu.Item
              onClick={() => setSelectedItem(item)}
              key={item.Id}
              icon={<UserOutlined />}
            >
              {item.LastName} {item.FirstName} {item.MiddleName} (
              {item.PositionName} {item.DepartmentName})
              {item.ExpiryDate &&
                `до ${format(new Date(item.ExpiryDate), 'dd.MM.yyyy')}`}
            </Menu.Item>
          ))}
        </Menu>
        <DButton
          small
          disabled={!selectedItem}
          defaultPrimary
          className="mt15"
          onClick={() => updateSubstitution(selectedItem)}
        >
          Заместить
        </DButton>
      </div>
    </Drawer>
  );
};
