import { Drawer, Dropdown, Menu, Skeleton } from 'antd';
import { DrawerHeader } from './DrawerHeader';
import { useCallback, useEffect, useState } from 'react';
import { DButton } from 'shared/ui-kit';
import { DownOutlined } from '@ant-design/icons';
import { $api } from 'shared/utils/api';
import { errorThrow, successNotification } from 'shared/utils';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';

type TDocumentReportDrawer = {
  visible: boolean;
  onClose: (bool: boolean) => void;
};
export const DocumentReportDrawer = ({
  visible,
  onClose,
}: TDocumentReportDrawer) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportInfo, setReportInfo] = useState<any>();
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );

  const getInfo = useCallback(() => {
    setIsLoading(true);
    $api
      .get(`documents/${documentInfo?.Id}/listapproval/html`, {
        params: {
          Id: documentInfo?.Id,
        },
      })
      .then(res => setReportInfo(res.data))
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, [documentInfo?.Id]);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const onDownloadFile = useCallback(
    (type: string) => {
      const url = window.URL.createObjectURL(new Blob([reportInfo]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Лист согласования.${type}`);
      document.body.appendChild(link);
      link.click();
      successNotification('Отчёт скачан');
    },
    [reportInfo],
  );

  const downloadMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => onDownloadFile('html')}>
        HTML файл
      </Menu.Item>
    </Menu>
  );

  return (
    <Drawer
      placement="right"
      onClose={() => onClose(false)}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Отчёт - Лист согласования" />
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            {reportInfo && (
              <Dropdown
                className="mb10"
                trigger={['click']}
                overlay={downloadMenu}
              >
                <DButton defaultPrimary small>
                  Скачать <DownOutlined />
                </DButton>
              </Dropdown>
            )}
          </>
        )}
      </div>
      {reportInfo && (
        <div className="report-wrapper" style={{ height: 'auto' }}>
          <div dangerouslySetInnerHTML={{ __html: reportInfo }} />
        </div>
      )}
    </Drawer>
  );
};
