import { Modal } from 'antd';
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_TOOLS } from './tools';
import './style.scss';
import { HelpInfoProps } from './types';
import { FC } from 'react';
import { useHelpBlock } from './useHelpBlock';
import { Icon } from '../IconComponent';
import infoSvg from 'shared/assets/images/info.svg';

export const HelpBlock: FC<HelpInfoProps> = ({ code, isDrawer }) => {
  const ReactEditorJS = createReactEditorJS();
  const {
    helpInfo,
    handleInitialize,
    editorContent,
    onClickQuestionCircle,
    visible,
    closeModal,
  } = useHelpBlock(code);

  const helpModal = (
    <Modal
      centered
      className="help-modal"
      visible={visible}
      onOk={closeModal}
      onCancel={closeModal}
      bodyStyle={{ overflow: 'auto', height: '100vh' }}
      footer={[null]}
    >
      <h2 style={{ padding: '0 50px' }}>{helpInfo?.Name}</h2>
      {helpInfo !== null ? (
        <ReactEditorJS
          value={editorContent}
          defaultValue={editorContent}
          onInitialize={handleInitialize}
          tools={EDITOR_TOOLS}
          readOnly
        />
      ) : (
        <p />
      )}
    </Modal>
  );

  return (
    <>
      <Icon
        icon={infoSvg}
        alt="Информация по странице"
        onClick={onClickQuestionCircle}
        tooltip="Информация по странице"
        className="mr15"
        tooltipPlacement={isDrawer ? 'bottom' : 'top'}
      />
      {helpModal}
    </>
  );
};
