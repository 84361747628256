import sortSvg from 'shared/assets/images/sort.svg';
import { Icon } from '../IconComponent';
import { useEffect, useRef, useState } from 'react';
import { SortMenu } from './SortMenu';
import { SortTypes } from './types';
import './TableSort.scss';

export const TableSort = ({ type }: { type: SortTypes }) => {
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const ref = useRef(null);

  const handleOutSideClick = (e: any) => {
    const path = e.path || (e.composedPath && e.composedPath());
    if (!path.includes(ref.current)) setVisibleMenu(false);
  };

  useEffect(() => {
    document.body.addEventListener('click', handleOutSideClick);
  }, []);

  return (
    <div ref={ref} style={{ position: 'relative' }} className="mr15">
      <Icon
        className="sort-icon"
        icon={sortSvg}
        alt="Сортировка"
        onClick={() => setVisibleMenu(!visibleMenu)}
        tooltip="Сортировка"
      />
      {visibleMenu && (
        <div>
          <SortMenu type={type} onClose={() => setVisibleMenu(false)} />
        </div>
      )}
    </div>
  );
};
