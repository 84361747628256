import { Tooltip } from 'antd';
import { removeDocumentLinkToArchiveCase } from 'api';
import { format } from 'date-fns';
import moment from 'moment';
import { formatStateName } from 'pages/DocumentPage/components/nameStateDocuments';
import { useParams } from 'react-router-dom';
import { DButton } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { CurrentDocument } from 'types/document';
import './NomenclatureDocument.scss';

export const NomenclatureDocument = ({
  documentInfo,
  onUpdateDocuments,
  onOpenDocument,
  onOpenArchiveChangeDrawer,
}: {
  documentInfo: CurrentDocument;
  onUpdateDocuments: () => void;
  onOpenDocument: (id: number) => void;
  onOpenArchiveChangeDrawer: (documentId: number) => void;
}) => {
  const { archiveCase } = useParams<{
    archiveCase: string;
  }>();

  const onRemove = () => {
    removeDocumentLinkToArchiveCase({
      documentId: documentInfo.Id,
      archiveCaseId: Number(archiveCase),
    }).then(onUpdateDocuments);
  };

  return (
    <div className="form mt15 form-document__wrapper">
      <div className="form-document__inner">
        <DFormItemTwo label="Состояние" className="document-item">
          <b>{formatStateName(documentInfo?.State)}</b>
        </DFormItemTwo>
        <DFormItemTwo label="Тема" className="document-item">
          <Tooltip title={documentInfo?.Subject}>
            <span
              onClick={() => onOpenDocument(documentInfo.Id)}
              className="document-item__subject custom-link"
            >
              {documentInfo?.Subject}
            </span>
          </Tooltip>
        </DFormItemTwo>
        <DFormItemTwo label="Рег. номер и рег. дата" className="document-item">
          <span>
            {documentInfo?.RegNumber ?? 'б/н'} от{' '}
            {documentInfo.RegDate
              ? moment(documentInfo?.RegDate).format('DD.MM.YYYY')
              : '-'}
          </span>
        </DFormItemTwo>
        <DFormItemTwo label="Дата создания" className="document-item">
          <span>
            {documentInfo?.CreateDate &&
              format(new Date(documentInfo?.CreateDate), 'dd.MM.yyyy')}
          </span>
        </DFormItemTwo>
        <DFormItemTwo label="Автор РКК" className="document-item mr15">
          {documentInfo?.AuthorId ? (
            <span className="subject">
              {documentInfo?.AuthorLastName} {documentInfo?.AuthorFirstName}{' '}
              {documentInfo?.AuthorMiddleName}
            </span>
          ) : (
            'Не задано '
          )}
        </DFormItemTwo>
        <DFormItemTwo label="Файлы" className="document-item">
          <ul style={{ padding: 0, marginBottom: 0 }}>
            {documentInfo?.Files.length
              ? documentInfo?.Files.map(item => (
                  <span key={item.Id} className="ui-li">
                    — <span>{item.Name}</span> <br />
                    <span></span>
                  </span>
                ))
              : 'нет файлов'}
          </ul>
        </DFormItemTwo>
      </div>
      <div>
        <DButton
          small
          defaultPrimary
          className="mb15"
          onClick={() => onOpenArchiveChangeDrawer(documentInfo.Id)}
        >
          Перенести
        </DButton>
        <DButton small defaultDanger onClick={onRemove}>
          Исключить
        </DButton>
      </div>
    </div>
  );
};
