export const codeNames = [
  {
    location: 'active-documents',
    code: 'ACTIVE_DOCUMENTS_PAGE',
    name: 'Документы',
  },
  {
    location: 'DocumentsInner',
    code: 'INNER_DOCUMENTS_PAGE',
    name: 'Внутренние документы',
  },
  {
    location: 'DocumentsIncoming',
    code: 'INCOMING_DOCUMENTS_PAGE',
    name: 'Входящие документы',
  },
  {
    location: 'DocumentsOutcoming',
    code: 'OUTCOMING_DOCUMENTS_PAGE',
    name: 'Исходящие документы',
  },
  {
    location: 'on-registration',
    code: 'ON_REGISTRATION_DOCUMENTS_PAGE',
    name: 'Документы на регистрацию',
  },
  {
    location: 'control-documents',
    code: 'CONTROL_DOCUMENTS_PAGE',
    name: 'Контрольные документы',
  },
];

export const buttonTypes = [
  {
    Type: 'completed',
    Name: 'Завершенные',
    url: '/workend',
    hasOrgId: false,
  },
  {
    Type: 'my',
    Name: 'Мои документы',
    url: '/create',
    hasOrgId: true,
  },
  {
    Type: 'all',
    Name: 'Все',
    url: '/all',
    hasOrgId: false,
  },
  {
    Type: 'control',
    Name: 'На контроле',
    url: '/control',
    hasOrgId: false,
  },
  {
    Type: 'inWork',
    Name: 'В работе',
    url: '/work',
    hasOrgId: false,
  },
  {
    Type: 'draft',
    Name: 'Черновики',
    url: '/draft',
    hasOrgId: false,
  },
];
