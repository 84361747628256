import { pdfjs, Document, Page } from 'react-pdf';
import { DButton } from 'shared/ui-kit';
import { Select } from 'antd';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { AssignmentGeneralInfo } from '../AssignmentGeneralInfo';
import { stampTypes } from './constants';
import { useAssignmentStamp } from './useAssignmentStamp';
import { TAssignmentStamp } from './types';
import { StampComponent } from './components/StampComponent';
import '../AssignmentComponents.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

const { Option } = Select;

export const AssignmentStamp = ({
  phases,
  assignment,
  documentData,
  onSuccessResponse,
}: TAssignmentStamp) => {
  const {
    formik,
    onPreviousPage,
    pageNumber,
    onNextPage,
    isStamp,
    file,
    onCreateStampInfo,
    onRejectStamp,
    onDocumentLoadSuccess,
    draggableEntity,
    dragWrapperSize,
    updateSelectedPhase,
    getFileInfo,
    stampXY,
    onDragging,
    setStampXY,
    setRegXY,
    regXY,
    checkStampOnThePage,
    renderDocuments,
    isLoading,
  } = useAssignmentStamp(assignment, onSuccessResponse, documentData);

  return (
    <>
      <div className="page3">
        <AssignmentGeneralInfo
          assignment={assignment}
          documentData={documentData}
        />
      </div>
      <form className="drawer-form page3" onSubmit={formik.handleSubmit}>
        <div className="pdf-content">
          <div id="pdfcontent" style={{ width: '100%' }}>
            <DFormItemTwo label="Файл" name="File">
              <Select
                value={formik.values.File}
                className="ui-select"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={value => formik.setFieldValue('File', value)}
                onSelect={(option: any) => {
                  if (typeof option === 'object') {
                    getFileInfo(Number(option.value));
                  }

                  getFileInfo(Number(option));
                }}
              >
                {documentData?.Files.map(file => (
                  <Option value={file.Id}>{file.Name}</Option>
                ))}
              </Select>
            </DFormItemTwo>
            <DFormItemTwo label="Тип штампа" name="StampType">
              <Select
                value={formik.values.StampType}
                disabled={isStamp}
                className="ui-select"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={value => formik.setFieldValue('StampType', value)}
              >
                {stampTypes?.map(stamp => (
                  <Option value={stamp.Type}>{stamp.Name}</Option>
                ))}
              </Select>
            </DFormItemTwo>
            <DFormItemTwo>
              <div className="navigation-content">
                <DButton onClick={onPreviousPage}>←</DButton>
                <h3> Страница: {pageNumber}</h3>
                <DButton onClick={onNextPage}>→</DButton>
              </div>
              {!isStamp && !checkStampOnThePage() ? (
                <DButton
                  disabled={!formik.values.StampType}
                  className="ml15"
                  onClick={onCreateStampInfo}
                >
                  Создать штамп
                </DButton>
              ) : (
                <DButton className="ml15" defaultDanger onClick={onRejectStamp}>
                  Отменить создание штампа
                </DButton>
              )}
            </DFormItemTwo>
            <DFormItemTwo>
              {phases.length ? (
                phases.map(item => (
                  <DButton
                    disabled={
                      !formik.values.File ||
                      !formik.values.StampType ||
                      isLoading
                    }
                    onClick={() => updateSelectedPhase(item.Id)}
                    className="mr15"
                    primary
                    small
                    type="submit"
                  >
                    {item.LinkName ?? 'Выполнить'}
                  </DButton>
                ))
              ) : (
                <DButton
                  disabled={!formik.values.File || isLoading}
                  onClick={() => updateSelectedPhase(null)}
                  className="mr15"
                  primary
                  small
                  type="submit"
                >
                  Выполнить
                </DButton>
              )}
            </DFormItemTwo>
          </div>
        </div>
      </form>
      {renderDocuments()}
      <article className="report-wrapper">
        {file && (
          <div>
            <Document
              options={{ workerSrc: '/pdf.worker.js' }}
              onLoadSuccess={onDocumentLoadSuccess}
              file={file}
            >
              <Page pageNumber={pageNumber} />
              {isStamp ? (
                <StampComponent
                  stampType={formik.values.StampType}
                  dragWrapperSize={dragWrapperSize}
                  stampXY={stampXY}
                  draggableEntity={draggableEntity}
                  onDragging={onDragging}
                  setStampXY={setStampXY}
                  setRegXY={setRegXY}
                  regXY={regXY}
                />
              ) : null}
            </Document>
          </div>
        )}
      </article>
    </>
  );
};
