import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TOrganisationsList } from 'shared/components/CustomDrawers/FilterDrawer/types';

type OfficerRelations = {
  OrganisationId?: number;
  OrganisationName?: string;
  AddresseeId?: number | null;
  AddresseeLastName?: string | null;
  AddresseeFirstName?: string | null;
  AddresseeMiddleName?: string | null;
  SendTypeName?: string;
  SendTypeId?: number;
  ContactId?: number;
};

type TCreateDocument = {
  documentReceivers: TOrganisationsList[];
  officerRelations: OfficerRelations[];
  disabledButton: boolean;
};

const initialState: TCreateDocument = {
  documentReceivers: [],
  officerRelations: [],
  disabledButton: false,
};

const creatingDocumentSlice = createSlice({
  name: 'creatingDocument',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    updateDocumentReceivers(
      state,
      action: PayloadAction<TOrganisationsList[]>,
    ) {
      state.documentReceivers = action.payload;
    },
    updateOfficerRelations(state, action: PayloadAction<OfficerRelations[]>) {
      state.officerRelations = action.payload;
    },
    updateDisabledButton(state, action: PayloadAction<boolean>) {
      state.disabledButton = action.payload;
    },
  },
});

export const {
  reset,
  updateDocumentReceivers,
  updateOfficerRelations,
  updateDisabledButton,
} = creatingDocumentSlice.actions;
export const creatingDocumentReducer = creatingDocumentSlice.reducer;
