import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { Skeleton } from 'antd';
import { PageHeader } from 'shared/components/PageHeader';
import { updateReport } from 'store/slice/reports';
import { REPORTS_PAGE } from 'shared/constants/helpCodes';

export type TReports = {
  Key: string;
  Value: string;
};

const EXCEPTION_KEYS = [
  'DocumentApprovalList',
  'DocumentSingle',
  'ArchiveCase',
];

export const Report = () => {
  const [reports, setReports] = useState<TReports[]>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    $api
      .get('Reports')
      .then(({ data }) => {
        const newArray = data.filter(
          (item: TReports) => !EXCEPTION_KEYS.includes(item.Key),
        );
        setReports(newArray);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setLoader(false));
  }, []);

  return (
    <section id="reports">
      <PageHeader title="Список отчётов" code={REPORTS_PAGE} />
      {loader ? (
        <Skeleton />
      ) : (
        <div>
          {reports.map(item => (
            <div id={item.Key}>
              <p
                className="subject"
                style={{ maxWidth: '100%' }}
                onClick={() => {
                  history.push(`/reports/${item.Key}`);
                  dispatch(
                    updateReport({
                      Key: item.Key,
                      Value: item.Value,
                    }),
                  );
                }}
              >
                {item.Value}
              </p>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};
