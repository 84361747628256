import { $api } from 'shared/utils/api';
import { notification } from 'antd';
import login_error from 'shared/assets/images/login-error.png';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  InitialValues,
  LinkListTypes,
  useIssuanceAssignmentDrawerTypes,
} from './types';
import { TDepartment } from 'pages/AdminPage/Departments';
import { errorThrow, successNotification } from 'shared/utils';
import { ContactList } from 'pages/CreateDocument/types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import moment from 'moment';
import { useFormik } from 'formik';
import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import { TYPES_ASSIGNMENT } from './constants';
import {
  getAssignmentText,
  getAssignmentType,
  getNextAllowedDate,
  getValueToSendRequest,
} from './utils';
import cn from 'classnames';
import { FileListParams } from 'shared/components/Files/types';
import { formatFiles } from 'shared/utils/fromatFilesToSend';
import { fetchDepartmentData, fetchExecutors } from './api';

export const useIssuanceAssignmentDrawer = ({
  type,
  documentData,
  childType,
  currentAssignment,
  onClose,
  isFollowing,
}: useIssuanceAssignmentDrawerTypes) => {
  const [executors, setExecutors] = useState<ContactList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowPreloader, setIsShowPreloader] = useState<boolean>(false);
  const [responsibleList, setResponsibleList] = useState<any>([]);
  const [responsibleDepsList, setResponsibleDepsList] = useState<any>([]);
  const [visibleTemplateDrawer, setVisibleTemplateDrawer] = useState<boolean>(
    false,
  );
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [catalogType, setCatalogType] = useState<
    'contactsAssignment' | 'document' | 'departments'
  >('contactsAssignment');
  const [showError, setShowError] = useState<boolean>(false);
  const [currentFiles, setCurrentFiles] = useState<FileListParams[]>([]);
  const [departments, setDepartments] = useState<TDepartment[]>([]);
  const [linkList, setLinkList] = useState<LinkListTypes[]>([]);
  const [isSaved, setIsSaved] = useState(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const [visibleTemplateModal, setVisibleTemplateModal] = useState<boolean>(
    false,
  );
  const { dates } = useSelector((state: ReduxState) => state.calendarReducer);
  const holidayDays = dates.filter(item => item.holiday);
  const dispatch = useDispatch();

  const DRAWER_TITLE_CODE = `GIVE_ASSIGNMENT_${getAssignmentType(
    type,
  ).toUpperCase()}`;
  const getIdsFromLinkList = () => linkList.map(item => item.LinkedDocumentId);
  const userId = user?.CurrentContact?.Id;

  const getInitialSubjectValue = () => {
    if (currentAssignment) {
      return currentAssignment?.Subject as string;
    }

    return getAssignmentType(type);
  };

  const getNotification = (description: string) => {
    setShowError(true);
    notification['error']({
      message: 'Произошла ошибка',
      description: description,
      className: 'custom-notification__warning',
      icon: <img src={login_error} alt="error" />,
    });
  };

  const initialValues = {
    executor: [],
    responsible: undefined,
    AssignmentType: undefined,
    Subject: getInitialSubjectValue(),
    deadlineDate: getNextAllowedDate(
      moment().set({ h: 17, m: 30 }),
      holidayDays,
    ),
    Controler: undefined,
    ExecutionType: 'contacts',
    text: undefined,
    checkedValues: [],
    executorGroup: [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  const isContactType = formik.values.ExecutionType === 'contacts';

  const executorClassName = isContactType ? 'assignment-select' : '';

  const IS_SHOW_MORE_BUTTON = user?.Permissions.find(
    item => item.Entity === 'assignment.ext.contacts',
  );

  const IsDisabledResponsibles =
    !formik.values.executor || !formik.values.executor.length;

  const assignmentTypeClass =
    showError && !formik.values.AssignmentType
      ? 'ui-select-danger'
      : 'ui-select';

  const updateResponisbleList = (arrayOfIds: number[]) => {
    if (arrayOfIds.length) {
      const arrayOfResponsibles = executors.filter(item =>
        arrayOfIds.includes(item.Id),
      );
      setResponsibleList(arrayOfResponsibles);
    } else {
      setResponsibleList([]);
    }
  };

  const updateResponisbleDepsList = (arrayOfIds: number[]) => {
    if (arrayOfIds.length) {
      const arrayOfResponsibles = departments.filter(item =>
        arrayOfIds.includes(item.Id),
      );
      setResponsibleDepsList(arrayOfResponsibles);
    } else {
      setResponsibleDepsList([]);
    }
  };

  const onAddLink = (documentInfo: any) => {
    const isExist = linkList.find(
      (item: LinkListTypes) => item.LinkedDocumentId === documentInfo.Id,
    );

    if (!isExist)
      setLinkList([
        ...linkList,
        {
          DocumentRegDate: documentInfo.RegDate,
          DocumentRegNumber: documentInfo.RegNumber,
          CreateDate: moment(),
          UpdateDate: moment(),
          LinkedDocumentId: documentInfo.Id,
          AssignmentId: null,
        },
      ]);
  };

  const onRemoveLinks = (id: number) => {
    const newArray = linkList.filter(item => item.LinkedDocumentId !== id);
    setLinkList(newArray);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchExecutors()
      .then(list => setExecutors(list))
      .finally(() => setIsLoading(false));
    formik.resetForm();
  }, [childType]);

  const onCreateAssignment = (values: InitialValues) => {
    $api
      .post(
        `assignments`,
        getValueToSendRequest({
          values,
          userId,
          currentAssignment,
          childType,
          type,
          isContactType,
          documentId: documentData?.Id,
          documentLinks: getIdsFromLinkList(),
          fileIds: formatFiles(currentFiles),
          isSaved,
        }),
      )
      .then(({ data }) => {
        const currentExecutors = responsibleList
          ?.map((item: any) =>
            !item.hasOwnProperty('ShortNotation')
              ? item?.LastName + ' ' + item?.FirstName + ' ' + item?.MiddleName
              : item?.Name,
          )
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          )
          .join(', ');
        const currentDepsExecutors = responsibleDepsList
          ?.map((item: any) =>
            !item.hasOwnProperty('ShortNotation')
              ? item?.LastName + ' ' + item?.FirstName + ' ' + item?.MiddleName
              : item?.Name,
          )
          .join(', ');
        const notificationDescription = `${getAssignmentText(
          getAssignmentType(type),
          documentData?.Id,
          true,
        )} ${currentExecutors} ${currentDepsExecutors}`;
        successNotification(notificationDescription);
        getDocumentInfo({
          documentType: 'Documents' + documentData?.Type,
          documentId: documentData?.Id,
          dispatch,
        });
        onClose();
      })
      .catch(({ response }) => {
        errorThrow(response);
        setIsShowPreloader(false);
      })
      .finally(() => setIsShowPreloader(false));
  };

  const onFinish = async (values: InitialValues) => {
    if (
      (!values.executor ||
        !values.executor.length ||
        !values.executorGroup.length) &&
      !values.Subject &&
      !values.AssignmentType
    ) {
      getNotification(
        'Необходимо заполнить исполнителя, тему задачи и тип задачи',
      );
    } else if (currentAssignment && !values.AssignmentType) {
      getNotification('Необходимо заполнить тип задачи');
    } else if (
      !values.executor ||
      (Array.isArray(values.executor) &&
        !values.executor.length &&
        !values.executorGroup.length)
    ) {
      getNotification('Необходимо заполнить исполнителя');
    } else if (!values.Subject) {
      getNotification('Необходимо заполнить тему задачи');
    } else {
      setIsShowPreloader(true);
      if (isFollowing) {
        // @ts-ignore
        await onClose(true);
        onCreateAssignment(values);
      } else {
        onCreateAssignment(values);
      }
    }
  };

  const getExecutorName = useCallback(
    (arrayOfIds: number[]) => {
      const requests = arrayOfIds.map(item => $api.get(`Contacts/${item}`));
      Promise.all(requests)
        .then(res => {
          const allData: ContactList[] = res.map(item => item.data);
          setExecutors([...executors, ...allData]);
          setResponsibleList(
            [...executors, ...allData].filter(item =>
              arrayOfIds.includes(item.Id),
            ),
          );
        })
        .catch(({ response }) => errorThrow(response));
    },
    [executors],
  );

  const getExecutorGroupName = useCallback(
    (arrayOfIds: number[]) => {
      const requests = arrayOfIds.map(id => $api.get(`Departments/${id}`));
      Promise.all(requests)
        .then(res => {
          const allData: TDepartment[] = res.map(item => item.data);
          setDepartments([...departments, ...allData]);
          setResponsibleDepsList(
            [...departments, ...allData].filter(item =>
              arrayOfIds.includes(item.Id),
            ),
          );
        })
        .catch(({ response }) => errorThrow(response));
    },
    [departments],
  );

  useEffect(() => {
    fetchDepartmentData().then(list => setDepartments(list));
  }, [formik.values.ExecutionType]);

  const onChangeTemplateVisible = (visible: boolean) =>
    setVisibleTemplateDrawer(visible);

  const onChangeCatalogVisible = (visible: boolean) =>
    setVisibleCatalog(visible);

  const onSelectTemplate = (id: string) => {
    setResponsibleList([]);
    $api
      .get(`AssignmentTemplates/${id}/executors`)
      .then(res => {
        const ids = [...res.data.ExecutorsId];
        getExecutorName(ids.filter(item => item));
        getExecutorGroupName(
          [...res.data.ExecutionsGroupId].filter(item => item),
        );
        formik.setFieldValue('executor', res.data.ExecutorsId);
        formik.setFieldValue('executorGroup', res.data.ExecutionsGroupId);
        formik.setFieldValue('responsible', res.data.ResponsibleId);
        formik.setFieldValue('text', res.data.Content);
        formik.setFieldValue('Controler', res.data.ControllerId);
        formik.setFieldValue(
          'deadlineDate',
          moment().set({ hour: 17, minute: 30 }),
        );
        onChangeTemplateVisible(false);
      })
      .catch(({ response }) => errorThrow(response));
  };

  const onRemoveExecutor = (isContact: boolean, id: number) => {
    if (isContact) {
      const filteredArr = responsibleList.filter((item: any) => item.Id !== id);
      setResponsibleList(filteredArr);
      formik.setFieldValue(
        'executor',
        filteredArr.map((item: any) => item.Id),
      );
    } else {
      const filteredArr = responsibleDepsList.filter(
        (item: any) => item.Id !== id,
      );
      setResponsibleDepsList(filteredArr);
      formik.setFieldValue(
        'executorGroup',
        filteredArr.map((item: any) => item.Id),
      );
    }
  };

  useEffect(() => {
    formik.resetForm();

    formik.setFieldValue(
      'Subject',
      getAssignmentText(getAssignmentType(type), documentData?.Id),
    );
  }, []);

  const updateExecutor = (item: ContactList) => {
    setExecutors(prev => [...prev, item]);
    // if (isContactType) {
    setResponsibleList((prev: any) => [...prev, item]);
    // }
    formik.setFieldValue('executor', [...formik.values.executor, item.Id]);
  };

  const onCloseDrawer = () => {
    onClose();
    formik.resetForm();
  };

  const onSelectAssignmentType = (type: string) => {
    const subjectValue = `Документ № ${
      documentData?.Id
    } - Выдача задачи ${TYPES_ASSIGNMENT.find(
      item => item.Type === type,
    )?.Name.toLowerCase() ?? ''}`;

    setShowError(false);
    formik.setFieldValue('AssignmentType', type);
    formik.setFieldValue('Subject', subjectValue);
  };

  const onSelectExecutionType = (type: string) => {
    formik.setFieldValue('ExecutionType', type);
    formik.setFieldValue('executor', undefined);
    setResponsibleList([]);
  };

  const onOpenCatalog = (
    type: 'contactsAssignment' | 'document' | 'departments',
  ) => {
    onChangeCatalogVisible(true);
    setCatalogType(type);
  };

  const getExecutorSelectProps = (): {
    mode?: 'multiple' | 'tags';
    maxTagCount?: number | 'responsive';
    className: string;
  } => {
    const hasExecutors =
      showError && (!formik.values.executor || !formik.values.executor.length);

    return {
      mode: 'multiple',
      maxTagCount: 'responsive',
      className: cn(executorClassName, {
        'ui-select-danger': hasExecutors,
        'ui-select': !hasExecutors,
      }),
    };
  };

  const onChangeExecutor = (isContact: boolean, value: number[]) => {
    if (isContact) {
      formik.setFieldValue('executor', value);
      updateResponisbleList(value);
    } else {
      formik.setFieldValue('executorGroup', value);
      updateResponisbleDepsList(value);
    }
    setShowError(false);
  };

  const onAddDepartment = (item: TDepartment) => {
    setDepartments(prev => [...prev, item]);
    setResponsibleDepsList((prev: any) => [...prev, item]);
    const executors = formik.values.executorGroup
      ? formik.values.executorGroup
      : [];
    formik.setFieldValue('executorGroup', [...executors, item.Id]);
  };

  const onAddedItemFromCatalog = (item: ContactList) => {
    if (catalogType === 'contactsAssignment') {
      updateExecutor(item);
    }
    if (catalogType === 'document') {
      onAddLink(item);
    }
    if (catalogType === 'departments') {
      // @ts-ignore
      onAddDepartment(item as TDepartment);
    }
  };

  const onChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    e.target.value.length > 2 && setShowError(false);
  };

  const createAssignmentTemplate = useCallback(
    name => {
      $api
        .post('AssignmentTemplates/withmanyexecutors', {
          Name: name,
          Content: formik.values?.text ?? null,
          ExecutorsId: formik.values?.executor,
          ExecutionTime: formik.values.deadlineDate,
          ResponsibleId: formik.values.responsible,
          ExecutionGroupId: formik.values.executorGroup,
        })
        .then(() => {
          setVisibleTemplateModal(false);
          successNotification('Шаблон создан');
        })
        .catch(({ response }) => errorThrow(response));
    },
    [
      formik.values.deadlineDate,
      formik.values?.executor,
      formik.values.executorGroup,
      formik.values.responsible,
      formik.values?.text,
    ],
  );

  return {
    visibleTemplateDrawer,
    showError,
    currentFiles,
    setCurrentFiles,
    getNotification,
    getAssignmentType,
    onSelectTemplate,
    visibleCatalog,
    catalogType,
    onCloseDrawer,
    isLoading,
    onChangeTemplateVisible,
    formik,
    IS_SHOW_MORE_BUTTON,
    linkList,
    assignmentTypeClass,
    onSelectAssignmentType,
    onSelectExecutionType,
    getExecutorSelectProps,
    onChangeExecutor,
    onOpenCatalog,
    responsibleList,
    onRemoveExecutor,
    onRemoveLinks,
    onAddedItemFromCatalog,
    IsDisabledResponsibles,
    onChangeSubject,
    DRAWER_TITLE_CODE,
    executors,
    isContactType,
    departments,
    onChangeCatalogVisible,
    isShowPreloader,
    responsibleDepsList,
    setVisibleTemplateModal,
    visibleTemplateModal,
    createAssignmentTemplate,
    setIsSaved,
    holidayDays,
  };
};
