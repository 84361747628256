import { debounce } from 'lodash-es';
import { useState } from 'react';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { DEFAULT_COUNT } from '../constants';
import { TTreeData, UseDocumentTypeItemProps } from '../types';

export const useDocumentTypeItem = ({
  item,
  updateItem,
  onSelect,
  fetchData,
}: UseDocumentTypeItemProps) => {
  const [defaultItem] = useState<TTreeData>(item);
  const [countForDisplay, setCountForDisplay] = useState<number>(DEFAULT_COUNT);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const DOC_TYPE_CHILDS = item?.children;
  const isShowButton = DOC_TYPE_CHILDS.length > countForDisplay && !isCollapsed;

  const onSearch = debounce(value => {
    if (!value.length) {
      updateItem(defaultItem);
      fetchData();
      return;
    }

    const newChildrens = item.children.filter(item =>
      item.DocumentKindName.toLowerCase().includes(value.toLowerCase()),
    );

    updateItem({ ...item, children: newChildrens });
    setIsLoading(false);
  }, DEBOUNCE_TIME);

  const onShowMore = () => {
    setCountForDisplay(
      !isCollapsed ? DOC_TYPE_CHILDS.length + 1 : DEFAULT_COUNT,
    );

    setIsCollapsed(!isCollapsed);
  };

  const onChangeInput = (value: string) => {
    if (value.length > 2) {
      setIsLoading(true);
    }
    checkLengthStringForSearch(value, onSearch);
  };

  return {
    onChangeInput,
    isLoading,
    isShowButton,
    onShowMore,
    isCollapsed,
    DOC_TYPE_CHILDS,
    countForDisplay,
  };
};
