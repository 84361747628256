import { List, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { $api } from 'shared/utils/api';
import { DButton, DTextarea } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import moment from 'moment';
import deleteSvg from 'shared/assets/images/delete.svg';
import editSvg from 'shared/assets/images/edit.svg';
import { Icon } from 'shared/components/IconComponent';
import '../DocumentPage.scss';
import { DeleteModal, OfficerDrawer } from 'shared/components';

type TComment = {
  Id: number;
  AuthorId: number;
  AuthorFirstName: string;
  AuthorLastName: string;
  AuthorMiddleName: string;
  CanEdit: boolean;
  Content: string;
  CreateDate: string;
  DocumentId: number;
  UpdateDate: string;
};

type TCommentsTable = {
  documentId?: number;
  type?: string;
};

export const CommentsTable = ({ documentId, type }: TCommentsTable) => {
  const [comments, setComments] = useState<TComment[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAuthorId, setSelectedAuthorId] = useState<number>(0);
  const [visibleOfficerDrawer, setVisibleOfficerDrawer] = useState<boolean>(
    false,
  );
  const [currentComment, setCurrentComment] = useState<TComment | null>(null);
  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);

  const onChangeVisibleDrawer = (visible: boolean) =>
    setVisibleOfficerDrawer(visible);

  const createComment = (text: string) => {
    $api
      .post(`documents/${documentId}/comments`, {
        DocumentId: documentId,
        Content: text,
        AuthorId: user?.CurrentContact?.Id,
        CreateDate: moment(),
        UpdateDate: moment(),
      })
      .then(() => {
        fetchComment();
        setCurrentComment(null);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setValue(''));
  };

  const deleteComment = (id?: number) => {
    $api
      .delete(`documents/comments/${id}`)
      .then(() => {
        fetchComment();
        setCurrentComment(null);
      })
      .catch(({ response }) => errorThrow(response));
  };

  const editComment = (text: string) => {
    $api
      .put(`documents/${documentId}/comments/${currentComment?.Id}`, {
        Content: text,
        AuthorId: user?.CurrentContact?.Id,
        UpdateDate: moment(),
        CreateDate: currentComment?.CreateDate,
      })
      .then(() => {
        fetchComment();
        setValue('');
        setCurrentComment(null);
      });
  };

  const fetchComment = useCallback(() => {
    setIsLoading(true);
    $api
      .get(`Documents/${documentId}/comments`)
      .then(res => {
        setComments(res.data);
        setIsLoading(false);
      })
      .catch(({ response }) => errorThrow(response));
  }, [documentId]);

  useEffect(() => {
    if (documentId) fetchComment();
  }, [fetchComment]);

  return (
    <>
      {visibleOfficerDrawer ? (
        <OfficerDrawer
          visibleDrawer={visibleOfficerDrawer}
          onCloseDrawer={onChangeVisibleDrawer}
          officerId={selectedAuthorId}
        />
      ) : null}
      <div
        className="mt35 mb15"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <h2 style={{ marginBottom: 0 }}>Комментарии</h2>
        {type !== 'read' ? (
          <DButton
            className="ml15"
            small
            onClick={() => setIsModalVisible(true)}
          >
            Добавить
          </DButton>
        ) : null}
      </div>
      <List
        itemLayout="horizontal"
        dataSource={comments}
        loading={isLoading}
        renderItem={item => (
          <List.Item
            style={{ display: 'flex' }}
            key={item.Id}
            actions={
              type !== 'read' && item.CanEdit
                ? [
                    <Icon
                      icon={editSvg}
                      alt="editSvg"
                      onClick={() => {
                        setCurrentComment(item);
                        setValue(item.Content);
                        setIsModalVisible(true);
                      }}
                      className="comment-icon"
                      tooltip="Редактировать"
                    />,
                    <Icon
                      icon={deleteSvg}
                      alt="deleteSvg"
                      className="comment-icon"
                      onClick={() => {
                        setCurrentComment(item);
                        setVisibleModalDelete(true);
                      }}
                    />,
                  ]
                : [null]
            }
          >
            <List.Item.Meta
              className="route-form"
              title={
                <>
                  <span
                    onClick={() => {
                      setSelectedAuthorId(item.AuthorId);
                      onChangeVisibleDrawer(true);
                    }}
                    className="mr15 subject"
                  >
                    {item.AuthorLastName} {item.AuthorFirstName}{' '}
                    {item.AuthorMiddleName}
                  </span>
                  <span>{format(new Date(item.UpdateDate), 'dd.MM.yyyy')}</span>
                </>
              }
              description={item.Content}
            />
          </List.Item>
        )}
      />
      <Modal
        title={
          currentComment
            ? 'Редактирование комментария'
            : 'Добавление комментария'
        }
        visible={isModalVisible}
        onOk={() => {
          currentComment ? editComment(value) : createComment(value);
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
          setValue('');
          setCurrentComment(null);
        }}
        footer={[
          <DButton
            disabled={!value}
            primary
            small
            className="mr15"
            onClick={() => {
              currentComment ? editComment(value) : createComment(value);
              setIsModalVisible(false);
            }}
          >
            Отправить
          </DButton>,
          <DButton
            defaultDanger
            small
            onClick={() => {
              setIsModalVisible(false);
              setValue('');
              setCurrentComment(null);
            }}
          >
            Закрыть
          </DButton>,
        ]}
      >
        <DTextarea
          placeholder="Введите комментарий"
          maxLength={1000}
          showCount
          value={value}
          className="w100per"
          onChange={e => setValue(e.target.value)}
        />
      </Modal>
      <DeleteModal
        title="Удаление комментария"
        description="Вы уверены что хотите удалить комментарий?"
        visible={visibleModalDelete}
        setVisible={setVisibleModalDelete}
        onDelete={() => {
          setVisibleModalDelete(false);
          deleteComment(currentComment?.Id);
        }}
      />
    </>
  );
};
