import { User } from 'store/slice/table';
export { PermissionsAccess } from 'types/permissions';

export const permissionExist = (
  permissionList: User['Permissions'],
  permission: string,
) => {
  return (
    !!permissionList && permissionList.find(item => item.Entity === permission)
  );
};
