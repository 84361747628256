import './index.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { TDButton } from './type';
import {
  convertClasses,
  convertStyles,
} from 'shared/utils/getClassesAndStyles';

/**
 *
 * @danger boolean (красная кнопка)
 * @primary boolean (кнопка с заливкой)
 * @type 'button'
 * @loader boolean
 * @disabled boolean
 * @large boolean (большая кнопка)
 * @small boolean (маленькая кнопка)
 * @style any;
 * @className string, object of string;
 */
const DButton = ({
  children,
  danger,
  primary,
  defaultDanger,
  defaultPrimary,
  text,
  textDanger,
  type,
  loader,
  disabled,
  large,
  small,
  onClick,
  style,
  className,
}: TDButton) => {
  const typeButton = {
    default: 'DButton',
    danger: 'DButtonDanger',
    defaultPrimary: 'DButtonDefaultPrimary',
    defaultDanger: 'DButtonDefaultDanger',
    primary: 'DButtonPrimary',
    text: 'text',
    textDanger: 'textDanger',
  };

  const typeButtonConvert = () => {
    const typebutton = danger
      ? typeButton.danger
      : primary
      ? typeButton.primary
      : defaultDanger
      ? typeButton.defaultDanger
      : text
      ? typeButton.text
      : textDanger
      ? typeButton.textDanger
      : defaultPrimary
      ? typeButton.defaultPrimary
      : typeButton.default;

    const sizebutton = large ? 'large' : small ? 'small' : 'middle';
    return typebutton + ' ' + sizebutton;
  };

  return (
    <button
      style={convertStyles([style])}
      className={convertClasses(typeButtonConvert(), className, disabled)}
      onClick={onClick}
      type={type ? type : 'button'}
      disabled={disabled}
    >
      {loader ? <LoadingOutlined spin /> : null}
      <span className={loader ? 'loader' : ''}>{children}</span>
    </button>
  );
};

export { DButton };
