import { formatStateName } from './nameStateDocuments';
import { format } from 'date-fns';
import { LinkedDocuments } from './LinkedDocuments';
import { CurrentDocument } from 'types/document';
import { useState } from 'react';
import { OfficerDrawer } from 'shared/components';
import { Report } from './Report';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { DocumentFiles } from './DocumentFiles';
import { DocumentSendContext } from './DocumentSendContext';
import { OrganisationDrawer } from 'shared/components/CustomDrawers/OrganisationDrawer';

type TGeneralInfo = {
  currentDocument: CurrentDocument | null;
  canEdit: boolean;
};

export const GeneralInfo = ({ currentDocument, canEdit }: TGeneralInfo) => {
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [selectItem, setSelectItem] = useState<number>(0);
  const [drawerType, setDrawerType] = useState<'organisation' | 'contact'>(
    'contact',
  );

  const onCloseDrawer = (bool: boolean) => setVisibleDrawer(bool);

  return (
    <>
      {visibleDrawer && drawerType === 'organisation' ? (
        <OrganisationDrawer
          visibleDrawer={visibleDrawer}
          onCloseDrawer={onCloseDrawer}
          organisationId={selectItem}
        />
      ) : null}
      {visibleDrawer && drawerType === 'contact' ? (
        <OfficerDrawer
          visibleDrawer={visibleDrawer}
          onCloseDrawer={onCloseDrawer}
          officerId={selectItem}
        />
      ) : null}
      {canEdit ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Report />
        </div>
      ) : null}
      <form
        name="basic"
        autoComplete="off"
        className="drawer-form read form mb15"
      >
        <DFormItemTwo label="Состояние">
          <b>{formatStateName(currentDocument?.State)}</b>
        </DFormItemTwo>
        <DFormItemTwo label="Дата создания">
          <span>
            {currentDocument?.CreateDate &&
              format(new Date(currentDocument?.CreateDate), 'dd.MM.yyyy')}
          </span>
        </DFormItemTwo>
        <DFormItemTwo label="Автор РКК">
          {currentDocument?.AuthorId ? (
            <span
              className="subject"
              onClick={() => {
                setDrawerType('contact');
                setVisibleDrawer(true);
                setSelectItem(currentDocument?.AuthorId);
              }}
            >
              {currentDocument?.AuthorLastName}{' '}
              {currentDocument?.AuthorFirstName}{' '}
              {currentDocument?.AuthorMiddleName}
            </span>
          ) : (
            'Не задано '
          )}
        </DFormItemTwo>
        <DFormItemTwo className="document-files" label="Файлы">
          <DocumentFiles currentDocument={currentDocument} />
        </DFormItemTwo>
        <DFormItemTwo
          style={{ alignItems: 'baseLine' }}
          label="Связанные документы"
        >
          <LinkedDocuments currentDocument={currentDocument} />
        </DFormItemTwo>
      </form>
      {currentDocument?.Type !== 'Inner' && (
        <DocumentSendContext
          currentDocument={currentDocument}
          canEdit={canEdit}
        />
      )}
    </>
  );
};
