import { Drawer, Table } from 'antd';
import { Dispatch } from 'react';
import { DButton, DInput } from 'shared/ui-kit';
import { TDocumentFile } from 'types/document';
import { DrawerHeader } from '../CustomDrawers/DrawerHeader';
import { FileListParams } from './types';
import { useUploadByBarCode } from './useUploadByBarCode';

export const UploadByBarCode = ({
  setCurrentFiles,
  onClose,
  visible,
}: {
  setCurrentFiles: Dispatch<React.SetStateAction<FileListParams[]>>;
  onClose: () => void;
  visible: boolean;
}) => {
  const {
    setSearchValue,
    searchValue,
    findFileByBarCode,
    barCodeList,
    onAddFileByBarCode,
  } = useUploadByBarCode({
    setCurrentFiles,
    onClose,
  });

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: TDocumentFile) => (
        <DButton small primary onClick={() => onAddFileByBarCode(item)}>
          Добавить
        </DButton>
      ),
    },
  ];

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
      width="70%"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Поиск файла по коду" />
        <div className="catalog__search-box mb15 mt15">
          <DInput
            type="text"
            value={searchValue}
            onChange={({ target: { value } }) => setSearchValue(value)}
            className="mr15"
          />
          <DButton small primary onClick={findFileByBarCode}>
            Найти
          </DButton>
        </div>
        <Table columns={columns} dataSource={barCodeList} />
      </div>
    </Drawer>
  );
};
