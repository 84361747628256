import { Icon } from 'shared/components/IconComponent';
import deleteSvg from 'shared/assets/images/delete.svg';
import { getDocumentType } from 'pages/CreateDocument/utils';
import { DocumentLinkProps } from './types';
import moment from 'moment';

export const DocumentLink = ({ item, onRemoveLinks }: DocumentLinkProps) => {
  const date = item.RegDate ? moment(item.RegDate).format('DD.MM.YYYY') : '-';

  return (
    <div className="flex-space" key={item.Id}>
      <div className="mb5">
        <p className="custom-link mb0">
          {getDocumentType(item.DocumentType ?? item.Type)} документ:{' '}
          {item.RegNumber ?? 'б/н'} от {date} (
          {item.Subject ?? item?.DocumentSubject})
        </p>
      </div>
      <Icon
        icon={deleteSvg}
        alt="deleteSvg"
        onClick={() => onRemoveLinks(item)}
        tooltip="Удалить"
      />
    </div>
  );
};
