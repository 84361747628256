import { Table } from 'antd';
import { debounce } from 'lodash-es';
import { ContactList } from 'pages/CreateDocument/types';
import { useCallback, useEffect, useState } from 'react';
import { OrganisationOfficerDrawer } from 'shared/components/CustomDrawers/OrganisationOfficerDrawer';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { CONTACTS } from 'shared/constants/helpCodes';
import { Icon } from 'shared/components/IconComponent';
import searchSvg from 'shared/assets/images/search.svg';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { Pagination } from 'types/pagination';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { DEFAULT_PAGINATION } from 'shared/constants/pagination';

export const Officers = () => {
  const [officerList, setOfficerList] = useState<ContactList[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [selectedOfficer, setSelectedOfficer] = useState<ContactList>();
  const [pagination, setPagination] = useState<Pagination>(DEFAULT_PAGINATION);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredCount, setFilteredCount] = useState<number>(0);
  const [isArchive, setIsArchive] = useState<boolean>(false);

  const onClose = () => {
    setVisible(false);
    setSelectedOfficer(undefined);
  };

  const getData = useCallback(
    (pagination: Pagination, query?: string) => {
      setIsLoading(true);
      const requestLink = !isArchive
        ? 'contacts/active/byorganization/page'
        : 'contacts/ban/byorganization/page';

      $api
        .get(requestLink, {
          params: {
            FastSearch: query,
            Limit: pagination.pageSize,
            Page: pagination.page - 1,
          },
        })
        .then(({ data: { Data, FilteredCount } }) => {
          setOfficerList(Data);
          setFilteredCount(FilteredCount);
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    },
    [isArchive],
  );

  useEffect(() => {
    getData(DEFAULT_PAGINATION);
    setPagination(DEFAULT_PAGINATION);
    setSearchValue('');
  }, [getData, isArchive]);

  const onSearch = useCallback(
    debounce(value => {
      getData(DEFAULT_PAGINATION, value);
      setPagination(DEFAULT_PAGINATION);
    }, DEBOUNCE_TIME),
    [],
  );

  const onChangePagination = (page: number, pageSize: number) => {
    const pagination = { page, pageSize };
    setPagination(pagination);
    getData(pagination, searchValue);
  };

  const onDelete = (id: number) => {
    $api
      .delete(`Contacts/${id}/byorganization`)
      .then(() => getData(DEFAULT_PAGINATION))
      .catch(({ response }) => errorThrow(response));
  };

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
      render: (text: string, item: ContactList) => (
        <span>
          {item.LastName} {item.FirstName} {item.MiddleName}
        </span>
      ),
    },
    {
      title: 'Забанен',
      dataIndex: 'IsBanned',
      key: 'IsBanned',
      render: (text: string, item: ContactList) => (
        <span>{item?.IsBanned ? 'Забанен' : ''}</span>
      ),
    },
    {
      title: 'Должность',
      dataIndex: 'PositionName',
      key: 'PositionName',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: ContactList) => (
        <>
          <DButton
            small
            defaultPrimary
            className="mr15"
            onClick={() => {
              setVisible(true);
              setSelectedOfficer(item);
              setType('edit');
            }}
          >
            Редактировать
          </DButton>
          <DButton small defaultDanger onClick={() => onDelete(item.Id)}>
            Удалить
          </DButton>
        </>
      ),
    },
  ];

  return (
    <>
      {visible && (
        <OrganisationOfficerDrawer
          visible={visible}
          onClose={onClose}
          type={type}
          item={selectedOfficer}
          getData={getData}
          pagination={pagination}
        />
      )}
      <PageHeader title="Сотрудники" code={CONTACTS} />
      <div className="flex-space mb15">
        <div>
          <DButton
            onClick={() => {
              setVisible(true);
              setType('create');
            }}
            className="mr15"
            small
            primary
          >
            + Создать
          </DButton>
          <DButton
            onClick={() => {
              setIsArchive(!isArchive);
            }}
            small
            className={isArchive ? 'selected-button' : ''}
          >
            Архив
          </DButton>
        </div>
        <DInput
          style={{ maxWidth: 630, width: '100%' }}
          icon={<Icon icon={searchSvg} alt="searchSvg" />}
          type="search"
          placeholder="Поиск"
          value={searchValue}
          onChange={({ target: { value } }) => {
            setSearchValue(value);
            checkLengthStringForSearch(value, onSearch);
          }}
        />
      </div>

      <Table
        loading={isLoading}
        dataSource={officerList}
        columns={columns}
        pagination={{
          onChange: onChangePagination,
          showSizeChanger: false,
          position: ['bottomRight'],
          defaultCurrent: 1,
          defaultPageSize: 10,
          hideOnSinglePage: false,
          current: pagination?.page,
          total: filteredCount,
          showTotal: () => filteredCount,
        }}
      />
    </>
  );
};
