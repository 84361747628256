import { Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { DocumentKindDrawer } from 'shared/components/CustomDrawers/DocumentKindDrawer';
import { UploadBookDrawer } from 'shared/components/CustomDrawers/UploadBookDrawer/UploadBookDrawer';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DOCUMENT_KINDS } from 'shared/constants/helpCodes';
import searchSvg from 'shared/assets/images/search.svg';
import { Icon } from 'shared/components/IconComponent';

export type TDocumentType = {
  OrganisationId: number;
  ParentId: number;
  Name: string;
  DocumentType: string;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
};

export const documentTypes = [
  {
    type: 'Incoming',
    name: 'Входящие',
  },
  {
    type: 'Outcoming',
    name: 'Исходящие',
  },
  {
    type: 'Inner',
    name: 'Внутренние',
  },
];

const getDocumentTypeName = (type: string) =>
  documentTypes.filter(item => item.type === type).map(item => item.name)[0];

export const DocumentKinds = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [documentKindList, setDocumentKindList] = useState<TDocumentType[]>([]);
  const [defaultList, setDefaultList] = useState<TDocumentType[]>([]);
  const [selectedItem, setSelectedItem] = useState<TDocumentType>();
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [visibleUpload, setVisibleUpload] = useState<boolean>(false);

  const onCloseUpload = () => setVisibleUpload(false);

  const onClose = () => {
    setVisibleDrawer(false);
    setSelectedItem(undefined);
  };

  const getData = useCallback(() => {
    setIsLoading(true);
    $api
      .get(`documentkinds/byorganization`)
      .then(res => {
        setDocumentKindList(res.data);
        setDefaultList([...res.data]);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, []);

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Тип документа',
      dataIndex: 'DocumentType',
      key: 'DocumentType',
      render: (type: string) => getDocumentTypeName(type),
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: TDocumentType) => (
        <>
          <DButton
            small
            defaultPrimary
            className="mr15"
            onClick={() => {
              setSelectedItem(item);
              setVisibleDrawer(true);
              setType('edit');
            }}
          >
            Редактировать
          </DButton>
          <DButton
            small
            defaultDanger
            onClick={() => {
              setSelectedItem(item);
              setVisibleDrawer(true);
              setType('delete');
            }}
          >
            Удалить
          </DButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  const onSearch = (value: string) => {
    if (value) {
      const newList = defaultList?.filter(item =>
        item?.Name?.toLowerCase()?.includes(value.toLowerCase()),
      );
      setDocumentKindList(newList);
    } else {
      setDocumentKindList(defaultList);
    }
  };

  return (
    <>
      {visibleUpload && (
        <UploadBookDrawer
          visible={visibleUpload}
          onClose={onCloseUpload}
          type="documentkind"
          title="вида документов"
          getData={getData}
        />
      )}
      {visibleDrawer && (
        <DocumentKindDrawer
          visible={visibleDrawer}
          type={type}
          item={selectedItem}
          getData={getData}
          onClose={onClose}
        />
      )}
      <PageHeader title="Виды документов" code={DOCUMENT_KINDS} />
      <div className="flex-space mb15">
        <DButton
          className="mr15"
          onClick={() => {
            setVisibleDrawer(true);
            setType('create');
          }}
          small
          primary
        >
          + Создать
        </DButton>
        <DInput
          type="text"
          style={{ maxWidth: 630, width: '100%' }}
          placeholder="Поиск по наименованию"
          icon={<Icon icon={searchSvg} alt="searchSvg" />}
          onChange={e => onSearch(e.target.value)}
        />
        <DButton
          className="ml15"
          small
          defaultPrimary
          onClick={() => setVisibleUpload(true)}
        >
          Загрузить
        </DButton>
      </div>
      <Table
        loading={isLoading}
        dataSource={documentKindList}
        columns={columns}
        pagination={{
          showSizeChanger: false,
        }}
      />
    </>
  );
};
