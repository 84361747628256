import { DatePicker, Select, Skeleton } from 'antd';
import { DButton, DInput, DTextarea } from 'shared/ui-kit';
import moment, { Moment } from 'moment';
import { $api } from 'shared/utils/api';
import { AssignmentType, CurrentDocument } from 'types/document';
import { useCallback, useEffect, useState } from 'react';
import { errorThrow, successNotification } from 'shared/utils';
import { DatePick } from 'shared/components/DatePicker/DatePick';
import { useFormik } from 'formik';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { UnuseRegNumbers } from 'shared/components/CustomDrawers/UnuseRegnumbers';
import { AssignmentGeneralInfo } from './AssignmentGeneralInfo';
import { Phases } from '../types';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import { CatalogButton } from 'shared/components/CatalogButton';
import { PreLoader } from 'shared/components/PreLoader';
import { debounce } from 'lodash-es';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { getUnUniqDocuments } from 'pages/CreateDocument/components/RegistrateDocument';
import { ReduxState } from 'store/store';
import { useSelector } from 'react-redux';

type TAssignmentRegistrationsForm = {
  phases: Phases[];
  assignment: AssignmentType;
  documentData?: CurrentDocument | null;
  onSuccessResponse: (text: string) => void;
};

type NomenclatureParams = {
  Name: string;
  Id: number;
  Code: string;
  Type: string;
};

export const AssignmentRegistrationsForm = ({
  phases,
  assignment,
  documentData,
  onSuccessResponse,
}: TAssignmentRegistrationsForm) => {
  const [nomenclaturesList, setNomenclaturesList] = useState<
    NomenclatureParams[]
  >([]);
  const [selectedOrgNomenclature, setSelectedOrgNomenclature] = useState<
    number | null
  >(null);
  const [selectedPhase, setSelectedPhase] = useState<number | null>();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { Option } = Select;

  const initialValues = {
    NomenclatureId: documentData?.NomenclatureId ?? undefined,
    RegNUmberRequisite: '',
    DocumentContent: documentData?.DocumentComposition,
    Resolution: '',
    RegDate: documentData?.RegDate ? moment(documentData?.RegDate) : moment(),
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  const onClose = () => setVisible(false);

  const onCheckRegNumber = useCallback(() => {
    setIsLoading(true);
    $api
      .get('documents/checkregnumber', {
        params: {
          RegNumber: formik.values.RegNUmberRequisite,
        },
      })
      .then(({ data: { Ids } }) => {
        !Ids.length
          ? successNotification('Текущий рег. номер является уникальным')
          : getUnUniqDocuments(Ids);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, [formik.values.RegNUmberRequisite]);

  const getRegNumber = useCallback(
    ({ requestUrl, value }: { requestUrl: string; value?: number }) => {
      $api
        .get(requestUrl, {
          params: {
            DocumentId: documentData?.Id,
            NomenculatureId: value ?? selectedOrgNomenclature,
            DocumentType: documentData?.Type,
            DocumentKind: documentData?.DocumentKindId,
          },
        })
        .then(res => {
          formik.setFieldValue('RegNUmberRequisite', res.data);
        })
        .catch(({ response }) => {
          errorThrow(response);
        });
    },
    [
      documentData?.DocumentKindId,
      documentData?.Id,
      documentData?.Type,
      formik,
      selectedOrgNomenclature,
    ],
  );

  const getNomenclatures = useCallback(
    (query?: string) => {
      $api
        .get(`nomenclatures/active/page`, {
          params: {
            Limit: 15,
            FastSearch: query,
            OrganisationId: user?.CurrentContact?.OrganisationId,
          },
        })
        .then(({ data }) => {
          setNomenclaturesList(data.Data);
        })
        .catch(({ response }) => errorThrow(response));
    },
    [documentData?.OrganisationId, user?.CurrentContact?.OrganisationId],
  );

  useEffect(() => {
    getNomenclatures();
  }, [getNomenclatures]);

  const getNomenclature = useCallback(() => {
    if (documentData?.NomenclatureId)
      $api
        .get(`Nomenclatures/${documentData?.NomenclatureId}`)
        .then(res => {
          setSelectedOrgNomenclature(documentData?.NomenclatureId);
          setNomenclaturesList(prev => [...prev, res.data]);
          formik.setFieldValue('NomenclatureId', documentData?.NomenclatureId);
        })
        .catch(({ response }) => {
          errorThrow(response);
        });
  }, [documentData?.NomenclatureId]);

  useEffect(() => {
    getNomenclature();
    if (documentData?.RegNumber) {
      formik.setFieldValue('RegNUmberRequisite', documentData?.RegNumber);
    }
  }, [getNomenclature]);

  const onFinish = useCallback(
    (values: any) => {
      $api
        .patch(`Assignments/${assignment?.Id}/Execute/Registering`, {
          PhaseId: selectedPhase ?? null,
          Resolution: values.Resolution,
          RegNumber: values.RegNUmberRequisite,
          DocumentComposition: values.DocumentContent,
          OrgNomenclatureId: values.NomenclatureId,
          RegDate: values.RegDate._d,
          ExecutionFactTime: new Date().toISOString(),
        })
        .then(() => onSuccessResponse('Задача успешно исполнена'))
        .catch(({ response }) => {
          errorThrow(response);
        });
    },
    [assignment?.Id, selectedPhase, onSuccessResponse],
  );

  const onSearch = useCallback(
    debounce(query => getNomenclatures(query), DEBOUNCE_TIME),
    [],
  );

  const onSelectNomenclature = (item: NomenclatureParams) => {
    setNomenclaturesList(prev => [...prev, item]);
    formik.setFieldValue('NomenclatureId', item.Id);
    setSelectedOrgNomenclature(item.Id);
    getRegNumber({
      requestUrl: 'documents/getregnumberbynomenclarue/byonenomenclature',
      value: item.Id,
    });
  };

  return (
    <>
      {isLoading && <PreLoader />}

      {visible && (
        <UnuseRegNumbers
          visible={visible}
          onClose={onClose}
          nomenclatureId={selectedOrgNomenclature}
          formik={formik}
        />
      )}
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={() => setVisibleCatalog(false)}
          type="nomenclatures"
          func={onSelectNomenclature}
          orgId={user?.CurrentContact?.OrganisationId}
        />
      )}
      <AssignmentGeneralInfo
        assignment={assignment}
        documentData={documentData}
      />
      <form className="drawer-form" onSubmit={formik.handleSubmit}>
        <DFormItemTwo label="Дело по номенклатуре" name="NomenclatureId">
          <Select
            className="ui-select"
            allowClear
            showSearch
            disabled={!!documentData?.RegNumber}
            onClear={() => setSelectedOrgNomenclature(0)}
            onSelect={(value: any) => {
              setSelectedOrgNomenclature(value);
              getRegNumber({
                requestUrl:
                  'documents/getregnumberbynomenclarue/byonenomenclature',
                value,
              });
            }}
            onSearch={value => checkLengthStringForSearch(value, onSearch)}
            optionFilterProp="children"
            value={formik.values.NomenclatureId}
            onChange={value => formik.setFieldValue('NomenclatureId', value)}
          >
            {nomenclaturesList?.map(nomenclature => (
              <Option value={nomenclature.Id}>
                {nomenclature.Code} {nomenclature.Name}
              </Option>
            ))}
          </Select>
          <CatalogButton
            disabled={!!documentData?.RegNumber}
            onClick={() => {
              setVisibleCatalog(true);
            }}
          />
        </DFormItemTwo>
        <DFormItemTwo label="Рег. номер" name="RegNUmberRequisite">
          <DInput
            type="text"
            className="mr10"
            name="RegNUmberRequisite"
            disabled={!!documentData?.RegNumber}
            value={formik.values.RegNUmberRequisite}
            onChange={formik.handleChange}
          />
          {!documentData?.RegNumber && (
            <DButton
              onClick={() =>
                getRegNumber({ requestUrl: 'Documents/GetRegistrationNumber' })
              }
              className="mr10"
              small
              primary
            >
              Получить рег. №
            </DButton>
          )}
          <DatePicker
            style={{
              maxWidth: 140,
              height: 36,
              borderRadius: 8,
            }}
            disabled={!!documentData?.RegNumber}
            onChange={date => {
              formik.setFieldValue('RegDate', date);
            }}
            allowClear={false}
            defaultValue={moment()}
            format={'DD.MM.YYYY'}
            value={moment(formik.values.RegDate)}
          />
        </DFormItemTwo>
        {!documentData?.RegNumber && (
          <>
            <DFormItemTwo className="registrate-buttons">
              <DButton
                disabled={!formik.values?.RegNUmberRequisite}
                small
                defaultPrimary
                onClick={onCheckRegNumber}
              >
                Проверить
              </DButton>
              <span onClick={() => setVisible(true)} className="custom-link">
                Неиспользованные рег. номера
              </span>
            </DFormItemTwo>
            <DFormItemTwo label="Состав документа" name="DocumentContent">
              <DInput
                width="100%"
                type="text"
                value={formik.values.DocumentContent}
                name="DocumentContent"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo label="Комментарий исполнителя" name="Resolution">
              <DTextarea
                value={formik.values.Resolution}
                name="Resolution"
                onChange={formik.handleChange}
                className="w100per"
              />
            </DFormItemTwo>
          </>
        )}
        <DFormItemTwo className="form-buttons">
          {phases.length ? (
            phases.map(item => (
              <DButton
                disabled={Boolean(!formik.values.RegNUmberRequisite)}
                onClick={() => setSelectedPhase(item.Id)}
                className="mr15"
                primary
                small
                type="submit"
              >
                {item.LinkName ?? 'Выполнить'}
              </DButton>
            ))
          ) : (
            <DButton
              disabled={Boolean(!formik.values.RegNUmberRequisite)}
              onClick={() => setSelectedPhase(null)}
              className="mr15"
              primary
              small
              type="submit"
            >
              Выполнить
            </DButton>
          )}
        </DFormItemTwo>
      </form>
    </>
  );
};
