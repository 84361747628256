import { Tooltip } from 'antd';
import { DocumentKindItemProps } from '../types';
import { ReactComponent as ArrowRight } from 'shared/assets/images/arrow-right.svg';
import { MAX_TITLE_LENGTH } from '../constants';

export const DocumentKindItem = ({
  child,
  index,
  onSelect,
}: DocumentKindItemProps) => {
  const { DocumentKindName: title, Description } = child;
  const isMoreThanMaxLength = title.length > MAX_TITLE_LENGTH;
  const renderTitle = `${title.substring(0, MAX_TITLE_LENGTH)}${
    isMoreThanMaxLength ? '...' : ''
  }`;

  return (
    <div
      key={`${title}_${index}`}
      onClick={() => onSelect(child)}
      className="redirect-subItem"
    >
      <div>
        <Tooltip title={title}>
          <p className="redirect-subItem__title">{renderTitle}</p>
        </Tooltip>
        <p className="redirect-subItem__description">{Description}</p>
      </div>
      <ArrowRight />
    </div>
  );
};
