import { RcFile } from 'antd/lib/upload';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { downloadByResponse } from 'shared/utils/downloadByResponse';

export const onDownloadFile = (file?: RcFile & { Id: number }) => {
  if (file?.Id) {
    $api
      .get(`files/Download/${file?.Id}`, {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
        responseType: 'blob',
      })
      .then(({ data }) => {
        downloadByResponse(data, file.name);
      })
      .catch(({ response }) => errorThrow(response));
  }
};
