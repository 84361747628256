import { TDTag } from './types';
import {
  convertClasses,
  convertStyles,
} from 'shared/utils/getClassesAndStyles';
import './index.scss';

/**
 * @onClick func;
 * @disabled boolean;
 * @width string;
 * @style any;
 * @className string, object of string;
 * @key any;
 */

export const DTag = ({
  children,
  onClick,
  style,
  className,
  width,
  disabled,
  key,
  type,
}: TDTag) => {
  return (
    <div
      key={key}
      onClick={onClick}
      style={convertStyles([width && { width: width }, style])}
      className={convertClasses(
        `DTag ${type ? 'small' : ''}`,
        className,
        disabled,
      )}
    >
      <span>{children}</span>
    </div>
  );
};
