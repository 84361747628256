import './DFormItem.scss';

type TDFormItemTwo = {
  label?: string;
  children: any;
  name?: string;
  style?: any;
  className?: string;
  required?: boolean;
  childrenClassName?: string;
};

export const DFormItemTwo = ({
  label,
  children,
  name,
  style,
  className,
  required,
  childrenClassName,
}: TDFormItemTwo) => {
  return (
    <div style={style} className={`form-item ${className ?? ''}`}>
      <label
        className={`form-item__label${required ? '-required' : ''}`}
        htmlFor={name}
      >
        {label ? `${label} :` : ''}
      </label>
      <div className={childrenClassName}>{children}</div>
    </div>
  );
};
