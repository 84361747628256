import { Divider } from 'antd';
import { CurrentDocument } from 'types/document';
import { ContextItem } from './ContextItem';

type TDocumentSendContext = {
  currentDocument: CurrentDocument | null;
  canEdit: boolean;
};

export const sendingStates = [
  {
    State: 'None',
    Name: 'Не указан',
  },
  {
    State: 'PreparedForSending',
    Name: 'Подготовлен к подписанию',
  },
  {
    State: 'ReadyForSending',
    Name: 'Готов к отправке',
  },
  {
    State: 'Sended',
    Name: 'Отправлен',
  },
  {
    State: 'Canceled',
    Name: 'Отправка отменена',
  },
  {
    State: 'Error',
    Name: 'Ошибка отправки',
  },
  {
    State: 'Rejected',
    Name: 'Отклонен',
  },
];

export const DocumentSendContext = ({
  currentDocument,
  canEdit,
}: TDocumentSendContext) => {
  return (
    <>
      {currentDocument?.SendingContexts.length ? (
        <form name="basic" autoComplete="off" className="drawer-form read form">
          {currentDocument?.SendingContexts?.map((item, index) => (
            <>
              <ContextItem
                canEdit={canEdit}
                sendItem={item}
                currentDocument={currentDocument}
              />
              {index < currentDocument.SendingContexts.length - 1 ? (
                <Divider />
              ) : null}
            </>
          ))}
        </form>
      ) : null}
    </>
  );
};
