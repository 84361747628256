import { downloadArchiveCaseInventory, fetchArchiveItemsByYear } from 'api';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton } from 'shared/ui-kit';
import { ArchiveItem } from '../../type';
import { ArchiveCaseDrawer } from '../ArchiveCaseDrawer';
import { ArchiveCase } from './ArchiveCase';
import './ArchiveCase.scss';
import { $api } from 'shared/utils/api';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { errorThrow } from 'shared/utils';
import { NomenclatureParams } from 'pages/AssignmentCard/types';
import { Select } from 'antd';
import { debounce } from 'lodash-es';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';

export const ArchiveCaseByTome = () => {
  const [archiveItems, setArchiveItems] = useState<ArchiveItem[]>([]);
  const [nomenclaturesList, setNomenclaturesList] = useState<
    NomenclatureParams[]
  >([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { tomeId } = useParams<{
    tomeId: string;
  }>();
  const history = useHistory();

  const fetchNomenclatures = useCallback(
    (query?: string) => {
      $api
        .get(`nomenclatures/byorganization/active/page`, {
          params: {
            Limit: 50,
            FastSearch: query,
            OrganisationId: user?.CurrentContact?.OrganisationId,
          },
        })
        .then(({ data }) => {
          setNomenclaturesList(data.Data);
        })
        .catch(({ response }) => errorThrow(response));
    },
    [user?.CurrentContact?.OrganisationId],
  );

  const getArchiveItems = useCallback(
    (nomenclatureId?: number) => {
      fetchArchiveItemsByYear({
        tomeNumber: tomeId,
        nomenclatureId,
        orgId: user?.CurrentContact?.OrganisationId as number,
      }).then(list => setArchiveItems(list));
    },
    [tomeId, user?.CurrentContact?.OrganisationId],
  );

  useEffect(() => fetchNomenclatures(), [fetchNomenclatures]);

  useEffect(() => {
    if (tomeId) {
      getArchiveItems();
    }
  }, [getArchiveItems, tomeId]);

  const onSearch = useCallback(
    debounce(query => fetchNomenclatures(query), DEBOUNCE_TIME),
    [],
  );

  return (
    <div>
      {visibleModal && (
        <ArchiveCaseDrawer
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
          onUpdateData={getArchiveItems}
          drawerType="single"
          type="create"
        />
      )}
      <PageHeader title={tomeId}>
        <DButton
          className="mr15"
          small
          defaultDanger
          onClick={() => history.push(`/archive`)}
        >
          Назад
        </DButton>
        <DButton
          className="mr15"
          small
          primary
          onClick={() => setVisibleModal(true)}
        >
          Создать дело
        </DButton>
        <DButton
          className="mr15"
          small
          primary
          onClick={() => downloadArchiveCaseInventory(Number(tomeId))}
        >
          Скачать опись
        </DButton>
        <Select
          className="ui-select tablemenu__search-input"
          allowClear
          showSearch
          placeholder="Поиск по номенклатуре"
          onChange={(value: number) => getArchiveItems(value)}
          onSearch={value => checkLengthStringForSearch(value, onSearch)}
          optionFilterProp="children"
        >
          {nomenclaturesList.map(nomenclature => (
            <Select.Option value={nomenclature.Id}>
              {nomenclature.Code} {nomenclature.Name}
            </Select.Option>
          ))}
        </Select>
      </PageHeader>

      {archiveItems?.length ? (
        <div className="archive-case-wrapper">
          {archiveItems.map(item => (
            <ArchiveCase archiveCase={item} />
          ))}
        </div>
      ) : (
        <h1 className="mt40">Данные не найдены</h1>
      )}
    </div>
  );
};
