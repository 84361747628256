import { History } from 'history';

import {
  closeBreadcrumb,
  reloadBreadcrumbs,
  updateBreadcrumbs,
} from 'store/slice/table';
import { AppDispatch } from 'store/store';

type TUseBreadcrumb = {
  docType?: string;
  id?: string;
  documentId?: number;
  assignmentType?: string;
};

type TReloadBreadcrumb = {
  path: string;
  title: string;
  id?: string;
  docType?: string;
};

export const useBreadcrumb = () => {
  const updateBreadcrumb = (
    history: History,
    dispatch: AppDispatch,
    url: string,
    title: string,
    data?: TUseBreadcrumb,
  ) => {
    history.push(url, {
      docType: data?.docType,
      id: data?.id,
      documentId: data?.documentId,
      assignmentType: data?.assignmentType,
    });
    dispatch(
      updateBreadcrumbs({
        path: url,
        title: title,
      }),
    );
  };

  const closeBreadcrumbs = (dispatch: AppDispatch, location: string) => {
    dispatch(closeBreadcrumb(location));
  };

  const reloadBreadcrumb = (
    dispatch: AppDispatch,
    history: History,
    data: TReloadBreadcrumb,
  ) => {
    dispatch(reloadBreadcrumbs(data));
    history.push(data.path, { id: data.id, documentType: data.docType });
  };

  return {
    updateBreadcrumb,
    closeBreadcrumbs,
    reloadBreadcrumb,
  };
};
