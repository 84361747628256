import { SortFilters } from './types';

export const DOCUMENT_FILTERS: SortFilters[] = [
  {
    title: 'ДАТА СОЗДАНИЯ',
    options: [
      {
        title: 'Сначала новые',
        enabled: true,
        type: 'ByCreateDateDesc',
        filterTitle: 'ДАТА СОЗДАНИЯ',
      },
      {
        title: 'Сначала старые',
        enabled: false,
        type: 'ByCreateDateAsc',
      },
    ],
  },
  {
    title: 'ДАТА РЕГИСТРАЦИИ',
    options: [
      {
        title: 'Сначала новые',
        enabled: false,
        type: 'ByRegDateDesc',
        filterTitle: 'ДАТА РЕГИСТРАЦИИ',
      },
      {
        title: 'Сначала старые',
        enabled: false,
        type: 'ByRegDate',
        // filterTitle: 'ДАТА СОЗДАНИЯ',
      },
    ],
  },
  {
    title: 'АВТОР ПО АЛФАВИТУ',
    options: [
      {
        title: 'А-Я',
        enabled: false,
        type: 'ByAuthorName',
        filterTitle: 'АВТОР ПО АЛФАВИТУ',
      },
      {
        title: 'Я-А',
        enabled: false,
        type: 'ByAuthorNameDesc',
        // filterTitle: 'АВТОР ПО АЛФАВИТУ',
      },
    ],
  },
  {
    title: 'СИСТЕМНЫЙ НОМЕР',
    options: [
      {
        title: 'По возрастанию',
        enabled: false,
        type: 'ByDocumentId',
        filterTitle: 'СИСТЕМНЫЙ НОМЕР',
      },
      {
        title: 'По убыванию',
        enabled: false,
        type: 'ByDocumentIdDesc',
        // filterTitle: 'ДАТА СОЗДАНИЯ',
      },
    ],
  },
];

export const ASSIGNMENT_FILTERS: SortFilters[] = [
  {
    title: 'ДАТА СОЗДАНИЯ',
    options: [
      {
        title: 'Сначала новые',
        enabled: true,
        type: 'ByCreateDateDesc',
        filterTitle: 'ДАТА СОЗДАНИЯ',
      },
      {
        title: 'Сначала старые',
        enabled: false,
        type: 'ByCreateDate',
      },
    ],
  },
  {
    title: 'АВТОР ПО АЛФАВИТУ',
    options: [
      {
        title: 'А-Я',
        enabled: false,
        type: 'ByAuthorName',
        filterTitle: 'АВТОР ПО АЛФАВИТУ',
      },
      {
        title: 'Я-А',
        enabled: false,
        type: 'ByAuthorNameDesc',
      },
    ],
  },
  {
    title: 'ИСПОЛНИТЕЛЬ ПО АЛФАВИТУ',
    options: [
      {
        title: 'А-Я',
        enabled: false,
        type: 'ByExecutorName',
        filterTitle: 'ИСПОЛНИТЕЛЬ ПО АЛФАВИТУ',
      },
      {
        title: 'Я-А',
        enabled: false,
        type: 'ByExecutorNameDesc',
      },
    ],
  },
  {
    title: 'ИСПОЛНИТЕЛЬ ДЕПАРТАМЕНТ ПО АЛФАВИТУ',
    options: [
      {
        title: 'А-Я',
        enabled: false,
        type: 'ByExecutorGroupName',
        filterTitle: 'ИСПОЛНИТЕЛЬ ДЕПАРТАМЕНТ ПО АЛФАВИТУ',
      },
      {
        title: 'Я-А',
        enabled: false,
        type: 'ByExecutorGroupNameDesc',
      },
    ],
  },
  {
    title: 'СИСТЕМНЫЙ НОМЕР ЗАДАЧИ',
    options: [
      {
        title: 'По возрастанию',
        enabled: false,
        type: 'ByAssignmentId',
        filterTitle: 'СИСТЕМНЫЙ НОМЕР ЗАДАЧИ',
      },
      {
        title: 'По убыванию',
        enabled: false,
        type: 'ByAssignmentIdDesc',
      },
    ],
  },
];
