import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssignmentType } from 'types/document';
import { SortActiveParams } from '../table';

export type TFilterType =
  | 'author'
  | 'executor'
  | 'overdue'
  | 'all'
  | 'executed';

type TPagination = {
  page: number;
  pageSize: number;
};

export type SignedInfo = {
  CertificateThumbprint: string;
  DocumentSignatureData: string;
  DocumentSignature: string;
};

export type Assignments = {
  assignments?: AssignmentType[];
  activeAssignment: number;
  config: any;
  filterType?: TFilterType;
  EstimationByPhase: any;
  searchValue: string;
  pagination: TPagination;
  filteredCount: number;
  assignmentList: AssignmentType[];
  isLoading: boolean;
  signedInfo: SignedInfo;
  activeAssignmentSortParams: SortActiveParams | null;
};

export type EstimationByPhaseTypes = {
  Estimation: number;
  PhaseId: number;
  IsRequired?: boolean;
};

const initialState: Assignments = {
  assignments: [],
  activeAssignment: 0,
  config: null,
  filterType: 'executor',
  EstimationByPhase: [],
  searchValue: '',
  pagination: {
    page: 1,
    pageSize: 15,
  },
  filteredCount: 0,
  assignmentList: [],
  isLoading: true,
  signedInfo: {
    CertificateThumbprint: 'Unknown',
    DocumentSignatureData: '',
    DocumentSignature: '',
  },
  activeAssignmentSortParams: {
    title: 'ДАТА СОЗДАНИЯ',
    option: 'ByCreateDateDesc',
  },
};

const assignmentSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {
    updateAssignmentsTable(
      state,
      action: PayloadAction<AssignmentType[] | undefined>,
    ) {
      state.assignments = action.payload;
      state.activeAssignment = 0;
    },
    selectActiveAssignment(state, action: PayloadAction<number>) {
      state.activeAssignment = action.payload;
    },
    updateIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateSignedInfo(state, action: PayloadAction<SignedInfo>) {
      state.signedInfo = action.payload;
    },
    updateAssignmentList(state, action: PayloadAction<AssignmentType[]>) {
      state.assignmentList = action.payload;
    },
    updateAssignmentFilterConfig(state, action: PayloadAction<any>) {
      state.config = action.payload;
    },
    updateFilterType(state, action: PayloadAction<TFilterType>) {
      state.filterType = action.payload;
    },
    updateEstimations(state, action: PayloadAction<any>) {
      state.EstimationByPhase = action.payload;
    },
    updateAssignmentSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
    updatePagination(state, action: PayloadAction<TPagination>) {
      state.pagination = action.payload;
    },
    updateFilteredCount(state, action: PayloadAction<number>) {
      state.filteredCount = action.payload;
    },
    updateAssignmentActiveSortParams(
      state,
      action: PayloadAction<SortActiveParams | null>,
    ) {
      state.activeAssignmentSortParams = action.payload;
    },
  },
});

export const {
  updateAssignmentsTable,
  selectActiveAssignment,
  updateAssignmentFilterConfig,
  updateFilterType,
  updateEstimations,
  updateAssignmentSearchValue,
  updatePagination,
  updateFilteredCount,
  updateAssignmentList,
  updateIsLoading,
  updateSignedInfo,
  updateAssignmentActiveSortParams,
} = assignmentSlice.actions;
export const assignmentReducer = assignmentSlice.reducer;
