import { message } from 'antd';

const success = () => {
  message.success('Скопировано', 3);
};

export const copyToClipboard = (url: string) => {
  const textField = document.createElement('textarea');
  textField.innerText = url;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  success();
};
