export const pointByType = [
  {
    type: 'take-control',
    point: 'DocumentControls',
    success: 'Контроль выдан',
  },
  {
    type: 'mark-execution-document',
    point: 'DocumentControls/id/Execute',
    success: 'Отмечено исполнение контроля',
  },
  {
    type: 'deregister-document',
    point: 'DocumentControls/id/Remove',
    success: 'Контроль удалён',
  },
  {
    type: 'relinquish-control',
    point: 'DocumentControls/id/Refusal',
    success: 'Документ снят с контроля',
  },
  {
    type: 'reschedule-control',
    point: 'DocumentControls/id',
    success: 'Контроль изменен',
  },
];

export const controlType = [
  {
    Type: 'take-control',
    Text: 'Поставить на контроль',
  },
  {
    Type: 'mark-execution-document',
    Text: 'Отметить исполнение',
  },
  {
    Type: 'deregister-document',
    Text: 'Снять с контроля',
  },
  {
    Type: 'relinquish-control',
    Text: 'Отказаться от контроля',
  },
  {
    Type: 'reschedule-control',
    Text: 'Перенести срок исполнения',
  },
];
