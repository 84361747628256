import { Radio, RadioChangeEvent } from 'antd';
import { fetchAssignmentList } from 'api/assignments/fetchAssignmentList';
import { updateTableData } from 'pages/MainPage/MainPage';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DButton } from 'shared/ui-kit';
import { updateAssignmentActiveSortParams } from 'store/slice/assignments';
import { SortActiveParams, updateActiveSortParams } from 'store/slice/table';
import { ReduxState } from 'store/store';
import { ASSIGNMENT_FILTERS, DOCUMENT_FILTERS } from './constants';
import { SortTypes } from './types';
import { getDefaultActiveParams } from './utils';

export const SortMenu = ({
  type = 'document',
  onClose,
}: {
  type?: SortTypes;
  onClose: () => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeParams, setActiveParams] = useState<SortActiveParams | null>(
    null,
  );
  const {
    tableType,
    tablePagination,
    searchValue,
    activeSortParams,
  } = useSelector((state: ReduxState) => state.tableDataReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const pageNumber = location.pathname
    .split('/')
    [location.pathname.split('/').length - 1].split('page=')[1];
  const {
    activeAssignmentSortParams,
    filterType,
    pagination,
    searchValue: assignmentSearchValue,
  } = useSelector((state: ReduxState) => state.assignmentReducer);
  const updateDocumentSortParams = useCallback(() => {
    if (!activeSortParams) {
      setActiveParams(
        getDefaultActiveParams(DOCUMENT_FILTERS) as SortActiveParams,
      );
    } else {
      setActiveParams(activeSortParams);
    }
  }, [activeSortParams]);

  const updateAssignmentSortParams = useCallback(() => {
    if (!activeAssignmentSortParams) {
      setActiveParams(
        getDefaultActiveParams(ASSIGNMENT_FILTERS) as SortActiveParams,
      );
    } else {
      setActiveParams(activeAssignmentSortParams);
    }
  }, [activeAssignmentSortParams]);
  console.log(activeParams);
  useEffect(() => {
    if (type === 'document') {
      updateDocumentSortParams();
    } else {
      updateAssignmentSortParams();
    }
  }, [type, updateDocumentSortParams, updateAssignmentSortParams]);

  useEffect(() => {
    if (activeParams) {
      setIsLoading(false);
    }
  }, [activeParams]);

  const onUpdateDocumentTable = () => {
    dispatch(updateActiveSortParams(activeParams));
    updateTableData({
      type: tableType,
      dispatch,
      tablePagination: {
        page: Number(pageNumber) || tablePagination?.page,
        pageSize: tablePagination?.pageSize,
      },
      searchValue,
      sortParams: activeParams as SortActiveParams,
    });
    onClose();
  };

  const onUpdateAssignmentTable = () => {
    dispatch(updateAssignmentActiveSortParams(activeParams));
    fetchAssignmentList(
      dispatch,
      {
        type: filterType,
        query: assignmentSearchValue,
        pagination,
      },
      activeParams as SortActiveParams,
    );
    onClose();
  };

  const onReject = () => {
    if (type === 'document') {
      dispatch(updateActiveSortParams(null));
      updateTableData({
        type: tableType,
        dispatch,
        tablePagination: {
          page: Number(pageNumber) || tablePagination?.page,
          pageSize: tablePagination?.pageSize,
        },
        searchValue,
      });
    } else {
      dispatch(updateAssignmentActiveSortParams(null));
      fetchAssignmentList(dispatch, {
        type: filterType,
        query: assignmentSearchValue,
        pagination,
      });
    }

    onClose();
  };

  const getOnlyOptions = () => {
    if (type === 'document') {
      return DOCUMENT_FILTERS.map(item => [...item.options]).flat();
    } else {
      return ASSIGNMENT_FILTERS.map(item => [...item.options]).flat();
    }
  };

  const onChange = (e: RadioChangeEvent) => {
    const {
      target: { value },
    } = e;
    setActiveParams({
      ...(activeParams as SortActiveParams),
      option: value,
    });
  };

  return (
    <div
      className="sort-menu"
      style={type === 'assignment' ? { height: 700 } : {}}
    >
      {/* {isLoading ? (
        <Skeleton />
      ) : (
        DOCUMENT_FILTERS.map((item, index, arr) => (
          <>
            <SortMenuItem
              activeParams={activeParams as SortActiveParams}
              item={item}
              onClick={updateActiveParams}
            />
            {index < arr?.length - 1 ? (
              <Divider style={{ margin: '20px 0' }} />
            ) : null}
          </>
        ))
      )} */}
      {getOnlyOptions().map((option, index, arr) => (
        <Radio.Group
          onChange={onChange}
          value={activeParams?.option}
          defaultValue={activeParams?.option}
        >
          <p className="sort-menu__item-title mt20">{option?.filterTitle}</p>
          <Radio className="sort-menu__item-option" value={option.type}>
            {option.title}
          </Radio>
        </Radio.Group>
      ))}
      <div className="flex-space mt20" style={{ gap: 8 }}>
        <DButton small onClick={() => onReject()}>
          Отменить
        </DButton>
        <DButton
          small
          primary
          onClick={() =>
            type === 'document'
              ? onUpdateDocumentTable()
              : onUpdateAssignmentTable()
          }
        >
          Применить
        </DButton>
      </div>
    </div>
  );
};
