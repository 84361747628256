import { errorThrow, TError } from 'shared/utils';
import { $api } from 'shared/utils/api';

export const fetchDepartmentData = async () => {
  try {
    const { data } = await $api.get(`departments/byorganization`);
    return data;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const fetchExecutors = async () => {
  try {
    const { data } = await $api.get('contacts/byorganization/active');
    return data;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};
