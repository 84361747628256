import { $api } from 'shared/utils/api';
import { updateAssignmentsTable } from 'store/slice/assignments';
import { updateRoutData } from 'store/slice/routing';
import {
  updateConfigureButtons,
  updateDocumentInfo,
  updateLoader,
} from 'store/slice/document';
import { errorThrow } from 'shared/utils';
import { AppDispatch } from 'store/store';
import { AssignmentType } from 'types/document';
import { TDepartment } from 'pages/AdminPage/Departments';
import { NomenclatureParams } from 'pages/AssignmentCard/types';

export type TFilterAssignment = 'all' | 'completed' | 'not';

const getDocumentButtonsConfig = ({
  dispatch,
  documentId,
}: {
  dispatch: AppDispatch;
  documentId: number;
}) => {
  $api
    .get(`documents/${documentId}/check`)
    .then(res => dispatch(updateConfigureButtons(res.data)))
    .catch(({ response }) => errorThrow(response));
};

export const findParent = (
  array?: AssignmentType[] | TDepartment[] | NomenclatureParams[],
) => {
  const newArray = array?.map(item => ({ children: null, ...item }));
  const map = new Map(newArray?.map((item: any) => [item.Id, item]));
  // @ts-ignore
  for (let item of map.values()) {
    if (!map.has(item.ParentId)) {
      continue;
    }
    const parent = map.get(item.ParentId);
    parent.children = [...(parent?.children || []), item];
    parent.key = parent.Id;
  }
  // @ts-ignore
  return [...map.values()].filter(item => item.ParentId === null);
};

export const fetchAssignmentsData = ({
  array,
  dispatch,
  type,
}: {
  array?: AssignmentType[];
  dispatch: AppDispatch;
  type: TFilterAssignment;
}) => {
  if (type === 'all') {
    const tree = findParent(array);
    dispatch(updateAssignmentsTable(tree));
  } else if (type === 'completed') {
    const filteredData = array?.filter(
      (item: AssignmentType) => item.State === 'Completed',
    );
    dispatch(updateAssignmentsTable(filteredData));
  } else if (type === 'not') {
    const filteredData = array?.filter(
      (item: AssignmentType) =>
        item.State !== 'Completed' && item.State !== 'Rejected',
    );
    dispatch(updateAssignmentsTable(filteredData));
  }
};

export const getDocumentInfo = ({
  documentType,
  documentId,
  dispatch,
}: any) => {
  dispatch(updateLoader(true));
  $api
    .get(`Documents/${documentId}/full`)
    .then(({ data }) => {
      fetchAssignmentsData({ array: data.Assignments, dispatch, type: 'all' });
      getDocumentButtonsConfig({ dispatch, documentId: data.Id });
      if (data?.SendType) {
        $api
          .get(`/SendTypes/${data?.SendType}`)
          .then(res => {
            const sendTypeName = { sendTypeName: res.data.Name };
            dispatch(updateDocumentInfo(Object.assign(sendTypeName, data)));
          })
          .catch(({ response }) => errorThrow(response))
          .finally(() => dispatch(updateLoader(false)));
      } else {
        dispatch(updateLoader(false));
        dispatch(updateDocumentInfo(data));
      }
    })
    .catch(({ response }) => {
      errorThrow(response);
      dispatch(updateLoader(false));
    });
  dispatch(
    updateRoutData({
      documentId: documentId,
      documentType: documentType,
    }),
  );
};
