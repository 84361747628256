export const MOCK_CHILDS = [
  {
    Name: 'Внутренние документы',
    Id: 3,
    link: 'DocumentsInner/page=1',
    parentId: null,
    permission: 'document.inner',
  },
  {
    Name: 'Исходящие документы',
    Id: 4,
    link: 'DocumentsOutcoming/page=1',
    parentId: null,
    permission: 'document.outcoming',
  },
  {
    Name: 'Входящие документы',
    Id: 5,
    link: 'DocumentsIncoming/page=1',
    parentId: null,
    permission: 'document.incoming',
  },
  {
    Name: 'На регистрацию',
    Id: 6,
    link: 'on-registration/page=1',
    parentId: null,
    permission: 'document.registration',
  },
];

export const NUMBER_TO_UNIQ_FILTER = 30;
export const NUMBER_TO_UNIQ_CHILD = 7;
