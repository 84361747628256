import { Drawer, Select } from 'antd';
import { getUserGroup } from 'api';
import { useFormik } from 'formik';
import _ from 'lodash';
import { ContactList, DeliveryParams } from 'pages/CreateDocument/types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CatalogButton } from 'shared/components/CatalogButton';
import { CatalogDrawer } from 'shared/components/CustomDrawers/CatalogDrawer';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { updateUserGroup, UserReceiverGroup } from 'store/slice/userGroups';
import { ReduxState } from 'store/store';
import { TOrganisationsList } from 'types/typesOrganisations';
import { UserGroupDrawerType } from '../types';
import { ReceiverGroup } from './ReceiverGroup';
import './ReceiverGroup.scss';

export const UserGroupDrawer = ({
  type,
  visible,
  onClose,
  selectedId,
  onGetData,
}: UserGroupDrawerType) => {
  const [visibleCatalog, setVisibleCatalog] = useState(false);
  const [catalogType, setCatalogType] = useState<'organisations' | 'contacts'>(
    'contacts',
  );
  const [receiverGroups, setReceiversGroups] = useState<
    Partial<UserReceiverGroup[]>
  >([]);
  const [deliveryList, setDeliveryList] = useState<DeliveryParams[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<{
    Id: number;
    Name: string;
  }>();
  const [organisationList, setOrganisationList] = useState<
    TOrganisationsList[]
  >([]);
  const { selectedItem } = useSelector(
    (state: ReduxState) => state.userGroupsReducer,
  );
  const [officers, setOfficers] = useState<ContactList[]>([]);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const headerText = type === 'edit' ? 'Редактирование' : 'Создание';
  const dispatch = useDispatch();

  const getDelivery = useCallback(() => {
    $api
      .get('SendTypes')
      .then(({ data }) => setDeliveryList(data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const updateOfficers = useCallback(
    (query?: string, organisationId?: number) => {
      const headers = {
        params: {
          counterpartyIds: user?.CurrentContact?.OrganisationId,
          fastSearch: query,
          limit: 25,
        },
      };
      const url = !organisationId
        ? 'contacts/active/bycounterparty/page'
        : `contacts/active/byorganization/${organisationId}/page`;

      $api
        .get(url, headers)
        .then(({ data: { Data } }) => setOfficers(Data))
        .catch(({ response }) => errorThrow(response));
      // .finally(() => setLoader(false));
    },
    [user?.CurrentContact?.OrganisationId],
  );

  const getOrganisations = useCallback(() => {
    $api
      .get('counterparties/byorganization')
      .then(({ data }) => setOrganisationList(data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  useEffect(() => {
    getOrganisations();
    updateOfficers();
  }, [getOrganisations, updateOfficers]);

  const onSubmit = (values: any) => {
    const urlRequest =
      type !== 'edit'
        ? 'organisationreceivergroups/byorganization'
        : `organisationreceivergroups/${selectedId}/byorganization`;
    const requestType = type !== 'edit' ? $api.post : $api.put;

    requestType(urlRequest, {
      Name: values.Name,
      ReceiverGroupItems: _.uniqBy(receiverGroups, 'OrganisationId').map(
        item => ({
          AddresseeId: item?.AddresseeId,
          OrganisationId: item?.OrganisationId,
          SendTypeId: item?.SendTypeId,
        }),
      ),
    })
      .then(() => onClose())
      .catch(({ response }) => errorThrow(response))
      .finally(() => onGetData());
  };

  const formik = useFormik({
    initialValues: {
      Name: selectedItem?.Name,
      organisationId: undefined,
      delivery: undefined,
      addresseeId: undefined,
    },
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    getDelivery();
  }, [getDelivery]);

  useEffect(() => {
    if (selectedId) {
      getUserGroup(selectedId).then(userGroup => {
        dispatch(updateUserGroup(userGroup));
        setReceiversGroups(userGroup.ReceiverGroupItems);
      });
    }
  }, [dispatch, selectedId]);

  const onSelectOrganisationByCatalog = (item: TOrganisationsList) => {
    formik.setFieldValue('organisationId', item.Id);
    updateOfficers('', item.Id);
    setSelectedOrganisation({ Id: item.Id, Name: item.Name as string });
    if (!formik.values.delivery) {
      formik.setFieldValue('delivery', item.SendTypes?.[0]);
    }
  };

  const onAddOnlyOrganisation = (organisationId: number) => {
    const organisationItem = {
      OrganisationId: organisationId,
      OrganisationName: selectedOrganisation?.Name,
    };
    const sendType = {
      SendTypeId: formik.values.delivery,
      SendTypeName: deliveryList.find(
        item => item.Id === formik.values.delivery,
      )?.Name,
    };
    // @ts-ignore
    setReceiversGroups(prev => [
      ...prev,
      {
        ...organisationItem,
        ...sendType,
        AddresseeFirstName: null,
        AddresseeMiddleName: null,
        AddresseeLastName: null,
        AddresseeId: null,
      },
    ]);
    updateOfficers();
    setSelectedOrganisation(undefined);
  };

  const onAddAddresses = async (addresseId: number) => {
    let addresseeInfo: any;
    let addressee: any;
    try {
      const { data: addressees } = await $api.get(`contacts/${addresseId}`);
      addresseeInfo = addressees;
      addressee = {
        AddresseeId: addresseId,
        AddresseeFirstName: addressees?.FirstName,
        AddresseeMiddleName: addressees?.MiddleName,
        AddresseeLastName: addressees?.LastName,
      };
    } catch ({ response }) {
      console.log(response);
    }

    // const addresseeInfo = officers.find(item => item.Id === addresseId);

    let organisationItem: any;
    let deliveryItem: any;

    if (formik.values.delivery) {
      deliveryItem = {
        SendTypeId: formik.values.delivery,
        SendTypeName: deliveryList.find(
          item => item.Id === formik.values.delivery,
        )?.Name,
      };
    }

    if (selectedOrganisation?.Id) {
      organisationItem = {
        OrganisationId: selectedOrganisation.Id,
        OrganisationName: organisationList.find(
          item => item.Id === selectedOrganisation.Id,
        )?.Name,
      };
      setSelectedOrganisation(undefined);
    } else {
      if (addresseeInfo?.OrganisationId)
        try {
          const { data } = await $api.get(
            `organisations/${addresseeInfo?.OrganisationId}`,
          );
          organisationItem = {
            OrganisationId: data.Id,
            OrganisationName: data.Name,
          };

          if (!formik.values.delivery) {
            deliveryItem = {
              SendTypeId: data.SendTypes[0].Id,
              SendTypeName: deliveryList.find(
                item => item.Id === data.SendTypes[0].Id,
              )?.Name,
            };
          }
        } catch ({ response }) {
          console.log(response);
        }
    }

    setReceiversGroups(prev => [
      ...prev,
      {
        ...deliveryItem,
        ...organisationItem,
        ...addressee,
      },
    ]);
  };

  const onSelectContactByCatalog = (item: ContactList) => {
    setOfficers(prev => [...prev, item]);
    onAddAddresses(item.Id);
  };

  const onSelectOrganisation = (organisationId: number) => {
    const currentOrganisation = organisationList.find(
      item => item.Id === organisationId,
    );
    setSelectedOrganisation({
      Id: organisationId,
      Name: currentOrganisation?.Name as string,
    });
    updateOfficers('', organisationId);
    if (!formik.values.delivery) {
      formik.setFieldValue('delivery', currentOrganisation?.SendTypeId);
    }
  };

  const onRemoveReceiverGroup = (item?: Partial<UserReceiverGroup>) => {
    setReceiversGroups(prev =>
      prev.filter(
        prevItem => prevItem?.OrganisationId !== item?.OrganisationId,
      ),
    );
  };

  return (
    <>
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={() => setVisibleCatalog(false)}
          type={catalogType}
          func={(item: any) => {
            if (catalogType === 'organisations') {
              onSelectOrganisationByCatalog(item);
            } else {
              onSelectContactByCatalog(item);
            }
          }}
        />
      )}
      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        closeIcon={null}
        className="drawer"
      >
        <div className="page3">
          <DrawerHeader
            onClose={onClose}
            text={headerText + ' группы пользователей'}
          />
          <form onSubmit={formik.handleSubmit}>
            <DFormItemTwo label="Имя" name="Name">
              <DInput
                width="100%"
                type="text"
                value={formik.values.Name}
                name="Name"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo label="Доставка" name="delivery">
              <Select
                onChange={value => formik.setFieldValue('delivery', value)}
                value={formik?.values?.delivery}
                className="ui-select"
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {deliveryList?.map(item => (
                  <Select.Option value={item.Id}>{item.Name}</Select.Option>
                ))}
              </Select>
            </DFormItemTwo>
            <DFormItemTwo label="Получатель" name="organisationId">
              <Select
                className="ui-select"
                allowClear
                showSearch
                optionFilterProp="children"
                value={selectedOrganisation?.Name}
                onSelect={(value: any) => onSelectOrganisation(value)}
                onClear={() => {
                  updateOfficers();
                  setSelectedOrganisation(undefined);
                }}
              >
                {_.uniqBy(organisationList, 'Id')?.map(item => (
                  <Select.Option value={item.Id}>
                    {item.Name} <br />
                    <span className="bottom-comment">{item.SendTypeName}</span>
                  </Select.Option>
                ))}
              </Select>
              {selectedOrganisation && (
                <DButton
                  defaultPrimary
                  disabled={!selectedOrganisation}
                  onClick={() =>
                    selectedOrganisation &&
                    onAddOnlyOrganisation(selectedOrganisation.Id)
                  }
                  className="ml15"
                  small
                >
                  Добавить
                </DButton>
              )}
              <CatalogButton
                onClick={() => {
                  setVisibleCatalog(true);
                  setCatalogType('organisations');
                }}
              />
            </DFormItemTwo>
            <DFormItemTwo label="Адресат" name="addresseeId">
              <Select
                className="ui-select"
                allowClear
                value={formik.values.addresseeId}
                showSearch
                optionFilterProp="children"
                onSearch={value =>
                  checkLengthStringForSearch(value, updateOfficers)
                }
                onSelect={async (value: any) => {
                  formik.setFieldValue('addresseeId', value);
                  await onAddAddresses(value);
                  formik.setFieldValue('addresseeId', undefined);
                }}
              >
                {_.uniqBy(officers, 'Id').map(item => (
                  <Select.Option key={item.Id} value={item.Id}>
                    {item?.LastName} {item?.FirstName} {item?.MiddleName}
                  </Select.Option>
                ))}
              </Select>
              <CatalogButton
                onClick={() => {
                  setVisibleCatalog(true);
                  setCatalogType('contacts');
                }}
              />
            </DFormItemTwo>
            <DFormItemTwo className="receiver-group">
              {_.uniqBy(receiverGroups, 'OrganisationId').map(item => (
                <ReceiverGroup item={item} onRemove={onRemoveReceiverGroup} />
              ))}
            </DFormItemTwo>
            <DFormItemTwo>
              <DButton small primary type="submit">
                {type === 'edit' ? 'Сохранить' : 'Создать'}
              </DButton>
            </DFormItemTwo>
          </form>
        </div>
      </Drawer>
    </>
  );
};
