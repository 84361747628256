import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';
import { DButton } from 'shared/ui-kit';
import { TDocument } from 'pages/AdminPage/DocumentFlow/types';
import { FlowTypes } from 'pages/AdminPage/DocumentFlow/components/DocumentFlowDrawer/types';
import '../DocumentFlowDrawer.scss';

type TableProcessesProps = {
  flowList: FlowTypes[];
  item?: TDocument | null;
};

type TProcesses = {
  DocumentStreamId: number;
  FlowId: number;
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  Name?: string;
};

export const TablesProcesses = ({
  flowList,
  item,
}: TableProcessesProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [processes, setProcesses] = useState<TProcesses[]>([]);

  const deleteProcess = (id: number) => {
    $api
      .delete(`documentstreams/flows/${id}`)
      .then(() => getProcess())
      .catch(({ response }) => errorThrow(response));
  };

  const addProcesses = (value: FlowTypes) => {
    $api
      .post('documentstreams/flows', {
        Id: item?.Id,
        CreateDate: value?.CreateDate,
        UpdateDate: value?.UpdateDate,
        DocumentStreamId: item?.Id,
        FlowId: value?.Id,
      })
      .then(() => getProcess())
      .catch(({ response }) => errorThrow(response));
  };

  const getProcess = useCallback(() => {
    setIsLoading(true);
    $api
      .get(`documentstreams/flows/${item?.Id}`)
      .then(({ data }) => {
        const res = data.map((itemProcess: TProcesses) => {
          const flowListFilter = flowList.filter(
            value => value?.Id === itemProcess?.FlowId,
          );

          return { ...itemProcess, Name: flowListFilter[0]?.Name };
        });

        setProcesses(res);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, [flowList, item?.Id]);

  useEffect(() => getProcess(), [getProcess]);

  const checkFindItemInProcesses = (item: FlowTypes) =>
    !processes?.find((value: TProcesses) => item?.Id === value?.FlowId);

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Действие',
      dataIndex: 'Flow',
      key: 'Flow',
      render: (text: string, item: any) => {
        if (!item?.OrganisationId) {
          return (
            <DButton
              small
              defaultDanger
              className="mr15"
              onClick={() => deleteProcess(item?.Id)}
            >
              Удалить
            </DButton>
          );
        } else if (checkFindItemInProcesses(item)) {
          return (
            <DButton
              small
              defaultPrimary
              className="mr15"
              onClick={() =>
                checkFindItemInProcesses(item) && addProcesses(item)
              }
            >
              Добавить
            </DButton>
          );
        }
      },
    },
  ];

  return (
    <>
      <h1 className="mt15">Привязанные процессы</h1>
      <Table loading={isLoading} dataSource={processes} columns={columns} />
      <h2 className="mt15">Выбор доступного процесса</h2>
      <Table loading={!flowList} dataSource={flowList} columns={columns} />
    </>
  );
};
