import { notification } from 'antd';
import { Phases } from 'pages/AssignmentCard/types';
import { getIsComma } from './getIsComma';
import { notificationToSendAssignment } from 'pages/AssignmentCard/utils';

const testFn = () => {
  console.log('test');
};

export const successNotification = (text: string) => {
  notification['success']({
    message: text,
    className: 'custom-notification__success',
  });
};

export const successNotificationToRouteSend = (
  arrayOfPhases: Phases[],
  index: number = 0,
) => {
  if (arrayOfPhases.length - 1 < index) return;
  if (arrayOfPhases[index].Contacts?.length) {
    const contactIds = arrayOfPhases[index].Contacts?.map(
      item => item.ContactId,
    );
    const contactNames = arrayOfPhases[index].Contacts?.reduce(
      (acc, { FirstName, LastName, MiddleName }, index) => {
        const fullName = `${LastName} ${FirstName} ${MiddleName}`;
        acc += `${fullName}${contactIds && getIsComma(contactIds, index)}`;
        return acc;
      },
      '',
    );
    notificationToSendAssignment(contactNames);
    return;
  }

  const userGroup = arrayOfPhases[index].UserGroups;

  if (userGroup) {
    const departmentsIds = userGroup?.map(item => item.Id);
    const departmentName = userGroup.reduce((acc, { Name }, index) => {
      acc += `${Name}${departmentsIds && getIsComma(departmentsIds, index)}`;
      return acc;
    }, '');
    notificationToSendAssignment(departmentName);
    return;
  }

  successNotificationToRouteSend(arrayOfPhases, index + 1);
};
