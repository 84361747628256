import { Drawer, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { TDepartment } from 'pages/AdminPage/Departments';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DButton, DInput, DTextarea } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow, successNotification } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { DrawerHeader } from './DrawerHeader';

type TDepartmentDrawer = {
  visible: boolean;
  onClose: () => void;
  item?: TDepartment;
  type: string;
  getData: (query?: string) => void;
  searchValue: string;
};

export const DepartmentDrawer = ({
  visible,
  onClose,
  item,
  type,
  getData,
  searchValue,
}: TDepartmentDrawer) => {
  const [departments, setDepartments] = useState<TDepartment[]>([]);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { Option } = Select;
  const [form] = useForm();

  const initialValues = {
    Name: item?.Name,
    ParentId: item?.ParentId,
    Kpp: item?.Kpp ?? '',
    ShortNotation: item?.ShortNotation ?? '',
  };

  const getDepartments = useCallback(() => {
    $api
      .get(`Departments/byorganization`)
      .then(res => {
        const array = res.data.filter(
          (department: TDepartment) => department.Id !== item?.Id,
        );
        setDepartments(array);
      })
      .catch(({ response }) => errorThrow(response));
    if (item?.ParentId)
      $api
        .get(`Departments/${item?.ParentId}/byorganization`)
        .then(res => setDepartments(prev => [...prev, res.data]))
        .catch(({ response }) => errorThrow(response));
  }, [item?.ParentId, user?.CurrentContact?.OrganisationId]);

  useEffect(() => {
    getDepartments();
    if (type === 'edit') {
      form.setFieldsValue({
        Name: item?.Name,
        ParentId: item?.ParentId ?? undefined,
      });
    }
  }, [getDepartments]);

  const onFinish = (values: any) => {
    const requestType = type === 'edit' ? $api.put : $api.post;
    const requestUrl =
      type === 'edit'
        ? `departments/${item?.Id}/byorganization`
        : 'Departments/byorganization';

    requestType(requestUrl, {
      ParentId: values?.ParentId,
      OrganisationId: user?.CurrentContact?.OrganisationId,
      Name: values?.Name,
      Id: item?.Id,
      ShortNotation: values?.ShortNotation,
      Kpp: values?.Kpp,
    })
      .then(() => {
        successNotification(
          `Департамент ${type === 'edit' ? 'обновлен' : 'создан'}`,
        );
        getData(searchValue);
        onClose();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader
          onClose={onClose}
          text={`${
            type === 'edit' ? 'Редактирование' : 'Создание'
          } подразделения`}
        />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Родительский департамент" name="ParentId">
            <Select
              value={formik.values.ParentId}
              className="ui-select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('ParentId', value)}
            >
              {departments?.map(item => (
                <Option value={item.Id}>{item.Name}</Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Имя" name="Name">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Name}
              name="Name"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="КПП" name="Kpp">
            <DInput
              width="100%"
              type="text"
              value={String(formik.values.Kpp)}
              name="Kpp"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo
            style={{ alignItems: 'baseLine' }}
            label="Краткое описание"
            name="ShortNotation"
          >
            <DTextarea
              maxLength={4000}
              showCount
              name="ShortNotation"
              value={formik.values.ShortNotation}
              className="w100per"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              {type === 'create' ? 'Создать' : 'Редактировать'}
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
