import { Drawer, Select } from 'antd';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { documentTypes } from 'pages/AdminPage/DocumentFlow/constants';
import { TDocumentType } from 'pages/AdminPage/DocumentKinds';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import './DocumentFlowDrawer.scss';
import { TablesProcesses } from './TablesProcesses';
import { TRegNumber } from 'pages/AdminPage/DocumentRegistration/types';
import { NomenclatureParams } from 'pages/AssignmentCard/types';
import {
  DocumentFlowDrawerTypes,
  FlowTypes,
  InitialValuesTypes,
} from './types';

export const DocumentFlowDrawer = ({
  visible,
  onClose,
  item,
  type,
  getData,
}: DocumentFlowDrawerTypes) => {
  const [documentKindList, setDocumentKindList] = useState<TDocumentType[]>([]);
  const [nomenclatureList, setNomenclatureList] = useState<
    NomenclatureParams[]
  >([]);
  const [regNumberList, setRegNumberList] = useState<TRegNumber[]>([]);
  const [flowList, setFlowList] = useState<FlowTypes[]>([]);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { Option } = Select;

  const initialValues: InitialValuesTypes = {
    DocumentKindId: item?.DocumentKindId,
    DocumentType: item?.DocumentType,
    NomenclatureId: item?.NomenclatureId,
    FlowId: item?.FlowId,
    Id: item?.Id,
    DocRegisterId: item?.DocRegisterId,
    Description: item?.Description,
  };

  const request = useCallback((url: string, setState: any) => {
    $api
      .get(url)
      .then(res => setState(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const onFinish = (values: InitialValuesTypes) => {
    const request = type === 'create' ? $api.post : $api.put;
    request('documentstreams/byorganisation', {
      ...values,
      OrganisationId: user?.CurrentContact?.OrganisationId,
    })
      .then(() => {
        getData();
        onClose();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const getName = (list: any[], id?: number) =>
    list?.find((item: any) => id === item.Id)?.Name;

  const formik = useFormik({
    initialValues,
    onSubmit: values => onFinish(values),
  });

  useEffect(() => {
    request('flows/byorganization', setFlowList);
    request('documentkinds/byorganization', setDocumentKindList);
    request('nomenclatures/byorganization', setNomenclatureList);
    request('documentregisters', setRegNumberList);
  }, [request]);

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      className="flow-drawer"
    >
      <div className="page3">
        <DrawerHeader
          onClose={onClose}
          text={`${type === 'edit' ? 'Редактирование' : 'Создание'} документа`}
        />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Вид документа" name="DocumentKindId">
            <Select
              value={formik.values.DocumentKindId}
              className="ui-select"
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('DocumentKindId', value)}
            >
              {documentKindList?.map(item => (
                <Option value={item.Id} key={item.Id}>
                  {item.Name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Тип документа" name="DocumentType">
            <Select
              value={formik.values.DocumentType}
              className="ui-select"
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('DocumentType', value)}
            >
              {documentTypes?.map(item => (
                <Option value={item.type} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Номенклатура" name="NomenclatureId">
            <Select
              value={formik.values.NomenclatureId}
              className="ui-select"
              showSearch
              allowClear
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('NomenclatureId', value)}
            >
              {nomenclatureList?.map(item => (
                <Option value={item.Id} key={item.Id}>
                  {item.Name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Процесс" name="FlowId">
            <Select
              value={getName(flowList, formik.values.FlowId)}
              className="ui-select"
              showSearch
              allowClear
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('FlowId', value)}
            >
              {flowList?.map(item => (
                <Option value={item.Id} key={item.Id}>
                  {item.Name}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Регистрация" name="DocRegisterId">
            <Select
              allowClear
              value={formik.values.DocRegisterId}
              className="ui-select"
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('DocRegisterId', value)}
            >
              {regNumberList?.map(item => (
                <Option value={item.Id} key={item.Id}>
                  {item?.Name ?? item?.MaskRegNumber}
                </Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Описание" name="Description">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Description}
              name="Description"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              {type === 'create' ? 'Создать' : 'Редактировать'}
            </DButton>
          </DFormItemTwo>
        </form>
        {item?.Id && <TablesProcesses flowList={flowList} item={item} />}
      </div>
    </Drawer>
  );
};
