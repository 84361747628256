import moment from 'moment';
import { useState } from 'react';
import { DInput } from 'shared/ui-kit';
import { DatePick } from '../DatePicker/DatePick';

type TPhaseEstimation = {
  estimationHours?: number;
  onChange: (hours: number) => void;
};

export const PhaseEstimation = ({
  estimationHours,
  onChange,
}: TPhaseEstimation) => {
  const [hours, setHours] = useState<number | undefined>(estimationHours);

  const onChangePicker = (date: moment.Moment) => {
    const diffInHours = moment.duration(date.diff(moment())).asHours();
    onChange(Math.ceil(diffInHours));
    setHours(Math.ceil(diffInHours));
  };

  return (
    <>
      <DatePick
        allowClear={false}
        onChange={onChangePicker}
        disabledDate={(current: moment.Moment) =>
          current &&
          current <
            moment()
              .endOf('day')
              .add(-1, 'days')
        }
        defaultValue={moment().add(hours, 'hours')}
        style={{ height: 36, borderRadius: 8 }}
        format={'DD.MM.YYYY'}
      />
      <span style={{ color: '#999999' }} className="mr10 ml15">
        Часы :
      </span>
      <DInput disabled type="text" width="50px" value={String(hours)} />
    </>
  );
};
