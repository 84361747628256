import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableMenu } from 'shared/components/TableMenu';
import {
  SortActiveParams,
  TTablePagination,
  updateCurrentItemId,
  updateSelectedFilter,
  updateTableType,
} from 'store/slice/table';
import { MainTable } from 'shared/components/MainTable';
import { MatchParams } from 'types/tableData';
import { updateTable } from 'api/table/table';
import { AppDispatch, ReduxState } from 'store/store';
import './MainPage.scss';
import { updateRoutData } from 'store/slice/routing';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

type TUpdateTable = {
  type: string;
  dispatch: AppDispatch;
  tablePagination: TTablePagination | null;
  orgId?: string | number;
  searchValue?: string;
  sendTypeId?: number | string;
  DocumentType?: string;
  sortParams?: SortActiveParams | null;
};

export const updateTableData = ({
  type,
  dispatch,
  tablePagination,
  orgId,
  searchValue,
  sendTypeId,
  DocumentType,
  sortParams,
}: TUpdateTable) => {
  dispatch(updateCurrentItemId(null));
  dispatch(updateTableType(type));

  updateTable(
    dispatch,
    type,
    searchValue,
    orgId,
    tablePagination?.page,
    tablePagination?.pageSize,
    sendTypeId,
    DocumentType,
    sortParams,
  );
  dispatch(
    updateRoutData({
      id: 'active-documents',
    }),
  );
};

export const MainPage = ({ match }: MatchParams) => {
  const {
    tablePagination,
    documentTableData,
    buttonType,
    searchValue,
    activeSortParams,
  } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { substituateId: substituteId } = useSelector(
    (state: ReduxState) => state.authReducer,
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const pageNumber = location.pathname
    .split('/')
    [location.pathname.split('/').length - 1].split('page=')[1];

  const updateTable = useCallback(
    debounce(() => {
      if (buttonType === 'inWork') {
        updateTableData({
          type: 'Documents/byuser/work',
          dispatch,
          tablePagination: {
            page: Number(pageNumber) ?? tablePagination?.page,
            pageSize: tablePagination?.pageSize,
          },
          searchValue: searchValue,
          sortParams: activeSortParams,
        });
      }
      dispatch(updateSelectedFilter(null));
    }, 500),
    [
      substituteId,
      dispatch,
      buttonType,
      activeSortParams,
      pageNumber,
      tablePagination?.pageSize,
    ],
  );

  useEffect(() => {
    updateTable();
  }, [substituteId, updateTable, activeSortParams]);

  return (
    <section id="main_table">
      <TableMenu />
      <MainTable
        data={{
          tableData: documentTableData.documents,
          filteredCount: documentTableData.filteredCount,
        }}
        match={match}
      />
    </section>
  );
};
