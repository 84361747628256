import { ReactElement, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { MatchParams } from 'types/tableData';
import { useDocumentPage } from './useDocumentPage';
import { AssignmentIndicator, DocumentFooter, TableDT } from './components';
import { PageHeader } from 'shared/components/PageHeader';
import { getDocumentInfo } from 'api/assignments/fetchAssignmentsData';
import { ReduxState } from 'store/store';
import { updateConfigureButtons, updateLoader } from 'store/slice/document';
import { PreLoader } from 'shared/components/PreLoader';
import { DocumentInfo } from './components/DocumentInfo/DocumentInfo';
import './DocumentPage.scss';
import { DocumentTag } from './components/DocumentTags/DocumentTag';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';
import { updateAssignmentFilterConfig } from 'store/slice/assignments';
import { useCallback } from 'react';

export const AllTypesDocument = ({ match }: MatchParams): ReactElement => {
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const { documentInfo, loader } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { documentHeader } = useDocumentPage({
    match,
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const documentId = isNaN(Number(location.pathname.split('/')[3]))
    ? location.pathname.split('/')[4]
    : location.pathname.split('/')[3];
  const documentType =
    location.pathname.split('/')[2] === 'on-registration' ||
    location.pathname.split('/')[2] === 'MyDocuments'
      ? location.pathname.split('/')[3]
      : location.pathname.split('/')[2];

  const DOCUMENT_CONTROL = documentInfo?.Controls[0];

  useEffect(() => {
    dispatch(updateLoader(true));
    getDocumentInfo({ documentType, documentId, dispatch });
  }, [dispatch, documentId, documentType]);

  useEffect(() => {
    dispatch(updateAssignmentFilterConfig(null));
  }, [dispatch]);

  const PAGE_TITLE = `Документ № ${documentInfo?.RegNumber ?? 'б/н'} от ${
    documentInfo?.RegDate
      ? format(new Date(documentInfo?.RegDate), 'dd.MM.yyyy')
      : '-'
  }`;

  return (
    <section id="document">
      <PageHeader title={PAGE_TITLE} code="DOCUMENT_PAGE">
        {/* DKS-2605-скрыть выдачу разрешения */}
        {/* <UnlockTwoTone
          onClick={() => setVisibleDrawer(true)}
          twoToneColor="#AAA"
          style={{ fontSize: 28 }}
          className="mr15"
        /> */}
      </PageHeader>
      {/* {visibleDrawer && (
        <DocumentPermissionDrawer visible={visibleDrawer} onClose={onClose} />
      )} */}
      {loader ? <PreLoader /> : null}
      <div className="content-head__wrapper">
        <div>
          <AssignmentIndicator />
          <DocumentTag />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {documentHeader()}
          <div style={{ whiteSpace: 'nowrap' }}>
            {DOCUMENT_CONTROL?.Id ? (
              <>
                {DOCUMENT_CONTROL?.ControlDateFact !== null ? (
                  <div className="date-show">
                    <h3 className="danger">Исполнено:</h3>
                    <h3 className="ml5">
                      {format(
                        new Date(DOCUMENT_CONTROL?.ExecutionDate),
                        'dd.MM.yyyy',
                      )}
                    </h3>
                  </div>
                ) : null}
                <div className="date-show">
                  <h3 className="danger">Срок исполнения:</h3>
                  <h3 className="ml5">
                    {format(
                      new Date(DOCUMENT_CONTROL?.ControlDate),
                      'dd.MM.yyyy',
                    )}
                  </h3>
                </div>
                {DOCUMENT_CONTROL?.Status === 'OutControl' &&
                DOCUMENT_CONTROL?.ControlDateFact === null ? (
                  <div className="date-show">
                    <h3 className="danger">Снято с контроля:</h3>
                    <h3 className="ml5">
                      {DOCUMENT_CONTROL?.RemovedFromControlDate &&
                        format(
                          new Date(DOCUMENT_CONTROL?.RemovedFromControlDate),
                          'dd.MM.yyyy',
                        )}
                    </h3>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        <DocumentInfo currentDocument={documentInfo} />
      </div>
      <TableDT />
      <DocumentFooter />
    </section>
  );
};
