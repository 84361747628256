import { Drawer, Select } from 'antd';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { ContactList } from 'pages/CreateDocument/types';
import { RemindList } from 'pages/DocumentPage/components/DocumentReminder/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DButton, DTextarea } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { DatePick } from '../DatePicker/DatePick';
import { CatalogDrawer } from './CatalogDrawer';
import { DrawerHeader } from './DrawerHeader';
import { CatalogButton } from '../CatalogButton';

type TDocumentReminderDrawer = {
  visible: boolean;
  onClose: () => void;
  type: string;
  item?: RemindList | null;
  getData: () => void;
};

const datePickStyles = { height: 36, borderRadius: 8 };

export const DocumentReminderDrawer = ({
  visible,
  onClose,
  type,
  item,
  getData,
}: TDocumentReminderDrawer) => {
  const [contactList, setContactList] = useState<ContactList[]>([]);
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const { Option } = Select;
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const DOCUMENT_CONTROL = documentInfo?.Controls[0];

  const getExecutors = useCallback(() => {
    const contactRequests =
      item?.Executors.map(item => $api.get(`contacts/${item.Id}`)) ?? [];
    Promise.all(contactRequests)
      .then(res => {
        res.map(item => setContactList(prev => [...prev, item.data]));
      })
      .catch(({ response }) => errorThrow(response));
  }, [item?.Executors]);

  const getContacts = useCallback(() => {
    $api
      .get('contacts/byorganization/active')
      .then(res => {
        setContactList(res.data);
        getExecutors();
      })
      .catch(({ response }) => errorThrow(response));
  }, [getExecutors]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const initialValues = {
    ControlId: item?.ControlId ?? undefined,
    DocumentId: item?.DocumentId ?? undefined,
    ExecutorIds: item?.Executors.length
      ? item?.Executors?.map(item => item.Id)
      : undefined,
    ExecutionDate: item?.ControlDate ?? undefined,
    Summary: item?.Summary ?? undefined,
  };

  const onFinish = (values: Partial<RemindList>) => {
    const requestType = type === 'create' ? $api.post : $api.put;
    const requestUrl =
      type === 'create'
        ? 'documentcontrolreminders'
        : `documentcontrolreminders/${item?.Id}`;

    requestType(requestUrl, {
      ...item,
      Id: item?.Id,
      ControlId: DOCUMENT_CONTROL?.Id,
      DocumentId: documentInfo?.Id,
      ExecutorIds: values.ExecutorIds,
      ControlDate:
        type !== 'execute'
          ? values.ExecutionDate ?? moment()
          : item?.ControlDate,
      Summary: values?.Summary,
      ExecutionDate: type === 'execute' ? values.ExecutionDate : undefined,
      IsExecuted: type === 'execute',
    })
      .then(() => {
        getData();
        onClose();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });
  const { setFieldValue } = formik;

  const onAddContact = (item: ContactList) => {
    setContactList(prev => [...prev, item]);
    const executorList = formik.values?.ExecutorIds?.length
      ? formik.values.ExecutorIds
      : [];
    formik.setFieldValue('ExecutorIds', [...executorList, item.Id]);
  };

  const getButtonText = () => {
    if (type === 'create') return 'Создать';
    if (type === 'edit') return 'Редактировать';
    if (type === 'execute') return 'Исполнить';
  };

  const onChangeDate = useCallback(
    (date: Moment) => {
      setFieldValue('ExecutionDate', date);
    },
    [setFieldValue],
  );

  const dateValue = useMemo(() => {
    return moment(formik.values.ExecutionDate);
  }, [formik.values.ExecutionDate]);

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={() => setVisibleCatalog(false)}
          type="contacts"
          func={onAddContact}
        />
      )}
      <div className="page3">
        <DrawerHeader
          onClose={onClose}
          text={
            type === 'create'
              ? 'Создание напоминания'
              : 'Исполнение напоминания'
          }
        />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo
            label="Исполнитель"
            name="ExecutorIds"
            className="reminder-select"
          >
            <Select
              value={formik.values.ExecutorIds}
              className="ui-select"
              allowClear
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('ExecutorIds', value)}
            >
              {_.uniqBy(contactList, 'Id')?.map(item => (
                <Option value={item.Id} key={item.Id}>
                  {item.LastName} {item.FirstName} {item.MiddleName}
                </Option>
              ))}
            </Select>
            <CatalogButton onClick={() => setVisibleCatalog(true)} />
          </DFormItemTwo>
          <DFormItemTwo label="Контрольный срок" name="ExecutionDate">
            <DatePick
              allowClear={false}
              onChange={onChangeDate}
              value={dateValue}
              style={datePickStyles}
              format="DD.MM.YYYY HH:mm"
              placeholder="Выберите дату и время"
              showTime
            />
          </DFormItemTwo>
          <DFormItemTwo label="Комментарий" name="Summary">
            <DTextarea
              className="w100per"
              value={formik.values.Summary}
              name="Summary"
              onChange={e => formik.handleChange(e)}
            />
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              {getButtonText()}
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
