import { useCallback, useRef, useState } from 'react';
import { HelpInfo } from './types';
import { $api } from 'shared/utils/api';
import { errorThrow, TError } from 'shared/utils';
import { EditorConfig } from '@editorjs/editorjs';

const requestHelpInfo = async (code: string) => {
  try {
    const { data } = await $api.get(`Helps/${code}`);
    return data;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const useHelpBlock = (code: string | undefined) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [helpInfo, setHelpInfo] = useState<HelpInfo | null>(null);
  const editorCore = useRef<EditorConfig | null>(null);

  const handleInitialize = useCallback((instance: EditorConfig) => {
    editorCore.current = instance;
  }, []);

  const closeModal = () => {
    setVisible(false);
  };

  const editorContent = helpInfo?.Text && JSON.parse(helpInfo.Text);

  const onClickQuestionCircle = () => {
    if (code) {
      setVisible(true);
      requestHelpInfo(code).then(setHelpInfo);
    }
  };

  return {
    helpInfo,
    handleInitialize,
    editorContent,
    onClickQuestionCircle,
    visible,
    closeModal,
  };
};
