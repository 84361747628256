import React, {
  FC,
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { onLogin } from 'api/login/login';
import { Button, Input, Checkbox } from '@duktus/ui-kit';
import { ForgotPasswordDrawer } from 'shared/components/CustomDrawers/ForgotPasswordDrawer';
import logo from 'shared/assets/images/updoc-logo.svg';
import error from 'shared/assets/images/error.svg';
import './LoginPage.scss';
import { useFormik } from 'formik';

export const UpDocLoginPage: FC<{ isShowForm?: boolean }> = ({
  isShowForm = true,
}): ReactElement => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const history = useHistory();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      username,
      password,
    },
    onSubmit: () => {
      onFinish({ username, password });
    },
  });

  useEffect(() => {
    username?.length && password?.length
      ? setDisabled(false)
      : setDisabled(true);
  }, [username, password]);

  const onClose = () => setVisibleDrawer(false);

  const onFinish = useCallback(
    (values: Record<string, string>) => {
      setIsLoading(true);
      if (values.username.length && values.password.length) {
        setShowError(false);
        onLogin({ dispatch, history, values, setShowError, setErrorText });
      }
      setIsLoading(false);
    },
    [dispatch, history],
  );

  return (
    <div id="login" className="login__wrapper">
      <div className="login__container-form">
        {visibleDrawer && (
          <ForgotPasswordDrawer visible={visibleDrawer} onClose={onClose} />
        )}
        {isShowForm && (
          <form
            id="basic"
            className="login__form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <h1 className="login__title-form">Войти в сервис</h1>
            {showError && (
              <div className="login__error">
                <img src={error} alt="error" />
                <span>
                  {errorText ??
                    ' Неверное имя пользователя или пароль, повторите попытку'}
                </span>
              </div>
            )}
            <Input
              autoFocus
              className="login__input"
              type="text"
              required
              onChange={e => setUsername(e.target.value)}
              placeholder="Логин"
            />
            <Input
              className="login__input"
              type="password"
              required
              onChange={e => setPassword(e.target.value)}
              placeholder="Пароль"
            />
            <div style={{ display: 'none' }}>
              <Checkbox>Запомнить меня</Checkbox>
            </div>
            <Button
              className="login__button"
              htmlType="submit"
              disabled={disabled}
              color="blue"
            >
              Войти
            </Button>
            <span
              className="login__forgot"
              onClick={() => setVisibleDrawer(true)}
            >
              Забыл пароль или логин?
            </span>
            <div style={{ display: 'none' }} className="login__link">
              <p>
                Нет аккаунта?{' '}
                <span className="custom-link">Зарегистрироваться</span>
              </p>
            </div>
          </form>
        )}
      </div>
      <div className="login__title-container">
        <img className="login__logotype" src={logo} alt="Логотип" />
        <div className="login__title">
          <span>UpDoc</span>
        </div>
      </div>
    </div>
  );
};
