export const options = [
  {
    id: 1,
    title: 'Ссылка на документ',
  },
  {
    id: 2,
    title: 'Имеет вложения',
  },
  {
    id: 3,
    title: 'Тип',
  },
  {
    id: 4,
    title: 'Признак контроля',
  },
  {
    id: 5,
    title: 'Признак исполнения',
  },
  {
    id: 6,
    title: 'Наличие ЭП',
  },
  {
    id: 7,
    title: 'Рег. номер и дата',
  },
  {
    id: 8,
    title: 'Рег. номер',
  },
  {
    id: 9,
    title: 'Дата рег.',
  },
  {
    id: 10,
    title: 'Рег. номер (№)',
  },
  {
    id: 11,
    title: 'Системный контроль',
  },
  {
    id: 12,
    title: 'Контроль',
  },
  {
    id: 13,
    title: 'Состояние',
  },
  {
    id: 14,
    title: 'Документ',
  },
  {
    id: 15,
    title: 'Краткое содержание',
  },
  {
    id: 16,
    title: 'Дата создания',
  },
  {
    id: 17,
    title: 'Получено',
  },
  {
    id: 18,
    title: 'Автор РКК',
  },
  {
    id: 19,
    title: 'Организация автора РКК',
  },
  {
    id: 20,
    title: 'Корреспондент',
  },
  {
    id: 21,
    title: 'Адресат',
  },
  {
    id: 22,
    title: 'Отправитель/Получатель',
  },
  {
    id: 23,
    title: 'Исх. номер отправителя',
  },
  {
    id: 24,
    title: 'Вид документа',
  },
  {
    id: 26,
    title: 'Тематики',
  },
  {
    id: 27,
    title: 'Дело по номенклатуре',
  },
  {
    id: 28,
    title: 'Гриф доступа',
  },
  {
    id: 29,
    title: 'Папка',
  },
  {
    id: 30,
    title: 'Отправка по МЭДО',
  },
];
