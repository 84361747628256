import { Modal } from 'antd';
import { ModalMedoNotificationTypes } from './types';
import { ReactElement } from 'react';

export const ModalMedoNotification = ({
  notificationData,
  visible,
  onClose,
}: ModalMedoNotificationTypes): ReactElement => {
  return (
    <Modal
      title="Уведомление"
      visible={visible}
      onCancel={() => onClose(false)}
      footer={null}
    >
      <p className="modal-text">{notificationData?.Content}</p>
    </Modal>
  );
};
