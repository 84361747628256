import { useState } from 'react';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton } from 'shared/ui-kit';
import { OrganisationDrawer } from './OrganisationDrawer';

export const Organisations = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  return (
    <div>
      <PageHeader title="Организации" />
      {visibleDrawer && (
        <OrganisationDrawer
          visible={visibleDrawer}
          onClose={() => setVisibleDrawer(false)}
        />
      )}
      <DButton small primary onClick={() => setVisibleDrawer(true)}>
        Создать
      </DButton>
    </div>
  );
};
