import { format } from 'date-fns';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FileListParams } from 'shared/components/Files/types';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow, getFullName } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { CurrentDocument } from 'types/document';
import { TOrganisationsList } from 'types/typesOrganisations';

type TCreateGeneralInfo = {
  formValues?: CurrentDocument;
  currentFiles: FileListParams[];
  docType: string;
};

const documentKinds = [
  {
    Kind: 'Incoming',
    Name: 'Входящие',
  },
  {
    Kind: 'Outcoming',
    Name: 'Исходящие',
  },
  {
    Kind: 'Inner',
    Name: 'Внутренние',
  },
];

export const CreateGeneralInfo = ({
  formValues,
  currentFiles,
  docType,
}: TCreateGeneralInfo) => {
  const { documentInfo, sampleSettings } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const [currentDocument, setCurrentDocument] = useState<CurrentDocument>();
  const [organisation, setOrganisation] = useState<TOrganisationsList>();
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const organisationId = currentDocument
    ? currentDocument?.OrganisationId
    : documentInfo?.OrganisationId;
  const condition = sampleSettings
    ? formValues?.Id
    : !sampleSettings
    ? formValues?.Id ?? documentInfo?.Id
    : undefined;

  const getCurrentDocument = useCallback(() => {
    if (condition) {
      $api
        .get(`Documents/${formValues?.Id ?? documentInfo?.Id}/full`)
        .then(res => setCurrentDocument(res.data))
        .catch(({ response }) => errorThrow(response));
    }
  }, [condition, documentInfo, formValues]);

  const getOrganisationInfo = useCallback(() => {
    if (organisationId) {
      $api
        .get(`organisations/${organisationId}`)
        .then(({ data }) => setOrganisation(data))
        .catch(({ response }) => errorThrow(response));
    }
  }, [organisationId]);

  useEffect(() => getCurrentDocument(), [condition, getCurrentDocument]);

  useEffect(() => getOrganisationInfo(), [getOrganisationInfo]);

  return (
    <>
      <h2>Общие сведения</h2>
      <form className="form mb15">
        <DFormItemTwo label="Документ">
          <span>
            № {currentDocument ? currentDocument?.Id : documentInfo?.Id}
          </span>
        </DFormItemTwo>
        <DFormItemTwo label="Тип документа">
          <b>{documentKinds.find(item => item.Kind === docType)?.Name}</b>
        </DFormItemTwo>
        <DFormItemTwo label="Вид документа" name="DocumentKind">
          <b>{currentDocument?.KindName}</b>
        </DFormItemTwo>
        <DFormItemTwo label="Организация:">
          <span>{organisation?.Name}</span>
        </DFormItemTwo>
        <DFormItemTwo label="Автор РКК">
          {currentDocument ? (
            <span>
              {user?.CurrentContact?.LastName} {user?.CurrentContact?.FirstName}{' '}
              {user?.CurrentContact?.MiddleName}
            </span>
          ) : (
            <span>
              {documentInfo?.AuthorLastName} {documentInfo?.AuthorFirstName}{' '}
              {documentInfo?.AuthorMiddleName}
            </span>
          )}
        </DFormItemTwo>
        <DFormItemTwo label="Тема">
          <span>
            {currentDocument ? currentDocument?.Subject : documentInfo?.Subject}
          </span>
        </DFormItemTwo>
        <DFormItemTwo label="Краткое содержание">
          {currentDocument
            ? currentDocument?.Summary ?? 'нет краткого содержания'
            : documentInfo?.Summary}
        </DFormItemTwo>
        <DFormItemTwo style={{ alignItems: 'baseLine' }} label="Файлы">
          <div>
            {currentDocument ? (
              currentFiles.length ? (
                currentFiles.map(item => (
                  <p className="mr5 mb0">{item.file?.name}</p>
                ))
              ) : (
                <span>Нет файлов</span>
              )
            ) : (
              documentInfo?.Files.map(item => (
                <p className="mr5 mb0">{item?.Name}</p>
              ))
            )}
          </div>
        </DFormItemTwo>
        {docType === 'Incoming' && (
          <>
            <DFormItemTwo label="Рег номер. и дата">
              {currentDocument ? (
                <span>
                  {currentDocument.RegNumber ?? 'б/н'}{' '}
                  {currentDocument.RegDate &&
                    `от ${format(
                      new Date(currentDocument.RegDate),
                      'dd.MM.yyyy',
                    )}`}
                </span>
              ) : (
                <span>
                  {documentInfo?.RegNumber ?? 'б/н'} от{' '}
                  {documentInfo?.RegDate &&
                    `от ${format(
                      new Date(documentInfo?.RegDate),
                      'dd.MM.yyyy',
                    )}`}
                </span>
              )}
            </DFormItemTwo>
          </>
        )}
        {docType === 'Outcoming' && (
          <DFormItemTwo label="Адресаты" className="addressee-form">
            {currentDocument?.SendingContexts?.length
              ? currentDocument?.SendingContexts?.map(item =>
                  item.AddresseeId ? (
                    <div className="addressee-form__item form">
                      {getFullName({
                        lastName: item?.AddresseeLastName,
                        middleName: item?.AddresseeMiddleName,
                        firstName: item?.AddresseeFirstName,
                      })}
                    </div>
                  ) : null,
                )
              : '-'}
          </DFormItemTwo>
        )}
      </form>
    </>
  );
};
