import Table from '@editorjs/table';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import Header from '@editorjs/header';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';

export const EDITOR_TOOLS = {
  table: Table,
  list: List,
  warning: Warning,
  header: Header,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
};
