import { prefix } from 'api/prefix';
import axios from 'axios';
import { errorThrow, TError } from 'shared/utils';
import { getAuthToken } from 'shared/utils/getAuthToken';
import { TDocumentFile } from 'types/document';

export const changeBarCodeFile = async (
  item: TDocumentFile,
  objectId: number,
  type: 'Document' | 'Assignment',
) => {
  const formData = new FormData();
  formData.append('ObjectId', String(objectId));
  formData.append('Id', String(item.Id));
  formData.append('ObjectType', type);

  try {
    await axios.put(`${prefix}/Files`, formData, {
      headers: {
        ...getAuthToken('auth_token'),
        'Content-Type': 'multipart/form-data; ',
      },
    });
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};
