import {
  ConfigProvider,
  Drawer,
  Empty,
  Select,
  SelectProps,
  Switch,
  Table,
  TablePaginationConfig,
} from 'antd';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { PhaseEstimation } from '../PhaseEstimation';
import { DrawerHeader } from './DrawerHeader';
import { SectionTypes } from 'types/sectionTypes';
import { TOrganiastion, TypesOrganisations } from 'types/typesOrganisations';
import { updatePhases } from 'store/slice/phases';
import {
  Phases,
  TPhaseContacts,
  TPHaseUserGroup,
} from 'pages/AssignmentCard/types';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { Icon } from 'shared/components/IconComponent';
import searchSvg from 'shared/assets/images/search.svg';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';

type TCatalogDrawer = {
  visible: boolean;
  onClose: () => void;
  type:
    | 'organisations'
    | 'contacts'
    | 'assignment'
    | 'document'
    | 'departments'
    | 'contactsAssignment'
    | 'nomenclatures';
  func: any;
  defaultList?: any;
  phase?: Phases;
  orgId?: number;
  isReceiver?: boolean;
  withRemoveTable?: boolean;
};

type TList = {
  Name: string;
  Id: number;
  FirstName: string;
  LastName: string;
  MiddleName: string;
};

enum TypesUrl {
  document = 'documents/byorganisation',
  contactsAssignment = 'contacts/active/page',
  contacts = 'contacts/active/page2',
  organisations = 'organisations/active/page',
  assignment = 'assignment/page',
  departments = 'departments/page',
  nomenclatures = 'nomenclatures/active/page',
  receiverGroup = 'organisationreceivergroups/byorganisation/page',
}

const DEFAULT_PAGE_SIZE = 10;

export const CatalogDrawer = ({
  visible,
  onClose,
  type,
  func,
  defaultList,
  phase,
  orgId,
  isReceiver,
  withRemoveTable = false,
}: TCatalogDrawer) => {
  const [list, setList] = useState<TList[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [filteredCount, setFilteredCount] = useState<number>(0);
  const [receiverSwitcher, setReceiverSwitcher] = useState(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { phases } = useSelector((state: ReduxState) => state.phaseReducer);
  const [organisations, setOrganisations] = useState<TOrganiastion[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<number>();
  const dispatch = useDispatch();
  const organisationId =
    type === 'nomenclatures' ? orgId : user?.CurrentContact?.OrganisationId;

  const isMode =
    phase?.Type === 'TaskDispatch' ||
    phase?.Type === 'TaskCheck' ||
    phase?.Type === 'TaskRegistration' ||
    phase?.Type === 'TaskStamp';

  const isAlreadyExist = (item: TList) =>
    defaultList?.find(
      (listItem: TPhaseContacts | TPHaseUserGroup) => listItem?.Id === item.Id,
    );

  const getDepartments = useCallback(
    (value?: string, page?: number) => {
      $api
        .get('departments/byorganisation/page', {
          params: {
            OrganisationId: organisationId,
            FastSearch: value,
            Page: page && page - 1,
            Limit: DEFAULT_PAGE_SIZE,
          },
        })
        .then(({ data }) => {
          const { Data, FilteredCount } = data;
          setList(Data);
          setFilteredCount(FilteredCount);
        })
        .catch(({ response }) => errorThrow(response));
    },
    [organisationId],
  );

  const getData = useCallback(
    async (query?: string, page?: number, orgId?: number) => {
      setIsLoading(true);
      if (query) {
        const paramsRequest = {
          FastSearch: query,
          Page: page && page - 1,
          Limit: DEFAULT_PAGE_SIZE,
          OrganisationId: orgId || organisationId,
        };

        const requestUrl = `${TypesUrl[type]}/${
          type === SectionTypes.Document ? orgId || organisationId : ''
        }`;

        const { data } = await $api.get(
          receiverSwitcher ? TypesUrl['receiverGroup'] : requestUrl,
          {
            params: paramsRequest,
          },
        );

        try {
          const { Data, FilteredCount } = data;

          setFilteredCount(FilteredCount);
          setList(Data);
        } catch ({ response }) {
          errorThrow(response);
        } finally {
          setIsLoading(false);
        }
      } else {
        setList([]);
        setIsLoading(false);
      }
    },
    [organisationId, receiverSwitcher, type],
  );

  const getAssignmentData = useCallback((value?: string, page?: number) => {
    setIsLoading(true);

    $api
      .get('contacts/active/page2', {
        params: {
          FastSearch: value,
          Page: page && page - 1,
          Limit: DEFAULT_PAGE_SIZE,
        },
      })
      .then(({ data: { Data, FilteredCount } }) => {
        setFilteredCount(FilteredCount);
        setList(Data);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => setIsLoading(false));
  }, []);

  const onSearch = useCallback(
    debounce(value => {
      setPage(1);
      if (!value.length) {
        setList([]);
        return;
      }
      if (type !== SectionTypes.Assignment)
        getData(value, 1, selectedOrganisation);
      else {
        if (!phase?.IsGroup) getAssignmentData(value);
        else getDepartments(value);
      }
    }, DEBOUNCE_TIME),
    [receiverSwitcher, type, phase?.IsGroup, selectedOrganisation],
  );

  const onFinish = (item: any) => {
    if (type !== SectionTypes.Assignment) {
      func(receiverSwitcher ? { ...item, receiverGroup: true } : item);
      if (!withRemoveTable) onClose();
    } else {
      func(item, 'add');
    }
  };

  const getName = () => {
    switch (type) {
      case 'contacts':
        return 'ФИО';

      case 'assignment':
        return 'ФИО';

      case 'organisations':
        return 'Наименование';

      case 'nomenclatures':
        return 'Наименование';

      default:
        return 'Имя';
    }
  };

  const assignmentColunmns = [
    {
      title: 'Номер',
      dataIndex: phase?.IsGroup ? 'Id' : 'ContactId',
      key: phase?.IsGroup ? 'Id' : 'ContactId',
    },
    {
      title: 'ФИО',
      dataIndex: 'Name',
      key: 'Name',
      render: (text: string, item: TList) =>
        !phase?.IsGroup ? (
          <span>
            {item.LastName} {item.FirstName} {item.MiddleName}
          </span>
        ) : (
          <span>{text}</span>
        ),
    },
    {
      title: 'Должность',
      dataIndex: 'PositionName',
      key: 'PositionName',
    },
    {
      title: 'Действия',
      dataIndex: 'Action',
      key: 'Action',
      render: (text: string, item: TList) => (
        <DButton small defaultDanger onClick={() => func(item, 'remove')}>
          Удалить
        </DButton>
      ),
    },
  ];

  const getColumns = (removeAction: boolean) => {
    const removeCol = {
      title: 'Действия',
      dataIndex: 'Action',
      key: 'Action',
      render: (text: string, item: TList) => (
        <DButton small defaultDanger onClick={() => func(item, 'remove')}>
          Удалить
        </DButton>
      ),
    };

    const selectCol = {
      title: 'Действия',
      dataIndex: 'Action',
      key: 'Action',
      render: (text: string, item: TList) => (
        <DButton
          disabled={(isMode && !!defaultList?.length) || !!isAlreadyExist(item)}
          small
          defaultPrimary
          onClick={() => onFinish(item)}
        >
          Выбрать
        </DButton>
      ),
    };

    const columns = [
      {
        ...(type !== SectionTypes.Nomenclatures
          ? { title: 'Номер', dataIndex: 'Id', key: 'Id' }
          : {}),
      },
      {
        ...(type === SectionTypes.Nomenclatures
          ? {
              title: 'Код',
              dataIndex: 'Code',
              key: 'Code',
            }
          : {}),
      },
      {
        ...(type === SectionTypes.Nomenclatures
          ? {
              title: 'Наименование',
              dataIndex: 'Name',
              key: 'Name',
            }
          : {}),
      },
      {
        ...(type !== SectionTypes.Document &&
        type !== SectionTypes.Nomenclatures
          ? {
              title: getName(),
              dataIndex: 'Name',
              key: 'Name',
              render: (text: string, item: TList) =>
                type === SectionTypes.Organisations ||
                type === SectionTypes.Departments ||
                (type === SectionTypes.Assignment && phase?.IsGroup)
                  ? text
                  : `${item.LastName} ${item.FirstName} ${item.MiddleName}`,
            }
          : {
              title: 'Тема',
              dataIndex: 'Subject',
              key: 'Subject',
            }),
      },
      type === SectionTypes.Contacts || type === SectionTypes.Assignment
        ? {
            title: 'Должность',
            dataIndex: 'PositionName',
            key: 'PositionName',
          }
        : {},
      type === SectionTypes.Contacts ||
      type === SectionTypes.Assignment ||
      type === SectionTypes.ContactAssignment
        ? {
            title: 'Организация',
            dataIndex: 'OrganisationName',
            key: 'OrganisationName',
          }
        : {},
      type === SectionTypes.Organisations
        ? {
            title: 'Тип',
            dataIndex: 'Type',
            key: 'Type',
            render: (text: keyof typeof TypesOrganisations) =>
              TypesOrganisations[text],
          }
        : {},
      {
        ...(type === SectionTypes.Document
          ? {
              title: 'Рег. номер и дата',
              dataIndex: 'RegDate',
              key: 'RegDate',
              render: (date: string, item: any) => (
                <span>
                  {item?.RegNumber ? `${item?.RegNumber} от` : ''}{' '}
                  {`${date ? format(new Date(date), 'dd.MM.yyyy') : '-'}`}
                </span>
              ),
            }
          : {}),
      },
      removeAction ? removeCol : selectCol,
    ].filter(item => item?.title);

    return columns;
  };

  const onChangeEstimation = (hours: number) => {
    const newArr = phases.map(item =>
      item.Id === phase?.Id ? { ...item, Estimation: hours } : item,
    );
    dispatch(updatePhases(newArr));
  };

  const onChangePagination = (currentPage: number) => {
    if (type !== SectionTypes.Assignment)
      getData(searchValue, currentPage, selectedOrganisation);
    else {
      if (!phase?.IsGroup) getAssignmentData(searchValue, currentPage);
      else getDepartments(searchValue, currentPage);
    }
    setPage(currentPage);
  };

  const paramsPagination: TablePaginationConfig = {
    current: page,
    total: filteredCount,
    pageSize: DEFAULT_PAGE_SIZE,
    onChange: onChangePagination,
    showTotal: () => filteredCount,
    hideOnSinglePage: false,
    showSizeChanger: false,
  };

  useEffect(() => {
    $api
      .get('/organisations/byuser')
      .then(res => {
        setOrganisations(res.data);
        setSelectedOrganisation(user?.CurrentContact?.OrganisationId);
      })
      .catch(({ response }) => errorThrow(response));
  }, []);

  const organisationOptions: SelectProps['options'] = organisations.map(
    org => ({
      value: org.Id,
      label: org.Name,
    }),
  );

  const onChangeAllowOrganisations = (value: number) => {
    setSelectedOrganisation(value);
    setPage(1);
    getData(searchValue, 1, value);
  };

  // useEffect(() => {
  //   if (receiverSwitcher) {
  //     getData('test');
  //   } else {
  //     setList([]);
  //   }
  // }, [getData, receiverSwitcher]);

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Справочник" />
        {withRemoveTable && (
          <Table
            loading={isLoading}
            dataSource={defaultList}
            columns={getColumns(true)}
            pagination={{
              position: ['bottomRight'],
              defaultCurrent: 1,
              defaultPageSize: 5,
              hideOnSinglePage: false,
              showSizeChanger: false,
            }}
          />
        )}
        {type === 'assignment' && (
          <>
            <div className="flex-space">
              <div className="w100per" />
              <DFormItemTwo label="Дата" name="Estimation">
                <PhaseEstimation
                  estimationHours={
                    phases?.find(item => item?.Id === phase?.Id)?.Estimation
                  }
                  onChange={onChangeEstimation}
                />
              </DFormItemTwo>
            </div>
            <Table
              loading={isLoading}
              dataSource={defaultList}
              columns={assignmentColunmns}
              pagination={{
                position: ['bottomRight'],
                defaultCurrent: 1,
                defaultPageSize: 5,
                hideOnSinglePage: false,
                showSizeChanger: false,
              }}
            />
          </>
        )}
        {type === 'document' && (
          <div className="catalog__select-box mb15">
            <Select
              className="ui-select"
              placeholder="Искать в доступных организациях"
              value={selectedOrganisation}
              onChange={onChangeAllowOrganisations}
              options={organisationOptions}
            />
          </div>
        )}
        <div className="catalog__search-box mb15 mt15">
          {isReceiver ? (
            <div>
              <span className="mr5">Организации</span>
              <Switch
                onChange={() => {
                  setReceiverSwitcher(!receiverSwitcher);
                  getData();
                }}
              />
              <span className="ml5">Группы</span>
            </div>
          ) : (
            <div />
          )}
          <DInput
            style={{ maxWidth: 630, width: '100%' }}
            icon={<Icon icon={searchSvg} alt="searchSvg" />}
            type="search"
            placeholder="Поиск"
            onChange={({ target: { value } }) => {
              checkLengthStringForSearch(value, onSearch);
              setSearchValue(value);
            }}
          />
        </div>

        <ConfigProvider
          renderEmpty={() => <Empty description="Данные не найдены" />}
        >
          <Table
            loading={isLoading}
            dataSource={list}
            columns={getColumns(false)}
            pagination={list.length ? paramsPagination : false}
          />
        </ConfigProvider>
      </div>
    </Drawer>
  );
};
