import { Button, Form, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useBreadcrumb } from 'shared/hooks/useBreadcrumb';
import { options } from 'mock/Option';
import './SortPage.scss';

export const SortPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { closeBreadcrumbs } = useBreadcrumb();
  const { Option } = Select;

  const onFinish = (values: any) => {
    console.log(values);
  };

  const sortType = [
    {
      id: 1,
      title: 'По возрастанию',
    },
    {
      id: 2,
      title: 'По убыванию',
    },
  ];

  return (
    <Form
      name="basic"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label="Сортировать элементы по полю"
        name="1"
        className="sort_input"
      >
        <Select className="mr15">
          {options.map(option => (
            <Option key={option.id} value={option.id}>
              {option.title}
            </Option>
          ))}
        </Select>
        <Select className="ml5" defaultValue={['По возрастанию']}>
          {sortType.map(type => (
            <Option key={type.id} value={type.id}>
              {type.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Затем по полю" name="2" className="sort_input">
        <Select className="mr15">
          {options.map(option => (
            <Option key={option.id} value={option.id}>
              {option.title}
            </Option>
          ))}
        </Select>
        <Select className="ml5" defaultValue={['По возрастанию']}>
          {sortType.map(type => (
            <Option key={type.id} value={type.id}>
              {type.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Затем по полю" name="3" className="sort_input">
        <Select className="mr15">
          {options.map(option => (
            <Option key={option.id} value={option.id}>
              {option.title}
            </Option>
          ))}
        </Select>
        <Select className="ml5" defaultValue={['По возрастанию']}>
          {sortType.map(type => (
            <Option key={type.id} value={type.id}>
              {type.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Затем по полю" name="4" className="sort_input">
        <Select className="mr15">
          {options.map(option => (
            <Option key={option.id} value={option.id}>
              {option.title}
            </Option>
          ))}
        </Select>
        <Select className="ml5" defaultValue={['По возрастанию']}>
          {sortType.map(type => (
            <Option key={type.id} value={type.id}>
              {type.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className="form-buttons">
        <Button type="primary" htmlType="submit" className="mr15 button">
          Сортировка
        </Button>
        <Button
          danger
          className="mr15"
          onClick={() => {
            history.goBack();
            closeBreadcrumbs(dispatch, history.location.pathname);
          }}
        >
          Закрыть
        </Button>
      </Form.Item>
    </Form>
  );
};
