import { AssignmentType } from 'types/document';

export type AssignmentTypes = {
  DocumentId: number;
  Assignments: AssignmentType[];
  DocumentSubject: string;
};

export type PaginationTypes = {
  page: number;
  pageSize: number;
};

export enum AssignmentTag {
  IsDelegated = 'Делегировано',
  IsConsolidated = 'Сводное',
}
