import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { TDates, updateCalendar } from 'store/slice/calendar';
import { AppDispatch } from 'store/store';

export const fetchCalendarData = ({
  dispatch,
  year,
}: {
  dispatch: AppDispatch;
  year?: number;
}) => {
  $api
    .get(`Calendar/byyear/${year ?? new Date().getFullYear()}`)
    .then(res => {
      const arr: TDates[] = [];
      const MONTHS = res.data.Months;

      for (let index = 0; index < MONTHS.length; index++) {
        const currentMonth = MONTHS[index];

        for (let j = 0; j < currentMonth.Days.length; j++) {
          const t = currentMonth.Days[j];
          const day = t.Number < 10 ? `0${t.Number}` : t.Number;
          const month = index < 9 ? `0${index + 1}` : index + 1;
          const year = res.data.Number;
          const fullDate = `${day}.${month}.${year}`;

          if (t.Type === 'Weekend' || t.Type === 'Holiday') {
            arr.push({ date: fullDate, holiday: true });
          } else {
            arr.push({ date: fullDate, holiday: false });
          }
        }
      }

      dispatch(updateCalendar(arr));
    })
    .catch(({ response }) => errorThrow(response));
};
