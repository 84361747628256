export enum PermissionsAccess {
  AdminSso = 'admin.sso',
  AssignmentExtContacts = 'assignment.ext.contacts',
  DocumentIncoming = 'document.incoming',
  DocumentCreate = 'document.create',
  DocumentDelo = 'document.delo',
  UserSubstitution = 'user.substitution',
  AdminOrganization = 'admin.organisation',
  Control = 'control',
  DocumentInner = 'document.inner',
  DocumentRegistration = 'document.registration',
  DocumentOutcoming = 'document.outcoming',
  Chief = 'chief',
  Admin = 'admin',
  Report = 'report',
  StreamsByOrganisation = 'document.create.ext.organisation',
}
