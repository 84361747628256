import { YearItem } from './components/YearItem';
import { getDecades, getDecadeTitle } from './utils';
import './Archive.scss';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton } from 'shared/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { ArchiveCaseDrawer } from './components/ArchiveCaseDrawer';
import { fetchTomeList } from 'api';
import { ArchiveDrawerType, DecadeArray } from './type';

export const Archive = () => {
  const [visible, setVisible] = useState(false);
  const [drawerType, setDrawerType] = useState<ArchiveDrawerType>('single');
  const [years, setYears] = useState<number[]>([]);

  const getTomeList = useCallback(() => {
    fetchTomeList().then(list => setYears(list));
  }, []);

  useEffect(() => {
    getTomeList();
  }, [getTomeList]);

  return (
    <>
      {visible && (
        <ArchiveCaseDrawer
          visible={visible}
          drawerType={drawerType}
          onClose={() => setVisible(false)}
          onUpdateData={getTomeList}
          type="create"
        />
      )}
      <PageHeader title="Архив" code="ARCHIVE">
        <DButton
          primary
          small
          className="mr15"
          onClick={() => {
            setVisible(true);
            setDrawerType('single');
          }}
        >
          Создать дело
        </DButton>
        <DButton
          primary
          small
          onClick={() => {
            setVisible(true);
            setDrawerType('multi');
          }}
        >
          Создать дела для тома
        </DButton>
      </PageHeader>
      <div>
        {!!years?.length &&
          getDecades(years)?.map(decadeWithYears => {
            return (
              <div className="decade-wrapper">
                <h2>{getDecadeTitle(decadeWithYears as DecadeArray[])}</h2>
                <div className="years-wrapper">
                  {(decadeWithYears[1] as number[]).map((year: any) => (
                    <YearItem year={year} />
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
