import { DButton } from 'shared/ui-kit';
import { TCatalogButton } from './types';

export const CatalogButton = ({ onClick, disabled }: TCatalogButton) => {
  return (
    <DButton
      disabled={disabled}
      onClick={onClick}
      small
      className="button-p form-item-button"
    >
      ...
    </DButton>
  );
};
