import { Dropdown, Form, Button, Menu, Input, Upload } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  DownOutlined,
  InfoOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React from 'react';

export const ExecutionAssignmentsPage = () => {
  const history = useHistory();
  const link = '/execution-assignment';

  //TODO: ждать api
  const onFinish = (values: any) => {
    console.log(values);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">Запросить уточнение</Menu.Item>
      <Menu.Item key="2" onClick={() => history.push(`${link}/delegate`)}>
        Делегировать исполнение
      </Menu.Item>
      <Menu.Item key="3" onClick={() => history.push(`${link}/refund`)}>
        Вернуть информацию без исполнения
      </Menu.Item>
      <Menu.Item key="4" onClick={() => history.push(`${link}/information`)}>
        Информация
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <h3>Отчёт об исполнении задачи</h3>
      <Form
        name="basic"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
        className="form"
      >
        <div className="header">
          <div>
            <span>Тест</span>
            <br />
            <span>
              От кого: <Link to="/">Земцов Д.Н.</Link>
            </span>
            <span> Срок: 29.03.2021 17:30</span>
          </div>
          <Dropdown overlay={menu}>
            <Button>
              Дополнительные операции <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <div className="form-inner">
          <div className="within">
            <h3>Лист задач (в рамках):</h3>
            <Button>Создать новое поручение в рамках</Button>
            <Dropdown overlay={menu}>
              {/*TODO: другой оверлей*/}
              <Button>
                Создать поручение по шаблону <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className="following mt20">
            <h3>Лист поручений (по итогам):</h3>
            <Button>Создать новое поручение по итогам</Button>
            <Dropdown overlay={menu}>
              {/*TODO: другой оверлей*/}
              <Button>
                Создать поручение по шаблону <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div className="mt20">
            <h3>Текст ответа при завершении исполнения</h3>
            <div className="form-inner ">
              <Button className="add-from">Добавить из поручений</Button>
              <Form.Item label="Текст резолюции" name="Resolution">
                <Input.TextArea />
              </Form.Item>
              <Form.Item className="uploading" label="Файлы" name="Files">
                <Upload
                  className="mr15"
                  name="logo"
                  action="/upload.do"
                  listType="text"
                >
                  <Button icon={<UploadOutlined />}>Добавить</Button>
                </Upload>
                <span>Разрешено к загрузке: 50 000 КБ</span>
              </Form.Item>
              {/*TODO: вернуть когда апи будет*/}
              {/*<Form.Item label="Документы">*/}
              {/*  <Button className="mr15">Добавить</Button>*/}
              {/*  <Button>Создать документ</Button>*/}
              {/*</Form.Item>*/}
            </div>
          </div>
        </div>
        <div className="form-buttons mt20 flex-space">
          <div>
            <Button className="button mr15" htmlType="submit">
              Завершить исполнение
            </Button>
            <Button className="button mr15">Сохранить</Button>
            <Button className="button mr15">Выдать задачу "в рамках"</Button>
            <Button danger onClick={() => history.goBack()}>
              Закрыть
            </Button>
          </div>
          <div>
            <span className="mr15">
              <InfoOutlined /> Помощь
            </span>
            <span>
              <SettingOutlined /> Настройка
            </span>
          </div>
        </div>
      </Form>
    </>
  );
};
