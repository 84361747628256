import { Table } from 'antd';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton } from 'shared/ui-kit';
import { DocumentRegistrationDrawer } from './DocumentRegistrationDrawer';
import { useDocumentRegistration } from './useDocumentRegistration';
import { DOCUMENT_REGISTERS } from 'shared/constants/helpCodes';

export const DocumentRegistration = () => {
  const {
    isLoading,
    columns,
    regNumberList,
    onCreate,
    selectedItem,
    visible,
    onClose,
    drawerType,
  } = useDocumentRegistration();
  return (
    <>
      {visible && (
        <DocumentRegistrationDrawer
          item={selectedItem}
          onClose={onClose}
          visible={visible}
          type={drawerType}
        />
      )}
      <PageHeader title="Регистрация документа" code={DOCUMENT_REGISTERS} />
      <div className="flex-space mb15">
        <DButton onClick={onCreate} small primary>
          + Создать
        </DButton>
      </div>
      <Table
        loading={isLoading}
        dataSource={regNumberList}
        columns={columns}
        pagination={{
          showSizeChanger: false,
        }}
      />
    </>
  );
};
