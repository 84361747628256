export enum SectionTypes {
  Organisations = 'organisations',
  Assignment = 'assignment',
  Departments = 'departments',
  Document = 'document',
  Contacts = 'contacts',
  Nomenclatures = 'nomenclatures',
  ContactAssignment = 'contactsAssignment',
}

export enum DrawerTypes {
  Create = 'create',
  Edit = 'edit',
}
