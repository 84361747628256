import { Drawer, Form, Checkbox, Select, notification } from 'antd';
import { DrawerHeader } from './DrawerHeader';
import { DButton, DInput } from 'shared/ui-kit';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { format } from 'date-fns';
import { DocumentEditDrawer } from './DocumentEditDrawer';
import {
  updateASampleDocument,
  updateInfoByLinkishDocument,
} from 'store/slice/document';
import { $api } from 'shared/utils/api';
import { errorThrow } from 'shared/utils';
import { useForm } from 'antd/es/form/Form';
import login_error from 'shared/assets/images/login-error.png';
import { updateDocumentKindId } from '../../../store/slice/table';
import { TDocumentFile } from 'types/document';
import { useHistory } from 'react-router-dom';

type TASampleDocumentDrawer = {
  onClose: (bool: boolean) => void;
  visible: boolean;
};

export type TDocumentKind = {
  Id: number;
  DocumentKindId: number;
  DocumentType: string;
  NomenclatureId: number;
  FlowId: number;
  MaskRegNumber: string;
  OrganisationId: number;
  DocumentKindName: string;
  NomenclatureName: string;
  FlowName: string;
};

const documentTypes = [
  {
    type: 'Inner',
    name: 'Внутренние',
  },
  {
    type: 'Incoming',
    name: 'Входящие',
  },
  {
    type: 'Outcoming',
    name: 'Исходящие',
  },
];

const options = [
  {
    value: 'Subject',
    label: 'Тему',
  },
  {
    value: 'Summary',
    label: 'Краткое содержание',
  },
  {
    value: 'Correspondent',
    label: 'Корреспондента',
  },
  {
    value: 'Addressee',
    label: 'Адресата',
  },
  {
    value: 'SendType',
    label: 'Вид доставки',
  },
  {
    value: 'Files',
    label: 'Вложения, файлы',
  },
];

export type TSampleDocument = {
  Subject?: string;
  Summary?: string;
  Correspondent?: number;
  Addressee?: number;
  SendType?: number;
  DocumentType: string;
  DocumentKindId: number;
  DocumentStreamId?: number;
  Files?: TDocumentFile[];
  isCreated?: boolean;
};

export const ASampleDocumentDrawer = ({
  onClose,
  visible,
}: TASampleDocumentDrawer) => {
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [visibleEditDrawer, setVisibleEditDrawer] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [documentKindList, setDocumentKindList] = useState<TDocumentKind[]>([]);
  const [documentStream, setDocumentStream] = useState<TDocumentKind>();
  const { documentInfo, linkishId } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { Option } = Select;
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const [form] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const drawerTitle = !linkishId
    ? 'Создание документа по образцу'
    : 'Создание связанного документа';

  const onCloseEditDrawer = (bool: boolean) => {
    setVisibleEditDrawer(bool);
  };

  const getDocumentKinds = useCallback(
    type => {
      form.setFieldsValue({ DocumentKindId: undefined });
      $api
        .get(`DocumentStreams/filter`, {
          params: {
            OrganisationId: user?.CurrentContact?.OrganisationId,
            DocumentType: type,
            ForSelectedOrg: false,
          },
        })
        .then(res => {
          setDocumentKindList(res.data.Data);
        })
        .catch(({ response }) => errorThrow(response));
    },
    [form, user?.CurrentContact?.OrganisationId],
  );

  const getDocumentTypeName = () => {
    return documentTypes.find(item => item.type === documentInfo?.Type)?.name;
  };

  const copyText = `${getDocumentTypeName() ?? ''} №${
    documentInfo?.Id
    // @ts-ignore
  } от ${
    documentInfo?.CreateDate
      ? format(new Date(documentInfo?.CreateDate), 'dd.MM.yyyy')
      : '-'
  } "${documentInfo?.Subject}"`;

  const onFinish = (values: {
    DocumentKindId: number;
    DocumentType: string;
  }) => {
    const initialValues: TSampleDocument = {} as TSampleDocument;
    if (!values.DocumentType) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо выбрать тип документа',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
      return;
    }
    if (values.DocumentType && !values.DocumentKindId) {
      setShowError(true);
      notification['error']({
        message: 'Произошла ошибка',
        description: 'Необходимо выбрать вид документа',
        className: 'custom-notification__warning',
        icon: <img src={login_error} alt="error" />,
      });
      return;
    }
    if (selectedOptions.includes('Subject')) {
      initialValues.Subject = documentInfo?.Subject;
    }
    if (selectedOptions.includes('Summary')) {
      initialValues.Summary = documentInfo?.Summary;
    }
    if (selectedOptions.includes('Correspondent')) {
      initialValues.Correspondent =
        documentInfo?.SendingContexts?.[0]?.CorrespondentId;
    }
    if (selectedOptions.includes('Addressee')) {
      initialValues.Addressee = documentInfo?.SendingContexts[0]?.AddresseeId;
    }
    if (selectedOptions.includes('SendType')) {
      initialValues.SendType = documentInfo?.SendingContexts?.[0]?.SendTypeId;
    }
    if (selectedOptions.includes('Files')) {
      initialValues.Files = documentInfo?.Files;
    }
    initialValues.DocumentType = values.DocumentType;
    initialValues.DocumentKindId = values.DocumentKindId;
    initialValues.DocumentStreamId = documentStream?.Id;
    initialValues.isCreated = false;
    dispatch(updateASampleDocument(initialValues));
    const currentDocument = documentKindList.find(
      kindItem => kindItem.DocumentKindId === values.DocumentKindId,
    );
    if (currentDocument) {
      dispatch(
        updateDocumentKindId({
          Name: currentDocument.DocumentKindName,
          Id: currentDocument.DocumentKindId,
          FlowId: currentDocument.FlowId,
          FlowName: currentDocument.FlowName,
          DocumentStreamId: currentDocument.Id,
          OrganisationId: user?.CurrentContact?.OrganisationId,
          OrganisationName: user?.CurrentContact?.OrganisationName,
        }),
      );
    }
    if (linkishId) {
      // dispatch(updateDocumentInfo(null));
      history.push(`/create/${values.DocumentType}`);
    }
    setVisibleEditDrawer(true);
  };

  return (
    <>
      {visibleEditDrawer && (
        <DocumentEditDrawer
          visible={visibleEditDrawer}
          onClose={onCloseEditDrawer}
        />
      )}
      <Drawer
        placement="right"
        onClose={() => {
          onClose(false);
          dispatch(updateASampleDocument());
          dispatch(updateInfoByLinkishDocument(null));
        }}
        visible={visible}
        closeIcon={null}
        className="drawer"
      >
        <div className="page3">
          <DrawerHeader
            onClose={onClose}
            text={drawerTitle}
            code="CREATE_SAMPLE_DOCUMENT"
          />
          <Form
            form={form}
            name="sample"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
            className="drawer-form password-form"
            onFinish={onFinish}
          >
            <Form.Item required label="Тип документа" name="DocumentType">
              <Select
                className={
                  showError && !form.getFieldValue('DocumentType')
                    ? 'ui-select-danger'
                    : 'ui-select'
                }
                showSearch
                optionFilterProp="children"
                // @ts-ignore
                onSelect={type => getDocumentKinds(type)}
              >
                {documentTypes.map(item => (
                  <Option value={item.type}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item required label="Вид документа" name="DocumentKindId">
              <Select
                disabled={!documentKindList.length}
                className={
                  showError && !form.getFieldValue('DocumentKindId')
                    ? 'ui-select-danger'
                    : 'ui-select'
                }
                showSearch
                optionFilterProp="children"
                onSelect={(id: number) => {
                  const currentItem = documentKindList.find(
                    item => item.DocumentKindId === id,
                  );
                  setDocumentStream(currentItem);
                }}
              >
                {documentKindList.map(item => (
                  <Option value={item.DocumentKindId}>
                    {item.DocumentKindName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="item"
              label="Копировать из документа"
              name="copy"
            >
              <>
                <DInput width="100%" disabled type="text" value={copyText} />
              </>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Checkbox.Group
                style={{ display: 'grid' }}
                onChange={value => setSelectedOptions(value)}
              >
                {options.map(item => (
                  <Checkbox value={item.value}>{item.label}</Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <div style={{ display: 'flex' }}>
                <DButton small primary type="submit" className="mr15">
                  Сохранить
                </DButton>
                <DButton
                  small
                  defaultDanger
                  onClick={() => {
                    onClose(false);
                    dispatch(updateASampleDocument());
                    dispatch(updateInfoByLinkishDocument(null));
                  }}
                >
                  Закрыть
                </DButton>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </>
  );
};
