import { ContactList } from 'pages/CreateDocument/types';

export type DocumentReminderProps = {
  canEdit?: boolean;
};

export enum ControlStatuses {
  Unknown = 'Unknown',
  OutControl = 'OutControl',
  InControl = 'InControl',
  NotRequired = 'NotRequired',
}

export type RemindList = {
  Id: number;
  CreateDate: string;
  UpdateDate: string;
  ControlId: number;
  DocumentId: number;
  ExecutorIds: number[];
  Executors: ContactList[];
  IsExecuted: boolean;
  ExecutionDate: string;
  ExecutorLastName: string;
  ExecutorMiddleName: string;
  ExecutorFirstName: string;
  Summary: string;
  ControlDate: string;
};
