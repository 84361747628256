import { Drawer } from 'antd';
import { useFormik } from 'formik';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DrawerHeader } from './DrawerHeader';

type TCreateProcessDrawer = {
  visible: boolean;
  onClose: () => void;
  getData: () => void;
};

export const CreateProcessDrawer = ({
  visible,
  onClose,
  getData,
}: TCreateProcessDrawer) => {
  const initialValues = {
    Name: '',
  };

  const onFinish = (values: { Name: string }) => {
    $api
      .post('flows/byorganization', {
        Name: values.Name,
      })
      .then(() => {
        getData();
        onClose();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      className="drawer"
      closeIcon={null}
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Создание процесса" />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Имя" name="Name">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Name}
              name="Name"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              Создать
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
