import { TTreeData } from './types';

export const DEFAULT_DATA: TTreeData[] = [
  {
    title: 'Внутренние',
    type: 'Inner',
    Id: 1,
    key: 1,
    children: [],
  },
  {
    title: 'Исходящие',
    type: 'Outcoming',
    Id: 3,
    key: 3,
    children: [],
  },
  {
    title: 'Входящие',
    type: 'Incoming',
    Id: 2,
    key: 2,
    children: [],
  },
];

export const DEFAULT_COUNT = 10;

export const MAX_TITLE_LENGTH = 50;
