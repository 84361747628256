import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReduxState } from 'store/store';
import { Folder } from 'types/folder';

type TAtomSidebar = {
  item: Folder;
  onClickItem: (item: Folder) => void;
};

const AtomSidebar = ({ item, onClickItem }: TAtomSidebar) => {
  const location = useLocation();

  const { activeCount } = useSelector(
    (state: ReduxState) => state.sidebarReducer,
  );
  useEffect(() => {
    if (location.pathname?.includes('active-documents')) {
      sessionStorage.setItem('uniqNumberAtom', '1');
      sessionStorage.setItem('uniqItem', '0');
    }

    if (location.pathname?.includes('searching/'))
      sessionStorage.setItem('uniqNumberAtom', '21');
  }, []);

  return (
    <div
      key={item.Id}
      onClick={() => onClickItem(item)}
      className={
        sessionStorage.getItem('uniqNumberAtom') === String(item.Id)
          ? 'sidebar__topItemActive'
          : 'sidebar__topItem'
      }
    >
      {item.link === 'control-documents' ? (
        <>
          <strong style={{ color: 'red' }}>!!!</strong> {item.Name}
        </>
      ) : item.link === 'my-control' ? (
        <>
          {item.Name}
          <div className="divider-sidebar"></div>
        </>
      ) : (
        item.Name
      )}
      {/* {item.link?.includes('active-documents') ? (
        <span className="ml5">({activeCount?.CountActiveDocuments})</span>
      ) : item.link === 'assignments' ? (
        <span className="ml5">({activeCount?.CountActiveAssignments})</span>
      ) : null} */}
    </div>
  );
};

export { AtomSidebar };
