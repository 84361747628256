import { Select, Skeleton } from 'antd';
import { useFormik } from 'formik';
import { DeliveryParams } from 'pages/CreateDocument/types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  SendTypes,
  TOrganisationsList,
} from 'shared/components/CustomDrawers/FilterDrawer/types';
import { OrganisationAddressDrawer } from 'shared/components/CustomDrawers/OrganisationAddressDrawer';
import { PageHeader } from 'shared/components/PageHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { REQUISITES } from 'shared/constants/helpCodes';

export const Requisites = () => {
  const [organisation, setOrganisation] = useState<TOrganisationsList>();
  const [sendTypes, setSendTypes] = useState<DeliveryParams[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { Option } = Select;
  const onClose = () => setVisible(false);
  const initialValues = {
    Name: organisation?.Name,
    Email: organisation?.Email,
    Address: organisation?.Address,
    SendTypeId: organisation?.SendTypes?.map(item => item?.SendTypeId),
    PhoneNumber: organisation?.PhoneNumber,
    Ogrn: organisation?.Ogrn ?? '',
    Inn: organisation?.Inn ?? '',
    Kpp: organisation?.Kpp ?? '',
    IfnsCode: organisation?.IfnsCode ?? '',
    IedoCode: organisation?.IedoCode ?? '',
  };

  const getSendTypes = useCallback(() => {
    $api
      .get('SendTypes')
      .then(res => setSendTypes(res.data))
      .catch(({ response }) => errorThrow(response));
  }, []);

  const getData = useCallback(() => {
    if (user?.CurrentContact?.OrganisationId) {
      setIsLoading(true);
      $api
        .get(`Organisations/${user?.CurrentContact?.OrganisationId}`)
        .then(res => {
          setOrganisation(res.data);
          for (let value in res.data) {
            formik.setFieldValue(value, res.data[value] ?? '');
          }
          formik.setFieldValue(
            'SendTypeId',
            res.data?.SendTypes?.map((item: SendTypes) => item?.SendTypeId),
          );
        })
        .catch(({ response }) => errorThrow(response))
        .finally(() => setIsLoading(false));
    }
  }, [user?.CurrentContact?.OrganisationId]);

  useEffect(() => {
    getData();
    getSendTypes();
  }, [getData, getSendTypes]);

  const onFinish = (values: any) => {
    $api
      .put(`Organisations/${organisation?.Id}`, {
        ...organisation,
        Id: organisation?.Id,
        Name: values?.Name,
        Email: values?.Email,
        Address: values?.Address,
        SendTypeIds: values?.SendTypeId,
        PhoneNumber: values?.PhoneNumber,
        Ogrn: values?.Ogrn,
        Inn: values?.Inn,
        Kpp: values?.Kpp,
        IfnsCode: values?.IfnsCode,
        IedoCode: values?.IedoCode,
      })
      .then(() => getData())
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <>
      {visible && (
        <OrganisationAddressDrawer
          visible={visible}
          onClose={onClose}
          item={organisation}
          getData={getData}
        />
      )}
      <PageHeader title="Реквизиты" code={REQUISITES} />
      {isLoading ? (
        <Skeleton />
      ) : (
        <form className="form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Название" name="Name">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Name}
              name="Name"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="ИНН" name="Inn">
            <DInput
              width="100%"
              type="text"
              value={String(formik.values.Inn)}
              name="Inn"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="КПП" name="Kpp">
            <DInput
              width="100%"
              type="text"
              value={String(formik.values.Kpp)}
              name="Kpp"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="ОГРН" name="Ogrn">
            <DInput
              width="100%"
              type="text"
              value={String(formik.values.Ogrn)}
              name="Ogrn"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="ИФНС код" name="IfnsCode">
            <DInput
              width="100%"
              type="text"
              value={String(formik.values.IfnsCode)}
              name="IfnsCode"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Юридический адрес" name="Address">
            <span>{organisation?.Address}</span>
          </DFormItemTwo>
          <DFormItemTwo>
            <span className="custom-link" onClick={() => setVisible(true)}>
              редактировать
            </span>
          </DFormItemTwo>
          <h3>Контакты</h3>
          <DFormItemTwo label="Почта" name="Email">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Email}
              name="Email"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Телефон" name="PhoneNumber">
            <DInput
              width="100%"
              type="text"
              value={formik.values.PhoneNumber}
              name="PhoneNumber"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Доставка" name="SendTypeId">
            <Select
              value={formik.values.SendTypeId}
              className="ui-select"
              allowClear
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('SendTypeId', value)}
            >
              {sendTypes?.map(item => (
                <Option value={item.Id}>{item.Name}</Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="ЭДО код" name="IedoCode">
            <span>{organisation?.IedoCode}</span>
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              Сохранить
            </DButton>
          </DFormItemTwo>
        </form>
      )}
    </>
  );
};
