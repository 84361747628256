import { Form, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { DButton, DInput } from 'shared/ui-kit';
import { TemplateModalProps } from '../types';

export const TemplateModal = ({
  visible,
  onClose,
  createTemplate,
}: TemplateModalProps) => {
  const [form] = useForm();

  return (
    <Modal
      title="Создание шаблона задачи"
      centered
      visible={visible}
      onCancel={onClose}
      footer={[
        <DButton
          primary
          small
          className="mr15"
          type="submit"
          onClick={() => createTemplate(form.getFieldValue('Name'))}
        >
          Подтвердить
        </DButton>,
        <DButton small defaultDanger onClick={onClose}>
          Отмена
        </DButton>,
      ]}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 10 }}
        autoComplete="off"
        onFinish={() => createTemplate(form.getFieldValue('Name'))}
      >
        <Form.Item label="Название" name="Name">
          <DInput type="text" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
