import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { useDocumentTags } from './useDocumentTags';
import { DetachTagModal } from './components/DetachTagModal';
import { CreateTagDrawer } from './components/TagDrawer';

export const DocumentTag = () => {
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );

  const {
    detachTag,
    onSelectTag,
    visibleDrawer,
    visibleModal,
    onChangeModalVisible,
    onChangeDrawerVisible,
    updateSelectedTag,
  } = useDocumentTags();

  return (
    <article className="document-tags mb15">
      <DetachTagModal
        visible={visibleModal}
        detachTag={detachTag}
        onChangeVisible={onChangeModalVisible}
      />
      {visibleDrawer && (
        <CreateTagDrawer
          visible={visibleDrawer}
          onClose={onChangeDrawerVisible}
        />
      )}
      <div className="document-tag-list">
        {documentInfo?.Tags.map(item => (
          <div className="item-wrapper">
            <span
              onClick={() => onSelectTag(item.Id)}
              className="document-tags-item"
            >
              {item.Name}
            </span>
            <CloseOutlined
              onClick={() => {
                updateSelectedTag(item.Id);
                onChangeModalVisible(true);
              }}
              className="detach-tag"
            />
          </div>
        ))}
        <div className="document-tag-button">
          <span onClick={() => onChangeDrawerVisible(true)}>
            + Добавить тег
          </span>
        </div>
      </div>
    </article>
  );
};
