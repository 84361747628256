import * as React from 'react';
import { CheckableTagProps } from 'antd/lib/tag/CheckableTag';

export type DrawerType = 'edit' | 'create';

export type TRegNumber = {
  Name: string;
  Id?: number;
  CreateDate?: string;
  UpdateDate?: string;
  StartCount: string & (string | number | readonly string[]);
  MaskRegNumber?: string;
};

export enum MOCK_EXAMPLE {
  '{Count}' = '',
  '{OrgSenderOfficialCode}' = '20',
  '{OrgOfficialCode}' = '18',
  '{Day}' = '22',
  '{Month}' = '10',
  '{Year}' = '2022',
  '{NomCode}' = '13',
  '{Number}' = '34',
  '{FullDate}' = '22/06/2001 16:44:12',
}

export type RegistrationDrawer = {
  item?: TRegNumber;
  onClose: (isSending?: boolean) => void;
  visible: boolean;
  type: DrawerType;
};

export type useDocumentRegistrationDrawerTypes = {
  item?: TRegNumber;
  type: DrawerType;
  onClose: (isSending?: boolean) => void;
};

export type InitialValuesTypes = {
  Name: string;
  DocumentKindId: number;
  DocumentType: string;
  NomenclatureId: number;
  FlowId: number;
  Id: number;
  MaskRegNumber: string;
  StartCount: string & (string | number | readonly string[]);
};

export type TMaskCondition = Record<string, string>;

export type CheckableTagTypes = {
  CheckableTag: React.FC<CheckableTagProps>;
};
