import { Select, Skeleton } from 'antd';
import { DButton, DInput } from 'shared/ui-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { $api } from 'shared/utils/api';
import { ReceiverComponent } from 'pages/CreateDocument/components/ReceiverComponent';
import { UploadFiles } from 'shared/components/Files';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { errorThrow, formatFiles } from 'shared/utils';
import { useFormik } from 'formik';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { AssignmentGeneralInfo } from './AssignmentGeneralInfo';
import {
  AssignmentDocumentDispatchFormProps,
  Receiver,
  SubmittedData,
} from '../types';
import { debounce } from 'lodash-es';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { ContactList } from 'pages/CreateDocument/types';
import _ from 'lodash';
import { reset, updateOfficerRelations } from 'store/slice/creatingDocument';
import { FileListParams } from 'shared/components/Files/types';
import { TSendingContext } from 'types/document';
import { PreLoader } from 'shared/components/PreLoader';

const sendAssignments = async (
  submittedData: SubmittedData,
  assignmentId: number,
) => {
  try {
    await $api.patch(
      `Assignments/${assignmentId}/Execute/Sending`,
      submittedData,
    );
  } catch (error) {
    errorThrow(error.response);
    throw error;
  }
};

const fetchDocumentSendingContext = async (documentId: number) => {
  try {
    const { data } = await $api.get(`documents/${documentId}/sendingcontexts`);
    return data;
  } catch ({ response }) {
    errorThrow(response);
  }
};

export const AssignmentDocumentDispatchForm = ({
  phases,
  assignment,
  documentData,
  onSuccessResponse,
}: AssignmentDocumentDispatchFormProps) => {
  const [sendingContext, setSendingContext] = useState<TSendingContext[]>([]);
  const [currentFiles, setCurrentFiles] = useState<FileListParams[]>([]);
  const [correspondentList, setCorrespondentList] = useState<ContactList[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<number | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { Option } = Select;
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { officerRelations, disabledButton } = useSelector(
    (state: ReduxState) => state.creatingDocumentReducer,
  );
  const dispatch = useDispatch();
  const correspondentId = sendingContext[0]?.CorrespondentId;

  const initialValues = {
    delivery: undefined,
    DocumentContent: documentData?.DocumentComposition,
    Comment: '',
    Correspondent: undefined,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onExecuteSending(values);
    },
  });

  const getCorrespondentList = useCallback(
    (query?: string) => {
      $api
        .get(
          `contacts/active/byorganization/${user?.CurrentContact?.OrganisationId}/page`,
          {
            params: {
              FastSearch: query,
            },
          },
        )
        .then(({ data: { Data } }) => {
          setCorrespondentList(Data);
          if (correspondentId) {
            $api
              .get(`contacts/${correspondentId}`)
              .then(({ data }) => {
                setCorrespondentList(prev => [...prev, data]);
              })
              .catch(({ response }) => errorThrow(response));
          }
        })
        .catch(({ response }) => errorThrow(response));
    },
    [correspondentId, user?.CurrentContact?.OrganisationId],
  );

  const onSearchCorrespondent = useCallback(
    debounce(value => {
      getCorrespondentList(value);
    }, DEBOUNCE_TIME),
    [],
  );

  const updateFormValues = useCallback(() => {
    documentData?.Id &&
      fetchDocumentSendingContext(documentData?.Id).then(list => {
        setSendingContext(list);

        formik.setFieldValue('Correspondent', list[0]?.CorrespondentId);
        formik.setFieldValue('delivery', list[0]?.SendTypeId);
      });
  }, [documentData?.Id]);

  useEffect(() => {
    updateFormValues();
    getCorrespondentList();
  }, [getCorrespondentList, updateFormValues]);

  const formatReceivers = (sendingContext: TSendingContext[]) => {
    return sendingContext.map(item => ({
      OrganisationId: item?.OrganisationId,
      OrganisationName: item?.OrganisationName,
      AddresseeId: item.AddresseeId,
      AddresseeLastName: item.AddresseeLastName,
      AddresseeFirstName: item.AddresseeFirstName,
      AddresseeMiddleName: item.AddresseeMiddleName,
      SendTypeName: item.SendTypeName,
      SendTypeId: item?.SendTypeId,
    }));
  };

  useEffect(() => {
    dispatch(updateOfficerRelations(formatReceivers(sendingContext) ?? []));
  }, [dispatch, sendingContext]);

  const formatDocumentReceivers = useCallback(() => {
    return officerRelations.map(item => {
      return {
        OrganisationId: item?.OrganisationId,
        ContactId: item?.AddresseeId ?? null,
        SendTypeId: item?.SendTypeId,
      };
    }) as Receiver[];
  }, [officerRelations]);

  const onExecuteSending = useCallback(
    async values => {
      setIsLoading(true);
      const submittedData: SubmittedData = {
        PhaseId: selectedPhase ?? null,
        Resolution: values.Comment,
        ExecutionFactTime: new Date().toISOString(),
        DocumentComposition: values.DocumentContent,
        FileIds: formatFiles(currentFiles),
        DocumentReceivers: formatDocumentReceivers(),
        CorrespondentId: values.Correspondent,
      };

      await sendAssignments(submittedData, assignment.Id).finally(() =>
        setIsLoading(false),
      );
      onSuccessResponse('Задача успешно исполнена');
      dispatch(reset());
    },
    [
      selectedPhase,
      currentFiles,
      formatDocumentReceivers,
      assignment.Id,
      onSuccessResponse,
      dispatch,
    ],
  );

  return (
    <>
      {(isLoading || disabledButton) && <PreLoader />}
      {!documentData ? (
        <Skeleton />
      ) : (
        <>
          <AssignmentGeneralInfo
            assignment={assignment}
            documentData={documentData}
          />
          <form className="drawer-form" onSubmit={formik.handleSubmit}>
            <ReceiverComponent formik={formik} />
            <DFormItemTwo label="Корреспондент(от кого)" name="Correspondent">
              <Select
                className="ui-select"
                allowClear
                showSearch
                optionFilterProp="children"
                onChange={value => formik.setFieldValue('Correspondent', value)}
                value={formik.values.Correspondent}
                onSearch={onSearchCorrespondent}
              >
                {_.uniqBy(correspondentList, 'Id').map(
                  (incidences: ContactList) => (
                    <Option key={incidences.Id} value={incidences.Id}>
                      {incidences.LastName} {incidences.FirstName}{' '}
                      {incidences.MiddleName}
                    </Option>
                  ),
                )}
              </Select>
            </DFormItemTwo>
            <DFormItemTwo label="Состав документа" name="DocumentContent">
              <DInput
                width="100%"
                type="text"
                value={formik.values.DocumentContent}
                name="DocumentContent"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo label="Комментарий" name="Comment">
              <DInput
                width="100%"
                type="text"
                value={formik.values.Comment}
                name="Comment"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo
              label="Файлы для отправки"
              className="form-item__with-list"
            >
              <UploadFiles
                setCurrentFiles={setCurrentFiles}
                currentFiles={currentFiles}
              />
            </DFormItemTwo>
            <DFormItemTwo className="form-buttons">
              {phases.length ? (
                phases.map(item => (
                  <>
                    <DButton
                      onClick={() => setSelectedPhase(item.Id)}
                      className="mr15"
                      disabled={disabledButton}
                      primary
                      small
                      type="submit"
                    >
                      {item.LinkName ?? 'Выполнить'}
                    </DButton>
                  </>
                ))
              ) : (
                <DButton
                  onClick={() => setSelectedPhase(null)}
                  className="mr15"
                  disabled={disabledButton}
                  primary
                  small
                  type="submit"
                >
                  Выполнить
                </DButton>
              )}
            </DFormItemTwo>
          </form>
        </>
      )}
    </>
  );
};
