import { errorThrow, TError } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { DEFAULT_DATA } from './constants';
import { getItemChildrens } from './utils';

export const fetchDocumentKindsWithPermission = async (
  OrganisationId?: number,
) => {
  try {
    const {
      data: { Data },
    } = await $api.get(`documentStreams/filter`, {
      params: {
        OrganisationId,
        limit: 1000,
        ForSelectedOrg: true,
      },
    });
    const convertedData = DEFAULT_DATA.map(item =>
      getItemChildrens(item, Data),
    );

    return convertedData;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};

export const fetchDocumentKindsWithOutPermission = async () => {
  try {
    const { data } = await $api.get(`documentStreams/byorganisation`, {
      params: {
        limit: 1000,
      },
    });
    const convertedData = DEFAULT_DATA.map(item =>
      getItemChildrens(item, data),
    );

    return convertedData;
  } catch ({ response }) {
    errorThrow(response as TError);
  }
};
