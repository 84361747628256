import { useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { AnimatePresence, motion } from 'framer-motion';
import { MainTable } from 'shared/components/MainTable';
import { MatchParams } from 'types/tableData';
import { DTag } from 'shared/ui-kit/DTag';
import { FilterDrawer } from 'shared/components/CustomDrawers/FilterDrawer';
import { PageHeader } from 'shared/components/PageHeader';
import { Skeleton } from 'antd';
import { useFilteredDocumentPage } from './useFilteredDocumentPage';
import { DButton } from 'shared/ui-kit';

export const FilteredDocumentsPage = ({ match }: MatchParams) => {
  const { isLoading, onClose, settings } = useFilteredDocumentPage();
  const { filteredDocuments, visibleSettings } = useSelector(
    (state: ReduxState) => state.tableDataReducer,
  );

  return (
    <section id="filtered_table">
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <PageHeader title="Поиск" code="FILTERED_DOCUMENTS_PAGE" />
        <DButton
          small
          className="open-filter"
          onClick={() => onClose(!visibleSettings)}
        >
          <div className="flex-space">
            Фильтр
            <div className={!visibleSettings ? 'arrow-bottom' : 'arrow-up'} />
          </div>
        </DButton>
      </div>
      {/* @ts-ignore */}
      <AnimatePresence initial={false}>
        {visibleSettings && (
          <motion.div
            className="form mb40"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.4, 0.5, 0.4, 0.5] }}
          >
            <FilterDrawer
              type="window"
              visible={visibleSettings}
              onClose={onClose}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="flex-space">
        {isLoading ? (
          <Skeleton.Input className="w300" active />
        ) : (
          <div>
            {settings?.length
              ? settings.map(item => (
                  <DTag className="mr15">
                    {item.Name}: {item.value}
                  </DTag>
                ))
              : null}
          </div>
        )}
      </div>
      <MainTable
        data={{
          tableData: filteredDocuments.data ?? [],
          filteredCount: filteredDocuments.filteredCount,
        }}
        match={match}
      />
    </section>
  );
};
