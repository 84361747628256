import { DatePicker, Drawer, Select } from 'antd';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import { DrawerHeader } from './DrawerHeader';
import moment from 'moment';
import { NomenclatureParams } from 'pages/AssignmentCard/types';
import { getDataAfterToday } from 'pages/AdminPage/Nomenclatures/utils';
import { ExpirationDates } from 'pages/Archive/constants';

type TNomenclatureDrawer = {
  visible: boolean;
  onClose: () => void;
  item: NomenclatureParams | null;
  type: string;
  getData: () => void;
};

export const NomenclatureDrawer = ({
  visible,
  onClose,
  item,
  type,
  getData,
}: TNomenclatureDrawer) => {
  const [nomenclatureList, setNomenclatureList] = useState<
    NomenclatureParams[]
  >([]);
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const { Option } = Select;
  const TITLE_TYPE = type === 'edit' ? 'Редактирование' : 'Создание';

  const initialValues = {
    Name: item?.Name,
    Code: item?.Code,
    Type: item?.Type,
    ParentId: item?.ParentId,
    EndDate: item?.EndDate,
    ArchiveExpirationDateType: item?.ArchiveExpirationDateType,
  };

  const getNomenclatures = useCallback(() => {
    $api
      .get(
        `nomenclatures/byorganization/${user?.CurrentContact?.OrganisationId}`,
        {
          params: {
            Limit: 30000,
          },
        },
      )
      .then(({ data }) => {
        setNomenclatureList(data.filter(getDataAfterToday));
      })
      .catch(({ response }) => errorThrow(response));
  }, [user?.CurrentContact?.OrganisationId]);

  useEffect(() => {
    getNomenclatures();
  }, [getNomenclatures]);

  const onFinish = (values: any) => {
    const request = type === 'create' ? $api.post : $api.put;
    const requestUrl =
      type === 'create'
        ? 'Nomenclatures/byorganization'
        : `nomenclatures/${item?.Id}/byorganization`;

    request(requestUrl, {
      Id: item?.Id,
      ParentId: values.ParentId,
      Name: values.Name,
      Code: values.Code,
      Type: values.Type,
      OrganisationId: user?.CurrentContact?.OrganisationId,
      EndDate: values.EndDate,
      ArchiveExpirationDateType: values.ArchiveExpirationDateType,
    })
      .then(() => {
        getData();
        onClose();
      })
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <Drawer
      placement="right"
      onClose={() => onClose()}
      visible={visible}
      closeIcon={null}
      className="drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text={`${TITLE_TYPE} номенклатуры`} />
        <form className="drawer-form" onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Имя" name="Name">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Name}
              name="Name"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Родительская номенклатура" name="ParentId">
            <Select
              value={formik.values.ParentId}
              className="ui-select"
              allowClear
              showSearch
              optionFilterProp="children"
              onChange={value => formik.setFieldValue('ParentId', value)}
            >
              {nomenclatureList?.map(item => (
                <Option value={item.Id}>{item.Name}</Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo label="Код" name="Code">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Code}
              name="Code"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Срок действия" name="EndDate">
            <DatePicker
              format="DD.MM.YYYY"
              defaultValue={
                formik.values.EndDate
                  ? moment(formik.values.EndDate)
                  : undefined
              }
              onChange={date =>
                formik.setFieldValue('EndDate', moment.utc(date))
              }
              style={{ width: '100%', height: 36, borderRadius: 8 }}
            />
          </DFormItemTwo>
          <DFormItemTwo
            label="Срок хранения в архиве"
            name="ArchiveExpirationDateType"
          >
            <Select
              value={formik.values.ArchiveExpirationDateType}
              className="ui-select"
              showSearch
              optionFilterProp="children"
              onChange={value =>
                formik.setFieldValue('ArchiveExpirationDateType', value)
              }
            >
              {ExpirationDates?.map(item => (
                <Select.Option value={item.type} key={item.type}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </DFormItemTwo>
          <DFormItemTwo className="form-buttons">
            <DButton small primary className="mr15" type="submit">
              {type === 'create' ? 'Создать' : 'Редактировать'}
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
