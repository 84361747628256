import { DatePicker, Select, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { ContactList } from 'pages/CreateDocument/types';
import { $api } from 'shared/utils/api';
import { ReduxState } from 'store/store';
import moment from 'moment';
import { DButton, DTextarea } from 'shared/ui-kit';
import { errorThrow } from 'shared/utils';
import './DocumentControl.scss';
import { useFormik } from 'formik';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { useSelector } from 'react-redux';
import _ from 'lodash';

type PropParams = {
  type: string;
  onFinish: any;
  showError: boolean;
  updateShowError: () => void;
};

const controlKinds = [
  {
    Kind: 'One',
    title: 'Однократный',
  },
  {
    Kind: 'Week',
    title: 'Постоянный с еженедельной информацией',
  },
  {
    Kind: 'Month',
    title: 'Постоянный с ежемесячной информацией',
  },
  {
    Kind: 'Quarter',
    title: 'Постоянный с ежеквартальной информацией',
  },
  {
    Kind: 'HalfYear',
    title: 'Постоянный с полугодовой информацией',
  },
  {
    Kind: 'Year',
    title: 'Постоянный с информацией по годам',
  },
];

export const DocumentControlForm = ({
  type,
  onFinish,
  showError,
  updateShowError,
}: PropParams) => {
  const [controllers, setControllers] = useState<ContactList[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { Option } = Select;
  const { documentInfo } = useSelector(
    (state: ReduxState) => state.documentInfoReducer,
  );
  const { user } = useSelector((state: ReduxState) => state.tableDataReducer);
  const DOCUMENT_CONTROL = documentInfo?.Controls[0];

  const getInfo = useCallback(() => {
    if (type === 'take-control' || type === 'reschedule-control') {
      setLoader(true);
      $api
        .get('Contacts/WithRoleController')
        .then(res => setControllers(res.data))
        .catch(({ response }) => errorThrow(response))
        .finally(() => setLoader(false));
    }
  }, [type]);

  useEffect(() => {
    getInfo();
  }, []);

  const initialValues = {
    ...(type === 'reschedule-control'
      ? {
          deadlineDate: DOCUMENT_CONTROL
            ? moment(new Date(DOCUMENT_CONTROL?.ControlDate))
            : null,
          ControlKind: DOCUMENT_CONTROL?.ControlKind,
          Controller: DOCUMENT_CONTROL?.ControllerId,
        }
      : {
          Controller: undefined,
          ControlKind: undefined,
          deadlineDate: moment(),
        }),
    DeregisterDate: moment(),
    ExecuteDate: moment(),
    Comment: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  return (
    <section id="control-form">
      {loader ? (
        <Skeleton />
      ) : (
        <>
          <form className="drawer-form" onSubmit={formik.handleSubmit}>
            {(type === 'take-control' || type === 'reschedule-control') && (
              <>
                <DFormItemTwo
                  label="Срок получения ответа"
                  required
                  name="deadlineDate"
                  className="control-time-picker"
                >
                  <DatePicker
                    className={
                      !showError || formik.values.deadlineDate !== undefined
                        ? ''
                        : 'date-picker-danger'
                    }
                    allowClear={false}
                    onChange={date => {
                      updateShowError();
                      formik.setFieldValue('deadlineDate', date);
                    }}
                    value={moment(formik.values.deadlineDate)}
                    style={{ height: 36, borderRadius: 8 }}
                    format="DD.MM.YYYY HH:00"
                    placeholder="Выберите дату и время"
                    showTime
                    showNow={false}
                  />
                </DFormItemTwo>
                <DFormItemTwo required label="Вид контроля" name="ControlKind">
                  <Select
                    className={
                      !showError || formik.values.ControlKind !== undefined
                        ? 'ui-select'
                        : 'ui-select-danger'
                    }
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={value => {
                      formik.setFieldValue('ControlKind', value);
                      updateShowError();
                    }}
                    value={formik.values.ControlKind}
                  >
                    {controlKinds.map(kind => (
                      <Option key={kind.Kind} value={kind.Kind}>
                        {kind.title}
                      </Option>
                    ))}
                  </Select>
                </DFormItemTwo>
                <DFormItemTwo required label="Контролер" name="Controller">
                  <Select
                    className={
                      !showError || formik.values.Controller !== undefined
                        ? 'ui-select'
                        : 'ui-select-danger'
                    }
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    onChange={value => {
                      updateShowError();
                      formik.setFieldValue('Controller', value);
                    }}
                    value={formik.values.Controller}
                  >
                    {_.uniqBy(controllers, 'Id')
                      .filter(
                        controller =>
                          controller.Id === user?.CurrentContact?.Id,
                      )
                      .map(controller => (
                        <Option key={controller.Id} value={controller.Id}>
                          {controller.LastName} {controller.FirstName}{' '}
                          {controller.MiddleName}
                        </Option>
                      ))}
                  </Select>
                </DFormItemTwo>
              </>
            )}
            {type === 'deregister-document' && (
              <DFormItemTwo
                className="control-time-picker"
                label="Снят с контроля"
                name="DeregisterDate"
                required
              >
                <DatePicker
                  className={
                    !showError || formik.values.DeregisterDate !== undefined
                      ? ''
                      : 'date-picker-danger'
                  }
                  onChange={date => {
                    formik.setFieldValue('DeregisterDate', date);
                  }}
                  allowClear={false}
                  style={{ height: 36, borderRadius: 8 }}
                  showTime
                  value={moment(formik.values.DeregisterDate)}
                  format="DD.MM.YYYY HH:00"
                  placeholder="Выберите дату"
                  showNow={false}
                />
              </DFormItemTwo>
            )}
            {type === 'mark-execution-document' && (
              <DFormItemTwo
                required
                className="control-time-picker"
                label="Дата исполнения документа"
                name="ExecuteDate"
              >
                <DatePicker
                  className={
                    !showError || formik.values.ExecuteDate !== undefined
                      ? ''
                      : 'date-picker-danger'
                  }
                  allowClear={false}
                  style={{ height: 36, borderRadius: 8 }}
                  format="DD.MM.YYYY HH:00"
                  placeholder="Выберите дату и время"
                  showTime
                  value={moment(formik.values.ExecuteDate)}
                  onChange={date => {
                    formik.setFieldValue('ExecuteDate', date);
                  }}
                  showNow={false}
                />
              </DFormItemTwo>
            )}
            <DFormItemTwo
              style={{ alignItems: 'baseLine' }}
              label="Комментарий"
              name="Comment"
            >
              <DTextarea
                name="Comment"
                value={formik.values.Comment}
                className="w100per"
                onChange={formik.handleChange}
              />
            </DFormItemTwo>
            <DFormItemTwo className="form-buttons">
              <DButton className="mr15" small type="submit" primary>
                Выполнить
              </DButton>
            </DFormItemTwo>
          </form>
        </>
      )}
    </section>
  );
};
