import { useState } from 'react';
import { DocumentReportDrawer } from 'shared/components/CustomDrawers/DocumentReportDrawer';

export const Report = () => {
  const [visibleReportDrawer, setVisibleReportDrawer] = useState<boolean>(
    false,
  );

  const onCloseReportDrawer = (bool: boolean) => {
    setVisibleReportDrawer(bool);
  };

  return (
    <>
      {visibleReportDrawer && (
        <DocumentReportDrawer
          visible={visibleReportDrawer}
          onClose={onCloseReportDrawer}
        />
      )}
      <span
        className="custom-link mb15"
        onClick={() => setVisibleReportDrawer(true)}
      >
        Лист согласования
      </span>
    </>
  );
};
