import { updateTableData } from 'pages/MainPage/MainPage';
import { SortActiveParams, TTablePagination } from 'store/slice/table';
import { AppDispatch } from 'store/store';
import { buttonTypes } from './constants';
import { TableButton } from './types';

export const updateTableOnSelectType = ({
  type,
  dispatch,
  pagination,
  organisationId,
  searchValue,
  sortParams,
}: {
  type?: TableButton;
  dispatch: AppDispatch;
  pagination: TTablePagination | null;
  organisationId?: number;
  searchValue?: string;
  sortParams?: SortActiveParams | null;
}) => {
  buttonTypes.forEach(({ Type: buttonType, url, hasOrgId }) => {
    if (type === buttonType) {
      updateTableData({
        type: `documents/byuser/${url}`,
        dispatch,
        orgId: hasOrgId ? organisationId : undefined,
        tablePagination: pagination,
        searchValue: searchValue,
        sortParams,
      });
    }
  });
};
