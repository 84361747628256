import { fetchUserGroups, removeUserGroup } from 'api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DButton } from 'shared/ui-kit';
import {
  reset,
  updateLoader,
  updateUserGroup,
  updateUserGroups,
  UserGroupList,
} from 'store/slice/userGroups';

export const useUserGroups = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [drawerType, setDrawerType] = useState<'create' | 'edit'>('create');
  const dispatch = useDispatch();

  const onFetchData = useCallback(() => {
    dispatch(updateLoader(true));

    fetchUserGroups()
      .then(list => dispatch(updateUserGroups(list)))
      .finally(() => dispatch(updateLoader(false)));
  }, [dispatch]);

  const onRemove = (id: number) => {
    removeUserGroup(id).finally(onFetchData);
  };

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Имя',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, item: UserGroupList) => (
        <>
          <DButton
            small
            defaultPrimary
            className="mr15"
            onClick={() => {
              setSelectedId(item.Id);
              setVisibleDrawer(true);
              setDrawerType('edit');
            }}
          >
            Редактировать
          </DButton>
          <DButton small defaultDanger onClick={() => onRemove(item.Id)}>
            Удалить
          </DButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    onFetchData();
    return () => {
      dispatch(reset());
    };
  }, [dispatch, onFetchData]);

  return {
    columns,
    visibleDrawer,
    selectedId,
    drawerType,
    updateDrawerType: setDrawerType,
    updateDrawerVisible: (visible: boolean) => {
      setVisibleDrawer(visible);
      setSelectedId(null);
      dispatch(updateUserGroup(null));
    },
    onFetchData,
  };
};
