import { Table } from 'antd';
import { format } from 'date-fns/esm';
import { SignatureTable } from 'pages/Archive/type';

export const SignaturesTable = ({
  signatureTableData,
}: {
  signatureTableData: SignatureTable[];
}) => {
  const columns = [
    {
      title: 'Номер',
      dataIndex: 'Id',
      key: 'Id',
    },
    {
      title: 'Дата подписания',
      dataIndex: 'CreateDate',
      key: 'CreateDate',
      render: (date: string) => {
        return <span>{format(new Date(date), 'dd.MM.yyyy')}</span>;
      },
    },
    {
      title: 'Подписант',
      dataIndex: 'SignerId',
      key: 'SignerId',
      render: (text: string, item: SignatureTable) => (
        <span>
          {item.SignerLastName} {item.SignerFirstName} {item.SignerMiddleName}
        </span>
      ),
    },
    {
      title: 'Отпечаток подписи',
      dataIndex: 'Thumbprint',
      key: 'Thumbprint',
    },
    {
      title: 'Признак',
      dataIndex: 'IsCorrect',
      key: 'IsCorrect',
      render: (IsCorrect: boolean) => (IsCorrect ? 'Верна' : 'Не верна'),
    },
  ];

  return <Table dataSource={signatureTableData} columns={columns} />;
};
