import { Drawer, Radio, Select, Skeleton, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { ContactList } from 'pages/CreateDocument/types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DButton, DInput } from 'shared/ui-kit';
import { assignmentState, assignmentTypes, errorThrow } from 'shared/utils';
import { $api } from 'shared/utils/api';
import {
  updateAssignmentFilterConfig,
  updateAssignmentSearchValue,
  updateFilteredCount,
  updatePagination,
} from 'store/slice/assignments';
import { ReduxState } from 'store/store';
import { AssignmentType } from 'types/document';
import { RangePick } from '../DatePicker/RangePick';
import { DrawerHeader } from './DrawerHeader';
import { useFormik } from 'formik';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { CatalogDrawer } from './CatalogDrawer';
import { CatalogButton } from '../CatalogButton';

type TAssignmentFilterDrawer = {
  visible: boolean;
  onClose: () => void;
  updateTable: ({
    query,
    array,
    type,
  }: {
    query?: string;
    array?: AssignmentType[];
    type?: any;
  }) => void;
};

export const AssignmentFilterDrawer = ({
  visible,
  onClose,
  updateTable,
}: TAssignmentFilterDrawer) => {
  const [authorContacts, setAuthorContacts] = useState<ContactList[]>([]);
  const [executorContacts, setExecutorContacts] = useState<ContactList[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedPoint, setSelectedPoint] = useState<number>(0);
  const [visibleCatalog, setVisibleCatalog] = useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState<string>('');
  const [form] = useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { config } = useSelector(
    (state: ReduxState) => state.assignmentReducer,
  );

  const onCloseCatalog = () => setVisibleCatalog(false);

  let initialValues: any = {
    AuthorId: undefined,
    AssignmentType: undefined,
    ExecutorId: undefined,
    AssignmentState: undefined,
    State: undefined,
    searchBy: undefined,
    ExecutionTime: [],
    DocumentId: '',
  };

  const updateFields = () => {
    const initialValue = { ...config };
    if (config.AuthorId) {
      $api
        .get(`Contacts/${config.AuthorId}`)
        .then(res => setAuthorContacts(prev => [...prev, res.data]))
        .catch(({ response }) => errorThrow(response));
    }
    if (config.ExecutorId) {
      $api
        .get(`Contacts/${config.ExecutorId}`)
        .then(res => setExecutorContacts(prev => [...prev, res.data]))
        .catch(({ response }) => errorThrow(response));
    }
    if (config.StartControlDate) {
      initialValue.ExecutionTime = [];
      initialValue.ExecutionTime.push(moment(config.StartControlDate));
    }
    if (config.EndControlDate) {
      initialValue.ExecutionTime.push(moment(config.EndControlDate));
    }
    if (config.IssuedByMe) {
      setSelectedPoint(1);
    }
    if (config.IssuedToMe) {
      setSelectedPoint(2);
    }
    if (config.RequireProcessing) {
      setSelectedPoint(3);
    }
    if (config.WithAtach) {
      setSelectedPoint(4);
    }
    for (let value in initialValue) {
      formik.setFieldValue(value, initialValue[value]);
    }
    form.setFieldsValue(initialValue);
  };

  const getData = useCallback(() => {
    $api
      .get('Contacts/byorganization')
      .then(res => {
        setExecutorContacts(res.data);
        setAuthorContacts(res.data);
      })
      .catch(({ response }) => errorThrow(response))
      .finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    getData();
    if (config) updateFields();
  }, [getData]);

  const onFinish = (values: any) => {
    let isEmpty = false;
    for (let value in values) {
      if (values[value]) isEmpty = true;
    }
    dispatch(updateAssignmentSearchValue(''));
    if (isEmpty)
      $api
        .get('Assignments/Filter', {
          params: {
            Limit: 1000,
            ...values,
            StartControlDate: values.ExecutionTime?.length
              ? values.ExecutionTime[0]._d
              : null,
            EndControlDate: values.ExecutionTime?.length
              ? values.ExecutionTime[1]._d
              : null,
            IssuedByMe: selectedPoint === 1,
            IssuedToMe: selectedPoint === 2,
            RequireProcessing: selectedPoint === 3,
            WithAtach: selectedPoint === 4,
          },
        })
        .then(res => {
          dispatch(
            updateAssignmentFilterConfig({
              ...values,
              StartControlDate: values.ExecutionTime?.length
                ? values.ExecutionTime[0]._d
                : null,
              EndControlDate: values.ExecutionTime?.length
                ? values.ExecutionTime[1]._d
                : null,
              IssuedByMe: selectedPoint === 1,
              IssuedToMe: selectedPoint === 2,
              RequireProcessing: selectedPoint === 3,
              WithAtach: selectedPoint === 4,
            }),
          );
          updateTable({ query: '', array: res.data.Data });
          dispatch(updatePagination({ page: 1, pageSize: 15 }));
          dispatch(updateFilteredCount(res.data.FilteredCount));
          onClose();
        })
        .catch(({ response }) => errorThrow(response));
    else onClose();
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
      onFinish(values);
    },
  });

  const updateList = (item: ContactList) => {
    if (fieldValue === 'AuthorId') {
      setAuthorContacts(prev => [...prev, item]);
      formik.setFieldValue('AuthorId', item.Id);
    } else {
      setExecutorContacts(prev => [...prev, item]);
      formik.setFieldValue('ExecutorId', item.Id);
    }
  };

  const checkDisabledButtonSearch = () =>
    !Object.values(formik.values).find(item =>
      Array.isArray(item) ? item?.length : !!item,
    );

  return (
    <>
      {visibleCatalog && (
        <CatalogDrawer
          visible={visibleCatalog}
          onClose={onCloseCatalog}
          type="contacts"
          func={updateList}
        />
      )}
      <Drawer
        placement="right"
        onClose={() => onClose()}
        visible={visible}
        className="drawer"
        closeIcon={null}
      >
        <div className="page3">
          <DrawerHeader onClose={onClose} text="Расширенный поиск задач" />
          {loader ? (
            <Skeleton />
          ) : (
            <form className="drawer-form" onSubmit={formik.handleSubmit}>
              <DFormItemTwo label="Автор" name="AuthorId">
                <Select
                  optionFilterProp="children"
                  className="ui-select"
                  placeholder="Выберите автора"
                  allowClear
                  showSearch
                  value={formik.values.AuthorId}
                  onChange={value => formik.setFieldValue('AuthorId', value)}
                >
                  {_.uniqBy(authorContacts, 'Id')?.map((item: any) => (
                    <Option key={item.Id} value={item.Id}>
                      {item.LastName} {item.FirstName} {item.MiddleName}
                    </Option>
                  ))}
                </Select>
                <CatalogButton
                  onClick={() => {
                    setVisibleCatalog(true);
                    setFieldValue('AuthorId');
                  }}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Исполнитель" name="ExecutorId">
                <Select
                  optionFilterProp="children"
                  className="ui-select"
                  placeholder="Выберите исполнителя"
                  allowClear
                  showSearch
                  value={formik.values.ExecutorId}
                  onChange={value => formik.setFieldValue('ExecutorId', value)}
                >
                  {_.uniqBy(executorContacts, 'Id')?.map((item: any) => (
                    <Option key={item.Id} value={item.Id}>
                      {item.LastName} {item.FirstName} {item.MiddleName}
                    </Option>
                  ))}
                </Select>
                <CatalogButton
                  onClick={() => {
                    setVisibleCatalog(true);
                    setFieldValue('ExecutorId');
                  }}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Тип задачи" name="AssignmentType">
                <Select
                  optionFilterProp="children"
                  className="ui-select"
                  placeholder="Выберите тип задачи"
                  allowClear
                  showSearch
                  value={formik.values.AssignmentType}
                  onChange={value =>
                    formik.setFieldValue('AssignmentType', value)
                  }
                >
                  {assignmentTypes?.map(item => (
                    <Option key={item.Type} value={item.Type}>
                      {item.Text}
                    </Option>
                  ))}
                </Select>
              </DFormItemTwo>
              <DFormItemTwo label="Состояние задачи" name="AssignmentState">
                <Select
                  optionFilterProp="children"
                  className="ui-select"
                  placeholder="Выберите состояние задачи"
                  allowClear
                  showSearch
                  value={formik.values.AssignmentState}
                  onChange={value =>
                    formik.setFieldValue('AssignmentState', value)
                  }
                >
                  {assignmentState?.map(item => (
                    <Option key={item.State} value={item.State}>
                      {item.Text}
                    </Option>
                  ))}
                </Select>
              </DFormItemTwo>
              <DFormItemTwo label="Контрольный срок" name="ExecutionTime">
                <RangePick
                  onChange={(date: Moment) => {
                    formik.setFieldValue('ExecutionTime', date);
                  }}
                  value={formik.values.ExecutionTime}
                  style={{ width: '100%', height: 36, borderRadius: 8 }}
                  format={'DD.MM.YYYY'}
                />
              </DFormItemTwo>
              <DFormItemTwo label="Системный номер документа" name="DocumentId">
                <DInput
                  width="100%"
                  type="text"
                  value={formik.values.DocumentId}
                  name="DocumentId"
                  onChange={formik.handleChange}
                />
              </DFormItemTwo>
              <DFormItemTwo name="searchBy">
                <Radio.Group
                  onChange={e => {
                    setSelectedPoint(e.target.value);
                    formik.setFieldValue('searchBy', e.target.value);
                  }}
                  value={selectedPoint}
                >
                  <Space direction="vertical">
                    <Radio value={1}>Задачи, выданные мной</Radio>
                    <Radio value={2}>Задачи, выданные мне</Radio>
                    <Radio value={3}>Задачи, требующие обработки</Radio>
                    <Radio value={4}>Задачи, имеющие прикрепленные файлы</Radio>
                  </Space>
                </Radio.Group>
              </DFormItemTwo>
              <DFormItemTwo className="form-buttons">
                <DButton
                  className="mr15"
                  type="submit"
                  disabled={checkDisabledButtonSearch()}
                  primary
                  small
                >
                  Найти
                </DButton>
                <DButton
                  className="mr15"
                  defaultDanger
                  small
                  onClick={() => onClose()}
                >
                  Отмена
                </DButton>
                <DButton
                  className="mr15"
                  defaultPrimary
                  small
                  onClick={() => {
                    formik.resetForm({
                      values: initialValues,
                    });
                    setSelectedPoint(0);
                    updateTable({ query: '', array: undefined });
                    dispatch(updateAssignmentFilterConfig(null));
                  }}
                >
                  Очистить
                </DButton>
              </DFormItemTwo>
            </form>
          )}
        </div>
      </Drawer>
    </>
  );
};
