import { DInput } from 'shared/ui-kit';
import { debounce } from 'lodash-es';
import './AssignmentMenu.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store/store';
import { AssignmentType } from 'types/document';
import {
  updateAssignmentSearchValue,
  updatePagination,
} from 'store/slice/assignments';
import { PageHeader } from 'shared/components/PageHeader';
import { checkLengthStringForSearch } from 'shared/utils/checkLengthStringForSearch';
import { DEFAULT_PAGINATION } from '../constants';
import { Icon } from 'shared/components/IconComponent';
import searchSvg from 'shared/assets/images/search.svg';
import { DEBOUNCE_TIME } from 'shared/constants/debounceTimeout';
import { TableSort } from 'shared/components/TableSort/TableSort';

export const AssignmentMenu = ({
  onUpdateTable,
  onSearchWithFilter,
}: {
  onUpdateTable: ({
    query,
    array,
    type,
  }: {
    query?: string;
    array?: AssignmentType[];
    type?: any;
  }) => void;
  onSearchWithFilter: (query?: string) => void;
}) => {
  const { config, filteredCount, filterType, isLoading } = useSelector(
    (state: ReduxState) => state.assignmentReducer,
  );
  const dispatch = useDispatch();

  const onSearch = debounce(value => {
    dispatch(updateAssignmentSearchValue(value));
    dispatch(updatePagination({ ...DEFAULT_PAGINATION }));
    onUpdateTable({ query: value });
  }, DEBOUNCE_TIME);

  const getAssignmentCount = () => {
    if (filterType === 'executor' && !isLoading) {
      return filteredCount;
    }

    return '';
  };

  return (
    <div className="tablemenu__wrapper">
      <div className="mt40">
        <PageHeader
          className="tablemenu__title-text"
          title={`Задачи ${getAssignmentCount()}`}
          code="ACTIVE_ASSIGNMENTS_PAGE"
        />
        <div className="flex-space">
          {!config ? (
            <DInput
              style={{ maxWidth: 630, width: '100%' }}
              icon={<Icon icon={searchSvg} alt="searchSvg" />}
              type="search"
              placeholder="Поиск по типу, теме, ФИО"
              onChange={({ target: { value } }) => {
                checkLengthStringForSearch(
                  value,
                  !config ? onSearch : onSearchWithFilter,
                );
              }}
            />
          ) : (
            <div />
          )}
          <TableSort type="assignment" />
        </div>
      </div>
    </div>
  );
};
