import moment, { Moment } from 'moment';
import { TDates } from 'store/slice/calendar';
import { PLAIN_OPTIONS, TYPES_ASSIGNMENT } from './constants';
import {
  AssignmentIncomingType,
  AssignmentOutcomingType,
  ValueToRequest,
} from './types';

export const getDisabledDates = (
  currentDate: Moment,
  disableDates: TDates[],
) => {
  const lessRealCurrentDate = currentDate.isBefore(moment().startOf('day'));

  return (
    lessRealCurrentDate ||
    !!disableDates.find(
      date => date.date === moment(currentDate).format('DD.MM.YYYY'),
    )
  );
};

export const getNextAllowedDate = (
  startDate: Moment,
  disabledDates: TDates[],
): Moment => {
  let count = 0;
  let currentDate = startDate.clone();

  if (
    disabledDates.some(item => item.date === currentDate.format('DD.MM.YYYY'))
  ) {
    count++;
  }

  while (count < 6) {
    if (
      !disabledDates.some(
        item => item.date === currentDate.format('DD.MM.YYYY'),
      )
    ) {
      count++;
    }
    currentDate.add(1, 'days');
  }

  return currentDate.subtract(1, 'days');
};

export const getAssignmentType = (type: AssignmentIncomingType) => {
  let assignmentType = null;

  switch (type) {
    case 'assignment-to-execute':
      assignmentType = 'ToExecute';
      break;
    case 'assignment-for-audit':
      assignmentType = 'ToView';
      break;
    case 'assignment-for-review':
      assignmentType = 'ToReview';
      break;
    case 'ToApproval':
      assignmentType = 'ToApproval';
      break;
  }

  return assignmentType as AssignmentOutcomingType;
};

export const getAssignmentText = (
  assignmentType: AssignmentOutcomingType,
  documentId?: number,
  isNotificationText?: boolean,
) => {
  const currentTypeInfo = TYPES_ASSIGNMENT.find(
    item => item.Type === assignmentType,
  );
  const textByType = currentTypeInfo?.Name.toLowerCase();

  const newAssignmentType = {
    type: currentTypeInfo?.Type,
    title: `Документ № ${documentId} - Выдача задачи ${textByType ?? ''}`,
    textSuccessNotification: `Выдана задача ${textByType ?? ''}`,
  };

  return isNotificationText
    ? newAssignmentType.textSuccessNotification
    : newAssignmentType.title;
};

export const getResponsibleName = (responsible: any) =>
  `${responsible.LastName ?? ''} ${responsible.FirstName ??
    ''} ${responsible.MiddleName ?? ''} (${responsible?.PositionName ??
    ''} ${responsible?.DepartmentName ?? ''})`;

export const getResponsibleDepartment = (responsible: any) => responsible?.Name;

export const getCurrentDateOfSubmit = (
  assignmentTime?: string,
  type?: string,
) => {
  return !type || type === 'ToExecute'
    ? moment(
        moment(new Date(), 'DD-MM-YYYY HH:00')
          .add(1, 'days')
          .utcOffset(0)
          .toDate()
          .setMinutes(0),
      )
    : moment(
        moment(new Date(assignmentTime ?? ''), 'DD-MM-YYYY HH:00').utcOffset(0),
      );
};

export const getValueToSendRequest = ({
  values,
  userId,
  currentAssignment,
  childType,
  type,
  isContactType,
  documentId,
  documentLinks,
  fileIds,
  isSaved,
}: ValueToRequest) => {
  // @ts-ignore
  // console.log(values.deadlineDate?._d.getTimezoneOffset());
  // console.log(values.deadlineDate?.utcOffset(180) ?? '123');
  // @ts-ignore
  // console.log(values.deadlineDate?.utcOffset(180)?._d.toISOString());
  return {
    AuthorId: userId,
    ExecutionTime:
      // @ts-ignore
      values.deadlineDate?._d.toISOString() ??
      getCurrentDateOfSubmit(currentAssignment?.ExecutionTime, childType),
    Content: values.text,
    // IsESigned: values.checkedValues.includes(PLAIN_OPTIONS[0].value),
    SigningRequired: values.checkedValues.includes(PLAIN_OPTIONS[0].value),
    AutoExecute: values.checkedValues.includes(PLAIN_OPTIONS[1].value),
    Type: currentAssignment ? values.AssignmentType : getAssignmentType(type),
    State: !isSaved ? 1 : 'Draft',
    ExecutorIds: values.executor.length
      ? Array.from(new Set(values.executor))
      : null,
    ResponsibleId: values.responsible,
    ExecutionGroupIds: values.executorGroup.length
      ? values.executorGroup
      : null,
    StateChangerId: userId,
    AuditorId: values.Controler,
    DocumentId: documentId,
    Subject: values.Subject,
    ParentId: currentAssignment ? currentAssignment?.Id : null,
    LinkedDocumentIds: documentLinks,
    FileIds: fileIds,
    FilesId: fileIds,
  };
};
