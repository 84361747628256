export const phaseTypes = [
  {
    Type: 'Unknown',
    Name: 'Неизвестен',
  },
  {
    Type: 'Task',
    Name: 'Задача',
  },
  {
    Type: 'TaskReview',
    Name: 'Задача - на рассмотрении',
  },
  {
    Type: 'TaskApproval',
    Name: 'Задача - на согласовании',
  },
  {
    Type: 'TaskCheck',
    Name: 'Задача - на проверке',
  },
  {
    Type: 'TaskSigning',
    Name: 'Задача - на подписании',
  },
  {
    Type: 'TaskRegistration',
    Name: 'Задача - на регистрации',
  },
  {
    Type: 'TaskDispatch',
    Name: 'Задача - на отправке',
  },
  {
    Type: 'TaskStamp',
    Name: 'Задача - наложение штампа',
  },
  {
    Type: 'EventStart',
    Name: 'Начало процесса',
  },
  {
    Type: 'EventEnd',
    Name: 'Конец процесса',
  },
  {
    Type: 'Notification',
    Name: 'Уведомление',
  },
  {
    Type: 'ScriptCSharp',
    Name: 'Скрипт C#',
  },
  {
    Type: 'GatewayInclusive',
    Name: 'Шлюз включающий',
  },
  {
    Type: 'GatewayExclusive',
    Name: 'Шлюз исключающий',
  },
  {
    Type: 'GatewayParallel',
    Name: 'Шлюз паралельный',
  },
  {
    Type: 'Access',
    Name: 'Выдать доступ',
  },
  {
    Type: 'GenerateDocument',
    Name: 'Генерация по шаблону',
  },
  {
    Type: 'AssignProcessObject',
    Name: 'Присваивание - объект процесса',
  },
  {
    Type: 'AssignTask',
    Name: 'Присваивание - задача',
  },
];

export const phaselinksColor = [
  {
    Color: 'Unknown',
    Name: 'Не задано',
  },
  {
    Color: 'Yellow',
    Name: 'Желтый',
  },
  {
    Color: 'White',
    Name: 'Белый',
  },
  {
    Color: 'Green',
    Name: 'Зеленый',
  },
  {
    Color: 'Blue',
    Name: 'Голубой',
  },
  {
    Color: 'Red',
    Name: 'Красный',
  },
];

export const executionType = [
  {
    Id: 1,
    Type: 'Author',
    Name: 'Автор',
  },
  {
    Id: 2,
    Type: 'AuthorLeader',
    Name: 'Руководитель автора',
  },
  {
    Id: 3,
    Type: 'User',
    Name: 'Пользователь',
  },
  {
    Id: 4,
    Type: 'Department',
    Name: 'Департамент',
  },
  {
    Id: 5,
    Type: 'Correspondent',
    Name: 'Корреспондент',
  },
  {
    Id: 6,
    Type: 'Addresseee',
    Name: 'Адресат',
  },
];
