import { notification } from 'antd';

export const checkIsItemUnderMaxSize = (itemSize: number, maxSize: number) => {
  return itemSize / 1024 / 1024 < maxSize;
};

export const getErrorNotification = (description: string) => {
  notification['error']({
    message: 'Произошла ошибка',
    description,
    className: 'custom-notification__error',
  });
};

export const getListSizes = (sizeList: number[]) => {
  const fullSize = sizeList.reduce((acc, item) => (acc += item), 0);

  return checkIsItemUnderMaxSize(fullSize, 100);
};
