import { Drawer, Select } from 'antd';
import { useFormik } from 'formik';
import { DrawerHeader } from 'shared/components/CustomDrawers/DrawerHeader';
import { DButton, DInput } from 'shared/ui-kit';
import { DFormItemTwo } from 'shared/ui-kit/DFormItem';
import { errorThrow, successNotification } from 'shared/utils';
import { $api } from 'shared/utils/api';
import { OrganisationDrawerType, OrganisationValues } from './types';
import './Organisations.scss';

export const OrganisationDrawer = ({
  visible,
  onClose,
}: OrganisationDrawerType) => {
  const initialValues: Partial<OrganisationValues> = {
    Name: undefined,
    Email: undefined,
    SendTypeIds: [],
    PhoneNumber: undefined,
    PhoneNumberAlter: undefined,
    Ogrn: undefined,
    Inn: undefined,
    Kpp: undefined,
    IfnsCode: undefined,
    IedoCode: undefined,
    OfficialCode: undefined,
  };

  const onSubmit = (values: Partial<OrganisationValues>) => {
    $api
      .post(`organisations/createwithinn/${values.Inn}`, {
        ...values,
        Type: 'Outside',
      })
      .then(() => {
        onClose();
        successNotification('Организация создана');
      })
      .catch(({ response }) => errorThrow(response));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
  });

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      closeIcon={null}
      className="drawer organisation-drawer"
    >
      <div className="page3">
        <DrawerHeader onClose={onClose} text="Создание организации" />
        <form onSubmit={formik.handleSubmit}>
          <DFormItemTwo label="Название" name="Name">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Name}
              name="Name"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo required label="ИНН" name="Inn">
            <DInput
              width="100%"
              type="number"
              value={formik.values.Inn}
              name="Inn"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="КПП" name="Kpp">
            <DInput
              width="100%"
              type="number"
              value={formik.values.Kpp}
              name="Kpp"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="ОГРН" name="Ogrn">
            <DInput
              width="100%"
              type="number"
              value={formik.values.Ogrn}
              name="Ogrn"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="ИФНС код" name="IfnsCode">
            <DInput
              width="100%"
              type="number"
              value={formik.values.IfnsCode}
              name="IfnsCode"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Почта" name="Email">
            <DInput
              width="100%"
              type="text"
              value={formik.values.Email}
              name="Email"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Телефон" name="PhoneNumber">
            <DInput
              width="100%"
              type="text"
              value={formik.values.PhoneNumber}
              name="PhoneNumber"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Служебный код" name="OfficialCode">
            <DInput
              width="100%"
              type="text"
              value={formik.values.OfficialCode}
              name="OfficialCode"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo label="Альтернативный телефон" name="PhoneNumberAlter">
            <DInput
              width="100%"
              type="text"
              value={formik.values.PhoneNumberAlter}
              name="PhoneNumberAlter"
              onChange={formik.handleChange}
            />
          </DFormItemTwo>
          <DFormItemTwo>
            <DButton primary small type="submit">
              Создать
            </DButton>
          </DFormItemTwo>
        </form>
      </div>
    </Drawer>
  );
};
